/* eslint-disable */ 

import React, { Component } from 'react';
import { withRouter} from "react-router-dom";
import axios from 'axios';
import Sidebar from './sidebar';
import Header from './Head';
import {  NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import $ from 'jquery';
import BaseURL from './config';
import TokenErrorMsg from './errorMsg';

import  getEditinfo    from './StartEditMode';
import  finishEditinfo    from './FinishEditMode';

import tenantUser from './TenantUserPermision';

const Cryptr = require('cryptr');
const cryptr = new Cryptr('@$#rgweR35*&YFSD)/');

  class EditFactoringCompany extends Component {
  _isMounted=false;
  constructor(props){
    super(props);
    this.state = {
     
     
      formdata:'',
      countries:[],
      states:[],
      Contacts:[],      
      factoryId:'',
      whileSave: false,  
      UpdateContactid:'',    
      readonly: true,
      isLoading: false,
      token:'',
      fields: {},
      errors: {},
      CheckEditInfo:{},
      role:'Tenant'

    };
    this.handleChange = this.handleChange.bind(this);
    this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);
    this.submit=this.submit.bind(this);
    this.validateForm=this.validateForm.bind(this);
    this.AutoFormatContact = this.AutoFormatContact.bind(this);
}

onUnload=  (event) =>{ 
  
  event.preventDefault();
  var params;  
  var http = new XMLHttpRequest();
   
 var url = BaseURL+'editModeInfo/finishEditMode';
 
 if(this.state.CheckEditInfo.factoringCompanyId) 
 {
  params = "factoringCompanyId="+this.state.factoryId+"&token="+this.state.token;

 }

 
 http.open("POST", url, true);
 
 http.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
 
 
 if(params){
  http.send(params);
  var start = new Date().getTime();
    while (new Date().getTime() < start + 350);  
 }
 
    
   
 } 

 
 async componentDidMount(){
  this._isMounted=true;
   window.addEventListener("beforeunload", this.onUnload)  

    var  data={
      'id':'',
      'customername':'',      
      'addressid':'',
      'address':'',
      'addressLineTwo':'',     
      'countryid':'',     
      'stateid':'',
      'city':'',
      'zip':'',
      'email':'',
      'phone':'',
      'ext':'',
      'fax':'',    
 
    };
    var arr= {
      'countries':[],
      'addressStates':[],  
      'factoringCompanyContacts':[]

    };
    var role='Tenant';
  const Role = localStorage.getItem('Role');
  if(Role){
    role = cryptr.decrypt(Role);    
   }
    const token=localStorage.getItem('Token');
    const id=this.props.match.params.id;
    if(this._isMounted){
    this.setState({factoryId:id,token:token,role:role});
    }
    await axios.post(BaseURL+'factoringCompany/findById', {
        'id': id,
        'token':token
        
      })
      .then(response=> {           
        if(response.data.error=== TokenErrorMsg){     
         localStorage.setItem('Token','') ;      
         this.props.history.push('/') ;       
             }

         arr=response.data.data;        
        var element =response.data.data.factoringCompany;
        console.log(element);       
         data={
            'id':element.id,
            'customername':element.companyName,           
            'addressid':element.address.id,
            'address':element.address.address,
            'addressLineTwo':element.address.addressLineTwo,
            // 'country':element.address.state.country.countryName,
            'countryid':element.address.addressCountryId,
            // 'state':element.address.state.state,
            'stateid':element.address.addressStateId,
            'city':element.address.city,
            'zip':element.address.zip,
            'email':element.address.email,
            'phone':element.address.telephone,
            'ext':element.address.ext,
            'fax':element.address.fax,            
       
          }
       
      }).catch(function (error) {
        NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);         
        console.log(error);
      })

   
      if(this._isMounted){

      await this.setState({
        formdata:data,       
        countries:arr.countries,
        states:arr.addressStates,  
        Contacts:arr.factoringCompanyContacts,    
       
      });

    }  
    
  }

  componentWillUnmount() {
    this._isMounted=false;
    window.removeEventListener("beforeunload", this.onUnload);
    var config=this.state.CheckEditInfo;
   if(config.token){
    finishEditinfo(config, (res) => {
     
    },(err) => {
      
      console.log(err)
    });
   }  
    
 }
 
  AutoFormatContact(e){
    

    function phone_formatting(ele,restore) {
      var new_number,
          // selection_start = ele.selectionStart,
          // selection_end = ele.selectionEnd,
          number = ele.value.replace(/\D/g,'');
      
      // automatically add dashes
      if (number.length > 2) {
        // matches: 123 || 123-4 || 123-45
        new_number = number.substring(0,3) + '-';
        if (number.length === 4 || number.length === 5) {
          // matches: 123-4 || 123-45
          new_number += number.substr(3);
        }
        else if (number.length > 5) {
          // matches: 123-456 || 123-456-7 || 123-456-789
          new_number += number.substring(3,6) + '-';
        }
        if (number.length > 6) {
          // matches: 123-456-7 || 123-456-789 || 123-456-7890
          new_number += number.substring(6);
        }
      }
      else {
        new_number = number;
      }
      
      // if value is heigher than 12, last number is dropped
      // if inserting a number before the last character, numbers
      // are shifted right, only 12 characters will show
      ele.value =  (new_number.length > 12) ? new_number.substring(0,12) : new_number;
      
      // restore cursor selection,
      // prevent it from going to the end
      // UNLESS
      // cursor was at the end AND a dash was added
    
      
      // if (new_number.slice(-1) === '-' && restore === false
      //     && (new_number.length === 8 && selection_end === 7)
      //         || (new_number.length === 4 && selection_end === 3)) {
      //     selection_start = new_number.length;
      //     selection_end = new_number.length;
      // }
      // else if (restore === 'revert') {
      //   selection_start--;
      //   selection_end--;
      // }
      // ele.setSelectionRange(selection_start, selection_end);
    
    }
      
    function phone_number_check(field,e) {
      var key_code = e.keyCode,
          key_string = String.fromCharCode(key_code),
          press_delete = false,
          dash_key = 189,
          delete_key = [8,46],
          direction_key = [33,34,35,36,37,38,39,40],
          selection_end = field.selectionEnd;
      
      // delete key was pressed
      if (delete_key.indexOf(key_code) > -1) {
        press_delete = true;
      }
      
      // only force formatting is a number or delete key was pressed
      if (key_string.match(/^\d+$/) || press_delete) {
        phone_formatting(field,press_delete);
      }
      // do nothing for direction keys, keep their default actions
      else if(direction_key.indexOf(key_code) > -1) {
        // do nothing
      }
      else if(dash_key === key_code) {
        if (selection_end === field.value.length) {
          field.value = field.value.slice(0,-1)
        }
        else {
          field.value = field.value.substring(0,(selection_end - 1)) + field.value.substr(selection_end)
          field.selectionEnd = selection_end - 1;
        }
      }
      // all other non numerical key presses, remove their value
      else {
        e.preventDefault();
    //    field.value = field.value.replace(/[^0-9\-]/g,'')
        phone_formatting(field,'revert');
      }
    
    }
    
    const Validate=(e)=> {
      if( !String.fromCharCode(e.keyCode).match(/^- $/))
      {
      this.handleChange(e)
      }
    }
   
    document.getElementById(e.target.id).onkeyup = function(e) {
      phone_number_check(this,e);
      Validate(e)
    }
  }

  async handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    if(this._isMounted){
    await this.setState({
      fields
    });
  }
    this.validateForm();
  }
  
  submituserRegistrationForm(e) {
    e.preventDefault();
    var send=false;
    if (this.validateAndSubmit() && this.validateForm() ) {
      
      send=true;
       
    }
    if(send)
    {
     
      this.submit();
    }
    
  }
  
 
validateAndSubmit(){

  // let fields = this.state.fields;
  let errors = {};
  let formIsValid = true;

  

  // CustomerNAme
  if (!this.refs.customerName.value || !this.refs.customerName.value.trim().length>0 ) {
    formIsValid = false;
    errors["customerName"] = "*Please Enter Company Name";
    $("[name='customerName']").focus();
   
  }

    // Address
    else if (!this.refs.address.value || !this.refs.address.value.trim().length>0) {
      formIsValid = false;
      errors["address"] = "*Please Enter Address";
      $("[name='address']").focus();
     
    }


    // Country
    else if (!this.refs.country_id.value) {
      formIsValid = false;
      errors["country"] = "*Please Select Country";
      $("[name='country']").focus();
     
    }

     // State
     else if (!this.refs.province_id.value) {
      formIsValid = false;
      errors["state"] = "*Please Select State";
      $("[name='state']").focus();
     
    }

    // City
    else  if (!this.refs.city.value || !this.refs.city.value.trim().length>0) {
      formIsValid = false;
      errors["city"] = "*Please Enter City";
      $("[name='city']").focus();
     
    }

    // Zip
    else if (!this.refs.zip.value || !this.refs.zip.value.trim().length>0) {
      formIsValid = false;
      errors["zip"] = "*Please Enter Zip";
      $("[name='zip']").focus();
     
    }    
    if(this._isMounted){
    this.setState({
      errors: errors
    });
  }
    return formIsValid;
}
  
  validateForm() {

    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
  
  
  // customerName
    if (typeof fields["customerName"] !== "undefined" && fields["customerName"]) {
    if (!fields["customerName"].match(/^[a-zA-Z#'".,-/ ]{1,200}$/)) {
      formIsValid = false;
      errors["customerName"] = "*Only Alphabets Allowed (Max Range: 200)";
    }
    }
  
  
  
    
  
    //address
    
    if (typeof fields["address"] !== "undefined" && fields["address"]) {
      if (!fields["address"].match(/^[a-zA-Z0-9#'"،.,-;/ ]{1,200}$/)) {
        formIsValid = false;
        errors["address"] = "*Please Enter Only Valid Characters (Max Range: 200)";
      }
      }
  
  
      
  //addressLineTwo
  
  if (typeof fields["addressLineTwo"] !== "undefined" && fields["addressLineTwo"]) {
    if (!fields["addressLineTwo"].match(/^[a-zA-Z0-9#'"،.,-;/ ]{1,200}$/)) {
      formIsValid = false;
      errors["addressLineTwo"] = "*Please Enter Only Valid Characters (Max Range: 200)";
    }
    }
    
  //city
  
  if (typeof fields["city"] !== "undefined" && fields["city"]) {
    if (!fields["city"].match(/^[a-zA-Z0-9#., ]{1,200}$/)) {
      formIsValid = false;
      errors["city"] = "*Please Enter Only Valid Characters (Max Range: 200)";
    }
    }
  
    //zip
  
  if (typeof fields["zip"] !== "undefined" && fields["zip"]) {
    if (!fields["zip"].match(/^[a-zA-Z0-9 ]{1,35}$/)) {
      formIsValid = false;
      errors["zip"] = "*Only Numbers Allowed in Zip (Max Range: 35)";
    }
    }
  
    //telephone
  
    if (typeof fields["telephone"] !== "undefined" && fields["telephone"]) {
      if (!fields["telephone"].match(/^[0-9- ]{12,30}$/)) {
        formIsValid = false;
        errors["telephone"] = "*Please Enter only valid Telephone Number ( Range: 10)";
      }
      }
  
    //Ext
  
  if (typeof fields["ext"] !== "undefined" && fields["ext"]) {
    if (!fields["ext"].match(/^[0-9]{1,20}$/)) {
      formIsValid = false;
      errors["ext"] = "*Only Numbers Allowed (Max Range: 20)";
    }
    }
    //Fax
  
    if (typeof fields["fax"] !== "undefined" && fields["fax"]) {
      if (!fields["fax"].match(/^[0-9- ]{12,30}$/)) {
        formIsValid = false;
        errors["fax"] = "*Please Enter only valid fax Number ( Range: 10)";
      }
      }
  
      //email
  
  if (typeof fields["email"] !== "undefined" && fields["email"]) {
    if (!fields["email"].match(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/)) {
      formIsValid = false;
      errors["email"] = "*Please Enter Valid Email (Max Range: 100)";
    }
    }  
  
  if(this._isMounted){
    this.setState({
      errors: errors
    });
  }
    return formIsValid;
  
  
  }


   states() {   

    var formData = this.state.formdata;
    formData['countryid']= this.refs.country_id.value;
    formData['stateid']= 1; 
    if(this._isMounted){
    this.setState({formdata:formData});
    }
     axios.post(BaseURL+'countries/states',{
      "countryId": this.refs.country_id.value,
         'token':this.state.token
    })
    .then(response=> {           
      if(response.data.error=== TokenErrorMsg){     
       localStorage.setItem('Token','') ;      
       this.props.history.push('/') ;       
           }   
      var arr = response.data.data;
      this.setState({states:arr});  
     
    }).catch(function (error) {
      NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);         
      console.log(error);
    })    
    
  }
  
 

  cancel(){
    
    if(window.confirm(' Are you sure you want to cancel ? '))
    this.props.history.push('/factoringCompany');
 
  }

    async submit(event){
   
      if(this._isMounted){
        this.setState({readonly:true});
      }
     const customerName =  this.refs.customerName.value.trim();
    
     const address =  this.refs.address.value.trim();
     const address2 =  this.refs.address2.value.trim();
     const country_id =  this.refs.country_id.value.trim();
     const province_id =  this.refs.province_id.value.trim();
     const city =  this.refs.city.value.trim();
     const zip =  this.refs.zip.value.trim();
     const email =  this.refs.email.value.trim();
     const telephone =  this.refs.telephone.value.trim();
     const fax =  this.refs.fax.value.trim();
     const ext =  this.refs.ext.value.trim();

    
     
      var customer={
        'token':this.state.token,
       "id":this.state.formdata.id,
       "companyName":customerName,
       "sameAsBillingAddress":0,
       "address": {
           "id": this.state.formdata.addressid,
           "address": address,
           "addressLineTwo": address2,
           "city": city,
           "zip": zip,
           "email": email,
           "telephone": telephone,
           "ext": ext,
           "fax": fax,
           "state": {
               "id": province_id,
               "country": {
                   "id": country_id
                   
               }
           }
       }
   }
 
   
      
    await axios.post(BaseURL+'factoringCompany/update', customer )
           .then(response=> {           
            if(response.data.error=== TokenErrorMsg){     
             localStorage.setItem('Token','') ;      
             this.props.history.push('/') ;       
                 }
              else if(response.data.status === 1)
              {
                NotificationManager.error(response.data.message, 'Error', 3000);
                return; 
              }
              else if(response.data.status === 0){
                NotificationManager.error(response.data.message, 'Error', 3000);
                return; 
              }
             NotificationManager.success('Factoring Company Edited Successfully.', 'Edited', 3000); 
             console.log("biling updated");
           }).catch(function (error) {
            NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);         
            console.log(error);
          })
        
        
 
  }

  
   
   EditForm(){

    var div = document.getElementById('EditDiv');   
    div.setAttribute("style"," cursor: not-allowed; pointer-events: none") 
    
    var config={
      "factoringCompanyId": this.state.factoryId,     
      "token":this.state.token
     }
  
    getEditinfo(config, (res) => {
      console.log(res);
      if(res.data.status === 0){
        if(this._isMounted){
         this.setState({
           readonly:false,
           CheckEditInfo:config
          });
        }
      }
      else {
        div.removeAttribute("style")
        NotificationManager.warning(res.data.message, 'Warning Message', 3000);   
      }
  },(err) => {
     
    console.log(err)
  });

  }

    selectState(e){

      e.preventDefault();
      var formData = this.state.formdata;

      if(e.target.name === 'state'  && this._isMounted ){
        formData['stateid'] = this.refs.province_id.value;
        this.setState({formdata:formData});
      }  
      
    }

    async ClearContact(){
      if(this._isMounted){
      await this.setState({
        UpdateContactid:'',
        
       });
      }
       
       this.refs.ContactName.value='';
       this.refs.ContactTitle.value='';
       this.refs.ContactEmail.value='';
       this.refs.ContactTelephone.value='';
       this.refs.ContactExt.value='';
       this.refs.ContactPhone.value='';
       this.refs.ContactFax.value='';
       
     } 
    
    async SaveContact(event){
      if(this._isMounted){
      this.setState({
        isLoading: true
      });
    }
      event.preventDefault();
      $('#closePopUp').click();
    var arr;
    var data=false;
    var custid=this.state.factoryId;
  
    if(this.state.UpdateContactid){
  
      var CustomerContact={
        'token':this.state.token,
        "id": this.state.UpdateContactid,
        "name": this.refs.ContactName.value.trim(),
        "title":  this.refs.ContactTitle.value.trim(),
        "email":  this.refs.ContactEmail.value.trim(),
        "telephone": this.refs.ContactTelephone.value.trim(),
        "ext":  this.refs.ContactExt.value.trim(),
        "cellphone":  this.refs.ContactPhone.value.trim(),
        "fax":  this.refs.ContactFax.value.trim()
      }
  
      await axios.post(BaseURL+'factoringCompanyContact/update', CustomerContact )
      .then(response=> {           
             
        if(response.data.error=== TokenErrorMsg){
          localStorage.setItem('Token','')
          this.props.history.push('/')
        }
       console.log(response.data); 
        NotificationManager.success('Factoring Company Contact Infomation Update  ', 'Factoring Company Info', 3000);
      }).catch(function (error) {
        NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);         
        console.log(error);
      })
      if(this._isMounted){
      this.setState({ UpdateContactid: '', isLoading: false});
      }
    }
  
    else{
    var CustomerContactUpdate={
      'token':this.state.token,
      "factoringCompanyId": custid,
      "name": this.refs.ContactName.value.trim(),
      "title":  this.refs.ContactTitle.value.trim(),
      "email":  this.refs.ContactEmail.value.trim(),
      "telephone": this.refs.ContactTelephone.value.trim(),
      "ext":  this.refs.ContactExt.value.trim(),
      "cellphone":  this.refs.ContactPhone.value.trim(),
      "fax":  this.refs.ContactFax.value.trim()
    }
  
      await axios.post(BaseURL+'factoringCompanyContact/save', CustomerContactUpdate )
      .then(response=> {
              
             
        if(response.data.error=== TokenErrorMsg){
          localStorage.setItem('Token','')
          this.props.history.push('/')
        }
       console.log(response.data); 
        NotificationManager.success('Factoring Company Contact Infomation Saved ', 'Factoring Company Info', 3000);
      }).catch(function (error) {
        NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);         
        console.log(error);
      })
      if(this._isMounted){
      this.setState({
        isLoading: false
      })
    }
    }
    
  
      await axios.post(BaseURL+'factoringCompanyContact/findByFactoringCompanyId', {
        "factoringCompanyId" :custid,
        'token':this.state.token
      } )
      .then(response=> {
              
             
        if(response.data.error=== TokenErrorMsg){
          localStorage.setItem('Token','')
          this.props.history.push('/')
        }
         arr=response.data.data;
         data=true;
         
      
      }).catch(function (error) {
        NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);         
        console.log(error);
      })
     
      if(data){
        if(this._isMounted){
        this.setState({Contacts:arr});
       }
        this.refs.ContactName.value='';
        this.refs.ContactTitle.value='';
        this.refs.ContactEmail.value='';
        this.refs.ContactTelephone.value='';
        this.refs.ContactExt.value='';
        this.refs.ContactPhone.value='';
        this.refs.ContactFax.value='';
      }
  
      
    }

    async EditContact(e){
  
      var a = document.getElementById(e.id);   
      a.setAttribute("style"," cursor: not-allowed; pointer-events: none")
     
  
      var config={     
        "factoringCompanyContactId": e.id,     
        "token":this.state.token
       }
    
       getEditinfo(config, (res) => {
        console.log(res);
        if(res.data.status === 0){
  
          this.refs.ContactName.value=e.name;
          this.refs.ContactTitle.value=e.title;
          this.refs.ContactEmail.value=e.email;
          this.refs.ContactTelephone.value=e.telephone;
          this.refs.ContactExt.value=e.ext;
          this.refs.ContactPhone.value=e.cellphone;
          this.refs.ContactFax.value=e.fax;  
  
          
    
            
            a.href = "#contact"
            a.click();
          
            if(this._isMounted){
                this.setState({
                  UpdateContactid:e.id,         
                  CheckEditInfo:config
                });
             }
          a.removeAttribute("href");
          a.removeAttribute("style");
  
        }
        else {
          
           a.removeAttribute("style");
             NotificationManager.warning(res.data.message, 'Warning Message', 3000) ; 
        }
        
    },(err) => {
       
      console.log(err)
    });      
   }
  
    async DelContact(e){
     
      if(!window.confirm("Are you sure you want to delete Contact?")){
        return false;
     }
     if(this._isMounted){
        this.setState({
          isLoading: true
        });
      }
      var arr;
  
      await axios.post(BaseURL+'factoringCompanyContact/delete', {
        "id" :e.id,
        'token':this.state.token
      } )
      .then(response=> {
              
             
        if(response.data.error=== TokenErrorMsg){
          localStorage.setItem('Token','')
          this.props.history.push('/')
        }
        console.log(response.data)
        NotificationManager.success(response.data.message, 'Factoring Company Info', 3000);
      }).catch(function (error) {
        NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);         
        console.log(error);
      })
      if(this._isMounted){
      this.setState({
        isLoading: false
      });
    }
  
      await axios.post(BaseURL+'factoringCompanyContact/findByFactoringCompanyId', {
        "factoringCompanyId" :this.state.factoryId,
        'token':this.state.token
      })
      .then(response=> {
              
             
        if(response.data.error=== TokenErrorMsg){
          localStorage.setItem('Token','')
          this.props.history.push('/')
        }
         arr=response.data.data;
        
      }).catch(function (error) {
        NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);         
        console.log(error);
      })
      if(this._isMounted){
        this.setState({Contacts:arr});
      }
  
    }
  
    FinishEditInfo() {
      var config=this.state.CheckEditInfo;
      if(config.token){
      
       finishEditinfo(config, (res) => {
        console.log(res);
        if(this._isMounted){
        this.setState({
          CheckEditInfo:{},       
          readonly:true
        });
      }
       
       },(err) => {
       
        console.log(err)
        });
    
       }   
       
    }


  render() {

    const stateid=this.state.formdata.stateid;   

   
    const msgNone = {
      display: 'none'
    }    

    const msgShowin = {
      display: 'inline-block',
      marginLeft: '10px'
    }
 
  const nonclick={
    cursor:' notAllowed',
    pointerEvents: 'none'
  }
   

    

    return (
        
      <div className="page-container-bg-solid page-header-fixed page-sidebar-closed-hide-logo page-md">
       <Header title='| Factoring Company' />
     <div className="page-container"> 
       <Sidebar/>
        <div className="page-content-wrapper">        
        <div className="page-content">     
          <div className="page-head">           
          </div>
          <div className="row">
            <div className="col-md-12">

            <div className="tabbable-line boxless  tabbable-reversed">
            <ul className="nav nav-tabs" hidden={this.state.hidden}>
              <li className="active border-green-jungle"> <a href="#tab_0" data-toggle="tab" onClick={this.FinishEditInfo.bind(this)}>Factoring Company</a> </li>
              <li className="border-green-jungle"> <a href="#tab_1" data-toggle="tab" onClick={this.FinishEditInfo.bind(this)} > Contact </a> </li>
            </ul>    
           
            <div className="tab-content">
              <div className="tab-pane active" id="tab_0">
                <div className="portlet box yellow-gold">
                      <div className="portlet-title">
                        <div className="caption"> <i className="icon-briefcase"></i>Update Factoring Company </div>
                        <div className="actions" style={this.state.readonly?{}:nonclick} id='EditDiv'>
                        
                          <div className="btn-group">
                              <button className="btn sbold white dark" onClick={this.EditForm.bind(this)}>
                                  <i className="fa fa-pencil"></i> Edit
                              </button>
                          </div>
                      
                       </div>
                      </div>
                      <div className="portlet-body form"> 
                      
                        <form  className="horizontal-form" autoComplete="off"  onSubmit={this.submituserRegistrationForm.bind(this)}>
                          <div className="form-body max_width800">
                            <div className="portlet-title">
                              <h2 className="h3"> Factoring Company Info   </h2>
                              
                              <div className="clearfix"></div>
                              <hr/>
                            </div>
                            <div className="form-group row">
                              <label className="col-md-3 control-label">Company Name <span className="font-red-thunderbird" >*</span></label>
                              <div className="col-md-9">
                              <input type="text" ref="customerName" onChange={this.handleChange} name="customerName"  className="form-control" placeholder="" defaultValue={this.state.formdata.customername}   readOnly={this.state.readonly}/>
                              <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.customerName}</div>
                              </div>
                            </div>
                           
                            <div className="portlet-title">
                              <h2 className="h3"> Address Info </h2>
                              <hr/>
                            </div>
                            <div className="form-group row">
                              <label className="col-md-3 control-label">Address <span className="font-red-thunderbird" >*</span></label>
                              <div className="col-md-9">
                              <input type="text" ref="address" onChange={this.handleChange} name="address" className="form-control" placeholder=""    defaultValue={this.state.formdata.address}   readOnly={this.state.readonly}/>
                              <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.address}</div>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-md-3 control-label">Address Line 2</label>
                              <div className="col-md-9">
                              <input type="text" ref="address2" onChange={this.handleChange} name="addressLineTwo" className="form-control" placeholder=""   defaultValue={this.state.formdata.addressLineTwo} readOnly={this.state.readonly}/>
                              <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.addressLineTwo}</div>
                              </div>
                            </div>
                          
                            <div className="form-group row">
                              <label className="col-md-3 control-label">Country <span className="font-red-thunderbird" >*</span></label>
                              <div className="col-md-9">
                                
                                
                             
                                
                                <select name="country" ref="country_id" className="form-control" title="Country" value={this.state.formdata.countryid}  onChange={this.states.bind(this)}   readOnly={this.state.readonly} disabled={this.state.readonly}>

                                
                                  {
                                  this.state.countries.map(function (data) {
                                                                     
                                  //  if(this.state.formdata.countryid === data.id)
                                  //   return   <option value={data.id} key={data.id} selected> {data.countryName} </option>;
                                    return  <option value={data.id} key={data.id}> {data.countryName} </option>;
                                   
                            
                                  },
                                  this
                                  )

                                }
                                  </select>
                                  <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.country}</div>
                              
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-md-3 control-label">State <span className="font-red-thunderbird" >*</span></label>
                              <div className="col-md-9">

                              <select name="state" ref="province_id" className="form-control" value={stateid}  onChange={this.selectState.bind(this)}   readOnly={this.state.readonly} disabled={this.state.readonly}>
                              
                             
                                {
                                  this.state.states.map(function (dat) {
                                    // if(stateid=== dat.id)
                                    // return <option value={dat.id} selected key={dat.id}>{dat.state}</option>;
                                       return <option value={dat.id} key={dat.id}> {dat.state} </option>;
                                  },this)

                                }
                              </select>
                              <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.state}</div>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-md-3 control-label">City <span className="font-red-thunderbird" >*</span></label>
                              <div className="col-md-9">
                              <input type="text" ref="city" onChange={this.handleChange} name="city" className="form-control" placeholder="" defaultValue={this.state.formdata.city}   readOnly={this.state.readonly}/>
                              <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.city}</div>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-md-3 control-label">Zip <span className="font-red-thunderbird" >*</span></label>
                              <div className="col-md-9">
                              <input type="text" ref="zip" onChange={this.handleChange} name="zip" className="form-control" placeholder="" defaultValue={this.state.formdata.zip}   readOnly={this.state.readonly}/>
                              <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.zip}</div>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-md-3 control-label">Email</label>
                              <div className="col-md-9">
                              <input type="email" ref="email" className="form-control" onChange={this.handleChange} name="email" placeholder="" defaultValue={this.state.formdata.email} readOnly={this.state.readonly}/>
                              <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.email}</div>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-md-3 control-label">Telephone</label>
                              <div className="col-md-9 form-inline">
                              <input type="text" ref="telephone" id='Telephone' onChange={this.AutoFormatContact} name="telephone" className="form-control" placeholder="xxx-xxx-xxxx" defaultValue={this.state.formdata.phone} readOnly={this.state.readonly}/>
                              <span className="help-inline"> EXT </span>  <input type="text" ref="ext"  name="ext"  onChange={this.handleChange} className="form-control" placeholder="" defaultValue={this.state.formdata.ext} readOnly={this.state.readonly}/>
                              <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.telephone}</div>
                              <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.ext}</div>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-md-3 control-label">Fax</label>
                              <div className="col-md-9">
                              <input type="text" ref="fax"  name="fax"  id='fax' onChange={this.AutoFormatContact} className="form-control" placeholder="xxx-xxx-xxxx" defaultValue={this.state.formdata.fax} readOnly={this.state.readonly}/>
                              <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.fax}</div>
                              </div>
                            </div>
                          
                          </div>
                          <div className="clearfix"></div>
                          <div className="form-actions right">
                            <button  onClick={this.cancel.bind(this)} type="button" className="btn default">Cancel</button> &nbsp;
                          
                            <button   type="submit" className="btn green-meadow" disabled={this.state.readonly}> <i className="fa fa-check"></i> Save</button>
                        
                            </div>
                        </form>
                       
                      </div>
                    </div>
            </div>
              <div className="tab-pane" id="tab_1">
                     <div className="portlet box yellow-gold">
                  <div className="portlet-title">
                    <div className="caption"> <i className="fa fa-envelope"></i> Contact Info</div>
                  </div>
                  <div className="portlet-body form "> 
                  
                    <form action="#" className="horizontal-form">
                      <div className="col-md-12">
                      <div className="mt10">
                          <table className="table table-striped table-bordered table-hover table-checkable order-column dataTable no-footer">
                            <thead>
                              <tr>
                              <th className="text-center"> Name </th>
                              <th className="text-center w200"> Title </th>
                              <th className="text-center"> Email </th>
                              <th className="text-center"> Telephone </th>
                              <th className="text-center"> Cell Phone </th>
                              <th className="text-center"> Fax </th>
                              <th className="text-center"> Action </th>
                            
                              </tr>
                            </thead>
                            <tbody>                  
                           
                              { this.state.Contacts.map(function (data) {
                                    return (
                                    <tr  key={data.id} >
                                    <td className="text-center"> {data.name} </td>
                                    <td className="text-center"> {data.title} </td>
                                    <td className="text-center"> {data.email} </td>
                                    <td className="text-center"> {data.telephone} </td>
                                    <td className="text-center"> {data.cellphone} </td>
                                    <td className="text-center"> {data.fax} </td>
                                    <td className="text-center"><a data-toggle="modal" id={data.id}> <i className="fa fa-pencil" onClick={() => this.EditContact(data)}></i> </a>  &nbsp;
                                    <a> <i className="fa fa-trash-o" onClick={() => this.DelContact(data)}></i> </a></td>
                                    </tr>
                                    );
                                  },this)}
                               
                              
                            </tbody>
                          </table>
                      </div>
                      <div className="mb-2">
                           <a data-toggle="modal" href="#contact"  className="btn  green-meadow" onClick={this.ClearContact.bind(this)}> Add Contact</a>
                           <span style={this.state.isLoading === false ? msgNone : msgShowin}><i className="fa fa-circle-o-notch fa-spin"></i></span>
                      </div>
                    </div>
                    &nbsp;
                    <div className="clearfix"></div>
                    </form>
                          
                  </div>
                </div>
                </div>



          </div>
        
        </div>
      
      </div>
      </div>
      </div>
      </div>

      <div className="modal fade" id="contact" data-backdrop="static" data-keyboard="false" tabIndex="-1"  aria-hidden="true">
        <div className="modal-dialog  modal-lg">
          <div className="modal-content">
            <div className="modal-header">
                <button type="button"  id="closePopUp" data-dismiss="modal" aria-hidden="true" hidden={true}></button>
                <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={this.FinishEditInfo.bind(this)}></button>
                <h4 className="modal-title">Contact</h4>
            </div>
            <div className="modal-body"> 
              <div className="portlet-body form"> 
                 
              <form onSubmit={this.SaveContact.bind(this)} className="horizontal-form">
                    <div className="form-body max_width800">
                     <br/>
                    
                      <div className="form-group row">
                        <label className="col-md-3 control-label">Name <span className="font-red-thunderbird" >*</span></label>
                        <div className="col-md-9">
                        <input type="text" ref="ContactName"   pattern="[a-z A-Z0-9-'@!$#:;,. ]{1,200}" title="Please Enter Contact Name (Max limit: 200)" className="form-control" placeholder="" required/>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-md-3 control-label ">Title</label>
                        <div className="col-md-9">
                        <input type="text" ref="ContactTitle" pattern="[a-z A-Z'(),-_.\x22 ]{1,200}" title="Please Enter title (Max limit: 200)" className="form-control" placeholder=""/>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-md-3 control-label">Email</label>
                        <div className="col-md-9">
                        <input type="email" ref="ContactEmail" className="form-control" placeholder=""/>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-md-3 control-label">Telephone</label>
                        <div className="col-md-9 form-inline">
                        <input type="text" ref="ContactTelephone" id='contactTelephone' pattern="[a-z A-Z0-9- ]{12}" title="Please Enter Full lenght Telephone (12 digits)" onChange={this.AutoFormatContact} className="form-control" placeholder="xxx-xxx-xxxx"/>
                        <span className="help-inline"> EXT </span>  <input type="text" pattern="[0-9]{1,10}" title="Please Enter extention (Max 10 Digits Only)" ref="ContactExt" className="form-control" placeholder=""/>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-md-3 control-label">Cell Phone</label>
                        <div className="col-md-9">
                        <input type="text" ref="ContactPhone" id='contactPhone' pattern="[a-z A-Z0-9- ]{12}" title="Please Enter Full lenght Cellphone (12 digits)" onChange={this.AutoFormatContact} className="form-control" placeholder="xxx-xxx-xxxx"/>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-md-3 control-label">Fax</label>
                        <div className="col-md-9">
                        <input type="text" ref="ContactFax" id='contactFax' pattern="[a-z A-Z0-9- ]{12}" title="Please Enter Full lenght fax (12 digits)" onChange={this.AutoFormatContact} className="form-control" placeholder="xxx-xxx-xxxx"/>
                        </div>
                      </div>
                    </div>
                    <div className="clearfix"></div>
                    <div className="modal-footer">
                    <button type="button" className="btn default" data-dismiss="modal" onClick={this.FinishEditInfo.bind(this)}>Cancel</button> &nbsp;
                    <button type="submit"  className="btn green-meadow"> <i className="fa fa-check"></i> Save</button>
                   </div>
                  </form>
                 
                </div>
            </div>
            
        </div>
      
    </div>
   
</div>



   </div>
   </div>
  


    );
  
}
}

export default withRouter(EditFactoringCompany);
