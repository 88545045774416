/* eslint-disable */

import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import $ from 'jquery';
import BaseURL from './config';
import TokenErrorMsg from './errorMsg';


class Addcarrier extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      saveBtnDisable: null,
      status: 1,
      errorMsg: false,
      countries: [],
      states: [],
      bstates: [],
      whileSave: false,
      readonly: false,
      hidden: true,
      fields: {},
      errors: {},
      token: '',

    }

    this.handleChange = this.handleChange.bind(this);
    this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);
    this.submit = this.submit.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.AutoFormatContact = this.AutoFormatContact.bind(this);
    this.AutoFormatEIN = this.AutoFormatEIN.bind(this);
  };

  AutoFormatContact(e) {



    function phone_formatting(ele, restore) {
      var new_number,
        // selection_start = ele.selectionStart,
        // selection_end = ele.selectionEnd,
        number = ele.value.replace(/\D/g, '');

      // automatically add dashes
      if (number.length > 2) {
        // matches: 123 || 123-4 || 123-45
        new_number = number.substring(0, 3) + '-';
        if (number.length === 4 || number.length === 5) {
          // matches: 123-4 || 123-45
          new_number += number.substr(3);
        }
        else if (number.length > 5) {
          // matches: 123-456 || 123-456-7 || 123-456-789
          new_number += number.substring(3, 6) + '-';
        }
        if (number.length > 6) {
          // matches: 123-456-7 || 123-456-789 || 123-456-7890
          new_number += number.substring(6);
        }
      }
      else {
        new_number = number;
      }


      ele.value = (new_number.length > 12) ? new_number.substring(0, 12) : new_number;



    }

    function phone_number_check(field, e) {
      var key_code = e.keyCode,
        key_string = String.fromCharCode(key_code),
        press_delete = false,
        dash_key = 189,
        delete_key = [8, 46],
        direction_key = [33, 34, 35, 36, 37, 38, 39, 40],
        selection_end = field.selectionEnd;

      // delete key was pressed
      if (delete_key.indexOf(key_code) > -1) {
        press_delete = true;
      }

      // only force formatting is a number or delete key was pressed
      if (key_string.match(/^\d+$/) || press_delete) {
        phone_formatting(field, press_delete);
      }
      // do nothing for direction keys, keep their default actions
      else if (direction_key.indexOf(key_code) > -1) {
        // do nothing
      }
      else if (dash_key === key_code) {
        if (selection_end === field.value.length) {
          field.value = field.value.slice(0, -1)
        }
        else {
          field.value = field.value.substring(0, (selection_end - 1)) + field.value.substr(selection_end)
          field.selectionEnd = selection_end - 1;
        }
      }
      // all other non numerical key presses, remove their value
      else {
        e.preventDefault();
        //    field.value = field.value.replace(/[^0-9\-]/g,'')
        phone_formatting(field, 'revert');
      }

    }

    const Validate = (e) => {
      if (!String.fromCharCode(e.keyCode).match(/^- $/)) {
        this.handleChange(e)
      }
    }

    document.getElementById(e.target.id).onkeyup = function (e) {
      phone_number_check(this, e);
      Validate(e)
    }
  }

  AutoFormatEIN(e) {



    function phone_formatting(ele, restore) {


      var new_number,
        // selection_start = ele.selectionStart,
        // selection_end = ele.selectionEnd,
        number = ele.value.replace(/\D/g, '');

      // automatically add dashes
      if (number.length > 1) {
        // matches: 123 || 123-4 || 123-45
        new_number = number.substring(0, 2) + '-';
        if (number.length === 3 || number.length === 4) {
          // matches: 123-4 || 123-45
          new_number += number.substr(2);
        }
        else if (number.length > 4) {
          // matches: 123-456 || 123-456-7 || 123-456-789
          new_number += number.substring(2, 5)
        }
        if (number.length > 5) {
          // matches: 123-456-7 || 123-456-789 || 123-456-7890
          new_number += number.substring(5);
        }
      }
      else {
        new_number = number;
      }


      ele.value = (new_number.length > 50) ? new_number.substring(0, 50) : new_number;



    }

    function phone_number_check(field, e) {
      var key_code = e.keyCode,
        key_string = String.fromCharCode(key_code),
        press_delete = false,
        dash_key = 189,
        delete_key = [8, 46],
        direction_key = [33, 34, 35, 36, 37, 38, 39, 40],
        selection_end = field.selectionEnd;

      // delete key was pressed
      if (delete_key.indexOf(key_code) > -1) {
        press_delete = true;
      }


      // only force formatting is a number or delete key was pressed
      if (key_string.match(/^\d+$/) || press_delete) {
        phone_formatting(field, press_delete);
      }
      // do nothing for direction keys, keep their default actions
      else if (direction_key.indexOf(key_code) > -1) {
        // do nothing
      }
      else if (dash_key === key_code) {
        if (selection_end === field.value.length) {
          field.value = field.value.slice(0, -1)
        }
        else {
          field.value = field.value.substring(0, (selection_end - 1)) + field.value.substr(selection_end)
          field.selectionEnd = selection_end - 1;
        }
      }
      // all other non numerical key presses, remove their value
      else {
        e.preventDefault();
        field.value = field.value.replace(/[^0-9\-]/g, '')
        phone_formatting(field, 'revert');
      }

    }

    const Validate = (e) => {
      if (!String.fromCharCode(e.keyCode).match(/^- $/)) {
        this.handleChange(e)
      }
    }

    document.getElementById(e.target.id).onkeyup = function (e) {
      phone_number_check(this, e);
      Validate(e)
    }

  }


  async handleChange(e) {



    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    if (this._isMounted) {
      this.setState({
        fields
      });
    }
    this.validateForm();
  }

  submituserRegistrationForm(e) {
    e.preventDefault();
    var send = false;
    if (this.validateAndSubmit() && this.validateForm()) {

      send = true;

    }
    if (send) {

      this.submit();
      send = false;
    }

  }




  validateAndSubmit() {


    let errors = {};
    let formIsValid = true;

    // CustomerNAme
    if (!this.refs.customerName.value || !this.refs.customerName.value.trim().length > 0) {
      formIsValid = false;
      errors["customerName"] = "*Please Enter Carrier Name";
      $("[name='customerName']").focus();

    }
    else if (!this.refs.mc.value || !this.refs.mc.value.trim().length > 0) {
      formIsValid = false;
      errors["mcNo"] = "*Please Enter M.C.#";
      $("[name='mcNo']").focus();

    }
    else if (!this.refs.businesstype.value) {

      formIsValid = false;
      errors["businesstype"] = "*Please Select Business Type";
      $("[name='businesstype']").focus();

    }

    // Address
    else if (!this.refs.address.value || !this.refs.address.value.trim().length > 0) {
      formIsValid = false;
      errors["address"] = "*Please Enter Address";
      $("[name='address']").focus();

    }


    // Country
    else if (!this.refs.country_id.value) {
      formIsValid = false;
      errors["country"] = "*Please Select Country";
      $("[name='country']").focus();

    }

    // State
    else if (!this.refs.province_id.value) {
      formIsValid = false;
      errors["state"] = "*Please Select State";
      $("[name='state']").focus();

    }

    // City
    else if (!this.refs.city.value || !this.refs.city.value.trim().length > 0) {
      formIsValid = false;
      errors["city"] = "*Please Enter City";
      $("[name='city']").focus();

    }

    // Zip
    else if (!this.refs.zip.value || !this.refs.zip.value.trim().length > 0) {
      formIsValid = false;
      errors["zip"] = "*Please Enter Zip";
      $("[name='zip']").focus();

    }




    // BillingAddress
    else if (!this.refs.billing.value || !this.refs.billing.value.trim().length > 0) {
      formIsValid = false;
      errors["billingAddress"] = "*Please Enter Billing Address";
      $("[name='billingAddress']").focus();

    }


    // Country
    else if (!this.refs.billing_country_id.value) {
      formIsValid = false;
      errors["billingAddressCountry"] = "*Please Select Country";
      $("[name='billingAddressCountry']").focus();

    }

    // State
    else if (!this.refs.billing_province_id.value) {
      formIsValid = false;
      errors["billingAddressState"] = "*Please Select State";
      $("[name='billingAddressState']").focus();

    }

    // City
    else if (!this.refs.b_city.value || !this.refs.b_city.value.trim().length > 0) {
      formIsValid = false;
      errors["billingAddressCity"] = "*Please Enter City";
      $("[name='billingAddressCity']").focus();

    }

    // Zip
    else if (!this.refs.b_zip.value || !this.refs.b_zip.value.trim().length > 0) {
      formIsValid = false;
      errors["billingAddressZip"] = "*Please Enter Zip";
      $("[name='billingAddressZip']").focus();

    }
    if (this._isMounted) {
      this.setState({
        errors: errors
      });
    }
    return formIsValid;
  }


  validateForm() {

    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;


    // customerName
    if (typeof fields["customerName"] !== "undefined" && fields["customerName"]) {
      if (!fields["customerName"].match(/^[a-zA-Z0-9#'".$,-/ ]{1,200}$/)) {
        formIsValid = false;
        errors["customerName"] = "*Only Alphabets Allowed (Max Range: 200)";
      }
    }
    //mcNo

    if (typeof fields["mcNo"] !== "undefined" && fields["mcNo"]) {
      if (!fields["mcNo"].match(/^[a-zA-Z0-9#.,-/ ]{1,200}$/)) {
        formIsValid = false;
        errors["mcNo"] = "*Only Alpha-Numeric Allowed (Max Range: 200)";
      } else if (
        !this.state.readonly &&
        fields["mcNo"].trim().length > 0 &&
        fields["mcNo"].trim().length < 200
      ) {
        errors["mcNo"] = "";
        this.setState({ errors });

        axios
          .post(BaseURL + "carrierInformation/findByMcNo", {
            // "id": this.state.loadid,
            mcNo: fields["mcNo"].trim(),
            token: this.state.token
          })
          .then(response => {
            if (response.data.message === "fail") {
              this.state.saveBtnDisable = false;
            }
            if (response.data.message === "success") {
              this.state.saveBtnDisable = true;
            }
            if (response.data.error === TokenErrorMsg) {
              localStorage.setItem("Token", "");
              this.props.history.push("/");
            }

            console.log("Load resonse ...:", response.data);

            response.data.error
              ? (errors["mcInternalload"] =
                // "*Record Already Exist With this M.C No"
                "*Carrier with same MC# exists and is active, Please InActive of existing carrier"
              )
              : (errors["mcInternalload"] = "");

            this.setState({ errors });
          })
          .catch(function (error) {
            NotificationManager.error(
              "Something Went Wrong Server Is Not Responding ",
              "Error Message",
              3000
            );
            console.log(error);
          });
      } else {
        errors["mcNo"] = "";
        errors["mcInternalload"] = "";
        this.setState({ errors });
      }
    }

    if (typeof fields["accountName"] !== "undefined" && fields["accountName"]) {
      if (!fields["accountName"].match(/^[a-zA-Z0-9a-zA-Z#'".,-/ ]{1,200}$/)) {
        formIsValid = false;
        errors["accountName"] = "*Only Alphabets Allowed (Max Range: 200)";
      }
    }

    //DotNo
    if (typeof fields["dotNo"] !== "undefined" && fields["dotNo"]) {
      if (!fields["dotNo"].match(/^[a-zA-Z0-9#'.,-/ ]{1,200}$/)) {
        formIsValid = false;
        errors["dotNo"] = "*Only Alpha-Numeric Allowed (Max Range: 200)";
      }
    }

    //legal name
    if (typeof fields["legalname"] !== "undefined" && fields["legalname"]) {
      if (!fields["legalname"].match(/^[a-zA-Z0-9 ]{1,200}$/)) {
        formIsValid = false;
        errors["legalname"] = "*Only Alpha-Numeric Allowed (Max Range: 200)";
      }
    }
    //ScAc
    if (typeof fields["scac"] !== "undefined" && fields["scac"]) {
      if (!fields["scac"].match(/^[a-zA-Z0-9 ]{1,200}$/)) {
        formIsValid = false;
        errors["scac"] = "*Only Alpha-Numeric Allowed (Max Range: 200)";
      }
    }
    //CARB
    if (typeof fields["carb"] !== "undefined" && fields["carb"]) {
      if (!fields["carb"].match(/^[a-zA-Z0-9 ]{1,200}$/)) {
        formIsValid = false;
        errors["carb"] = "*Only Alpha-Numeric Allowed (Max Range: 200)";
      }
    }
    //FID

    if (typeof fields["fid"] !== "undefined" && fields["fid"]) {
      if (!fields["fid"].match(/^[0-9-]{0,50}$/)) {
        formIsValid = false;
        errors["fid"] = "*Only Valid EIN Allowed ";
      }
    }


    //mcNo

    // if (typeof fields["mcNo"] !== "undefined" && fields["mcNo"]) {
    //   if (!fields["mcNo"].match(/^[a-zA-Z0-9#.,-/ ]{1,200}$/)) {
    //     formIsValid = false;
    //     errors["mcNo"] = "*Only Alpha-Numeric Allowed (Max Range: 200)";
    //   }
    // }

    //address
    if (typeof fields["baddress"] !== "undefined" && fields["baddress"]) {
      if (!fields["baddress"].match(/^[a-zA-Z0-9#'"،.,-/ ]{1,200}$/)) {
        formIsValid = false;
        errors["baddress"] = "*Please Enter Only Valid Characters (Max Range: 200)";
      }
    }

    if (typeof fields["address"] !== "undefined" && fields["address"]) {
      if (!fields["address"].match(/^[a-zA-Z0-9#'"،.,-;/ ]{1,200}$/)) {
        formIsValid = false;
        errors["address"] = "*Please Enter Only Valid Characters (Max Range: 200)";
      }
    }



    //addressLineTwo

    if (typeof fields["addressLineTwo"] !== "undefined" && fields["addressLineTwo"]) {
      if (!fields["addressLineTwo"].match(/^[a-zA-Z0-9#'"،.,-;/ ]{1,200}$/)) {
        formIsValid = false;
        errors["addressLineTwo"] = "*Please Enter Only Valid Characters (Max Range: 200)";
      }
    }

    //city
    if (typeof fields["bcity"] !== "undefined" && fields["bcity"]) {
      if (!fields["bcity"].match(/^[a-zA-Z0-9#., ]{1,200}$/)) {
        formIsValid = false;
        errors["bcity"] = "*Please Enter Only Valid Characters (Max Range: 200)";
      }
    }

    if (typeof fields["city"] !== "undefined" && fields["city"]) {
      if (!fields["city"].match(/^[a-zA-Z0-9#., ]{1,200}$/)) {
        formIsValid = false;
        errors["city"] = "*Please Enter Only Valid Characters (Max Range: 200)";
      }
    }

    //zip

    if (typeof fields["zip"] !== "undefined" && fields["zip"]) {
      if (!fields["zip"].match(/^[a-zA-Z0-9 ]{1,35}$/)) {
        formIsValid = false;
        errors["zip"] = "*Only Numbers Allowed in Zip (Max Range: 35)";
      }
    }

    if (typeof fields["telephone"] !== "undefined" && fields["telephone"]) {
      if (!fields["telephone"].match(/^[0-9- ]{12,30}$/)) {
        formIsValid = false;
        errors["telephone"] = "*Please Enter only valid Telephone Number ( Range: 10)";
      }
    }

    if (typeof fields["btelephone"] !== "undefined" && fields["btelephone"]) {
      if (!fields["btelephone"].match(/^[0-9- ]{12,30}$/)) {
        formIsValid = false;
        errors["btelephone"] = "*Please Enter only valid Telephone Number ( Range: 10)";
      }
    }

    if (typeof fields["bzip"] !== "undefined" && fields["bzip"]) {
      if (!fields["bzip"].match(/^[a-zA-Z0-9 ]{1,35}$/)) {
        formIsValid = false;
        errors["bzip"] = "*Only Numbers Allowed in Zip (Max Range: 35)";
      }
    }

    //telephone



    //Ext

    if (typeof fields["ext"] !== "undefined" && fields["ext"]) {
      if (!fields["ext"].match(/^[0-9]{1,20}$/)) {
        formIsValid = false;
        errors["ext"] = "*Only Numbers Allowed (Max Range: 20)";
      }
    }
    //Fax

    if (typeof fields["fax"] !== "undefined" && fields["fax"]) {
      if (!fields["fax"].match(/^[0-9- ]{12,30}$/)) {
        formIsValid = false;
        errors["fax"] = "*Please Enter only valid fax Number ( Range: 10)";
      }
    }
    if (typeof fields["bfax"] !== "undefined" && fields["bfax"]) {
      if (!fields["bfax"].match(/^[0-9- ]{12,30}$/)) {
        formIsValid = false;
        errors["bfax"] = "*Please Enter only valid fax Number ( Range: 10)";
      }
    }

    //email

    if (typeof fields["email"] !== "undefined" && fields["email"]) {
      if (!fields["email"].match(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/)) {
        formIsValid = false;
        errors["email"] = "*Please Enter Valid Email (Max Range: 100)";
      }
    }




    // Billing Address Validations

    //address

    if (typeof this.refs.billing.value !== "undefined" && this.refs.billing.value) {
      if (!this.refs.billing.value.match(/^[a-zA-Z0-9#'"،.,-;/ ]{1,200}$/)) {
        formIsValid = false;
        errors["billingAddress"] = "*Please Enter Only Valid Characters (Max Range: 200)";
      }
    }



    //addressLineTwo

    if (typeof this.refs.billing2.value !== "undefined" && this.refs.billing2.value) {
      if (!this.refs.billing2.value.match(/^[a-zA-Z0-9#'"،.,-;/ ]{1,200}$/)) {
        formIsValid = false;
        errors["billingAddressLineTwo"] = "*Please Enter Only Valid Characters (Max Range: 200)";
      }
    }

    //city

    if (typeof this.refs.b_city.value !== "undefined" && this.refs.b_city.value) {
      if (!this.refs.b_city.value.match(/^[a-zA-Z0-9#., ]{1,200}$/)) {
        formIsValid = false;
        errors["billingAddressCity"] = "*Please Enter Only Valid Characters (Max Range: 200)";
      }
    }

    //zip

    if (typeof this.refs.b_zip.value !== "undefined" && this.refs.b_zip.value) {
      if (!this.refs.b_zip.value.match(/^[a-zA-Z0-9 ]{1,35}$/)) {
        formIsValid = false;
        errors["billingAddressZip"] = "*Only Numbers Allowed (Max Range: 35)";
      }
    }

    //telephone

    if (typeof this.refs.b_telephone.value !== "undefined" && this.refs.b_telephone.value) {
      if (!this.refs.b_telephone.value.match(/^[0-9- ]{12,30}$/)) {
        formIsValid = false;
        errors["billingAddressTelephone"] = "*Please Enter only valid Telephone Number ( Range: 10)";
      }
    }

    //Ext

    if (typeof this.refs.b_ext.value !== "undefined" && this.refs.b_ext.value) {
      if (!this.refs.b_ext.value.match(/^[0-9]{1,20}$/)) {
        formIsValid = false;
        errors["billingAddressExt"] = "*Only Numbers Allowed (Max Range: 20)";
      }
    }
    //Fax

    if (typeof this.refs.b_fax.value !== "undefined" && this.refs.b_fax.value) {
      if (!this.refs.b_fax.value.match(/^[0-9- ]{12,30}$/)) {
        formIsValid = false;
        errors["billingAddressFax"] = "*Please Enter only valid fax Number ( Range: 10)";
      }
    }

    //email

    if (typeof this.refs.b_email.value !== "undefined" && this.refs.b_email.value) {
      if (!this.refs.b_email.value.match(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/)) {
        formIsValid = false;
        errors["billingAddressEmail"] = "*Please Enter Valid Email (Max Range: 100)";
      }
    }


    if (this._isMounted) {
      this.setState({
        errors: errors
      });
    }
    return formIsValid;


  }


  async componentDidMount() {

    this._isMounted = true;


    var arr = {
      countries: [],

    }

    var data = false;
    const token = localStorage.getItem('Token');
    if (this._isMounted) {
      await this.setState({ token: token });
    }

    await axios.post(BaseURL + 'carrierInformation/add', {
      'token': token
    })
      .then(response => {
        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem('Token', '');
          this.props.history.push('/');
        }
        arr = response.data.data ? response.data.data : arr;
        data = true;
      }).catch(function (error) {
        NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);
        console.log(error);
      })

    if (data && this._isMounted) {
      await this.setState({
        countries: arr.countries,
      });

    }


  }


  componentWillUnmount() {
    this._isMounted = false;

  }

  async states() {
    var arr;
    var data = false;

    await axios.post(BaseURL + 'countries/states', {
      "countryId": this.refs.country_id.value,
      'token': this.state.token
    })
      .then(response => {
        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem('Token', '');
          this.props.history.push('/');
        }
        arr = response.data.data;
        data = true;
      }).catch(function (error) {
        NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);
        console.log(error);
      })
    if (data && this._isMounted) {

      this.setState({ states: arr });
    }

  }


  async bstates() {
    var arr;
    var data = false;

    await axios.post(BaseURL + 'countries/states', {
      "countryId": this.refs.billing_country_id.value,
      'token': this.state.token
    })
      .then(response => {
        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem('Token', '');
          this.props.history.push('/');
        }
        arr = response.data.data;
        data = true;
      }).catch(function (error) {
        NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);
        console.log(error);
      })
    if (data && this._isMounted) {


      this.setState({ bstates: arr });

    }

  }


  async billaddress() {
    if (this.state.errorMsg === false) {

      document.getElementById('CarrierSameBillAddress').className = 'checked';

      if (this._isMounted) {
        await this.setState({ errorMsg: true });
      }
      var arr;
      await axios.post(BaseURL + 'countries/states', {
        "countryId": this.refs.country_id.value,
        'token': this.state.token
      })
        .then(response => {
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem('Token', '');
            this.props.history.push('/');
          }
          arr = response.data.data;
        }).catch(function (error) {
          NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);
          console.log(error);
        })

      if (this._isMounted) {
        await this.setState({ bstates: arr });
      }
      this.refs.billing.value = this.refs.address.value;
      this.refs.billing2.value = this.refs.address2.value;
      this.refs.billing_country_id.value = this.refs.country_id.value;
      this.refs.billing_province_id.value = this.refs.province_id.value;
      this.refs.b_city.value = this.refs.city.value;
      this.refs.b_zip.value = this.refs.zip.value;
      this.refs.b_email.value = this.refs.email.value;
      this.refs.b_telephone.value = this.refs.telephone.value;
      this.refs.b_fax.value = this.refs.fax.value;
      this.refs.b_ext.value = this.refs.ext.value;

    } else {
      document.getElementById('CarrierSameBillAddress').className = '';

      this.refs.billing.value = '';
      this.refs.billing2.value = '';
      this.refs.billing_country_id.value = '';
      this.refs.billing_province_id.value = '';
      this.refs.b_city.value = '';
      this.refs.b_zip.value = '';
      this.refs.b_email.value = '';
      this.refs.b_telephone.value = '';
      this.refs.b_fax.value = '';
      this.refs.b_ext.value = '';
      if (this._isMounted) {
        this.setState({
          errorMsg: false
        })
      }
    };


  }


  async submit() {

    if (this._isMounted) {
      this.setState({
        whileSave: true,

      })
    }


    const customerName = this.refs.customerName.value.trim();
    const dot_number = this.refs.dot_number.value.trim();
    const mc = this.refs.mc.value.trim();
    const legal = this.refs.legalname.value.trim();
    const scac = this.refs.scac.value.trim();
    const carb = this.refs.carb.value.trim();
    const fid = this.refs.fid.value.trim();
    const BusinessType = this.refs.businesstype.value;

    const address = this.refs.address.value.trim();
    const address2 = this.refs.address2.value.trim();
    const country_id = this.refs.country_id.value;
    const province_id = this.refs.province_id.value;
    const city = this.refs.city.value.trim();
    const zip = this.refs.zip.value.trim();
    const email = this.refs.email.value.trim();
    const telephone = this.refs.telephone.value.trim();
    const fax = this.refs.fax.value.trim();
    const ext = this.refs.ext.value.trim();

    const billing = this.refs.billing.value.trim();
    const billing2 = this.refs.billing2.value.trim();
    const billing_country_id = this.refs.billing_country_id.value;
    const billing_province_id = this.refs.billing_province_id.value;
    const b_city = this.refs.b_city.value.trim();
    const b_zip = this.refs.b_zip.value.trim();
    const b_email = this.refs.b_email.value.trim();
    const b_telephone = this.refs.b_telephone.value.trim();
    const b_fax = this.refs.b_fax.value.trim();
    const b_ext = this.refs.b_ext.value.trim();
    this.resetForm();
    if (this.state.errorMsg === false) {

      var customer = {

        'token': this.state.token,
        "status": this.state.status,
        'id': this.state.customerid,
        "carrierName": customerName,
        "dotNo": dot_number,
        "sameAsBillingAddress": 0,
        "mcNo": mc,
        'legalName': legal,
        'scacCode': scac,
        'carbNo': carb,
        "fidEinNo": fid,
        "businessTypeId": BusinessType,
        "address": {
          'id': this.state.addressid,
          "address": address,
          "addressLineTwo": address2,
          "city": city,
          "zip": zip,
          "email": email,
          "telephone": telephone,
          "ext": ext,
          "fax": fax,
          "state": {
            "id": province_id,
            "country": {
              "id": country_id

            }
          }
        },
        "billingAddress": {

          'id': this.state.billaddid,
          "address": billing,
          "addressLineTwo": billing2,
          "city": b_city,
          "zip": b_zip,
          "email": b_email,
          "telephone": b_telephone,
          "ext": b_ext,
          "fax": b_fax,
          "state": {
            "id": billing_province_id,
            "country": {
              "id": billing_country_id
            }
          }
        }
      }




      await axios.post(BaseURL + 'carrierInformation/save', customer)
        .then(response => {
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem('Token', '');
            this.props.history.push('/');
          }
          console.log(response.data);
          NotificationManager.success('New Carrier Added Successfully with New Billing Address', 'Added', 3000);
        }).catch(function (error) {
          NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);
          console.log(error);
        })

      if (this._isMounted) {
        this.setState({
          whileSave: false,

        });
      }


    }

    else {

      var billcustomer = {

        'token': this.state.token,
        "status": this.state.status,
        'id': this.state.customerid,
        "carrierName": customerName,
        "dotNo": dot_number,
        "sameAsBillingAddress": 1,
        "mcNo": mc,
        'legalName': legal,
        'scacCode': scac,
        'carbNo': carb,
        "fidEinNo": fid,
        "businessTypeId": BusinessType,
        "address": {
          'id': this.state.addressid,
          "address": address,
          "addressLineTwo": address2,
          "city": city,
          "zip": zip,
          "email": email,
          "telephone": telephone,
          "ext": ext,
          "fax": fax,
          "state": {
            "id": province_id,
            "country": {
              "id": country_id

            }
          }
        },
        "billingAddress": {

          'id': this.state.billaddid,
          "address": billing,
          "addressLineTwo": billing2,
          "city": b_city,
          "zip": b_zip,
          "email": b_email,
          "telephone": b_telephone,
          "ext": b_ext,
          "fax": b_fax,
          "state": {
            "id": billing_province_id,
            "country": {
              "id": billing_country_id
            }
          }
        }
      }





      await axios.post(BaseURL + 'carrierInformation/save', billcustomer)
        .then(response => {
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem('Token', '');
            this.props.history.push('/');
          }
          console.log(response.data);
          NotificationManager.success('New Carrier Added Successfully', 'Added', 3000);
        }).catch(function (error) {
          NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);
          console.log(error);
        })

      if (this._isMounted) {
        this.setState({
          whileSave: false
        })
      }



    }

  }

  resetForm = () => {
    $('#closePopUpCarrier').click();
    this.refs.carrierform.reset();
    this.setState({ states: [], bstates: [], status: 1 });
    document.getElementById('CarrierSameBillAddress').className = '';
    $("#carrierActiveLabel").addClass('active');
    $("#carrierNonActiveLabel").removeClass('active');
  }

  render() {


    const msgShow = {
      display: 'block',
    }



    return (
      <div className="modal-content">
        <div className="modal-header">
          <button type="button" className="close" id='closePopUpCarrier' aria-hidden="true" data-dismiss="modal" hidden={true}></button>
          <button type="button" className="close" onClick={this.resetForm} aria-hidden="true" ></button>
          <h4 className="modal-title">Carrier Info</h4>
        </div>
        <div className="modal-body">

          <div className="portlet-body form">

            <form onSubmit={this.submituserRegistrationForm.bind(this)} className="horizontal-form" autoComplete="off" ref='carrierform'>
              <div className="form-body max_width800">
                <div className="portlet-title">
                  <h2 className="h3"> Carrier Info
                    <span style={{ float: 'right', paddingLeft: ' 8px', marginTop: '2px' }} >
                      <div className="btn-group btn-group-devided" data-toggle="buttons" >
                        <label className="btn btn-transparent dark btn-outline btn-circle btn-sm active" id='carrierActiveLabel' onClick={() => { if (this._isMounted) { this.setState({ status: 1 }) } }}>
                          <input type="radio" name="options" className="toggle" id="option1" />
                          Active</label>
                        <label className="btn btn-transparent dark btn-outline btn-circle btn-sm" id='carrierNonActiveLabel' onClick={() => { if (this._isMounted) { this.setState({ status: 0 }) } }}>
                          <input type="radio" name="options" className="toggle" id="option2" />
                          Inactive</label>
                      </div>
                    </span>
                  </h2>
                  <div className="clearfix"></div>
                  <hr />
                </div>
                <div id="abcc" className="form-group row">
                  <label className="col-md-3 control-label">Carrier Name (DBA): <span className="font-red-thunderbird" >*</span></label>
                  <div className="col-md-9">
                    <input type="text" ref="customerName" onChange={this.handleChange} name="customerName" className="form-control" placeholder="" />
                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.customerName}</div>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 control-label">Legal Name</label>
                  <div className="col-md-9">
                    <input type="text" ref="legalname" onChange={this.handleChange} name="legalname" className="form-control" placeholder="" />
                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.legalname}</div>
                  </div>

                </div>
                <div className="form-group row">
                  <label className="col-md-3 control-label">M.C.#  <span className="font-red-thunderbird" >*</span> </label>
                  <div className="col-md-9">
                    <input type="text" ref="mc" name='mcNo' onChange={this.handleChange} className="form-control" placeholder="" />
                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.mcNo}</div>
                    <div
                      style={{ color: "red" }}
                      className="errorMsg"
                    >
                      {this.state.errors.mcInternalload}
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 control-label">DOT #</label>
                  <div className="col-md-9">
                    <input type="text" ref="dot_number" onChange={this.handleChange} name="dotNo" className="form-control" placeholder="" />
                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.dotNo}</div>
                  </div>

                </div>
                <div className="form-group row">
                  <label className="col-md-3 control-label">SCAC Code</label>
                  <div className="col-md-9">
                    <input type="text" ref="scac" name='scac' onChange={this.handleChange} className="form-control" placeholder="" />
                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.scac}</div>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 control-label">CARB#</label>
                  <div className="col-md-9">
                    <input type="text" ref="carb" name='carb' onChange={this.handleChange} className="form-control" placeholder="" />
                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.carb}</div>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-md-3 control-label">Business Type:<span className="font-red-thunderbird" >*</span></label>
                  <div className="col-md-9" >


                    <select ref="businesstype" name="businesstype" className="form-control" defaultValue=''   >

                      <option value="" disabled hidden>Select Business Type</option>

                      <option value='1'> Individual/sole proprietor </option>
                      <option value='2'> S Corporation </option>
                      <option value='3'>  C Corporation </option>
                      <option value='4'> LLC </option>

                    </select>

                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.businesstype}</div>

                  </div>
                </div>


                <div className="form-group row">
                  <label className="col-md-3 control-label">FID / EIN #</label>
                  <div className="col-md-9">
                    <input type="text" ref="fid" name='fid' id='fid' onChange={this.AutoFormatEIN} className="form-control" placeholder="xx-xxxxxxx" />
                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.fid}</div>
                  </div>
                </div>




                <div className="portlet-title">
                  <h2 className="h3"> Address Info </h2>
                  <hr />
                </div>
                <div className="form-group row">
                  <label className="col-md-3 control-label">Address <span className="font-red-thunderbird" >*</span></label>
                  <div className="col-md-9">
                    <input type="text" ref="address" onChange={this.handleChange} name="address" className="form-control" placeholder="" />
                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.address}</div>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 control-label">Address Line 2</label>
                  <div className="col-md-9">
                    <input type="text" ref="address2" onChange={this.handleChange} name="addressLineTwo" className="form-control" placeholder="" />
                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.addressLineTwo}</div>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-md-3 control-label">Country <span className="font-red-thunderbird" >*</span></label>
                  <div className="col-md-9">


                    <select ref="country_id" name="country" className="form-control" defaultValue='' title="Country" onChange={this.states.bind(this)}    >

                      <option value="" disabled hidden>Select Country</option>
                      {
                        this.state.countries.map(function (data) {
                          return <option value={data.id} key={data.id}> {data.countryName} </option>;
                        })

                      }




                    </select>
                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.country}</div>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 control-label">State <span className="font-red-thunderbird" >*</span></label>
                  <div className="col-md-9">
                    <select ref="province_id" name="state" className="form-control" defaultValue='1'     >

                      {
                        this.state.states.map(function (data) {
                          return <option value={data.id} key={data.id}> {data.state} </option>;
                        })

                      }
                    </select>
                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.state}</div>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 control-label">City <span className="font-red-thunderbird" >*</span></label>
                  <div className="col-md-9">
                    <input type="text" ref="city" onChange={this.handleChange} name="city" className="form-control" placeholder="" />
                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.city}</div>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 control-label">Zip <span className="font-red-thunderbird" >*</span></label>
                  <div className="col-md-9">
                    <input type="text" ref="zip" onChange={this.handleChange} name="zip" className="form-control" placeholder="" />
                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.zip}</div>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 control-label">Email</label>
                  <div className="col-md-9">
                    <input type="email" ref="email" onChange={this.handleChange} name="email" className="form-control" placeholder="" />
                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.email}</div>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 control-label">Telephone</label>
                  <div className="col-md-9 form-inline">
                    <input type="text" ref="telephone" id='phone' onChange={this.AutoFormatContact} name="telephone" className="form-control" placeholder="xxx-xx-xxxx" />

                    <span className="help-inline"> EXT </span>  <input type="text" name="ext" onChange={this.handleChange} ref="ext" className="form-control" placeholder="" />
                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.telephone}</div>
                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.ext}</div>

                  </div>
                </div>





                <div className="form-group row">
                  <label className="col-md-3 control-label">Fax</label>
                  <div className="col-md-9">
                    <input type="text" ref="fax" name="fax" id='fax' onChange={this.AutoFormatContact} className="form-control" placeholder="xxx-xxx-xxxx" />
                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.fax}</div>
                  </div>
                </div>
                <div className="portlet-title">
                  <h2 className="h3 checkbox-inline" style={{ padding: '0', margin: '0' }}> Billing Address  </h2>
                  <label className="checkbox-inline">
                    <div className="checkbox"><span>
                      <div className="checker" >
                        <span className='' id='CarrierSameBillAddress'>
                          <input type="checkbox" name="optionsRadios2" value="option1" onClick={this.billaddress.bind(this)} />
                        </span>
                      </div>
                    </span> Same as Mailing Address</div>
                  </label>
                  <hr />
                </div>


                <span style={this.state.errorMsg === true ? msgShow : msgShow}>
                  <div className="form-group row">
                    <label className="col-md-3 control-label">Address <span className="font-red-thunderbird" >*</span></label>
                    <div className="col-md-9">
                      <input type="text" ref="billing" onChange={this.handleChange} name="billingAddress" className="form-control" placeholder="" />
                      <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.billingAddress}</div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3 control-label">Address Line 2 </label>
                    <div className="col-md-9">
                      <input type="text" onChange={this.handleChange} name="billingAddressLineTwo" ref="billing2" className="form-control" placeholder="" />
                      <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.billingAddressLineTwo}</div>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-md-3 control-label">Country <span className="font-red-thunderbird" >*</span></label>
                    <div className="col-md-9">
                      <select name="billingAddressCountry" ref="billing_country_id" defaultValue='' className="form-control" title="Country" onChange={this.bstates.bind(this)}    >
                        <option value="" disabled hidden>Select Country</option>
                        {
                          this.state.countries.map(function (data) {
                            return <option value={data.id} key={data.id}> {data.countryName} </option>;
                          })

                        }
                      </select>
                      <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.billingAddressCountry}</div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3 control-label">State <span className="font-red-thunderbird" >*</span></label>
                    <div className="col-md-9">
                      <select name="billingAddressState" ref="billing_province_id" defaultValue='1' className="form-control"     >

                        {
                          this.state.bstates.map(function (data) {
                            return <option value={data.id} key={data.id}> {data.state} </option>;
                          })

                        }
                      </select>
                      <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.billingAddressState}</div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3 control-label">City <span className="font-red-thunderbird" >*</span></label>
                    <div className="col-md-9">
                      <input type="text" ref="b_city" onChange={this.handleChange} name="billingAddressCity" className="form-control" placeholder="" />
                      <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.billingAddressCity}</div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3 control-label">Zip <span className="font-red-thunderbird" >*</span></label>
                    <div className="col-md-9">
                      <input type="text" ref="b_zip" onChange={this.handleChange} name="billingAddressZip" className="form-control" placeholder="" />
                      <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.billingAddressZip}</div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3 control-label">Email</label>
                    <div className="col-md-9">
                      <input type="email" ref="b_email" onChange={this.handleChange} name="billingAddressEmail" className="form-control" placeholder="" />
                      <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.billingAddressEmail}</div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3 control-label">Telephone</label>
                    <div className="col-md-9 form-inline">
                      <input type="text" id='phone2' onChange={this.AutoFormatContact} name="billingAddressTelephone" ref="b_telephone" className="form-control" placeholder="xxx-xxx-xxxx" />

                      <span className="help-inline"> EXT </span>  <input name="billingAddressExt" onChange={this.handleChange} type="text" ref="b_ext" className="form-control" placeholder="" />
                      <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.billingAddressTelephone}</div>
                      <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.billingAddressExt}</div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3 control-label">Fax</label>
                    <div className="col-md-9">
                      <input type="text" ref="b_fax" id='billfax' onChange={this.AutoFormatContact} name="billingAddressFax" className="form-control" placeholder="xxx-xxx-xxxx" />
                      <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.billingAddressFax}</div>
                    </div>
                  </div>
                </span>

              </div>
              <div className="clearfix"></div>
              <div className="form-actions right">
                <button type="button" onClick={this.resetForm} className="btn default">Cancel</button> &nbsp;
                <button
                  type="submit"
                  className="btn green-meadow"
                  disabled={this.state.saveBtnDisable == false}
                >
                  {this.state.whileSave === false
                    ? <i className='fa fa-save'></i>
                    : <i className="fa fa-circle-o-notch fa-spin"></i>}
                  Save
                </button>
              </div>

            </form>
          </div>


        </div>
      </div>

    );
  }
}

export default withRouter(Addcarrier);