import React, { PureComponent } from 'react';

import { compose, withProps } from "recompose";
import {
    withGoogleMap,
    GoogleMap,
    DirectionsRenderer
} from "react-google-maps";


class Map extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            directions: ''
        };
    }

    // componentDidMount() {
    // }

    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.soureLatLng !== this.props.soureLatLng) || (prevProps.destinationLatLng !== this.props.destinationLatLng) || (prevProps.wayPoints.length !== this.props.wayPoints.length)) {
            this.getFormattedDirections(this.props.soureLatLng, this.props.destinationLatLng, this.props.wayPoints);

        }
    }

    getFormattedDirections = (soureLatLng, destinationLatLng, wayPoints) => {

        var waypts = [];

        wayPoints.forEach(element => {
            if (element.LatLng) {
                waypts.push({
                    location: new window.google.maps.LatLng(
                        parseFloat(element.LatLng.lat),
                        parseFloat(element.LatLng.lng)
                    ),
                    // stopover: true
                });
            }
        });


        if (soureLatLng && destinationLatLng) {
            const DirectionsService = new window.google.maps.DirectionsService();
            DirectionsService.route({
                origin: new window.google.maps.LatLng(parseFloat(soureLatLng.lat), parseFloat(soureLatLng.lng)),
                destination: new window.google.maps.LatLng(parseFloat(destinationLatLng.lat), parseFloat(destinationLatLng.lng)),
                waypoints: waypts,
                optimizeWaypoints: true,
                travelMode: window.google.maps.TravelMode.DRIVING,
            }, (response, status) => {
                if (status === window.google.maps.DirectionsStatus.OK) {

                    this.setState({
                        directions: response,
                    });

                    var total_distance = 0.0;
                    for (var i = 0; i < response.routes[0].legs.length; i++) {
                        total_distance += response.routes[0].legs[i].distance.value;
                    }

                    var total_miles = (total_distance * 0.000621371192).toFixed(2);
                    this.props.setTotalMiles(total_miles);
                    this.props.setDurationToReach && this.props.setDurationToReach(response.routes[0].legs[0].duration)

                }
                else {
                    // reject("error");
                    console.error(`error fetching directions ${response}`);
                }
            });
        }
    }

    render() {

        const { soureLatLng, destinationLatLng } = this.props;

        return (
            <GoogleMap
                defaultZoom={4}
                defaultCenter={new window.google.maps.LatLng(40.730610, -73.935242)}
                options={{ gestureHandling: "greedy" }}
            >
                {soureLatLng && destinationLatLng &&
                    <DirectionsRenderer
                        directions={this.state.directions}
                        // options={{
                        //     draggable:true
                        // }}
                        // onDirectionsChanged={
                        //     (a, b, c, d) => {
                        //         console.log(a, b, c, d)
                        //     }
                        // }

                    />}
            </GoogleMap>
        )
    }
}

// const MyMapComponent = compose(
//     withProps({
//       loadingElement: <div style={{ height: `100%` }} />,
//       containerElement: <div style={{ height: `calc(100vh - 119px)` , paddingLeft: "85px" }} />,
//       mapElement: <div style={{ height: `100%` }} />,
//     }),
//     withGoogleMap,
//     lifecycle({
//       componentDidMount() {
//         const DirectionsService = new google.maps.DirectionsService();

//         DirectionsService.route({
//           origin: new google.maps.LatLng(31.8095, 74.2534),
//           destination: new google.maps.LatLng(31.4697, 74.2728),
//           travelMode: google.maps.TravelMode.DRIVING,
//         }, (result, status) => {
//           console.log(result)
//           console.log(status)
//           if (status === google.maps.DirectionsStatus.OK) {
//             this.setState({
//               directions: result,
//             });
//           } else {
//             console.error(`error fetching directions ${result}`);
//           }
//         });
//       }
//     })
//   )(props =>
//     <GoogleMap
//       defaultZoom={15}
//       defaultCenter={new google.maps.LatLng(31.5204,  74.3587)}
//     >
//       {props.directions && 
//       <DirectionsRenderer 
//       directions={props.directions}   
//        />}
//     </GoogleMap>
//   );

export default compose(
    withProps({
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: '100%', width: '100%' }} />,
        mapElement: <div style={{ height: `100%` }} />
    }),
    withGoogleMap,
)(Map);