import React from "react";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import MuiTablePagination from "@material-ui/core/TablePagination";


class CustomFooter extends React.Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }

    TablePaginationActions = (tableArg) => {

        const { handleFirstPageOffset, handleLastPageOffset } = this.props;
        const { count, page, rowsPerPage, onChangePage } = tableArg;

        function handleFirstPageButtonClick(event) {
            handleFirstPageOffset(0)
            onChangePage(event, 0);
        }

        function handleBackButtonClick(event) {
            onChangePage(event, page - 1);
        }

        function handleNextButtonClick(event) {
            onChangePage(event, page + 1);
        }

        function handleLastPageButtonClick(event) {
            let total = count
            let divide = rowsPerPage
            let result = parseInt(total / divide);
            result = result - 1
            let finalOffset = result * divide
            handleLastPageOffset(finalOffset)
            onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        }

        return (
            <div>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="first page"
                >
                    <FirstPageIcon />
                </IconButton>
                <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                    <KeyboardArrowLeft />
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    <KeyboardArrowRight />
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                >
                    <LastPageIcon />
                </IconButton>
            </div>
        );
    }

    handleRowChange = event => {
        this.props.changeRowsPerPage(event.target.value);
    };

    handlePageChange = (_, page) => {
        this.props.changePage(page);
    };

    render() {
        const { count, rowsPerPage, page } = this.props;

        return (
            <TableFooter>
                <TableRow>
                    <TableCell>
                        <MuiTablePagination
                            component="div"
                            count={count}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            rowsPerPageOptions={[25, 50, 100]}
                            onChangePage={this.handlePageChange}
                            onChangeRowsPerPage={this.handleRowChange}
                            ActionsComponent={this.TablePaginationActions}
                        />
                    </TableCell>
                </TableRow>
            </TableFooter>
        );
    }

}

export default CustomFooter

// this.tablePaginationActions(data)
