import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import Userlogin from "./UserLogin";
import Pagecontent from "./PageContents1";
import Addcustomer from "./AddCustomer";
import EditCustomer from "./EditCustomer";
import InsuranceAgent from "./insuranceagent";
import Addagent from "./Addagent";
import Editagent from "./Editagent";
import Carrier from "./carrier";
import Addcarrier from "./Addcarrier";
import Editcarrier from "./Editcarrier";
import Driver from "./driver";
import AddDriver from "./Adddriver";
import EditDriver from "./Editdriver";
import Truck from "./truck";
import AddTruck from "./Addtruck";
import EditTruck from "./EditTruck";
import Trailer from "./trailer";
import AddTrailer from "./Addtrailer";
import EditTrailer from "./Edittrailer";
import Profile from "./Profile";
import Admin from "./Admin";
import AddUser from "./AddUser";
import EditUser from "./EditUser";
import TenantUsers from "./TenantUsers";
import AddTenantUser from "./AddTenantUser";
import EditTenantUser from "./EditTenantUser";
import Shipper from "./shipper";
import AddShipper from "./AddShipper";
import EditShipper from "./EditShipper";
import Load from "./Load";
import AddLoad from "./AddLoad";
import EditLoad from "./EditLoad";
import FactoringComapny from "./factoringCompany";
import AddFactoringComapny from "./AddFactoringCompany";
import EditFactoringCompany from "./EditFactoringCompany";
import Accounting from "./accounting";
import Billing from "./Billing";
import Tools from "./Tools";
import SearchLoad from "./SearchLoad";
import QuickAddLoad from "./QuickAddLoad";
import PickupDelieveryDetails from "./PickupDelieveryDetails";
import EditPickUpAndDelieveryDetails from "./EditPickUpAndDelieveryDetails";
// const Userlogin = React.lazy( async () => await import('./UserLogin'));
// const Pagecontent = React.lazy( async () => await import('./PageContents1'));
// const Addcustomer = React.lazy(async () => await import('./AddCustomer'));
// const EditCustomer = React.lazy(async () => await import('./EditCustomer'));
// const InsuranceAgent = React.lazy(async () => await import('./insuranceagent'));
// const Addagent = React.lazy(async () => await import('./Addagent'));
// const Editagent = React.lazy(async () => await import('./Editagent'));
// const Carrier = React.lazy(async () => await import('./carrier'));
// const Addcarrier = React.lazy(async () => await import('./Addcarrier'));
// const Editcarrier = React.lazy(async () => await import('./Editcarrier'));
// const Driver = React.lazy(async () => await import('./driver'));
// const AddDriver = React.lazy(async () => await import('./Adddriver'));
// const EditDriver = React.lazy(async () => await import('./Editdriver'));
// const Truck = React.lazy(async () => await import('./truck'));
// const AddTruck = React.lazy(async () => await import('./Addtruck'));
// const EditTruck = React.lazy(async () => await import('./EditTruck'));
// const Trailer = React.lazy(async () => await import('./trailer'));
// const AddTrailer = React.lazy(async () => await import('./Addtrailer'));
// const EditTrailer = React.lazy(async () => await import('./Edittrailer'));
// const Profile = React.lazy(async () => await import('./Profile'));
// const Admin = React.lazy(async () => await import('./Admin'));
// const AddUser = React.lazy(async () => await import('./AddUser'));
// const EditUser = React.lazy(async () => await import('./EditUser'));
// const TenantUsers = React.lazy(async () => await import('./TenantUsers'));
// const AddTenantUser = React.lazy(async () => await import('./AddTenantUser'));
// const EditTenantUser = React.lazy(async () => await import('./EditTenantUser'));
// const Shipper = React.lazy(async () => await import('./shipper'));
// const AddShipper = React.lazy(async () => await import('./AddShipper'));
// const EditShipper = React.lazy(async () => await import('./EditShipper'));
// const Load = React.lazy(async () => await import('./Load'));
// const AddLoad = React.lazy(async () => await import('./AddLoad'));
// const EditLoad = React.lazy(async () => await import('./EditLoad'));

const Cryptr = require("cryptr");
const cryptr = new Cryptr("@$#rgweR35*&YFSD)/");

var AdminRedirect, DispatchRedirect, AccountingRedirect;

const AdminRoutes = [
  {
    path: "/Admin",
    exact: true,
    page_content: () => <Admin />,
  },
  {
    path: "/AddUser",
    exact: true,
    page_content: () => <AddUser />,
  },
  {
    path: "/EditUser/:id",
    exact: true,
    page_content: () => <EditUser />,
  },
  {
    path: "/TenantUsers",
    exact: true,
    page_content: () => <TenantUsers />,
  },
  {
    path: "/Add-Tenant-User",
    exact: true,
    page_content: () => <AddTenantUser />,
  },
  {
    path: "/Edit-Tenant-User/:id",
    exact: true,
    page_content: () => <EditTenantUser />,
  },
  {
    path: "/Pickup-Deleivery-Details",
    exact: true,
    page_content: () => <PickupDelieveryDetails />,
  }
];

const routes = [
  {
    path: "/dashboard",
    exact: true,
    page_content: () => <Pagecontent />,
  },

  {
    path: "/AddCustomer",
    exact: true,
    page_content: () => <Addcustomer />,
  },
  {
    path: "/EditCustomer/:id",
    exact: true,
    page_content: () => <EditCustomer />,
  },
  {
    path: "/AddAgent",
    exact: true,
    page_content: () => <Addagent />,
  },
  {
    path: "/AddCarrier",
    exact: true,
    page_content: () => <Addcarrier />,
  },

  {
    path: "/AddDriver",
    exact: true,
    page_content: () => <AddDriver />,
  },
  {
    path: "/AddTruck",
    exact: true,
    page_content: () => <AddTruck />,
  },
  {
    path: "/AddTrailer",
    exact: true,
    page_content: () => <AddTrailer />,
  },
  {
    path: "/Profile",
    exact: true,
    page_content: () => <Profile />,
  },
  {
    path: "/shipper",
    exact: true,
    page_content: () => <Shipper />,
  },
  {
    path: "/AddShipper",
    exact: true,
    page_content: () => <AddShipper />,
  },
  {
    path: "/EditShipper/:id",
    exact: true,
    page_content: () => <EditShipper />,
  },
  {
    path: "/AddLoad",
    exact: true,
    page_content: () => <AddLoad />,
  },
  {
    path: "/QuickAddLoad",
    exact: true,
    page_content: () => <QuickAddLoad />,
  },
  {
    path: "/factoringCompany",
    exact: true,
    page_content: () => <FactoringComapny />,
  },
  {
    path: "/AddFactoringComapny",
    exact: true,
    page_content: () => <AddFactoringComapny />,
  },
  {
    path: "/EditFactoringCompany/:id",
    exact: true,
    page_content: () => <EditFactoringCompany />,
  },
  // {
  //   path: "/accounting",
  //   exact: true,
  //   page_content: () => <Accounting />,
  // },
  {
    path: "/billing",
    exact: true,
    page_content: () => <Billing />,
  },
  {
    path: "/Tools",
    exact: true,
    page_content: () => <Tools />,
  },
  {
    path: "/searchLoad",
    exact: true,
    page_content: () => <SearchLoad />,
  },

];

const TenantUserRoutes = [
  {
    path: "/carrier",
    exact: true,
    page_content: () => <Carrier />,
  },
  {
    path: "/insuranceAgent",
    exact: true,
    page_content: () => <InsuranceAgent />,
  },
  {
    path: "/driver",
    exact: true,
    page_content: () => <Driver />,
  },
  {
    path: "/truck",
    exact: true,
    page_content: () => <Truck />,
  },
  {
    path: "/trailer",
    exact: true,
    page_content: () => <Trailer />,
  },
  {
    path: "/EditAgent/:id",
    exact: true,
    page_content: () => <Editagent />,
  },
  {
    path: "/EditCarrier/:id",
    exact: true,
    page_content: () => <Editcarrier />,
  },
  {
    path: "/EditDriver/:id",
    exact: true,
    page_content: () => <EditDriver />,
  },
  {
    path: "/EditTruck/:id",
    exact: true,
    page_content: () => <EditTruck />,
  },
  {
    path: "/EditTrailer/:id",
    exact: true,
    page_content: () => <EditTrailer />,
  },
  {
    path: "/Load",
    exact: true,
    page_content: () => <Load />,
  },
  {
    path: "/EditLoad/:id/:from?",
    exact: true,
    page_content: () => <EditLoad />,
  },
  {
    path: "/EditPickUpAndDelieveryDetails/:id/:from?",
    exact: true,
    page_content: () => <EditPickUpAndDelieveryDetails />,
  }
];

const AccountingRoutes = [
  {
    path: "/accounting",
    exact: true,
    page_content: () => <Accounting />,
  },
];


const checkAuth = () => {
  const token = localStorage.getItem("Token");
  const Role = localStorage.getItem("Role");
  var role = "";

  if (Role) {
    role = cryptr.decrypt(Role);
  }

  if (
    !token ||
    (role !== "Admin" && role !== "Dispatcher" && role !== "Tenant" && role !== "Accounting")
  ) {
    return false;
  }
  return true;
};

const AuthRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      checkAuth() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/" }} />
      )
    }
  />
);

const checkAccountingAuth = () => {
  const token = localStorage.getItem("Token");
  const Role = localStorage.getItem("Role");

  var role = "";
  if (Role) {
    role = cryptr.decrypt(Role);
  }

  if (role === "Dispatcher") {
    AccountingRedirect = "/dashboard";
    return false;
  } else if (role === "Tenant") {
    AccountingRedirect = "/carrier";
    return false;
  } else if (
    !token ||
    (role !== "Admin" && role !== "Dispatcher" && role !== "Tenant" && role !== "Accounting")
  ) {
    AccountingRedirect = "/";
    return false;
  }
  return true;
};

const checkAdminAuth = () => {
  const token = localStorage.getItem("Token");
  const Role = localStorage.getItem("Role");

  var role = "";
  if (Role) {
    role = cryptr.decrypt(Role);
  }

  if (role === "Accounting") {
    AdminRedirect = "/dashboard";
    return false;
  } else if (role === "Dispatcher") {
    AdminRedirect = "/dashboard";
    return false;
  } else if (role === "Tenant") {
    AdminRedirect = "/carrier";
    return false;
  } else if (
    !token ||
    (role !== "Admin" && role !== "Dispatcher" && role !== "Tenant" && role !== "Accounting")
  ) {
    AdminRedirect = "/";
    return false;
  }
  return true;
};

const AdminAuthRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      checkAdminAuth() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: AdminRedirect }} />
      )
    }
  />
);
const AccountingAuthRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      checkAccountingAuth() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: AccountingRedirect }} />
      )
    }
  />
);

const checkDispatchAuth = () => {

  const token = localStorage.getItem("Token");
  const Role = localStorage.getItem("Role");
  var role = "";

  if (Role) {
    role = cryptr.decrypt(Role);
  }

  if (role === "Dispatcher") {
    if (role === "Accounting") {
      DispatchRedirect = "/dashboard";
      return false;
    } else if (role === "Tenant") {
      DispatchRedirect = "/carrier";
      return false;
    } else if (
      !token ||
      (role !== "Admin" && role !== "Dispatcher" && role !== "Tenant" && role !== "Accounting")
    ) {
      DispatchRedirect = "/";
      return false;
    }
    return true;
  } else {
    return true;
  }
};

const DispatchAuthRoute = ({ component: Component, auth, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      checkDispatchAuth() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: DispatchRedirect }} />
      )
    }
  />
);

export default class App extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {

    return (
      <Router>
        {/* <Suspense  fallback={<div>Loading....</div>}> */}
        <Switch>
          <Route exact path="/" render={(props) => <Userlogin {...props} />} />

          {TenantUserRoutes.map((route, index) => (
            <AuthRoute
              key={index}
              path={route.path}
              exact={route.exact}
              component={route.page_content}
            />
          ))}

          {routes.map((route, index) => {
            return (
              <DispatchAuthRoute
                key={index}
                path={route.path}
                exact={route.exact}
                component={route.page_content}
              />
            )
          })}

          {AccountingRoutes.map((route, index) => (
            <AccountingAuthRoute
              key={index}
              path={route.path}
              exact={route.exact}
              component={route.page_content}
            />
          ))}


          {AdminRoutes.map((route, index) => (
            <AdminAuthRoute
              key={index}
              path={route.path}
              exact={route.exact}
              component={route.page_content}
            />
          ))}

          <Route
            exact
            path="/*"
            render={(props) => (
              <h2 style={{ marginLeft: "50px" }}>404 Page Not Found </h2>
            )}
          />
        </Switch>
        {/* </Suspense> */}
      </Router>
    );
  }
}
