/* eslint-disable */

import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import MUiTable from "./MuiTable";
import axios from 'axios';
import './table.css';
import Sidebar from './sidebar';
import Header from './Head';
import f from './Loading_icon.gif';
import BaseURL from './config';

import { NotificationManager } from 'react-notifications';
import TokenErrorMsg from './errorMsg';
import Tick from './green-tick.jpg';
import Cross from './red-cross.jpg';

import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from "@material-ui/core/MenuItem";
import { getPersistentTableState, setPersistentTableState } from './LocalStorage';


window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

class Pagecontent extends Component {

  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      status: 2,
      isLoading: false,
      page: 0,
      rowsPerPage: 50,
      btnVal: "Show All",
      searchVal: false,
      token: '',
      open: false,
      offset: 0,
      limit: 50,
      order: 'ASC',
      sortByColumn: 'customerName',
      count: '',
      search: ''
    };
    this.startEditing = this.startEditing.bind(this);


  };

  columns = [
    {
      name: "ID",
      options: {
        display: false,
      }
    },
    {
      name: "customerName",
      label: "Company Name", options: {
        sort: true,
      }
    },
    {
      name: "address",
      label: "Address",
      options: {
        sort: true,
      }
    },
    {
      name: "telephone",
      label: "Telephone", options: {
        sort: true,
      }
    },
    {
      name: "Status", options: {
        sort: false,
      }
    }
  ];

  handleToggle = event => {

    if (this._isMounted) {
      this.setState(state => ({ open: !state.open, anchorEl: event.currentTarget }));
    }

  };

  handleClose = event => {

    if (this.anchorEl.contains(event.target)) {
      return;
    }
    if (this._isMounted) {
      this.setState({ open: false });
    }

  };


  async componentDidMount() {

    this._isMounted = true;
    const token = localStorage.getItem('Token');
    const tableState = getPersistentTableState('customer');

    this.columns.find(col => col.name === tableState.sortByColumn)
    .options
    .sortDirection = tableState.order.toLowerCase();

    if (this._isMounted) {
      await this.setState({
        token: token,
        order: tableState.order,
        sortByColumn: tableState.sortByColumn,
        btnVal: tableState.btnVal,
        status: tableState.status,
        search: tableState.search,
        isLoading: true,
        searchVal: tableState.search ? true : false,
        page: tableState.page,
        offset: tableState.offset,
        limit: tableState.limit,
        rowsPerPage: tableState.limit,
      });
      tableState.search ? this.search() : this.fetchPaginationData();
    }

  }

  componentWillUnmount() {
    this._isMounted = false
  }

  async active(theme, e) {

    if (this._isMounted) {
      this.refs.search.value = '';

      await this.setState({
        searchVal: false,
        isLoading: true,
        status: 1,
        open: false,
        anchorEl: null,
        btnVal: theme,
        page: 0,
        offset: 0,
        order: 'ASC',
        sortByColumn: 'customerName',
        search: '',
      })
      this.fetchPaginationData();

    }
  }

  async Inactive(theme, e) {

    if (this._isMounted) {
      this.refs.search.value = '';

      await this.setState({
        searchVal: false,
        isLoading: true,
        status: 0,
        open: false,
        anchorEl: null,
        btnVal: theme,
        page: 0,
        offset: 0,
        order: 'ASC',
        sortByColumn: 'customerName',
        search: '',
      })
      this.fetchPaginationData();

    }
  }

  async search() {

    if (this.refs.search.value.trim()) {

      var arr = [], count;
      var status;

      this.setLocalStorage();

      await axios.post(BaseURL + 'customer/findByName', {
        "searchKeyword": this.refs.search.value.trim(),
        "offset": this.state.offset,
        "limit": this.state.limit,
        "sortOrder": this.state.order,
        "sortBy": this.state.sortByColumn,
        "status": this.state.status,
        'token': this.state.token
      }).then(response => {
        console.log(response)
        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem('Token', '');
          this.props.history.push('/');
        }

        count = response.data.data.count;

        for (const key in response.data.data.rows) {
          if (response.data.data.rows.hasOwnProperty(key)) {
            const element = response.data.data.rows[key];

            if (element.status === 1) {
              status = <img style={{ width: "15px", height: "15px" }} src={Tick} alt='Active' />
            }
            else {
              status = <img style={{ width: "15px", height: "15px" }} src={Cross} alt='Inactive' />
            };


            var Obj = [
              element.id,
              element.customerName,
              element.address.address + ', ' + (element.address.addressLineTwo ? element.address.addressLineTwo + ', ' : '') + element.address.city + ', ' + element.address.state.state + ', ' + element.address.zip,
              element.address.telephone,
              status,


            ]

            arr.push(Obj);

          }
        }
      }).catch(function (error) {
        NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);
        console.log(error);
      })

      if (this._isMounted) {
        this.setState({ data: arr, isLoading: false, count });
      }

    }
    else {
      alert('Please Type Something in Search Bar')
    }



  }

  async showall(theme, e) {

    this.refs.search.value = '';    
    if (this._isMounted) {

      await this.setState({
        searchVal: false,
        isLoading: true,
        status: 2,
        open: false,
        anchorEl: null,
        btnVal: theme,
        page: 0,
        offset: 0,
        order: 'ASC',
        sortByColumn: 'customerName',
        search:''
      })
      this.fetchPaginationData();

    }


  }

  startEditing(colData, cellMeta) {

    if (window.getSelection().toString()) {
      return false;
    }
    else if (cellMeta.colIndex === 1) {
      var data = this.state.data;
      var id = data[cellMeta.dataIndex][0]
      // console.log('Cell meta ',colData,cellMeta,data[cellMeta.dataIndex])
      this.props.history.push('/EditCustomer/' + id);
    }

  }

  handleSearch = async (e) => {

    if (this.refs.search.value) {
      if (this._isMounted) {
        this.setState({ searchVal: true, search: e.target.value });
      }
    }
    else {
      this.showall("Show All", this);
    }


  }

  handleKeyPress = e => {
    if (event.key === 'Enter') {
      this.SetSearchParameters()
    }
  };

  SetSearchParameters = async () => {

    if (this._isMounted && this.refs.search.value.trim()) {

      await this.setState({

        isLoading: true,
        page: 0,
        offset: 0,
        order: 'ASC',
        sortByColumn: 'customerName',
      })

      this.search();
    }
    else {
      alert('Please Type Something in Search Bar')
    }
  }


  onTableChange = async (action, tableState) => {

    console.log('action', action);
    console.log('state', tableState)


    switch (action) {

      case 'changePage':

        this.changePage(tableState);
        break;


      case 'changeRowsPerPage':

        this.changeRowsPerPage(tableState);
        break;

      case 'sort':

        this.sort(tableState);
        break;

      default:
        break;

    }
  }


  changePage = async (tableState) => {

    var offset = this.state.offset;


    if (tableState.page > this.state.page) // Next Icon Clicked >
    {

      var tempOffset = offset + tableState.rowsPerPage
      if (this._isMounted) {

        await this.setState({
          offset: tempOffset, //50
          limit: tableState.rowsPerPage, // 76
          page: tableState.page
        });


        await this.state.searchVal ? this.search() : this.fetchPaginationData();
        // this.setState({
        //   page: tableState.page,

        // })
      }
    }
    else {
      var tempLimit = tableState.rowsPerPage;
      var tempOffset = offset - tempLimit;
      if (tempOffset < 0) {
        tempOffset = 0
      }

      if (this._isMounted) {

        await this.setState({
          limit: tempLimit,
          offset: tempOffset,
          page: tableState.page
        });


        await this.state.searchVal ? this.search() : this.fetchPaginationData()

      }

    }

  }


  changeRowsPerPage = async (tableState) => {

    if (this._isMounted) {
      await this.setState({
        limit: tableState.rowsPerPage, // 100
        offset: 0,
        rowsPerPage: tableState.rowsPerPage,
        page: 0,

      });
      this.state.searchVal ? this.search() : this.fetchPaginationData();

    }

  }


  sort = async (tableState) => {

    var sortByColumn = this.state.sortByColumn;
    var order = this.state.order;

    var sortOrder = tableState['columns'][tableState.activeColumn].name === sortByColumn ? (order === 'ASC' ? 'DESC' : 'ASC') : 'ASC';


    if (this._isMounted) {

      await this.setState({
        order: sortOrder,
        sortByColumn: tableState['columns'][tableState.activeColumn].name

      });


      this.state.searchVal ? this.search() : this.fetchPaginationData();

    }

  }

  fetchPaginationData = () => {


    //console.log('TableState Data',state.data)
    var postData = {
      'token': this.state.token,
      'status': this.state.status,
      "offset": this.state.offset,
      "limit": this.state.limit,
      "sortOrder": this.state.order,
      "sortBy": this.state.sortByColumn,
    };

    this.setLocalStorage();

    var arr = [];
    //console.log('state',arr)
    var status, count;
    axios.post(BaseURL + 'customer/', postData
      ,
      {
        headers: { 'Content-Type': 'application/json' }
      }
    ).then(response => {
      console.log(response.data.data)
      if (response.data.error === TokenErrorMsg) {
        localStorage.setItem('Token', '');
        this.props.history.push('/');
      }


      count = response.data.data.count;

      for (const key in response.data.data.rows) {

        if (response.data.data.rows.hasOwnProperty(key)) {
          const element = response.data.data.rows[key];

          if (element.status === 1) {
            status = <img style={{ width: "15px", height: "15px" }} src={Tick} alt='Active' />
          }
          else {
            status = <img style={{ width: "15px", height: "15px" }} src={Cross} alt='Inactive' />
          };


          var Obj = [
            element.id,
            element.customerName,
            element.address.address + ', ' + (element.address.addressLineTwo ? element.address.addressLineTwo + ', ' : '') + element.address.city + ', ' + element.address.state.state + ', ' + element.address.zip,
            element.address.telephone,
            status,


          ]

          arr.push(Obj);

        }

      }

      if (this._isMounted) {
        this.setState({ data: arr, isLoading: false, count });
      }

      // console.log(arr);
    }).catch(function (error) {
      NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);
      console.log(error);
    })





  }

  setLocalStorage = () => {

    const table = {
      order: this.state.order,
      sortByColumn: this.state.sortByColumn,
      btnVal: this.state.btnVal,
      status: this.state.status,
      search: this.state.search,
      page: this.state.page,
      offset: this.state.offset,
      limit: this.state.limit,
    };

    setPersistentTableState('customer', table);

    this.columns = this.columns.map(col => {
      col.name === table.sortByColumn ?
      col.options.sortDirection = table.order.toLowerCase() :
      delete col.options.sortDirection;
      return col;
    });

  }

  render() {
    const crossN = {
      display: 'none'
    }
    const crossS = {
      display: 'block'
    }

    const { isLoading, data, rowsPerPage, searchVal, open, count, page } = this.state;

    const options = {
      responsive: "scroll",
      selectableRows: false,
      rowHover: true,
      print: false,
      download: false,
      filter: false,
      search: false,
      rowsPerPageOptions: [25, 50, 100],
      rowsPerPage: rowsPerPage,
      viewColumns: false,
      textLabels: {
        body: {
          toolTip: "",
        }
      },
      onCellClick: this.startEditing,

      serverSide: true,
      count: count,
      page: page,
      onTableChange: this.onTableChange,

    };






    return (

      <div className="page-container-bg-solid page-header-fixed page-sidebar-closed-hide-logo page-md">
        {<Header title='| Customer List' />}
        <div className="page-container">

          <Sidebar />
          <div className="page-content-wrapper">

            <div className="page-content">

              <div className="row">
                <div className="col-md-12">

                  <div className="portlet box yellow-gold">
                    <div className="portlet-title">
                      <div className="caption"> <i className="fa fa-user"></i> <span className="caption-subject"> Customers List</span> </div>

                      <div className="actions">
                        <div className="btn-group">
                          {/* <a className="btn btn-circle btn-icon-only dark " data-toggle="modal" href="#basic2" style={{margin:" 0px 10px 0 0", lineHeight: "24px",  padding: "0 0", width: "28px", height: "28px"}}>
                    <i className="fa fa-question"></i>
                </a> */}
                          <Link to="/AddCustomer" id="ab" className="btn sbold white dark"> Add New <i className="fa fa-plus"></i> </Link>

                        </div>
                      </div>
                    </div>
                    <div className="portlet-body">
                      <div className="table-toolbar">
                        <div style={{ textAlign: 'right', paddingRight: ' 10px', paddingBottom: '8px' }}>
                          <span style={{ float: "left", paddingLeft: "8px", marginTop: "6px" }}>
                            <div className="btnShowall">
                              <Button
                                buttonRef={node => {
                                  this.anchorEl = node;
                                }}
                                aria-owns={open ? 'menu-list-grow' : undefined}
                                aria-haspopup="true"
                                onClick={this.handleToggle}
                              >
                                {this.state.btnVal} &nbsp;&nbsp;
                          <span className="fa fa-chevron-down"></span>
                              </Button>
                              <Popper open={open} anchorEl={this.anchorEl} transition disablePortal>
                                {({ TransitionProps, placement }) => (
                                  <Grow
                                    {...TransitionProps}
                                    id="menu-list-grow"
                                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                  >
                                    <Paper>
                                      <ClickAwayListener onClickAway={this.handleClose}>
                                        <MenuList>
                                          <MenuItem onClick={(e) => this.showall("Show All", e)}>Show All</MenuItem>
                                          <MenuItem onClick={(e) => this.active("Active", e)}>Active</MenuItem>
                                          <MenuItem onClick={(e) => this.Inactive("Inactive", e)}>Inactive</MenuItem>
                                        </MenuList>
                                      </ClickAwayListener>
                                    </Paper>
                                  </Grow>
                                )}
                              </Popper>
                            </div>

                          </span>
                          <div style={{ marginRight: '0px' }}>

                            <div className="searchField form-control">
                              <input id="search-field" ref="search" name="searchinput" type="text" placeholder="Search..." value={this.state.search} onChange={this.handleSearch} onKeyPress={this.handleKeyPress} />
                              <span style={searchVal ? crossS : crossN} className="removeSearchBtn" onClick={(e) => this.showall("Show All", e)}><i className="fa fa-close"></i></span>
                            </div>

                            <input name="searchbutton" style={{ marginRight: "10px" }} className="btn green-meadow" type="submit" value="SEARCH" onClick={this.SetSearchParameters} />
                            {/* <input type="button" className="btn green-meadow" value="SHOW ALL" onClick={this.showall.bind(this)}/> */}
                            {/* <img alt='' id="excel_export" src="../assets/apps/img/excel_button.png" title="Export data to Excel" />  */}

                          </div>
                        </div>
                      </div>
                      {
                        isLoading ?
                          <p style={{ textAlign: "center" }}><img src={f} alt='Loader' /></p> :
                          <MUiTable
                            TableName='CustomerTable muitable'
                            data={data}
                            columns={this.columns}
                            options={options}
                          />
                      }




                    </div>
                  </div>

                </div>
              </div>

            </div>

          </div>

        </div>
      </div>

    );
  }
}

export default withRouter(Pagecontent);