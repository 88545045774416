/* eslint-disable */ 

import React, { Component } from 'react';
import { withRouter} from "react-router-dom";
import MUiTable from './MuiTable'
import Tick from './green-tick.jpg';
import Cross from './red-cross.jpg';
import { NotificationManager} from 'react-notifications';
import DatePicker from "react-datepicker";
import axios from 'axios';
import BaseURL from './config';
import $ from 'jquery';
import f from './Loading_icon.gif';
import  getEditinfo    from './StartEditMode';
import  finishEditinfo    from './FinishEditMode';

import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from "@material-ui/core/MenuItem";
import TokenErrorMsg from './errorMsg';


 class DriverTab extends Component {
    _isMounted=true;
    constructor(props){
        super(props);
        this.state = {
            Status:2,
            status:1,
            countries:[],
            readonly:false,
            carrierId:'',
            driverid:'',
            addressid:'',
            token:'',
            data: '',  
            btnVal: "Show All",
            page: 0,
            rowsPerPage: 50,    
            searchVal: false,
          
            fields: {},
            errors: {},           
            make:[],
            states:[], 
            bstates:[],  
            whileSave: false,
            modifyMedExpDate:  new Date(),
            modifyLicExpDate: new Date(),
            MedExpDate:  new Date(),
            LicExpDate: new Date(),
            file:{
                License:'',
                Medcertificate:'',        
            },
            Licenseload:true,
            Medicalload:true,
            hidden:true,  
            driverform:'',
            isLoading: false,   
            load:true ,
            open: false,
            offset: 0,
            limit: 50,
            order:'ASC',
            sortByColumn:'firstName',
            count:'',

        };
        this.handleChange = this.handleChange.bind(this);
        this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);
        this.submitEditRegistrationForm = this.submitEditRegistrationForm.bind(this);
        this.submit=this.submit.bind(this);
        this.validateForm=this.validateForm.bind(this); 
        this.AutoFormatContact = this.AutoFormatContact.bind(this);
        this.startEditing=this.startEditing.bind(this);
    }

    columns = [
      {name:"ID",options: {  
     display:false,
    }},
     {name:"firstName",
     label: "Driver Name",options: {  
     sortDirection	:'asc', 
     sort: true,    
    }}, 
    {name:"address",
    label: "Address",options: {   
     sort: true,
    }},
    {name:"telephone",
    label: "Telephone",options: {   
     sort: true,
    }},
    {name:"Status",options: {   
      sort: false,
     }}];
     
    AutoFormatContact(e){

        this.handleChange(e); 
        function phone_formatting(ele,restore) {
          var new_number,
              // selection_start = ele.selectionStart,
              // selection_end = ele.selectionEnd,
              number = ele.value.replace(/\D/g,'');
          
          // automatically add dashes
          if (number.length > 2) {
            // matches: 123 || 123-4 || 123-45
            new_number = number.substring(0,3) + '-';
            if (number.length === 4 || number.length === 5) {
              // matches: 123-4 || 123-45
              new_number += number.substr(3);
            }
            else if (number.length > 5) {
              // matches: 123-456 || 123-456-7 || 123-456-789
              new_number += number.substring(3,6) + '-';
            }
            if (number.length > 6) {
              // matches: 123-456-7 || 123-456-789 || 123-456-7890
              new_number += number.substring(6);
            }
          }
          else {
            new_number = number;
          }
          
          // if value is heigher than 12, last number is dropped
          // if inserting a number before the last character, numbers
          // are shifted right, only 12 characters will show
          ele.value =  (new_number.length > 12) ? new_number.substring(0,12) : new_number;
          
          // restore cursor selection,
          // prevent it from going to the end
          // UNLESS
          // cursor was at the end AND a dash was added
        
          
          // if (new_number.slice(-1) === '-' && restore === false
          //     && (new_number.length === 8 && selection_end === 7)
          //         || (new_number.length === 4 && selection_end === 3)) {
          //     selection_start = new_number.length;
          //     selection_end = new_number.length;
          // }
          // else if (restore === 'revert') {
          //   selection_start--;
          //   selection_end--;
          // }
          // ele.setSelectionRange(selection_start, selection_end);
        
        }
          
        function phone_number_check(field,e) {
          var key_code = e.keyCode,
              key_string = String.fromCharCode(key_code),
              press_delete = false,
              dash_key = 189,
              delete_key = [8,46],
              direction_key = [33,34,35,36,37,38,39,40],
              selection_end = field.selectionEnd;
          
          // delete key was pressed
          if (delete_key.indexOf(key_code) > -1) {
            press_delete = true;
          }
          
          // only force formatting is a number or delete key was pressed
          if (key_string.match(/^\d+$/) || press_delete) {
            phone_formatting(field,press_delete);
          }
          // do nothing for direction keys, keep their default actions
          else if(direction_key.indexOf(key_code) > -1) {
            // do nothing
          }
          else if(dash_key === key_code) {
            if (selection_end === field.value.length) {
              field.value = field.value.slice(0,-1)
            }
            else {
              field.value = field.value.substring(0,(selection_end - 1)) + field.value.substr(selection_end)
              field.selectionEnd = selection_end - 1;
            }
          }
          // all other non numerical key presses, remove their value
          else {
            e.preventDefault();
        //    field.value = field.value.replace(/[^0-9\-]/g,'')
            phone_formatting(field,'revert');
          }
        
        }
        
        document.getElementById(e.target.id).onkeyup = function(e) {
          phone_number_check(this,e);
        }
      }
  
   onUnload=  (event) =>{ 
  
    event.preventDefault();  
    var http = new XMLHttpRequest();
     
   var url = BaseURL+'editModeInfo/finishEditMode';
   
   var params = "driverId="+this.state.driverid+"&token="+this.state.token;
   
   http.open("POST", url, true);
   
   http.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
   
   if(this.state.driverid){

   
   http.send(params);
   var start = new Date().getTime();
     while (new Date().getTime() < start + 350);  
   
    }
     
   }
   
   
   async componentDidMount(){
      
     window.addEventListener("beforeunload", this.onUnload)  
    var token=localStorage.getItem('Token'); 
    
    console.log(this.props.Driver);        
        
      
    var arr=[] ,count;
    if(this.props.Driver.Driver.rows){

      count=this.props.Driver.Driver.count;
  
      for (const key in this.props.Driver.Driver.rows) {
      
        if (this.props.Driver.Driver.rows.hasOwnProperty(key)) {
          const element = this.props.Driver.Driver.rows[key];

          var status
          if(element.status === 1){
             status=<img style={{ width:"15px", height:"15px"}} src={Tick} alt='Active'/>
            } 
            else   {
             status=<img style={{ width:"15px", height:"15px"}} src={Cross} alt='Inactive'/>
            };        
         
          var Obj=[
            element.id,
            element.firstName+' '+element.lastName,
            element.address.address+', '+(element.address.addressLineTwo ?element.address.addressLineTwo+', ':'')+element.address.city+', '+element.address.state.state+ ', '+element.address.zip ,    
         
            element.address.telephone,
            status        
          ]
          
          arr.push(Obj);
          
        }
       
      }

      this.setState({
        count,
        data:arr,
        carrierId:this.props.CarrierId,
        countries:this.props.Driver.countries,
        bstates:this.props.Driver.states, 
        token:token
        
     });

    }
   
   

           
  }
  
  componentWillUnmount() {
  
   
    var config={
      "driverId": this.state.driverid,   
      "token":this.state.token
     }
     
     if(this.state.driverid)
     {     
        finishEditinfo(config, (res) => {
        console.log(res);
        
            },(err) => {
                
                console.log(err)
            });
     }
  
    
  }

    
    async search(){

      if(this.refs.search.value.trim()){
    
    
      
        var arr=[],count;
        var status;
        await axios.post(BaseURL+'driver/findByName',{
          "searchKeyword": this.refs.search.value.trim(),
          "carrierId":this.state.carrierId,
          "offset": this.state.offset,
          "limit": this.state.limit,
          "sortOrder": this.state.order,
          "sortBy": this.state.sortByColumn,
          "status":this.state.Status,
          'token':this.state.token
        })
                .then(response=> {  
                  console.log(response)         
                  if(response.data.error=== TokenErrorMsg){     
                    localStorage.setItem('Token','') ;      
                    this.props.history.push('/') ;       
                        }
                  
                    count =response.data.data.count;
      
                  for (const key in response.data.data.rows) {
                    if (response.data.data.rows.hasOwnProperty(key)) {
                      const element = response.data.data.rows[key];
    
                      
                      if(element.status === 1){
                        status=<img style={{ width:"15px", height:"15px"}} src={Tick} alt='Active'/>
                        } 
                        else   {
                        status=<img style={{ width:"15px", height:"15px"}} src={Cross} alt='Inactive'/>
                        };
                      var Obj=[
                        element.id,
                        element.firstName+' '+element.lastName,
                        element.address.address+', '+(element.address.addressLineTwo ?element.address.addressLineTwo+', ':'')+element.address.city+', '+element.address.state.state+ ', '+element.address.zip ,    
                    
                        element.address.telephone ,
                        status          
                      ]
                      
                      arr.push(Obj);
                      
                    }
                  }
                }).catch(function (error) {
                  NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);         
                  console.log(error);
                })
                
                if(this._isMounted){
                this.setState({data:arr,isLoading:false,count});
                }
    
      }
      else{
        alert('Please Type Something in Search Bar')
      }
      
      
      
    
    
    }

    async showall(theme, e){

      this.refs.search.value='';
      if(this._isMounted){
    
        await  this.setState({
            searchVal: false,
            isLoading: true,
            Status:2,
            open: false,
            anchorEl: null,
            btnVal: theme,
            page:0,
            offset: 0,
            order:'ASC',
            sortByColumn:'firstName',  
            
    
          })
          this.fetchPaginationData();
          
        }
      
      
    }
    
     async startEditing (colData, cellMeta) {

           
      if(window.getSelection().toString()){
        return false;
      }
      else if(cellMeta.colIndex !== 1)
      {
       return;
      }
       this.setState({   
        readonly:true            
       });
       
       var data=this.state.data;
       var id =data[cellMeta.dataIndex][0]
    
       
      var arr={
            'carriers':[],
            'countries':[],   
            'states':[],
       }; 
      var driverform={};     
      var License='',Medcertificate='';

      $("#viewtab1").removeClass('active');
      $("#tab_19").removeClass('active');

      $('#tab_18').addClass('active');
      $('#viewtab0').addClass('active');     
     

        document.getElementById('drivercarrierinfo').click(); 
   
      
    
        await axios.post(BaseURL+'driver/findById',{ 'id':id, 'token':this.state.token})
              .then(response=> {           
                if(response.data.error=== TokenErrorMsg){     
                 localStorage.setItem('Token','') ;      
                 this.props.history.push('/') ;       
                     }
                     console.log(response.data.data);
                     arr=response.data.data;             
                     var element=response.data.data.driver;
       
                     
                     if(element.status === 0){
                        $("#driveractiveLabel").removeClass('active');
                       $("#driverinactiveLabel").addClass('active');                         
                      }
                      else{
                        $("#driverinactiveLabel").removeClass('active');   
                        $("#driveractiveLabel").addClass('active');
                        }
       
                     driverform={
                         'status': element.status,
                         'firstname':element.firstName,
                         'lastname':element.lastName,
                         'middlename':element.middleName,
                         'carrierid':element.carrierId,
       
                         'addressid':element.address.id,
                         'address':element.address.address,
                         'addressLineTwo':element.address.addressLineTwo,            
                         'countryid':element.address.addressCountryId,                  
                         'stateid':element.address.addressStateId,
                         'city':element.address.city,
                         'zip':element.address.zip,
                         'email':element.address.email,
                         'phone':element.address.telephone,
                         'ext':element.address.ext,
                         'fax':element.address.fax,
       
                         'license':element.licenseNo,
                         'licensestate':element.licenseStateId,
                         'licexpdate':new Date(element.licenseExpirationDate),
                         'medexpdate':new Date(element.medicalCertificateExpirationDate),                                    
                         }
                        
       
                         response.data.data.driverDocuments.forEach(element => {
       
                           var filename=  element.documentName ;
                           if(filename.length>15)
                           {
                             filename=filename.substring(0, 15)+'....'
                             
                           }        
               
                       if( Number(element.documentType) === 1){
                          License={'name':filename};                  
                 
                       }
                       else if( Number(element.documentType) === 2){          
                         Medcertificate={'name':filename}; 
               
                       }
                             
                       });
                         
              
              }).catch(function (error) {
                NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);         
                console.log(error);
              })
              
                        
              
              
        
                  
     this.setState({
        driverid:id,
        driverform:driverform,
        status:driverform.status,       
        countries:arr.countries,
        addressid:driverform.addressid,
         modifyLicExpDate:driverform.licexpdate,
         modifyMedExpDate:driverform.medexpdate,
         states:arr.states,       
         file:{
           License:License,
           Medcertificate:Medcertificate,        
         },        
       });
      
    };
    
    handleSearch = async() => {

      if (this.refs.search.value) {
        this.setState({ searchVal: true });
      }
      else{
        this.showall('Show All',this)
      }
  
    }
    
   

     async  cancel(){      
        
        var config={
            "driverId": this.state.driverid,   
            "token":this.state.token
           }
           
           if(config.driverId)
           {     
             await finishEditinfo(config, (res) => {
              console.log(res);
              
                  },(err) => {
                      
                      console.log(err)
                  });
              this.setState({ hidden:true, states:[],  readonly:false,status:1,driverid:'',driverform:'',MedExpDate:  new Date(), LicExpDate: new Date(), errors:{} , file:{  License:'', Medcertificate:'' } })
           }
           else{
               
               await this.setState({
                    states:[],
                    readonly:false,
                    status:1,
                    hidden:true,  
                    MedExpDate:  new Date(),
                    LicExpDate: new Date(),
                    errors:{},
                    file:{
                        License:'',
                        Medcertificate:'',        
                    },
                })    
           }
           
           document.getElementById('driverform').reset();
           document.getElementById('driverviewform').reset();

            $("#driverinactive").removeClass('active');
            $('#driveractive').addClass('active');        
           
                    
      }

    EditForm(e){       
      
        var div = document.getElementById('driverEditDiv');
        var viewdiv = document.getElementById('driverviewEditDiv');

        div.setAttribute("style"," cursor: not-allowed; pointer-events: none");
        viewdiv.setAttribute("style"," cursor: not-allowed; pointer-events: none")
      
        var config={
          "driverId": this.state.driverid,   
          "token":this.state.token
         }
      
         getEditinfo(config, (res) => {
          console.log(res);
          if(res.data.status === 0){
             this.setState({readonly:false});
             viewdiv.removeAttribute("style")
             div.removeAttribute("style")
          }
          else {
            viewdiv.removeAttribute("style")
            div.removeAttribute("style")
            NotificationManager.warning(res.data.message, 'Warning Message', 3000);   
          }
      },(err) => {
         
        console.log(err)
      }); 
      
      }
    
    
   async handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    await this.setState({
      fields
    });
    this.validateForm();
  }
  
  submituserRegistrationForm(e) {

    e.preventDefault();
    var send=false;
     


   
    if (this.validateAndSubmit()  && this.validateForm()  ) {
      
      send=true;
       
    }
    if(send)
    {  
      this.submit();
      this.refs.firstName.focus();
     
    }
    
  }

  submitEditRegistrationForm(e) {

    e.preventDefault();
    var send=false;
   
    if (this.EditvalidateAndSubmit()  && this.validateForm()  ) {
      
      send=true;
       
    }
    if(send)
    {     
      this.submit();
      this.refs.modifyfirstName.focus();
     
    }
    
  }
  
 

  EditvalidateAndSubmit(){
  
   
   // let fields = this.state.fields;
  let errors = {};
  let formIsValid = true;

  // CustomerNAme
  if (!this.refs.modifyfirstName.value || !this.refs.modifyfirstName.value.trim().length>0) {
    formIsValid = false;
    errors["modifyfirstName"] = "*Please Enter Driver Name";
    $("[name='modifyfirstName']").focus();
   
  }
  


    // Address
    else if (!this.refs.modifyaddress.value || !this.refs.modifyaddress.value.trim().length>0) {
      formIsValid = false;
      errors["modifyaddress"] = "*Please Enter Address";
      $("[name='modifyaddress']").focus();
     
    }   
    

    // City
    else  if (!this.refs.modifycity.value || !this.refs.modifycity.value.trim().length>0) {
      formIsValid = false;
      errors["modifycity"] = "*Please Enter City";
      $("[name='modifycity']").focus();
     
    }

    // Zip
    else if (!this.refs.modifyzip.value || !this.refs.modifyzip.value.trim().length>0) {
      formIsValid = false;
      errors["modifyzip"] = "*Please Enter Zip";
      $("[name='modifyzip']").focus();
     
    }
    else if (!this.refs.modifydriverLicense.value || !this.refs.modifydriverLicense.value.trim().length>0) {
      formIsValid = false;
      errors["modifyDriverLicense"] = "*Please Enter Driver License";
      $("[name='modifyDriverLicense']").focus();
     
    }




    
    this.setState({
      errors: errors
    });
    return formIsValid;
  }
  
  
validateAndSubmit(){

    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
  
    if (!this.refs.firstName.value || !this.refs.firstName.value.trim().length>0 ) {
      formIsValid = false;
      errors["firstName"] = "*Please Enter Driver Name";
      $("[name='firstName']").focus();
     
    }
  
  
  
  
      // Address
      else if (!this.refs.address.value || !this.refs.address.value.trim().length>0) {
        formIsValid = false;
        errors["address"] = "*Please Enter Address";
        $("[name='address']").focus();
       
      }  
  
  
      // Country
      else if (!this.refs.country_id.value) {
        formIsValid = false;
        errors["country"] = "*Please Select Country";
        $("[name='country']").focus();
       
      }
  
       // State
       else if (!this.refs.province_id.value) {
        formIsValid = false;
        errors["state"] = "*Please Select State";
        $("[name='state']").focus();
       
      }
  
      // City
      else  if (!this.refs.city.value || !this.refs.city.value.trim().length>0 ) {
        formIsValid = false;
        errors["city"] = "*Please Enter City";
        $("[name='city']").focus();
        
      }

      // Zip
      else if (!this.refs.zip.value || !this.refs.zip.value.trim().length>0) {
        formIsValid = false;
        errors["zip"] = "*Please Enter Zip";
        $("[name='zip']").focus();
        
      }
      else if (!this.refs.driverLicense.value || !this.refs.driverLicense.value.trim().length>0) {
        formIsValid = false;
        errors["DriverLicense"] = "*Please Enter Driver License";
        $("[name='DriverLicense']").focus();
        
      }
      else if (!this.refs.bprovince_id.value) {
        formIsValid = false;
        errors["bstate"] = "*Please Select State";
        $("[name='bstate']").focus();
       
      }
  
      
  
  
      
      this.setState({
        errors: errors
      });
      return formIsValid;
  }
  
  
  
  validateForm() {
  
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    
    
  
  
  
    if (typeof fields["firstName"] !== "undefined" && fields["firstName"]) {
    if (!fields["firstName"].match(/^[a-zA-Z#'".,-/ ]{1,200}$/)) {
      formIsValid = false;
      errors["firstName"] = "*Only Alphabets Allowed (Max Range: 200)";
    }
    }
     
    if (typeof fields["lastName"] !== "undefined" && fields["lastName"]) {
      if (!fields["lastName"].match(/^[a-zA-Z#'".,-/ ]{1,200}$/)) {
        formIsValid = false;
        errors["lastName"] = "*Only Alphabets Allowed (Max Range: 200)";
      }
      }
  
      if (typeof fields["middleName"] !== "undefined" && fields["middleName"]) {
        if (!fields["middleName"].match(/^[a-zA-Z#'".,-/ ]{1,200}$/)) {
          formIsValid = false;
          errors["middleName"] = "*Only Alphabets Allowed (Max Range: 200)";
        }
        }
  
  
    
  
    //address
    
    if (typeof fields["address"] !== "undefined" && fields["address"]) {
      if (!fields["address"].match(/^[a-zA-Z0-9#'"،.,-;/ ]{1,200}$/)) {
        formIsValid = false;
        errors["address"] = "*Please Enter Only Valid Characters (Max Range: 200)";
      }
      }
  
  
      
  //addressLineTwo
  
  if (typeof fields["addressLineTwo"] !== "undefined" && fields["addressLineTwo"]) {
    if (!fields["addressLineTwo"].match(/^[a-zA-Z0-9#'"،.,-;/ ]{1,200}$/)) {
      formIsValid = false;
      errors["addressLineTwo"] = "*Please Enter Only Valid Characters (Max Range: 200)";
    }
    }
    
  //city
  
  if (typeof fields["city"] !== "undefined" && fields["city"]) {
    if (!fields["city"].match(/^[a-zA-Z0-9#., ]{1,200}$/)) {
      formIsValid = false;
      errors["city"] = "*Please Enter Only Valid Characters (Max Range: 200)";
    }
    }
  
    //zip
  
  if (typeof fields["zip"] !== "undefined" && fields["zip"]) {
    if (!fields["zip"].match(/^[a-zA-Z0-9 ]{1,35}$/)) {
      formIsValid = false;
      errors["zip"] = "*Only Numbers Allowed in Zip (Max Range: 35)";
    }
    }
  
    //telephone
  
    if (typeof fields["telephone"] !== "undefined" && fields["telephone"]) {
      if (!fields["telephone"].match(/^[0-9- ]{12,30}$/)) {
        formIsValid = false;
        errors["telephone"] = "*Please Enter only valid Telephone Number ( Range: 10)";
      }
      }
  
    //Ext
  
  if (typeof fields["ext"] !== "undefined" && fields["ext"]) {
    if (!fields["ext"].match(/^[0-9]{1,20}$/)) {
      formIsValid = false;
      errors["ext"] = "*Only Numbers Allowed (Max Range: 20)";
    }
    }
    //Fax
  
    if (typeof fields["fax"] !== "undefined" && fields["fax"]) {
      if (!fields["fax"].match(/^[0-9- ]{12,30}$/)) {
        formIsValid = false;
        errors["fax"] = "*Please Enter only valid fax Number ( Range: 10)";
      }
      }
  
      //email
  
  if (typeof fields["email"] !== "undefined" && fields["email"]) {
    if (!fields["email"].match(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/)) {
      formIsValid = false;
      errors["email"] = "*Please Enter Valid Email ";
    }
    }
  
    if (typeof fields["DriverLicense"] !== "undefined" && fields["DriverLicense"]) {
      if (!fields["DriverLicense"].match(/^[a-zA-Z0-9'-.#$ ]{1,30}$/)) {
        formIsValid = false;
        errors["DriverLicense"] = "*Please Enter Valid License  (Max Range: 30)";
      }
      }
  
      if (typeof this.state.LicExpDate !== "undefined" && this.state.LicExpDate) {
        if ( this.state.LicExpDate.getFullYear() < 2000 || this.state.LicExpDate.getFullYear() > 2099 ) {
          formIsValid = false;
          errors["LicExpDate"] = "*Enter Valid Date ( Year Between 2000-2099 )";
        }
        }
    
        if (typeof this.state.MedExpDate !== "undefined" && this.state.MedExpDate) {
          if ( this.state.MedExpDate.getFullYear() < 2000 || this.state.MedExpDate.getFullYear() > 2099 ) {
            formIsValid = false;
            errors["MedExpDate"] = "*Enter Valid Date ( Year Between 2000-2099 )";
          }
          }




          if (typeof fields["modifyfirstName"] !== "undefined" && fields["modifyfirstName"]) {
            if (!fields["modifyfirstName"].match(/^[a-zA-Z#'".,-/ ]{1,200}$/)) {
              formIsValid = false;
              errors["modifyfirstName"] = "*Only Alphabets Allowed (Max Range: 200)";
            }
            }
             
            if (typeof fields["modifylastName"] !== "undefined" && fields["modifylastName"]) {
              if (!fields["modifylastName"].match(/^[a-zA-Z#'".,-/ ]{1,200}$/)) {
                formIsValid = false;
                errors["modifylastName"] = "*Only Alphabets Allowed (Max Range: 200)";
              }
              }
          
              if (typeof fields["modifymiddleName"] !== "undefined" && fields["modifymiddleName"]) {
                if (!fields["modifymiddleName"].match(/^[a-zA-Z#'".,-/ ]{1,200}$/)) {
                  formIsValid = false;
                  errors["modifymiddleName"] = "*Only Alphabets Allowed (Max Range: 200)";
                }
                }
          
          
            
          
            //address
            
            if (typeof fields["modifyaddress"] !== "undefined" && fields["modifyaddress"]) {
              if (!fields["modifyaddress"].match(/^[a-zA-Z0-9#'"،.,-;/ ]{1,200}$/)) {
                formIsValid = false;
                errors["modifyaddress"] = "*Please Enter Only Valid Characters (Max Range: 200)";
              }
              }
          
          
              
          //addressLineTwo
          
          if (typeof fields["modifyaddressLineTwo"] !== "undefined" && fields["modifyaddressLineTwo"]) {
            if (!fields["modifyaddressLineTwo"].match(/^[a-zA-Z0-9#'"،.,-;/ ]{1,200}$/)) {
              formIsValid = false;
              errors["modifyaddressLineTwo"] = "*Please Enter Only Valid Characters (Max Range: 200)";
            }
            }
            
          //city
          
          if (typeof fields["modifycity"] !== "undefined" && fields["modifycity"]) {
            if (!fields["modifycity"].match(/^[a-zA-Z0-9#., ]{1,200}$/)) {
              formIsValid = false;
              errors["modifycity"] = "*Please Enter Only Valid Characters (Max Range: 200)";
            }
            }
          
            //zip
          
          if (typeof fields["modifyzip"] !== "undefined" && fields["modifyzip"]) {
            if (!fields["modifyzip"].match(/^[a-zA-Z0-9 ]{1,35}$/)) {
              formIsValid = false;
              errors["modifyzip"] = "*Only Numbers Allowed in Zip (Max Range: 35)";
            }
            }
          
            //telephone
          
            if (typeof fields["modifytelephone"] !== "undefined" && fields["modifytelephone"]) {
              if (!fields["modifytelephone"].match(/^[0-9- ]{12,30}$/)) {
                formIsValid = false;
                errors["modifytelephone"] = "*Please Enter only valid Telephone Number ( Range: 10)";
              }
              }
          
            //Ext
          
          if (typeof fields["modifyext"] !== "undefined" && fields["modifyext"]) {
            if (!fields["modifyext"].match(/^[0-9]{1,20}$/)) {
              formIsValid = false;
              errors["modifyext"] = "*Only Numbers Allowed (Max Range: 20)";
            }
            }
            //Fax
          
            if (typeof fields["modifyfax"] !== "undefined" && fields["modifyfax"]) {
              if (!fields["modifyfax"].match(/^[0-9- ]{12,30}$/)) {
                formIsValid = false;
                errors["modifyfax"] = "*Please Enter only valid fax Number ( Range: 10)";
              }
              }
          
              //email
          
          if (typeof fields["modifyemail"] !== "undefined" && fields["modifyemail"]) {
            if (!fields["modifyemail"].match(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/)) {
              formIsValid = false;
              errors["modifyemail"] = "*Please Enter Valid Email ";
            }
            }
          
            if (typeof fields["modifyDriverLicense"] !== "undefined" && fields["modifyDriverLicense"]) {
              if (!fields["modifyDriverLicense"].match(/^[a-zA-Z0-9'-.#$ ]{1,30}$/)) {
                formIsValid = false;
                errors["modifyDriverLicense"] = "*Please Enter Valid License  (Max Range: 30)";
              }
              }
          
              if (typeof this.state.modifyLicExpDate !== "undefined" && this.state.modifyLicExpDate) {
                if ( this.state.modifyLicExpDate.getFullYear() < 2000 || this.state.modifyLicExpDate.getFullYear() > 2099 ) {
                  formIsValid = false;
                  errors["modifyLicExpDate"] = "*Enter Valid Date ( Year Between 2000-2099 )";
                }
                }
            
                if (typeof this.state.modifyMedExpDate !== "undefined" && this.state.modifyMedExpDate) {
                  if ( this.state.modifyMedExpDate.getFullYear() < 2000 || this.state.modifyMedExpDate.getFullYear() > 2099 ) {
                    formIsValid = false;
                    errors["modifyMedExpDate"] = "*Enter Valid Date ( Year Between 2000-2099 )";
                  }
                  }
  
  
  
    
  
    this.setState({
      errors: errors
    });
    return formIsValid;
  
  
  }


  async states()
{

  var arr;
  var data=false;
 
  await axios.post(BaseURL+'countries/states',{
    "countryId": this.refs.country_id.value,
    'token':this.state.token
  })
          .then(response=> {           
            if(response.data.error=== TokenErrorMsg){     
             localStorage.setItem('Token','') ;      
             this.props.history.push('/') ;       
                 }
            
            arr = response.data.data;
            data=true;
          }).catch(function (error) {
            NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);         
            console.log(error);
          })
          this.refs.province_id.value=1;        
        
          if(data){
          this.setState({states:arr});
          
          }

}
async modifystates()
{

  var arr;
  var data=false;
 
  await axios.post(BaseURL+'countries/states',{
    "countryId": this.refs.modifycountry_id.value,
    'token':this.state.token
  })
          .then(response=> {           
            if(response.data.error=== TokenErrorMsg){     
             localStorage.setItem('Token','') ;      
             this.props.history.push('/') ;       
                 }
            
            arr = response.data.data;
            data=true;
          }).catch(function (error) {
            NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);         
            console.log(error);
          })
          this.refs.modifyprovince_id.value=1;        
        
          if(data){
          this.setState({states:arr});
          
          }

}

async submit(){
    this.setState({ whileSave: true ,hidden:false,readonly:true})
  
    var custId, addressid;

    const firstName =  this.refs.firstName.value.trim();
    const lastName =  this.refs.lastName.value.trim();
    const middleName =  this.refs.middleName.value.trim();
    const carrierId=this.state.carrierId;
    const address =  this.refs.address.value.trim();
    const address2 =  this.refs.address2.value.trim();
    const country_id =  this.refs.country_id.value;
    const province_id =  this.refs.province_id.value;
    const city =  this.refs.city.value.trim();
    const zip =  this.refs.zip.value.trim();
    const email =  this.refs.email.value.trim();
    const telephone =  this.refs.telephone.value.trim();
    const fax =  this.refs.fax.value.trim();
    const ext =  this.refs.ext.value.trim();
    const bprovince_id =  this.refs.bprovince_id.value;
    const driverLicense = this.refs.driverLicense.value.trim();
    

    const modifyfirstName =  this.refs.modifyfirstName.value.trim();
    const modifylastName =  this.refs.modifylastName.value.trim();
    const modifymiddleName =  this.refs.modifymiddleName.value.trim();
    const modifyaddress =  this.refs.modifyaddress.value.trim();
    const modifyaddress2 =  this.refs.modifyaddress2.value.trim();
    const modifycountry_id =  this.refs.modifycountry_id.value;
    const modifyprovince_id =  this.refs.modifyprovince_id.value;
    const modifycity =  this.refs.modifycity.value.trim();
    const modifyzip =  this.refs.modifyzip.value.trim();
    const modifyemail =  this.refs.modifyemail.value.trim();
    const modifytelephone =  this.refs.modifytelephone.value.trim();
    const modifyfax =  this.refs.modifyfax.value.trim();
    const modifyext =  this.refs.modifyext.value.trim();
    const modifybprovince_id =  this.refs.modifybprovince_id.value;
    const modifydriverLicense = this.refs.modifydriverLicense.value.trim();
   
      
      var driver={
     'token':this.state.token,
     'id': this.state.driverid,
     "firstName": firstName,
     "lastName": lastName,
     "middleName": middleName,
     "licenseNo": driverLicense,
     "licenseExpirationDate": this.state.LicExpDate,
     "medicalCertificateExpirationDate": this.state.MedExpDate,
     "status": this.state.status,
     "carrierId": carrierId,
     "licenseStateId": bprovince_id,
     
      "address": {
         'id':this.state.addressid,
          "address": address,
          "addressLineTwo": address2,
          "city": city,
          "zip": zip,
          "email": email,
          "telephone": telephone,
          "ext": ext,
          "fax": fax,
          "state": {
              "id": province_id,
              "country": {
                  "id": country_id
                  
              }
          }
      }   
  }




  var modifydriver={
    'token':this.state.token,
    'id': this.state.driverid,
    "firstName": modifyfirstName,
    "lastName": modifylastName,
    "middleName": modifymiddleName,
    "licenseNo": modifydriverLicense,
    "licenseExpirationDate": this.state.modifyLicExpDate,
    "medicalCertificateExpirationDate": this.state.modifyMedExpDate,
    "status": this.state.status,
    "carrierId": carrierId,
    "licenseStateId": modifybprovince_id,
    
     "address": {
        'id':this.state.addressid,
         "address": modifyaddress,
         "addressLineTwo":modifyaddress2,
         "city": modifycity,
         "zip": modifyzip,
         "email": modifyemail,
         "telephone": modifytelephone,
         "ext": modifyext,
         "fax": modifyfax,
         "state": {
             "id": modifyprovince_id,
             "country": {
                 "id": modifycountry_id
                 
             }
         }
     }   
 }

  if(this.state.driverid){
   
    var driverData=this.state.driverform;
   await axios.post(BaseURL+'driver/update', driverData? modifydriver: driver )
          .then(response=> {           
           if(response.data.error=== TokenErrorMsg){     
            localStorage.setItem('Token','') ;      
            this.props.history.push('/') ;       
                }
            console.log(response.data);
            NotificationManager.success('Driver upated Successfully', 'Edited', 3000);
          }).catch(function (error) {
           NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);         
           console.log(error);
         })
    this.setState({
      whileSave: false
    })


 }

  else{
     
  await axios.post(BaseURL+'driver/save', driver )
          .then(response=> {           
           if(response.data.error=== TokenErrorMsg){     
            localStorage.setItem('Token','') ;      
            this.props.history.push('/') ;       
                }
            console.log(response.data);
            custId= response.data.data.id;   
            addressid=  response.data.data.addressId;
           
            NotificationManager.success('New Driver Added Successfully', 'Added', 3000);
          }).catch(function (error) {
           NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);         
           console.log(error);
         })
   this.setState({
     driverid:custId,
     whileSave: false,
     addressid:addressid,     
    
   });
 }
 this.showall("Show All", this);
       
}


  
onFileChange =async (event) => {
    
    let errors = this.state.errors; 

    event.preventDefault();
    event.persist();
    var file=await event.target.files[0];

    function getFileExtension(filename) {
      return filename.split('.').pop();
    }

     if(event.target.files[0])
    {

    var filename= {'name': file.name };


    if (file.type !=='application/pdf' && file.type !=='image/jpeg' && file.type !=='image/png' && file.type !=='image/jpg' && file.type !=='image/gif' && file.type !=='image/tif' && getFileExtension(file.name) !=='docx' && getFileExtension(file.name) !=='doc' && getFileExtension(file.name) !=='odt' && getFileExtension(file.name) !=='xls' && getFileExtension(file.name) !=='xlsx' && getFileExtension(file.name) !=='ppt' && getFileExtension(file.name) !=='pptx') {
    errors[event.target.name]='Please Upload only PDF ,Images, Document files ';
    this.setState({errors:errors})
    return false;
      }
      else if (file.size> 1000000) {
        errors[event.target.name]='Please Upload A File Less Than 1MB ( Max size for file: 1MB ) ';
        this.setState({errors:errors})
        return false;
          }
    else{
      errors[event.target.name]='';
      this.setState({errors:errors})
    }

    if(filename.name.length>15)
    {
      filename={
        'name':filename.name.substring(0, 15)+'....'
      }
    }
    console.log(file);

    var doctype= event.target.id;
    var id=event.target.id;
    console.log(id);    

   
    
      if( Number(id)===1){

        this.setState({
  
          Licenseload:false
         
       });
  
     }
     else if(Number(id)===2){
  
          this.setState({
            Medicalload:false
       });
       
     }
    
    

   console.log('enter in upload file');
    let data = new FormData();
    await data.append('file', file);
    await data.append('fileName', file.name);
    await data.append("driverId",  this.state.driverid);
    await data.append("type",  file.type);
    await data.append("documentType", doctype);   
   


    await axios.post(BaseURL+'driverDocuments/uploadFileToS3', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'token':this.state.token
      }
    })
    .then(response=> {           
      if(response.data.error=== TokenErrorMsg){     
       localStorage.setItem('Token','') ;      
       this.props.history.push('/') ;       
           }
      console.log(response.data)
      NotificationManager.success('Document Uploaded Successfully', 'Upload', 3000);

    }).catch(function (error) {
      NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);         
      console.log(error);
    })

    if(Number(id)=== 1){

      await this.setState({
        Licenseload:true,
        file:{
        License:filename,
        Medcertificate:this.state.file.Medcertificate,
        }
      });

    }
    else if(Number(id)=== 2){

      await this.setState({
        Medicalload:true,
        file:{
          License:this.state.file.License,
          Medcertificate:filename ,      
        
         
        }
      });
      
    }
  
    
  }

  }

  deleteFile=(e)=>{
    e.preventDefault();
    e.persist();
    var doctype=e.target.id;
    var id=e.target.id;
    console.log(id);
    


    if(window.confirm('Are You Sure To Remove Docment ?'))
    {

      
      console.log('enter in delete file');
        axios.post(BaseURL+'driverDocuments/deleteFile', {
          "driverId":   this.state.driverid,
          "documentType": doctype,
          'token':this.state.token

        })
        .then(response=> {           
          if(response.data.error=== TokenErrorMsg){     
           localStorage.setItem('Token','') ;      
           this.props.history.push('/') ;       
               }
          NotificationManager.success('Document Deleted Successfully', 'Delete', 3000);
          console.log(response.data)

        }).catch(function (error) {
          NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);         
          console.log(error);
        })

        if( Number(id) ===1 ) {

          this.setState({
         
           file:{
           License:'',
           Medcertificate:this.state.file.Medcertificate,
           }
         });
    
       }
       else if( Number(id)===2 ){
    
            this.setState({
              
            file:{
              License:this.state.file.License,
              Medcertificate:'' , 
            
           }
         });
         
       }     
     
    
    }

    
  }
  

  download =async (e) => { 
    
    e.preventDefault();
    e.persist();   
    var doctype= await e.target.id;
    var id= await e.target.id;


    if(Number(id)===1){

      this.setState({

        Licenseload:false
       
     });

   }
   else if(Number(id)===2){

        this.setState({
          Medicalload:false
     });
     
   }
 
   
   
 

    

    console.log(doctype);
    console.log('enter in download file');

    await axios.post(BaseURL+'driverDocuments/getDocuments',{
      "driverId":   this.state.driverid,
      'token':this.state.token
    })
    .then(response=> {           
      if(response.data.error=== TokenErrorMsg){     
       localStorage.setItem('Token','') ;      
       this.props.history.push('/') ;       
           }
      
         response.data.documents.forEach(element => {
      if(element.documentType === doctype){
       console.log( element );
       window.open(element.documentUrl,'_blank')       

      }  
         
      });   

    }).catch(function (error) {
      NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);         
      console.log(error);
    })

    if(Number(id)===1){

      this.setState({

        Licenseload:true
       
     });

   }
   else if(Number(id)===2){

        this.setState({
          Medicalload:true
     });
     
   }
  

   
        
  }

  ResetTabs(){    
    $("#tab1").removeClass('active');
    $("#tab_17").removeClass('active');

    $('#tab_16').addClass('active');
    $('#tab0').addClass('active'); 
  }
  
  handleKeyPress = e => {
    if (event.key === 'Enter') {
      this.SetSearchParameters()
    }
  };
  SetSearchParameters=async ()=>{

    if(this._isMounted && this.refs.search.value.trim()){
     
      await this.setState({
        
         isLoading: true,
         page:0,
         offset: 0,
         order:'ASC',
         sortByColumn:'firstName',  
       })
  
       this.search();
     }
     else{
      alert('Please Type Something in Search Bar')    
     }
  }
 
   
handleToggle = event => {

  if(this._isMounted){
  this.setState(state => ({ open: !state.open, anchorEl: event.currentTarget }));
  }

};

handleClose = event => {

  if (this.anchorEl.contains(event.target)) {
    return;
  }
  if(this._isMounted){
  this.setState({ open: false });
  }

};




async active(theme, e){ 
   

  if(this._isMounted){
   this.refs.search.value='';

   await  this.setState({
      searchVal: false,
       isLoading: true,
       Status:1,
       open: false,
       anchorEl: null,
       btnVal: theme,
       page:0,
       offset: 0,
       order:'ASC',
       sortByColumn:'firstName',  
       

     })
     this.fetchPaginationData();
    
   }

}

async Inactive(theme, e){


  if(this._isMounted){
   this.refs.search.value='';
   await  this.setState({
       searchVal: false,
       isLoading: true,
       Status:0,
       open: false,
       anchorEl: null,
       btnVal: theme,
       page:0,
       offset: 0,
       order:'ASC',
       sortByColumn:'firstName',  
       

     })
     this.fetchPaginationData();
    
   }
    
     

}


onTableChange= async (action, tableState) => {

console.log('action',action);
console.log('state',tableState)


switch (action) {

case 'changePage':

 this.changePage(tableState);
 break;


case 'changeRowsPerPage':

 this.changeRowsPerPage(tableState);
 break;

case 'sort':

 this.sort(tableState);
 break;

default:
 break;

}
}


changePage=async (tableState)=>{

var offset=this.state.offset;


if(tableState.page > this.state.page) // Next Icon Clicked >
{ 
 
 var tempOffset = offset+tableState.rowsPerPage
 if(this._isMounted){

 await this.setState({
   offset: tempOffset, //50
   limit: tableState.rowsPerPage, // 76
  
 
 });

 
 await  this.state.searchVal ? this.search() :this.fetchPaginationData();
 this.setState({
   page:tableState.page,
   
 })
}
}
else{
 var tempLimit = tableState.rowsPerPage;
 var tempOffset = offset-tempLimit;
 if(tempOffset < 0){
   tempOffset = 0
 }

if(this._isMounted){

 await this.setState({
   limit: tempLimit,
   offset: tempOffset, 
  
 
 });


   await   this.state.searchVal ? this.search() :this.fetchPaginationData();

   this.setState({
   page:tableState.page
 })
}
 



}

}


changeRowsPerPage=async (tableState)=>{

if(this._isMounted){
 await this.setState({
   limit:tableState.rowsPerPage, // 100
   offset: 0,
   rowsPerPage: tableState.rowsPerPage,
   page: 0     ,
 
 });
 this.state.searchVal ?this.search() :this.fetchPaginationData();

}

}


sort=async (tableState)=>{

var sortByColumn=this.state.sortByColumn;
var order=this.state.order;

var sortOrder = tableState['columns'][tableState.activeColumn].name ===  sortByColumn ? (order==='ASC' ? 'DESC' : 'ASC') : 'ASC' ;


if(this._isMounted){

await this.setState({
order:sortOrder,
sortByColumn:tableState['columns'][tableState.activeColumn].name    

});  


this.state.searchVal ?this.search() :this.fetchPaginationData();

}

}

fetchPaginationData=()=>{


//console.log('TableState Data',state.data)
var postData= {
'token':this.state.token,
'carrierId':this.state.carrierId,
'status':this.state.Status,
"offset": this.state.offset,
"limit": this.state.limit,
"sortOrder": this.state.order,
"sortBy": this.state.sortByColumn,
};

var arr=[];
//console.log('state',arr)
var status,count;
axios.post(BaseURL+'driver/findDriversByCarrierId',postData
,
{ 
headers: { 'Content-Type': 'application/json' }
}
).then(response=> {   
       console.log(response.data.data)
       if(response.data.error=== TokenErrorMsg){     
        localStorage.setItem('Token','') ;      
        this.props.history.push('/') ;       
            }
       
       
       count =response.data.data.count;

       for (const key in response.data.data.rows) {
         
        if (response.data.data.rows.hasOwnProperty(key)) {
          const element = response.data.data.rows[key];
          
          if(element.status === 1){
            status=<img style={{ width:"15px", height:"15px"}} src={Tick} alt='Active'/>
           } 
           else   {
            status=<img style={{ width:"15px", height:"15px"}} src={Cross} alt='Inactive'/>
           };
         var Obj=[
           element.id,
           element.firstName+' '+element.lastName,
           element.address.address+', '+(element.address.addressLineTwo ?element.address.addressLineTwo+', ':'')+element.address.city+', '+element.address.state.state+ ', '+element.address.zip ,    
       
           element.address.telephone ,
           status          
         ]
         
         arr.push(Obj);
          
         
          
        }
       
      }

      if(this._isMounted){
       this.setState({data:arr, isLoading: false ,count});
      }
      
     // console.log(arr);
     }).catch(function (error) {
       NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);         
       console.log(error);
     })         
     
   
     


}


    render() {
        const msgNone = {
            display: 'none'
          }
          const msgShow = {
            display: 'block',
          }
          const nonclick={
            cursor:' notAllowed',
            pointerEvents: 'none'
          }

          var year=[];

          for(var i=1900;i<2091;i++){
            year.push(i);
           }

        const { isLoading, data, rowsPerPage, open, searchVal ,count,page  } = this.state; 
        const options = {      
          responsive: "scroll",
          selectableRows: false,
          rowHover:true,
          print:false,
          download:false,
          filter:false,
          search:false,
          rowsPerPageOptions :[25,50,100],
          rowsPerPage:rowsPerPage,
          viewColumns:false,
          textLabels: {
            body: {          
              toolTip: "",
            }},       
           onCellClick:this.startEditing,

           serverSide:true,
           count: count,
           page: page,
           onTableChange: this.onTableChange,
          
        };   
       return(
         <div className="portlet box yellow-gold">
        <div className="portlet-title">
          <div className="caption"> <i className="fa fa-users"></i> <span className="caption-subject"> Drivers</span> </div>
          <div className="actions">
            <div className="btn-group">
                {/* <a className="btn btn-circle btn-icon-only dark " data-toggle="modal" href="#basic2" style={{margin:" 0px 10px 0 0", lineHeight: "24px",  padding: "0 0", width: "28px", height: "28px"}}>
                    <i className="fa fa-question"></i>
                </a> */}
              <a  data-toggle="modal" href="#drivercarrier_info" className="btn sbold white dark" onClick={this.ResetTabs.bind(this)}> Add New <i className="fa fa-plus"></i> </a>
              <a  data-toggle="modal" href="#driverviewcarrier_info" className="btn sbold white dark" id='drivercarrierinfo' style={{display:'none'}} > </a>
             
            </div>
          </div>
        </div>
        <div className="portlet-body">
          <div className="table-toolbar">
            <div style={{textAlign: 'right', paddingRight:' 10px', paddingBottom: '8px'}}>
            <span style={{float: "left", paddingLeft: "8px",  marginTop: "6px"}}>
              <div className="btnShowall">
                  <Button
                    buttonRef={node => {
                      this.anchorEl = node;
                    }}
                    aria-owns={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={this.handleToggle}
                  >
                    {this.state.btnVal} &nbsp;&nbsp;
                          <span className="fa fa-chevron-down"></span>
                  </Button>
               
                  <Popper open={open} anchorEl={this.anchorEl} transition disablePortal className="dropdown">
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        id="menu-list-grow"
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={this.handleClose}>
                            <MenuList>
                              <MenuItem onClick={(e) => this.showall("Show All", e)}>Show All</MenuItem>
                              <MenuItem onClick={(e) => this.active("Active", e)}>Active</MenuItem>
                              <MenuItem onClick={(e) => this.Inactive("Inactive", e)}>Inactive</MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </div>
                
              </span>
              <div style={{marginRight: '0px'}}>
               
                <div className="searchField form-control">
                  <input id="search-field" ref="search" name="searchinput" type="text" placeholder="Search..." onChange={this.handleSearch} onKeyPress={this.handleKeyPress}/>
                  <span style={searchVal ? msgShow : msgNone} className="removeSearchBtn" onClick={(e) => this.showall("Show All", e)}><i className="fa fa-close"></i></span>
                </div>
                <input name="searchbutton" style={{marginRight: "10px"}} className="btn green-meadow" type="submit" value="SEARCH" onClick={this.SetSearchParameters}/>
               
                {/* <img alt='' id="excel_export" src="../assets/apps/img/excel_button.png" title="Export data to Excel" />  */}
              
                </div>
            </div>
          </div>
           {isLoading ? <p style={{textAlign:"center"}}><img src={f} alt='Loader'/></p>:
        //  <MuiThemeProvider theme={this.getMuiTheme()}>
          
        //       <div className='muitable'>
        //           <MUIDataTable
        //             // title={"ACME Employee list"}
        //             data={data}
        //             columns={this.columns}
        //             options={options}
        //           />
        //       </div>
        // </MuiThemeProvider >
        <MUiTable
           data={data}
           columns={this.columns}
           options={options}
          />
           }
        </div>
        <div className="modal fade" id="drivercarrier_info" data-backdrop="static" data-keyboard="false" tabIndex="-1"  aria-hidden="true">
       <div className="modal-dialog  modal-lg">
        <div className="modal-content">
            <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" onClick={this.cancel.bind(this)} aria-hidden="true"></button>
             
            </div>
            <div className="modal-body"> 
             
            <ul className="nav nav-tabs" hidden={this.state.hidden}>
              <li className="active border-green-jungle" id='tab0'> <a href="#tab_16" data-toggle="tab">Driver  </a> </li>
              <li className="border-green-jungle" id='tab1'> <a href="#tab_17" data-toggle="tab"> Documents </a> </li>
             
            </ul>
            <div className="tab-content">
              <div className="tab-pane active" id="tab_16">
              <div className="portlet box yellow-gold">
                      <div className="portlet-title">
                        <div className="caption"> <i className="fa fa-user"></i>Add Driver Information</div>
                        <div className="actions" style={this.state.readonly?{}:nonclick} >
                          <div className="btn-group" style={this.state.hidden === false ? msgShow : msgNone} >
                              <button className="btn sbold white dark" id='driverEditDiv' onClick={this.EditForm.bind(this)}  hidden={this.state.hidden} disabled ={this.state.hidden}>
                                  <i className="fa fa-pencil"></i> Edit
                              </button>
                          </div>
                        </div>
                      </div>
                      <div className="portlet-body form"> 
                      
                      <form  onSubmit={this.submituserRegistrationForm.bind(this)} id='driverform'  className="horizontal-form" autoComplete="off" >
                        <div className="form-body max_width800">
                          <div className="portlet-title">
                            <h2 className="h3"> Driver Info 
                            <span style={{float: 'right', paddingLeft:' 8px', marginTop: '2px'}} >
                            <div className="btn-group btn-group-devided" data-toggle="buttons" style={this.state.readonly?nonclick:msgShow} >
                              <label className="btn btn-transparent dark btn-outline btn-circle btn-sm active" id='driveractive' disabled={this.state.readonly} onClick={()=>this.setState({status:1})}>
                                <input type="radio" name="options" className="toggle" id="option1"   />
                                Active</label>
                              <label className="btn btn-transparent dark btn-outline btn-circle btn-sm" id='driverinactive' disabled={this.state.readonly} onClick={()=>this.setState({status:0})}>
                                <input type="radio" name="options"  className="toggle"  id="option2" />
                                Inactive</label>
                            </div>
                            </span>
                             </h2>
                            
                            <div className="clearfix"></div>
                            <hr/>
                          </div>

                          <div className="form-group row">
                            <label className="col-md-3 control-label">First Name: <span className="font-red-thunderbird" >*</span></label>
                            <div className="col-md-9">
                            <input type="text" ref="firstName" onChange={this.handleChange} name="firstName" className="form-control" placeholder=""   readOnly={this.state.readonly}/>
                            <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.firstName}</div>
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-md-3 control-label">Middle Name: <span className="font-red-thunderbird" ></span></label>
                            <div className="col-md-9">
                            <input type="text" ref="middleName" onChange={this.handleChange} name="middleName" className="form-control" placeholder=""   readOnly={this.state.readonly}/>
                            <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.middleName}</div>
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-md-3 control-label">Last Name: <span className="font-red-thunderbird" ></span></label>
                            <div className="col-md-9">
                            <input type="text" ref="lastName" onChange={this.handleChange} name="lastName" className="form-control" placeholder=""   readOnly={this.state.readonly}/>
                            <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.lastName}</div>
                            </div>
                          </div>

                          
                        
                          <div className="portlet-title">
                            <h2 className="h3"> Address Info </h2>
                            <hr/>
                          </div>
                          <div className="form-group row">
                            <label className="col-md-3 control-label">Address <span className="font-red-thunderbird" >*</span></label>
                            <div className="col-md-9">
                            <input type="text" ref="address" onChange={this.handleChange} name="address" className="form-control" placeholder=""   readOnly={this.state.readonly}/>
                            <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.address}</div>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-md-3 control-label">Address Line 2</label>
                            <div className="col-md-9">
                            <input type="text" ref="address2" onChange={this.handleChange} name="addressLineTwo" className="form-control" placeholder="" readOnly={this.state.readonly}/>
                            <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.addressLineTwo}</div>
                            </div>
                          </div>
                        
                          <div className="form-group row">
                            <label className="col-md-3 control-label">Country <span className="font-red-thunderbird" >*</span></label>
                            <div className="col-md-9">

                            
                              <select name="country" ref="country_id" className="form-control" title="Country" onChange={this.states.bind(this)} defaultValue=''   disabled={this.state.readonly} readOnly={this.state.readonly}>

                               <option value="" selected disabled hidden>Select Country</option>                              
                              {
                                this.state.countries.map(function (data) {
                                  return <option value={data.id}> {data.countryName} </option>;
                                })

                              }
                                                                
                              </select>
                              <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.country}</div>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-md-3 control-label">State <span className="font-red-thunderbird" >*</span></label>
                            <div className="col-md-9">
                            <select name="state" ref="province_id" className="form-control" title="State/Province"  defaultValue=''   disabled={this.state.readonly} readOnly={this.state.readonly}>
                            <option value="" selected disabled hidden>Select State</option>                              
                              {
                                this.state.states.map(function (data) {
                                  return <option value={data.id}> {data.state} </option>;
                                })

                              }
                            </select>
                            <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.state}</div>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-md-3 control-label">City <span className="font-red-thunderbird" >*</span></label>
                            <div className="col-md-9">
                            <input type="text" ref="city" onChange={this.handleChange} name="city" className="form-control" placeholder=""   readOnly={this.state.readonly}/>
                            <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.city}</div>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-md-3 control-label">Zip <span className="font-red-thunderbird" >*</span></label>
                            <div className="col-md-9">
                            <input type="text" ref="zip" onChange={this.handleChange} name="zip" className="form-control" placeholder=""   readOnly={this.state.readonly}/>
                            <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.zip}</div>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-md-3 control-label">Email</label>
                            <div className="col-md-9">
                            <input type="email" ref="email" onChange={this.handleChange} name="email" className="form-control" placeholder="" readOnly={this.state.readonly}/>
                            <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.email}</div>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-md-3 control-label">Telephone</label>
                            <div className="col-md-9 form-inline">
                            <input type="text" ref="telephone" id='Telephone' onChange={this.AutoFormatContact} name="telephone" className="form-control" placeholder="xxx-xxx-xxxx" readOnly={this.state.readonly}/>
                            <span className="help-inline"> EXT </span>  <input type="text"   name="ext"  onChange={this.handleChange} ref="ext" className="form-control" placeholder="" readOnly={this.state.readonly}/>
                            <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.telephone}</div>
                            <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.ext}</div>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-md-3 control-label">Fax</label>
                            <div className="col-md-9">
                            <input type="text" ref="fax" name="fax"  id='Fax' onChange={this.AutoFormatContact} className="form-control" placeholder="xxx-xxx-xxxx" readOnly={this.state.readonly}/>
                            <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.fax}</div>
                            </div>
                          </div>
                         

                         <div className="portlet-title">
                        <h2 className="h3 checkbox-inline" style={{padding: '0',margin:'0'}}> Driver Qualification File </h2>
                        <hr/>
                        </div>
                        <div className="portlet-title">
                        <h4 className="h4" style={{fontWeight: '700'}}> Driver License Info </h4>
                        <br/>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-3 control-label">License No. <span className="font-red-thunderbird" >*</span></label>
                            <div className="col-md-9">
                            <input type="text" ref="driverLicense" onChange={this.handleChange} name="DriverLicense" className="form-control" placeholder=""   readOnly={this.state.readonly}/>
                            <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.DriverLicense}</div>
                            </div>
                         </div>
                         <div className="form-group row">
                            <label className="col-md-3 control-label">State <span className="font-red-thunderbird" >*</span></label>
                            <div className="col-md-9">
                            <select name="bstate" ref="bprovince_id" className="form-control" defaultValue=''    disabled={this.state.readonly} readOnly={this.state.readonly}>
                            <option value=""  disabled hidden>Select State</option>                              
                              {
                                this.state.bstates.map(function (data) {
                                  return <option value={data.id}> {data.state} </option>;
                                })

                              }
                            </select>
                            <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.bstate}</div>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-md-3 control-label">Expiration Date</label>
                            <div className="col-md-9">

                                    <DatePicker
                                        className='form-control'                                                                                                                                                    
                                        selected={this.state.LicExpDate}
                                        onChange={ async (date)=> {
                                         await this.setState({
                                          LicExpDate: date
                                        });
                                      console.log(date);
                                      this.validateForm();
                                    }}
                                      readOnly={this.state.readonly}                                      />
                               <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.LicExpDate}</div>
                            </div>
                           
                          </div>




                        <div className="portlet-title">
                        <h4 className="h4" style={{fontWeight: '700'}}> Medical Certificate </h4>
                        <br/>
                        </div>

                        <div className="form-group row">
                            <label className="col-md-3 control-label">Expiration Date</label>
                            <div className="col-md-9">

                                    <DatePicker
                                        className='form-control'                                                                                                                 
                                        selected={this.state.MedExpDate}
                                        onChange={ async (date)=> { 
                                          await this.setState({
                                          MedExpDate: date
                                        });
                                      console.log(this.state.MedExpDate);
                                      this.validateForm();
                                    }}
                                      readOnly={this.state.readonly}                                      />
                              <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.MedExpDate}</div>
                            </div>
                          </div>



                         
                        </div>      
                        <div className="clearfix"></div>
                        <div className="form-actions right">
                          <button data-dismiss="modal" onClick={this.cancel.bind(this)} type="button" className="btn default">Cancel</button> &nbsp;
                          <button  disabled={this.state.readonly} type="submit" className="btn green-meadow">
                            {this.state.whileSave === false 
                                ? <i className='fa fa-save'></i> 
                                : <i className="fa fa-circle-o-notch fa-spin"></i>}                              
                            Save 
                          </button>
                        </div>
                      
                      </form>
                  </div>
                   </div>
                
              </div>
              <div className="tab-pane" id="tab_17">
              <div className="portlet box yellow-gold">
                  <div className="portlet-title">
                    <div className="caption"> <i className="fa fa-file"></i> Documents </div>
                  </div>
                  <div className="portlet-body form">
                  
                    <form action="#" className="horizontal-form" _lpchecked="1">
                      <div className="form-body max_width800">
                        <div className="form-group row">
                          <label className="col-md-4 control-label">Driver License </label>
                          <div className="col-md-8 fileinput ">


                              <div className="input-group input-large doc_custom">
                                  <div className="form-control uneditable-input input-fixed input-medium" data-trigger="fileinput">
                                      <i className="fa fa-file fileinput-exists"></i>&nbsp;
                                      <span className="fileinput-filename">{this.state.file.License.name}</span>
                                  </div>
                                  <span className="input-group-addon btn default btn-file">
                                      <span className="fileinput-new"> Upload file </span>                                      
                                      <input type="file" id='1' name='license'  onChange= {this.onFileChange}/> </span>
                                      {
                                        this.state.Licenseload?
                                        <button hidden={this.state.file.License.name?false:true }   className="input-group-addon btn green fileinput-exists"  id='1' onClick={this.download}> <i id='1'  style={{color:'#fff'}}  className="fa fa-eye"></i> </button>:
                                        <button className="input-group-addon btn green fileinput-exists"  id='1' > <i id='1'  style={{color:'#fff'}}  className='fa fa-circle-o-notch fa-spin'></i> </button> 
                                      }
                                     
                                      <button  hidden={this.state.file.License.name?false:true} className="input-group-addon btn red fileinput-exists"  id='1' onClick={this.deleteFile}> <i id='1' style={{color:'#fff'}} className="fa fa-trash"></i> </button>
                                     
                                      </div>  
                                      <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.license}</div>  


                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-md-4 control-label">Medical Certificate</label>

                           <div className="col-md-8 fileinput ">
                         
                              <div className="input-group input-large doc_custom">
                                  <div className="form-control uneditable-input input-fixed input-medium" data-trigger="fileinput">
                                      <i className="fa fa-file fileinput-exists"></i>&nbsp;
                                      <span className="fileinput-filename">{this.state.file.Medcertificate.name}</span>
                                  </div>
                                  <span className="input-group-addon btn default btn-file">
                                      <span className="fileinput-new"> Upload file </span>                                      
                                      <input type="file" id='2' name='certificate' onChange={this.onFileChange}/> </span>

                                      {
                                        this.state.Medicalload?
                                        <button hidden={this.state.file.Medcertificate.name?false:true} className="input-group-addon btn green fileinput-exists"  id='2' onClick={this.download}> <i id='2' style={{color:'#fff'}} className="fa fa-eye"></i> </button>:
                                        <button className="input-group-addon btn green fileinput-exists"  id='1' > <i id='1'  style={{color:'#fff'}}  className='fa fa-circle-o-notch fa-spin'></i> </button>                    


                                      }                                                 
                                      <button hidden={this.state.file.Medcertificate.name?false:true} className="input-group-addon btn red fileinput-exists"  id='2' onClick={this.deleteFile}> <i id='2' style={{color:'#fff'}} className="fa fa-trash"></i> </button>
                                      
                              </div>
                              <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.certificate}</div> 
                          </div>
                        
                        </div>
                        
                        
                      </div>
                      <div className="clearfix"></div>
                    
                    </form>
                  
                  </div>
              </div>
                </div>
              </div>
           
             
            </div>
          </div>
            </div>
        
       
    </div>
      <div className="modal fade" id="driverviewcarrier_info" data-backdrop="static" data-keyboard="false" tabIndex="-1" aria-hidden="true">
       <div className="modal-dialog  modal-lg"> 
        <div className="modal-content">
            <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" onClick={this.cancel.bind(this)} aria-hidden="true"></button>
             
            </div>
            <div className="modal-body"> 
              
            <ul className="nav nav-tabs">
              <li className="active border-green-jungle" id='viewtab0'> <a href="#tab_18" data-toggle="tab">Driver  </a> </li>
              <li className="border-green-jungle" id='viewtab1' > <a href="#tab_19" data-toggle="tab"> Documents </a> </li>             
            </ul>

            <div className="tab-content">
              <div className="tab-pane active" id="tab_18">
              <div className="portlet box yellow-gold">
                      <div className="portlet-title">
                        <div className="caption"> <i className="fa fa-truck"></i> Driver Information</div>
                        <div className="actions"style={this.state.readonly?{}:nonclick} >
                          <div className="btn-group"  >
                              <button className="btn sbold white dark" id='driverviewEditDiv' onClick={this.EditForm.bind(this)} >
                                  <i className="fa fa-pencil"></i> Edit
                              </button>
                          </div>
                        </div>
                      </div>
                      <div className="portlet-body form"> 
                      
                      <form  onSubmit={this.submitEditRegistrationForm.bind(this)} id='driverviewform' className="horizontal-form" autoComplete="off" >
                        <div className="form-body max_width800">
                          <div className="portlet-title">
                            <h2 className="h3"> Driver Info 
                            <span style={{float: 'right', paddingLeft:' 8px', marginTop: '2px'}} >
                            <div className="btn-group btn-group-devided" data-toggle="buttons" style={this.state.readonly?nonclick:msgShow} >
                              <label className="btn btn-transparent dark btn-outline btn-circle btn-sm " id='driveractiveLabel' disabled={this.state.readonly} onClick={()=>this.setState({status:1})}>
                                <input type="radio" name="options" className="toggle" id="option1"   />
                                Active</label>
                              <label className="btn btn-transparent dark btn-outline btn-circle btn-sm" id='driverinactiveLabel' disabled={this.state.readonly} onClick={()=>this.setState({status:0})}>
                                <input type="radio" name="options"  className="toggle"  id="option2" />
                                Inactive</label>
                            </div>
                            </span>
                             </h2>
                            
                            <div className="clearfix"></div>
                            <hr/>
                          </div>

                          <div className="form-group row">
                            <label className="col-md-3 control-label">First Name: <span className="font-red-thunderbird" >*</span></label>
                            <div className="col-md-9">
                            <input type="text" ref="modifyfirstName" onChange={this.handleChange} name="modifyfirstName" className="form-control" placeholder="" defaultValue={this.state.driverform.firstname}   readOnly={this.state.readonly}/>
                            <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.modifyfirstName}</div>
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-md-3 control-label">Middle Name: <span className="font-red-thunderbird" ></span></label>
                            <div className="col-md-9">
                            <input type="text" ref="modifymiddleName" onChange={this.handleChange} name="modifymiddleName" className="form-control" placeholder="" defaultValue={this.state.driverform.middlename}  readOnly={this.state.readonly}/>
                            <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.modifymiddleName}</div>
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-md-3 control-label">Last Name: <span className="font-red-thunderbird" ></span></label>
                            <div className="col-md-9">
                            <input type="text" ref="modifylastName" onChange={this.handleChange} name="modifylastName" className="form-control" placeholder="" defaultValue={this.state.driverform.lastname}  readOnly={this.state.readonly}/>
                            <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.modifylastName}</div>
                            </div>
                          </div>                         
                        

                          <div className="portlet-title">
                            <h2 className="h3"> Address Info </h2>
                            <hr/>
                          </div>
                          <div className="form-group row">
                            <label className="col-md-3 control-label">Address <span className="font-red-thunderbird" >*</span></label>
                            <div className="col-md-9">
                            <input type="text" ref="modifyaddress" onChange={this.handleChange} name="modifyaddress" className="form-control" placeholder="" defaultValue={this.state.driverform.address}    readOnly={this.state.readonly}/>
                            <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.modifyaddress}</div>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-md-3 control-label">Address Line 2</label>
                            <div className="col-md-9">
                            <input type="text" ref="modifyaddress2" onChange={this.handleChange} name="modifyaddressLineTwo" className="form-control" placeholder="" defaultValue={this.state.driverform.addressLineTwo}  readOnly={this.state.readonly}/>
                            <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.modifyaddressLineTwo}</div>
                            </div>
                          </div>
                        
                          <div className="form-group row">
                            <label className="col-md-3 control-label">Country <span className="font-red-thunderbird" >*</span></label>
                            <div className="col-md-9">

                            
                              <select name="modifycountry" ref="modifycountry_id" className="form-control" title="Country" onChange={this.modifystates.bind(this)}   disabled={this.state.readonly} readOnly={this.state.readonly}>

                                                        
                              {
                                this.state.countries.map(function (data) {
                                  if(this.state.driverform.countryid === data.id)
                                  return <option value={data.id} selected> {data.countryName} </option>;
                                  return <option value={data.id}> {data.countryName} </option>;
                                },this)

                              }
                                                                
                              </select>
                              <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.modifycountry}</div>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-md-3 control-label">State <span className="font-red-thunderbird" >*</span></label>
                            <div className="col-md-9">
                            <select name="modifystate" ref="modifyprovince_id" className="form-control" title="State/Province"    disabled={this.state.readonly} readOnly={this.state.readonly}>
                                                  
                              {
                                this.state.states.map(function (data) {
                                  if(this.state.driverform.stateid === data.id)
                                  return <option value={data.id} selected> {data.state} </option>;
                                  return <option value={data.id}> {data.state} </option>;
                                },this)

                              }
                            </select>
                            <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.modifystate}</div>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-md-3 control-label">City <span className="font-red-thunderbird" >*</span></label>
                            <div className="col-md-9">
                            <input type="text" ref="modifycity" onChange={this.handleChange} name="modifycity" className="form-control" placeholder="" defaultValue={this.state.driverform.city}    readOnly={this.state.readonly}/>
                            <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.modifycity}</div>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-md-3 control-label">Zip <span className="font-red-thunderbird" >*</span></label>
                            <div className="col-md-9">
                            <input type="text" ref="modifyzip" onChange={this.handleChange} name="modifyzip" className="form-control" placeholder="" defaultValue={this.state.driverform.zip}   readOnly={this.state.readonly}/>
                            <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.modifyzip}</div>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-md-3 control-label">Email</label>
                            <div className="col-md-9">
                            <input type="email" ref="modifyemail" onChange={this.handleChange} name="modifyemail" className="form-control" placeholder="" defaultValue={this.state.driverform.email}  readOnly={this.state.readonly}/>
                            <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.modifyemail}</div>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-md-3 control-label">Telephone</label>
                            <div className="col-md-9 form-inline">
                            <input type="text" ref="modifytelephone" id='modifyTelephone' onChange={this.AutoFormatContact} name="modifytelephone" className="form-control" defaultValue={this.state.driverform.phone}  placeholder="xxx-xxx-xxxx" readOnly={this.state.readonly}/>
                            <span className="help-inline"> EXT </span>  <input type="text"   name="modifyext"  onChange={this.handleChange} ref="modifyext" className="form-control" placeholder="" defaultValue={this.state.driverform.ext}  readOnly={this.state.readonly}/>
                            <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.modifytelephone}</div>
                            <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.modifyext}</div>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-md-3 control-label">Fax</label>
                            <div className="col-md-9">
                            <input type="text" ref="modifyfax" name="modifyfax"  id='modifyfax' onChange={this.AutoFormatContact} className="form-control" defaultValue={this.state.driverform.fax}  placeholder="xxx-xxx-xxxx" readOnly={this.state.readonly}/>
                            <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.modifyfax}</div>
                            </div>
                          </div>
                         

                         <div className="portlet-title">
                        <h2 className="h3 checkbox-inline" style={{padding: '0',margin:'0'}}> Driver Qualification File </h2>
                        <hr/>
                        </div>
                        <div className="portlet-title">
                        <h4 className="h4" style={{fontWeight: '700'}}> Driver License Info </h4>
                        <br/>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-3 control-label">License No.<span className="font-red-thunderbird" >*</span></label>
                            <div className="col-md-9">
                            <input type="text" ref="modifydriverLicense" onChange={this.handleChange} name="modifyDriverLicense" className="form-control" placeholder=""  defaultValue={this.state.driverform.license}   readOnly={this.state.readonly}/>
                            <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.modifyDriverLicense}</div>
                            </div>
                         </div>
                         <div className="form-group row">
                            <label className="col-md-3 control-label">State <span className="font-red-thunderbird" >*</span></label>
                            <div className="col-md-9">
                            <select name="modifybstate" ref="modifybprovince_id" className="form-control" title="State/Province"    disabled={this.state.readonly} readOnly={this.state.readonly}>
                                                    
                              {
                                this.state.bstates.map(function (data) {
                                  if(this.state.driverform.licensestate === data.id)
                                  return <option value={data.id} selected> {data.state} </option>;
                                  return <option value={data.id}> {data.state} </option>;
                                },this)

                              }
                            </select>
                            <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.modifybstate}</div>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-md-3 control-label">Expiration Date</label>
                            <div className="col-md-9">

                                 <DatePicker
                                        className='form-control'                                                                                                                                                    
                                        selected={this.state.modifyLicExpDate}
                                        onChange={ async (date)=> {
                                         await this.setState({
                                            modifyLicExpDate: date
                                        });
                                      console.log(date);
                                      this.validateForm();
                                    }}
                                      readOnly={this.state.readonly}                                      />
                               <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.modifyLicExpDate}</div>
                          
                            </div>
                          </div>




                        <div className="portlet-title">
                        <h4 className="h4" style={{fontWeight: '700'}}> Medical Certificate </h4>
                        <br/>
                        </div>

                        <div className="form-group row">
                            <label className="col-md-3 control-label">Expiration Date</label>
                            <div className="col-md-9">

                                     <DatePicker
                                        className='form-control'                                                                                                                 
                                        selected={this.state.modifyMedExpDate}
                                        onChange={ async (date)=> { 
                                          await this.setState({
                                            modifyMedExpDate: date
                                        });
                                      console.log(this.state.modifyMedExpDate);
                                      this.validateForm();
                                    }}
                                      readOnly={this.state.readonly}                                      />
                              <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.modifyMedExpDate}</div>
                          
                            </div>
                          </div>



                         
                        </div>      
                        <div className="clearfix"></div>
                        <div className="form-actions right">
                          <button  data-dismiss="modal" onClick={this.cancel.bind(this)} type="button" className="btn default">Cancel</button> &nbsp;
                          <button disabled={this.state.readonly}  type="submit" className="btn green-meadow">
                            {this.state.whileSave === false 
                                ? <i className='fa fa-save'></i> 
                                : <i className="fa fa-circle-o-notch fa-spin"></i>}                              
                            Save 
                          </button>
                        </div>
                     
                      </form>
                  </div>
                   </div>
                
              </div>
              <div className="tab-pane" id="tab_19">
              <div className="portlet box yellow-gold">
                  <div className="portlet-title">
                    <div className="caption"> <i className="fa fa-file"></i> Documents </div>
                  </div>
                  <div className="portlet-body form">
                  
                  <form action="#" className="horizontal-form" _lpchecked="1">
                    <div className="form-body max_width800">
                      <div className="form-group row">
                        <label className="col-md-4 control-label">Driver License </label>
                        <div className="col-md-8 fileinput ">


                            <div className="input-group input-large doc_custom">
                                <div className="form-control uneditable-input input-fixed input-medium" data-trigger="fileinput">
                                    <i className="fa fa-file fileinput-exists"></i>&nbsp;
                                    <span className="fileinput-filename">{this.state.file.License.name}</span>
                                </div>
                                <span className="input-group-addon btn default btn-file">
                                    <span className="fileinput-new"> Upload file </span>                                      
                                    <input type="file" id='1' name='license'  onChange= {this.onFileChange}/> </span>
                                    {
                                      this.state.Licenseload?
                                      <button hidden={this.state.file.License.name?false:true }   className="input-group-addon btn green fileinput-exists"  id='1' onClick={this.download}> <i id='1'  style={{color:'#fff'}}  className="fa fa-eye"></i> </button>:
                                      <button className="input-group-addon btn green fileinput-exists"  id='1' > <i id='1'  style={{color:'#fff'}}  className='fa fa-circle-o-notch fa-spin'></i> </button> 
                                    }
                                   
                                    <button  hidden={this.state.file.License.name?false:true} className="input-group-addon btn red fileinput-exists"  id='1' onClick={this.deleteFile}> <i id='1' style={{color:'#fff'}} className="fa fa-trash"></i> </button>
                                                
                            </div>      
                            <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.license}</div>                                              
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-md-4 control-label">Medical Certificate</label>

                         <div className="col-md-8 fileinput ">
                       
                            <div className="input-group input-large doc_custom">
                                <div className="form-control uneditable-input input-fixed input-medium" data-trigger="fileinput">
                                    <i className="fa fa-file fileinput-exists"></i>&nbsp;
                                    <span className="fileinput-filename">{this.state.file.Medcertificate.name}</span>
                                </div>
                                <span className="input-group-addon btn default btn-file">
                                    <span className="fileinput-new"> Upload file </span>                                      
                                    <input type="file" id='2' name='certificate' onChange={this.onFileChange}/> </span>

                                    {
                                      this.state.Medicalload?
                                      <button hidden={this.state.file.Medcertificate.name?false:true} className="input-group-addon btn green fileinput-exists"  id='2' onClick={this.download}> <i id='2' style={{color:'#fff'}} className="fa fa-eye"></i> </button>:
                                      <button className="input-group-addon btn green fileinput-exists"  id='1' > <i id='1'  style={{color:'#fff'}}  className='fa fa-circle-o-notch fa-spin'></i> </button>                    


                                    }                                                 
                                    <button hidden={this.state.file.Medcertificate.name?false:true} className="input-group-addon btn red fileinput-exists"  id='2' onClick={this.deleteFile}> <i id='2' style={{color:'#fff'}} className="fa fa-trash"></i> </button>
                                    
                            </div>

                            <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.certificate}</div>                        
                        </div>
                      
                      </div>
                      
                      
                    </div>
                    <div className="clearfix"></div>
                 
                  </form>
                
                </div>
              </div>
                </div>
              </div>
           
             
            </div>
          </div>
            </div>
        </div>
       
    </div>
   
     

     
    );
}
}

export default withRouter(DriverTab);