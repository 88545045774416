/* eslint-disable */

import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import MUiTable from "./MuiTable";
import axios from "axios";
import Sidebar from "./sidebar";
import Header from "./Head";
import BaseURL from "./config";
import { NotificationManager } from "react-notifications";
import f from "./Loading_icon.gif";
import TokenErrorMsg from "./errorMsg";
import { getPersistentTableState, setPersistentTableState } from './LocalStorage';

// import tenantUser from './TenantUserPermision';

import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng
  } from 'react-places-autocomplete';

const searchOptions = {
    componentRestrictions: { country: ['us'] },
    types: ['(regions)'],
}

class SearchLoad extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoading: false,
      page: 0,
      rowsPerPage: 50,
      token: "",
      offset: 0,
      limit: 50,
      order: "ASC",
      sortByColumn: "internalLoadNo",
      count: "",    
      address: '',
      addressLatLng: {},
      destAddress: '',
      destAddressLatLng: {},
      radius: '150',
      destRadius: '150'
    };
    this.startEditing = this.startEditing.bind(this);
  }

  columns = [
    {
      name: "ID",
      options: {
        display: false
      }
    },

    {
      name: "internalLoadNo",
      label: "Load#",
      options: {
        sort: true
      }
    },    
    {
      name: "customerLoadNo",
      label: "Customer Load#",
      options: {
        sort: true
      }
    },    
    {
      name: "scheduleFrom",
      label: "Pickup Date",
      options: {
        sort: true
      }
    },
    {
      name: "pickupCity",
      label: "Pickup City, State",
      options: {
        sort: true
      }
    },
    {
      name: "scheduleTo",
      label: "Drop off Date",
      options: {
        sort: true
      }
    },
    {
      name: "city",
      label: "Delivery City, State",
      options: {
        sort: true
      }
    },
    {
      name: "customerName",
      label: "Customer",
      options: {
        sort: true
      }
    },
    {
      name: "name",
      label: "Contact Name",
      options: {
        sort: true
      }
    },    
    {
      name: "cellphone",
      label: "Phone",
      options: {
        sort: true
      }
    },
    {
      name: "telephone",
      label: "Telephone",
      options: {
        sort: true
      }
    },
    {
      name: "email",
      label: "Email",
      options: {
        sort: true
      }
    },
    {
      name: "distance",
      label: "DH–O",
      options: {
        sort: true
      }
    },
    {
      name: "destDistance",
      label: "DH–D",
      options: {
        sort: true
      }
    }
  ];

  async componentDidMount() {
    const token = localStorage.getItem("Token");
    this._isMounted = true;

    const tableState = getPersistentTableState('searchLoad');

    this.columns.find(col => col.name === tableState.sortByColumn)
    .options
    .sortDirection = tableState.order.toLowerCase();

    if (this._isMounted) {
      await this.setState({
        token: token,
        order: tableState.order,
        sortByColumn: tableState.sortByColumn,
        isLoading: tableState.address || tableState.destAddress ? true : false,
        page: tableState.page,
        offset: tableState.offset,
        limit: tableState.limit,
        rowsPerPage: tableState.limit,
        address: tableState.address,
        addressLatLng: tableState.addressLatLng,
        destAddress: tableState.destAddress,
        destAddressLatLng: tableState.destAddressLatLng,
        radius: tableState.radius,
        destRadius: tableState.destRadius
      });
      tableState.address || tableState.destAddress ? this.search() : {};
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async search() {

    var arr = [], count;

    this.setLocalStorage();

    await axios
      .post(BaseURL + "customerLoad/findByOriginAndDestination", {
        offset: this.state.offset,
        limit: this.state.limit,
        sortOrder: this.state.order,
        sortBy: this.state.sortByColumn,
        token: this.state.token,
        origin: this.state.address ? this.state.addressLatLng : {},
        radius: this.refs.radius.value,
        destination: this.state.destAddress ? this.state.destAddressLatLng : {},
        destinationRadius: this.refs.destRadius.value,
      })
      .then(response => {
        console.log(response);
        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem("Token", "");
          this.props.history.push("/");
        }        

        count = response.data.data.count;
        for (const key in response.data.data.rows) {
          if (response.data.data.rows.hasOwnProperty(key)) {
            const element = response.data.data.rows[key];
            var PickupDate = "",
              PickupCityState = "",
              DropOfDate = "",
              DelivCityState = "";

            element.loadPickupAndDeliveryDetails.forEach(stop => {
              if (stop.stopType === "pickup") {
                var date = new Date(stop.scheduleFrom);
                date = [
                  ("0" + (date.getMonth() + 1)).slice(-2),
                  ("0" + date.getDate()).slice(-2),
                  date.getFullYear()
                ].join("/");
                PickupDate = PickupDate ? PickupDate : date;

                var PickupCity =
                  stop.shipper &&
                    stop.shipper.address &&
                    stop.shipper.address.city
                    ? stop.shipper.address.city + ", "
                    : "";
                var PickupState =
                  stop.shipper &&
                    stop.shipper.address &&
                    stop.shipper.address.state
                    ? stop.shipper.address.state.stateAbbrivation
                    : "";

                PickupCityState = PickupCityState
                  ? PickupCityState
                  : PickupCity + PickupState;
              } else if (stop.stopType === "delivery") {
                var date = new Date(stop.scheduleTo);
                date = [
                  ("0" + (date.getMonth() + 1)).slice(-2),
                  ("0" + date.getDate()).slice(-2),
                  date.getFullYear()
                ].join("/");
                DropOfDate = date;

                var DelivCity =
                  stop.shipper &&
                    stop.shipper.address &&
                    stop.shipper.address.city
                    ? stop.shipper.address.city + ", "
                    : "";
                var DelivState =
                  stop.shipper &&
                    stop.shipper.address &&
                    stop.shipper.address.state
                    ? stop.shipper.address.state.stateAbbrivation
                    : "";

                DelivCityState = DelivCity + DelivState;
              }
            });          

            var Obj = [
              element.id,
              element.internalLoadNo,
              element.customerLoadNo,
              PickupDate,
              PickupCityState,
              DropOfDate,
              DelivCityState,
              element.customer ? element.customer.customerName : "",
              element.customerContact ? element.customerContact.name : "",
              element.customerContact ? element.customerContact.cellphone : "",
              element.customerContact ? element.customerContact.telephone : "",
              element.customerContact ? element.customerContact.email : "",
              element.origin.city ? element.origin.distance : "—",
              element.destination.city ? element.destination.distance : "—",
            ];
            arr.push(Obj);
          }
        }
      })
      .catch(function (error) {
        NotificationManager.error(
          "Something Went Wrong Server Is Not Responding ",
          "Error Message",
          3000
        );
        console.log(error);
      });

    if (this._isMounted) {
      this.setState({ data: arr, isLoading: false, count });
    }

  }

  startEditing(colData, cellMeta) {
    if (window.getSelection().toString()) {
      return false;
    } else if (cellMeta.colIndex === 1) {
      var data = this.state.data;
      var id = data[cellMeta.dataIndex][0];
      // console.log('Cell meta ',colData,cellMeta,data[cellMeta.dataIndex])
      this.props.history.push("/EditLoad/" + id + "/searchLoad");
    }
  } 

  handleKeyPress = e => {
    if (event.key === "Enter") {
      this.SetSearchParameters();
    }
  };

  SetSearchParameters = async () => {
    let address = this.state.address.trim();
    let destAddress = this.state.destAddress.trim();

    let radius = this.refs.radius.value;
    let destRadius = this.refs.destRadius.value;

    if (address || destAddress) {
      if (address && radius <= 0) {
        alert("Origin/Destination Location and Radius>0 are required");
      }
      else if (destAddress && destRadius <= 0) {
        alert("Origin/Destination Location and Radius>0 are required");
      }
      else if (this._isMounted) {
        await this.setState({
          isLoading: true,
          page: 0,
          offset: 0,
          order: "ASC",
          sortByColumn: "internalLoadNo",
        });
        this.search();
      }
    }
    else {
      alert("Origin/Destination Location and Radius>0 are required");
    }
  };

  onTableChange = async (action, tableState) => {
    console.log("action", action);
    console.log("state", tableState);

    switch (action) {
      case "changePage":
        this.changePage(tableState);
        break;

      case "changeRowsPerPage":
        this.changeRowsPerPage(tableState);
        break;

      case "sort":
        this.sort(tableState);
        break;

      default:
        break;
    }
  };

  changePage = async tableState => {
    var offset = this.state.offset;

    if (tableState.page > this.state.page) {
      // Next Icon Clicked >
      var tempOffset = offset + tableState.rowsPerPage;
      if (this._isMounted) {
        await this.setState({
          offset: tempOffset, //50
          limit: tableState.rowsPerPage, // 76
          page: tableState.page
        });

        this.setLocalStorage();      
        
      }
    } else {
      var tempLimit = tableState.rowsPerPage;
      var tempOffset = offset - tempLimit;
      if (tempOffset < 0) {
        tempOffset = 0;
      }

      if (this._isMounted) {
        await this.setState({
          limit: tempLimit,
          offset: tempOffset,
          page: tableState.page
        });

        this.setLocalStorage();      
      }
    }
  };

  changeRowsPerPage = async tableState => {
    if (this._isMounted) {
      await this.setState({
        limit: tableState.rowsPerPage, // 100
        offset: 0,
        rowsPerPage: tableState.rowsPerPage,
        page: 0
      });
      this.setLocalStorage();      
    }
  };

  sort = async tableState => {
    var sortByColumn = this.state.sortByColumn;
    var order = this.state.order;

    var sortOrder =
      tableState["columns"][tableState.activeColumn].name === sortByColumn
        ? order === "ASC"
          ? "DESC"
          : "ASC"
        : "ASC";

    if (this._isMounted) {
      await this.setState({
        order: sortOrder,
        sortByColumn: tableState["columns"][tableState.activeColumn].name
      });

      this.setLocalStorage();
    }
  };

  handleAddressChange = address => {
    if(this._isMounted){
      this.handleSelect(address);
    }
  };
 
  handleSelect = address => {
    if(this._isMounted){
      this.setState({ address: address }, () => this.setLocalStorage());
      geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => this.setState({addressLatLng: latLng}))
      .catch(error => console.error('Error', error));
    }     
  };

  handleDestAddressChange = address => {
    if(this._isMounted){
      this.handleDestSelect(address);
    }
  };
 
  handleDestSelect = address => {
    if(this._isMounted){
      this.setState({ destAddress: address }, () => this.setLocalStorage());
      geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => this.setState({destAddressLatLng: latLng}))
      .catch(error => console.error('Error', error));
    }     
  };

  setLocalStorage = () => {

    const table = {
      order: this.state.order,
      sortByColumn: this.state.sortByColumn,
      page: this.state.page,
      offset: this.state.offset,
      limit: this.state.limit,
      address: this.state.address,
      addressLatLng: this.state.addressLatLng,
      destAddress: this.state.destAddress,
      destAddressLatLng: this.state.destAddressLatLng,
      radius: this.state.radius,
      destRadius: this.state.destRadius
    };

    setPersistentTableState('searchLoad', table);

    this.columns = this.columns.map(col => {
      col.name === table.sortByColumn ?
      col.options.sortDirection = table.order.toLowerCase() :
      delete col.options.sortDirection;
      return col;
    });

  }

  render() {
    const {
      isLoading,
      data,
      rowsPerPage,      
      count,
      page
    } = this.state;

    const options = {
      responsive: "scroll",
      selectableRows: false,
      rowHover: true,
      print: false,
      download: false,
      filter: false,
      search: false,
      rowsPerPageOptions: [25, 50, 100],
      rowsPerPage: rowsPerPage,
      viewColumns: false,
      textLabels: {
        body: {
          toolTip: ""
        }
      },
      onCellClick: this.startEditing,

      // serverSide: true,
      // count: count,
      page: page,
      onTableChange: this.onTableChange
    };

    const crossN = {
      display: "none"
    };
    const crossS = {
      display: "block"
    };
      return (
        <div className="page-container-bg-solid page-header-fixed page-sidebar-closed-hide-logo page-md">
          <Header title="| Load Details" />
          <div className="page-container">
            <Sidebar />
            <div className="page-content-wrapper">
              <div className="page-content">
                <div className="row">
                  <div className="col-md-12">
                    <div className="portlet box yellow-gold">
                      <div className="portlet-title">
                        <div className="caption">
                          
                          <i className="fa fa-search"></i>
                          <span className="caption-subject">
                            
                            Load Details
                          </span>
                        </div>
                        {/* <div className="actions">
                          <div className="btn-group">
                            <Link
                              to="/AddLoad"
                              id="ab"
                              className="btn sbold white dark"
                            >
                              
                              Add New <i className="fa fa-plus"></i>
                            </Link>
                          </div>
                        </div> */}
                      </div>
                      <div className="portlet-body">
                        <div className="table-toolbar">
                          <span>
                              <PlacesAutocomplete
                                searchOptions={searchOptions}
                                value={this.state.address}
                                onChange={this.handleAddressChange}
                                onSelect={this.handleSelect}
                                onError={(e)=>console.log(e)}
                                clearItemsOnError={true}
                                
                              >
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                               <div className="form-group">                              
                                <div className="col-md-4">
                                <input type="text" ref="address" {...getInputProps({                                       
                                        className: 'location-search-input',                                       
                                      })}  name="address"   className="form-control" placeholder="Origin Location" />                                      
                                
                                <div className="autocomplete-dropdown-container" style={{position: "absolute", zIndex: 9, background: "white", width: "92%"}}>
                                      {loading && <div className="suggestion-item">Loading...</div>}
                                      {suggestions.map(suggestion => {
                                        const className = suggestion.active
                                          ? 'suggestion-item--active'
                                          : 'suggestion-item';
                                        return (
                                          <div
                                            {...getSuggestionItemProps(suggestion, {
                                              className,                                              
                                              
                                            })}
                                          >
                                            <span >{ suggestion.description}</span>
                                          </div>
                                        );
                                      })}
                                    </div>
                                    
                                   </div>
                                  </div>
                                )}
                              </PlacesAutocomplete>

                              <div
                                className="searchField"
                                style={{ width: "140px" , border: "none"}}
                              >
                                    <input
                                    ref="radius"
                                    name="searchinput"
                                    type="text"
                                    placeholder="Radius"
                                    className="form-control"
                                    style={{ border : "1px solid #c2cad8", height: "auto" }}
                                    value={this.state.radius}
                                    onChange={(e) => this.setState({radius: e.target.value})}
                                    />                                    
                              </div>

                              <PlacesAutocomplete
                                searchOptions={searchOptions}
                                value={this.state.destAddress}
                                onChange={this.handleDestAddressChange}
                                onSelect={this.handleDestSelect}
                                onError={(e)=>console.log(e)}
                                clearItemsOnError={true}
                                
                              >
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                               <div className="form-group">                              
                                <div className="col-md-4">
                                <input type="text" ref="address" {...getInputProps({                                       
                                        className: 'location-search-input',                                       
                                      })}  name="address"   className="form-control" placeholder="Destination Location" />                                      
                                
                                <div className="autocomplete-dropdown-container" style={{position: "absolute", zIndex: 9, background: "white", width: "92%"}}>
                                      {loading && <div className="suggestion-item">Loading...</div>}
                                      {suggestions.map(suggestion => {
                                        const className = suggestion.active
                                          ? 'suggestion-item--active'
                                          : 'suggestion-item';
                                        return (
                                          <div
                                            {...getSuggestionItemProps(suggestion, {
                                              className,                                              
                                              
                                            })}
                                          >
                                            <span >{ suggestion.description}</span>
                                          </div>
                                        );
                                      })}
                                    </div>
                                    
                                   </div>
                                  </div>
                                )}
                              </PlacesAutocomplete>

                              <div
                                className="searchField"
                                style={{ width: "140px" , border: "none"}}
                              >
                                    <input
                                    ref="destRadius"
                                    name="searchinput"
                                    type="text"
                                    placeholder="Radius"
                                    className="form-control"
                                    onKeyPress={this.handleKeyPress}
                                    style={{ border : "1px solid #c2cad8", height: "auto" }}
                                    value={this.state.destRadius}
                                    onChange={(e) => this.setState({destRadius: e.target.value})}
                                    />                                    
                              </div>

                              

                            <div style={{ marginRight: "0px", display: "inline"}}>                             
                              <input
                                name="searchbutton"
                                style={{ marginRight: "10px" }}
                                className="btn green-meadow"
                                type="submit"
                                value="SEARCH"
                                onClick={this.SetSearchParameters}
                              />
                            </div>
                          </span>
                        </div>
                        {
                        isLoading ?
                          <p style={{ textAlign: "center" }}><img src={f} alt='Loader' /></p> :
                          <MUiTable
                            data={data}
                            columns={this.columns}
                            options={options}
                          />
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );    
  }
}

export default withRouter(SearchLoad);
