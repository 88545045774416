import axios from 'axios';
import BaseURL from './config';

function finishEditinfo(config, callback, errorcallback){
  
    axios.post(BaseURL+'editModeInfo/finishEditMode', config)
    .then(res => {
      //do something
      if(callback != null){
         callback(res);
        
      }
    })
    .catch(err => {
      // catch error
      if(errorcallback != null){
         errorcallback(err);
        
      }
    })
}
 export default finishEditinfo;