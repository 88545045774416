/* eslint-disable */

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import MUiTable from "./MuiTable";
import { NotificationManager } from "react-notifications";
import axios from "axios";
import BaseURL from "./config";
import $ from "jquery";
import f from "./Loading_icon.gif";
import getEditinfo from "./StartEditMode";
import finishEditinfo from "./FinishEditMode";
import Select from "react-virtualized-select";
import TokenErrorMsg from "./errorMsg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import moment from "moment";
import AddDriver from "./DispatchDriver";
import AddTruck from "./DispatchTruck";
import AddTrailer from "./DispatchTrailer";
import tenantUser from "./TenantUserPermision";

class DispatchTab extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      status: 1,
      readonly: false,
      dispatchid: "",
      token: "",
      data: [],
      page: 0,
      rowsPerPage: 50,
      searchVal: false,

      fields: {},
      errors: {},
      whileSave: false,

      hidden: true,
      isLoading: false,

      offset: 0,
      limit: 50,
      order: "ASC",
      sortByColumn: "carrierName",
      count: "",
      CarrierSelectedOption: null,
      CarrierOptions: [],
      driver1SelectedOption: null,
      driver1Options: [],
      driver2SelectedOption: null,
      driver2Options: [],
      truckSelectedOption: null,
      truckOptions: [],
      trailerSelectedOption: null,
      trailerOptions: [],

      carrierCard: {},
      driver1Card: {},
      driver2Card: {},
      truckCard: {},
      trailerCard: {},
      carrierOptionsData: [],
      driver1OptionsData: [],
      driver2OptionsData: [],
      truckOptionsData: [],
      trailerOptionsData: [],
      dispatchPlainInfo: {},
      popUpInfoValues: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.submituserRegistrationForm = this.submituserRegistrationForm.bind(
      this
    );
    this.submit = this.submit.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.startEditing = this.startEditing.bind(this);
  }
  columns = [
    {
      name: "ID",
      options: {
        display: false,
      },
    },
    {
      name: "carrierName",
      label: "Carrier",
      options: {
        sortDirection: "asc",
        sort: true,
      },
    },
    {
      name: "dispatchRate",
      label: "Dispatch Rate",
      options: {
        sort: true,
      },
    },
    {
      name: "driverName",
      label: "Driver 1",
      options: {
        sort: true,
      },
    },
    {
      name: "driverName",
      label: "Driver 2",
      options: {
        sort: true,
      },
    },
    {
      name: "truckNo",
      label: "Truck",
      options: {
        sort: true,
      },
    },
    {
      name: "trailerNo",
      label: "Trailer",
      options: {
        sort: true,
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        sort: false,
      },
    },
  ];

  onUnload = (event) => {
    event.preventDefault();
    var http = new XMLHttpRequest();

    var url = BaseURL + "editModeInfo/finishEditMode";

    var params =
      "dispatchDetailId=" +
      this.state.dispatchid +
      "&token=" +
      this.state.token;

    http.open("POST", url, true);

    http.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

    if (this.state.dispatchid) {
      http.send(params);
      var start = new Date().getTime();
      while (new Date().getTime() < start + 350);
    }
  };

  async componentDidMount() {
    this._isMounted = true;

    window.addEventListener("beforeunload", this.onUnload);
    var token = localStorage.getItem("Token");

    if (this._isMounted) {
      await this.setState({ token: token });
      this.showall();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    var config = {
      dispatchDetailId: this.state.dispatchid,
      token: this.state.token,
    };

    if (this.state.dispatchid) {
      finishEditinfo(
        config,
        (res) => {
          console.log(res);
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  async search() {
    if (this.refs.search.value.trim()) {
      var arr = [],
        count;
      await axios
        .post(BaseURL + "dispatch/findByName", {
          searchKeyword: this.refs.search.value.trim(),
          offset: this.state.offset,
          limit: this.state.limit,
          sortOrder: this.state.order,
          sortBy: this.state.sortByColumn,
          loadId: this.props.loadid,
          token: this.state.token,
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem("Token", "");
            this.props.history.push("/");
          }

          count = response.data.data.count;

          for (const key in response.data.data.dispatchDetail) {
            if (response.data.data.dispatchDetail.hasOwnProperty(key)) {
              const element = response.data.data.dispatchDetail[key];

              var driver1 = "",
                driver2 = "";

              element.dispatchDrivers[0].driverId
                ? element.dispatchDrivers[0].driverNo === 1
                  ? (driver1 = element.dispatchDrivers[0].driver.firstName)
                  : (driver2 = element.dispatchDrivers[0].driver.firstName)
                : "";
              element.dispatchDrivers[1].driverId
                ? element.dispatchDrivers[1].driverNo === 1
                  ? (driver1 = element.dispatchDrivers[1].driver.firstName)
                  : (driver2 = element.dispatchDrivers[1].driver.firstName)
                : "";
              var actionButton = (
                <button
                  type="button"
                  style={{
                    outline: "none",
                    backgroundColor: "#1BBC9B",
                    fontSize: "13px",
                    borderRadius: "6px",
                    color: "white",
                    padding: "8px 16px",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    this.handleDispatchActionButton(element);
                  }}
                >
                  <b> Dispatch </b>
                </button>
              );

              var Obj = [
                element.id,
                element.carrier.carrierName,
                element.dispatchRate,
                driver1,
                driver2,
                element.truck ? element.truck.truckNo : "",
                element.trailer ? element.trailer.trailerNo : "",
                actionButton,
              ];

              arr.push(Obj);
            }
          }

          // console.log(arr);
        })
        .catch(function (error) {
          NotificationManager.error(
            "Something Went Wrong Server Is Not Responding ",
            "Error Message",
            3000
          );
          console.log(error);
        });

      if (this._isMounted) {
        this.setState({ data: arr, isLoading: false, count });
      }
    } else {
      alert("Please Type Something in Search Bar");
    }
  }

  async showall(e) {
    this.refs.search.value = "";
    if (this._isMounted) {
      await this.setState({
        searchVal: false,
        isLoading: true,
        page: 0,
        offset: 0,
        order: "ASC",
        sortByColumn: "carrierName",
      });
      this.fetchPaginationData();
    }
  }

  async startEditing(colData, cellMeta) {
    if (window.getSelection().toString()) {
      return false;
    } else if (cellMeta.colIndex !== 1) {
      return;
    }
    if (this._isMounted) {
      this.setState({
        readonly: true,
        hidden: false,
      });
    }

    var data = this.state.data;
    var id = data[cellMeta.dataIndex][0];

    document.getElementById("dispatchform").click();
    console.log("herererer");

    await axios
      .post(BaseURL + "dispatch/findById", { id: id, token: this.state.token })
      .then((response) => {
        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem("Token", "");
          this.props.history.push("/");
        }
        //   console.log(response.data);

        var arr = response.data.data;
        arr.dispatchDetail
          ? this.props.checkedDispatchStopsCheckBox(arr.dispatchDetail.shippers)
          : "";
        this.refs.dispatchRate.value = arr.dispatchDetail.dispatchRate;

        var driver1Id =
          arr.dispatchDetail.dispatchDrivers[0].driverNo === 1
            ? arr.dispatchDetail.dispatchDrivers[0].driverId
            : arr.dispatchDetail.dispatchDrivers[1].driverNo === 1
              ? arr.dispatchDetail.dispatchDrivers[1].driverId
              : null;

        var driver2Id =
          arr.dispatchDetail.dispatchDrivers[0].driverNo === 2
            ? arr.dispatchDetail.dispatchDrivers[0].driverId
            : arr.dispatchDetail.dispatchDrivers[1].driverNo === 2
              ? arr.dispatchDetail.dispatchDrivers[1].driverId
              : null;

        var driverOptions = [];
        response.data.data.driver.rows.forEach((element) => {
          var obj = {
            value: element.id,
            label:
              element.firstName +
              " " +
              element.lastName +
              (element.address.address ? ", " + element.address.address : "") +
              (element.address.city ? ", " + element.address.city : "") +
              (element.address.state
                ? ", " + element.address.state.state
                : "") +
              (element.address.zip ? ", " + element.address.zip : ""),
          };

          driverOptions.push(obj);
        });

        var element = arr.carrierData;
        var carrierOption = {
          value: element.id,
          label:
            element.carrierName +
            (element.address.address ? ", " + element.address.address : "") +
            (element.address.city ? ", " + element.address.city : "") +
            (element.address.state ? ", " + element.address.state.state : "") +
            (element.address.zip ? ", " + element.address.zip : ""),
        };
        var carrierCard = {
          address: element.address.address,
          city: element.address.city,
          state: element.address.state.state,
        };

        var truckOptions = [];

        response.data.data.trucks.rows.forEach((element) => {
          var obj = {
            value: element.id,
            label:
              element.truckNo +
              " " +
              element.make.make +
              " " +
              element.model +
              ", " +
              element.licensePlateNo,
          };

          truckOptions.push(obj);
        });

        var trailerOptions = [];

        response.data.data.trailers.rows.forEach((element) => {
          var obj = {
            value: element.id,
            label:
              element.trailerNo +
              " " +
              element.make.make +
              " " +
              element.model +
              ", " +
              element.licensePlateNo,
          };

          trailerOptions.push(obj);
        });

        if (this._isMounted) {
          this.setState({
            dispatchid: id,

            CarrierSelectedOption: arr.carrierData.id,
            driver1SelectedOption: driver1Id,
            driver2SelectedOption: driver2Id,
            truckSelectedOption: arr.dispatchDetail.truckId,
            trailerSelectedOption: arr.dispatchDetail.trailerId,

            CarrierOptions: [carrierOption],
            driver1Options: driverOptions,
            driver2Options: driverOptions,
            truckOptions: truckOptions,
            trailerOptions: trailerOptions,

            carrierOptionsData: [arr.carrierData],
            driver1OptionsData: response.data.data.driver.rows,
            driver2OptionsData: response.data.data.driver.rows,
            truckOptionsData: response.data.data.trucks.rows,
            trailerOptionsData: response.data.data.trailers.rows,

            carrierCard: carrierCard,
          });

          this.loadDriver1Card(driver1Id);
          this.loadDriver2Card(driver2Id);
          this.loadTruckCard(arr.dispatchDetail.truckId);
          this.loadTrailerCard(arr.dispatchDetail.trailerId);
        }
      })
      .catch(function (error) {
        NotificationManager.error(
          "Something Went Wrong Server Is Not Responding ",
          "Error Message",
          3000
        );
        console.log(error);
      });
  }

  handleSearch = async () => {
    if (this.refs.search.value) {
      if (this._isMounted) {
        this.setState({ searchVal: true });
      }
    } else {
      this.showall(this);
    }
  };

  async cancel() {
    this.props.resetDispatchStopsCheckBox();
    var config = {
      dispatchDetailId: this.state.dispatchid,
      token: this.state.token,
    };

    if (config.dispatchDetailId && !this.state.readonly) {
      finishEditinfo(
        config,
        (res) => {
          console.log(res);
        },
        (err) => {
          console.log(err);
        }
      );
    }

    if (this._isMounted) {
      this.refs.dispatchRate.value = "";

      this.setState({
        readonly: false,
        hidden: true,
        errors: {},
        dispatchid: "",
        CarrierSelectedOption: null,
        driver1SelectedOption: null,
        driver2SelectedOption: null,
        truckSelectedOption: null,
        trailerSelectedOption: null,
        CarrierOptions: [],
        driver1Options: [],
        driver2Options: [],
        truckOptions: [],
        trailerOptions: [],

        carrierCard: {},
        driver1Card: {},
        driver2Card: {},
        truckCard: {},
        trailerCard: {},
      });
    }
  }

  EditForm(e) {
    var div = document.getElementById("EditDivDispatch");

    div.setAttribute("style", " cursor: not-allowed; pointer-events: none");

    var config = {
      dispatchDetailId: this.state.dispatchid,
      token: this.state.token,
    };

    getEditinfo(
      config,
      (res) => {
        console.log(res);
        if (res.data.status === 0) {
          this.setState({ readonly: false });

          div.removeAttribute("style");
        } else {
          div.removeAttribute("style");
          NotificationManager.warning(
            res.data.message,
            "Warning Message",
            3000
          );
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  async handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;

    if (this._isMounted) {
      await this.setState({
        fields,
      });
      this.validateForm();
    }
  }

  submituserRegistrationForm(e) {
    e.preventDefault();
    var send = false;

    if (this.validateAndSubmit() && this.validateForm()) {
      send = true;
    }
    if (send) {
      this.submit();
    }
  }

  validateAndSubmit() {
    let errors = {};
    let formIsValid = true;

    if (!this.state.CarrierSelectedOption) {
      formIsValid = false;
      errors["carrierName"] = "*Please Select Carrier";
      $("[name='carrierName']").focus();
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  validateForm() {
    // let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (this.refs.dispatchRate.value.length > 200) {
      formIsValid = false;
      errors["dispatchRate"] = "*Please do not enter more than 200 characters";
      $("[name='dispatchRate']").focus();
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  async submit() {
    this.refs.truckform.click();
    this.setState({ whileSave: true, hidden: false, readonly: true });
    var Id,
      shippers = "";
    const dispatchRate = this.refs.dispatchRate.value.trim();

    this.props.stopData.forEach((element) => {
      if (element.dispatchCheckBox) {
        shippers = (shippers ? shippers + "," : "") + element.stopNo;
      }
    });

    var dispatch = {
      loadId: this.props.loadid,
      token: this.state.token,
      id: this.state.dispatchid,
      dispatchRate: dispatchRate,
      dispatchCarrierId: this.state.CarrierSelectedOption,
      truckId: this.state.truckSelectedOption,
      trailerId: this.state.trailerSelectedOption,
      dispatchDrivers: [
        {
          driverId: this.state.driver1SelectedOption,
          driverNo: 1,
        },
        {
          driverId: this.state.driver2SelectedOption,
          driverNo: 2,
        },
      ],
      shippers: shippers.toString(),
    };

    if (this.state.dispatchid) {
      axios
        .post(BaseURL + "dispatch/update", dispatch)
        .then((response) => {
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem("Token", "");
            this.props.history.push("/");
          }
          console.log(response.data);
          NotificationManager.success(
            "Dispatch Details Updated Successfully",
            "Edited",
            3000
          );
          this.cancel();
          this.showall("Show All", this);
          this.setState({
            whileSave: false,
          });
        })
        .catch(function (error) {
          NotificationManager.error(
            "Something Went Wrong Server Is Not Responding ",
            "Error Message",
            3000
          );
          console.log(error);
        });
    } else {
      axios
        .post(BaseURL + "dispatch/save", dispatch)
        .then((response) => {
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem("Token", "");
            this.props.history.push("/");
          }
          console.log(response.data);
          Id = response.data.data.id;

          NotificationManager.success(
            "Dispatch Deatils Added Successfully",
            "Added",
            3000
          );
          this.cancel();
          this.showall("Show All", this);
          this.setState({
            dispatchid: Id,
            whileSave: false,
          });
        })
        .catch(function (error) {
          NotificationManager.error(
            "Something Went Wrong Server Is Not Responding ",
            "Error Message",
            3000
          );
          console.log(error);
        });
    }
  }

  handleKeyPress = (e) => {
    if (event.key === "Enter") {
      this.SetSearchParameters();
    }
  };

  SetSearchParameters = async () => {
    if (this._isMounted && this.refs.search.value.trim()) {
      await this.setState({
        isLoading: true,
        page: 0,
        offset: 0,
        order: "ASC",
        sortByColumn: "carrierName",
      });

      this.search();
    } else {
      alert("Please Type Something in Search Bar");
    }
  };

  onTableChange = async (action, tableState) => {
    console.log("action", action);
    console.log("state", tableState);

    switch (action) {
      case "changePage":
        this.changePage(tableState);
        break;

      case "changeRowsPerPage":
        this.changeRowsPerPage(tableState);
        break;

      case "sort":
        this.sort(tableState);
        break;

      default:
        break;
    }
  };

  changePage = async (tableState) => {
    var offset = this.state.offset;

    if (tableState.page > this.state.page) {
      // Next Icon Clicked >
      var tempOffset = offset + tableState.rowsPerPage;
      if (this._isMounted) {
        await this.setState({
          offset: tempOffset, //50
          limit: tableState.rowsPerPage, // 76
        });

        (await this.state.searchVal)
          ? this.search()
          : this.fetchPaginationData();
        this.setState({
          page: tableState.page,
        });
      }
    } else {
      var tempLimit = tableState.rowsPerPage;
      var tempOffset = offset - tempLimit;
      if (tempOffset < 0) {
        tempOffset = 0;
      }

      if (this._isMounted) {
        await this.setState({
          limit: tempLimit,
          offset: tempOffset,
        });

        (await this.state.searchVal)
          ? this.search()
          : this.fetchPaginationData();

        this.setState({
          page: tableState.page,
        });
      }
    }
  };

  changeRowsPerPage = async (tableState) => {
    if (this._isMounted) {
      await this.setState({
        limit: tableState.rowsPerPage, // 100
        offset: 0,
        rowsPerPage: tableState.rowsPerPage,
        page: 0,
      });
      this.state.searchVal ? this.search() : this.fetchPaginationData();
    }
  };

  sort = async (tableState) => {
    var sortByColumn = this.state.sortByColumn;
    var order = this.state.order;

    var sortOrder =
      tableState["columns"][tableState.activeColumn].name === sortByColumn
        ? order === "ASC"
          ? "DESC"
          : "ASC"
        : "ASC";

    if (this._isMounted) {
      await this.setState({
        order: sortOrder,
        sortByColumn: tableState["columns"][tableState.activeColumn].name,
      });

      this.state.searchVal ? this.search() : this.fetchPaginationData();
    }
  };

  fetchPaginationData = () => {
    var postData = {
      token: this.state.token,
      offset: this.state.offset,
      limit: this.state.limit,
      sortOrder: this.state.order,
      sortBy: this.state.sortByColumn,
      loadId: this.props.loadid,
    };

    var arr = [];
    var count;
    axios
      .post(BaseURL + "dispatch/", postData, {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem("Token", "");
          this.props.history.push("/");
        }

        let url = window.location.href
        const word = 'AddLoad';
        let sentence = url.includes(word)
        if (sentence === false) {
          this.props.dispatchExistanceHandler(response.data);
        }

        count = response.data.data.count;

        for (const key in response.data.data.dispatchDetail) {
          if (response.data.data.dispatchDetail.hasOwnProperty(key)) {
            const element = response.data.data.dispatchDetail[key];

            var driver1 = "",
              driver2 = "";

            element.dispatchDrivers[0].driverId
              ? element.dispatchDrivers[0].driverNo === 1
                ? (driver1 = element.dispatchDrivers[0].driver.firstName)
                : (driver2 = element.dispatchDrivers[0].driver.firstName)
              : "";
            element.dispatchDrivers[1].driverId
              ? element.dispatchDrivers[1].driverNo === 1
                ? (driver1 = element.dispatchDrivers[1].driver.firstName)
                : (driver2 = element.dispatchDrivers[1].driver.firstName)
              : "";

            var actionButton = (
              <button
                type="button"
                style={{
                  outline: "none",
                  backgroundColor: "#1BBC9B",
                  fontSize: "13px",
                  borderRadius: "6px",
                  color: "white",
                  padding: "8px 16px",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  this.handleDispatchActionButton(element);
                }}
              >
                <b> Dispatch </b>
              </button>
            );

            var Obj = [
              element.id,
              element.carrier.carrierName,
              element.dispatchRate,
              driver1,
              driver2,
              element.truck ? element.truck.truckNo : "",
              element.trailer ? element.trailer.trailerNo : "",
              actionButton,
            ];

            arr.push(Obj);
          }
        }

        if (this._isMounted) {
          this.setState({ data: arr, isLoading: false, count });
        }

        // console.log(arr);
      })
      .catch(function (error) {
        NotificationManager.error(
          "Something Went Wrong Server Is Not Responding ",
          "Error Message",
          3000
        );
      });
  };

  handleDispatchActionButton = (element) => {
    var shippers = element.shippers ? element.shippers.split(",") : [];
    var driver1 = "",
      driver2 = "";
    element.dispatchDrivers[0].driverId
      ? element.dispatchDrivers[0].driverNo === 1
        ? (driver1 = element.dispatchDrivers[0].driver.firstName)
        : (driver2 = element.dispatchDrivers[0].driver.firstName)
      : "";
    element.dispatchDrivers[1].driverId
      ? element.dispatchDrivers[1].driverNo === 1
        ? (driver1 = element.dispatchDrivers[1].driver.firstName)
        : (driver2 = element.dispatchDrivers[1].driver.firstName)
      : "";

    var dispatchPlainInfo = {
      driver1,
      driver2,
    };

    var value = `    Load #: ${this.props.loadNo}
    First Driver: ${driver1} 
    Second Driver: ${driver2}
    Freight Details: ${this.props.freightType.toUpperCase()} Temp: ${this.props.temp
      }  ${this.props.temperatureType.toUpperCase()} ${this.props.cycle.toUpperCase()}

    Pickup & Delivery Details:

    ${this.props.stopData.map((elem, index) => {
        if (elem.shipperId === null) {
          return shippers.map((shipperId) => {
            return Number(shipperId) === Number(elem.stopNo)
              ? ` Stop# ${elem.stopNo}` +
              " " +
              `${elem.stopType.toUpperCase()}` +
              "\n    " +
              moment(elem["scheduleFrom"]).utc().format("MM/DD/YYYY HH:mm") +
              "  to  " +
              moment(elem["scheduleTo"]).utc().format("MM/DD/YYYY HH:mm") +
              "\n    " +
              elem.address +
              "\n    " +
              "Phone#: " +
              "\n    " +
              elem['puNo'].map((el, ind) => {
                return `${elem["stopType"] === "pickup" ? "PU" : "DEL"} #${ind + 1}` +
                  " " +
                  el + "\n    "
              })
              +
              "\n    " +
              `Notes:` +
              " " +
              elem["notes"] +
              "\n\n   "
              : null;
          });
        } else {
          return shippers.map((shipperId) => {
            return Number(shipperId) === Number(elem.stopNo)
              ? `Stop# ${elem.stopNo}` +
              " " +
              `${elem.stopType.toUpperCase()}` +
              "\n    " +
              moment(elem["scheduleFrom"]).utc().format("MM/DD/YYYY HH:mm") +
              "  to  " +
              moment(elem["scheduleTo"]).utc().format("MM/DD/YYYY HH:mm") +
              "\n    " +
              elem["shipperCard"]["name"] +
              "\n    " +
              elem["shipperCard"]["address"] +
              (elem["shipperCard"]["city"]
                ? ", " + elem["shipperCard"]["city"]
                : "") +
              (elem["shipperCard"]["stateAbbr"]
                ? ", " + elem["shipperCard"]["stateAbbr"]
                : "") +
              (elem["shipperCard"]["zip"]
                ? ", " + elem["shipperCard"]["zip"]
                : "") +
              "\n    " +
              "Phone#: " +
              elem["shipperCard"]["telephone"] +
              "\n    " +
              elem['puNo'].map((el, ind) => {
                return `${elem["stopType"] === "pickup" ? "PU" : "DEL"} #${ind + 1}` +
                  " " +
                  el + "\n    "
              })
              +
              `Notes:` +
              " " +
              elem["notes"] +
              "\n\n    "
              : null;
          });
        }
      })}`
      .split(",")
      .join("");

    // for (let index = value.length-1; index > -1; index--) {

    //   var letter = value.charAt(index);
    //   if (letter === ','){
    //     value = value.substr(0, index) + '' + value.substr(index + 1);
    //   }
    //   else {
    //     break;
    //   }

    // }

    // ${this.props.stopData.map((elem, index) => {
    //   return (elem.shipperId ?
    //     (
    //       `Stop# ${elem.stopNo}` + ' ' + `${elem.stopType.toUpperCase()}` + '\n    ' +
    //       moment(elem['scheduleFrom']).format('MM/DD/YYYY HH:mm') + '  to  ' + moment(elem['scheduleTo']).format('MM/DD/YYYY HH:mm') + '\n    ' +
    //       elem['shipperCard']['name'] + '\n    ' +
    //       elem['shipperCard']['address'] + (elem['shipperCard']['city'] ? ', ' + elem['shipperCard']['city'] : '') + (elem['shipperCard']['stateAbbr'] ? ', ' + elem['shipperCard']['stateAbbr'] : '') + (elem['shipperCard']['zip'] ? ', ' + elem['shipperCard']['zip'] : '') + '\n    ' +
    //       'Phone#: ' + elem['shipperCard']['telephone'] + '\n    ' +
    //       `${elem['stopType'] === 'pickup' ? 'PU' : 'DEL'}#` + ' ' + elem['puNo'] + '\n    ' +
    //       `Notes:` + ' ' + elem['notes']
    //       + '\n\n   ') : null
    //   )
    // })}`.split(',Stop').join(' Stop');

    this.setState({
      dispatchPlainInfo,
      popUpInfoValues: value,
    });

    document.getElementById("plain_info").click();
  };

  handleChangeCarrier = (selectedOption) => {
    var event = selectedOption ? selectedOption.value : null;

    if (this._isMounted) {
      this.setState({ CarrierSelectedOption: event, errors: [] });
    }

    this.loadCarrierCard(event);
  };

  loadCarrierCard(e) {
    if (e) {
      var id = e;

      this.state.carrierOptionsData.forEach((element) => {
        if (element.id === Number(id)) {
          var data = {
            address: element.address.address,
            city: element.address.city,
            state: element.address.state.state,
          };

          this.setState({ carrierCard: data });
        }
      });
      this.loadDriverTruckTrailerDropdowns(id, "carrier");
    } else {
      if (this._isMounted) {
        this.setState({
          carrierCard: "",

          driver1Options: [],
          driver1SelectedOption: null,
          driver1Card: {},

          driver2Options: [],
          driver2SelectedOption: null,
          driver2Card: {},

          truckOptions: [],
          truckSelectedOption: null,
          truckCard: {},

          trailerOptions: [],
          trailerSelectedOption: null,
          trailerCard: {},
        });
      }
    }
  }

  loadDriverTruckTrailerDropdowns = (id, callFrom) => {
    axios
      .post(
        BaseURL + "carrierInformation/findByIdForDispatch",
        {
          token: this.state.token,
          carrierId: id,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then((response) => {
        // console.log(response.data)
        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem("Token", "");
          this.props.history.push("/");
        }

        var driverOptions = [],
          truckOptions = [],
          trailerOptions = [];

        response.data.data.driver.rows.forEach((element) => {
          var obj = {
            value: element.id,
            label:
              element.firstName +
              " " +
              element.lastName +
              (element.address.address ? ", " + element.address.address : "") +
              (element.address.city ? ", " + element.address.city : "") +
              (element.address.state
                ? ", " + element.address.state.state
                : "") +
              (element.address.zip ? ", " + element.address.zip : ""),
          };

          driverOptions.push(obj);
        });

        response.data.data.trucks.rows.forEach((element) => {
          var obj = {
            value: element.id,
            label:
              element.truckNo +
              " " +
              element.make.make +
              " " +
              element.model +
              ", " +
              element.licensePlateNo,
          };

          truckOptions.push(obj);
        });

        response.data.data.trailers.rows.forEach((element) => {
          var obj = {
            value: element.id,
            label:
              element.trailerNo +
              " " +
              element.make.make +
              " " +
              element.model +
              ", " +
              element.licensePlateNo,
          };

          trailerOptions.push(obj);
        });

        callFrom === "carrier"
          ? this.setState({
            driver1Options: driverOptions,
            driver1OptionsData: response.data.data.driver.rows,
            driver2Options: driverOptions,
            driver2OptionsData: response.data.data.driver.rows,
            trailerOptions: trailerOptions,
            trailerOptionsData: response.data.data.trailers.rows,
            truckOptions: truckOptions,
            truckOptionsData: response.data.data.trucks.rows,
            driver1SelectedOption: null,
            driver1Card: {},
            driver2SelectedOption: null,
            driver2Card: {},
            truckSelectedOption: null,
            truckCard: {},
            trailerSelectedOption: null,
            trailerCard: {},
          })
          : this.setState({
            driver1Options: driverOptions,
            driver1OptionsData: response.data.data.driver.rows,
            driver2Options: driverOptions,
            driver2OptionsData: response.data.data.driver.rows,
            trailerOptions: trailerOptions,
            trailerOptionsData: response.data.data.trailers.rows,
            truckOptions: truckOptions,
            truckOptionsData: response.data.data.trucks.rows,
          });
      })
      .catch(function (error) {
        NotificationManager.error(
          "Something Went Wrong Server Is Not Responding ",
          "Error Message",
          3000
        );
        console.log(error);
      });
  };

  handleChangedriver1 = (selectedOption) => {
    var event = selectedOption ? selectedOption.value : null;

    if (this._isMounted) {
      this.setState({ driver1SelectedOption: event });
    }

    this.loadDriver1Card(event);
  };

  loadDriver1Card(e) {
    if (e) {
      var id = e;
      var data;

      // console.log(this.state.driver1OptionsData);

      this.state.driver1OptionsData.forEach((element) => {
        if (element.id === Number(id)) {
          data = {
            address: element.address.address,
            city: element.address.city,
            state: element.address.state.state,
          };
        }
      });

      if (data && this._isMounted) {
        this.setState({ driver1Card: data });
      }
    } else {
      if (this._isMounted) {
        this.setState({ driver1Card: "" });
      }
    }
  }

  handleChangedriver2 = (selectedOption) => {
    var event = selectedOption ? selectedOption.value : null;

    if (this._isMounted) {
      this.setState({ driver2SelectedOption: event });
    }

    this.loadDriver2Card(event);
  };

  loadDriver2Card(e) {
    if (e) {
      var id = e;
      var data;

      this.state.driver2OptionsData.forEach((element) => {
        if (element.id === Number(id)) {
          data = {
            address: element.address.address,
            city: element.address.city,
            state: element.address.state.state,
          };
        }
      });

      if (data && this._isMounted) {
        this.setState({ driver2Card: data });
      }
    } else {
      if (this._isMounted) {
        this.setState({ driver2Card: "" });
      }
    }
  }

  handleChangetruck = (selectedOption) => {
    var event = selectedOption ? selectedOption.value : null;

    if (this._isMounted) {
      this.setState({ truckSelectedOption: event });
    }
    this.loadTruckCard(event);
  };

  loadTruckCard(e) {
    if (e) {
      var id = e;
      var data;

      this.state.truckOptionsData.forEach((element) => {
        if (element.id === Number(id)) {
          data = {
            truckNo: element.truckNo,
            make: element.make.make,
            model: element.model,
            licensePlateNo: element.licensePlateNo,
          };
        }
      });

      if (data && this._isMounted) {
        this.setState({ truckCard: data });
      }
    } else {
      if (this._isMounted) {
        this.setState({ truckCard: "" });
      }
    }
  }

  handleChangetrailer = (selectedOption) => {
    var event = selectedOption ? selectedOption.value : null;

    if (this._isMounted) {
      this.setState({ trailerSelectedOption: event });
    }
    this.loadTrailerCard(event);
  };

  loadTrailerCard(e) {
    if (e) {
      var id = e;
      var data;

      this.state.trailerOptionsData.forEach((element) => {
        if (element.id === Number(id)) {
          data = {
            trailerNo: element.trailerNo,
            make: element.make.make,
            model: element.model,
            licensePlateNo: element.licensePlateNo,
          };
        }
      });

      if (data && this._isMounted) {
        this.setState({ trailerCard: data });
      }
    } else {
      if (this._isMounted) {
        this.setState({ trailerCard: "" });
      }
    }
  }

  render() {
    const msgNone = {
      display: "none",
    };
    const msgShow = {
      display: "block",
    };
    const nonclick = {
      cursor: " notAllowed",
      pointerEvents: "none",
    };

    const { isLoading, data, rowsPerPage, searchVal, count, page } = this.state;
    const options = {
      responsive: "scroll",
      selectableRows: false,
      rowHover: true,
      print: false,
      download: false,
      filter: false,
      search: false,
      rowsPerPageOptions: [25, 50, 100],
      rowsPerPage: rowsPerPage,
      viewColumns: false,
      textLabels: {
        body: {
          toolTip: "",
        },
      },
      onCellClick: this.startEditing,

      serverSide: true,
      count: count,
      page: page,
      onTableChange: this.onTableChange,
    };

    return (
      <div className="portlet box yellow-gold">
        <div className="portlet-title">
          <div className="caption">
            {" "}
            <i className="fa fa-paper-plane"></i>{" "}
            <span className="caption-subject"> Dispatch Details</span>{" "}
          </div>
          <div className="actions">
            <div className="btn-group">
              <a
                data-toggle="modal"
                href="#dispatch_form"
                id="dispatchform"
                className="btn sbold white dark"
                style={this.props.role !== tenantUser ? {} : msgNone}
              >
                {" "}
                Add New <i className="fa fa-plus"></i>{" "}
              </a>
              <a
                data-toggle="modal"
                href="#dispatch_plain_info"
                id="plain_info"
                className="btn sbold white dark"
                style={msgNone}
              >
                {" "}
                Add New <i className="fa fa-plus"></i>{" "}
              </a>
              <a
                data-toggle="modal"
                href="#driver"
                id="driverPopUp"
                className="btn sbold white dark"
                style={msgNone}
              >
                {" "}
                Add New <i className="fa fa-plus"></i>{" "}
              </a>
              <a
                data-toggle="modal"
                href="#truck"
                id="truckPopUp"
                className="btn sbold white dark"
                style={msgNone}
              >
                {" "}
                Add New <i className="fa fa-plus"></i>{" "}
              </a>
              <a
                data-toggle="modal"
                href="#trailer"
                id="trailerPopUp"
                className="btn sbold white dark"
                style={msgNone}
              >
                {" "}
                Add New <i className="fa fa-plus"></i>{" "}
              </a>
            </div>
          </div>
        </div>
        <div className="portlet-body">
          <div className="table-toolbar">
            <div
              style={{
                textAlign: "right",
                paddingRight: " 10px",
                paddingBottom: "8px",
              }}
            >
              <div style={{ marginRight: "0px" }}>
                <div className="searchField form-control">
                  <input
                    id="search-field"
                    ref="search"
                    name="searchinput"
                    type="text"
                    placeholder="Search..."
                    onChange={this.handleSearch}
                    onKeyPress={this.handleKeyPress}
                  />
                  <span
                    style={searchVal ? msgShow : msgNone}
                    className="removeSearchBtn"
                    onClick={(e) => this.showall("Show All", e)}
                  >
                    <i className="fa fa-close"></i>
                  </span>
                </div>
                <input
                  name="searchbutton"
                  style={{ marginRight: "10px" }}
                  className="btn green-meadow"
                  type="submit"
                  value="SEARCH"
                  onClick={this.SetSearchParameters}
                />

                {/* <img alt='' id="excel_export" src="../assets/apps/img/excel_button.png" title="Export data to Excel" />  */}
              </div>
            </div>
          </div>
          {isLoading ? (
            <p style={{ textAlign: "center" }}>
              <img src={f} alt="Loader" />
            </p>
          ) : (
            <MUiTable data={data} columns={this.columns} options={options} />
          )}
        </div>
        <div
          className="modal fade"
          id="dispatch_form"
          data-backdrop="static"
          data-keyboard="false"
          tabIndex="-1"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  ref="truckform"
                  aria-hidden="true"
                  style={{ display: "none" }}
                ></button>

                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={this.cancel.bind(this)}
                  aria-hidden="true"
                ></button>
              </div>
              <div className="modal-body">
                <div className="tab-content">
                  <div className="tab-pane active" id="tab_7">
                    <div className="portlet box yellow-gold">
                      <div className="portlet-title">
                        <div className="caption">
                          {" "}
                          <i className="fa fa-paper-plane"></i>Dispatch Details{" "}
                        </div>
                        {this.props.role !== tenantUser ? (
                          <div
                            className="actions"
                            style={this.state.readonly ? {} : nonclick}
                          >
                            <div
                              className="btn-group"
                              style={
                                this.state.hidden === false ? msgShow : msgNone
                              }
                            >
                              <button
                                className="btn sbold white dark"
                                id="EditDivDispatch"
                                onClick={this.EditForm.bind(this)}
                                hidden={this.state.hidden}
                                disabled={this.state.hidden}
                              >
                                <i className="fa fa-pencil"></i> Edit
                              </button>
                            </div>
                          </div>
                        ) : null}
                      </div>
                      <div className="portlet-body form">
                        <form
                          onSubmit={this.submituserRegistrationForm.bind(this)}
                          ref="form"
                          className="horizontal-form"
                          autoComplete="off"
                        >
                          <div className="form-body max_width800">
                            <div className="portlet-title">
                              <h2 className="h3"> Carrier Details</h2>

                              <div className="clearfix"></div>
                              <hr />
                            </div>

                            <div className="form-group row">
                              <label className="col-md-3 control-label">
                                Carrier:{" "}
                                <span className="font-red-thunderbird">*</span>
                              </label>
                              <div className="col-md-9">
                                <div className="form-group row">
                                  <div className="col-md-12">
                                    <Select
                                      optionHeight={50}
                                      disabled={this.state.readonly}
                                      value={this.state.CarrierSelectedOption}
                                      options={this.state.CarrierOptions}
                                      onChange={this.handleChangeCarrier}
                                      onInputChange={(input) => {
                                        if (input.trim().length > 1) {
                                          axios
                                            .post(
                                              BaseURL +
                                              "carrierInformation/findByName",
                                              {
                                                status: 2,
                                                searchKeyword: input,
                                                token: this.state.token,
                                              }
                                            )
                                            .then((response) => {
                                              if (
                                                response.data.error ===
                                                TokenErrorMsg
                                              ) {
                                                localStorage.setItem(
                                                  "Token",
                                                  ""
                                                );
                                                this.props.history.push("/");
                                              }

                                              var arr = [];

                                              response.data.data.rows.forEach(
                                                (element) => {
                                                  var obj = {
                                                    value: element.id,
                                                    label:
                                                      element.carrierName +
                                                      (element.address.address
                                                        ? ", " +
                                                        element.address
                                                          .address
                                                        : "") +
                                                      (element.address.city
                                                        ? ", " +
                                                        element.address.city
                                                        : "") +
                                                      (element.address.state
                                                        ? ", " +
                                                        element.address.state
                                                          .state
                                                        : "") +
                                                      (element.address.zip
                                                        ? ", " +
                                                        element.address.zip
                                                        : ""),
                                                  };

                                                  arr.push(obj);
                                                }
                                              );

                                              this.setState({
                                                CarrierOptions: arr,
                                                carrierOptionsData:
                                                  response.data.data.rows,
                                              });
                                            })
                                            .catch(function (error) {
                                              NotificationManager.error(
                                                "Something Went Wrong Server Is Not Responding ",
                                                "Error Message",
                                                3000
                                              );
                                              console.log(error);
                                            });
                                        }
                                      }}
                                      placeholder="Search and Choose "
                                    />
                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.carrierName}
                                    </div>
                                  </div>
                                </div>

                                <div
                                  className="row"
                                  style={
                                    this.state.carrierCard.state
                                      ? msgShow
                                      : msgNone
                                  }
                                >
                                  <div className="col-md-12">
                                    <div className="card_info">
                                      <div className="row">
                                        <div className="col-md-12">
                                          <p>
                                            <i
                                              className="fa fa-map-marker"
                                              aria-hidden="true"
                                            ></i>
                                            &nbsp;
                                            {this.state.carrierCard.address}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-6">
                                          <p>
                                            <i
                                              className="fa fa-building"
                                              aria-hidden="true"
                                            ></i>
                                            &nbsp;
                                            {this.state.carrierCard.city}
                                          </p>
                                        </div>
                                        <div className="col-md-6">
                                          <p>
                                            <i
                                              className="fa fa-globe"
                                              aria-hidden="true"
                                            ></i>{" "}
                                            &nbsp;
                                            {this.state.carrierCard.state}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-md-3 control-label">
                                Dispatch Rate:{" "}
                                <span className="font-red-thunderbird"></span>
                              </label>
                              <div className="col-md-9">
                                <input
                                  type="number"
                                  ref="dispatchRate"
                                  onChange={this.handleChange}
                                  name="dispatchRate"
                                  className="form-control"
                                  placeholder=""
                                  readOnly={this.state.readonly}
                                />
                                <div
                                  style={{ color: "red" }}
                                  className="errorMsg"
                                >
                                  {this.state.errors.dispatchRate}
                                </div>
                              </div>
                            </div>

                            <div className="portlet-title">
                              <h2 className="h3">
                                {" "}
                                Driver and Equipment Details:{" "}
                              </h2>

                              <div className="clearfix"></div>
                              <hr />
                            </div>

                            <div className="form-group row">
                              <label className="col-md-3 control-label">
                                Driver 1:{" "}
                                <span className="font-red-thunderbird"></span>
                              </label>
                              <div className="col-md-9">
                                <div className="form-group row">
                                  <div className="col-md-9">
                                    <Select
                                      optionHeight={50}
                                      disabled={this.state.readonly}
                                      value={this.state.driver1SelectedOption}
                                      options={this.state.driver1Options}
                                      onChange={this.handleChangedriver1}
                                      onInputChange={(input) => {
                                        if (input.trim().length > 1) {
                                          var errors = this.state.errors;
                                          if (
                                            this.state.CarrierSelectedOption ===
                                            null
                                          ) {
                                            errors["driver1"] =
                                              "Please Select Carrier First";
                                            this.setState({ errors });
                                          } else {
                                            errors["driver1"] = "";
                                            this.setState({ errors });
                                          }
                                        }
                                      }}
                                      placeholder="Search and Choose "
                                    />
                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.driver1}
                                    </div>
                                  </div>
                                  <div
                                    className="col-md-3"
                                    style={this.state.readonly ? nonclick : {}}
                                  >
                                    {this.state.CarrierSelectedOption ? (
                                      <button
                                        className="btn btn-block green-meadow"
                                        type="button"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.refs.truckform.click();
                                          document
                                            .getElementById("driverPopUp")
                                            .click();
                                        }}
                                      >
                                        {" "}
                                        <i className="fa fa-plus"></i> Add
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>

                                <div
                                  className="row"
                                  style={
                                    this.state.driver1Card.state
                                      ? msgShow
                                      : msgNone
                                  }
                                >
                                  <div className="col-md-12">
                                    <div className="card_info">
                                      <div className="row">
                                        <div className="col-md-12">
                                          <p>
                                            <i
                                              className="fa fa-map-marker"
                                              aria-hidden="true"
                                            ></i>
                                            &nbsp;
                                            {this.state.driver1Card.address}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-6">
                                          <p>
                                            <i
                                              className="fa fa-building"
                                              aria-hidden="true"
                                            ></i>
                                            &nbsp;
                                            {this.state.driver1Card.city}
                                          </p>
                                        </div>
                                        <div className="col-md-6">
                                          <p>
                                            <i
                                              className="fa fa-globe"
                                              aria-hidden="true"
                                            ></i>{" "}
                                            &nbsp;
                                            {this.state.driver1Card.state}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-md-3 control-label">
                                Driver 2:{" "}
                                <span className="font-red-thunderbird"></span>
                              </label>
                              <div className="col-md-9">
                                <div className="form-group row">
                                  <div className="col-md-9">
                                    <Select
                                      optionHeight={50}
                                      disabled={this.state.readonly}
                                      value={this.state.driver2SelectedOption}
                                      options={this.state.driver2Options}
                                      onChange={this.handleChangedriver2}
                                      onInputChange={(input) => {
                                        if (input.trim().length > 1) {
                                          var errors = this.state.errors;
                                          if (
                                            this.state.CarrierSelectedOption ===
                                            null
                                          ) {
                                            errors["driver2"] =
                                              "Please Select Carrier First";
                                            this.setState({ errors });
                                          } else {
                                            errors["driver2"] = "";
                                            this.setState({ errors });
                                          }
                                        }
                                      }}
                                      placeholder="Search and Choose "
                                    />
                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.driver2}
                                    </div>
                                  </div>
                                  <div
                                    className="col-md-3"
                                    style={this.state.readonly ? nonclick : {}}
                                  >
                                    {this.state.CarrierSelectedOption ? (
                                      <button
                                        className="btn btn-block green-meadow"
                                        type="button"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.refs.truckform.click();
                                          document
                                            .getElementById("driverPopUp")
                                            .click();
                                        }}
                                      >
                                        {" "}
                                        <i className="fa fa-plus"></i> Add
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>

                                <div
                                  className="row"
                                  style={
                                    this.state.driver2Card.state
                                      ? msgShow
                                      : msgNone
                                  }
                                >
                                  <div className="col-md-12">
                                    <div className="card_info">
                                      <div className="row">
                                        <div className="col-md-12">
                                          <p>
                                            <i
                                              className="fa fa-map-marker"
                                              aria-hidden="true"
                                            ></i>
                                            &nbsp;
                                            {this.state.driver2Card.address}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-6">
                                          <p>
                                            <i
                                              className="fa fa-building"
                                              aria-hidden="true"
                                            ></i>
                                            &nbsp;
                                            {this.state.driver2Card.city}
                                          </p>
                                        </div>
                                        <div className="col-md-6">
                                          <p>
                                            <i
                                              className="fa fa-globe"
                                              aria-hidden="true"
                                            ></i>{" "}
                                            &nbsp;
                                            {this.state.driver2Card.state}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-md-3 control-label">
                                Truck:{" "}
                                <span className="font-red-thunderbird"></span>
                              </label>
                              <div className="col-md-9">
                                <div className="form-group row">
                                  <div className="col-md-9">
                                    <Select
                                      optionHeight={50}
                                      disabled={this.state.readonly}
                                      value={this.state.truckSelectedOption}
                                      options={this.state.truckOptions}
                                      onChange={this.handleChangetruck}
                                      onInputChange={(input) => {
                                        if (input.trim().length > 1) {
                                          var errors = this.state.errors;
                                          if (
                                            this.state.CarrierSelectedOption ===
                                            null
                                          ) {
                                            errors["truck"] =
                                              "Please Select Carrier First";
                                            this.setState({ errors });
                                          } else {
                                            errors["truck"] = "";
                                            this.setState({ errors });
                                          }
                                        }
                                      }}
                                      placeholder="Search and Choose "
                                    />
                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.truck}
                                    </div>
                                  </div>
                                  <div
                                    className="col-md-3"
                                    style={this.state.readonly ? nonclick : {}}
                                  >
                                    {this.state.CarrierSelectedOption ? (
                                      <button
                                        className="btn btn-block green-meadow"
                                        type="button"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.refs.truckform.click();
                                          document
                                            .getElementById("truckPopUp")
                                            .click();
                                        }}
                                      >
                                        {" "}
                                        <i className="fa fa-plus"></i> Add
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                                <div
                                  className="row"
                                  style={
                                    this.state.truckCard.truckNo
                                      ? msgShow
                                      : msgNone
                                  }
                                >
                                  <div className="col-md-12">
                                    <div className="card_info">
                                      <div className="row">
                                        <div className="col-md-12">
                                          <p>
                                            <i
                                              className="fa "
                                              aria-hidden="true"
                                            ></i>
                                            &nbsp;
                                            <b>Truck No:&nbsp; </b>{" "}
                                            {this.state.truckCard.truckNo}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-6">
                                          <p>
                                            <i
                                              className="fa "
                                              aria-hidden="true"
                                            ></i>
                                            &nbsp;
                                            <b>Make:&nbsp; </b>{" "}
                                            {this.state.truckCard.make}
                                          </p>
                                        </div>
                                        <div className="col-md-6">
                                          <p>
                                            <i
                                              className="fa "
                                              aria-hidden="true"
                                            ></i>{" "}
                                            &nbsp;
                                            <b>License Plate No:&nbsp; </b>{" "}
                                            {
                                              this.state.truckCard
                                                .licensePlateNo
                                            }
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-md-3 control-label">
                                Trailer:{" "}
                                <span className="font-red-thunderbird"></span>
                              </label>
                              <div className="col-md-9">
                                <div className="form-group row">
                                  <div className="col-md-9">
                                    <Select
                                      optionHeight={50}
                                      disabled={this.state.readonly}
                                      value={this.state.trailerSelectedOption}
                                      options={this.state.trailerOptions}
                                      onChange={this.handleChangetrailer}
                                      onInputChange={(input) => {
                                        if (input.trim().length > 1) {
                                          var errors = this.state.errors;
                                          if (
                                            this.state.CarrierSelectedOption ===
                                            null
                                          ) {
                                            errors["trailer"] =
                                              "Please Select Carrier First";
                                            this.setState({ errors });
                                          } else {
                                            errors["trailer"] = "";
                                            this.setState({ errors });
                                          }
                                        }
                                      }}
                                      placeholder="Search and Choose "
                                    />
                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.trailer}
                                    </div>
                                  </div>
                                  <div
                                    className="col-md-3"
                                    style={this.state.readonly ? nonclick : {}}
                                  >
                                    {this.state.CarrierSelectedOption ? (
                                      <button
                                        className="btn btn-block green-meadow"
                                        type="button"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.refs.truckform.click();
                                          document
                                            .getElementById("trailerPopUp")
                                            .click();
                                        }}
                                      >
                                        {" "}
                                        <i className="fa fa-plus"></i> Add
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                                <div
                                  className="row"
                                  style={
                                    this.state.trailerCard.trailerNo
                                      ? msgShow
                                      : msgNone
                                  }
                                >
                                  <div className="col-md-12">
                                    <div className="card_info">
                                      <div className="row">
                                        <div className="col-md-12">
                                          <p>
                                            <i
                                              className="fa "
                                              aria-hidden="true"
                                            ></i>
                                            &nbsp;
                                            <b>Trailer No:&nbsp; </b>{" "}
                                            {this.state.trailerCard.trailerNo}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-6">
                                          <p>
                                            <i
                                              className="fa "
                                              aria-hidden="true"
                                            ></i>
                                            &nbsp;
                                            <b>Make:&nbsp; </b>{" "}
                                            {this.state.trailerCard.make}
                                          </p>
                                        </div>
                                        <div className="col-md-6">
                                          <p>
                                            <i
                                              className="fa "
                                              aria-hidden="true"
                                            ></i>{" "}
                                            &nbsp;
                                            <b>License Plate No:&nbsp; </b>{" "}
                                            {
                                              this.state.trailerCard
                                                .licensePlateNo
                                            }
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="portlet-title">
                              <h2 className="h3">
                                {" "}
                                Pickup and Delivery Details:{" "}
                              </h2>
                              <div className="clearfix"></div>
                              <hr />
                            </div>
                            {this.props.stopData.map((element, index) => {
                              return element.shipperId ? (
                                <div
                                  className="form-group row"
                                  key={element.stopNo}
                                >
                                  <label className="col-md-3 control-label">
                                    Stop {element.stopNo}
                                  </label>
                                  <div className="col-md-1">
                                    <div
                                      className="checkbox-list"
                                      style={{
                                        display: "inline-block",
                                        verticalAlign: "22px",
                                      }}
                                    >
                                      <label className="checkbox-inline">
                                        <div className="checker">
                                          <span
                                            className={
                                              element.dispatchCheckBox
                                                ? "checked"
                                                : ""
                                            }
                                          >
                                            <input
                                              type="checkbox"
                                              onClick={() =>
                                                this.props.handleDispatchStopsCheckBox(
                                                  index
                                                )
                                              }
                                              value="option1"
                                              disabled={this.state.readonly}
                                            />
                                          </span>
                                        </div>
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-md-8">
                                    {element.ShipperOptions.map((shipper) => {
                                      if (shipper.value === element.shipperId) {
                                        return (
                                          <div key={element.shipperId}>
                                            <b>
                                              {" "}
                                              {element.stopType.toUpperCase()}{" "}
                                            </b>
                                            &nbsp; {shipper.label}
                                          </div>
                                        );
                                      }
                                    })}
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="form-group row"
                                  key={element.stopNo}
                                >
                                  <label className="col-md-3 control-label">
                                    Stop {element.stopNo}
                                  </label>
                                  <div className="col-md-1">
                                    <div
                                      className="checkbox-list"
                                      style={{
                                        display: "inline-block",
                                        verticalAlign: "22px",
                                      }}
                                    >
                                      <label className="checkbox-inline">
                                        <div className="checker">
                                          <span
                                            className={
                                              element.dispatchCheckBox
                                                ? "checked"
                                                : ""
                                            }
                                          >
                                            <input
                                              type="checkbox"
                                              onClick={() =>
                                                this.props.handleDispatchStopsCheckBox(
                                                  index
                                                )
                                              }
                                              value="option1"
                                              disabled={this.state.readonly}
                                            />
                                          </span>
                                        </div>
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-md-8">
                                    <div key={element.shipperId}>
                                      <b> {element.stopType.toUpperCase()} </b>
                                      &nbsp; {element.address}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <div className="clearfix"></div>
                          <div className="form-actions right">
                            <button
                              data-dismiss="modal"
                              type="button"
                              className="btn default"
                              onClick={this.cancel.bind(this)}
                            >
                              Cancel
                            </button>{" "}
                            &nbsp;
                            {this.props.role !== tenantUser ? (
                              <button
                                disabled={this.state.readonly}
                                type="submit"
                                className="btn green-meadow"
                              >
                                {this.state.whileSave === false ? (
                                  <i className="fa fa-save"></i>
                                ) : (
                                  <i className="fa fa-circle-o-notch fa-spin"></i>
                                )}
                                Save
                              </button>
                            ) : null}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="dispatch_plain_info"
          data-keyboard="false"
          tabIndex="-1"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                ></button>
              </div>
              <div className="modal-body">
                <div className="tab-content">
                  <div className="tab-pane active" id="tab_8">
                    <div className="portlet-body">
                      <form className="horizontal-form" autoComplete="off">
                        <div className="form-body max_width800">
                          <div className="portlet-title">
                            <h2 className="h3"> Dispatch Details </h2>
                            <hr />
                          </div>
                          <CopyToClipboard
                            text={this.state.popUpInfoValues}
                            onCopy={() => { }}
                          >
                            <button
                              className="btn-group"
                              type="button"
                              style={{
                                outline: "none",
                                backgroundColor: "rgb(27, 188, 155)",
                                fontSize: "13px",
                                borderRadius: "6px",
                                color: "white",
                                padding: "8px 16px",
                                cursor: "pointer",
                              }}
                            >
                              Copy To Clipboard &nbsp;
                              <i className="fa fa-clipboard"></i>
                            </button>
                          </CopyToClipboard>
                          <br />
                          <br />
                          <div className="form-group">
                            {/* <label htmlFor="Textarea1">Example textarea</label> */}
                            <textarea
                              className="form-control"
                              id="Textarea1"
                              rows="20"
                              readOnly
                              value={this.state.popUpInfoValues}
                              onChange={(e) => {
                                var value = e.target.value;
                                this.setState({
                                  popUpInfoValues: value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="clearfix"></div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="driver"
          data-backdrop="static"
          data-keyboard="false"
          tabIndex="-1"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-lg">
            <AddDriver
              carrierId={this.state.CarrierSelectedOption}
              loadDriverTruckTrailerDropdowns={
                this.loadDriverTruckTrailerDropdowns
              }
            />
          </div>
        </div>
        <div
          className="modal fade"
          id="truck"
          data-backdrop="static"
          data-keyboard="false"
          tabIndex="-1"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-lg">
            <AddTruck
              carrierId={this.state.CarrierSelectedOption}
              loadDriverTruckTrailerDropdowns={
                this.loadDriverTruckTrailerDropdowns
              }
            />
          </div>
        </div>
        <div
          className="modal fade"
          id="trailer"
          data-backdrop="static"
          data-keyboard="false"
          tabIndex="-1"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-lg">
            <AddTrailer
              carrierId={this.state.CarrierSelectedOption}
              loadDriverTruckTrailerDropdowns={
                this.loadDriverTruckTrailerDropdowns
              }
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(DispatchTab);
