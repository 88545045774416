/* eslint-disable */

import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import MUiTable from "./MuiTable";
import axios from "axios";
import Sidebar from "./sidebar";
import Header from "./Head";
import Select from "react-virtualized-select";
import BaseURL from "./config";
import { NotificationManager } from "react-notifications";
import f from "./Loading_icon.gif";
import TokenErrorMsg from "./errorMsg";
import "./filter.css";
import { CSVLink } from 'react-csv';
// import tenantUser from './TenantUserPermision';
import CustomFooter from "./CustomFooter";
import moment from "moment";
import DatePicker from "react-datepicker";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
// import { CircularProgress } from '@material-ui/core';

import {
  getPersistentTableState,
  setPersistentTableState,
} from "./LocalStorage";
class PickupDelieveryDetails extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
      this.state = {
      CustomerSelectedOption: null,
      CustomerOptions: [],
      CarrierSelectedOption: null,
      CarrierOptions: [],
      customerOptionsData: {},
      readyForCsvDownload: false,
      readyCsvDownloadFile: false,
      advanceSearch: false,
      advanceSearchFormValues: {
        sfCustomerName: "",
        sfCarrierName: "",
        sfPickupCity: "",
        sfDeliveryCity: "",
        sfShipper: "",
        sfReciever: "",
      },
      csvReportData: {
        data: [],
        headers: [
          { label: 'Load #', key: 'load_number' },
          { label: 'Customer Name', key: 'customer_name' },
          { label: 'Customer Load #', key: 'customer_load_number' },
          { label: 'Carrier Name', key: 'carrier_name' },
          { label: 'Pickup Date', key: 'pickup_date' },
          { label: 'Pickup City,State', key: 'pickup_city_state' },
          { label: 'Delivery Date', key: 'dropoff_date' },
          { label: 'Delivery City,State', key: 'dropoff_city_state' },
          { label: 'Status', key: 'load_status' }
        ],
        filename: 'AllCustomerLoads.csv',
      },
      showAdvanceFilter: false,
      data: [],
      loadPickupAndDeliveryDetails:[],
      open: false,
      status: "",
      isLoading: false,
      page: 0,
      rowsPerPage: 50,
      btnVal: "all",
      searchVal: false,
      token: "",
      offset: 0,
      limit: 50,
      order: "Desc",
      sortByColumn: "internalLoadNo",
      count: "",
      startDate: null,
      endDate: null,
      search: "",
      //
      dateDropDown: "pickup",
    };
    this.csvLinkEl = React.createRef();
    this.startEditing = this.startEditing.bind(this);
    this.setLocalStorage()
  console.log(this.state)
  }

  columns = [
    {
      name: "ID",
      options: {
        display: false,
      },
    },
    {
      name: "internalLoadNo",
      label: "Load#",
      options: {
        sort: true,
        sortDirection:"DESC"
      },
    },
    {
      name: "loadId",
      label: "Load#",
      options: {
        sort: true,
        sortDirection:"ASC",
        display:false
      },
    },
    
    {
      name: "customerLoadNo",
      label: "Customer Load#",
      options: {
        sort: true,
        sortDirection:"ASC"
      },
    },
    {
      name: "customerName",
      label: "Customer",
      options: {
        sort: true,
        sortDirection:"ASC"
      },
    },
    {
      name: "carrierName",
      label: "Carrier",
      options: {
        sort: true,
        sortDirection:"ASC"
      },
    },
    {
      name: "stopNo",
      label: "Stop#",
      options: {
        sort: true,
      },
    },{
      name: "loadStatus",
      label: "Status",
      options: {
        sort: true,
        display:true
      },
    },
    {
      name: "pickupCityState",
      label: "City, State",
      options: {
        sort: true,
      },
    },
    {
      name: "scheduleFrom",
      label: "From",
      options: {
        sort: true,
      },
    },
    {
      name: "scheduleTo",
      label: "To",
      options: {
        sort: true,
      },
    },
    {
      name: "verified",
      label: "Verified",
      options: {
        sort: true,
      },
    },
    {
      name: "eta",
      label: "ETA",
      options: {
        sort: true,
      },
    },
    {
      name: "driverCheckIn",
      label: "In",
      options: {
        sort: true,
      },
    },
    {
      name: "driverCheckOut",
      label: "Out",
      options: {
        sort: true,
      },
    },
    // {
    //   name: "delivCityState",
    //   label: "Delivery City, State",
    //   options: {
    //     sort: true,
    //   },
    // },
    
  ];

  async startDateHandler(startDate) {
    await this.setState({ startDate });
    this.setLocalStorage()
  }
  async endDateHandler(endDate) {
    await this.setState({ endDate });
    this.setLocalStorage()
  }

  handleChangeCustomer = async (selectedOption) => {
    var event = selectedOption ? selectedOption.value : null;
    var selectedValue = selectedOption ? selectedOption.label : null;
    let setValue = this.state.advanceSearchFormValues;
    setValue.sfCustomerName = selectedValue;
    await this.setState({ advanceSearchFormValues: setValue });
    if (event) {
      if (this._isMounted) {
        this.setState({ CustomerSelectedOption: event });
      }

      this.state.customerOptionsData.forEach((element) => {
        if (element.id === event) {
          this.setState({
            customerCard: {
              address: element.address.address,
              city: element.address.city,
              state: element.address.state.state,
            },
          });
        }
      });

      var arr = [],
        options = [];
      await axios
        .post(BaseURL + "customerContact/findByCustomerId", {
          token: this.state.token,
          customerId: event,
        })
        .then((response) => {
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem("Token", "");
            this.props.history.push("/");
          }
          arr = response.data.data;
        })
        .catch(function (error) {
          NotificationManager.error(
            "Something Went Wrong Server Is Not Responding ",
            "Error Message",
            3000
          );
          console.log(error);
        });

      arr.forEach((element) => {
        var obj = { value: element.id, label: element.name };

        options.push(obj);
      });

      if (this._isMounted) {
        this.setState({
          ContactOptions: options,
          contactOptionsData: arr,
          contactCard: {},
        });
      }
    } else {
      if (this._isMounted) {
        this.setState({
          CustomerSelectedOption: event,
          ContactOptions: [],
          ContactSelectedOption: event,
          customerCard: {},
          contactCard: {},
        });
      }
    }
    this.setLocalStorage()
  };

  async componentDidMount() {
    console.log(`Component Did Mount --------------------------------`)
    const token = localStorage.getItem("Token");
    this._isMounted = true;

    const tableState = getPersistentTableState("load");
    this.columns.find(
      (col) => col.name === tableState.sortByColumn
    ).options.sortDirection = tableState.order.toLowerCase();

    if (this._isMounted) {
      await this.setState({
        token: token,
        order: tableState.order,
        sortByColumn: tableState.sortByColumn,
        btnVal: tableState.btnVal,
        status: tableState.status,
        search: tableState.search,
        advanceSearch: tableState.advanceSearchFormValues && (tableState.advanceSearchFormValues.sfCarrierName || tableState.advanceSearchFormValues.sfCustomerName) ? tableState.advanceSearch : false,
        showAdvanceFilter: tableState.advanceSearch ? tableState.showAdvanceFilter : false,
        advanceSearchFormValues: {
          sfCarrierName: tableState.advanceSearchFormValues ? tableState.advanceSearchFormValues.sfCarrierName : "",
          sfCustomerName: tableState.advanceSearchFormValues ? tableState.advanceSearchFormValues.sfCustomerName : ""
        },
        CustomerSelectedOption: tableState.CustomerSelectedOption ? tableState.CustomerSelectedOption : null,
        CarrierSelectedOption: tableState.CarrierSelectedOption ? tableState.CarrierSelectedOption : null,
        CarrierOptions: tableState.CarrierSelectedOption ? [{ value: tableState.CarrierSelectedOption, label: tableState.advanceSearchFormValues.sfCarrierName }] : [],
        CustomerOptions: tableState.CustomerSelectedOption ? [{ value: tableState.CustomerSelectedOption, label: tableState.advanceSearchFormValues.sfCustomerName }] : [],
        isLoading: true,
        searchVal:
          tableState.search || tableState.startDate || tableState.endDate || (tableState.advanceSearchFormValues && (tableState.advanceSearchFormValues.sfCarrierName || tableState.advanceSearchFormValues.sfCustomerName) ? tableState.advanceSearch : false)
            ? true
            : false,
        page: tableState.page,
        offset: tableState.offset,
        limit: tableState.limit,
        rowsPerPage: tableState.limit,
        startDate:
          tableState.startDate === null ? null : new Date(tableState.startDate),
        endDate:
          tableState.endDate === null ? null : new Date(tableState.endDate),
        dateDropDown:
          tableState.dateDropDown === undefined
            ? "pickup"
            : this.state.dateDropDown,
      });
      tableState.search || tableState.startDate || tableState.endDate || (tableState.advanceSearchFormValues && (tableState.advanceSearchFormValues.sfCarrierName || tableState.advanceSearchFormValues.sfCustomerName) ? tableState.advanceSearch : false)
        ? this.search()
        : this.fetchPaginationData();
    }
  }

  componentWillUnmount() {
    
    this._isMounted = false;
  }

  async search() {
    var arr = [],
      count;

    this.setLocalStorage();

    let obj = {
      customerName: this.state.advanceSearchFormValues.sfCustomerName,
      carrierName: this.state.advanceSearchFormValues.sfCarrierName,
      advanceSearch: this.state.advanceSearch,
      searchKeyword: !this.state.advanceSearch ? this.refs.search.value.trim() : "",
      offset: this.state.offset,
      limit: this.state.limit,
      sortOrder: this.state.order,
      sortBy: this.state.sortByColumn,
      loadStatus: this.state.status,
      stopType:this.state.btnVal,
    
      token: this.state.token,
    };
    console.log("===============",this.state.dateDropDown,this.state.startDate)
    if (this.state.dateDropDown === "pickup") {
      obj.startDate =
        this.state.startDate === null
          ? null
          : moment(this.state.startDate).format("YYYY-MM-DD");
      obj.endDate =
        this.state.endDate === null
          ? null
          : moment(this.state.endDate).format("YYYY-MM-DD");
    } else if (this.state.dateDropDown === "dropOff") {
      obj.deliveryStartDate =
        this.state.startDate === null
          ? null
          : moment(this.state.startDate).format("YYYY-MM-DD");
      obj.deliveryEndDate =
        this.state.endDate === null
          ? null
          : moment(this.state.endDate).format("YYYY-MM-DD");
    }
    var getStartDate
    var getendDate
   console.log(obj)
    await axios
      .post(BaseURL + "loadPickupAndDelievery/", obj)
      .then(async (response) => {

        if (obj.startDate) {
          getStartDate = new Date(obj.startDate).toISOString();
          getStartDate = new Date(getStartDate).getTime();
        }
        if (obj.endDate) {
          getendDate = new Date(obj.endDate).toISOString();
          getendDate = new Date(getendDate).getTime();
        } else {
          getendDate = new Date().toISOString();
          getendDate = new Date(getendDate).getTime();
        }

        if (obj.deliveryStartDate) {
          getStartDate = new Date(obj.deliveryStartDate).toISOString();
          getStartDate = new Date(getStartDate).getTime();
        }
        if (obj.deliveryEndDate) {
          getendDate = new Date(obj.deliveryEndDate).toISOString();
          getendDate = new Date(getendDate).getTime();
        } else {
          getendDate = new Date().toISOString();
          getendDate = new Date(getendDate).getTime();
        }

        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem("Token", "");
          this.props.history.push("/");
        }
        console.log(response.data.data)
        count = response.data.count;
 arr=await this.formTableData(response.data.rows) 
      
      })
      .catch(function (error) {
        NotificationManager.error(
          "Something Went Wrong Server Is Not Responding ",
          "Error Message",
          3000
        );
        console.log(error);
      });

    if (this._isMounted) {
      this.setState({ data: arr, isLoading: false, count });
    }
  }

  async showall(theme, e) {
    if (this._isMounted) {
      await this.setState({
        searchVal: false,
        isLoading: true,
        status: "",
        open: false,
        anchorEl: null,
        btnVal: theme,
        page: 0,
        offset: 0,
        order: "DESC",
        sortByColumn: "internalLoadNo",
      });
      let startDate = this.state.startDate;
      let endDate = this.state.endDate;
      let search = this.state.search;
      let advanceSearch = this.state.advanceSearch;

      search || startDate || endDate || advanceSearch
        ? this.search()
        : this.fetchPaginationData();
    }
  }

  startEditing(colData, cellMeta) {
    if (window.getSelection().toString()) {
      return false;
    } else if (cellMeta.colIndex === 1) {
      var data = this.state.data;
      var id = data[cellMeta.dataIndex]['loadId'];
      var stopNo = data[cellMeta.dataIndex]['stopNo'];
      console.log(this.state.data)
      // console.log('Cell meta ',colData,cellMeta,data[cellMeta.dataIndex])
      this.props.history.push("/EditPickUpAndDelieveryDetails/" + id+"-"+stopNo);
    }
  }

  handleSearch = async (e) => {
    if (this._isMounted) {
      this.setState({ search: e.target.value });
    }
  };

  handleKeyPress = (e) => {
    if (event.key === "Enter") {
      this.SetSearchParameters();
    }
  };

  SetSearchParameters = async () => {
    if (
      this._isMounted &&
      (this.refs.search.value.trim() ||
        this.state.startDate ||
        this.state.endDate || this.state.advanceSearch)
    ) {
      await this.setState({
        isLoading: true,
        page: 0,
        offset: 0,
        order: "ASC",
        sortByColumn: "loadId",
        searchVal: true,
      });

      this.search();
    } else {
      alert("Search/Date Input is required");
    }
  };

  handleToggle = (event) => {
    if (this._isMounted) {
      var target = event.currentTarget;
      this.setState((state) => ({ open: !state.open, anchorEl: target }));
    }
  };

  handleClose = (event) => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }
    if (this._isMounted) {
      this.setState({ open: false });
    }
  };

  async handleChangeDropDown(theme, e) {
    if (this._isMounted) {
      await this.setState({
        searchVal: false,
        isLoading: true,
        status: theme,
        open: false,
        anchorEl: null,
        btnVal: theme,
        page: 0,
        offset: 0,
        order: "Desc",
        sortByColumn: "internalLoadNo",
      });
      let startDate = this.state.startDate;
      let endDate = this.state.endDate;
      let search = this.state.search;
      let advanceSearch = this.state.advanceSearch;

      search || startDate || endDate || advanceSearch
        ? this.search()
        : this.fetchPaginationData();
    }
  }

  onTableChange = async (action, tableState) => {

    switch (action) {
      case "changePage":
        this.changePage(tableState);
        break;

      case "changeRowsPerPage":
        this.changeRowsPerPage(tableState);
        break;

      case "sort":
        this.sort(tableState);
        break;

      default:
        break;
    }
  };

  handleFirstPageOffset = async (Oset) => {
    this.state.offset = Oset
  }
  handleLastPageOffset = async (Oset) => {
    this.state.offset = Oset
  }

  changePage = async (tableState) => {
    var offset = this.state.offset;

    if (tableState.page > this.state.page) {
      // Next Icon Clicked >
      var tempOffset = offset + tableState.rowsPerPage;
      if (this._isMounted) {
        await this.setState({
          offset: tempOffset, //50
          limit: tableState.rowsPerPage, // 76
          page: tableState.page,
        });

        (await this.state.searchVal)
          ? this.search()
          : this.fetchPaginationData();
      }
    } else {
      var tempLimit = tableState.rowsPerPage;
      var tempOffset = offset - tempLimit;
      if (tempOffset < 0) {
        tempOffset = 0;
      }

      if (this._isMounted) {
        await this.setState({
          limit: tempLimit,
          offset: tempOffset,
          page: tableState.page,
        });

        (await this.state.searchVal)
          ? this.search()
          : this.fetchPaginationData();
      }
    }
  };

  changeRowsPerPage = async (tableState) => {
    if (this._isMounted) {
      await this.setState({
        limit: tableState.rowsPerPage, // 100
        offset: 0,
        rowsPerPage: tableState.rowsPerPage,
        page: 0,
      });
      this.state.searchVal ? this.search() : this.fetchPaginationData();
    }
  };

  sort = async (tableState) => {
    var sortByColumn = this.state.sortByColumn;
    var order = this.state.order;

    var sortOrder =
      tableState["columns"][tableState.activeColumn].name === sortByColumn
        ? order === "ASC"
          ? "DESC"
          : "ASC"
        : "ASC";

    if (this._isMounted) {
      await this.setState({
        order: sortOrder,
        sortByColumn: tableState["columns"][tableState.activeColumn].name,
      });

      this.state.searchVal ? this.search() : this.fetchPaginationData();
    }
  };

  fetchPaginationData =async () => {
    console.log('TableState Data',this.state.btnVal,this.state.status)
    var postData = {
      token: this.state.token,
      loadStatus: this.state.status,
      offset: this.state.offset,
      limit: this.state.limit,
      sortOrder: "DESC",
      sortOrder: this.state.order,
      sortBy: this.state.sortByColumn,
      stopType:this.state.btnVal,
      startDate:                                                                                                                                                                                                                                             
        this.state.startDate === null
          ? null
          : moment(this.state.startDate).format("YYYY-MM-DD"),
      endDate:
        this.state.endDate === null
          ? null
          : moment(this.state.endDate).format("YYYY-MM-DD")
    };

  
    // console.log("state", postData);

    this.setLocalStorage();

    axios
      .post(BaseURL + "loadPickupAndDelievery", postData, {
        headers: { "Content-Type": "application/json" },
      })
      .then(async(response) => {
        // console.log(response.data, "all loads");
        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem("Token", "");
          this.props.history.push("/");
        }

       let count = response.data.count;
       let data=await  this.formTableData(response.data.rows)
       if (this._isMounted) {
          this.setState({ data: data, isLoading: false,count  });
        }
      })
      .catch(function (error) {
        NotificationManager.error(
          "Something Went Wrong Server Is Not Responding ",
          "Error Message",
          3000
        );
        console.log(error);
      });
  };

  handleStatusDropDown = (id, value, index) => {
    var data = [...this.state.data];
    var selectDropDown = (
      <select
        value={value}
        className="form-control"
        style={{ width: "auto" }}
        onChange={(e) => this.handleStatusDropDown(id, e.target.value, index)}
      >
        <option value="Pending">Pending</option>
        <option value="Dispatched">Dispatched</option>
        <option value="In-Transit">In-Transit</option>
        <option value="Delivered">Delivered</option>
        <option value="Completed">Completed</option>
        <option value="Invoiced">Invoiced</option>
        <option value="Closed">Closed</option>
        <option value="Cancel">Cancel</option>
      </select>
    );

    axios
      .post(BaseURL + "customerLoad/updateStatus", {
        id: id,
        loadStatus: value,
        token: this.state.token,
      })
      .then((response) => {
        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem("Token", "");
          this.props.history.push("/");
        }

        data[index][9] = selectDropDown;
        this.setState({ data });
        NotificationManager.success(response.data.message, "Message", 3000);
      })
      .catch(function (error) {
        NotificationManager.error(
          "Something Went Wrong Server Is Not Responding ",
          "Error Message",
          3000
        );
        console.log(error);
      });
  };

  setLocalStorage = () => {
    const table = {
      order: this.state.order,
      sortByColumn: this.state.sortByColumn,
      btnVal: this.state.btnVal,
      status: this.state.status,
      search: this.state.search,
      page: this.state.page,
      offset: this.state.offset,
      limit: this.state.limit,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      dateDropDown: this.state.dateDropDown,
      advanceSearch: this.state.advanceSearch,
      advanceSearchFormValues: {
        sfCarrierName: this.state.advanceSearchFormValues.sfCarrierName,
        sfCustomerName: this.state.advanceSearchFormValues.sfCustomerName
      },
      showAdvanceFilter: this.state.showAdvanceFilter,
      CustomerSelectedOption: this.state.advanceSearchFormValues.sfCustomerName,
      CarrierSelectedOption: this.state.advanceSearchFormValues.sfCarrierName
    };

    setPersistentTableState("load", table);

    this.columns = this.columns.map((col) => {
      col.name === table.sortByColumn
        ? (col.options.sortDirection = table.order.toLowerCase())
        : delete col.options.sortDirection;
      return col;
    });
  };

formTableData=async (rows)=>{

  let arr=[]
  console.log(rows)
  for (const iterator of rows) {
   
    let PickupDate,PickupCityState,DropOfDate,DelivCityState
           if (iterator.stopType === "pickup") {
                var date = moment(iterator.scheduleFrom).format("MM/DD/YYYY");
                PickupDate = PickupDate ? PickupDate : date;
                var date = moment(iterator.scheduleTo).format("MM/DD/YYYY");
                DropOfDate=DropOfDate?DropOfDate:date
                var PickupCity =
                  iterator.shipper &&
                    iterator.shipper.address &&
                    iterator.shipper.address.city
                    ? iterator.shipper.address.city + ", "
                    : "";
                var PickupState =
                  iterator.shipper &&
                    iterator.shipper.address &&
                    iterator.shipper.address.state
                    ? iterator.shipper.address.state.stateAbbrivation
                    : "";

                if (iterator.shipperId === null) {
                  PickupCityState = iterator.address;
                } else {
                  PickupCityState = PickupCityState
                    ? PickupCityState
                    : (PickupCity + PickupState);
                }
              } else if (iterator.stopType === "delivery") {
                var date = moment(iterator.scheduleTo).format("MM/DD/YYYY");
                DropOfDate = date;
                var date = moment(iterator.scheduleFrom).format("MM/DD/YYYY");
                PickupDate = PickupDate ? PickupDate : date;
                var DelivCity =
                  iterator.shipper &&
                    iterator.shipper.address &&
                    iterator.shipper.address.city
                    ? iterator.shipper.address.city + ", "
                    : "";
                var DelivState =
                  iterator.shipper &&
                    iterator.shipper.address &&
                    iterator.shipper.address.state
                    ? iterator.shipper.address.state.stateAbbrivation
                    : "";

                if (iterator.shipperId === null) {
                  DelivCityState = iterator.address;
                } else {
                  DelivCityState = (DelivCity + DelivState);
                }
              }
                  arr.push({
              ...iterator,
              scheduleFrom:PickupDate,
              scheduleTo:DropOfDate,    
              internalLoadNo:iterator.customerLoad.internalLoadNo,
              customerName:iterator.customerLoad.customer.customerName || "",
              customerLoadNo:iterator.customerLoad.customerLoadNo || '',
              
              pickupCityState:iterator.stopType==="delivery"? DelivCityState:PickupCityState,
              delivCityState:DelivCityState,
              loadStatus:iterator.stopType.toUpperCase(),
              eta:iterator.eta,
              verified:Boolean(iterator.verified)?"TRUE":"FALSE",
              driverCheckIn:iterator.driverCheckIn,
              driverCheckOut:iterator.driverCheckOut,
              carrierName:iterator.customerLoad.carrier && iterator.customerLoad.carrier.carrierName?iterator.customerLoad.carrier.carrierName:''
                })
              }
  return arr
}

  filterHandler = async () => {
    const value = this.state.showAdvanceFilter;
    await this.setState({
      advanceSearch: true,
      showAdvanceFilter: !value
    });
    this.setLocalStorage()
  }

  advanceFilterHandler = (e) => {
    e.preventDefault()
    this.setState({
      isLoading: true,
      page: 0,
      offset: 0,
      order: "ASC",
      sortByColumn: "loadId",
      searchVal: true,
    });

    this.search();
  }

  advanceFilterClear = (e) => {
    e.preventDefault();
    this.setState({
      startDate: null,
      endDate: null,
      search: '',
      readyCsvDownloadFile: false,
      advanceSearch: false,
      advanceSearchFormValues: {
        sfCustomerName: "",
        sfCarrierName: "",
        sfPickupCity: "",
        sfDeliveryCity: "",
        // sfShipper: "",
        // sfReciever: "",
      },
      CustomerSelectedOption: null,
      CarrierSelectedOption: null,
      searchVal: false
    });
    this.showall("all", e);
    this.setState({ showAdvanceFilter: false });
  }

  render() {
    const {
      isLoading,
      data,
      rowsPerPage,
      open,
      searchVal,
      count,
      page,
      csvReportData,
      showAdvanceFilter,
      readyCsvDownloadFile,
      readyForCsvDownload,
    } = this.state;
    const options = {

      // responsive: "scroll",
      // selectableRows: false,
      // rowHover: true,
      // print: false,
      // download: false,
      // filter: false,
      // search: false,
      // rowsPerPageOptions: [25, 50, 100],
      // rowsPerPage: rowsPerPage,
      // viewColumns: false,
      // textLabels: {
      //   body: {
      //     toolTip: "",
      //   },
      // },
      // onCellClick: this.startEditing,

      // serverSide: true,
      // count: count,
      // page: page,
      // onTableChange: this.onTableChange,

      responsive: "scroll",
      selectableRows: false,
      rowHover: true,
      print: false,
      download: false,
      filter: false,
      search: false,
      rowsPerPageOptions: [25, 50, 100],
      rowsPerPage: rowsPerPage,
      viewColumns: false,
      textLabels: {
        body: {
          toolTip: "",
        },
      },
      onCellClick: this.startEditing,

      serverSide: true,
      count: count,
      page: page,
      onTableChange: this.onTableChange,
      customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
        return (
          <CustomFooter
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            changeRowsPerPage={changeRowsPerPage}
            changePage={changePage}
            handleFirstPageOffset={this.handleFirstPageOffset}
            handleLastPageOffset={this.handleLastPageOffset}
          />
        );
      }
    };

    const crossN = {
      display: "none",
    };
    const crossS = {
      display: "block",
    };

    return (
      <div className="page-container-bg-solid page-header-fixed page-sidebar-closed-hide-logo page-md">
        <Header title="| PickUp and Delievery Details.  " />
        <div className="page-container">
          <Sidebar />
          <div className="page-content-wrapper">
            <div className="page-content">
              <div className="row">
                <div className="col-md-12">
                  <div className="portlet box yellow-gold">
                    <div className="portlet-title">
                      <div className="caption">
                        <i className="fa fa-cubes"></i>
                        <span className="caption-subject">
                          Pick Up And Delievery Details
                          {/* <i className="fa fa-filter filter" onClick={this.filterHandler}></i> */}
                        </span>
                      </div>
                      
                    </div>
                    <div className="portlet-body">
                      <div className="table-toolbar">
                        <div
                          style={{
                            textAlign: "right",
                            paddingBottom: "8px",
                          }}
                        >
                          {
                            !showAdvanceFilter ? (
                              <span
                                style={{
                                  float: "left",
                                  display: "flex",
                                  alignItems: "center",
                                  backgroundColor: "#fff",
                                }}
                              >
                                <div className="btnShowall">
                                  <Button
                                    buttonRef={(node) => {
                                      this.anchorEl = node;
                                    }}
                                    aria-owns={open ? "menu-list-grow" : undefined}
                                    aria-haspopup="true"
                                    onClick={this.handleToggle}
                                  >
                                    {this.state.btnVal} &nbsp;&nbsp;
                                    <span className="fa fa-chevron-down"></span>
                                  </Button>
                                  <Popper
                                    open={open}
                                    anchorEl={this.anchorEl}
                                    transition
                                    disablePortal
                                  >
                                    {({ TransitionProps, placement }) => (
                                      <Grow
                                        {...TransitionProps}
                                        id="menu-list-grow"
                                        style={{
                                          transformOrigin:
                                            placement === "bottom"
                                              ? "center top"
                                              : "center bottom",
                                        }}
                                      >
                                        <Paper>
                                          <ClickAwayListener
                                            onClickAway={this.handleClose}
                                          >
                                            <MenuList>
                                              <MenuItem
                                                style={{
                                                  paddingTop: "6px",
                                                  paddingBottom: "6px",
                                                }}
                                                onClick={(e) =>
                                                  this.showall("all", e)
                                                }
                                              >
                                                Show All
                                              </MenuItem>
                                              <MenuItem
                                                style={{
                                                  paddingTop: "6px",
                                                  paddingBottom: "6px",
                                                }}
                                                onClick={(e) =>
                                                  this.showall("pickup", e)
                                                }
                                              >
                                                PickUp
                                              </MenuItem>
                                              <MenuItem
                                                style={{
                                                  paddingTop: "6px",
                                                  paddingBottom: "6px",
                                                }}
                                                onClick={(e) =>
                                                  this.showall("delivery", e)
                                                }
                                              >
                                                Delievery
                                              </MenuItem>
                                              <MenuItem
                                                style={{
                                                  paddingTop: "6px",
                                                  paddingBottom: "6px",
                                                }}
                                                onClick={(e) =>
                                                  this.showall("transit", e)
                                                }
                                              >
                                                In-Transit
                                              </MenuItem>
                                            </MenuList>
                                          </ClickAwayListener>
                                        </Paper>
                                      </Grow>
                                    )}
                                  </Popper>
                                </div>
                                <div style={{ marginLeft: "20px", width: "140px" }}>
                                  <DatePicker
                                    // disabled={this.state.readonly}
                                    className="form-control"
                                    selected={this.state.startDate}
                                    dateFormat="yyyy-MM-dd"
                                    placeholderText="Start Date"
                                    onChange={(date) => this.startDateHandler(date)}
                                    isClearable
                                  />
                                </div>
                                <div
                                  className="text-center"
                                  style={{ margin: "0 10px" }}
                                >
                                  TO
                                </div>
                                <div style={{ width: "140px" }}>
                                  <DatePicker
                                    // disabled={this.state.readonly}
                                    className="form-control"
                                    selected={this.state.endDate}
                                    dateFormat="yyyy-MM-dd"
                                    placeholderText="End Date"
                                    onChange={(date) => this.endDateHandler(date)}
                                    isClearable
                                  />
                                </div>
                                <select
                                  style={{
                                    width: "140px",
                                    marginLeft: "10px",
                                    marginRight: "10px",
                                  }}
                                  value={this.state.dateDropDown}
                                  className="form-control"
                                  onChange={(e) =>
                                    this.setState({ dateDropDown: e.target.value })
                                  }
                                >
                                  <option value="pickup">Pickup Date</option>
                                  <option value="dropOff">Drop off Date</option>
                                </select>
                              </span>
                            ) :
                              (
                                <>
                                  <span
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      backgroundColor: "#fff",
                                    }}
                                  >
                                    <div className="btnShowall">
                                      <Button
                                        buttonRef={(node) => {
                                          this.anchorEl = node;
                                        }}
                                        aria-owns={open ? "menu-list-grow" : undefined}
                                        aria-haspopup="true"
                                        onClick={this.handleToggle}
                                      >
                                        {this.state.btnVal} &nbsp;&nbsp;
                                        <span className="fa fa-chevron-down"></span>
                                      </Button>
                                      <Popper
                                        open={open}
                                        anchorEl={this.anchorEl}
                                        transition
                                        disablePortal
                                      >
                                        {({ TransitionProps, placement }) => (
                                          <Grow
                                            {...TransitionProps}
                                            id="menu-list-grow"
                                            style={{
                                              transformOrigin:
                                                placement === "bottom"
                                                  ? "center top"
                                                  : "center bottom",
                                            }}
                                          >
                                            <Paper>
                                              <ClickAwayListener
                                                onClickAway={this.handleClose}
                                              >
                                                <MenuList>
                                                  <MenuItem
                                                    style={{
                                                      paddingTop: "6px",
                                                      paddingBottom: "6px",
                                                    }}
                                                    onClick={(e) =>
                                                      this.showall("Show All", e)
                                                    }
                                                  >
                                                    Show All
                                                  </MenuItem>
                                                  <MenuItem
                                                    style={{
                                                      paddingTop: "6px",
                                                      paddingBottom: "6px",
                                                    }}
                                                    onClick={(e) =>
                                                      this.handleChangeDropDown(
                                                        "Pending",
                                                        e
                                                      )
                                                    }
                                                  >
                                                    Pending
                                                  </MenuItem>
                                                  <MenuItem
                                                    style={{
                                                      paddingTop: "6px",
                                                      paddingBottom: "6px",
                                                    }}
                                                    onClick={(e) =>
                                                      this.handleChangeDropDown(
                                                        "Dispatched",
                                                        e
                                                      )
                                                    }
                                                  >
                                                    Dispatched
                                                  </MenuItem>
                                                  <MenuItem
                                                    style={{
                                                      paddingTop: "6px",
                                                      paddingBottom: "6px",
                                                    }}
                                                    onClick={(e) =>
                                                      this.handleChangeDropDown(
                                                        "In-Transit",
                                                        e
                                                      )
                                                    }
                                                  >
                                                    In-Transit
                                                  </MenuItem>
                                                  <MenuItem
                                                    style={{
                                                      paddingTop: "6px",
                                                      paddingBottom: "6px",
                                                    }}
                                                    onClick={(e) =>
                                                      this.handleChangeDropDown(
                                                        "Delivered",
                                                        e
                                                      )
                                                    }
                                                  >
                                                    Delivered
                                                  </MenuItem>
                                                  <MenuItem
                                                    style={{
                                                      paddingTop: "6px",
                                                      paddingBottom: "6px",
                                                    }}
                                                    onClick={(e) =>
                                                      this.handleChangeDropDown(
                                                        "Completed",
                                                        e
                                                      )
                                                    }
                                                  >
                                                    Completed
                                                  </MenuItem>
                                                  <MenuItem
                                                    style={{
                                                      paddingTop: "6px",
                                                      paddingBottom: "6px",
                                                    }}
                                                    onClick={(e) =>
                                                      this.handleChangeDropDown(
                                                        "Invoiced",
                                                        e
                                                      )
                                                    }
                                                  >
                                                    Invoiced
                                                  </MenuItem>
                                                  <MenuItem
                                                    style={{
                                                      paddingTop: "6px",
                                                      paddingBottom: "6px",
                                                    }}
                                                    onClick={(e) =>
                                                      this.handleChangeDropDown(
                                                        "Closed",
                                                        e
                                                      )
                                                    }
                                                  >
                                                    Closed
                                                  </MenuItem>
                                                  <MenuItem
                                                    style={{
                                                      paddingTop: "6px",
                                                      paddingBottom: "6px",
                                                    }}
                                                    onClick={(e) =>
                                                      this.handleChangeDropDown(
                                                        "Cancel",
                                                        e
                                                      )
                                                    }
                                                  >
                                                    Cancel
                                                  </MenuItem>
                                                </MenuList>
                                              </ClickAwayListener>
                                            </Paper>
                                          </Grow>
                                        )}
                                      </Popper>
                                    </div>
                                    <div style={{ marginLeft: "20px", width: "140px" }}>
                                      <DatePicker
                                        // disabled={this.state.readonly}
                                        className="form-control"
                                        selected={this.state.startDate}
                                        dateFormat="yyyy-MM-dd"
                                        placeholderText="Start Date"
                                        onChange={(date) => this.startDateHandler(date)}
                                        isClearable
                                      />
                                    </div>
                                    <div
                                      className="text-center"
                                      style={{ margin: "0 10px" }}
                                    >
                                      TO
                                    </div>
                                    <div style={{ width: "140px" }}>
                                      <DatePicker
                                        // disabled={this.state.readonly}
                                        className="form-control"
                                        selected={this.state.endDate}
                                        dateFormat="yyyy-MM-dd"
                                        placeholderText="End Date"
                                        onChange={(date) => this.endDateHandler(date)}
                                        isClearable
                                      />
                                    </div>
                                    <select
                                      style={{
                                        width: "140px",
                                        marginLeft: "10px",
                                        marginRight: "10px",
                                      }}
                                      value={this.state.dateDropDown}
                                      className="form-control"
                                      onChange={(e) =>
                                        this.setState({ dateDropDown: e.target.value })
                                      }
                                    >
                                      <option value="pickup">Pickup Date</option>
                                      <option value="dropOff">Drop off Date</option>
                                    </select>
                                  </span>
                                  <div>
                                    <div class="row d-flex text-left">
                                      <div class="col-12 col-md-6">
                                        <label for="inputEmail4">Customer Name</label>
                                        {/* <input name="sfCustomerName" onChange={this.advanceSearchInputHandler} value={this.state.advanceSearchFormValues.sfCustomerName} type="" className="form-control" placeholder="Customer Name" /> */}
                                        <Select
                                          className="customeLeft"
                                          optionHeight={50}
                                          disabled={this.state.readonly}
                                          value={
                                            this.state.CustomerSelectedOption
                                            // this.state.advanceSearchFormValues.sfCustomerName
                                          }
                                          options={this.state.CustomerOptions}
                                          onChange={this.handleChangeCustomer}
                                          onInputChange={(input) => {
                                            if (input.trim().length > 1) {
                                              axios
                                                .post(
                                                  BaseURL +
                                                  "customer/findByName",
                                                  {
                                                    status: 2,
                                                    searchKeyword: input,
                                                    token: this.state.token,
                                                  }
                                                )
                                                .then((response) => {
                                                  if (
                                                    response.data.error ===
                                                    TokenErrorMsg
                                                  ) {
                                                    localStorage.setItem(
                                                      "Token",
                                                      ""
                                                    );
                                                    this.props.history.push(
                                                      "/"
                                                    );
                                                  }

                                                  var arr = [];

                                                  response.data.data.rows.forEach(
                                                    (element) => {
                                                      var obj = {
                                                        value: element.id,
                                                        label: element.customerName
                                                      };
                                                      arr.push(obj);
                                                    }
                                                  );

                                                  this.setState({
                                                    CustomerOptions: arr,
                                                    customerOptionsData:
                                                      response.data.data.rows,
                                                  });
                                                })
                                                .catch(function (error) {
                                                  NotificationManager.error(
                                                    "Something Went Wrong Server Is Not Responding ",
                                                    "Error Message",
                                                    3000
                                                  );
                                                  console.log(error);
                                                });
                                            }
                                          }}
                                          placeholder="Search and Choose "
                                        />
                                      </div>

                                      <div class="col-12 col-md-6">
                                        <label for="inputEmail4">Carrier Name</label>
                                        {/* <input name="sfCarrierName" onChange={this.advanceSearchInputHandler} value={this.state.advanceSearchFormValues.sfCarrierName} type="" className="form-control" placeholder="Carrier Name" /> */}
                                        <Select
                                          optionHeight={50}
                                          disabled={this.state.readonly}
                                          value={
                                            this.state.CarrierSelectedOption
                                          }
                                          options={this.state.CarrierOptions}
                                          onChange={this.handleChangeCarrier}
                                          onInputChange={(input) => {
                                            if (input.trim().length > 1) {
                                              axios
                                                .post(
                                                  BaseURL +
                                                  "carrierInformation/findByName",
                                                  {
                                                    status: 2,
                                                    searchKeyword: input,
                                                    token: this.state.token,
                                                  }
                                                )
                                                .then((response) => {
                                                  if (
                                                    response.data.error ===
                                                    TokenErrorMsg
                                                  ) {
                                                    localStorage.setItem(
                                                      "Token",
                                                      ""
                                                    );
                                                    this.props.history.push(
                                                      "/"
                                                    );
                                                  }

                                                  var arr = [];

                                                  if (response.data.data.count === 0) {
                                                    let errors = {};
                                                    errors["inactiveValidation"] = "";
                                                    this.setState({
                                                      errors: errors
                                                    });
                                                  }

                                                  response.data.data.rows.forEach(
                                                    (element) => {
                                                      if (element.status !== 0) {
                                                        let errors = {};
                                                        errors["inactiveValidation"] = "";
                                                        var obj = {
                                                          value: element.id,
                                                          label:
                                                            element.carrierName
                                                        };

                                                        arr.push(obj);
                                                        this.setState({
                                                          errors: errors
                                                        });
                                                      } else if (element.status === 0) {
                                                        let errors = {};
                                                        // console.log(element, "element 3")
                                                        errors["inactiveValidation"] = "*Carrier exist with inactive status";
                                                        this.setState({
                                                          errors: errors
                                                        });
                                                      }
                                                    }
                                                  );

                                                  this.setState({
                                                    CarrierOptions: arr,
                                                    loadData:
                                                      response.data.data.rows,
                                                  });
                                                })
                                                .catch(function (error) {
                                                  NotificationManager.error(
                                                    "Something Went Wrong Server Is Not Responding ",
                                                    "Error Message",
                                                    3000
                                                  );
                                                  console.log(error);
                                                });
                                            } else {
                                              let errors = {};
                                              errors["inactiveValidation"] = "";
                                              this.setState({
                                                errors: errors
                                              });
                                            }
                                          }}
                                          placeholder="Search and Choose "
                                        />
                                      </div>
                                    </div>

                                    
                                    <div className="filterSerchDiv">
                                      <button

                                        style={{ marginRight: "5px" }}
                                        className="btn green-meadow filterSerchBtn"
                                        disabled={!this.state.advanceSearchFormValues.sfCarrierName && !this.state.advanceSearchFormValues.sfCustomerName}
                                        onClick={this.advanceFilterHandler}>
                                        Search
                                      </button>
                                      <button className="btn" onClick={this.advanceFilterClear}>Clear</button>
                                    </div>

                                  </div>

                                </>
                              )
                          }

                          <div
                            style={{
                              marginRight: "0px",
                              display: "flex",

                              justifyContent: "flex-end",
                            }}
                          >
                            {!showAdvanceFilter ? (
                              <div
                                style={{ marginRight: "10px" }}
                                className="searchField form-control"
                              >
                                <input
                                  id="search-field"
                                  ref="search"
                                  name="searchinput"
                                  type="text"
                                  placeholder="Search..."
                                  style={{ position: "relative" }}
                                  value={this.state.search}
                                  onChange={this.handleSearch}
                                  onKeyPress={this.handleKeyPress}
                                />
                                <i className="fa fa-chevron-down"
                                  style={{ position: "absolute", right: "10px", top: "8px", cursor: "pointer" }}
                                  onClick={this.filterHandler}></i>
                              </div>
                            ) : null}

                            {!showAdvanceFilter ? (
                              <>
                                <input
                                  name="searchbutton"
                                  style={{ marginRight: "10px" }}
                                  className="btn green-meadow"
                                  type="submit"
                                  value="SEARCH"
                                  onClick={this.SetSearchParameters}
                                />
                                <button
                                  // style={{marginRight: "10px"}}
                                  className="btn"
                                  type="button"
                                  onClick={async (e) => {
                                    await this.setState({
                                      startDate: null,
                                      endDate: null,
                                      search: "",
                                      advanceSearch: false,
                                      advanceSearchFormValues: {
                                        sfCustomerName: "",
                                        sfCarrierName: "",
                                        sfPickupCity: "",
                                        sfDeliveryCity: "",
                                        // sfShipper: "",
                                        // sfReciever: "",
                                      },
                                      CustomerSelectedOption: null,
                                      CarrierSelectedOption: null
                                    });
                                    this.showall("all", e);
                                  }}
                                >
                                  Clear
                                </button>
                              </>
                            ) : null}
                            {/* <img alt='' id="excel_export" src="../assets/apps/img/excel_button.png" title="Export data to Excel" />  */}
                          </div>
                        </div>
                      </div>

                      {isLoading ? (
                        <p style={{ textAlign: "center" }}>
                          <img src={f} alt="Loader" />
                        </p>
                      ) : (
                        <MUiTable
                          data={data}
                          columns={this.columns}
                          options={options}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
} 

export default withRouter(PickupDelieveryDetails);


