/* eslint-disable */

import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Map from "./Map";
import MUIDataTable from "mui-datatables";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import timezone from "node-google-timezone";
import moment from "moment-timezone";
import "moment-timezone";
import "react-notifications/lib/notifications.css";
import Sidebar from "./sidebar";
import Header from "./Head";
import $ from "jquery";
import BaseURL from "./config";
import TokenErrorMsg from "./errorMsg";
import Select from "react-virtualized-select";
import DatePicker from "react-datepicker";
import CurrencyInput from "react-currency-input";
// import moment from "moment";
import tenantUser from "./TenantUserPermision";
import tenantSubRole from "./TenantUserSubRolePermission";
import Customer from "./LoadCustomer";
import AddShipper from "./LoadShipper";
import Addcarrier from "./LoadCarrier";
import DispatchTab from "./DispatchTab";
import InvoiceTab from "./InvoiceTab";
import "./tabID.css";
import getEditinfo from "./StartEditMode";
import finishEditinfo from "./FinishEditMode";
import currencyFormatter from "currency-formatter";
import { Button, Modal, Form } from 'react-bootstrap';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import * as Scroll from 'react-scroll';
const optionsDropdown = [
  { label: "Line Haul", value: 1 },
  { label: "TONU", value: 2 },
];
const optionsUsersList = [];

const columnsTable = ["User Name", "Action", "Type", "Time", "IP Address"];
const dataTable = [["Joe James", "Test Corp", "Yonkers", "NY", "192.9982"]];
const optionsTable = {
  selectableRows: false,
};

function isDST(d) {
  let jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
  let jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
  return Math.max(jan, jul) != d.getTimezoneOffset();
}
function getTimeZone() {
  var offset = new Date().getTimezoneOffset(),
    o = Math.abs(offset);
  return (
    (offset < 0 ? "+" : "-") +
    ("00" + Math.floor(o / 60)).slice(-2) +
    ":" +
    ("00" + (o % 60)).slice(-2)
  );

  // var d = new Date();
  // var offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
  // var timezone = (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2);

  // if (timezone < 0) {
  //   let tz = timezone.replace("-", "");
  //   if (isDST(d)) {
  //     timezone = parseInt(tz) + 1;
  //     timezone = '-0' + timezone;
  //   }
  // } else {
  //   let tz = timezone;
  //   if (isDST(d)) {
  //     timezone = parseInt(tz) + 1;
  //   }

  // }
  // return timezone + ":" + ("00" + (o % 60)).slice(-2);
}

const DragHandle = sortableHandle(() => (
  <button
    type="button"
    style={{
      background: "white",
      border: "none",
      focus: {
        border: "none",
      },
    }}
  >
    <i className="fa fa-sort"></i>
  </button>
));

const SortableItem = sortableElement(
  ({
    value,
    stopNo,
    props,
    readonly,
    indexValue,
    totalStops,
    stopDeleteHandler,
    sortUpHandler,
    sortDownHandler,
    dispatchedStops,
    address,
  }) => {
    const nonclick = {
      cursor: " notAllowed",
      pointerEvents: "none",
    };
    let foundStop = dispatchedStops.find((stop) => stop == stopNo);
    let isDispatched = foundStop === undefined ? false : true;

    return (
      <li style={{ display: "block" }}>
        <>
          <div style={{ marginBottom: "0px" }} className="form-group row">
            <div className="col-md-4 mt-1 d-flex">
              {/* <DragHandle /> */}

              <div></div>
              {indexValue === 0 ? (
                <span style={{ width: "34px" }}></span>
              ) : (
                <button
                  type="button"
                  className="no-focus"
                  style={{
                    background: "transparent",
                    marginLeft: "2px",
                    border: "none",
                  }}
                  onClick={() => sortUpHandler(indexValue)}
                >
                  <i className="fa fa-sort-up"></i>
                </button>
              )}
              {indexValue === totalStops - 1 ? (
                <span style={{ width: "34px" }}></span>
              ) : (
                <button
                  type="button"
                  className="no-focus"
                  style={{
                    background: "transparent",
                    marginLeft: "2px",
                    border: "none",
                  }}
                  onClick={() => sortDownHandler(indexValue)}
                >
                  <i
                    style={{
                      transform: "translateY(-6px)",
                    }}
                    className="fa fa-sort-down"
                  ></i>
                </button>
              )}
              <span>{value}</span>
              <button
                className="no-focus"
                type="button"
                onClick={() => {
                  document.getElementById(
                    `item-${props.stopNo}`
                  ).style.display =
                    document.getElementById(`item-${props.stopNo}`).style
                      .display === ""
                      ? "none"
                      : "";
                }}
                style={{
                  background: "transparent",
                  marginLeft: "2px",
                  border: "none",
                }}
              >
                <i
                  className={
                    document.getElementById(`item-${props.stopNo}`) === null ||
                      document.getElementById(`item-${props.stopNo}`).style
                        .display === ""
                      ? "fa fa-expand"
                      : "fa fa-compress"
                  }
                ></i>
              </button>
              {isDispatched === true ? (
                <button
                  data-toggle="tooltip"
                  title="This stop is dispatched"
                  type="button"
                  className="no-focus"
                  style={{
                    background: "transparent",
                    marginLeft: "2px",
                    border: "none",
                    cursor: "unset",
                  }}
                >
                  <i className="fa fa-paper-plane"></i>
                </button>
              ) : (
                ""
              )}
            </div>
            <div className="col-md-6 d-flex">
              <span
                style={{
                  marginTop: "2px",
                  marginTop: "6px",
                  position: "relative",
                  zIndex: 0,
                }}
              >
                <div
                  className="btn-group btn-group-devided"
                  data-toggle="buttons"
                  style={readonly ? nonclick : {}}
                >
                  {props.stopType === "pickup" ? (
                    <label
                      className={"btn btn-success btn-sm btn-sm active"}
                      disabled={readonly}
                    >
                      <input
                        type="radio"
                        name="options"
                        className="toggle"
                        id="option1"
                      />
                      &nbsp;PICKUP&nbsp;
                    </label>
                  ) : (
                    ""
                  )}
                  {props.stopType === "delivery" ? (
                    <label
                      className={"btn btn-success btn-sm btn-sm active"}
                      disabled={readonly}
                    >
                      <input
                        type="radio"
                        name="options"
                        className="toggle"
                        id="option2"
                      />
                      DELIVERY
                    </label>
                  ) : (
                    ""
                  )}{" "}
                  {props.stopType === "transit" ? (
                    <label
                      className={"btn btn-success btn-sm btn-sm active"}
                      disabled={readonly}
                    >
                      <input
                        type="radio"
                        name="options"
                        className="toggle"
                        id="option2"
                      />
                      Transit
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              </span>
              <span
                style={{
                  marginTop: "10px",
                  marginLeft: "10px",
                  position: "relative",
                  zIndex: 0,
                }}
              >
                Address: {address}{" "}
              </span>
            </div>
            <div className="col-md-2 text-right">
              {props.stopNo !== 1 && props.stopNo !== 2 ? (
                <button
                  onClick={() => stopDeleteHandler(indexValue)}
                  type="button"
                  className="btn btn-danger danger btn-outline btn-circle btn-"
                  disabled={readonly}
                >
                  DELETE Stop
                </button>
              ) : null}
            </div>
          </div>
        </>
      </li>
    );
  }
);

const SortableContainer = sortableContainer(({ children }) => {
  return <ul style={{ paddingInlineStart: "0px" }}>{children}</ul>;
});

const Cryptr = require("cryptr");
const cryptr = new Cryptr("@$#rgweR35*&YFSD)/");

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import dispatcherUser from "./permissions/DispatcherUserPermision";
import accountingUser from "./permissions/AccountingUserPermision";

const searchOptions = {
  componentRestrictions: { country: ["us"] },
  types: ["(regions)"],
};

class EditLoad extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      auditTrailsResponse: [],
      data: [],
      soureAddress: "",
      destinationAddress: "",
      soureLatLng: "",
      destinationLatLng: "",
      sourceClock: {
        checkedOutTime: "",
        timeZone: "",
        timeZoneName: "",
      },
      destinationClock: {
        timeZone: "",
        timeZoneName: "",
      },
      totalMiles: 0,
      truckAvgSpeed: 50,
      extraMinutesForDriver: 30,
      estimatedDelveryDateTime: "",
      wayPoints: [],
      stopLimitError: "",
      mine1: "",
      mine2: "",

      // 123123
      myValue: "",
      currenOpenTab: "",
      selectRateTypeValue: {},
      salesPerson1: {},
      salesPerson2: {},
      countries: [],
      selectedCountryId: null,
      states: [],
      showSelectedRowOnTabs: [],
      showSelectedRowOnTabsBoolean: false,
      showRowDeliveryDate: "",
      showRowPickupDate: "",
      showRowPickupCityState: "",
      showRowDeliveryCityState: "",
      status: 1,
      loadid: "",
      whileSave: false,
      readonly: true,
      hidden: false,
      accountingInfoReadOnly: true,
      accountingInfoHidden: false,
      accountingLoad: null,
      billingInfoReadOnly: true,
      accountingCustomsLoad: null,
      accountingVerified: false,
      billingInfoHidden: false,
      accountingRate: null,
      isLoading: false,
      token: "",
      fields: {},
      errors: {},
      CustomerSelectedOption: null,
      CustomerOptions: [],
      ContactSelectedOption: null,
      RateSelectedOption: null,
      ContactOptions: [],
      CarrierSelectedOption: null,
      CarrierOptions: [],
      role: "Tenant",
      subRole: "Basic",
      loadData: [],
      carrierdata: {},

      customerOptionsData: [],
      customerCard: {},
      contactOptionsData: [],
      contactCard: {},
      dispatchPercentage: 0,
      billingLoad: null,
      billingCustomsLoad: null,
      billingRate: null,
      billingAmount: null,
      suggestedAmount: null,
      billingStatus: null,
      billingInvoice: null,
      billingVerified: null,
      dispatchFee: 0,
      dispatchType: 2,
      dispatchTypeOptions: [
        { id: 1, name: "Dispatch Fee" },
        { id: 2, name: "Dispatch Percentage" },
      ],
      puFields: [],
      StopData: [
        {
          ShipperOptions: [],
          shipperId: null,
          stopNo: 1,
          stopType: "",
          isAddressAvailable: "1",
          address: "",
          scheduleFTime: null,
          scheduleTTime: null,
          scheduleFrom: moment().set({ hour: 0, minute: 0, second: 0 }),
          scheduleTo: moment().set({ hour: 0, minute: 0, second: 0 }),
          puNo: [""],
          notes: "",
          shipperOptionsData: [],
          shipperCard: {},
          checkedIn: "0",
          checkedOut: "0",
          checkedInT: 0,
          checkedOutT: 0,
          checkedInTime: moment().set({ hour: 0, minute: 0, second: 0 }),
          checkedOutTime: moment().set({ hour: 0, minute: 0, second: 0 }),
          dispatchCheckBox: false,
        },
        {
          ShipperOptions: [],
          shipperId: null,
          stopNo: 2,
          isAddressAvailable: "1",
          address: "",
          stopType: "",
          scheduleFTime: null,
          scheduleTTime: null,
          scheduleFrom: moment().set({ hour: 0, minute: 0, second: 0 }),
          scheduleTo: moment().set({ hour: 0, minute: 0, second: 0 }),
          puNo: [""],
          notes: "",
          shipperOptionsData: [],
          shipperCard: {},
          checkedIn: "0",
          checkedOut: "0",
          checkedInT: 0,
          checkedOutT: 0,
          checkedInTime: moment().set({ hour: 0, minute: 0, second: 0 }),
          checkedOutTime: moment().set({ hour: 0, minute: 0, second: 0 }),
          dispatchCheckBox: false,
        },
      ],
      freightType: "",
      temperatureType: "F",
      cycle: "cont",
      loadFormData: {},
      CheckEditInfo: {},

      Documents: [
        {
          label: "Rate Sheet",
          docType: 1,
          documentNo: 1,
          fileName: "",
          error: "",
          showIcons: true,
          file: "",
          s3FileKey: "",
          checked: false,
          documentId: "",
          invoiceError: "",
          fileExtension: "",
        },
        {
          label: "BOL (Bill of Loading)",
          docType: 2,
          documentNo: 2,
          fileName: "",
          error: "",
          showIcons: true,
          file: "",
          s3FileKey: "",
          checked: false,
          documentId: "",
          invoiceError: "",
          fileExtension: "",
        },
        {
          label: "POD (Proof of Delivery)",
          docType: 3,
          documentNo: 3,
          fileName: "",
          error: "",
          showIcons: true,
          file: "",
          s3FileKey: "",
          checked: false,
          documentId: "",
          invoiceError: "",
          fileExtension: "",
        },
        {
          label: "Lumper Receipt",
          docType: 4,
          documentNo: 4,
          fileName: "",
          error: "",
          showIcons: true,
          file: "",
          s3FileKey: "",
          checked: false,
          documentId: "",
          invoiceError: "",
          fileExtension: "",
        },
      ],

      additionalCharges: [],
      totalAmount: 0.0,
      paymentType: "Quick Pay",
      invoicePopUpInputValues: {
        chargesType: "Lumper",
        amount: "",
      },
      invoiceId: null,
      invoiceURL: null,
      invoiceS3FileKey: null,
      invoiceLoader: false,
      invoiceFileName: "",
      quickPay: "",
      invoiceTo: "Customer",
      invoiceStatus: "Processed",
      //Individual Edit
      isLoadDetailsEditable: false,
      isCustomerDetailsEditable: false,
      isRateDetailsEditable: false,
      isSalesDetailsEditable: false,
      isCarrierDetailsEditable: false,
      isFreightDetailsEditable: false,
      isStopsDetailsEditable: false,
      isRequirementsDetailsEditable: false,
      dispatchedStops: [],
      commodity: "",
      tempValue: "",
      typeCommodity: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.submituserRegistrationForm = this.submituserRegistrationForm.bind(
      this
    );
    this.submit = this.submit.bind(this);
    this.billingSubmitForm = this.billingSubmitForm.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.AutoFormatContact = this.AutoFormatContact.bind(this);
  }

  handleClose = () => {
    this.setState({ show: false })
  };
  handleShow = () => {
    this.setState({ show: true })
  };

  confirmation = () => {
    var errors = this.state.errors;
    if (this.state.tempValue === 'dry' ? this.state.freightTypeConfirmation !== 'DRY' : this.state.freightTypeConfirmation !== 'REEFER') {
      errors["freightTypeConfirmation"] =
        "*Not Matched";
      this.setState({ errors });
    }
    else {
      this.setState({ show: false })
      this.setState({ freightType: this.state.tempValue })
    }
  }

  onUnload = (event) => {
    event.preventDefault();
    var http = new XMLHttpRequest();

    var url = BaseURL + "editModeInfo/finishEditMode";

    var params =
      "customerLoadId=" + this.state.loadid + "&token=" + this.state.token;

    http.open("POST", url, true);

    http.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

    if (!this.state.readonly) {
      http.send(params);
      var start = new Date().getTime();
      while (new Date().getTime() < start + 350);
    }
  };

  AutoFormatContact(e) {
    function phone_formatting(ele, restore) {
      var new_number,
        // selection_start = ele.selectionStart,
        // selection_end = ele.selectionEnd,
        number = ele.value.replace(/\D/g, "");

      // automatically add dashes
      if (number.length > 2) {
        // matches: 123 || 123-4 || 123-45
        new_number = number.substring(0, 3) + "-";
        if (number.length === 4 || number.length === 5) {
          // matches: 123-4 || 123-45
          new_number += number.substr(3);
        } else if (number.length > 5) {
          // matches: 123-456 || 123-456-7 || 123-456-789
          new_number += number.substring(3, 6) + "-";
        }
        if (number.length > 6) {
          // matches: 123-456-7 || 123-456-789 || 123-456-7890
          new_number += number.substring(6);
        }
      } else {
        new_number = number;
      }

      // if value is heigher than 12, last number is dropped
      // if inserting a number before the last character, numbers
      // are shifted right, only 12 characters will show
      ele.value =
        new_number.length > 12 ? new_number.substring(0, 12) : new_number;

      // restore cursor selection,
      // prevent it from going to the end
      // UNLESS
      // cursor was at the end AND a dash was added

      // if (new_number.slice(-1) === '-' && restore === false
      //     && (new_number.length === 8 && selection_end === 7)
      //         || (new_number.length === 4 && selection_end === 3)) {
      //     selection_start = new_number.length;
      //     selection_end = new_number.length;
      // }
      // else if (restore === 'revert') {
      //   selection_start--;
      //   selection_end--;
      // }
      // ele.setSelectionRange(selection_start, selection_end);
    }

    function phone_number_check(field, e) {
      var key_code = e.keyCode,
        key_string = String.fromCharCode(key_code),
        press_delete = false,
        dash_key = 189,
        delete_key = [8, 46],
        direction_key = [33, 34, 35, 36, 37, 38, 39, 40],
        selection_end = field.selectionEnd;

      // delete key was pressed
      if (delete_key.indexOf(key_code) > -1) {
        press_delete = true;
      }

      // only force formatting is a number or delete key was pressed
      if (key_string.match(/^\d+$/) || press_delete) {
        phone_formatting(field, press_delete);
      }
      // do nothing for direction keys, keep their default actions
      else if (direction_key.indexOf(key_code) > -1) {
        // do nothing
      } else if (dash_key === key_code) {
        if (selection_end === field.value.length) {
          field.value = field.value.slice(0, -1);
        } else {
          field.value =
            field.value.substring(0, selection_end - 1) +
            field.value.substr(selection_end);
          field.selectionEnd = selection_end - 1;
        }
      }
      // all other non numerical key presses, remove their value
      else {
        e.preventDefault();
        //    field.value = field.value.replace(/[^0-9\-]/g,'')
        phone_formatting(field, "revert");
      }
    }

    const Validate = (e) => {
      if (!String.fromCharCode(e.keyCode).match(/^- $/)) {
        this.handleChange(e);
      }
    };

    document.getElementById(e.target.id).onkeyup = function (e) {
      phone_number_check(this, e);
      Validate(e);
    };
  }

  // 123123 google -- total miles --start
  handleSourceSelect = (soureAddress) => {
    this.setState({ soureAddress });
    geocodeByAddress(soureAddress)
      .then((results) => getLatLng(results[0]))
      .then((soureLatLng) => {
        this.setState({ soureLatLng });
        this.doTimeZoneApiCall(soureLatLng, this.setSourceClock);
      });
  };
  doTimeZoneApiCall = (soureLatLng, func) => {
    timezone.key("AIzaSyDIPwhthYzVPP6RkJG9sl903Bk98XsCi0g");
    timezone.data(soureLatLng.lat, soureLatLng.lng, 1, function (err, tz) {
      func(tz);
    });
  };
  handleDestSelect = (destinationAddress) => {
    this.setState({ destinationAddress });
    geocodeByAddress(destinationAddress)
      .then((results) => getLatLng(results[0]))
      .then((destinationLatLng) => {
        this.setState({ destinationLatLng });
        this.doTimeZoneApiCall(destinationLatLng, this.setDestinationClock);
      });
  };
  setDestinationClock = (timeZone) => {
    if (timeZone.raw_response.status === "OK") {
      var destinationClock = {
        timeZone: timeZone.raw_response.timeZoneId,
        timeZoneName: timeZone.raw_response.timeZoneName,
      };
      this.setState({
        destinationClock,
      });
    }
  };
  setSourceClock = (timeZone) => {
    if (timeZone.raw_response.status === "OK") {
      var checkedOutTime = moment.tz(
        new Date(),
        timeZone.raw_response.timeZoneId
      );
      var sourceClock = {
        checkedOutTime,
        timeZone: timeZone.raw_response.timeZoneId,
        timeZoneName: timeZone.raw_response.timeZoneName,
      };

      this.setState({
        sourceClock,
      });
    }
  };
  setTotalMiles = (miles) => {
    this.setState({
      totalMiles: miles,
    });
  };

  getFormattedWayPoints = (wayPoints) => {
    var newWayPoints = [];
    wayPoints.forEach((element) => {
      if (element.LatLng) {
        newWayPoints.push(element);
      }
    });

    return newWayPoints;
  };
  // 123123 google -- total miles --end

  async componentDidMount() {
    this._isMounted = true;
    window.addEventListener("beforeunload", this.onUnload);
    var role = "Tenant";
    var subRole = "Basic";
    const Role = localStorage.getItem("Role");
    const SubRole = localStorage.getItem("SubRole");

    if (Role) {
      role = cryptr.decrypt(Role);
    }

    if (SubRole) {
      subRole = cryptr.decrypt(SubRole);
      this.setState({ subRole: subRole });
    }

    var token = localStorage.getItem("Token");
    const id = this.props.match.params.id;

    if (this._isMounted) {
      await this.setState({ token: token, loadid: id, role });
      this.getUsersList();
    }

    this.findLoadById();

    await axios
      .post(BaseURL + "customerLoad/getBillingDetails", {
        id: id,
        token: token,
      })
      .then((res) => {
        // console.log("BillingTabRes.....: ", res.data);
        this.setState({
          dispatchPercentage: res.data.dispatchPercentage || 0,
          billingLoad: res.data.internalLoadNo,
          accountingLoad: res.data.internalLoadNo,
          billingCustomsLoad: res.data.customerLoadNo,
          accountingCustomsLoad: res.data.customerLoadNo,
          billingRate: res.data.rate,
          accountingRate: res.data.rate,
          billingAmount: res.data.billingAmount,
          billingStatus: res.data.billingStatus,
          billingInvoice: res.data.invoiceNo,
          billingVerified: res.data.billingVerified,
          accountingVerified: res.data.accountingVerified,
          dispatchFee: res.data.dispatchFee || 0,
          dispatchType: res.data.dispatchType || 2,
          suggestedAmount: res.data.dispatchType == 2 ? (res.data.dispatchPercentage * parseInt(res.data.rate)) / 100 : null
        });
      })
      .catch((err) => {
        console.log("BillingTabError.....: ", err);
      });
  }

  getUsersList = () => {
    let value = 0;
    var postData = {
      token: this.state.token,
      status: 2,
      offset: 0,
      limit: 50,
      sortOrder: "ASC",
      sortBy: "firstName",
    };
    if (
      this.state.role === "Admin" ||
      this.state.role === dispatcherUser ||
      this.state.role === accountingUser
    ) {
      axios
        .post(BaseURL + "users/", postData, {
          headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem("Token", "");
            this.props.history.push("/");
          }
          let array = response.data.data.users.rows;
          array.forEach((user) => {
            optionsUsersList.push({
              label: user.firstName + " " + user.lastName,
              value: value,
            });
            value = value + 1;
          });
        });
    }
  };

  findLoadById = async () => {
    var arr = {
      carrier: {},
      customerContacts: [],
      documents: [],
      customerLoad: {
        loadPickupAndDeliveryDetails: [
          {
            ShipperOptions: [],
            shipperId: null,
            stopNo: 1,
            isAddressAvailable: "1",
            address: "",
            stopType: "",
            scheduleFrom: moment().set({ hour: 0, minute: 0, second: 0 }),
            scheduleTo: moment().set({ hour: 0, minute: 0, second: 0 }),
            puNo: "",
            notes: "",
            shipperOptionsData: [],
            shipperCard: {},
            checkedIn: "0",
            checkedOut: "0",
            checkedInT: 0,
            checkedOutT: 0,
            checkedInTime: moment().set({ hour: 0, minute: 0, second: 0 }),
            checkedOutTime: moment().set({ hour: 0, minute: 0, second: 0 }),
            dispatchCheckBox: false,
          },
          {
            ShipperOptions: [],
            shipperId: null,
            stopNo: 2,
            stopType: "",
            isAddressAvailable: "1",
            address: "",
            scheduleFrom: moment().set({ hour: 0, minute: 0, second: 0 }),
            scheduleTo: moment().set({ hour: 0, minute: 0, second: 0 }),
            puNo: "",
            notes: "",
            shipperOptionsData: [],
            shipperCard: {},
            checkedIn: "0",
            checkedOut: "0",
            checkedInT: 0,
            checkedOutT: 0,
            checkedInTime: moment().set({ hour: 0, minute: 0, second: 0 }),
            checkedOutTime: moment().set({ hour: 0, minute: 0, second: 0 }),
            dispatchCheckBox: false,
          },
        ],
      },
      customer: [],
      shipper: [],
    },
      options = [],
      option1 = [],
      option2 = [],
      contacts = [],
      customerOptionsData = [],
      customerCard = {},
      contactOptionsData = [],
      contactCard = {},
      carrierCard = {};

    await axios
      .post(BaseURL + "customerLoad/findById", {
        token: this.state.token,
        id: this.state.loadid,
      })
      .then((response) => {
        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem("Token", "");
          this.props.history.push("/");
        }

        let audits = response.data.data.auditTrails;
        let getAuditArray = [];
        audits.forEach((audit) => {
          const format1 = "YYYY-MM-DD HH:mm:ss";
          audit.time = moment(audit.time).format(format1);
          let arr = [
            `${audit.usersDetail[0].email}`,
            `${audit.action}`,
            `${audit.type}`,
            `${audit.time}`,
            `${audit.ip}`,
          ];
          getAuditArray.push(arr);
        });
        this.setState({
          auditTrailsResponse: getAuditArray,
        });

        this.setState({
          showSelectedRowOnTabs: response.data.data,
        });

        let PickUpDate = new Date(
          response.data.data.customerLoad.loadPickupAndDeliveryDetails[0].scheduleFrom
        ).toLocaleDateString();

        this.setState({
          showRowPickupDate: PickUpDate,
        });
        let DeliveryDate = new Date(
          response.data.data.customerLoad.loadPickupAndDeliveryDetails[1].scheduleTo
        ).toLocaleDateString();

        this.setState({
          showRowDeliveryDate: DeliveryDate,
        });

        if (response.data.data.shipper[0]) {
          this.setState({
            showRowPickupCityState: response.data.data.shipper[0].address.city,
          });
        }
        if (response.data.data.shipper[1]) {
          this.setState({
            showRowDeliveryCityState:
              response.data.data.shipper[1].address.city,
          });
        }

        this.setState({
          showSelectedRowOnTabsBoolean: true,
        });
        // let pickDelivery = response.data.data.customerLoad.loadPickupAndDeliveryDetails
        // 123123
        // pickDelivery.forEach(element => {
        function isJSON(str) {
          const convertToString = str.toString()

          try {
            let arr = JSON.parse(convertToString)

            if (Array.isArray(arr)) { return true } else {
              return false
            }

          } catch (error) {
            return false
          }

        }
        let loadPickupDetails = response.data.data.customerLoad.loadPickupAndDeliveryDetails
        loadPickupDetails = loadPickupDetails.length !== 0 ? loadPickupDetails.map((loadPickup, index) => {


          if (isJSON(loadPickup.puNo)) {
            loadPickup = { ...loadPickup, puNo: JSON.parse(loadPickup.puNo), checkedInT: new Date(loadPickup.checkedInTime).getTime(), checkedOutT: new Date(loadPickup.checkedOutTime).getTime() }
            return loadPickup
          } else {
            // console.log("above",loadPickup)

            loadPickup = { ...loadPickup, puNo: [loadPickup.puNo], checkedInT: new Date(loadPickup.checkedInTime).getTime(), checkedOutT: new Date(loadPickup.checkedOutTime).getTime() }

            // console.log("else part running",loadPickup)
            return loadPickup
          }
        }) : null
        // let data = response.data.data
        let customerLoad = response.data.data.customerLoad
        customerLoad = { ...customerLoad, loadPickupAndDeliveryDetails: loadPickupDetails }

        let data = { ...response.data.data, customerLoad }
        // response.data.data.customerLoad.loadPickupAndDeliveryDetails=loadPickupDetails 
        arr = response.data.data ? data : arr;
        // console.log(data, 'RESPONSE++++++++++++++++++')

        setTimeout(() => {
          this.state.StopData.forEach((ele, index) => {
            if (index === 0) {
              let address =
                ele.shipperCard.name +
                "," +
                ele.shipperCard.address +
                "," +
                ele.shipperCard.city +
                "," +
                ele.shipperCard.zip;
              if (address === "undefined,undefined,undefined,undefined") {
                this.handleSourceSelect(
                  response.data.data.customerLoad
                    .loadPickupAndDeliveryDetails[0].address
                );
              } else {
                this.handleSourceSelect(address);
              }
            }
            if (index === 1) {
              let address =
                ele.shipperCard.name +
                "," +
                ele.shipperCard.address +
                "," +
                ele.shipperCard.city +
                "," +
                ele.shipperCard.zip;
              if (address === "undefined,undefined,undefined,undefined") {
                this.handleDestSelect(
                  response.data.data.customerLoad
                    .loadPickupAndDeliveryDetails[1].address
                );
              } else {
                this.handleDestSelect(address);
              }
            }
          });
        }, 0);
      })
      .catch(function (error) {
        NotificationManager.error(
          "Something Went Wrong Server Is Not Responding ",
          "Error Message",
          3000
        );
        console.log(error);
      });

    if (this._isMounted) {
      if (arr.customerLoad.freightType !== "") {
        $("#" + arr.customerLoad.freightType).addClass("active");
      }
      // $("#"+arr.customerLoad.temperatureType).addClass('active');
      //  $("#"+arr.customerLoad.cycle).addClass('active');
      //  $("#"+arr.customerLoad.driverRequirements).addClass('active');
      // arr.customerLoad.freightType === 'reefer' ? ( document.getElementById('Shipping1').setAttribute('disabled',true)): {};

      arr.customerLoad.trailerType === "1"
        ? (document.getElementById("Shipping").className = "checked")
        : false;
      arr.customerLoad.trailerTypeOne === "1"
        ? (document.getElementById("Shipping1").className = "checked")
        : false;
      arr.customerLoad.trailerSize === "1"
        ? (document.getElementById("trailerType").className = "checked")
        : false;
      arr.customerLoad.trailerSizeOne === "1"
        ? (document.getElementById("trailerType1").className = "checked")
        : false;
      arr.customerLoad.driverRequirements === "1"
        ? (document.getElementById("driverRequirements").className = "checked")
        : false;
      arr.customerLoad.driverRequirementsOne === "1"
        ? (document.getElementById("driverRequirements1").className = "checked")
        : false;
    }

    arr.carrier !== null && arr.carrier
      ? (options.push({
        value: arr.carrier.id,
        label:
          arr.carrier.carrierName +
          (arr.carrier.address
            ? (arr.carrier.address.address
              ? ", " + arr.carrier.address.address
              : "") +
            (arr.carrier.address.city
              ? ", " + arr.carrier.address.city
              : "") +
            (arr.carrier.address.state
              ? ", " + arr.carrier.address.state.state
              : "") +
            (arr.carrier.address.zip ? ", " + arr.carrier.address.zip : "")
            : ""),
      }),
        (carrierCard = {
          factoringCompany: arr.carrier.factoringCompany,
          address: arr.carrier.address ? arr.carrier.address.address : "",
          city: arr.carrier.address ? arr.carrier.address.city : "",
          state: arr.carrier.address ? arr.carrier.address.state.state : "",
        }))
      : "";

    arr.customer !== null && arr.customer.address
      ? (customerOptionsData.push(arr.customer),
        (customerCard = {
          address: arr.customer.address.address,
          city: arr.customer.address.city,
          state: arr.customer.address.state.state,
        }),
        option1.push({
          value: arr.customer.id,
          label:
            arr.customer.customerName +
            (arr.customer.address.address
              ? ", " + arr.customer.address.address
              : "") +
            (arr.customer.address.city
              ? ", " + arr.customer.address.city
              : "") +
            (arr.customer.address.state
              ? ", " + arr.customer.address.state.state
              : "") +
            (arr.customer.address.zip ? ", " + arr.customer.address.zip : ""),
        }))
      : "";

    arr.customerLoad.loadPickupAndDeliveryDetails.forEach((shipper) => {
      shipper.ShipperOptions = [];
      shipper.shipperOptionsData = [];
      shipper.shipperCard = {};

      arr.shipper.forEach((element) => {
        if (element && shipper.shipperId === element.id) {
          var CompleteAddress = "";

          var address = element.address
            ? element.address.address
              ? element.address.address
              : ""
            : "";
          var city = element.address
            ? element.address.city
              ? element.address.city
              : ""
            : "";
          var state = element.address
            ? element.address.state
              ? element.address.state.state
              : ""
            : "";
          var zip = element.address
            ? element.address.zip
              ? element.address.zip
              : ""
            : "";
          var telephone = element.address
            ? element.address.telephone
              ? element.address.telephone
              : ""
            : "";
          var stateAbbreviation = element.address
            ? element.address.state
              ? element.address.state.stateAbbrivation
              : ""
            : "";

          CompleteAddress =
            (address ? ", " + address + ", " : address) +
            (city ? city + ", " : city) +
            (state ? state + ", " : state) +
            zip;

          shipper.shipperOptionsData.push(element);
          shipper.shipperCard = {
            address: address,
            city: city,
            state: state,
            telephone: telephone,
            name: element.name,
            stateAbbr: stateAbbreviation,
            zip: zip,
          };

          shipper.ShipperOptions = [
            { value: element.id, label: element.name + CompleteAddress },
          ];
        }
      });
    });

    arr.customerContacts
      ? arr.customerContacts.forEach((element) => {
        contactOptionsData.push(element);
        arr.customerLoad.customerContactId === element.id
          ? (contactCard = {
            name: element.name,
            email: element.email,
            telephone: element.telephone,
            fax: element.fax,
          })
          : "";
        var obj = { value: element.id, label: element.name };

        contacts.push(obj);
      })
      : {};

    var Documents = this.state.Documents;

    arr.documents.forEach((element) => {
      var filename = element.documentName;
      var extension = element.documentName.split(".").pop();

      if (filename.length > 15) {
        filename = filename.substring(0, 15) + "....";
      }

      Documents.forEach((docElement) => {
        if (docElement.documentNo === element.documentNo) {
          docElement["fileName"] = filename;
          docElement["s3FileKey"] = element.s3FileKey;
          docElement["documentId"] = element.id;
          docElement["fileExtension"] = extension;
        }
      });

      if (element.documentNo > 4) {
        var docType = element.documentType;
        var label =
          docType === "1"
            ? "Rate Sheet"
            : docType === "2"
              ? "BOL (Bill of Loading)"
              : docType === "3"
                ? "POD (Proof of Delivery)"
                : "Lumper Receipt";

        var obj = {
          label: label,
          docType: docType,
          documentNo: element.documentNo,
          fileName: filename,
          error: "",
          showIcons: true,
          file: "",
          s3FileKey: element.s3FileKey,
          documentId: element.id,
          invoiceError: "",
          fileExtension: extension,
        };
        Documents.push(obj);
      }
    });

    arr.invoiceSelectedDocuments
      ? Documents.forEach((element) => {
        arr.invoiceSelectedDocuments.forEach((elem) => {
          if (elem.loadDocumentId === element.documentId) {
            element.checked = true;
          }
        });
      })
      : [];

    var loadFormData = this.state.loadFormData;
    loadFormData = arr.customerLoad;
    loadFormData["rate"] = arr.customerLoad.rate
      ? arr.customerLoad.rate
      : "0.00";

    this.state.selectRateTypeValue = { label: loadFormData.rateType, value: 0 };
    this.state.salesPerson1 = {
      label: !loadFormData.salesDetails
        ? "Select..."
        : loadFormData.salesDetails,
      value: 0,
    };
    this.state.salesPerson2 = {
      label: !loadFormData.salesDetails1
        ? "Select..."
        : loadFormData.salesDetails1,
      value: 0,
    };

    if (this._isMounted) {
      await this.setState({
        Documents,
        carrierdata: carrierCard,
        loadData: arr.carrier ? [arr.carrier] : [],
        CarrierOptions: options,
        CustomerOptions: option1,
        ContactOptions: contacts,
        loadFormData: loadFormData,
        CustomerSelectedOption: arr.customerLoad.customerId,
        ContactSelectedOption: arr.customerLoad.customerContactId,
        CarrierSelectedOption: arr.customerLoad.carrierId,
        StopData: arr.customerLoad.loadPickupAndDeliveryDetails,
        freightType: arr.customerLoad.freightType,
        temperatureType: arr.customerLoad.temperatureType,
        cycle: arr.customerLoad.cycle,
        driverRequirements: arr.customerLoad.driverRequirements,
        customerOptionsData: customerOptionsData,
        customerCard: customerCard,
        contactOptionsData: contactOptionsData,
        contactCard: contactCard,

        totalAmount: arr.invoice
          ? arr.invoice.totalAmount
            ? currencyFormatter.format(arr.invoice.totalAmount, {
              code: "USD",
            })
            : "$0.00"
          : arr.customerLoad.rate
            ? currencyFormatter.format(arr.customerLoad.rate, { code: "USD" })
            : "$0.00",
        additionalCharges: arr.invoiceAdditionalCharges
          ? arr.invoiceAdditionalCharges
          : [],
        paymentType: arr.invoice ? arr.invoice.paymentType : "Quick Pay",
        invoiceId: arr.invoice ? arr.invoice.id : null,
        invoiceURL: arr.invoice ? arr.invoice.invoiceURL : null,
        invoiceS3FileKey: arr.invoice ? arr.invoice.s3FileKey : null,
        invoiceFileName: arr.invoice ? arr.invoice.fileName : "",
        quickPay: arr.invoice ? arr.invoice.quickPay : "",
        invoiceTo:
          arr.invoice && arr.invoice.invoiceTo
            ? arr.invoice.invoiceTo
            : "Customer",
        invoiceStatus:
          arr.invoice && arr.invoice.invoiceStatus
            ? arr.invoice.invoiceStatus
            : "Processed",
        commodity: arr.customerLoad.commodity,
        typeCommodity: arr.customerLoad.typeCommodity
      });
    }

    //  this.loadcarrierinfo(arr.customerLoad.carrierId);
  };

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("beforeunload", this.onUnload);
    var config = {
      customerLoadId: this.state.loadid,
      token: this.state.token,
    };

    if (config.customerLoadId) {
      finishEditinfo(
        config,
        (res) => {
          console.log(res);
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  async handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    if (this._isMounted) {
      await this.setState({
        fields,
      });
    }
    this.validateForm();
  }
  addressCheckboxHandler = (e, index) => {
    const StopData = this.state.StopData;
    StopData[index].isAddressAvailable = e.target.checked ? "0" : "1";
    StopData[index].shipperId = StopData[index].shipperId === null ? "" : null;
    this.setState(
      {
        StopData,
      },
      () => console.log(this.state.StopData[index].shipperId)
    );
  };
  handleAddressChange = (address, index) => {
    if (this._isMounted) {
      this.handleSelect(address, index);
    }
  };

  handleSelect = (address, index) => {
    if (this._isMounted) {
      let StopData = this.state.StopData;
      StopData[index].address = address;
      this.setState({ StopData });
      geocodeByAddress(address)
        .then((results) => getLatLng(results[0]))
        .then((latLng) => this.setState({ addressLatLng: latLng }))
        .catch((error) => console.error("Error", error));
    }
  };

  submituserRegistrationForm(e) {
    e.preventDefault();
    var send = false;

    if (this.validateAndSubmit() && this.validateForm() && this.validateDates()) {
      console.log(this.validateDates(), 'DATES STATUS')
      send = true;
    }
    if (send) {
      this.submit();
    }
  }
  //submitBillingRegistrationForm....
  submitBillingRegistrationForm(e) {
    e.preventDefault();
    var send = false;

    // if (this.validateBillingSubmit() && this.validateForm()) {
    if (this.validateForm()) {
      send = true;
    }
    if (send) {
      this.billingSubmitForm();
    }
  }
  //.............
  //.............
  //submitAccountingRegistrationForm....
  submitAccountingRegistrationForm(e) {
    e.preventDefault();
    var send = false;

    // if (this.validateBillingSubmit() && this.validateForm()) {
    if (this.validateForm()) {
      send = true;
    }
    if (send) {
      this.accountingSubmitForm();
    }
  }
  //.............

  validateDates() {
    let arr = []
    for (let ind = 0; ind < this.state.StopData.length; ind++) {
      const el = this.state.StopData[ind];
      let from = new Date(el.scheduleFrom).getTime()
      let to = new Date(el.scheduleTo).getTime()
      let checkedIn = new Date(el.checkedInTime).getTime()
      let checkedOut = new Date(el.checkedOutTime).getTime()
      if (from > to) {
        NotificationManager.error(
          `"Schedule From" date cannot be greater than "Schedule To"`,
          `Stop ${ind + 1}`,
          4000
        );
        arr.push(false)
      }
      else if (checkedIn > checkedOut) {
        NotificationManager.error(
          `CheckedIn Time can not be greater than CheckedOut Time.`,
          `Stop ${ind + 1}`,
          4000
        );
        arr.push(false)
      }
      else {
        arr.push(true)
      }
    }
    let a = arr.find(el => el == false)
    if (a == false) {
      return false
    } else {
      return true
    }

  }

  validateAndSubmit() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!this.state.CustomerSelectedOption) {
      formIsValid = false;
      errors["customer"] = "*Please Select Customer";
      $("[name='load']").focus();
    } else if (
      !this.refs.load.value ||
      !this.refs.load.value.trim().length > 0
    ) {
      formIsValid = false;
      errors["load"] = "*Please Enter Customers Load";
      errors["customer"] = "";
      $("[name='load']").focus();
    }

    if (this._isMounted) {
      this.setState({
        errors: errors,
      });
    }
    return formIsValid;
  }

  //..........
  //EditBillingForm....
  EditAccountingForm() {
    var div = document.getElementById("editAccountingDiv");
    div.setAttribute("style", " cursor: not-allowed; pointer-events: none");

    var config = {
      customerLoadId: this.state.loadid,
      token: this.state.token,
    };

    getEditinfo(
      config,
      (res) => {
        console.log(res);
        if (res.data.status === 0) {
          if (this._isMounted) {
            this.setState({
              accountingInfoReadOnly: false,
              CheckEditInfo: config,
            });

            this.state.freightType === "reefer"
              ? this.refs.Shipping1.setAttribute("disabled", true)
              : {};
          }
        } else {
          div.removeAttribute("style");
          NotificationManager.warning(
            res.data.message,
            "Warning Message",
            3000
          );
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  //..........
  //validateBillingSubmit.....
  validateBillingSubmit() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!this.state.CustomerSelectedOption) {
      formIsValid = false;
      errors["customer"] = "*Please Select Customer";
      $("[name='load']").focus();
    } else if (
      !this.refs.load.value ||
      !this.refs.load.value.trim().length > 0
    ) {
      formIsValid = false;
      errors["load"] = "*Please Enter Customers Load";
      errors["customer"] = "";
      $("[name='load']").focus();
    }

    if (this._isMounted) {
      this.setState({
        errors: errors,
      });
    }
    return formIsValid;
  }
  //...............

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    // dispatchPercentage
    if (
      typeof fields["dispatchPercentage"] !== "undefined" &&
      fields["dispatchPercentage"]
    ) {
      if (
        !fields["dispatchPercentage"].match(
          /(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/
        )
      ) {
        formIsValid = false;
        errors["dispatchPercentage"] =
          "*Percentage Accept Numeric Value And Not Greater Than 100";
      }
    }

    // billingInvoice
    if (
      typeof fields["billingInvoice"] !== "undefined" &&
      fields["billingInvoice"]
    ) {
      if (!fields["billingInvoice"].match(/^[a-zA-Z0-9]{1,200}$/)) {
        formIsValid = false;
        errors["billingInvoice"] =
          "*Only Alpha-numerics Allowed (Max Range: 200)";
      }
    }

    if (typeof fields["load"] !== "undefined" && fields["load"]) {
      if (fields["load"].length > 200) {
        formIsValid = false;
        errors["load"] = "*Please Enter Only Valid Characters (Max Range: 200)";
      }
    }

    if (
      typeof fields["internalload"] !== "undefined" &&
      fields["internalload"]
    ) {
      if (fields["internalload"].length > 200) {
        formIsValid = false;
        errors["internalload"] =
          "*Please Enter Only Valid Characters (Max Range: 200)";
      }
    }

    if (typeof fields["rate"] !== "undefined" && fields["rate"]) {
      if (fields["rate"].length > 200) {
        formIsValid = false;
        errors["rate"] = "*Please Enter Only Valid Characters (Max Range: 200)";
      }
    }

    if (typeof fields["temperature"] !== "undefined" && fields["temperature"]) {
      if (fields["temperature"].length > 200) {
        formIsValid = false;
        errors["temperature"] =
          "*Please Enter Only Valid Characters (Max Range: 200)";
      }
    }

    if (this.state.errors.internalload) {
      formIsValid = false;
      errors["internalload"] = this.state.errors.internalload;
      this.refs.internalload.focus();
    }

    if (this.state.errors.internalloadlimit) {
      formIsValid = false;
      errors["internalloadlimit"] = this.state.errors.internalloadlimit;
      this.refs.internalload.focus();
    }

    if (this.state.errors.load) {
      formIsValid = false;
      errors["load"] = "*Record Already Exist With this Load No";
      this.refs.load.focus();
    }

    if (this._isMounted) {
      this.setState({
        errors: errors,
      });
    }
    return formIsValid;
  }

  EditForm = (name) => {
    var div = document.getElementById("EditDiv");
    div.setAttribute("style", " cursor: not-allowed; pointer-events: none");

    if (
      this.state.isCustomerDetailsEditable ||
      this.state.isLoadDetailsEditable ||
      this.state.isRateDetailsEditable ||
      this.state.isSalesDetailsEditable ||
      this.state.isFreightDetailsEditable ||
      this.state.isStopsDetailsEditable ||
      this.state.isCarrierDetailsEditable ||
      this.state.isRequirementsDetailsEditable
    ) {
      this.setState({
        [name]: true,
      });
      return false;
    }

    var config = {
      customerLoadId: this.state.loadid,
      token: this.state.token,
    };

    getEditinfo(
      config,
      (res) => {
        // console.log(res);
        if (res.data.status === 0) {
          if (this._isMounted) {
            this.setState({
              readonly: false,
              [name]: true,
              CheckEditInfo: config,
            });

            this.state.freightType === "reefer"
              ? this.refs.Shipping1.setAttribute("disabled", true)
              : {};
          }
        } else {
          div.removeAttribute("style");
          NotificationManager.warning(
            res.data.message,
            "Warning Message",
            3000
          );
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  //..............
  //accountingSubmitForm............
  accountingSubmitForm() {
    this.setState({ accountingInfoReadOnly: true });

    var dispatchType = this.state.dispatchType;
    var dispatchPercentage = this.state.dispatchPercentage;
    var dispatchFee = this.state.dispatchFee;

    var Load = {
      token: this.state.token,
      id: this.state.loadid,
      invoiceNo:
        this.state.fields["billingInvoice"] === ""
          ? null
          : this.state.fields["billingInvoice"],
      rate: this.state.billingRate,
      billingStatus: this.state.billingStatus,
      billingVerified: this.state.billingVerified,
      accountingVerified: this.state.accountingVerified,
      dispatchType: dispatchType,
      dispatchPercentage:
        dispatchPercentage === 0 || dispatchType === 1
          ? null
          : dispatchPercentage,
      dispatchFee: dispatchFee === 0 || dispatchType === 2 ? null : dispatchFee,
    };

    if (this.state.loadid) {
      axios
        .post(BaseURL + "customerLoad/updateBillingDetails", Load)
        .then((response) => {
          console.log(response.data);
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem("Token", "");
            this.props.history.push("/");
          } else if (response.data.error === "Load Already Exist") {
            this.setState({ accountingInfoReadOnly: false });
            NotificationManager.error(
              "Accounting Load Already Exist With This Number",
              "Error",
              3000
            );
          } else {
            this.setState({ accountingInfoHidden: false });
            NotificationManager.success(
              "Accounting Load Upated Successfully",
              "Edited",
              3000
            );
            //.....getBillingTab.............
            axios
              .post(BaseURL + "customerLoad/getBillingDetails", {
                id: this.state.loadid,
                token: this.state.token,
              })
              .then((res) => {
                console.log("BillingTabRes.....: ", res.data);
                // alert(res.data.dispatchType == 2 ? (res.data.dispatchPercentage * parseInt(res.data.rate) )/ 100  : null);
                this.setState({
                  dispatchPercentage: res.data.dispatchPercentage,
                  billingLoad: res.data.internalLoadNo,
                  billingCustomsLoad: res.data.customerLoadNo,
                  billingRate: res.data.rate,
                  billingAmount: res.data.billingAmount,
                  billingStatus: res.data.billingStatus,
                  billingInvoice: res.data.invoiceNo,
                  billingVerified: res.data.billingVerified,
                  accountingVerified: res.data.accountingVerified,
                  suggestedAmount: res.data.dispatchType == 2 ? (res.data.dispatchPercentage * parseInt(res.data.rate)) / 100 : null
                });
              })
              .catch((err) => {
                console.log("AccountingTabError.....: ", err);
              });
            //..............
          }
        })
        .catch(function (error) {
          NotificationManager.error(
            "Something Went Wrong Server Is Not Responding ",
            "Error Message",
            3000
          );
          console.log(error);
        });
    }
  }
  //..............

  //EditBillingForm....
  EditBillingForm() {
    var div = document.getElementById("editBillingDiv");
    div.setAttribute("style", " cursor: not-allowed; pointer-events: none");

    var config = {
      customerLoadId: this.state.loadid,
      token: this.state.token,
    };

    getEditinfo(
      config,
      (res) => {
        console.log(res);
        if (res.data.status === 0) {
          if (this._isMounted) {
            this.setState({
              billingInfoReadOnly: false,
              CheckEditInfo: config,
            });

            this.state.freightType === "reefer"
              ? this.refs.Shipping1.setAttribute("disabled", true)
              : {};
          }
        } else {
          div.removeAttribute("style");
          NotificationManager.warning(
            res.data.message,
            "Warning Message",
            3000
          );
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  //..........

  async cancel() {
    if (window.confirm(" Are you sure you want to cancel ? "))
      var from = this.props.match.params.from
        ? "/" + this.props.match.params.from
        : "";
    var to = from || "/load";
    this.props.history.push(to);
  }

  submit() {
    var myAuditType = "";
    if (this.state.isLoadDetailsEditable === true) {
      myAuditType = "Load Details";
    }
    if (this.state.isCustomerDetailsEditable === true) {
      myAuditType = "Customer Details";
    }
    if (this.state.isFreightDetailsEditable === true) {
      myAuditType = "Freight Details";
    }
    if (this.state.isCarrierDetailsEditable === true) {
      myAuditType = "Carrier Details";
    }
    if (this.state.isRateDetailsEditable === true) {
      myAuditType = "Rate Details";
    }
    if (this.state.isSalesDetailsEditable === true) {
      myAuditType = "Sales Details";
    }
    if (this.state.isStopsDetailsEditable === true) {
      myAuditType = "Pickup and Delivery Details";
    }
    if (this.state.isRequirementsDetailsEditable === true) {
      myAuditType = "Requirements Details";
    }

    this.setState({
      isLoadDetailsEditable: false,
      isCustomerDetailsEditable: false,
      isFreightDetailsEditable: false,
      isCarrierDetailsEditable: false,
      isRateDetailsEditable: false,
      isSalesDetailsEditable: false,
      isStopsDetailsEditable: false,
      isRequirementsDetailsEditable: false,
    });

    const internalload = this.refs.internalload.value.trim();
    const load = this.refs.load.value.trim();
    const customerId = this.state.CustomerSelectedOption;
    const contactId = this.state.ContactSelectedOption;
    const rate = this.state.loadFormData.rate;
    const carrierId = this.state.CarrierSelectedOption;
    const freightType = this.state.freightType;
    const temperature =
      this.state.freightType !== "dry" ? this.refs.temperature.value : "";
    const temperatureType = this.state.temperatureType;
    const cycle = this.state.cycle;
    const loadStatus = this.refs.loadStatus.value;

    const trailerType =
      document.getElementById("trailerType").className === "checked"
        ? true
        : false;
    const trailerType1 =
      document.getElementById("trailerType1").className === "checked"
        ? true
        : false;
    const Shipping =
      document.getElementById("Shipping").className === "checked"
        ? true
        : false;
    const Shipping1 =
      document.getElementById("Shipping1").className === "checked"
        ? true
        : false;

    let r = JSON.stringify(this.state.StopData)
    let pickuUPs = JSON.parse(r)

    pickuUPs.map((stop, ind) => {

      const stringfyPickups = JSON.stringify(stop.puNo)
      stop.puNo = stringfyPickups
      return stop
    })
    console.log(pickuUPs, '+++++++++++++++++++++')
    const loadPickupAndDeliveryDetails = pickuUPs

    // const loadPickupAndDeliveryDetails = this.state.StopData;

    const driverRequirements =
      document.getElementById("driverRequirements").className === "checked"
        ? true
        : false;
    const driverRequirements1 =
      document.getElementById("driverRequirements1").className === "checked"
        ? true
        : false;

    if (this.state.salesPerson1 == null || this.state.salesPerson1 == "") {
      this.state.salesPerson1 = { label: "Select...", value: 0 };
    }
    if (this.state.salesPerson2 == null || this.state.salesPerson2 == "") {
      this.state.salesPerson2 = { label: "Select...", value: 0 };
    }
    if (this.state.selectRateTypeValue == null) {
      this.state.selectRateTypeValue = { label: "Line Haul", value: 0 };
    }

    var Load = {
      auditType: myAuditType,
      token: this.state.token,
      id: this.state.loadid,
      internalLoadNo: internalload,
      customerLoadNo: load,
      rate: rate,
      rateType: this.state.selectRateTypeValue.label,
      salesDetails: {
        salesPerson1:
          this.state.salesPerson1.label != "Select..."
            ? this.state.salesPerson1.label
            : "",
        salesPerson2:
          this.state.salesPerson2.label != "Select..."
            ? this.state.salesPerson2.label
            : "",
      },
      loadStatus: loadStatus,
      freightType: freightType,
      temperature: temperature,
      temperatureType: temperatureType,
      cycle: cycle,
      trailerType: Shipping,
      trailerTypeOne: Shipping1,
      trailerSize: trailerType,
      trailerSizeOne: trailerType1,
      driverRequirements: driverRequirements,
      driverRequirementsOne: driverRequirements1,
      customerId: customerId,
      customerContactId: contactId,
      carrierId: carrierId,
      loadPickupAndDeliveryDetails: loadPickupAndDeliveryDetails,
      commodity: this.state.commodity,
      typeCommodity: this.state.typeCommodity
    };

    // Load.loadPickupAndDeliveryDetails.forEach(element => {
    //   let getDateSF = new Date(moment(element.scheduleFrom).utc().toDate())
    //   let setDateSF = getDateSF.setHours(getDateSF.getHours() - 7);
    //   element.scheduleFrom = setDateSF;

    //   let getDateSTo = new Date(moment(element.scheduleTo).utc().toDate())
    //   let setDateSTo = getDateSTo.setHours(getDateSTo.getHours() - 7);
    //   element.scheduleTo = setDateSTo;
    // });

    Load.loadPickupAndDeliveryDetails.forEach((element) => {
      element.scheduleFrom = new Date(element.scheduleFrom).toISOString();
      element.scheduleTo = new Date(element.scheduleTo).toISOString();
    });

    setTimeout(() => {
      this.state.StopData.forEach((ele, index) => {
        if (index === 0) {
          let address =
            ele.shipperCard.name +
            "," +
            ele.shipperCard.address +
            "," +
            ele.shipperCard.city +
            "," +
            ele.shipperCard.zip;
          if (address === "undefined,undefined,undefined,undefined") {
            this.handleSourceSelect(
              Load.loadPickupAndDeliveryDetails[0].address
            );
          } else {
            this.handleSourceSelect(address);
          }
        }
        if (index === 1) {
          let address =
            ele.shipperCard.name +
            "," +
            ele.shipperCard.address +
            "," +
            ele.shipperCard.city +
            "," +
            ele.shipperCard.zip;
          if (address === "undefined,undefined,undefined,undefined") {
            this.handleDestSelect(Load.loadPickupAndDeliveryDetails[1].address);
          } else {
            this.handleDestSelect(address);
          }
        }
      });
    }, 0);

    if (this.state.loadid) {
      axios
        .post(BaseURL + "customerLoad/update", Load)
        .then((response) => {
          console.log(response.data);
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem("Token", "");
            this.props.history.push("/");
          } else if (response.data.error === "Load Already Exist") {
            this.setState({ readonly: false });
            NotificationManager.error(
              "Customer Load Already Exist With This Number",
              "Error",
              3000
            );
          } else {
            this.setState({ hidden: false });
            NotificationManager.success(
              "Customer Load Updated Successfully",
              "Edited",
              3000
            );
          }
        })
        .catch(function (error) {
          NotificationManager.error(
            "Something Went Wrong Server Is Not Responding ",
            "Error Message",
            3000
          );
          console.log(error);
        });
    }
  }

  //billingSubmitForm............
  billingSubmitForm() {
    this.setState({ billingInfoReadOnly: true });

    var dispatchType = this.state.dispatchType;
    var dispatchPercentage = this.state.dispatchPercentage;
    var dispatchFee = this.state.dispatchFee;

    var Load = {
      token: this.state.token,
      id: this.state.loadid,
      invoiceNo:
        this.state.fields["billingInvoice"] === ""
          ? null
          : this.state.fields["billingInvoice"],
      rate: this.state.billingRate,
      billingStatus: this.state.billingStatus,
      billingVerified: this.state.billingVerified,
      dispatchType: dispatchType,
      dispatchPercentage:
        dispatchPercentage === 0 || dispatchType === 1
          ? null
          : dispatchPercentage,
      dispatchFee: dispatchFee === 0 || dispatchType === 2 ? null : dispatchFee,
      billingAmount: this.state.billingAmount
    };

    if (this.state.loadid) {
      axios
        .post(BaseURL + "customerLoad/updateBillingDetails", Load)
        .then((response) => {
          console.log(response.data);
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem("Token", "");
            this.props.history.push("/");
          } else if (response.data.error === "Load Already Exist") {
            this.setState({ billingInfoReadOnly: false });
            NotificationManager.error(
              "Billing Load Already Exist With This Number",
              "Error",
              3000
            );
          } else {
            this.setState({ billingInfoHidden: false });
            NotificationManager.success(
              "Billing Load Upated Successfully",
              "Edited",
              3000
            );
            //.....getBillingTab.............
            axios
              .post(BaseURL + "customerLoad/getBillingDetails", {
                id: this.state.loadid,
                token: this.state.token,
              })
              .then((res) => {
                this.setState({
                  dispatchPercentage: res.data.dispatchPercentage,
                  billingLoad: res.data.internalLoadNo,
                  billingCustomsLoad: res.data.customerLoadNo,
                  billingRate: res.data.rate,
                  billingAmount: res.data.billingAmount,
                  billingStatus: res.data.billingStatus,
                  billingInvoice: res.data.invoiceNo,
                  billingVerified: res.data.billingVerified,
                  suggestedAmount: res.data.dispatchType == 2 ? (res.data.dispatchPercentage * parseInt(res.data.rate)) / 100 : null
                });
                console.log(this.state);
              })
              .catch((err) => {
                console.log("BillingTabError.....: ", err);
              });
            //..............
          }
        })
        .catch(function (error) {
          NotificationManager.error(
            "Something Went Wrong Server Is Not Responding ",
            "Error Message",
            3000
          );
          console.log(error);
        });
    }
  }
  //..............

  async SaveContact(event) {
    event.preventDefault();

    if (this._isMounted) {
      this.setState({
        isLoading: true,
      });
    }

    $("#closePopUp").click();
    var arr;
    var data = false;
    var selectvalue = this.state.CustomerSelectedOption;

    if (selectvalue) {
      var CustomerContactUpdate = {
        token: this.state.token,
        customerId: selectvalue,
        name: this.refs.ContactName.value.trim(),
        title: this.refs.ContactTitle.value.trim(),
        email: this.refs.ContactEmail.value.trim(),
        telephone: this.refs.ContactTelephone.value.trim(),
        ext: this.refs.ContactExt.value.trim(),
        cellphone: this.refs.ContactPhone.value.trim(),
        fax: this.refs.ContactFax.value.trim(),
      };
      this.ClearContact();

      await axios
        .post(BaseURL + "customerContact/save", CustomerContactUpdate)
        .then((response) => {
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem("Token", "");
            this.props.history.push("/");
          }
          console.log(response.data);

          NotificationManager.success(
            "Customer Contact Infomation Saved",
            "Customer Info",
            3000
          );
        })
        .catch(function (error) {
          NotificationManager.error(
            "Something Went Wrong Server Is Not Responding ",
            "Error Message",
            3000
          );
          console.log(error);
        });

      this.handleChangeCustomer({ value: selectvalue });
      if (this._isMounted) {
        this.setState({
          whileSave: false,
          isLoading: false,
        });
      }
    }
  }

  ClearContact = async () => {
    this.refs.ContactName.value = "";
    this.refs.ContactTitle.value = "";
    this.refs.ContactEmail.value = "";
    this.refs.ContactTelephone.value = "";
    this.refs.ContactExt.value = "";
    this.refs.ContactPhone.value = "";
    this.refs.ContactFax.value = "";
  };

  handleChangeCarrier = (selectedOption) => {
    var event = selectedOption ? selectedOption.value : null;
    if (this._isMounted) {
      this.setState({ CarrierSelectedOption: event });
    }
    this.loadcarrierinfo(event);
  };

  loadcarrierinfo(e) {
    if (e) {
      var id = e;
      var data;

      this.state.loadData
        ? this.state.loadData.forEach((element) => {
          if (element.id === Number(id)) {
            data = {
              factoringCompany: element.factoringCompany,
              address: element.address.address,
              city: element.address.city,
              state: element.address.state.state,
            };
          }
        })
        : {};

      if (data && this._isMounted) {
        data.factoringCompany
          ? this.setState({ carrierdata: data, invoiceTo: "Factoring Company" })
          : this.setState({ carrierdata: data });
      }
    } else {
      if (this._isMounted) {
        this.setState({ carrierdata: "" });
      }
    }
  }

  handleChangeCustomer = async (selectedOption) => {
    var event = selectedOption ? selectedOption.value : null;

    if (event) {
      if (this._isMounted) {
        this.setState({ CustomerSelectedOption: event });
      }

      this.state.customerOptionsData.forEach((element) => {
        if (element.id === event) {
          this.setState({
            customerCard: {
              address: element.address.address,
              city: element.address.city,
              state: element.address.state.state,
            },
          });
        }
      });

      var arr = [],
        options = [];
      await axios
        .post(BaseURL + "customerContact/findByCustomerId", {
          token: this.state.token,
          customerId: event,
        })
        .then((response) => {
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem("Token", "");
            this.props.history.push("/");
          }
          arr = response.data.data;
        })
        .catch(function (error) {
          NotificationManager.error(
            "Something Went Wrong Server Is Not Responding ",
            "Error Message",
            3000
          );
          console.log(error);
        });

      arr.forEach((element) => {
        var obj = { value: element.id, label: element.name };

        options.push(obj);
      });

      if (this._isMounted) {
        this.setState({
          ContactOptions: options,
          contactOptionsData: arr,
          contactCard: {},
        });
      }
    } else {
      if (this._isMounted) {
        this.setState({
          CustomerSelectedOption: event,
          ContactOptions: [],
          ContactSelectedOption: event,
          customerCard: {},
          contactCard: {},
        });
      }
    }
  };

  handleChangeContact = (selectedOption) => {
    var event = selectedOption ? selectedOption.value : null;

    if (this._isMounted) {
      event === null
        ? this.setState({ ContactSelectedOption: event, contactCard: {} })
        : this.setState({ ContactSelectedOption: event });
    }

    this.state.contactOptionsData.forEach((element) => {
      if (element.id === event) {
        this.setState({
          contactCard: {
            name: element.name,
            email: element.email,
            telephone: element.telephone,
            fax: element.fax,
          },
        });
      }
    });
  };

  addStopChild = async (e) => {
    e.preventDefault();

    var StopData = await this.state.StopData;

    await StopData.push({
      ShipperOptions: [],
      shipperId: null,
      stopNo: StopData.length + 1,
      address: "",
      isAddressAvailable: "1",
      stopType: "pickup",
      scheduleFrom: moment().set({ hour: 0, minute: 0, second: 0 }),
      scheduleTo: moment().set({ hour: 0, minute: 0, second: 0 }),
      puNo: [""],
      notes: "",
      shipperOptionsData: [],
      shipperCard: {},
      checkedIn: "0",
      checkedOut: "0",
      checkedInT: 0,
      checkedOutT: 0,
      checkedInTime: moment().set({ hour: 0, minute: 0, second: 0 }),
      checkedOutTime: moment().set({ hour: 0, minute: 0, second: 0 }),
      dispatchCheckBox: false,
    });

    if (this._isMounted) {
      this.setState({
        StopData: StopData,
      });
    }
  };

  handleCheckBox = (e) => {
    var id = e.target.name;
    var className = document.getElementById(id).className;
    if (className) {
      this.refs[id].checked = false;
      document.getElementById(id).className = "";
    } else {
      this.refs[id].checked = true;
      document.getElementById(id).className = "checked";
    }
  };

  onFileChange(index, element) {

    var Documents = this.state.Documents;

    var file = element.file;

    function getFileExtension(filename) {
      return filename.split(".").pop();
    }

    Documents[index]["fileExtension"] = getFileExtension(file.name);

    var filename = { name: file.name };

    if (
      file.type !== "application/pdf" &&
      file.type !== "image/jpeg" &&
      file.type !== "image/png" &&
      file.type !== "image/jpg" &&
      file.type !== "image/gif" &&
      file.type !== "image/tif" &&
      getFileExtension(file.name) !== "docx" &&
      getFileExtension(file.name) !== "doc" &&
      getFileExtension(file.name) !== "odt" &&
      getFileExtension(file.name) !== "xls" &&
      getFileExtension(file.name) !== "xlsx" &&
      getFileExtension(file.name) !== "ppt" &&
      getFileExtension(file.name) !== "pptx"
    ) {
      Documents[index]["error"] =
        "Please Upload only PDF ,Images, Document files ";
      this.setState({ Documents });

      return false;
    } else if (file.size > 1000000) {
      Documents[index]["error"] =
        "Please Upload A File Less Than 1MB ( Max size for file: 1MB ) ";
      this.setState({ Documents });
      return false;
    } else {
      Documents[index]["error"] = "";
      this.setState({ Documents });
    }

    if (filename.name.length > 15) {
      filename = {
        name: filename.name.substring(0, 15) + "....",
      };
    }

    var doctype = element.docType;

    Documents[index]["showIcons"] = false;
    this.setState({
      Documents,
    });

    console.log("enter in upload file");
    let data = new FormData();
    data.append("file", file);
    data.append("fileName", file.name);
    data.append("loadId", this.state.loadid);
    data.append("type", file.type);
    data.append("documentType", doctype);
    data.append("documentNo", element.documentNo);

    axios
      .post(BaseURL + "loadDocuments/uploadFileToS3", data, {
        headers: {
          "Content-Type": "multipart/form-data",
          token: this.state.token,
        },
      })
      .then((response) => {
        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem("Token", "");
          this.props.history.push("/");
        }
        console.log(response.data);
        NotificationManager.success(
          "Document Uploaded Successfully",
          "Upload",
          3000
        );

        Documents[index]["showIcons"] = true;
        Documents[index]["fileName"] = filename.name;
        Documents[index]["s3FileKey"] = response.data.data.s3FileKey;
        Documents[index]["documentId"] = response.data.data.id;

        this.setState({
          Documents,
        });
      })
      .catch(function (error) {
        NotificationManager.error(
          "Something Went Wrong Server Is Not Responding ",
          "Error Message",
          3000
        );
        console.log(error);
      });
  }

  deleteFile(index, element) {
    var Documents = this.state.Documents;

    var doctype = element.docType;

    if (window.confirm("Are You Sure To Remove Docment ?")) {
      axios
        .post(BaseURL + "loadDocuments/deleteFile", {
          loadId: this.state.loadid,
          documentNo: element.documentNo,
          documentType: doctype,
          token: this.state.token,
        })
        .then((response) => {
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem("Token", "");
            this.props.history.push("/");
          }
          NotificationManager.success(
            "Document Deleted Successfully",
            "Delete",
            3000
          );
          Documents[index]["fileName"] = "";
          Documents[index]["s3FileKey"] = "";
          Documents[index]["checked"] = false;
          Documents[index]["documentId"] = "";
          this.setState({ Documents });
        })
        .catch(function (error) {
          NotificationManager.error(
            "Something Went Wrong Server Is Not Responding ",
            "Error Message",
            3000
          );
          console.log(error);
        });
    }
  }

  download = (index, element) => {
    console.log(index, element);

    var Documents = this.state.Documents;

    var documentNo = element.documentNo;

    Documents[index]["showIcons"] = false;
    this.setState({
      Documents,
    });

    console.log("enter in download file");

    axios
      .post(BaseURL + "loadDocuments/getDocuments", {
        loadId: this.state.loadid,
        token: this.state.token,
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem("Token", "");
          this.props.history.push("/");
        }

        response.data.documents.forEach((element) => {
          if (element.documentNo === documentNo) {
            console.log(element);
            window.open(element.documentUrl, "_blank");
          }
        });

        Documents[index]["showIcons"] = true;
        this.setState({
          Documents,
        });
      })
      .catch(function (error) {
        NotificationManager.error(
          "Something Went Wrong Server Is Not Responding ",
          "Error Message",
          3000
        );
        console.log(error);
      });
  };

  FinishEditInfo() {
    var config = this.state.CheckEditInfo;
    if (config.token) {
      this.setState({ readonly: true });

      finishEditinfo(
        config,
        (res) => {
          console.log(res);

          this.setState({
            CheckEditInfo: {},
            billingInfoReadOnly: true,
          });
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  addInvoiceCharges = (e) => {
    e.preventDefault();

    var {
      additionalCharges,
      totalAmount,
      invoicePopUpInputValues,
    } = this.state;

    var ChargeType = invoicePopUpInputValues.chargesType;
    var Amount = invoicePopUpInputValues.amount
      ? currencyFormatter.unformat(invoicePopUpInputValues.amount, {
        code: "USD",
      })
      : 0;

    totalAmount =
      currencyFormatter.unformat(totalAmount, { code: "USD" }) + Amount;

    // totalAmount = totalAmount.toFixed(2);
    totalAmount = currencyFormatter.format(totalAmount, { code: "USD" });

    var additionalCharges = [...additionalCharges];

    additionalCharges.push({
      chargesType: ChargeType,
      amount: invoicePopUpInputValues.amount || "$0.00",
    });

    console.log("add case", totalAmount, invoicePopUpInputValues.amount);

    this.setState({ additionalCharges, totalAmount });

    this.resetAdditionalChargesPopUp();
  };

  deleteInvoiceCharges = (index) => {
    var { additionalCharges, totalAmount } = this.state;
    var additionalCharges = [...additionalCharges];

    console.log("del case", totalAmount, additionalCharges[index]["amount"]);

    totalAmount =
      currencyFormatter.unformat(totalAmount, { code: "USD" }) -
      currencyFormatter.unformat(additionalCharges[index]["amount"], {
        code: "USD",
      });
    // console.log(totalAmount );

    // totalAmount = totalAmount.toFixed(2)
    totalAmount = currencyFormatter.format(totalAmount, { code: "USD" });

    additionalCharges.splice(index, 1);

    this.setState({ additionalCharges, totalAmount });
  };

  resetAdditionalChargesPopUp = () => {
    // this.refs.chargesType.value='Lumper';
    // this.refs.amount.value='' ;

    var invoicePopUpInputValues = {
      chargesType: "Lumper",
      amount: "",
    };
    this.setState({ invoicePopUpInputValues });
  };

  setInvoicePaymentType = (paymentType) => {
    paymentType === "Regular Pay"
      ? this.setState({ paymentType, quickPay: "" })
      : this.setState({ paymentType });
  };

  handleInvoicePopUpInputChange = (name, value) => {
    var invoicePopUpInputValues = this.state.invoicePopUpInputValues;

    invoicePopUpInputValues[name] = value;

    this.setState({ invoicePopUpInputValues });
  };

  submitInvoiceForm = () => {
    var invoiceError = false;

    this.state.Documents.forEach((element) => {
      if (element.invoiceError) {
        invoiceError = true;
      }
    });

    if (!invoiceError) {
      var customer = {},
        carrier = {},
        Docs = [],
        shipper = null;

      this.setState({ invoiceLoader: true });
      this.refs.ToploadStatus.focus();

      this.state.customerOptionsData.forEach((element) => {
        if (element.id === this.state.CustomerSelectedOption) {
          customer = element;
        }
      });

      this.state.loadData.forEach((element) => {
        if (element.id === this.state.CarrierSelectedOption) {
          carrier = element;
        }
      });

      this.state.Documents.forEach((element) => {
        if (element.checked) {
          Docs.push({
            s3FileKey: element.s3FileKey,
            documentId: element.documentId,
          });
        }
      });

      var stopData = JSON.parse(JSON.stringify(this.state.StopData));

      stopData.forEach((element) => {
        element.shipperOptionsData.forEach((elem) => {
          if (element.shipperId === elem.id) {
            shipper = elem;
            element.shipperInfo = shipper;
          }
        });
      });

      var invoice = {
        token: this.state.token,
        loadId: this.state.loadid,
        totalAmount: this.state.totalAmount,
        paymentType: this.state.paymentType,
        additionalCharges: this.state.additionalCharges,
        customer: customer,
        carrier: carrier,
        internalLoadNo: this.refs.internalload.value,
        customerLoadNo: this.refs.load.value,
        loadPickupAndDeliveryDetails: stopData,
        rate: currencyFormatter.format(this.state.loadFormData.rate, {
          code: "USD",
        }),
        invoiceDocuments: Docs,
        invoiceId: this.state.invoiceId,
        s3FileKey: this.state.invoiceS3FileKey,
        quickPay: this.state.quickPay,
        invoiceTo: this.state.invoiceTo,
        factoringCompany: this.state.carrierdata.factoringCompany
          ? this.state.carrierdata.factoringCompany
          : null,
        invoiceStatus: this.state.invoiceStatus,
      };

      axios
        .post(BaseURL + "invoice/save", invoice)
        .then((response) => {
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem("Token", "");
            this.props.history.push("/");
          }

          console.log(response);

          this.setState({
            invoiceFileName: response.data.invoice.fileName,
            invoiceS3FileKey: response.data.invoice.s3FileKey,
            invoiceURL: response.data.invoice.invoiceURL,
            invoiceId: response.data.invoice.id,
            invoiceLoader: false,
          });

          NotificationManager.success(
            "Invoice Created Successfully",
            "Created",
            3000
          );
        })
        .catch((error) => {
          this.setState({ invoiceLoader: false });
          NotificationManager.error(
            "Something Went Wrong Server Is Not Responding ",
            "Error Message",
            3000
          );
          console.log(error);
        });
    }
  };

  handleInvoiceDocsCheckBox = (index) => {
    var Documents = [...this.state.Documents];

    console.log(Documents[index]["fileExtension"]);

    if (Documents[index]["checked"]) {
      Documents[index]["invoiceError"] = "";
      Documents[index]["checked"] = false;
      this.setState({ Documents });
    } else {
      if (
        Documents[index]["fileExtension"] !== "pdf" &&
        Documents[index]["fileExtension"] !== "PDF"
      ) {
        Documents[index]["invoiceError"] = "Please Select PDF Document Only";
      }
      Documents[index]["checked"] = true;
      this.setState({ Documents });
    }
  };

  deleteInvoice = () => {
    if (!window.confirm("Are you sure you want to delete ?")) {
      return;
    }

    axios
      .post(BaseURL + "invoice/delete", {
        invoiceId: this.state.invoiceId,
        s3FileKey: this.state.invoiceS3FileKey,
        token: this.state.token,
      })
      .then((response) => {
        console.log(response.data);

        this.setState({
          invoiceId: null,
          invoiceS3FileKey: null,
          invoiceURL: null,
          invoiceFileName: "",
        });
        NotificationManager.success(
          "Invoice Deleted Successfully",
          "Delete",
          3000
        );

        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem("Token", "");
          this.props.history.push("/");
        }
      })
      .catch(function (error) {
        NotificationManager.error(
          "Something Went Wrong Server Is Not Responding ",
          "Error Message",
          3000
        );
        console.log(error);
      });
  };

  inputQuickPay = (value) => {
    this.setState({ quickPay: value });
  };

  setInvoiceTo = (value) => {
    value === "Customer"
      ? this.setState({ invoiceTo: value })
      : this.setState({
        invoiceTo: value,
        quickPay: "",
        paymentType: "Quick Pay",
      });
  };

  handleDispatchStopsCheckBox = (index) => {
    var StopData = this.state.StopData;
    StopData[index]["dispatchCheckBox"] = StopData[index]["dispatchCheckBox"]
      ? false
      : true;
    this.setState(StopData);
  };

  resetDispatchStopsCheckBox = () => {
    var StopData = this.state.StopData;
    StopData.forEach((element) => {
      element.dispatchCheckBox = false;
    });
    this.setState({ StopData });
  };

  checkedDispatchStopsCheckBox = (ids) => {
    var idsArray = ids ? ids.split(",") : [];
    var StopData = this.state.StopData;
    StopData.forEach((element) => {
      idsArray.forEach((id) => {
        if (Number(id) === Number(element.stopNo)) {
          element.dispatchCheckBox = true;
        }
      });
    });

    this.setState({ StopData });
  };

  setInvoiceStatus = (value) => {
    this.setState({ invoiceStatus: value });
  };
  dispatchExistanceHandler = (response) => {
    let dispatchedStops = [];
    response.data.dispatchDetail.forEach((dispatch) => {
      let splitedStops = dispatch.shippers.split(",");
      if (splitedStops[0] !== "") {
        dispatchedStops = [...dispatchedStops, ...splitedStops];
      }
      this.setState({ dispatchedStops: dispatchedStops });
    });
  };
  verifyDispatch = (_stopNo) => {
    return this.state.dispatchedStops.length > 0 ? true : false;
  };
  onSortEnd = (oldIndex, type, stopNo) => {
    const doesDispatchExist = this.verifyDispatch();
    if (doesDispatchExist) {
      NotificationManager.warning(
        "You need to uncheck stops from dispatches before changing order",
        "Operation Not Allowed",
        5000
      );
      return false;
    }
    let newIndex = null;
    if (type === "up") newIndex = oldIndex - 1;
    else if (type === "down") newIndex = oldIndex + 1;
    this.shuffleElements(oldIndex, newIndex);
  };
  shuffleElements = (oldIndex, newIndex) => {
    if (newIndex === null) return false;
    let StopData = this.state.StopData;
    if (newIndex >= StopData.length) {
      let k = newIndex - StopData.length + 1;
      while (k--) {
        StopData.push(undefined);
      }
    }
    StopData.splice(newIndex, 0, StopData.splice(oldIndex, 1)[0]);
    StopData.forEach((obj, index) => (obj.stopNo = index + 1));
    this.setState({
      StopData,
    });
  };
  render() {
    var wayPoints = this.state.wayPoints;

    const msgNone = {
      display: "none",
    };
    const msgShow = {
      display: "block",
    };

    const nonclick = {
      cursor: " notAllowed",
      pointerEvents: "none",
    };

    const {
      StopData,
      loadFormData,
      Documents,
      loadid,
      totalAmount,
      additionalCharges,
      invoicePopUpInputValues,
      paymentType,
      invoiceURL,
      invoiceLoader,
      invoiceFileName,
      quickPay,
      invoiceTo,
      role,
      subRole,
      showSelectedRowOnTabs,
      showSelectedRowOnTabsBoolean,
      showRowPickupDate,
      showRowDeliveryDate,
      showRowPickupCityState,
      showRowDeliveryCityState,
    } = this.state;
    //  console.log(new Date(StopData[1]["scheduleFrom"]).getTime())
    return (
      <div className="page-container-bg-solid page-header-fixed page-sidebar-closed-hide-logo page-md">
        <Header title="| Load" />
        <div className="page-container">
          <Sidebar editMode={!this.state.readonly} />
          <div className="page-content-wrapper">
            <div className="page-content">
              <div className="page-head"></div>

              <div className="row">
                <div className="col-md-12">
                  <div className="tabbable-line tabbable-reversed pt-4" style={{ position: 'fixed', top: '8%', background: '#E9ECF3', zIndex: 1, width: '100%', padding: '17px' }}>
                    <ul
                      className="nav nav-tabs col-md-6"
                      hidden={this.state.hidden}
                    >
                      <li className="active border-green-jungle">
                        <a
                          href="#tab_0"
                          data-toggle="tab"
                          onClick={() => {
                            this.FinishEditInfo(this);
                            this.setState({
                              currenOpenTab: "Load",
                            });
                          }}
                        >
                          Load
                          {this.state.currenOpenTab === "Load" ? "" : ""}
                        </a>
                      </li>
                      <li className="border-green-jungle">
                        <a
                          href="#tab_1"
                          data-toggle="tab"
                          onClick={() => {
                            this.FinishEditInfo(this);
                            this.setState({
                              currenOpenTab: "Documents",
                            });
                          }}
                        >
                          Documents
                        </a>
                      </li>
                      <li className="border-green-jungle">
                        <a
                          href="#tab_2"
                          data-toggle="tab"
                          onClick={() => {
                            this.FinishEditInfo(this);
                            this.setState({
                              currenOpenTab: "Dispatch",
                            });
                          }}
                        >
                          Dispatch
                        </a>
                      </li>
                      <li className="border-green-jungle">
                        <a
                          href="#tab_3"
                          data-toggle="tab"
                          onClick={() => {
                            this.FinishEditInfo(this);
                            this.setState({
                              currenOpenTab: "Invoice",
                            });
                          }}
                        >
                          Invoice
                        </a>
                      </li>

                      {role !== "Dispatcher" && role !== "Accounting" ? (
                        <li className="border-green-jungle">
                          <a
                            href="#tab_4"
                            data-toggle="tab"
                            onClick={() => {
                              this.FinishEditInfo(this);
                              this.setState({
                                currenOpenTab: "Billing",
                              });
                            }}
                          >
                            Billing
                          </a>
                        </li>
                      ) : null}
                      {role !== "Dispatcher" ? (
                        <li className="border-green-jungle">
                          <a
                            href="#tab_5"
                            data-toggle="tab"
                            onClick={() => {
                              this.FinishEditInfo(this);
                              this.setState({
                                currenOpenTab: "Accounting",
                              });
                            }}
                          >
                            Accounting
                          </a>
                        </li>
                      ) : null}
                      {role !== "Dispatcher" ? (
                        <li className="border-green-jungle">
                          <a
                            href="#tab_6"
                            data-toggle="tab"
                            onClick={() => {
                              this.FinishEditInfo(this);
                              this.setState({
                                currenOpenTab: "Audit Trail",
                              });
                            }}
                          >
                            Audit Trail
                          </a>
                        </li>
                      ) : null}
                    </ul>

                    <div className="form-group col-md-4">
                      {role !== tenantUser ? (
                        <React.Fragment>
                          <label
                            className="col-md-2 control-label"
                            style={{ paddingTop: "7px" }}
                          >
                            Status:{" "}
                            <span className="font-red-thunderbird"></span>
                          </label>
                          <div className="col-md-6">
                            <select
                              ref="ToploadStatus"
                              value={
                                loadFormData.loadStatus
                                  ? loadFormData.loadStatus
                                  : "Pending"
                              }
                              className="form-control"
                              onChange={() => {
                                var value = this.refs.ToploadStatus.value;
                                var loadData = loadFormData;

                                axios
                                  .post(BaseURL + "customerLoad/updateStatus", {
                                    id: this.state.loadid,
                                    loadStatus: value,
                                    token: this.state.token,
                                  })
                                  .then((response) => {
                                    if (response.data.error === TokenErrorMsg) {
                                      localStorage.setItem("Token", "");
                                      this.props.history.push("/");
                                    }

                                    console.log(response.data);

                                    loadData["loadStatus"] = value;
                                    this.setState({ loadFormData: loadData });
                                  })
                                  .catch(function (error) {
                                    NotificationManager.error(
                                      "Something Went Wrong Server Is Not Responding ",
                                      "Error Message",
                                      3000
                                    );
                                    console.log(error);
                                  });
                              }}
                            >
                              <option value="Pending">Pending</option>
                              <option value="Dispatched">Dispatched</option>
                              <option value="In-Transit">In-Transit</option>
                              <option value="Delivered">Delivered</option>
                              <option value="Completed">Completed</option>
                              <option value="Invoiced">Invoiced</option>
                              <option value="Closed">Closed</option>
                              <option value="Cancel">Cancel</option>
                            </select>
                          </div>
                        </React.Fragment>
                      ) : null}
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <div className="pb-4 mb-4 row"><Scroll.Link spy={true} smooth={true} duration={1000} offset={-220} to="load">Load Details</Scroll.Link>
                      &nbsp;&nbsp;/&nbsp;&nbsp;<Scroll.Link spy={true} smooth={true} duration={1000} offset={-220} to="customer">Customer Details</Scroll.Link>
                      &nbsp;&nbsp;/&nbsp;&nbsp;<Scroll.Link spy={true} smooth={true} duration={1000} offset={-220} to="rate">Rate Details</Scroll.Link>
                      &nbsp;&nbsp;/&nbsp;&nbsp;<Scroll.Link spy={true} smooth={true} duration={1000} offset={-220} to="sales">Sales Details</Scroll.Link>
                      &nbsp;&nbsp;/&nbsp;&nbsp;<Scroll.Link spy={true} smooth={true} duration={1000} offset={-220} to="carrier">Carrier Details</Scroll.Link>
                      &nbsp;&nbsp;/&nbsp;&nbsp;<Scroll.Link spy={true} smooth={true} duration={1000} offset={-220} to="freight">Freight Details</Scroll.Link>
                      &nbsp;&nbsp;/&nbsp;&nbsp;<Scroll.Link spy={true} smooth={true} duration={1000} offset={-220} to="requirements">Requirements</Scroll.Link>
                      &nbsp;&nbsp;/&nbsp;&nbsp;<Scroll.Link spy={true} smooth={true} duration={1000} offset={-220} to="pickup">Pickup and Delivery Details</Scroll.Link>
                      <br />
                      <br />
                    </div>
                  </div>
                  <div>

                    <div className="tab-content">
                      <div className="tab-pane active" id="tab_0">

                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <form
                          onSubmit={this.submituserRegistrationForm.bind(this)}
                          className="horizontal-form"
                          autoComplete="off"
                        >
                          <div className="portlet box mb-3 yellow-gold" >
                            <div className="portlet-title" id="load">
                              <div className="caption">
                                <i className="fa fa-cubes"></i>Load Details
                              </div>
                              <div
                                className="actions"
                                style={
                                  !this.state.isLoadDetailsEditable
                                    ? {}
                                    : nonclick
                                }
                                id="EditDiv"
                              >
                                {role !== tenantUser ? (
                                  <div
                                    className="btn-group"
                                    style={
                                      this.state.hidden === false
                                        ? msgShow
                                        : msgNone
                                    }
                                  >
                                    <button
                                      className="btn sbold white dark"
                                      type="button"
                                      onClick={() =>
                                        this.EditForm("isLoadDetailsEditable")
                                      }
                                      disabled={
                                        this.state.isLoadDetailsEditable
                                      }
                                    >
                                      <i className="fa fa-pencil"></i> Edit
                                    </button>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="portlet-body form">
                              <div className="form-body max_width800">
                                <div className="portlet-title">
                                  <h2 className="h3"> Load Details</h2>

                                  <Map
                                    style={{ display: "none" }}
                                    soureLatLng={this.state.soureLatLng}
                                    destinationLatLng={
                                      this.state.destinationLatLng
                                    }
                                    wayPoints={this.getFormattedWayPoints(
                                      wayPoints
                                    )}
                                    setTotalMiles={this.setTotalMiles}
                                  />

                                  <div className="clearfix"></div>
                                  <hr />
                                </div>

                                <div className="form-group row">
                                  <label className="col-md-3 control-label">
                                    Load #:
                                    <span className="font-red-thunderbird"></span>
                                  </label>
                                  <div className="col-md-9">
                                    <input
                                      type="text"
                                      ref="internalload"
                                      name="internalload"
                                      className="form-control"
                                      disabled
                                      placeholder=""
                                      defaultValue={loadFormData.internalLoadNo}
                                      readOnly={
                                        !this.state.isLoadDetailsEditable
                                      }
                                      onChange={(event) => {
                                        const reg = /^[0-9]{0,12}$/;
                                        const validateText = reg.test(
                                          event.target.value.trim()
                                        );
                                        if (
                                          !this.state.readonly &&
                                          validateText &&
                                          event.target.value.trim().length >
                                          0 &&
                                          event.target.value.trim().length < 13
                                        ) {
                                          var errors = this.state.errors;
                                          errors["internalloadlimit"] = "";
                                          this.setState({ errors });

                                          axios
                                            .post(
                                              BaseURL +
                                              "customerLoad/findByLoadNo",
                                              {
                                                id: this.state.loadid,
                                                internalLoadNo:
                                                  event.target.value,
                                                token: this.state.token,
                                              }
                                            )
                                            .then((response) => {
                                              if (
                                                response.data.error ===
                                                TokenErrorMsg
                                              ) {
                                                localStorage.setItem(
                                                  "Token",
                                                  ""
                                                );
                                                this.props.history.push("/");
                                              }

                                              console.log(response.data);

                                              response.data.error
                                                ? (errors["internalload"] =
                                                  "*Record Already Exist With this Load No")
                                                : (errors["internalload"] = "");

                                              this.setState({ errors });
                                            })
                                            .catch(function (error) {
                                              NotificationManager.error(
                                                "Something Went Wrong Server Is Not Responding ",
                                                "Error Message",
                                                3000
                                              );
                                              console.log(error);
                                            });
                                        } else if (
                                          !this.state.readonly &&
                                          !validateText
                                        ) {
                                          var errors = this.state.errors;
                                          errors["internalloadlimit"] =
                                            "*Only Numerics Allowed (Max Range: 12)";
                                          this.setState({ errors });
                                        } else {
                                          var errors = this.state.errors;
                                          errors["internalloadlimit"] = "";
                                          errors["internalload"] = "";
                                          this.setState({ errors });
                                        }
                                      }}
                                    />
                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.internalload}
                                    </div>
                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.internalloadlimit}
                                    </div>
                                  </div>
                                </div>
                                <div className="portlet-title">
                                  <h2 className="h3"> Load Status: </h2>
                                  <hr />
                                </div>

                                <div className="form-group row">
                                  <label className="col-md-3 control-label">
                                    Status:
                                    <span className="font-red-thunderbird"></span>
                                  </label>
                                  <div className="col-md-9">
                                    <select
                                      ref="loadStatus"
                                      value={
                                        loadFormData.loadStatus
                                          ? loadFormData.loadStatus
                                          : "Pending"
                                      }
                                      className="form-control"
                                      disabled={
                                        !this.state.isLoadDetailsEditable
                                      }
                                      onChange={(e) => {
                                        var loadData = loadFormData;
                                        loadData[
                                          "loadStatus"
                                        ] = this.refs.loadStatus.value;
                                        this.setState({
                                          loadFormData: loadData,
                                        });
                                      }}
                                    >
                                      <option value="Pending">Pending</option>
                                      <option value="Dispatched">
                                        Dispatched
                                      </option>
                                      <option value="In-Transit">
                                        In-Transit
                                      </option>
                                      <option value="Delivered">
                                        Delivered
                                      </option>
                                      <option value="Completed">
                                        Completed
                                      </option>
                                      <option value="Invoiced">Invoiced</option>
                                      <option value="Closed">Closed</option>
                                      <option value="Cancel">Cancel </option>
                                    </select>

                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.loadStatus}
                                    </div>
                                  </div>
                                </div>

                                <div className="clearfix"></div>
                                <div className="text-right">
                                  <button
                                    onClick={this.cancel.bind(this)}
                                    type="button"
                                    className="btn default"
                                  >
                                    Cancel
                                  </button>
                                  &nbsp;
                                  {role !== tenantUser ? (
                                    <button
                                      disabled={
                                        !this.state.isLoadDetailsEditable
                                      }
                                      type="submit"
                                      className="btn green-meadow"
                                    >
                                      {this.state.whileSave === false ? (
                                        <i className="fa fa-save"></i>
                                      ) : (
                                        <i className="fa fa-circle-o-notch fa-spin"></i>
                                      )}
                                      Save
                                    </button>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="portlet box mb-3 yellow-gold" >
                            <div className="portlet-title" id="customer">
                              <div className="caption">
                                <i className="fa fa-cubes"></i>Customer Details
                              </div>
                              <div
                                className="actions"
                                style={
                                  !this.state.isCustomerDetailsEditable
                                    ? {}
                                    : nonclick
                                }
                                id="EditDiv"
                              >
                                {role !== tenantUser ? (
                                  <div
                                    className="btn-group"
                                    style={
                                      this.state.hidden === false
                                        ? msgShow
                                        : msgNone
                                    }
                                  >
                                    <button
                                      className="btn sbold white dark"
                                      type="button"
                                      onClick={() =>
                                        this.EditForm(
                                          "isCustomerDetailsEditable"
                                        )
                                      }
                                      disabled={
                                        this.state.isCustomerDetailsEditable
                                      }
                                    >
                                      <i className="fa fa-pencil"></i> Edit
                                    </button>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="portlet-body form">
                              <div className="form-body max_width800">
                                <div className="portlet-title">
                                  <h2 className="h3"> Customer Details: </h2>
                                  <hr />
                                </div>

                                <div className="form-group row">
                                  <label className="col-md-3 control-label">
                                    Customer:
                                    <span className="font-red-thunderbird">
                                      *
                                    </span>
                                  </label>
                                  <div className="col-md-9">
                                    <div className="form-group row">
                                      <div className="col-md-9">
                                        <Select
                                          optionHeight={50}
                                          required
                                          disabled={
                                            !this.state
                                              .isCustomerDetailsEditable
                                          }
                                          value={
                                            this.state.CustomerSelectedOption
                                          }
                                          options={this.state.CustomerOptions}
                                          onChange={this.handleChangeCustomer}
                                          onInputChange={(input) => {
                                            if (input.trim().length > 1) {
                                              axios
                                                .post(
                                                  BaseURL +
                                                  "customer/findByName",
                                                  {
                                                    status: 2,
                                                    searchKeyword: input,
                                                    token: this.state.token,
                                                  }
                                                )
                                                .then((response) => {
                                                  if (
                                                    response.data.error ===
                                                    TokenErrorMsg
                                                  ) {
                                                    localStorage.setItem(
                                                      "Token",
                                                      ""
                                                    );
                                                    this.props.history.push(
                                                      "/"
                                                    );
                                                  }

                                                  var arr = [];

                                                  response.data.data.rows.forEach(
                                                    (element) => {
                                                      var obj = {
                                                        value: element.id,
                                                        label:
                                                          element.customerName +
                                                          (element.address
                                                            .address
                                                            ? ", " +
                                                            element.address
                                                              .address
                                                            : "") +
                                                          (element.address.city
                                                            ? ", " +
                                                            element.address
                                                              .city
                                                            : "") +
                                                          (element.address.state
                                                            ? ", " +
                                                            element.address
                                                              .state.state
                                                            : "") +
                                                          (element.address.zip
                                                            ? ", " +
                                                            element.address
                                                              .zip
                                                            : ""),
                                                      };

                                                      arr.push(obj);
                                                    }
                                                  );

                                                  this.setState({
                                                    CustomerOptions: arr,
                                                    customerOptionsData:
                                                      response.data.data.rows,
                                                  });
                                                })
                                                .catch(function (error) {
                                                  NotificationManager.error(
                                                    "Something Went Wrong Server Is Not Responding ",
                                                    "Error Message",
                                                    3000
                                                  );
                                                  console.log(error);
                                                });
                                            }
                                          }}
                                          placeholder="Search and Choose "
                                        />
                                        <div
                                          style={{ color: "red" }}
                                          className="errorMsg"
                                        >
                                          {this.state.errors.customer}
                                        </div>
                                      </div>
                                      <div
                                        className="col-md-3"
                                        style={
                                          !this.state.isCustomerDetailsEditable
                                            ? nonclick
                                            : {}
                                        }
                                      >
                                        <a
                                          data-toggle="modal"
                                          href="#customer_info"
                                          className="btn btn-block green-meadow"
                                        >
                                          <i className="fa fa-plus"></i> Add
                                        </a>
                                      </div>
                                    </div>

                                    <div
                                      className="row"
                                      style={
                                        this.state.customerCard.state
                                          ? msgShow
                                          : msgNone
                                      }
                                    >
                                      <div className="col-md-12">
                                        <div className="card_info">
                                          <div className="row">
                                            <div className="col-md-12">
                                              <p>
                                                <i
                                                  className="fa fa-map-marker"
                                                  aria-hidden="true"
                                                ></i>
                                                &nbsp;
                                                {
                                                  this.state.customerCard
                                                    .address
                                                }
                                              </p>
                                            </div>
                                          </div>
                                          <div className="row">
                                            <div className="col-md-6">
                                              <p>
                                                <i
                                                  className="fa fa-building"
                                                  aria-hidden="true"
                                                ></i>
                                                &nbsp;
                                                {this.state.customerCard.city}
                                              </p>
                                            </div>
                                            <div className="col-md-6">
                                              <p>
                                                <i
                                                  className="fa fa-globe"
                                                  aria-hidden="true"
                                                ></i>
                                                &nbsp;
                                                {this.state.customerCard.state}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="col-md-3 control-label">
                                    Contact:
                                    <span className="font-red-thunderbird"></span>
                                  </label>
                                  <div className="col-md-9">
                                    <div className="form-group row">
                                      <div className="col-md-9">
                                        <Select
                                          disabled={
                                            !this.state
                                              .isCustomerDetailsEditable
                                          }
                                          value={
                                            this.state.ContactSelectedOption
                                          }
                                          options={this.state.ContactOptions}
                                          onChange={this.handleChangeContact}
                                          placeholder="Search and Choose "
                                        />
                                        <div
                                          style={{ color: "red" }}
                                          className="errorMsg"
                                        >
                                          {this.state.errors.contact}
                                        </div>
                                      </div>
                                      <div
                                        className="col-md-3"
                                        style={
                                          !this.state.isCustomerDetailsEditable
                                            ? nonclick
                                            : {}
                                        }
                                      >
                                        {this.state.CustomerSelectedOption ? (
                                          <a
                                            data-toggle="modal"
                                            href="#contact"
                                            className="btn btn-block green-meadow"
                                          >
                                            <i className="fa fa-plus"></i> Add
                                          </a>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>

                                    <div
                                      className="row"
                                      style={
                                        this.state.contactCard.name
                                          ? msgShow
                                          : msgNone
                                      }
                                    >
                                      <div className="col-md-12">
                                        <div className="card_info">
                                          <div className="row">
                                            <div className="col-md-6">
                                              <p>
                                                <i
                                                  className="fa fa-user"
                                                  aria-hidden="true"
                                                ></i>
                                                &nbsp;
                                                {this.state.contactCard.name}
                                              </p>
                                            </div>
                                            <div className="col-md-6">
                                              <p>
                                                <i
                                                  className="fa fa-envelope-o"
                                                  aria-hidden="true"
                                                ></i>
                                                &nbsp;
                                                {this.state.contactCard.email}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="row">
                                            <div className="col-md-6">
                                              <p>
                                                <i
                                                  className="fa fa-phone"
                                                  aria-hidden="true"
                                                ></i>
                                                &nbsp;
                                                {
                                                  this.state.contactCard
                                                    .telephone
                                                }
                                              </p>
                                            </div>
                                            <div className="col-md-6">
                                              <p>
                                                <i
                                                  className="fa fa-fax"
                                                  aria-hidden="true"
                                                ></i>
                                                &nbsp;
                                                {this.state.contactCard.fax}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="col-md-3 control-label">
                                    Customers Load #:
                                    <span className="font-red-thunderbird">
                                      *
                                    </span>
                                  </label>
                                  <div className="col-md-9">
                                    <input
                                      type="text"
                                      ref="load"
                                      name="load"
                                      required
                                      className="form-control"
                                      placeholder=""
                                      defaultValue={loadFormData.customerLoadNo}
                                      readOnly={
                                        !this.state.isCustomerDetailsEditable ||
                                        (this.state.CustomerSelectedOption
                                          ? false
                                          : true)
                                      }
                                      onChange={(event) => {
                                        if (
                                          !this.state.readonly &&
                                          event.target.value.trim().length > 0
                                        ) {
                                          axios
                                            .post(
                                              BaseURL +
                                              "customerLoad/findLoadByCustomerAndCustomerLoad",
                                              {
                                                id: this.state.loadid,
                                                customerLoadNo:
                                                  event.target.value,
                                                customerId: this.state
                                                  .CustomerSelectedOption,
                                                token: this.state.token,
                                              }
                                            )
                                            .then((response) => {
                                              if (
                                                response.data.error ===
                                                TokenErrorMsg
                                              ) {
                                                localStorage.setItem(
                                                  "Token",
                                                  ""
                                                );
                                                this.props.history.push("/");
                                              }

                                              console.log(response.data);

                                              var errors = this.state.errors;
                                              response.data.error
                                                ? (errors["load"] =
                                                  "*Record Already Exist With this Load No")
                                                : (errors["load"] = "");

                                              this.setState({ errors });
                                            })
                                            .catch(function (error) {
                                              NotificationManager.error(
                                                "Something Went Wrong Server Is Not Responding ",
                                                "Error Message",
                                                3000
                                              );
                                              console.log(error);
                                            });
                                        }
                                      }}
                                    />
                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.load}
                                    </div>
                                  </div>
                                </div>
                                <div className="clearfix"></div>

                                <div className="text-right">
                                  <button
                                    onClick={this.cancel.bind(this)}
                                    type="button"
                                    className="btn default"
                                  >
                                    Cancel
                                  </button>
                                  &nbsp;
                                  {role !== tenantUser ? (
                                    <button
                                      disabled={
                                        !this.state.isCustomerDetailsEditable
                                      }
                                      type="submit"
                                      className="btn green-meadow"
                                    >
                                      {this.state.whileSave === false ? (
                                        <i className="fa fa-save"></i>
                                      ) : (
                                        <i className="fa fa-circle-o-notch fa-spin"></i>
                                      )}
                                      Save
                                    </button>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="portlet box mb-3 yellow-gold" >
                            <div className="portlet-title" id="rate">
                              <div className="caption">
                                <i className="fa fa-cubes"></i>Rate Details
                              </div>
                              <div
                                className="actions"
                                style={
                                  !this.state.isRateDetailsEditable
                                    ? {}
                                    : nonclick
                                }
                                id="EditDiv"
                              >
                                {role !== tenantUser ? (
                                  <div
                                    className="btn-group"
                                    style={
                                      this.state.hidden === false
                                        ? msgShow
                                        : msgNone
                                    }
                                  >
                                    <button
                                      type="button"
                                      className="btn sbold white dark"
                                      onClick={() =>
                                        this.EditForm("isRateDetailsEditable")
                                      }
                                      hidden={this.state.hidden}
                                      disabled={this.state.hidden}
                                    >
                                      <i className="fa fa-pencil"></i> Edit
                                    </button>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="portlet-body form">
                              <div className="form-body max_width800">
                                <div className="portlet-title">
                                  <h2 className="h3"> Rate Details: </h2>
                                  <hr />
                                </div>

                                <div className="form-group row">
                                  <label className="col-md-3 control-label">
                                    Rate:
                                    <span className="font-red-thunderbird"></span>
                                  </label>
                                  <div className="col-md-9">
                                    {/* <input type="text" ref="rate" onChange={this.handleChange} name="rate" className="form-control" placeholder=""  defaultValue={loadFormData.rate} readOnly={this.state.readonly}/> */}
                                    <CurrencyInput
                                      precision="2"
                                      prefix="$"
                                      ref="rate"
                                      value={loadFormData.rate}
                                      onChangeEvent={(
                                        event,
                                        maskedvalue,
                                        floatvalue
                                      ) => {
                                        loadFormData["rate"] = floatvalue;

                                        var totalAmount = floatvalue;

                                        additionalCharges.forEach((element) => {
                                          totalAmount =
                                            totalAmount +
                                            currencyFormatter.unformat(
                                              element.amount,
                                              { code: "USD" }
                                            );
                                        });

                                        totalAmount = currencyFormatter.format(
                                          totalAmount,
                                          { code: "USD" }
                                        );

                                        this.setState({
                                          loadFormData,
                                          totalAmount,
                                        });
                                      }}
                                      className="form-control"
                                      readOnly={
                                        !this.state.isRateDetailsEditable
                                      }
                                      placeholder=""
                                    />

                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.rate}
                                    </div>
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="col-md-3 control-label">
                                    Type:
                                    <span className="font-red-thunderbird"></span>
                                  </label>
                                  <div className="col-md-9">
                                    <Select
                                      disabled={
                                        !this.state.isRateDetailsEditable
                                      }
                                      value={this.state.selectRateTypeValue}
                                      options={optionsDropdown}
                                      onChange={(selectValue) =>
                                        this.setState({
                                          selectRateTypeValue: selectValue,
                                        })
                                      }
                                    />
                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.rate}
                                    </div>
                                  </div>
                                </div>

                                <div className="clearfix"></div>

                                <div className="text-right">
                                  <button
                                    onClick={this.cancel.bind(this)}
                                    type="button"
                                    className="btn default"
                                  >
                                    Cancel
                                  </button>
                                  &nbsp;
                                  {role !== tenantUser ? (
                                    <button
                                      disabled={
                                        !this.state.isRateDetailsEditable
                                      }
                                      type="submit"
                                      className="btn green-meadow"
                                    >
                                      {this.state.whileSave === false ? (
                                        <i className="fa fa-save"></i>
                                      ) : (
                                        <i className="fa fa-circle-o-notch fa-spin"></i>
                                      )}
                                      Save
                                    </button>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* 123123 */}
                          {role === "Admin" ||
                            role === dispatcherUser ||
                            role === accountingUser ? (
                            <div className="portlet box mb-3 yellow-gold">
                              <div className="portlet-title" id="sales">
                                <div className="caption">
                                  <i className="fa fa-cubes"></i>Sales Details
                                </div>
                                <div
                                  className="actions"
                                  style={
                                    !this.state.isSalesDetailsEditable
                                      ? {}
                                      : nonclick
                                  }
                                  id="EditDiv"
                                >
                                  {role !== tenantUser ? (
                                    <div
                                      className="btn-group"
                                      style={
                                        this.state.hidden === false
                                          ? msgShow
                                          : msgNone
                                      }
                                    >
                                      <button
                                        type="button"
                                        className="btn sbold white dark"
                                        onClick={() =>
                                          this.EditForm(
                                            "isSalesDetailsEditable"
                                          )
                                        }
                                        hidden={this.state.hidden}
                                        disabled={this.state.hidden}
                                      >
                                        <i className="fa fa-pencil"></i> Edit
                                      </button>
                                    </div>
                                  ) : null}
                                </div>
                              </div>

                              <div className="portlet-body form">
                                <div className="form-body max_width800">
                                  <div className="portlet-title">
                                    <h2 className="h3"> Sales Details: </h2>
                                    <hr />
                                  </div>

                                  <div className="form-group row">
                                    <label className="col-md-3 control-label">
                                      Sales Person 1:
                                      <span className="font-red-thunderbird"></span>
                                    </label>
                                    <div className="col-md-9">
                                      <Select
                                        disabled={
                                          !this.state.isSalesDetailsEditable
                                        }
                                        value={this.state.salesPerson1}
                                        options={optionsUsersList}
                                        onChange={(selectValue) =>
                                          this.setState({
                                            salesPerson1: selectValue,
                                          })
                                        }
                                      />
                                      <div
                                        style={{ color: "red" }}
                                        className="errorMsg"
                                      >
                                        {this.state.errors.rate}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label className="col-md-3 control-label">
                                      Sales Person 2:
                                      <span className="font-red-thunderbird"></span>
                                    </label>
                                    <div className="col-md-9">
                                      <Select
                                        disabled={
                                          !this.state.isSalesDetailsEditable
                                        }
                                        value={this.state.salesPerson2}
                                        options={optionsUsersList}
                                        onChange={(selectValue) =>
                                          this.setState({
                                            salesPerson2: selectValue,
                                          })
                                        }
                                      />
                                      <div
                                        style={{ color: "red" }}
                                        className="errorMsg"
                                      >
                                        {this.state.errors.rate}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="clearfix"></div>

                                  <div className="text-right">
                                    <button
                                      onClick={this.cancel.bind(this)}
                                      type="button"
                                      className="btn default"
                                    >
                                      Cancel
                                    </button>
                                    &nbsp;
                                    {role !== tenantUser ? (
                                      <button
                                        disabled={
                                          !this.state.isSalesDetailsEditable
                                        }
                                        type="submit"
                                        className="btn green-meadow"
                                      >
                                        {this.state.whileSave === false ? (
                                          <i className="fa fa-save"></i>
                                        ) : (
                                          <i className="fa fa-circle-o-notch fa-spin"></i>
                                        )}
                                        Save
                                      </button>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {/* 123123 */}
                          <div className="portlet box mb-3 yellow-gold">
                            <div className="portlet-title" id="carrier">
                              <div className="caption">
                                <i className="fa fa-cubes"></i>Carrier Details
                              </div>
                              <div
                                className="actions"
                                style={
                                  !this.state.isCarrierDetailsEditable
                                    ? {}
                                    : nonclick
                                }
                                id="EditDiv"
                              >
                                {role !== tenantUser ? (
                                  <div
                                    className="btn-group"
                                    style={
                                      this.state.hidden === false
                                        ? msgShow
                                        : msgNone
                                    }
                                  >
                                    <button
                                      type="button"
                                      className="btn sbold white dark"
                                      onClick={() =>
                                        this.EditForm(
                                          "isCarrierDetailsEditable"
                                        )
                                      }
                                      hidden={this.state.hidden}
                                      disabled={this.state.hidden}
                                    >
                                      <i className="fa fa-pencil"></i> Edit
                                    </button>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="portlet-body form">
                              <div className="form-body max_width800">
                                <div className="portlet-title">
                                  <h2 className="h3"> Carrier Details: </h2>
                                  <hr />
                                </div>

                                <div className="form-group row">
                                  <label className="col-md-3 control-label">
                                    Carrier:
                                    <span className="font-red-thunderbird"></span>
                                  </label>
                                  <div className="col-md-9">
                                    <div className="form-group row">
                                      <div className="col-md-9">
                                        <Select
                                          optionHeight={50}
                                          disabled={
                                            !this.state.isCarrierDetailsEditable
                                          }
                                          value={
                                            this.state.CarrierSelectedOption
                                          }
                                          options={this.state.CarrierOptions}
                                          onChange={this.handleChangeCarrier}
                                          onInputChange={(input) => {
                                            if (input.trim().length > 1) {
                                              axios
                                                .post(
                                                  BaseURL +
                                                  "carrierInformation/findByName",
                                                  {
                                                    status: 2,
                                                    searchKeyword: input,
                                                    token: this.state.token,
                                                  }
                                                )
                                                .then((response) => {
                                                  if (
                                                    response.data.error ===
                                                    TokenErrorMsg
                                                  ) {
                                                    localStorage.setItem(
                                                      "Token",
                                                      ""
                                                    );
                                                    this.props.history.push(
                                                      "/"
                                                    );
                                                  }

                                                  var arr = [];

                                                  if (
                                                    response.data.data.count ===
                                                    0
                                                  ) {
                                                    let errors = {};
                                                    errors[
                                                      "inactiveValidation"
                                                    ] = "";
                                                    this.setState({
                                                      errors: errors,
                                                    });
                                                  }

                                                  response.data.data.rows.forEach(
                                                    (element) => {
                                                      if (
                                                        element.status !== 0
                                                      ) {
                                                        let errors = {};
                                                        errors[
                                                          "inactiveValidation"
                                                        ] = "";
                                                        var obj = {
                                                          value: element.id,
                                                          label:
                                                            element.carrierName +
                                                            (element.address
                                                              .address
                                                              ? ", " +
                                                              element.address
                                                                .address
                                                              : "") +
                                                            (element.address
                                                              .city
                                                              ? ", " +
                                                              element.address
                                                                .city
                                                              : "") +
                                                            (element.address
                                                              .state
                                                              ? ", " +
                                                              element.address
                                                                .state.state
                                                              : "") +
                                                            (element.address.zip
                                                              ? ", " +
                                                              element.address
                                                                .zip
                                                              : ""),
                                                        };
                                                        arr.push(obj);
                                                        this.setState({
                                                          errors: errors,
                                                        });
                                                      } else if (
                                                        element.status === 0
                                                      ) {
                                                        let errors = {};
                                                        console.log(
                                                          element,
                                                          "element 3"
                                                        );
                                                        errors[
                                                          "inactiveValidation"
                                                        ] =
                                                          "*Carrier exist with inactive status";
                                                        this.setState({
                                                          errors: errors,
                                                        });
                                                      }
                                                    }
                                                  );
                                                  this.setState({
                                                    CarrierOptions: arr,
                                                    loadData:
                                                      response.data.data.rows,
                                                  });
                                                })
                                                .catch(function (error) {
                                                  NotificationManager.error(
                                                    "Something Went Wrong Server Is Not Responding ",
                                                    "Error Message",
                                                    3000
                                                  );
                                                  console.log(error);
                                                });
                                            } else {
                                              let errors = {};
                                              errors["inactiveValidation"] = "";
                                              this.setState({
                                                errors: errors,
                                              });
                                            }
                                          }}
                                          placeholder="Search and Choose "
                                        />
                                        <div
                                          style={{
                                            color: "red",
                                            marginTop: 35,
                                          }}
                                          className="errorMsg"
                                        >
                                          {this.state.errors.inactiveValidation}
                                        </div>
                                        <div
                                          style={{ color: "red" }}
                                          className="errorMsg"
                                        >
                                          {this.state.errors.carrier}
                                        </div>
                                      </div>
                                      <div
                                        className="col-md-3"
                                        style={
                                          !this.state.isCarrierDetailsEditable
                                            ? nonclick
                                            : {}
                                        }
                                      >
                                        <a
                                          data-toggle="modal"
                                          href="#carrier_info"
                                          className="btn btn-block green-meadow"
                                        >
                                          <i className="fa fa-plus"></i> Add
                                        </a>
                                      </div>
                                    </div>
                                    <div
                                      className="row"
                                      style={
                                        this.state.carrierdata.address
                                          ? msgShow
                                          : msgNone
                                      }
                                    >
                                      <div className="col-md-12">
                                        <div className="card_info">
                                          <div className="row">
                                            <div className="col-md-12">
                                              <p>
                                                <i
                                                  className="fa fa-map-marker"
                                                  aria-hidden="true"
                                                ></i>
                                                &nbsp;
                                                {this.state.carrierdata.address}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="row">
                                            <div className="col-md-6">
                                              <p>
                                                <i
                                                  className="fa fa-building"
                                                  aria-hidden="true"
                                                ></i>
                                                &nbsp;
                                                {this.state.carrierdata.city}
                                              </p>
                                            </div>
                                            <div className="col-md-6">
                                              <p>
                                                <i
                                                  className="fa fa-globe"
                                                  aria-hidden="true"
                                                ></i>
                                                &nbsp;
                                                {this.state.carrierdata.state}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="clearfix"></div>

                                <div className="text-right">
                                  <button
                                    onClick={this.cancel.bind(this)}
                                    type="button"
                                    className="btn default"
                                  >
                                    Cancel
                                  </button>
                                  &nbsp;
                                  {role !== tenantUser ? (
                                    <button
                                      disabled={
                                        !this.state.isCarrierDetailsEditable
                                      }
                                      type="submit"
                                      className="btn green-meadow"
                                    >
                                      {this.state.whileSave === false ? (
                                        <i className="fa fa-save"></i>
                                      ) : (
                                        <i className="fa fa-circle-o-notch fa-spin"></i>
                                      )}
                                      Save
                                    </button>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="portlet box mb-3 yellow-gold">
                            <div className="portlet-title" id="freight">
                              <div className="caption">
                                <i className="fa fa-cubes"></i>Freight Details
                              </div>
                              <div
                                className="actions"
                                style={
                                  !this.state.isFreightDetailsEditable
                                    ? {}
                                    : nonclick
                                }
                                id="EditDiv"
                              >
                                {role !== tenantUser ? (
                                  <div
                                    className="btn-group"
                                    style={
                                      this.state.hidden === false
                                        ? msgShow
                                        : msgNone
                                    }
                                  >
                                    <button
                                      type="button"
                                      className="btn sbold white dark"
                                      onClick={() =>
                                        this.EditForm(
                                          "isFreightDetailsEditable"
                                        )
                                      }
                                      hidden={this.state.hidden}
                                      disabled={this.state.hidden}
                                    >
                                      <i className="fa fa-pencil"></i> Edit
                                    </button>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="portlet-body form">
                              <div className="form-body max_width800">
                                <div className="portlet-title">
                                  <h2 className="h3"> Freight Details: </h2>
                                  <hr />
                                </div>

                                <div className="form-group row">
                                  <label className="col-md-3 control-label">
                                    Freight Type:
                                  </label>
                                  <div
                                    className="col-md-9"
                                    style={
                                      !this.state.isFreightDetailsEditable
                                        ? nonclick
                                        : {}
                                    }
                                  >
                                    <span
                                      style={{
                                        marginTop: "2px",
                                        marginTop: "6px",
                                        position: "relative",
                                        zIndex: 0,
                                      }}
                                    >
                                      <select
                                        value={this.state.freightType}
                                        className="form-control"
                                        disabled={this.state.readonly}
                                        onChange={(e) => {
                                          if (e.target.value === 'dry' || e.target.value === 'reefer') {
                                            this.setState({ tempValue: e.target.value })
                                            this.setState({ show: true })
                                          } else this.setState({ freightType: e.target.value });
                                        }}
                                      >
                                        <option value="">Nothing</option>
                                        <option value="dry">
                                          Dry
                                        </option>
                                        <option value="reefer">
                                          Reefer
                                        </option>
                                      </select>
                                      <Modal show={this.state.show} onHide={this.handleClose}>
                                        <Modal.Header closeButton>
                                          <Modal.Title>Warning</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>Please check RATE SHEET and confirm freight type: DRY and type DRY in the box below</Modal.Body>
                                        <Modal.Body> <input onCopy={(e) => {
                                          e.preventDefault();
                                          return false
                                        }}
                                          onPaste={(e) => {
                                            e.preventDefault();
                                            return false
                                          }}
                                          placeholder={this.state.tempValue === "dry" ? "DRY" : "REEFER"}
                                          onChange={(e) => {
                                            this.setState({ errors: {} })
                                            this.setState({ freightTypeConfirmation: e.target.value })
                                          }}
                                        ></input>
                                          <div
                                            style={{ color: "red" }}
                                            className="errorMsg"
                                          >
                                            {this.state.errors.freightTypeConfirmation}
                                          </div>

                                        </Modal.Body>
                                        <Modal.Body>
                                          <div className="form-group">
                                            <label>
                                              Commodity
                                            </label>
                                            <div>
                                              <textarea
                                                rows="3"
                                                className="form-control"
                                                onChange={(e) => {
                                                  console.log(e.target.value, "Input")
                                                  this.setState({ commodity: e.target.value })
                                                }}
                                                value={this.state.commodity}
                                              />
                                            </div>
                                          </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                          <Button variant="secondary" onClick={this.handleClose}>
                                            Close
                                          </Button>
                                          <Button variant="primary" onClick={this.confirmation}>
                                            Confirm
                                          </Button>
                                        </Modal.Footer>
                                      </Modal>
                                    </span>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">Type</label>
                                  <div className="col-md-9">

                                    <span
                                      style={{
                                        marginTop: "2px",
                                        marginTop: "6px",
                                        position: "relative",
                                        zIndex: 0,
                                      }}
                                    >
                                      <input type="checkbox" name="typeCommodity" id="typeCommodity"
                                        onClick={() => {
                                          console.log(this.state.typeCommodity)
                                          this.setState({ typeCommodity: !this.state.typeCommodity })
                                        }}
                                        checked={this.state.typeCommodity ? true : false}
                                        disabled={!this.state.isFreightDetailsEditable}
                                      />
                                      <label>Commodity</label>
                                    </span>
                                  </div>
                                </div>
                                {this.state.freightType === "reefer" ? (
                                  <React.Fragment>
                                    <div className="portlet-title">
                                      <h2 className="h3">
                                        {" "}
                                        Temperature Info:{" "}
                                      </h2>
                                      <hr />
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-3 control-label">
                                        Temperature:
                                        <span className="font-red-thunderbird"></span>
                                      </label>
                                      <div className="col-md-9">
                                        <div className="form-group row">
                                          <div className="col-md-9">
                                            <input
                                              type="text"
                                              ref="temperature"
                                              onChange={this.handleChange}
                                              name="temperature"
                                              className="form-control"
                                              placeholder=""
                                              defaultValue={
                                                loadFormData.temperature
                                              }
                                              readOnly={
                                                !this.state
                                                  .isFreightDetailsEditable
                                              }
                                            />
                                            <div
                                              style={{ color: "red" }}
                                              className="errorMsg"
                                            >
                                              {this.state.errors.temperature}
                                            </div>
                                          </div>
                                          <div
                                            className="col-md-3"
                                            style={
                                              !this.state
                                                .isFreightDetailsEditable
                                                ? nonclick
                                                : {}
                                            }
                                          >
                                            <span
                                              style={{
                                                marginTop: "2px",
                                                marginTop: "6px",
                                              }}
                                            >
                                              <div
                                                className="btn-group btn-group-devided"
                                                data-toggle="buttons"
                                              >
                                                <label
                                                  className={
                                                    loadFormData.temperatureType ===
                                                      "F"
                                                      ? "btn btn-transparent dark btn-outline btn-circle btn-sm active"
                                                      : "btn btn-transparent dark btn-outline btn-circle btn-sm "
                                                  }
                                                  id="F"
                                                  disabled={
                                                    !this.state
                                                      .isFreightDetailsEditable
                                                  }
                                                  onClick={() => {
                                                    if (this._isMounted) {
                                                      this.setState({
                                                        temperatureType: "F",
                                                      });
                                                    }
                                                  }}
                                                >
                                                  <input
                                                    type="radio"
                                                    name="options"
                                                    className="toggle"
                                                    id="option1"
                                                  />
                                                  &#176;F
                                                </label>
                                                <label
                                                  className={
                                                    loadFormData.temperatureType ===
                                                      "C"
                                                      ? "btn btn-transparent dark btn-outline btn-circle btn-sm active"
                                                      : "btn btn-transparent dark btn-outline btn-circle btn-sm "
                                                  }
                                                  id="C"
                                                  disabled={
                                                    !this.state
                                                      .isFreightDetailsEditable
                                                  }
                                                  onClick={() => {
                                                    if (this._isMounted) {
                                                      this.setState({
                                                        temperatureType: "C",
                                                      });
                                                    }
                                                  }}
                                                >
                                                  <input
                                                    type="radio"
                                                    name="options"
                                                    className="toggle"
                                                    id="option2"
                                                  />
                                                  &#176;C
                                                </label>
                                              </div>
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="form-group row">
                                      <label className="col-md-3 control-label">
                                        Cycle:
                                      </label>
                                      <div
                                        className="col-md-9"
                                        style={
                                          !this.state.isFreightDetailsEditable
                                            ? nonclick
                                            : {}
                                        }
                                      >
                                        <span
                                          style={{
                                            marginTop: "2px",
                                            marginTop: "6px",
                                            position: "relative",
                                            zIndex: 0,
                                          }}
                                        >
                                          <div
                                            className="btn-group btn-group-devided"
                                            data-toggle="buttons"
                                          >
                                            <label
                                              className={
                                                loadFormData.cycle === "cont"
                                                  ? "btn btn-transparent dark btn-outline btn-circle btn-sm active"
                                                  : "btn btn-transparent dark btn-outline btn-circle btn-sm "
                                              }
                                              id="cont"
                                              disabled={this.state.readonly}
                                              onClick={() => {
                                                if (this._isMounted) {
                                                  this.setState({
                                                    cycle: "cont",
                                                  });
                                                }
                                              }}
                                            >
                                              <input
                                                type="radio"
                                                name="options"
                                                className="toggle"
                                                id="option1"
                                              />
                                              CONTN
                                            </label>
                                            <label
                                              className={
                                                loadFormData.cycle === "cycle"
                                                  ? "btn btn-transparent dark btn-outline btn-circle btn-sm active"
                                                  : "btn btn-transparent dark btn-outline btn-circle btn-sm "
                                              }
                                              id="cycle"
                                              disabled={
                                                !this.state
                                                  .isFreightDetailsEditable
                                              }
                                              onClick={() => {
                                                if (this._isMounted) {
                                                  this.setState({
                                                    cycle: "cycle",
                                                  });
                                                }
                                              }}
                                            >
                                              <input
                                                type="radio"
                                                name="options"
                                                className="toggle"
                                                id="option2"
                                              />
                                              CYCLE&nbsp;
                                            </label>
                                          </div>
                                        </span>
                                      </div>
                                    </div>
                                  </React.Fragment>
                                ) : null}
                                <div className="clearfix"></div>

                                <div className="text-right">
                                  <button
                                    onClick={this.cancel.bind(this)}
                                    type="button"
                                    className="btn default"
                                  >
                                    Cancel
                                  </button>
                                  &nbsp;
                                  {role !== tenantUser ? (
                                    <button
                                      disabled={
                                        !this.state.isFreightDetailsEditable
                                      }
                                      type="submit"
                                      className="btn green-meadow"
                                    >
                                      {this.state.whileSave === false ? (
                                        <i className="fa fa-save"></i>
                                      ) : (
                                        <i className="fa fa-circle-o-notch fa-spin"></i>
                                      )}
                                      Save
                                    </button>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="portlet box mb-3 yellow-gold">
                            <div className="portlet-title" id="requirements">
                              <div className="caption">
                                <i className="fa fa-cubes"></i>Requirements
                              </div>
                              <div
                                className="actions"
                                style={
                                  !this.state.isRequirementsDetailsEditable
                                    ? {}
                                    : nonclick
                                }
                                id="EditDiv"
                              >
                                {role !== tenantUser ? (
                                  <div
                                    className="btn-group"
                                    style={
                                      this.state.hidden === false
                                        ? msgShow
                                        : msgNone
                                    }
                                  >
                                    <button
                                      type="button"
                                      className="btn sbold white dark"
                                      onClick={() =>
                                        this.EditForm(
                                          "isRequirementsDetailsEditable"
                                        )
                                      }
                                      hidden={this.state.hidden}
                                      disabled={this.state.hidden}
                                    >
                                      <i className="fa fa-pencil"></i> Edit
                                    </button>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="portlet-body form">
                              <div className="form-body max_width800">
                                <div className="portlet-title">
                                  <h2 className="h3"> Requirements: </h2>
                                  <hr />
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">
                                    Trailer Type:
                                  </label>
                                  <div className="col-md-9">
                                    <div className="checkbox-list">
                                      <label className="checkbox-inline">
                                        <div className="checker">
                                          <span className="" id="Shipping">
                                            <input
                                              type="checkbox"
                                              ref="Shipping"
                                              name="Shipping"
                                              onClick={this.handleCheckBox}
                                              value="option1"
                                              disabled={
                                                !this.state
                                                  .isRequirementsDetailsEditable
                                              }
                                            />
                                          </span>
                                        </div>
                                        Reefer
                                      </label>
                                      <label className="checkbox-inline">
                                        <div className="checker">
                                          <span className="" id="Shipping1">
                                            <input
                                              type="checkbox"
                                              ref="Shipping1"
                                              name="Shipping1"
                                              onClick={this.handleCheckBox}
                                              value="option2"
                                              disabled={
                                                !this.state
                                                  .isRequirementsDetailsEditable
                                              }
                                            />
                                          </span>
                                        </div>
                                        Dry Van
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">
                                    Trailer Size:
                                  </label>
                                  <div className="col-md-9">
                                    <div className="checkbox-list">
                                      <label className="checkbox-inline">
                                        <div className="checker">
                                          <span className="" id="trailerType">
                                            <input
                                              type="checkbox"
                                              ref="trailerType"
                                              name="trailerType"
                                              onClick={this.handleCheckBox}
                                              value="option1"
                                              disabled={
                                                !this.state
                                                  .isRequirementsDetailsEditable
                                              }
                                            />
                                          </span>
                                        </div>
                                        53
                                      </label>
                                      <label className="checkbox-inline">
                                        <div className="checker">
                                          <span className="" id="trailerType1">
                                            <input
                                              type="checkbox"
                                              ref="trailerType1"
                                              name="trailerType1"
                                              onClick={this.handleCheckBox}
                                              value="option2"
                                              disabled={
                                                !this.state
                                                  .isRequirementsDetailsEditable
                                              }
                                            />
                                          </span>
                                        </div>
                                        48
                                      </label>
                                    </div>
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="col-md-3 control-label">
                                    Driver Requirements:
                                  </label>
                                  <div className="col-md-9">
                                    {/* <span style={{marginTop: '2px', marginTop: '6px',position:'relative',zIndex:0}} >
                                <div className="btn-group btn-group-devided" data-toggle="buttons" style={this.state.readonly ?nonclick:{}}>
                                  <label className="btn btn-transparent dark btn-outline btn-circle btn-sm " id='team' disabled={this.state.readonly } onClick={()=>{if(this._isMounted){this.setState({driverRequirements:'team'})}}}>
                                    <input type="radio" name="options" className="toggle" id="option1"/>
                                    TEAM</label>
                                  <label className="btn btn-transparent dark btn-outline btn-circle btn-sm" id='solo' disabled={this.state.readonly } onClick={()=>{if(this._isMounted){this.setState({driverRequirements:'solo'})}}}>
                                    <input type="radio" name="options" className="toggle" id="option2"/>
                                    SOLO</label>
                                </div>
                              </span> */}

                                    <div className="checkbox-list">
                                      <label className="checkbox-inline">
                                        <div className="checker">
                                          <span
                                            className=""
                                            id="driverRequirements"
                                          >
                                            <input
                                              type="checkbox"
                                              ref="driverRequirements"
                                              name="driverRequirements"
                                              onClick={this.handleCheckBox}
                                              value="option1"
                                              disabled={
                                                !this.state
                                                  .isRequirementsDetailsEditable
                                              }
                                            />
                                          </span>
                                        </div>
                                        Team
                                      </label>
                                      <label className="checkbox-inline">
                                        <div className="checker">
                                          <span
                                            className=""
                                            id="driverRequirements1"
                                          >
                                            <input
                                              type="checkbox"
                                              ref="driverRequirements1"
                                              name="driverRequirements1"
                                              onClick={this.handleCheckBox}
                                              value="option2"
                                              disabled={
                                                !this.state
                                                  .isRequirementsDetailsEditable
                                              }
                                            />
                                          </span>
                                        </div>
                                        Solo
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="clearfix"></div>

                                <div className="text-right">
                                  <button
                                    onClick={this.cancel.bind(this)}
                                    type="button"
                                    className="btn default"
                                  >
                                    Cancel
                                  </button>
                                  &nbsp;
                                  {role !== tenantUser ? (
                                    <button
                                      disabled={
                                        !this.state
                                          .isRequirementsDetailsEditable
                                      }
                                      type="submit"
                                      className="btn green-meadow"
                                    >
                                      {this.state.whileSave === false ? (
                                        <i className="fa fa-save"></i>
                                      ) : (
                                        <i className="fa fa-circle-o-notch fa-spin"></i>
                                      )}
                                      Save
                                    </button>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="bg-white border-orange yellow-gold">
                            <div className="portlet mb-3 yellow-gold box">
                              <div className="portlet-title" id="pickup">
                                <div className="caption">
                                  <i className="fa fa-cubes"></i>Pickup and
                                  Delivery Details:
                                </div>
                                <div
                                  className="actions"
                                  style={
                                    !this.state.isStopsDetailsEditable
                                      ? {}
                                      : nonclick
                                  }
                                  id="EditDiv"
                                >
                                  {role !== tenantUser ? (
                                    <div
                                      className="btn-group"
                                      style={
                                        this.state.hidden === false
                                          ? msgShow
                                          : msgNone
                                      }
                                    >
                                      <button
                                        type="button"
                                        className="btn sbold white dark"
                                        onClick={() =>
                                          this.EditForm(
                                            "isStopsDetailsEditable"
                                          )
                                        }
                                        hidden={this.state.hidden}
                                        disabled={this.state.hidden}
                                      >
                                        <i className="fa fa-pencil"></i> Edit
                                      </button>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>{" "}
                            <div className="portlet-body form">
                              <div className="max_width800">
                                <SortableContainer useDragHandle>
                                  {StopData.map((StopObj, index) => {
                                    return (
                                      <>
                                        <div className="card">
                                          <SortableItem
                                            key={`item-${StopObj.id}`}
                                            index={index}
                                            totalStops={StopData.length}
                                            props={StopObj}
                                            readonly={
                                              !this.state.isStopsDetailsEditable
                                            }
                                            value={`Stop ${StopObj.stopNo}`}
                                            stopNo={StopObj.stopNo}
                                            indexValue={index}
                                            stopDeleteHandler={(oldIndex) => {
                                              if (
                                                !window.confirm(
                                                  "Are You Sure You Want To Delete ? "
                                                )
                                              ) {
                                                return false;
                                              } else {
                                                var stop = StopData;
                                                stop.splice(index, 1);
                                                if (this._isMounted) {
                                                  this.setState({
                                                    StopData: stop,
                                                  });
                                                }
                                              }
                                            }}
                                            sortUpHandler={(oldIndex) =>
                                              this.onSortEnd(
                                                oldIndex,
                                                "up",
                                                StopObj.stopNo
                                              )
                                            }
                                            sortDownHandler={(oldIndex) =>
                                              this.onSortEnd(
                                                oldIndex,
                                                "down",
                                                StopObj.stopNo
                                              )
                                            }
                                            dispatchedStops={
                                              this.state.dispatchedStops
                                            }
                                            address={
                                              this.state.StopData[index]
                                                .shipperId !== null
                                                ? StopData[index][
                                                  "ShipperOptions"
                                                ][0] === undefined
                                                  ? ""
                                                  : StopData[index][
                                                    "ShipperOptions"
                                                  ][0].label
                                                : this.state.StopData[index]
                                                  .address
                                            }
                                          />

                                          <div id={`item-${StopObj.stopNo}`}>
                                            <div className="form-group row mt-2">
                                              <label className="col-md-3 control-label">

                                              </label>
                                              <div className="col-md-9">
                                                <select
                                                  value={StopObj.stopType.toString()}
                                                  className="form-control"
                                                  required
                                                  disabled={
                                                    !this.state
                                                      .isStopsDetailsEditable
                                                  }
                                                  onChange={(e) => {
                                                    console.log(e.target.value);
                                                    var stop = StopData;
                                                    stop[index]["stopType"] =
                                                      e.target.value;
                                                    if (this._isMounted) {
                                                      this.setState({
                                                        StopData: stop,
                                                      });
                                                    }
                                                  }}
                                                >
                                                  <option value="">-</option>
                                                  <option value="pickup">Pickup</option>
                                                  <option value="delivery">Delivery</option>
                                                  <option value="transit">Transit</option>
                                                </select>
                                              </div>
                                            </div>

                                            <div className="form-group row mt-2">
                                              <label className="col-md-3 control-label">
                                                Schedule:
                                              </label>
                                              <div className="col-md-9">
                                                <div className="form-group row">
                                                  <div
                                                    className="col-md-5"
                                                    style={{
                                                      position: "static",
                                                    }}
                                                  >
                                                    {/* 123123 */}
                                                    <DatePicker
                                                      disabled={
                                                        this.state.readonly
                                                      }
                                                      required
                                                      className="form-control"
                                                      placeholderText={StopData[index]["scheduleFTime"] == null && "MM/DD/YYYY HH:MM"}
                                                      selected={
                                                        StopData[index]["scheduleFTime"] !== null && new Date(
                                                          moment
                                                            .utc(
                                                              // (
                                                              StopData[index][
                                                              "scheduleFrom"
                                                              ]
                                                            )
                                                            .format(
                                                              "MM/DD/YYYY HH:mm:ss"
                                                            )
                                                        )
                                                      }
                                                      showTimeSelect
                                                      showTimeInput
                                                      timeInputLabel="Input Time:"
                                                      timeFormat="HH:mm"
                                                      timeIntervals={30}
                                                      dateFormat="MM/dd/yyyy HH:mm"
                                                      timeCaption="Time"
                                                      onChange={(date) => {
                                                        if (date == null) {
                                                          StopData[index]["scheduleFTime"] = null
                                                          StopData[index]["scheduleFrom"] = moment().set({ hour: 0, minute: 0, second: 0 })
                                                          if (this._isMounted) {
                                                            this.setState({
                                                              StopData: StopData,
                                                            });
                                                          }
                                                          return false
                                                        }
                                                        let getUTC = getTimeZone();
                                                        let splitUTC = getUTC.split(
                                                          ":"
                                                        );
                                                        let getHourse =
                                                          splitUTC[0];
                                                        let getMints =
                                                          splitUTC[1];

                                                        if (
                                                          getHourse.includes(
                                                            "-"
                                                          )
                                                        ) {
                                                          let split = getHourse.split(
                                                            "-"
                                                          );
                                                          split = parseInt(
                                                            split[1]
                                                          );
                                                          let finalMints = parseInt(
                                                            getMints
                                                          );
                                                          let getFinalDate = new Date(
                                                            moment(date)
                                                              // .utc()
                                                              .toDate()
                                                          );
                                                          let setDateFinal = getFinalDate.setHours(
                                                            getFinalDate.getHours() -
                                                            split
                                                          );
                                                          setDateFinal =
                                                            setDateFinal -
                                                            finalMints *
                                                            60 *
                                                            1000;
                                                          date = setDateFinal;
                                                        } else {
                                                          let split = getHourse.split(
                                                            "+"
                                                          );
                                                          split = parseInt(
                                                            split[1]
                                                          );
                                                          let finalMints = parseInt(
                                                            getMints
                                                          );
                                                          let getFinalDate = new Date(
                                                            moment(date)
                                                              // .utc()
                                                              .toDate()
                                                          );
                                                          let setDateFinal = getFinalDate.setHours(
                                                            getFinalDate.getHours() +
                                                            split
                                                          );
                                                          setDateFinal =
                                                            setDateFinal -
                                                            finalMints *
                                                            60 *
                                                            1000;
                                                          date = setDateFinal;
                                                        }
                                                        // if (date > new Date(StopData[index]["scheduleTo"]).getTime()) {
                                                        //   NotificationManager.error(
                                                        //     "delivery date cannot be greater than pickup!",
                                                        //     "Error Message",
                                                        //     3000
                                                        //   );
                                                        // } else {
                                                        var stop = StopData;
                                                        stop[index][
                                                          "scheduleFrom"
                                                        ] = date;
                                                        StopData[index]["scheduleFTime"] = date
                                                        // console.log(stop[index]['scheduleFTime']);
                                                        if (this._isMounted) {
                                                          this.setState({
                                                            StopData: stop,
                                                          });
                                                        }
                                                        // }

                                                      }}
                                                    />
                                                  </div>
                                                  <div className="col-md-2 text-center">
                                                    TO
                                                  </div>
                                                  <div
                                                    className="col-md-5"
                                                    style={{
                                                      position: "static",
                                                    }}
                                                  >
                                                    <DatePicker
                                                      disabled={
                                                        this.state.readonly || StopData[index]["scheduleFTime"] === null
                                                      }
                                                      required
                                                      className="form-control"
                                                      placeholderText={StopData[index]["scheduleTTime"] == null ? "MM/DD/YYYY HH:MM" : false}
                                                      selected={
                                                        StopData[index]["scheduleTTime"] !== null ? new Date(
                                                          moment
                                                            .utc(
                                                              // (
                                                              StopData[index][
                                                              "scheduleTo"
                                                              ]
                                                            )
                                                            .format(
                                                              "MM/DD/YYYY HH:mm:ss"
                                                            )
                                                        ) : false
                                                      }
                                                      showTimeSelect
                                                      showTimeInput
                                                      timeInputLabel="Input Time:"
                                                      timeFormat="HH:mm"
                                                      timeIntervals={30}
                                                      dateFormat="MM/dd/yyyy HH:mm"
                                                      timeCaption="Time"
                                                      onChange={(date) => {
                                                        // console.log(date);
                                                        // console.log('current',date,'schedule From', new Date(StopData[index]['scheduleFrom']).getUTCHours());
                                                        if (date == null) {
                                                          StopData[index]["scheduleTTime"] = null
                                                          StopData[index]["scheduleTo"] = moment().set({ hour: 0, minute: 0, second: 0 })
                                                          if (this._isMounted) {
                                                            this.setState({
                                                              StopData: StopData,
                                                            });
                                                          }
                                                          return false
                                                        }
                                                        let getUTC = getTimeZone();

                                                        let splitUTC = getUTC.split(
                                                          ":"
                                                        );
                                                        let getHourse =
                                                          splitUTC[0];
                                                        let getMints =
                                                          splitUTC[1];

                                                        if (
                                                          getHourse.includes(
                                                            "-"
                                                          )
                                                        ) {
                                                          let split = getHourse.split(
                                                            "-"
                                                          );
                                                          split = parseInt(
                                                            split[1]
                                                          );
                                                          let finalMints = parseInt(
                                                            getMints
                                                          );
                                                          let getFinalDate = new Date(
                                                            moment(date)
                                                              // .utc()
                                                              .toDate()
                                                          );
                                                          let setDateFinal = getFinalDate.setHours(
                                                            getFinalDate.getHours() -
                                                            split
                                                          );
                                                          setDateFinal =
                                                            setDateFinal -
                                                            finalMints *
                                                            60 *
                                                            1000;
                                                          date = setDateFinal;
                                                        } else {
                                                          let split = getHourse.split(
                                                            "+"
                                                          );
                                                          split = parseInt(
                                                            split[1]
                                                          );
                                                          let finalMints = parseInt(
                                                            getMints
                                                          );
                                                          let getFinalDate = new Date(
                                                            moment(date)
                                                              // .utc()
                                                              .toDate()
                                                          );
                                                          let setDateFinal = getFinalDate.setHours(
                                                            getFinalDate.getHours() +
                                                            split
                                                          );
                                                          setDateFinal =
                                                            setDateFinal -
                                                            finalMints *
                                                            60 *
                                                            1000;
                                                          date = setDateFinal;
                                                        }

                                                        // if (date < new Date(StopData[index]["scheduleFrom"]).getTime()) {
                                                        //   let from = new Date(StopData[index]['scheduleFrom'])
                                                        //   if (current.getDay() + current.getMonth() + current.getFullYear()
                                                        //     == from.getDay() + from.getMonth() + from.getFullYear()) {
                                                        //     console.log('Dates are same...');
                                                        //     var stop = StopData;
                                                        //     stop[index][
                                                        //       "scheduleTo"
                                                        //     ] = date;
                                                        //     StopData[index]["scheduleTTime"] = date
                                                        //     if (this._isMounted) {
                                                        //       this.setState({
                                                        //         StopData: stop,
                                                        //       });
                                                        //     }
                                                        //   } else {
                                                        //     NotificationManager.error(
                                                        //       "delivery date cannot be greater than pickup!",
                                                        //       "Error Message",
                                                        //       3000
                                                        //     );
                                                        //   }
                                                        // } else {
                                                        var stop = StopData;
                                                        stop[index][
                                                          "scheduleTo"
                                                        ] = date;
                                                        StopData[index]["scheduleTTime"] = date
                                                        if (this._isMounted) {
                                                          this.setState({
                                                            StopData: stop,
                                                          });
                                                        }
                                                        // }

                                                      }
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="form-group row">
                                              <label className="col-md-3 control-label"></label>
                                              <div className="col-md-9">
                                                <div className="form-group row">
                                                  <div
                                                    className="col-md-5"
                                                    style={{
                                                      position: "static",
                                                    }}
                                                  >
                                                    <label>
                                                      <div className="checker">
                                                        <span
                                                          className={
                                                            StopData[index][
                                                              "shipperId"
                                                            ] === null
                                                              ? ""
                                                              : "checked"
                                                          }
                                                        >
                                                          <input
                                                            type="checkbox"
                                                            onClick={(e) =>
                                                              this.addressCheckboxHandler(
                                                                e,
                                                                index
                                                              )
                                                            }
                                                            disabled={
                                                              this.state
                                                                .readonly
                                                            }
                                                          />
                                                        </span>
                                                      </div>
                                                      Address &nbsp;
                                                    </label>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            {this.state.StopData[index]
                                              .shipperId !== null ? (
                                              <>
                                                <div className="form-group row">
                                                  <label className="col-md-3 control-label">
                                                    {StopData[index][
                                                      "stopType"
                                                    ] === "pickup"
                                                      ? "Shipper"
                                                      : StopData[index][
                                                        "stopType"
                                                      ] === "delivery"
                                                        ? "Receiver"
                                                        : "Transit"}
                                                  </label>
                                                  <div className="col-md-9">
                                                    <div className="form-group row">
                                                      <div className="col-md-9">
                                                        <Select
                                                          optionHeight={50}
                                                          disabled={
                                                            this.state.readonly
                                                          }
                                                          value={
                                                            StopData[index][
                                                            "shipperId"
                                                            ]
                                                          }
                                                          options={
                                                            StopData[index][
                                                            "ShipperOptions"
                                                            ]
                                                          }
                                                          onChange={(
                                                            changeValue
                                                          ) => {
                                                            var value = changeValue
                                                              ? changeValue.value
                                                              : null;
                                                            var stop = StopData;
                                                            stop[index][
                                                              "shipperOptionsData"
                                                            ].forEach(
                                                              (element) => {
                                                                if (
                                                                  element.id ===
                                                                  value
                                                                ) {
                                                                  var address = element.address
                                                                    ? element
                                                                      .address
                                                                      .address
                                                                      ? element
                                                                        .address
                                                                        .address
                                                                      : ""
                                                                    : "";
                                                                  var city = element.address
                                                                    ? element
                                                                      .address
                                                                      .city
                                                                      ? element
                                                                        .address
                                                                        .city
                                                                      : ""
                                                                    : "";
                                                                  var state = element.address
                                                                    ? element
                                                                      .address
                                                                      .state
                                                                      ? element
                                                                        .address
                                                                        .state
                                                                        .state
                                                                      : ""
                                                                    : "";
                                                                  var zip = element.address
                                                                    ? element
                                                                      .address
                                                                      .zip
                                                                      ? element
                                                                        .address
                                                                        .zip
                                                                      : ""
                                                                    : "";
                                                                  var telephone = element.telephone
                                                                    ? element
                                                                      .address
                                                                      .telephone
                                                                      ? element
                                                                        .address
                                                                        .telephone
                                                                      : ""
                                                                    : "";
                                                                  var stateAbbreviation = element.address
                                                                    ? element
                                                                      .address
                                                                      .state
                                                                      ? element
                                                                        .address
                                                                        .state
                                                                        .stateAbbrivation
                                                                      : ""
                                                                    : "";
                                                                  stop[index][
                                                                    "shipperCard"
                                                                  ] = {
                                                                    address: address,
                                                                    city: city,
                                                                    state: state,
                                                                    telephone: telephone,
                                                                    name:
                                                                      element.name,
                                                                    stateAbbr: stateAbbreviation,
                                                                    zip: zip,
                                                                  };
                                                                }
                                                              }
                                                            );
                                                            stop[index][
                                                              "shipperId"
                                                            ] = value;
                                                            value
                                                              ? {}
                                                              : (stop[index][
                                                                "shipperCard"
                                                              ] = {});
                                                            if (
                                                              this._isMounted
                                                            ) {
                                                              this.setState({
                                                                StopData: stop,
                                                              });
                                                            }
                                                          }}
                                                          onInputChange={(
                                                            input
                                                          ) => {
                                                            if (
                                                              input.trim()
                                                                .length > 1
                                                            ) {
                                                              axios
                                                                .post(
                                                                  BaseURL +
                                                                  "shipper/findByName",
                                                                  {
                                                                    searchKeyword: input,
                                                                    token: this
                                                                      .state
                                                                      .token,
                                                                  }
                                                                )
                                                                .then(
                                                                  (
                                                                    response
                                                                  ) => {
                                                                    if (
                                                                      response
                                                                        .data
                                                                        .error ===
                                                                      TokenErrorMsg
                                                                    ) {
                                                                      localStorage.setItem(
                                                                        "Token",
                                                                        ""
                                                                      );
                                                                      this.props.history.push(
                                                                        "/"
                                                                      );
                                                                    }
                                                                    var arr = [];
                                                                    response.data.data.rows.forEach(
                                                                      (
                                                                        element
                                                                      ) => {
                                                                        var CompleteAddress =
                                                                          "";
                                                                        var address = element.address
                                                                          ? element
                                                                            .address
                                                                            .address
                                                                            ? ", " +
                                                                            element
                                                                              .address
                                                                              .address +
                                                                            ", "
                                                                            : ""
                                                                          : "";
                                                                        var city = element.address
                                                                          ? element
                                                                            .address
                                                                            .city
                                                                            ? element
                                                                              .address
                                                                              .city +
                                                                            ", "
                                                                            : ""
                                                                          : "";
                                                                        var state = element.address
                                                                          ? element
                                                                            .address
                                                                            .state
                                                                            ? element
                                                                              .address
                                                                              .state
                                                                              .state +
                                                                            ", "
                                                                            : ""
                                                                          : "";
                                                                        var zip = element.address
                                                                          ? element
                                                                            .address
                                                                            .zip
                                                                            ? element
                                                                              .address
                                                                              .zip
                                                                            : ""
                                                                          : "";
                                                                        CompleteAddress =
                                                                          address +
                                                                          city +
                                                                          state +
                                                                          zip;
                                                                        var obj = {
                                                                          value:
                                                                            element.id,
                                                                          label:
                                                                            element.name +
                                                                            CompleteAddress,
                                                                        };
                                                                        arr.push(
                                                                          obj
                                                                        );
                                                                      }
                                                                    );
                                                                    var stop = StopData;
                                                                    stop[index][
                                                                      "ShipperOptions"
                                                                    ] = arr;
                                                                    stop[index][
                                                                      "shipperOptionsData"
                                                                    ] =
                                                                      response.data.data.rows;
                                                                    this.setState(
                                                                      {
                                                                        StopData: stop,
                                                                      }
                                                                    );
                                                                  }
                                                                )
                                                                .catch(function (
                                                                  error
                                                                ) {
                                                                  NotificationManager.error(
                                                                    "Something Went Wrong Server Is Not Responding ",
                                                                    "Error Message",
                                                                    3000
                                                                  );
                                                                  console.log(
                                                                    error
                                                                  );
                                                                });
                                                            }
                                                          }}
                                                          placeholder="Search and Choose "
                                                        />
                                                      </div>
                                                      <div
                                                        className="col-md-3"
                                                        style={
                                                          this.state.readonly
                                                            ? nonclick
                                                            : {}
                                                        }
                                                      >
                                                        <a
                                                          data-toggle="modal"
                                                          href="#shipper_info"
                                                          className="btn btn-block green-meadow"
                                                        >
                                                          <i className="fa fa-plus"></i>
                                                          Add
                                                        </a>
                                                      </div>
                                                    </div>
                                                    <div
                                                      className="row"
                                                      style={
                                                        StopData[index][
                                                          "shipperCard"
                                                        ]["address"]
                                                          ? msgShow
                                                          : msgNone
                                                      }
                                                    >
                                                      <div className="col-md-12">
                                                        <div className="card_info">
                                                          <div className="row">
                                                            <div className="col-md-12">
                                                              <p>
                                                                <i
                                                                  className="fa fa-map-marker"
                                                                  aria-hidden="true"
                                                                ></i>
                                                                &nbsp;
                                                                {
                                                                  StopData[
                                                                  index
                                                                  ][
                                                                  "shipperCard"
                                                                  ]["address"]
                                                                }
                                                              </p>
                                                            </div>
                                                          </div>
                                                          <div className="row">
                                                            <div className="col-md-6">
                                                              <p>
                                                                <i
                                                                  className="fa fa-building"
                                                                  aria-hidden="true"
                                                                ></i>
                                                                &nbsp;
                                                                {
                                                                  StopData[
                                                                  index
                                                                  ][
                                                                  "shipperCard"
                                                                  ]["city"]
                                                                }
                                                              </p>
                                                            </div>
                                                            <div className="col-md-6">
                                                              <p>
                                                                <i
                                                                  className="fa fa-globe"
                                                                  aria-hidden="true"
                                                                ></i>
                                                                &nbsp;
                                                                {
                                                                  StopData[
                                                                  index
                                                                  ][
                                                                  "shipperCard"
                                                                  ]["state"]
                                                                }
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            ) : (
                                              <>
                                                <div className="form-group row">
                                                  <label className="col-md-3 control-label">
                                                    Address
                                                  </label>
                                                  <div className="col-md-9">
                                                    <PlacesAutocomplete
                                                      searchOptions={
                                                        searchOptions
                                                      }
                                                      value={
                                                        this.state.StopData[
                                                          index
                                                        ].address
                                                      }
                                                      onChange={(address) =>
                                                        this.handleAddressChange(
                                                          address,
                                                          index
                                                        )
                                                      }
                                                      onSelect={(address) =>
                                                        this.handleSelect(
                                                          address,
                                                          index
                                                        )
                                                      }
                                                      onError={(e) =>
                                                        console.log(e)
                                                      }
                                                      clearItemsOnError={true}
                                                    >
                                                      {({
                                                        getInputProps,
                                                        suggestions,
                                                        getSuggestionItemProps,
                                                        loading,
                                                      }) => (
                                                        <div className="form-group">
                                                          <div className="">
                                                            <input
                                                              type="text"
                                                              ref="address"
                                                              {...getInputProps(
                                                                {
                                                                  className:
                                                                    "location-search-input",
                                                                }
                                                              )}
                                                              name="address"
                                                              className="form-control"
                                                              placeholder="City/State"
                                                            />
                                                            <div
                                                              className="autocomplete-dropdown-container"
                                                              style={{
                                                                position:
                                                                  "absolute",
                                                                zIndex: 9,
                                                                background:
                                                                  "white",
                                                                width: "92%",
                                                              }}
                                                            >
                                                              {loading && (
                                                                <div className="suggestion-item">
                                                                  Loading...
                                                                </div>
                                                              )}
                                                              {suggestions.map(
                                                                (
                                                                  suggestion
                                                                ) => {
                                                                  const className = suggestion.active
                                                                    ? "suggestion-item--active"
                                                                    : "suggestion-item";
                                                                  return (
                                                                    <div
                                                                      {...getSuggestionItemProps(
                                                                        suggestion,
                                                                        {
                                                                          className,
                                                                        }
                                                                      )}
                                                                    >
                                                                      <span>
                                                                        {
                                                                          suggestion.description
                                                                        }
                                                                      </span>
                                                                    </div>
                                                                  );
                                                                }
                                                              )}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      )}
                                                    </PlacesAutocomplete>
                                                  </div>
                                                </div>
                                              </>
                                            )}
                                            <div className="form-group row">
                                              <label className="col-md-3 control-label">
                                                {StopData[index]["stopType"] ===
                                                  "pickup"
                                                  ? "Shipper"
                                                  : StopData[index][
                                                    "stopType"
                                                  ] === "delivery"
                                                    ? "Receiver"
                                                    : "Transit"}
                                                Status:
                                              </label>
                                              <div className="col-md-9">
                                                {/* <span style={{marginTop: '2px', marginTop: '6px' ,position:'relative',zIndex:0}}>
                                  <div className="btn-group btn-group-devided" data-toggle="buttons" style={this.state.readonly ?nonclick:{}}>
                                    <label className = {StopData[index]['shipperStatus'] ==='checkedin' ? "btn btn-transparent dark btn-outline btn-circle btn-sm active":'btn btn-transparent dark btn-outline btn-circle btn-sm'}
                                    disabled={this.state.readonly }
                                     onClick={()=>{
                                       var stop =StopData;
                                       stop[index]['shipperStatus']='checkedin';
                                       if(this._isMounted){
                                       this.setState({StopData:stop});
                                       }
                                       }}
                                       >
                                      <input type="radio" name="options" className="toggle" id="option1"/>
                                      CHECKED IN</label>
                                    <label className = {StopData[index]['shipperStatus'] ==='checkedout' ? "btn btn-transparent dark btn-outline btn-circle btn-sm active":'btn btn-transparent dark btn-outline btn-circle btn-sm'}
                                    disabled={this.state.readonly }
                                    onClick={()=>{
                                       var stop =StopData;
                                       stop[index]['shipperStatus']='checkedout';
                                       if(this._isMounted){
                                       this.setState({StopData:stop});
                                       }
                                       }}
                                       >
                                      <input type="radio" name="options" className="toggle" id="option2"/>
                                      CHECKED OUT</label>
                                  </div>
                                </span> */}
                                                <div
                                                  className="checkbox-list"
                                                  style={{
                                                    marginTop: "-4px",
                                                  }}
                                                >
                                                  <label className="checkbox-inline">
                                                    {/* <div className="checker">
                                                      <span
                                                        className={
                                                          StopData[index][
                                                            "checkedIn"
                                                          ] === "1"
                                                            ? "checked"
                                                            : ""
                                                        }
                                                      >
                                                        <input
                                                          type="checkbox"
                                                          onClick={() => {
                                                            var stop = StopData;
                                                            stop[index][
                                                              "checkedIn"
                                                            ] =
                                                              stop[index][
                                                                "checkedIn"
                                                              ] === "0"
                                                                ? "1"
                                                                : "0";
                                                            stop[index][
                                                              "checkedIn"
                                                            ] === "1"
                                                              ? (stop[index][
                                                                "checkedInTime"
                                                              ] = moment())
                                                              : (stop[index][
                                                                "checkedInTime"
                                                              ] = moment().set(
                                                                {
                                                                  hour: 0,
                                                                  minute: 0,
                                                                  second: 0,
                                                                }
                                                              ));
                                                            if (
                                                              this._isMounted
                                                            ) {
                                                              this.setState({
                                                                StopData: stop,
                                                              });
                                                            }
                                                          }}
                                                          value="option1"
                                                          disabled={
                                                            this.state.readonly
                                                          }
                                                        />
                                                      </span>
                                                    </div> */}
                                                    Checked In &nbsp;
                                                  </label>
                                                  <div
                                                    style={{
                                                      position: "static",
                                                      display: "inline-block",
                                                      marginLeft: "63px",
                                                    }}
                                                  >
                                                    <DatePicker
                                                      disabled={
                                                        this.state.readonly
                                                      }
                                                      placeholderText={StopData[index]['checkedInT'] == 0 && "MM/DD/YYYY HH:MM"}
                                                      className="form-control"
                                                      selected={
                                                        StopData[index]['checkedInT'] !== 0 && new Date(
                                                          // moment.utc(
                                                          StopData[index][
                                                          "checkedInTime"
                                                          ]
                                                          // ).format("MM/DD/YYYY HH:mm:ss")
                                                        )
                                                      }
                                                      showTimeSelect
                                                      showTimeInput
                                                      timeInputLabel="Input Time:"
                                                      timeFormat="HH:mm"
                                                      timeIntervals={30}
                                                      dateFormat={
                                                        StopData[index][
                                                          "checkedIn"
                                                        ] === "1"
                                                          ? "MM/dd/yyyy HH:mm"
                                                          : "HH:mm"
                                                      }
                                                      timeCaption="Time"
                                                      onChange={(date) => {
                                                        var stop = StopData;
                                                        if (date == null) {
                                                          stop[index]['checkedInT'] = 0;
                                                          stop[index]['checkedInTime'] = moment().set({ hour: 0, minute: 0, second: 0 })
                                                          if (this._isMounted) {
                                                            this.setState({
                                                              StopData: StopData,
                                                            });
                                                          }
                                                          return false;
                                                        }
                                                        // stop[index]['checkedInT'] = date.getTime()
                                                        // if (date.getTime() > stop[index]['checkedOutT'] && stop[index]['checkedOutT'] !== 0) {
                                                        //   console.log('CheckedIn Time Cannnot be greater');
                                                        //   NotificationManager.error(
                                                        //     `CheckedIn Time can not be greater than CheckedOut Time.`,
                                                        //     "Error Message",
                                                        //     3000
                                                        //   );
                                                        // } else {
                                                        stop[index][
                                                          "checkedInTime"
                                                        ] = date;
                                                        stop[index][
                                                          "checkedIn"
                                                        ] = "1";
                                                        if (this._isMounted) {
                                                          this.setState({
                                                            StopData: stop,
                                                          });
                                                        }
                                                        // }


                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                                <div
                                                  className="checkbox-list"
                                                  style={{
                                                    marginTop: "10px",
                                                  }}
                                                >
                                                  <label className="checkbox-inline">
                                                    {/* <div className="checker">
                                                      <span
                                                        className={
                                                          StopData[index][
                                                            "checkedOut"
                                                          ] === "1"
                                                            ? "checked"
                                                            : ""
                                                        }
                                                      >
                                                        <input
                                                          type="checkbox"
                                                          onClick={() => {
                                                            var stop = StopData;
                                                            stop[index][
                                                              "checkedOut"
                                                            ] =
                                                              stop[index][
                                                                "checkedOut"
                                                              ] === "0"
                                                                ? "1"
                                                                : "0";
                                                            stop[index][
                                                              "checkedOut"
                                                            ] === "1"
                                                              ? (stop[index][
                                                                "checkedOutTime"
                                                              ] = moment())
                                                              : (stop[index][
                                                                "checkedOutTime"
                                                              ] = moment().set(
                                                                {
                                                                  hour: 0,
                                                                  minute: 0,
                                                                  second: 0,
                                                                }
                                                              ));
                                                            if (
                                                              this._isMounted
                                                            ) {
                                                              this.setState({
                                                                StopData: stop,
                                                              });
                                                            }
                                                          }}
                                                          value="option1"
                                                          disabled={
                                                            this.state.readonly
                                                          }
                                                        />
                                                      </span>
                                                    </div> */}
                                                    Checked Out
                                                  </label>
                                                  <div
                                                    style={{
                                                      position: "static",
                                                      display: "inline-block",
                                                      marginLeft: "60px",
                                                    }}
                                                  >
                                                    <DatePicker
                                                      disabled={
                                                        this.state.readonly
                                                      }
                                                      className="form-control"
                                                      placeholderText={StopData[index]['checkedOutT'] == 0 && "MM/DD/YYYY HH:MM"}
                                                      selected={
                                                        StopData[index]['checkedOutT'] !== 0 && new Date(
                                                          // moment.utc(
                                                          StopData[index][
                                                          "checkedOutTime"
                                                          ]
                                                          // ).format("MM/DD/YYYY HH:mm:ss")
                                                        )
                                                      }
                                                      showTimeSelect
                                                      showTimeInput
                                                      timeInputLabel="Input Time:"
                                                      timeFormat="HH:mm"
                                                      timeIntervals={30}
                                                      dateFormat={
                                                        StopData[index][
                                                          "checkedOut"
                                                        ] === "1"
                                                          ? "MM/dd/yyyy HH:mm"
                                                          : "HH:mm"
                                                      }
                                                      timeCaption="Time"
                                                      onChange={(date) => {
                                                        var stop = StopData;
                                                        if (date == null) {
                                                          stop[index]['checkedOutT'] = 0;
                                                          stop[index]['checkedOutTime'] = moment().set({ hour: 0, minute: 0, second: 0 })
                                                          if (this._isMounted) {
                                                            this.setState({
                                                              StopData: StopData,
                                                            });
                                                          }
                                                          return false;
                                                        }
                                                        // stop[index]['checkedOutT'] = date.getTime()
                                                        // if (date.getTime() < stop[index]['checkedInT'] && stop[index]['checkedInT'] !== 0) {
                                                        //   console.log('CheckedOut Time Cannnot be greater');
                                                        //   NotificationManager.error(
                                                        //     `CheckedOut Time can not be less than CheckedIn Time.`,
                                                        //     "Error Message",
                                                        //     3000
                                                        //   );
                                                        // } else {
                                                        stop[index][
                                                          "checkedOutTime"
                                                        ] = date;
                                                        stop[index][
                                                          "checkedOut"
                                                        ] = "1";
                                                        if (this._isMounted) {
                                                          this.setState({
                                                            StopData: stop,
                                                          });
                                                        }
                                                        // }

                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            {StopData[index]["puNo"] ? StopData[index]["puNo"].map((el, ind) => (
                                              <div className="form-group row">
                                                <label className="col-md-3 control-label">
                                                  {StopData[index]["stopType"] ===
                                                    "pickup"
                                                    ? "PU"
                                                    : "DEL"} #{ind + 1}
                                                </label>
                                                <div className="col-md-9">
                                                  <input
                                                    type="text"
                                                    id="pu"
                                                    className="form-control"
                                                    placeholder=""
                                                    required
                                                    defaultValue={
                                                      el
                                                      // StopData[index]["puNo"]
                                                    }
                                                    disabled={this.state.readonly}
                                                    onChange={(e) => {
                                                      var stop = StopData;
                                                      stop[index]["puNo"][ind] =
                                                        e.target.value;
                                                      if (this._isMounted) {
                                                        this.setState({
                                                          StopData: stop,
                                                        });
                                                      }
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                            )) : <div className="form-group row">
                                              <label className="col-md-3 control-label">
                                                {StopData[index]["stopType"] ===
                                                  "pickup"
                                                  ? "PU"
                                                  : "DEL"} #
                                              </label>
                                              <div className="col-md-9">
                                                <input
                                                  type="text"
                                                  id="pu"
                                                  className="form-control"
                                                  placeholder=""
                                                  required
                                                  disabled={this.state.readonly}
                                                  onChange={(e) => {
                                                    var stop = StopData;
                                                    stop[index]["puNo"][ind] =
                                                      e.target.value;
                                                    if (this._isMounted) {
                                                      this.setState({
                                                        StopData: stop,
                                                      });
                                                    }
                                                  }}
                                                />
                                              </div>
                                            </div>}

                                            <div className="form-group row">
                                              <div className="col-md-3">
                                              </div>
                                              <div className="col-md-3">
                                                <Button type="button"
                                                  className="btn green-meadow"
                                                  disabled={StopData[index]["puNo"].at(-1) === ""}
                                                  onClick={() => {
                                                    var stop = StopData;
                                                    // this.state.puFields[index].push(this.state.puFields[index].length)
                                                    // this.setState({ puFields: [...this.state.puFields] })
                                                    StopData[index]["puNo"].push("")
                                                    if (this._isMounted) {
                                                      this.setState({
                                                        StopData: stop,
                                                      });
                                                    }
                                                  }}>Add {StopData[index]["stopType"] ===
                                                    "pickup"
                                                    ? "PU"
                                                    : StopData[index]["stopType"] ===
                                                      "delivery"
                                                      ? "DEL"
                                                      : "Transit"}</Button>
                                              </div>
                                            </div>
                                            <div className="form-group row">
                                              <label className="col-md-3 control-label">
                                                Notes:
                                              </label>
                                              <div className="col-md-9">
                                                <textarea
                                                  id="notes"
                                                  style={{ resize: 'none' }}
                                                  className="form-control"
                                                  value={
                                                    StopData[index]["notes"]
                                                  }
                                                  disabled={this.state.readonly}
                                                  onChange={(e) => {
                                                    var stop = StopData;
                                                    stop[index]["notes"] =
                                                      e.target.value;
                                                    if (this._isMounted) {
                                                      this.setState({
                                                        StopData: stop,
                                                      });
                                                    }
                                                  }}
                                                ></textarea>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })}
                                </SortableContainer>
                                <div className="form-group row mt-2">
                                  <div className="col-md-6">
                                    <button
                                      type="button"
                                      className="btn green-meadow"
                                      disabled={
                                        !this.state.isStopsDetailsEditable
                                      }
                                      onClick={this.addStopChild}
                                    >
                                      Add Stop
                                    </button>
                                  </div>
                                </div>
                                <div className="clearfix"></div>
                                {/* <div className="portlet box mb-3 yellow-gold">
                                  <div className="portlet-title">
                                    <div className="caption">
                                      <i className="fa fa-cubes"></i>Miles Details
                                    </div>
                                  </div> */}
                                <div
                                  className="portlet-body form"
                                  style={{ background: "#E9ECF3" }}
                                >
                                  <div className="form-body max_width800">
                                    <div className="portlet-title">
                                      <h2 className="h3"> Miles Details:</h2>

                                      <div className="clearfix"></div>
                                      <hr />
                                      <div className="form-group row">
                                        <label className="col-md-3 control-label">
                                          Miles:
                                          <span className="font-red-thunderbird"></span>
                                        </label>
                                        <div className="col-md-9">
                                          <input
                                            type="text"
                                            name="milesDetails"
                                            className="form-control"
                                            value={this.state.totalMiles}
                                            disabled
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* </div> */}
                                <br />
                                <div className="clearfix"></div>
                                <div className="text-right">
                                  <button
                                    onClick={this.cancel.bind(this)}
                                    type="button"
                                    className="btn default"
                                  >
                                    Cancel
                                  </button>
                                  &nbsp;
                                  {role !== tenantUser ? (
                                    <button
                                      disabled={
                                        !this.state.isStopsDetailsEditable
                                      }
                                      type="submit"
                                      className="btn green-meadow"
                                    >
                                      {this.state.whileSave === false ? (
                                        <i className="fa fa-save"></i>
                                      ) : (
                                        <i className="fa fa-circle-o-notch fa-spin"></i>
                                      )}
                                      Save
                                    </button>
                                  ) : null}
                                </div>
                                <br />
                              </div>
                            </div>
                          </div>
                        </form>{" "}
                      </div>
                      <div className="tab-pane " id="tab_1">
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        {showSelectedRowOnTabsBoolean ? (
                          <>
                            <div className="showRowForEdit">
                              <span className="showRowForEdit-items">
                                {
                                  showSelectedRowOnTabs.customerLoad
                                    .internalLoadNo
                                }
                              </span>
                              <span className="showRowForEdit-items">
                                {showSelectedRowOnTabs.customer.customerName}
                              </span>
                              <span className="showRowForEdit-items">
                                {
                                  showSelectedRowOnTabs.customerLoad
                                    .customerLoadNo
                                }
                              </span>
                              <span className="showRowForEdit-items">
                                {showSelectedRowOnTabs.carrier
                                  ? showSelectedRowOnTabs.carrier.carrierName
                                  : ""}
                              </span>
                              <span className="showRowForEdit-items">
                                {showRowPickupDate}
                              </span>
                              <span className="showRowForEdit-items">
                                {showRowPickupCityState}
                              </span>
                              <span className="showRowForEdit-items">
                                {showRowDeliveryDate}
                              </span>
                              <span className="showRowForEdit-items">
                                {showRowDeliveryCityState}
                              </span>
                              <span className="showRowForEdit-items">
                                {showSelectedRowOnTabs.customerLoad.loadStatus}
                              </span>
                            </div>
                          </>
                        ) : null}
                        <div className="portlet box yellow-gold">
                          <div className="portlet-title">
                            <div className="caption">
                              <i className="fa fa-file"></i> Documents
                            </div>
                          </div>
                          <div className="portlet-body form">
                            <form
                              action="#"
                              className="horizontal-form"
                              _lpchecked="1"
                            >
                              <div className="form-body max_width800">
                                {Documents.map((element, index) => {
                                  return (
                                    <div
                                      className="form-group row"
                                      key={element.documentNo}
                                    >
                                      <label className="col-md-4 control-label">
                                        {element.label}
                                      </label>
                                      <div className="col-md-8 fileinput ">
                                        <div className="input-group input-large doc_custom">
                                          <div
                                            className="form-control uneditable-input input-fixed input-medium"
                                            data-trigger="fileinput"
                                          >
                                            <i className="fa fa-file fileinput-exists"></i>
                                            &nbsp;
                                            <span className="fileinput-filename">
                                              {element.fileName}
                                            </span>
                                          </div>
                                          {role !== tenantUser ||
                                            (role == tenantUser &&
                                              subRole == tenantSubRole) ? (
                                            <span className="input-group-addon btn default btn-file">
                                              <span className="fileinput-new">
                                                Upload file
                                              </span>
                                              <input
                                                type="file"
                                                onChange={(e) => {
                                                  e.preventDefault();
                                                  if (e.target.files[0]) {
                                                    Documents[index]["file"] =
                                                      e.target.files[0];
                                                    this.setState({
                                                      Documents,
                                                    });
                                                    this.onFileChange(
                                                      index,
                                                      Documents[index]
                                                    );
                                                  }
                                                }}
                                              />
                                            </span>
                                          ) : null}
                                          {element.showIcons ? (
                                            <button
                                              hidden={
                                                element.fileName ? false : true
                                              }
                                              className="input-group-addon btn green fileinput-exists"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                this.download(
                                                  index,
                                                  Documents[index]
                                                );
                                              }}
                                            >
                                              <i
                                                style={{ color: "#fff" }}
                                                className="fa fa-eye"
                                              ></i>
                                            </button>
                                          ) : (
                                            <button
                                              className="input-group-addon btn green fileinput-exists"
                                              onClick={(e) =>
                                                e.preventDefault()
                                              }
                                            >
                                              <i
                                                style={{ color: "#fff" }}
                                                className="fa fa-circle-o-notch fa-spin"
                                              ></i>
                                            </button>
                                          )}

                                          {role !== tenantUser ||
                                            (role == tenantUser &&
                                              subRole == tenantSubRole) ? (
                                            <button
                                              hidden={
                                                element.fileName ? false : true
                                              }
                                              className="input-group-addon btn red fileinput-exists"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                this.deleteFile(
                                                  index,
                                                  Documents[index]
                                                );
                                              }}
                                            >
                                              <i
                                                style={{ color: "#fff" }}
                                                className="fa fa-trash"
                                              ></i>
                                            </button>
                                          ) : null}
                                        </div>
                                        <div
                                          style={{ color: "red" }}
                                          className="errorMsg"
                                        >
                                          {element.error}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}

                                {role !== tenantUser ? (
                                  <div className="">
                                    <a
                                      data-toggle="modal"
                                      href="#doc_pop"
                                      className="btn green-meadow"
                                    >
                                      <span
                                        className="md-click-circle md-click-animate"
                                        style={{
                                          height: "72px",
                                          width: "72px",
                                          top: "23px",
                                          left: "2px",
                                        }}
                                      ></span>
                                      <i className="fa fa-plus"></i> Add
                                    </a>
                                  </div>
                                ) : null}
                              </div>
                              <div className="clearfix"></div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane " id="tab_2">
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        {showSelectedRowOnTabsBoolean ? (
                          <>
                            <div className="showRowForEdit">
                              <span className="showRowForEdit-items">
                                {
                                  showSelectedRowOnTabs.customerLoad
                                    .internalLoadNo
                                }
                              </span>
                              <span className="showRowForEdit-items">
                                {showSelectedRowOnTabs.customer.customerName}
                              </span>
                              <span className="showRowForEdit-items">
                                {
                                  showSelectedRowOnTabs.customerLoad
                                    .customerLoadNo
                                }
                              </span>
                              <span className="showRowForEdit-items">
                                {showSelectedRowOnTabs.carrier
                                  ? showSelectedRowOnTabs.carrier.carrierName
                                  : ""}
                              </span>
                              <span className="showRowForEdit-items">
                                {showRowPickupDate}
                              </span>
                              <span className="showRowForEdit-items">
                                {showRowPickupCityState}
                              </span>
                              <span className="showRowForEdit-items">
                                {showRowDeliveryDate}
                              </span>
                              <span className="showRowForEdit-items">
                                {showRowDeliveryCityState}
                              </span>
                              <span className="showRowForEdit-items">
                                {showSelectedRowOnTabs.customerLoad.loadStatus}
                              </span>
                            </div>
                          </>
                        ) : null}
                        {this.state.loadid && loadFormData ? (
                          <DispatchTab
                            loadid={this.state.loadid}
                            stopData={StopData}
                            handleDispatchStopsCheckBox={
                              this.handleDispatchStopsCheckBox
                            }
                            resetDispatchStopsCheckBox={
                              this.resetDispatchStopsCheckBox
                            }
                            dispatchExistanceHandler={
                              this.dispatchExistanceHandler
                            }
                            checkedDispatchStopsCheckBox={
                              this.checkedDispatchStopsCheckBox
                            }
                            loadNo={loadFormData.internalLoadNo}
                            freightType={this.state.freightType}
                            temperatureType={this.state.temperatureType}
                            cycle={this.state.cycle}
                            temp={loadFormData.temperature}
                            role={role}
                          />
                        ) : null}
                      </div>
                      <div className="tab-pane " id="tab_3">
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        {showSelectedRowOnTabsBoolean ? (
                          <>
                            <div className="showRowForEdit">
                              <span className="showRowForEdit-items">
                                {
                                  showSelectedRowOnTabs.customerLoad
                                    .internalLoadNo
                                }
                              </span>
                              <span className="showRowForEdit-items">
                                {showSelectedRowOnTabs.customer.customerName}
                              </span>
                              <span className="showRowForEdit-items">
                                {
                                  showSelectedRowOnTabs.customerLoad
                                    .customerLoadNo
                                }
                              </span>
                              <span className="showRowForEdit-items">
                                {showSelectedRowOnTabs.carrier
                                  ? showSelectedRowOnTabs.carrier.carrierName
                                  : ""}
                              </span>
                              <span className="showRowForEdit-items">
                                {showRowPickupDate}
                              </span>
                              <span className="showRowForEdit-items">
                                {showRowPickupCityState}
                              </span>
                              <span className="showRowForEdit-items">
                                {showRowDeliveryDate}
                              </span>
                              <span className="showRowForEdit-items">
                                {showRowDeliveryCityState}
                              </span>
                              <span className="showRowForEdit-items">
                                {showSelectedRowOnTabs.customerLoad.loadStatus}
                              </span>
                            </div>
                          </>
                        ) : null}
                        {loadid ? (
                          <InvoiceTab
                            additionalCharges={additionalCharges}
                            Documents={[...Documents]}
                            totalAmount={totalAmount}
                            submitForm={this.submitInvoiceForm}
                            resetAdditionalChargesPopUp={
                              this.resetAdditionalChargesPopUp
                            }
                            addCharges={this.addInvoiceCharges}
                            deleteCharges={this.deleteInvoiceCharges}
                            setPaymentType={this.setInvoicePaymentType}
                            handleInputChange={
                              this.handleInvoicePopUpInputChange
                            }
                            InputValues={invoicePopUpInputValues}
                            download={this.download}
                            handleCheckBox={this.handleInvoiceDocsCheckBox}
                            paymentType={paymentType}
                            invoiceURL={invoiceURL}
                            deleteInvoice={this.deleteInvoice}
                            invoiceLoader={invoiceLoader}
                            loadRate={loadFormData["rate"]}
                            invoiceFileName={invoiceFileName}
                            quickPay={quickPay}
                            inputQuickPay={this.inputQuickPay}
                            invoiceTo={invoiceTo}
                            setInvoiceTo={this.setInvoiceTo}
                            factoringCompany={
                              this.state.carrierdata.factoringCompany
                            }
                            invoiceStatus={this.state.invoiceStatus}
                            setInvoiceStatus={this.setInvoiceStatus}
                            role={role}
                          />
                        ) : null}
                      </div>
                      <div className="tab-pane " id="tab_4">
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        {showSelectedRowOnTabsBoolean ? (
                          <>
                            <div className="showRowForEdit">
                              <span className="showRowForEdit-items">
                                {
                                  showSelectedRowOnTabs.customerLoad
                                    .internalLoadNo
                                }
                              </span>
                              <span className="showRowForEdit-items">
                                {showSelectedRowOnTabs.customer.customerName}
                              </span>
                              <span className="showRowForEdit-items">
                                {
                                  showSelectedRowOnTabs.customerLoad
                                    .customerLoadNo
                                }
                              </span>
                              <span className="showRowForEdit-items">
                                {showSelectedRowOnTabs.carrier
                                  ? showSelectedRowOnTabs.carrier.carrierName
                                  : ""}
                              </span>
                              <span className="showRowForEdit-items">
                                {showRowPickupDate}
                              </span>
                              <span className="showRowForEdit-items">
                                {showRowPickupCityState}
                              </span>
                              <span className="showRowForEdit-items">
                                {showRowDeliveryDate}
                              </span>
                              <span className="showRowForEdit-items">
                                {showRowDeliveryCityState}
                              </span>
                              <span className="showRowForEdit-items">
                                {showSelectedRowOnTabs.customerLoad.loadStatus}
                              </span>
                            </div>
                          </>
                        ) : null}
                        <div className="portlet box yellow-gold">
                          <div className="portlet-title">
                            <div className="caption">
                              <i className="fa fa-dollar"></i>Billing Details
                            </div>
                            {role !== tenantUser ? (
                              <div
                                className="actions"
                                style={
                                  this.state.billingInfoReadOnly ? {} : nonclick
                                }
                                id="editBillingDiv"
                              >
                                <div
                                  className="btn-group"
                                  style={
                                    this.state.billingInfoHidden === false
                                      ? msgShow
                                      : msgNone
                                  }
                                >
                                  <button
                                    className="btn sbold white dark"
                                    onClick={this.EditBillingForm.bind(this)}
                                    hidden={this.state.billingInfoHidden}
                                    disabled={this.state.billingInfoHidden}
                                  >
                                    <i className="fa fa-pencil"></i> Edit
                                  </button>
                                </div>
                              </div>
                            ) : null}
                          </div>
                          <div className="portlet-body form">
                            <form
                              onSubmit={this.submitBillingRegistrationForm.bind(
                                this
                              )}
                              className="horizontal-form"
                              autoComplete="off"
                            >
                              <div className="form-body max_width800">
                                <div className="portlet-title">
                                  <h2 className="h3">Billing Info</h2>

                                  <div className="clearfix"></div>
                                  <hr />
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">
                                    Load #:
                                    <span className="font-red-thunderbird"></span>
                                  </label>
                                  <div className="col-md-9">
                                    <input
                                      type="text"
                                      ref="billingLoad"
                                      name="billingLoad"
                                      className="form-control"
                                      placeholder=""
                                      defaultValue={this.state.billingLoad}
                                      readOnly={true}
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">
                                    Customers Load #:
                                  </label>
                                  <div className="col-md-9">
                                    <input
                                      type="text"
                                      ref="billingCustomsLoad"
                                      name="billingCustomsLoad"
                                      className="form-control"
                                      placeholder=""
                                      defaultValue={
                                        this.state.billingCustomsLoad
                                      }
                                      readOnly={true}
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">
                                    Rate:
                                    <span className="font-red-thunderbird"></span>
                                  </label>
                                  <div className="col-md-9">
                                    <CurrencyInput
                                      precision="2"
                                      prefix="$"
                                      ref="billingRate"
                                      name="billingRate"
                                      value={this.state.billingRate}
                                      onChangeEvent={(
                                        event,
                                        maskedvalue,
                                        floatvalue
                                      ) => {
                                        loadFormData["rate"] = floatvalue;
                                        this.setState({
                                          billingRate: floatvalue,
                                          loadFormData,
                                        });
                                      }}
                                      className="form-control"
                                      readOnly={this.state.billingInfoReadOnly}
                                      placeholder=""
                                    />

                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.billingRate}
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">
                                    Dispatch Fee Type:{" "}
                                    <span className="font-red-thunderbird"></span>
                                  </label>
                                  <div className="col-md-9">
                                    <select
                                      className="form-control"
                                      ref="dispatchType"
                                      disabled={this.state.billingInfoReadOnly}
                                      onChange={(e) => {
                                        e.preventDefault();
                                        var dispatchType = Number(
                                          e.target.value
                                        );
                                        this.setState({ dispatchType });
                                      }}
                                      value={this.state.dispatchType}
                                    >
                                      {this.state.dispatchTypeOptions.map(
                                        function (data) {
                                          return (
                                            <option value={data.id}>
                                              {data.name}
                                            </option>
                                          );
                                        }
                                      )}
                                    </select>
                                  </div>
                                </div>
                                {this.state.dispatchType === 2 ? (
                                  <div className="form-group row">
                                    <label className="col-md-3 control-label">
                                      Dispatch Percentage
                                      <span className="font-red-thunderbird"></span>
                                    </label>
                                    <div className="col-md-4">
                                      <CurrencyInput
                                        precision="2"
                                        suffix="%"
                                        ref="dispatchPercentage"
                                        value={this.state.dispatchPercentage}
                                        className="form-control"
                                        readOnly={
                                          this.state.billingInfoReadOnly
                                        }
                                        placeholder=""
                                        onChangeEvent={(
                                          event,
                                          maskedvalue,
                                          floatvalue
                                        ) => {
                                          var dispatchPercentage = floatvalue;
                                          var suggestedAmount = (dispatchPercentage / 100) * this.state.billingRate;
                                          this.setState({
                                            dispatchPercentage: dispatchPercentage,
                                            suggestedAmount: suggestedAmount,
                                          });
                                          console.log(this.state.dispatchPercentage, this.state.billingAmount);
                                        }}
                                      />
                                      <div
                                        style={{ color: "red" }}
                                        className="errorMsg"
                                      >
                                        {this.state.errors.dispatchPercentage}
                                      </div>
                                    </div>
                                    <label className="col-md-2 control-label">
                                        Estimated Price:
                                        <span className="font-red-thunderbird"></span>
                                      </label>
                                      <div className="col-md-3">
                                        <CurrencyInput
                                          precision="2"
                                          suffix="$"
                                          value={this.state.suggestedAmount}
                                          placeholder=""
                                          className="form-control"
                                          disabled
                                        />
                                      </div>
                                  </div>
                                ) : (
                                  <div className="form-group row">
                                    <label className="col-md-3 control-label">
                                      Dispatch Fee
                                      <span className="font-red-thunderbird"></span>
                                    </label>
                                    <div className="col-md-9">
                                      <CurrencyInput
                                        precision="2"
                                        prefix="$"
                                        ref="dispatchFee"
                                        value={this.state.dispatchFee}
                                        className="form-control"
                                        readOnly={
                                          this.state.billingInfoReadOnly
                                        }
                                        placeholder=""
                                        onChangeEvent={(
                                          event,
                                          maskedvalue,
                                          floatvalue
                                        ) => {
                                          var dispatchFee = floatvalue;
                                          this.setState({ dispatchFee });
                                        }}
                                      />
                                      <div
                                        style={{ color: "red" }}
                                        className="errorMsg"
                                      >
                                        {this.state.errors.dispatchFee}
                                      </div>
                                    </div>
                                  </div>
                                )}
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">
                                    Billing Amount:
                                    <span className="font-red-thunderbird"></span>
                                  </label>
                                  <div className="col-md-9">
                                    <CurrencyInput
                                      precision="2"
                                      prefix="$"
                                      name="billingAmount"
                                      ref="billingAmount"
                                      value={this.state.billingAmount}
                                      className="form-control"
                                      // readOnly={
                                      //   this.state.billingInfoReadOnly
                                      // }
                                      placeholder=""
                                      onChangeEvent={(
                                        event,
                                        maskedvalue,
                                        floatvalue
                                      ) => {
                                        console.log(floatvalue);
                                        // var billingAmount = floatvalue;
                                        // var dispatchPercentage = (billingAmount / this.state.billingRate) * 100;
                                        // console.log("Rate ===============>", this.state.billingRate)
                                        // console.log("Dispatch Percentage ================>", dispatchPercentage);
                                        // console.log("Billing Amount ================>", billingAmount);

                                        this.setState({
                                          billingAmount: floatvalue,
                                          // dispatchPercentage: dispatchPercentage,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">
                                    Billing Status:
                                    <span className="font-red-thunderbird"></span>
                                  </label>
                                  <div className="col-md-9">
                                    <select
                                      ref="billingStatus"
                                      billingStatus
                                      value={
                                        this.state.billingStatus
                                          ? this.state.billingStatus
                                          : "Pending"
                                      }
                                      className="form-control"
                                      disabled={this.state.billingInfoReadOnly}
                                      onChange={(e) => {
                                        this.setState({
                                          billingStatus: e.target.value,
                                        });
                                      }}
                                    >
                                      <option value="Pending">Pending</option>
                                      <option value="Billed">Billed</option>
                                      <option value="Paid">Paid</option>
                                    </select>

                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.billingStatus}
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">
                                    Invoice #:
                                    <span className="font-red-thunderbird"></span>
                                  </label>
                                  <div className="col-md-9">
                                    <input
                                      type="text"
                                      ref="billingInvoice"
                                      name="billingInvoice"
                                      className="form-control"
                                      placeholder=""
                                      defaultValue={this.state.billingInvoice}
                                      readOnly={this.state.billingInfoReadOnly}
                                      onChange={this.handleChange}
                                    />
                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.billingInvoice}
                                    </div>
                                  </div>
                                </div>
                                {role !== "Admin" ? (
                                  <div className="form-group row">
                                    <label className="col-md-3 control-label"></label>
                                    <div className="col-md-9">
                                      <div className="custom-control custom-checkbox">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          style={{
                                            marginRight: "6px",
                                            transform: "scale(1.2)",
                                          }}
                                          id="customCheck1"
                                          disabled={
                                            this.state.billingInfoReadOnly
                                          }
                                          onChange={() => {
                                            this.setState({
                                              billingVerified: !this.state
                                                .billingVerified,
                                            });
                                          }}
                                          checked={this.state.billingVerified}
                                        />
                                        <label
                                          className="custom-control-label"
                                          for="customCheck1"
                                        >
                                          Billing Verified
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                <div className="clearfix"></div>
                                <div className="text-right">
                                  <button
                                    onClick={this.cancel.bind(this)}
                                    type="button"
                                    className="btn default"
                                  >
                                    Cancel
                                  </button>
                                  &nbsp;
                                  {role !== tenantUser ? (
                                    <button
                                      disabled={this.state.billingInfoReadOnly}
                                      type="submit"
                                      className="btn green-meadow"
                                    >
                                      {this.state.whileSave === false ? (
                                        <i className="fa fa-save"></i>
                                      ) : (
                                        <i className="fa fa-circle-o-notch fa-spin"></i>
                                      )}
                                      Save
                                    </button>
                                  ) : null}
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane " id="tab_5">
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        {showSelectedRowOnTabsBoolean ? (
                          <>
                            <div className="showRowForEdit">
                              <span className="showRowForEdit-items">
                                {
                                  showSelectedRowOnTabs.customerLoad
                                    .internalLoadNo
                                }
                              </span>
                              <span className="showRowForEdit-items">
                                {showSelectedRowOnTabs.customer.customerName}
                              </span>
                              <span className="showRowForEdit-items">
                                {
                                  showSelectedRowOnTabs.customerLoad
                                    .customerLoadNo
                                }
                              </span>
                              <span className="showRowForEdit-items">
                                {showSelectedRowOnTabs.carrier
                                  ? showSelectedRowOnTabs.carrier.carrierName
                                  : ""}
                              </span>
                              <span className="showRowForEdit-items">
                                {showRowPickupDate}
                              </span>
                              <span className="showRowForEdit-items">
                                {showRowPickupCityState}
                              </span>
                              <span className="showRowForEdit-items">
                                {showRowDeliveryDate}
                              </span>
                              <span className="showRowForEdit-items">
                                {showRowDeliveryCityState}
                              </span>
                              <span className="showRowForEdit-items">
                                {showSelectedRowOnTabs.customerLoad.loadStatus}
                              </span>
                            </div>
                          </>
                        ) : null}
                        <div className="portlet box yellow-gold">
                          <div className="portlet-title">
                            <div className="caption">
                              <i className="fa fa-dollar"></i>Accounting Details
                            </div>
                            {role !== tenantUser ? (
                              <div
                                className="actions"
                                style={
                                  this.state.accountingInfoReadOnly
                                    ? {}
                                    : nonclick
                                }
                                id="editAccountingDiv"
                              >
                                <div
                                  className="btn-group"
                                  style={
                                    this.state.accountingInfoHidden === false
                                      ? msgShow
                                      : msgNone
                                  }
                                >
                                  <button
                                    className="btn sbold white dark"
                                    onClick={this.EditAccountingForm.bind(this)}
                                    hidden={this.state.accountingInfoHidden}
                                    disabled={this.state.accountingInfoHidden}
                                  >
                                    <i className="fa fa-pencil"></i> Edit
                                  </button>
                                </div>
                              </div>
                            ) : null}
                          </div>
                          <div className="portlet-body form">
                            <form
                              onSubmit={this.submitAccountingRegistrationForm.bind(
                                this
                              )}
                              className="horizontal-form"
                              autoComplete="off"
                            >
                              <div className="form-body max_width800">
                                <div className="portlet-title">
                                  <h2 className="h3">Accounting</h2>

                                  <div className="clearfix"></div>
                                  <hr />
                                </div>

                                <div className="form-group row">
                                  <label className="col-md-3 control-label">
                                    Load #:
                                    <span className="font-red-thunderbird"></span>
                                  </label>
                                  <div className="col-md-9">
                                    <input
                                      type="text"
                                      ref="accountingLoad"
                                      name="accountingLoad"
                                      className="form-control"
                                      placeholder=""
                                      defaultValue={this.state.accountingLoad}
                                      readOnly={true}
                                    />
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="col-md-3 control-label">
                                    Customers Load #:
                                  </label>
                                  <div className="col-md-9">
                                    <input
                                      type="text"
                                      ref="accountingCustomsLoad"
                                      name="accountingCustomsLoad"
                                      className="form-control"
                                      placeholder=""
                                      defaultValue={
                                        this.state.accountingCustomsLoad
                                      }
                                      readOnly={true}
                                    />
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="col-md-3 control-label">
                                    Rate:
                                    <span className="font-red-thunderbird"></span>
                                  </label>
                                  <div className="col-md-9">
                                    <CurrencyInput
                                      precision="2"
                                      prefix="$"
                                      ref="accountingRate"
                                      name="accountingRate"
                                      value={this.state.accountingRate}
                                      onChangeEvent={(
                                        event,
                                        maskedvalue,
                                        floatvalue
                                      ) => {
                                        loadFormData["rate"] = floatvalue;
                                        this.setState({
                                          accountingRate: floatvalue,
                                          loadFormData,
                                        });
                                      }}
                                      className="form-control"
                                      readOnly={
                                        this.state.accountingInfoReadOnly
                                      }
                                      placeholder=""
                                    />

                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.accountingRate}
                                    </div>
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="col-md-3 control-label"></label>
                                  <div className="col-md-9">
                                    <div className="custom-control custom-checkbox">
                                      <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        style={{
                                          marginRight: "6px",
                                          transform: "scale(1.2)",
                                        }}
                                        id="customCheck1"
                                        disabled={
                                          this.state.accountingInfoReadOnly
                                        }
                                        onChange={() => {
                                          this.setState({
                                            accountingVerified: !this.state
                                              .accountingVerified,
                                          });
                                        }}
                                        checked={this.state.accountingVerified}
                                      />
                                      <label
                                        className="custom-control-label"
                                        for="customCheck1"
                                      >
                                        Accounting Verified
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="clearfix"></div>
                              <div className="form-actions right">
                                <button
                                  onClick={this.cancel.bind(this)}
                                  type="button"
                                  className="btn default"
                                >
                                  Cancel
                                </button>
                                &nbsp;
                                {role !== tenantUser ? (
                                  <button
                                    disabled={this.state.accountingInfoReadOnly}
                                    type="submit"
                                    className="btn green-meadow"
                                  >
                                    {this.state.whileSave === false ? (
                                      <i className="fa fa-save"></i>
                                    ) : (
                                      <i className="fa fa-circle-o-notch fa-spin"></i>
                                    )}
                                    Save
                                  </button>
                                ) : null}
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane " id="tab_6">
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <div className="portlet box yellow-gold">
                          <div className="portlet-title">
                            <div className="caption">
                              <i className="fa fa-dollar"></i>Audit Trail
                            </div>
                          </div>
                          <div className="portlet-body form AuditTrailForm">
                            <MUIDataTable
                              title={"Audit Trail"}
                              data={this.state.auditTrailsResponse}
                              columns={columnsTable}
                              options={optionsTable}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="contact"
              data-backdrop="static"
              data-keyboard="false"
              tabIndex="-1"
              aria-hidden="true"
            >
              <div className="modal-dialog  modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      id="closePopUp"
                      data-dismiss="modal"
                      aria-hidden="true"
                      hidden={true}
                    ></button>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-hidden="true"
                      onClick={this.ClearContact}
                    ></button>
                    <h4 className="modal-title">Contact</h4>
                  </div>
                  <div className="modal-body">
                    <div className="portlet-body form">
                      <form
                        onSubmit={this.SaveContact.bind(this)}
                        className="horizontal-form"
                      >
                        <div className="form-body max_width800">
                          <br />

                          <div className="form-group row">
                            <label className="col-md-3 control-label">
                              Name
                              <span className="font-red-thunderbird">*</span>
                            </label>
                            <div className="col-md-9">
                              <input
                                type="text"
                                ref="ContactName"
                                pattern="[a-z A-Z0-9-'@!$#:;,.-_\x22 ]{1,200}"
                                title="Please Enter Contact Name (Max limit: 200)"
                                className="form-control"
                                placeholder=""
                                required
                              />
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-md-3 control-label">
                              Title
                            </label>
                            <div className="col-md-9">
                              <input
                                type="text"
                                ref="ContactTitle"
                                pattern="[a-z A-Z'(),-_.\x22 ]{1,200}"
                                title="Please Enter title (Max limit: 200)"
                                className="form-control"
                                placeholder=""
                              />
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-md-3 control-label">
                              Email
                            </label>
                            <div className="col-md-9">
                              <input
                                type="email"
                                ref="ContactEmail"
                                className="form-control"
                                placeholder=""
                              />
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-md-3 control-label">
                              Telephone
                            </label>
                            <div className="col-md-9 form-inline">
                              <input
                                type="text"
                                ref="ContactTelephone"
                                id="contactTelephone"
                                pattern="[a-z A-Z0-9- ]{12}"
                                title="Please Enter Full lenght Telephone (12 digits)"
                                onChange={this.AutoFormatContact}
                                className="form-control"
                                placeholder="xxx-xxx-xxxx"
                              />
                              <span className="help-inline"> EXT </span>
                              <input
                                type="text"
                                pattern="[0-9]{1,10}"
                                title="Please Enter extention (Max 10 Digits Only)"
                                ref="ContactExt"
                                className="form-control"
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-md-3 control-label">
                              Cell Phone
                            </label>
                            <div className="col-md-9">
                              <input
                                type="text"
                                ref="ContactPhone"
                                id="contactPhone"
                                pattern="[a-z A-Z0-9- ]{12}"
                                title="Please Enter Full lenght Cellphone (12 digits)"
                                onChange={this.AutoFormatContact}
                                className="form-control"
                                placeholder="xxx-xxx-xxxx"
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-md-3 control-label">
                              Fax
                            </label>
                            <div className="col-md-9">
                              <input
                                type="text"
                                ref="ContactFax"
                                id="contactFax"
                                pattern="[a-z A-Z0-9- ]{12}"
                                title="Please Enter Full lenght fax (12 digits)"
                                onChange={this.AutoFormatContact}
                                className="form-control"
                                placeholder="xxx-xxx-xxxx"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="clearfix"></div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn default"
                            onClick={this.ClearContact}
                            data-dismiss="modal"
                          >
                            Cancel
                          </button>
                          &nbsp;
                          <button type="submit" className="btn green-meadow">
                            <i className="fa fa-check"></i> Save
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="customer_info"
              data-backdrop="static"
              data-keyboard="false"
              tabIndex="-1"
              aria-hidden="true"
            >
              <div className="modal-dialog  modal-lg">
                <Customer />
              </div>
            </div>

            <div
              className="modal fade"
              id="shipper_info"
              data-backdrop="static"
              data-keyboard="false"
              tabIndex="-1"
              aria-hidden="true"
            >
              <div className="modal-dialog  modal-lg">
                <AddShipper />
              </div>
            </div>

            <div
              className="modal fade"
              id="carrier_info"
              data-backdrop="static"
              data-keyboard="false"
              tabIndex="-1"
              aria-hidden="true"
            >
              <div className="modal-dialog  modal-lg">
                <Addcarrier />
              </div>
            </div>
            <div
              className="modal fade"
              id="doc_pop"
              data-backdrop="static"
              data-keyboard="false"
              tabIndex="-1"
              aria-hidden="true"
            >
              <div className="modal-dialog  modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-hidden="true"
                      onClick={() => (this.refs.insertDocType.value = "1")}
                    ></button>
                    <h4 className="modal-title">Documents</h4>
                  </div>
                  <div className="modal-body">
                    <div className="form-group row">
                      <label className="col-md-3 control-label">
                        Documents Type:
                      </label>
                      <div className="col-md-8">
                        <select
                          className="form-control"
                          defaultValue="1"
                          ref="insertDocType"
                        >
                          <option value="2">BOL (Bill of Loading)</option>
                          <option value="1">Rate Sheet</option>
                          <option value="3">POD (Proof of Delivery)</option>
                          <option value="4">Lumper Receipt</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn default"
                      data-dismiss="modal"
                      onClick={() => (this.refs.insertDocType.value = "1")}
                    >
                      Cancel
                    </button>
                    &nbsp;
                    <button
                      type="submit"
                      className="btn green-meadow"
                      data-dismiss="modal"
                      onClick={(e) => {
                        e.preventDefault();
                        var docType = this.refs.insertDocType.value;
                        var label =
                          docType === "1"
                            ? "Rate Sheet"
                            : docType === "2"
                              ? "BOL (Bill of Loading)"
                              : docType === "3"
                                ? "POD (Proof of Delivery)"
                                : "Lumper Receipt";
                        var Documents = this.state.Documents;
                        var docNo = Documents.length + 1;
                        var obj = {
                          label: label,
                          docType: docType,
                          documentNo: docNo,
                          fileName: "",
                          error: "",
                          showIcons: true,
                          file: "",
                          s3FileKey: "",
                          checked: false,
                          documentId: "",
                          invoiceError: "",
                          fileExtension: "",
                        };
                        this.refs.insertDocType.value = "1";

                        Documents.push(obj);

                        this.setState({ Documents });
                      }}
                    >
                      <i className="fa fa-check"></i> Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(EditLoad);
