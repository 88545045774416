/* eslint-disable */ 

import React, { Component } from 'react';
import {Redirect  } from "react-router-dom";
import logo from './Logo.png';

class Header extends Component {

  _isMounted=false;
  constructor(props){
    super(props);
    this.state = {
        logout: false,
        userName:''

    };
    
}
componentDidMount() {     
  this._isMounted=true; 
  const name =localStorage.getItem('name') ; 
  if(name && this._isMounted)
  {
    this.setState({userName:name})
  } 
}

componentWillUnmount(){
  this._isMounted=false
}

  async logout(e){
    
    e.preventDefault();
    
    await localStorage.removeItem('Token');

    this.setState({
      logout: true
    })
  }
  
  render() {

    if(this.state.logout){
      return <Redirect to='/' />
    }

    return (
      <div>
<title>Bridge18 {this.props.title} </title>
<div className="page-header navbar navbar-fixed-top bg-blue-chambray"> 

  <div className="page-header-inner "> 
  
    <div className="page-logo"> <a> <img src={logo}/> </a>
      <div className="menu-toggler sidebar-toggler"> 
      
      </div>
    </div>
   
    {/* <a href="javascript:;" className="menu-toggler responsive-toggler" data-toggle="collapse" data-target=".navbar-collapse"> </a>  */}
 
    <div className="page-top">
      <div className="top-menu">
        <ul className="nav navbar-nav pull-right">
          
          <li className="dropdown dropdown-user dropdown-dark"> <a href="javascript:;" className="dropdown-toggle" data-toggle="dropdown" data-hover="dropdown" data-close-others="true"> <span className="username username-hide-on-mobile"> {this.state.userName} </span> 
          
            <img alt="" className="img-circle" src="../assets/layouts/layout4/img/avatar9.jpg" /> </a>
            <ul className="dropdown-menu dropdown-menu-default">
              <li> <a href="/" onClick={this.logout.bind(this)}> <i className="icon-key"></i> Log Out </a> </li>
             
            </ul>
          </li>
        
        </ul>
      </div>
    
    </div>
  
  </div>
 
 </div>
</div>
    );
  }
}

export default Header;
