/* eslint-disable */

import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import $ from 'jquery';
import BaseURL from './config';
import DatePicker from "react-datepicker";
import './date.css';
import TokenErrorMsg from './errorMsg';


class AddTruck extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            status: 1,
            make: [],
            states: [],
            truckid: '',
            whileSave: false,
            readonly: false,
            hidden: true,
            isLoading: false,
            token: '',
            fields: {},
            errors: {},
            carrier: [],
            carrierdata: '',
            LicExpDate: new Date(),
            file: {
                License: '',
            },
            Licenseload: true,

        };
        this.handleChange = this.handleChange.bind(this);
        this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);
        this.submit = this.submit.bind(this);
        this.validateForm = this.validateForm.bind(this);
    }


    async componentDidMount() {

        this._isMounted = true;
        var token = localStorage.getItem('Token');
        if (this._isMounted) {
            this.setState({ 'token': token });
        }
        var arr = {
            'make': [],
            'states': []
        };

        await axios.post(BaseURL + 'truck/add', { 'token': token })
            .then(response => {
                if (response.data.error === TokenErrorMsg) {
                    localStorage.setItem('Token', '');
                    this.props.history.push('/');
                }

                arr = response.data.data;

            }).catch(function (error) {
                NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);
                console.log(error);
            })

        if (this._isMounted) {
            this.setState({ make: arr.make, states: arr.states });
        }



    }

    componentWillUnmount() {
        this._isMounted = false;

    }



    async handleChange(e) {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        if (this._isMounted) {
            await this.setState({
                fields
            });
        }
        this.validateForm();
    }

    submituserRegistrationForm(e) {
        e.preventDefault();
        var send = false;

        if (this.validateAndSubmit() && this.validateForm()) {

            send = true;

        }
        if (send) {

            this.submit();
        }

    }


    validateAndSubmit() {


        let errors = {};
        let formIsValid = true;       

        // CustomerNAme
        if (!this.refs.truck.value || !this.refs.truck.value.trim().length > 0) {
            formIsValid = false;
            errors["truck"] = "*Please Enter Truck #";
            $("[name='truck']").focus();

        }     


        else if (this.refs.make.value === 'null') {
            formIsValid = false;
            errors["make"] = "*Please Select Make";
            $("[name='make']").focus();

        }

        else if (!this.refs.driverLicense.value || !this.refs.driverLicense.value.trim().length > 0) {

            formIsValid = false;
            errors["DriverLicense"] = "*Please Enter License No";
            $("[name='DriverLicense']").focus();

        }

        else if (this.refs.province_id.value === 'null') {
            formIsValid = false;
            errors["state"] = "*Please Select State";
            $("[name='state']").focus();

        }




        if (this._isMounted) {
            this.setState({
                errors: errors
            });
        }
        return formIsValid;
    }

    validateForm() {

        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;



        if (typeof fields["truck"] !== "undefined" && fields["truck"]) {
            if (!fields["truck"].match(/^[a-zA-Z0-9#'".,-/ ]{1,200}$/)) {
                formIsValid = false;
                errors["truck"] = "*Only Alphanumeric Allowed (Max Range: 200)";
            }
        }

        if (typeof fields["color"] !== "undefined" && fields["color"]) {
            if (!fields["color"].match(/^[a-zA-Z0-9#'".,-/ ]{1,200}$/)) {
                formIsValid = false;
                errors["color"] = "*Only Alphanumeric Allowed (Max Range: 200)";
            }
        }

        if (typeof fields["model"] !== "undefined" && fields["model"]) {
            if (!fields["model"].match(/^[a-zA-Z0-9#'".,-/ ]{1,200}$/)) {
                formIsValid = false;
                errors["model"] = "*Only Alphanumeric Allowed (Max Range: 200)";
            }
        }

        if (typeof fields["year"] !== "undefined" && fields["year"]) {
            if (!fields["year"].match(/^[a-zA-Z0-9#'".,-/ ]{1,200}$/)) {
                formIsValid = false;
                errors["year"] = "*Only Alphanumeric Allowed (Max Range: 200)";
            }
        }

        if (typeof fields["vin"] !== "undefined" && fields["vin"]) {
            if (!fields["vin"].match(/^[a-zA-Z0-9#'".,-/ ]{1,200}$/)) {
                formIsValid = false;
                errors["vin"] = "*Only Alphanumeric Allowed (Max Range: 200)";
            }
        }

        if (typeof fields["DriverLicense"] !== "undefined" && fields["DriverLicense"]) {
            if (!fields["DriverLicense"].match(/^[a-zA-Z0-9'-.#$ ]{1,30}$/)) {
                formIsValid = false;
                errors["DriverLicense"] = "*Please Enter Valid License  (Max Range: 30)";
            }
        }

        if (typeof this.state.LicExpDate !== "undefined" && this.state.LicExpDate) {
            if (this.state.LicExpDate.getFullYear() < 2000 || this.state.LicExpDate.getFullYear() > 2099) {
                formIsValid = false;
                errors["LicExpDate"] = "*Enter Valid Date ( Year Between 2000-2099 )";
            }
        }


        if (this._isMounted) {
            this.setState({
                errors: errors
            });
        }
        return formIsValid;


    }


    async submit() {

        if (this._isMounted) {
            this.setState({ whileSave: true })
        }


        const truckno = this.refs.truck.value.trim();
        const model = this.refs.model.value.trim();
        const year = this.refs.year.value.trim();
        const vin = this.refs.vin.value.trim();
        const color = this.refs.color.value.trim();
        const carrierid = this.props.carrierId;
        const makeid = this.refs.make.value.trim();



        const province_id = this.refs.province_id.value;
        const driverLicense = this.refs.driverLicense.value.trim();



        var truck = {
            'token': this.state.token,
            'status': this.state.status,
            "truckNo": truckno,
            "model": model,
            "year": year,
            "licenseExpirationDate": this.state.LicExpDate,
            "vinNo": vin,
            "color": color,
            "licensePlateNo": driverLicense,
            "licenseStateId": province_id,
            "makeId": makeid,
            "carrierId": carrierid

        }

        this.resetForm();     
        await axios.post(BaseURL + 'truck/save', truck)
            .then(response => {
                if (response.data.error === TokenErrorMsg) {
                    localStorage.setItem('Token', '');
                    this.props.history.push('/');
                }
                console.log(response.data);
                this.props.loadDriverTruckTrailerDropdowns(carrierid);
                NotificationManager.success('New Truck Added Successfully', 'Added', 3000);
            }).catch(function (error) {
                NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);
                console.log(error);
            })
        if (this._isMounted) {
            this.setState({
                whileSave: false,
            });
        }


    }

    resetForm = () => {
        $('#closeTruckPopUp').click();
        document.getElementById('dispatchform').click();
        this.setState({
            LicExpDate: new Date(),
            status: 1,
        });
        this.refs.truckForm.reset();
        $("#truckActiveLabel").addClass('active');
        $("#truckInActiveLabel").removeClass('active');

    }

    render() {

        const msgNone = {
            display: 'none'
        }
        const msgShow = {
            display: 'block',
        }

        const nonclick = {
            cursor: ' notAllowed',
            pointerEvents: 'none'
        }

        var year = [];
        for (var i = 1900; i < 2091; i++) {
            year.push(i);
        }




        return (
            <div className="modal-content">
                <div className="modal-header">
                    <button type="button" id="closeTruckPopUp" data-dismiss="modal" aria-hidden="true" hidden={true}></button>
                    <button type="button" className="close" onClick={this.resetForm} aria-hidden="true"></button>
                    <h4 className="modal-title">Truck Info</h4>
                </div>
                <div className="modal-body">
                    <div className="portlet-body form">

                        <form onSubmit={this.submituserRegistrationForm.bind(this)} ref="truckForm" className="horizontal-form" autoComplete="off" >
                            <div className="form-body max_width800">
                                <div className="portlet-title">
                                    <h2 className="h3"> Truck Info
                              <span style={{ float: 'right', paddingLeft: ' 8px', marginTop: '2px' }} >
                                            <div className="btn-group btn-group-devided" data-toggle="buttons" style={this.state.readonly ? nonclick : msgShow} >
                                                <label className="btn btn-transparent dark btn-outline btn-circle btn-sm active" id="truckActiveLabel" disabled={this.state.readonly} onClick={() => { if (this._isMounted) { this.setState({ status: 1 }) } }}>
                                                    <input type="radio" name="options" className="toggle" id="option1" />
                                                    Active</label>
                                                <label className="btn btn-transparent dark btn-outline btn-circle btn-sm" id="truckInActiveLabel" disabled={this.state.readonly} onClick={() => { if (this._isMounted) { this.setState({ status: 0 }) } }}>
                                                    <input type="radio" name="options1" className="toggle" id="option2" />
                                                    Inactive</label>
                                            </div>
                                        </span>
                                    </h2>

                                    <div className="clearfix"></div>
                                    <hr />
                                </div>

                                <div className="form-group row">
                                    <label className="col-md-3 control-label">Truck #: <span className="font-red-thunderbird" >*</span></label>
                                    <div className="col-md-9">
                                        <input type="text" ref="truck" onChange={this.handleChange} name="truck" className="form-control" placeholder="" readOnly={this.state.readonly} />
                                        <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.truck}</div>
                                    </div>
                                </div>



                                <div className="form-group row">
                                    <label className="col-md-3 control-label">Make: <span className="font-red-thunderbird" >*</span></label>
                                    <div className="col-md-9">
                                        <select name="make" ref="make" className="form-control" defaultValue='null' disabled={this.state.readonly} readOnly={this.state.readonly}>
                                            <option value="null" disabled hidden>Select Make</option>
                                            {
                                                this.state.make.map(function (data) {
                                                    return <option value={data.id} key={data.id}> {data.make} </option>;
                                                })

                                            }
                                        </select>
                                        <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.make}</div>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-md-3 control-label">Model: <span className="font-red-thunderbird" ></span></label>
                                    <div className="col-md-9">
                                        <input type="text" ref="model" onChange={this.handleChange} name="model" className="form-control" placeholder="" readOnly={this.state.readonly} />
                                        <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.model}</div>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-md-3 control-label">Year: <span className="font-red-thunderbird" >*</span></label>
                                    <div className="col-md-9">
                                        <select name="year" ref="year" className="form-control" defaultValue={new Date().getFullYear()} disabled={this.state.readonly} readOnly={this.state.readonly}>
                                            <option value="" disabled hidden>Select Year</option>
                                            {
                                                year.map(function (data) {
                                                    // if(data===new Date().getFullYear() )
                                                    // return <option value={data} selected> {data} </option>;
                                                    return <option value={data} key={data}> {data} </option>;
                                                })

                                            }
                                        </select>
                                        <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.year}</div>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-md-3 control-label">Vin #: <span className="font-red-thunderbird" ></span></label>
                                    <div className="col-md-9">
                                        <input type="text" ref="vin" onChange={this.handleChange} name="vin" className="form-control" placeholder="" readOnly={this.state.readonly} />
                                        <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.vin}</div>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-md-3 control-label">Color: <span className="font-red-thunderbird" ></span></label>
                                    <div className="col-md-9">
                                        <input type="text" ref="color" onChange={this.handleChange} name="color" className="form-control" placeholder="" readOnly={this.state.readonly} />
                                        <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.color}</div>
                                    </div>
                                </div>





                                <div className="portlet-title">
                                    <h2 className="h3 checkbox-inline" style={{ padding: '0', margin: '0' }}> License Plate Information </h2>
                                    <hr />
                                </div>
                                <div className="form-group row">
                                    <label className="col-md-3 control-label">License  Plate No. <span className="font-red-thunderbird" >*</span></label>
                                    <div className="col-md-9">
                                        <input type="text" ref="driverLicense" onChange={this.handleChange} name="DriverLicense" className="form-control" placeholder="" readOnly={this.state.readonly} />
                                        <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.DriverLicense}</div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-md-3 control-label">State <span className="font-red-thunderbird" >*</span></label>
                                    <div className="col-md-9">
                                        <select name="state" ref="province_id" className="form-control" defaultValue='null' disabled={this.state.readonly} readOnly={this.state.readonly}>
                                            <option value="null" disabled hidden>Select State</option>
                                            {
                                                this.state.states.map(function (data) {
                                                    return <option value={data.id} key={data.id}> {data.state} </option>;
                                                })

                                            }
                                        </select>
                                        <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.state}</div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-md-3 control-label">Expiration Date</label>
                                    <div className="col-md-9">

                                        <DatePicker
                                            className='form-control'
                                            selected={this.state.LicExpDate}
                                            onChange={async (date) => {
                                                if (this._isMounted) {
                                                    await this.setState({
                                                        LicExpDate: date
                                                    });
                                                }
                                                console.log(date);
                                                this.validateForm();
                                            }}
                                            readOnly={this.state.readonly} />
                                        <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.LicExpDate}</div>

                                    </div>
                                </div>



                            </div>
                            <div className="clearfix"></div>
                            <div className="form-actions right">
                                <button onClick={this.resetForm} type="button" className="btn default">Cancel</button> &nbsp;
                            <button disabled={this.state.readonly} type="submit" className="btn green-meadow">
                                    {this.state.whileSave === false
                                        ? <i className='fa fa-save'></i>
                                        : <i className="fa fa-circle-o-notch fa-spin"></i>}
                                    Save
                            </button>
                            </div>

                        </form>
                    </div>
                </div>
            </div>



        );
    }
}

export default withRouter(AddTruck);