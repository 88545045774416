/* eslint-disable */

import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Sidebar from './sidebar';
import Header from './Head';
import $ from 'jquery';
import BaseURL from './config';
import TokenErrorMsg from './errorMsg';
import PlacesAutocomplete, {
  geocodeByAddress,

} from 'react-places-autocomplete';
import getEditinfo from './StartEditMode';
import finishEditinfo from './FinishEditMode';
import LoadTab from './LoadTab';
import Swal from 'sweetalert2'


const time = [{ id: '00:00', time: '00:00' }];
for (var i = 1; i < 24; i++) {
  var obj =
  {
    id: i + ':00',
    time: i + ':00'
  };
  time.push(obj);
}

const searchOptions = {
  componentRestrictions: { country: ['us'] },
}


class EditShipper extends Component {

  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      status: 1,
      countries: [],
      states: [],
      Contacts: [],
      customerid: '',
      whileSave: false,
      UpdateContactid: '',
      readonly: true,
      hidden: false,
      addressid: '',
      fields: {},
      errors: {},
      isLoading: false,
      token: '',
      CheckEditInfo: {},
      HoursChild: [],
      HoursData: [{
        'sequenceId': 0,
        "day": "Monday-Friday",
        "from": "8:00",
        "to": "20:00"
      }],
      address: '',
      selectValue: { country: '', state: '1' },
      shiperFormData: {}
    }

    this.handleChange = this.handleChange.bind(this);
    this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);
    this.submit = this.submit.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.AutoFormatContact = this.AutoFormatContact.bind(this);
  };





  async handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    if (this._isMounted) {
      await this.setState({
        fields
      });
    }
    this.validateForm();
  }

  submituserRegistrationForm(e) {
    e.preventDefault();
    var send = false;
    if (this.validateAndSubmit() && this.validateForm()) {

      send = true;

    }
    if (send) {

      this.submit();
    }

  }


  validateAndSubmit() {

    // let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;



    // CustomerNAme
    if (!this.refs.customerName.value || !this.refs.customerName.value.trim().length > 0) {
      formIsValid = false;
      errors["customerName"] = "*Please Enter Name";
      $("[name='customerName']").focus();

    }

    // // Address
    // else if (!this.refs.address.value || !this.refs.address.value.trim().length>0) {
    //   formIsValid = false;
    //   errors["address"] = "*Please Enter Address";
    //   $("[name='address']").focus();

    // }


    // // Country
    // else if (!this.refs.country_id.value) {
    //   formIsValid = false;
    //   errors["country"] = "*Please Select Country";
    //   $("[name='country']").focus();

    // }

    //  // State
    //  else if (!this.refs.province_id.value) {
    //   formIsValid = false;
    //   errors["state"] = "*Please Select State";
    //   $("[name='state']").focus();

    // }

    // // City
    // else  if (!this.refs.city.value || !this.refs.city.value.trim().length>0) {
    //   formIsValid = false;
    //   errors["city"] = "*Please Enter City";
    //   $("[name='city']").focus();

    // }

    // // Zip
    // else if (!this.refs.zip.value || !this.refs.zip.value.trim().length>0) {
    //   formIsValid = false;
    //   errors["zip"] = "*Please Enter Zip";
    //   $("[name='zip']").focus();

    // }


    if (this._isMounted) {
      this.setState({
        errors: errors
      });
    }
    return formIsValid;
  }


  validateForm() {

    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;


    // customerName
    if (typeof fields["customerName"] !== "undefined" && fields["customerName"]) {
      if (fields["customerName"].length > 200) {
        formIsValid = false;
        errors["customerName"] = "*Please Enter Only Valid Characters (Max Range: 200)";
      }
    }



    //address


    if (this.state.address.length > 200) {
      formIsValid = false;
      errors["address"] = "*Please Enter Only Valid Characters (Max Range: 200)";
    }




    //addressLineTwo

    if (typeof fields["addressLineTwo"] !== "undefined" && fields["addressLineTwo"]) {
      if (!fields["addressLineTwo"].match(/^[a-zA-Z0-9#'"،.,-;/ ]{1,200}$/)) {
        formIsValid = false;
        errors["addressLineTwo"] = "*Please Enter Only Valid Characters (Max Range: 200)";
      }
    }

    //city

    if (typeof fields["city"] !== "undefined" && fields["city"]) {
      if (!fields["city"].match(/^[a-zA-Z0-9#., ]{1,200}$/)) {
        formIsValid = false;
        errors["city"] = "*Please Enter Only Valid Characters (Max Range: 200)";
      }
    }

    //zip

    if (typeof fields["zip"] !== "undefined" && fields["zip"]) {
      if (!fields["zip"].match(/^[a-zA-Z0-9-_ ]{1,35}$/)) {
        formIsValid = false;
        errors["zip"] = "*Only Numbers Allowed in Zip (Max Range: 35)";
      }
    }

    //telephone

    if (typeof fields["telephone"] !== "undefined" && fields["telephone"]) {
      if (!fields["telephone"].match(/^[0-9- ]{12,30}$/)) {
        formIsValid = false;
        errors["telephone"] = "*Please Enter only valid Telephone Number ( Range: 10)";
      }
    }

    //Ext

    if (typeof fields["ext"] !== "undefined" && fields["ext"]) {
      if (!fields["ext"].match(/^[0-9]{1,20}$/)) {
        formIsValid = false;
        errors["ext"] = "*Only Numbers Allowed (Max Range: 20)";
      }
    }
    //Fax

    if (typeof fields["fax"] !== "undefined" && fields["fax"]) {
      if (!fields["fax"].match(/^[0-9- ]{12,30}$/)) {
        formIsValid = false;
        errors["fax"] = "*Please Enter only valid fax Number ( Range: 10)";
      }
    }

    //email

    if (typeof fields["email"] !== "undefined" && fields["email"]) {
      if (!fields["email"].match(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/)) {
        formIsValid = false;
        errors["email"] = "*Please Enter Valid Email (Max Range: 100)";
      }
    }

    if (typeof fields["instructions"] !== "undefined" && fields["instructions"]) {
      if (!fields["instructions"].match(/^[a-zA-Z0-9#'"،.,-;/ ]{1,200}$/)) {
        formIsValid = false;
        errors["instructions"] = "*Please Enter Only Valid Characters (Max Range: 200)";
      }
    }






    if (this._isMounted) {
      this.setState({
        errors: errors
      });
    }
    return formIsValid;

  }

  AutoFormatContact(e) {





    function phone_formatting(ele, restore) {
      var new_number,
        // selection_start = ele.selectionStart,
        // selection_end = ele.selectionEnd,
        number = ele.value.replace(/\D/g, '');

      // automatically add dashes
      if (number.length > 2) {
        // matches: 123 || 123-4 || 123-45
        new_number = number.substring(0, 3) + '-';
        if (number.length === 4 || number.length === 5) {
          // matches: 123-4 || 123-45
          new_number += number.substr(3);
        }
        else if (number.length > 5) {
          // matches: 123-456 || 123-456-7 || 123-456-789
          new_number += number.substring(3, 6) + '-';
        }
        if (number.length > 6) {
          // matches: 123-456-7 || 123-456-789 || 123-456-7890
          new_number += number.substring(6);
        }
      }
      else {
        new_number = number;
      }

      // if value is heigher than 12, last number is dropped
      // if inserting a number before the last character, numbers
      // are shifted right, only 12 characters will show
      ele.value = (new_number.length > 12) ? new_number.substring(0, 12) : new_number;

      // restore cursor selection,
      // prevent it from going to the end
      // UNLESS
      // cursor was at the end AND a dash was added


      // if (new_number.slice(-1) === '-' && restore === false
      //     && (new_number.length === 8 && selection_end === 7)
      //         || (new_number.length === 4 && selection_end === 3)) {
      //     selection_start = new_number.length;
      //     selection_end = new_number.length;
      // }
      // else if (restore === 'revert') {
      //   selection_start--;
      //   selection_end--;
      // }
      // ele.setSelectionRange(selection_start, selection_end);

    }

    function phone_number_check(field, e) {
      var key_code = e.keyCode,
        key_string = String.fromCharCode(key_code),
        press_delete = false,
        dash_key = 189,
        delete_key = [8, 46],
        direction_key = [33, 34, 35, 36, 37, 38, 39, 40],
        selection_end = field.selectionEnd;

      // delete key was pressed
      if (delete_key.indexOf(key_code) > -1) {
        press_delete = true;
      }

      // only force formatting is a number or delete key was pressed
      if (key_string.match(/^\d+$/) || press_delete) {
        phone_formatting(field, press_delete);
      }
      // do nothing for direction keys, keep their default actions
      else if (direction_key.indexOf(key_code) > -1) {
        // do nothing
      }
      else if (dash_key === key_code) {
        if (selection_end === field.value.length) {
          field.value = field.value.slice(0, -1)
        }
        else {
          field.value = field.value.substring(0, (selection_end - 1)) + field.value.substr(selection_end)
          field.selectionEnd = selection_end - 1;
        }
      }
      // all other non numerical key presses, remove their value
      else {
        e.preventDefault();
        //    field.value = field.value.replace(/[^0-9\-]/g,'')
        phone_formatting(field, 'revert');
      }

    }

    const Validate = (e) => {
      if (!String.fromCharCode(e.keyCode).match(/^- $/)) {
        this.handleChange(e)
      }
    }

    document.getElementById(e.target.id).onkeyup = function (e) {
      phone_number_check(this, e);
      Validate(e)
    }


  }



  onUnload = (event) => {

    event.preventDefault();
    var params;
    var http = new XMLHttpRequest();

    var url = BaseURL + 'editModeInfo/finishEditMode';

    if (this.state.CheckEditInfo.customerId)
      params = "shipperId=" + this.state.customerid + "&token=" + this.state.token;
    else if (this.state.UpdateContactid)
      params = "shipperContactId=" + this.state.UpdateContactid + "&token=" + this.state.token;

    http.open("POST", url, true);

    http.setRequestHeader("Content-type", "application/x-www-form-urlencoded");


    if (params) {
      http.send(params);
      var start = new Date().getTime();
      while (new Date().getTime() < start + 350);
    }



  }



  async componentDidMount() {

    this._isMounted = true;

    window.addEventListener("beforeunload", this.onUnload)
    var arr = {
      addressStates: [],
      countries: [],
      shipper: {},
      shipperContact: [],
      shippperHours: [],
    };
    var data = false, selectValue = { country: '', state: '1' };
    const token = localStorage.getItem('Token');
    const id = this.props.match.params.id;
    if (this._isMounted) {
      await this.setState({ token: token, customerid: id });
    }
    await axios.post(BaseURL + 'shipper/findById', {
      'token': token, 'id': id
    })
      .then(response => {

        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem('Token', '');
          this.props.history.push('/');
        }
        arr = response.data.data;
        console.log(response.data.data);
        var element = arr.shipper;
        if (this._isMounted) {
          data = {
            'id': element.id,
            'name': element.name,
            'addressid': element.address ? element.address.id : '',
            'address': element.address ? element.address.address : '',
            'addressLineTwo': element.address ? element.address.addressLineTwo : '',
            'countryid': element.address ? element.address.addressCountryId : '',
            'stateid': element.address ? element.address.addressStateId : '',
            'city': element.address ? element.address.city : '',
            'zip': element.address ? element.address.zip : '',
            'email': element.address ? element.address.email : '',
            'phone': element.address ? element.address.telephone : '',
            'ext': element.address ? element.address.ext : '',
            'fax': element.address ? element.address.fax : '',

            'instructions': element.specialInstructions,
            'shippingSchedule': element.shippingSchedule === '1' ? document.getElementById('Shipping').className = 'checked' : false,
            'shippingSchedule1': element.shippingSchedule1 === '1' ? document.getElementById('Shipping1').className = 'checked' : false,
            'trailerType': element.trailerType === '1' ? document.getElementById('trailerType').className = 'checked' : false,
            'trailerType1': element.trailerType1 === '1' ? document.getElementById('trailerType1').className = 'checked' : false,
          }
        }
        data.countryid === null ? {} : selectValue = { country: data.countryid, state: data.stateid };
        arr.shipper = data;
        data = true;
      }).catch(function (error) {
        NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);
        console.log(error);
      })

    arr.shippperHours.length > 0 ? {} : arr.shippperHours = this.state.HoursData;

    this.CreateHoursChildFromData(arr.shippperHours);
    console.log(arr.shippperHours)

    if (data && this._isMounted) {
      this.setState({
        countries: arr.countries,
        shiperFormData: arr.shipper,
        addressid: arr.shipper.addressid,
        address: arr.shipper.address,
        states: arr.addressStates ? arr.addressStates : [],
        Contacts: arr.shipperContact,
        HoursData: arr.shippperHours,
        selectValue: selectValue,
      });
    }

  }


  async componentWillUnmount() {

    this._isMounted = false;
    window.removeEventListener("beforeunload", this.onUnload);
    var config = this.state.CheckEditInfo;


    if (config.token) {
      finishEditinfo(config, (res) => {

      }, (err) => {

        console.log(err)
      });

    }
  }



  async states() {
    var arr;
    var data = false;
    var id = this.refs.country_id.value;
    if (this._isMounted) {
      this.setState({

        selectValue: {
          country: id,
          state: '1'
        }
      })
    }
    await axios.post(BaseURL + 'countries/states', {
      "countryId": id,
      'token': this.state.token
    })
      .then(response => {
        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem('Token', '');
          this.props.history.push('/');
        }
        arr = response.data.data;
        data = true;
      }).catch(function (error) {
        NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);
        console.log(error);
      })
    // document.getElementsByClassName('state').value=1;
    if (data && this._isMounted) {
      this.setState({
        states: arr,
      });
    }

  }


  cancel() {

    if (window.confirm(' Are you sure you want to cancel ? '))
      this.props.history.push('/shipper');

  }

  async submit() {

    if (this._isMounted) {

      this.setHourChildDisableMode(true);
      this.setState({
        whileSave: true,
        hidden: false,
        readonly: true
      })
    }

    var Shipping, Shipping1, trailerType, trailerType1;

    const customerName = this.refs.customerName.value.trim();

    // const status=this.state.status;
    const address = this.state.address.trim();
    const address2 = this.refs.address2.value.trim();
    const country_id = this.refs.country_id.value.trim();
    const province_id = this.refs.province_id.value.trim();
    const city = this.refs.city.value.trim();
    const zip = this.refs.zip.value.trim();
    const email = this.refs.email.value.trim();
    const telephone = this.refs.telephone.value.trim();
    const fax = this.refs.fax.value.trim();
    const ext = this.refs.ext.value.trim();

    const instructions = this.refs.instructions.value.trim();

    document.getElementById('Shipping').className ? Shipping = true : Shipping = false;
    document.getElementById('Shipping1').className ? Shipping1 = true : Shipping1 = false;
    document.getElementById('trailerType').className ? trailerType = true : trailerType = false;
    document.getElementById('trailerType1').className ? trailerType1 = true : trailerType1 = false;





    var customer = {

      'token': this.state.token,
      'id': this.state.customerid,
      "name": customerName,
      "specialInstructions": instructions,
      "shippingSchedule": Shipping,
      "shippingSchedule1": Shipping1,
      "trailerType": trailerType,
      "trailerType1": trailerType1,
      "hours": this.state.HoursData,
      "address": {
        'id': this.state.addressid,
        "address": address,
        "addressLineTwo": address2,
        "city": city,
        "zip": zip,
        "email": email,
        "telephone": telephone,
        "ext": ext,
        "fax": fax,
        "state": {
          "id": country_id ? province_id : null,
          "country": {
            "id": country_id ? country_id : null

          }
        }
      }

    }


    console.log(customer);

    if (this.state.customerid) {
      await axios.post(BaseURL + 'shipper/update', customer)
        .then(response => {
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem('Token', '');
            this.props.history.push('/');
          }
          NotificationManager.success(' Shipper Updated Successfully', 'Updated', 3000);
          console.log(response.data);
        }).catch(function (error) {
          NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);
          console.log(error);
        })
      if (this._isMounted) {
        this.setState({
          whileSave: false
        })
      }
    }



  }


  async SaveContact(event) {

    if (this._isMounted) {
      this.setState({
        isLoading: true
      });
    }
    event.preventDefault();
    $('#closePopUp').click();
    var arr;
    var data = false;
    var custid = this.state.customerid;

    if (this.state.UpdateContactid) {

      var CustomerContact = {

        'token': this.state.token,
        "id": this.state.UpdateContactid,
        "name": this.refs.ContactName.value.trim(),
        "title": this.refs.ContactTitle.value.trim(),
        "email": this.refs.ContactEmail.value.trim(),
        "telephone": this.refs.ContactTelephone.value.trim(),
        "ext": this.refs.ContactExt.value.trim(),
        "cellphone": this.refs.ContactPhone.value.trim(),
        "fax": this.refs.ContactFax.value.trim()
      }

      await axios.post(BaseURL + 'shipperContact/update', CustomerContact)
        .then(response => {
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem('Token', '');
            this.props.history.push('/');
          }
          console.log(response.data);
          NotificationManager.success('Shipper Contact Infomation Update Successfully', 'Customer Info', 3000);
        }).catch(function (error) {
          NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);
          console.log(error);
        })
      if (this._isMounted) {
        this.setState({
          UpdateContactid: '',
          whileSave: false, isLoading: false
        });
      }




    }
    else {
      var CustomerContactUpdate = {

        'token': this.state.token,
        "shipperId": custid,
        "name": this.refs.ContactName.value.trim(),
        "title": this.refs.ContactTitle.value.trim(),
        "email": this.refs.ContactEmail.value.trim(),
        "telephone": this.refs.ContactTelephone.value.trim(),
        "ext": this.refs.ContactExt.value.trim(),
        "cellphone": this.refs.ContactPhone.value.trim(),
        "fax": this.refs.ContactFax.value.trim()
      }

      await axios.post(BaseURL + 'shipperContact/save', CustomerContactUpdate)
        .then(response => {
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem('Token', '');
            this.props.history.push('/');
          }
          console.log(response.data);

          NotificationManager.success('Shipper Contact Infomation Saved', 'Customer Info', 3000);

        }).catch(function (error) {
          NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);
          console.log(error);
        })
      if (this._isMounted) {
        this.setState({
          whileSave: false,
          isLoading: false
        })
      }

    }


    await axios.post(BaseURL + 'shipperContact/findByShipperId', {
      "shipperId": custid,
      'token': this.state.token
    })
      .then(response => {
        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem('Token', '');
          this.props.history.push('/');
        }
        arr = response.data.data;
        data = true;

      }).catch(function (error) {
        NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);
        console.log(error);
      })

    if (data && this._isMounted) {
      this.setState({ Contacts: arr });
      this.refs.ContactName.value = '';
      this.refs.ContactTitle.value = '';
      this.refs.ContactEmail.value = '';
      this.refs.ContactTelephone.value = '';
      this.refs.ContactExt.value = '';
      this.refs.ContactPhone.value = '';
      this.refs.ContactFax.value = '';
    }


  }

  async ClearContact() {

    if (this._isMounted) {
      await this.setState({
        UpdateContactid: '',

      });
    }

    this.refs.ContactName.value = '';
    this.refs.ContactTitle.value = '';
    this.refs.ContactEmail.value = '';
    this.refs.ContactTelephone.value = '';
    this.refs.ContactExt.value = '';
    this.refs.ContactPhone.value = '';
    this.refs.ContactFax.value = '';

  }


  async EditContact(e) {

    var a = document.getElementById(e.id);
    a.setAttribute("style", " cursor: not-allowed; pointer-events: none")


    var config = {
      "shipperContactId": e.id,
      "token": this.state.token
    }

    getEditinfo(config, (res) => {
      console.log(res);
      if (res.data.status === 0) {

        this.refs.ContactName.value = e.name;
        this.refs.ContactTitle.value = e.title;
        this.refs.ContactEmail.value = e.email;
        this.refs.ContactTelephone.value = e.telephone;
        this.refs.ContactExt.value = e.ext;
        this.refs.ContactPhone.value = e.cellphone;
        this.refs.ContactFax.value = e.fax;




        a.href = "#contact"
        a.click();

        if (this._isMounted) {
          this.setState({
            UpdateContactid: e.id,
            CheckEditInfo: config
          });
        }
        a.removeAttribute("href");
        a.removeAttribute("style");

      }
      else {

        a.removeAttribute("style");
        NotificationManager.warning(res.data.message, 'warning Message', 3000);
      }

    }, (err) => {

      console.log(err)
    });



  }


  EditForm() {

    var div = document.getElementById('EditDiv');
    div.setAttribute("style", " cursor: not-allowed; pointer-events: none")

    var config = {
      "shipperId": this.state.customerid,
      "token": this.state.token
    }

    getEditinfo(config, (res) => {
      console.log(res);
      if (res.data.status === 0) {
        if (this._isMounted) {
          this.setState({
            readonly: false,
            CheckEditInfo: config
          });
        }
        this.setHourChildDisableMode(false);
      }
      else {
        div.removeAttribute("style")
        NotificationManager.warning(res.data.message, 'warning Message', 3000);

      }

    }, (err) => {

      console.log(err)
    });


  }

  async DelContact(e) {

    if (!window.confirm("Are you sure you want to delete Contact?")) {
      return false;
    }
    if (this._isMounted) {
      this.setState({
        isLoading: true
      });
    }
    var arr;

    await axios.post(BaseURL + 'shipperContact/delete', {
      "id": e.id,
      'token': this.state.token
    })
      .then(response => {
        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem('Token', '');
          this.props.history.push('/');
        }
        console.log(response.data)
        NotificationManager.success(response.data.message, 'Shipper Info', 3000);
      }).catch(function (error) {
        NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);
        console.log(error);
      })
    if (this._isMounted) {
      this.setState({
        isLoading: false
      });
    }

    await axios.post(BaseURL + 'shipperContact/findByShipperId', {
      "shipperId": this.state.customerid,
      'token': this.state.token
    })
      .then(response => {
        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem('Token', '');
          this.props.history.push('/');
        }
        arr = response.data.data;

      }).catch(function (error) {
        NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);
        console.log(error);
      })
    if (this._isMounted) {
      this.setState({ Contacts: arr });
    }

  }

  async status(event) {
    if (this._isMounted) {
      await this.setState({ status: 0 });
    }
  }
  async Actstatus(event) {
    if (this._isMounted) {
      await this.setState({ status: 1 });
    }
  }


  FinishEditInfo() {
    var config = this.state.CheckEditInfo;
    if (config.token) {

      finishEditinfo(config, (res) => {
        console.log(res);
        if (this._isMounted) {
          this.setState({
            CheckEditInfo: {},
            readonly: true
          });
        }

      }, (err) => {

        console.log(err)
      });

    }

  }

  async AddHourRow(e) {

    e.preventDefault();

    var stateChild = await this.state.HoursChild;
    var HoursData = await this.state.HoursData;

    var id = stateChild[stateChild.length - 1];
    id ? id = id.id + 1 : id = 1;


    var child = {
      id: id, Child: <div className="form-group row" key={id} >
        <label className="col-md-3 control-label">Hours</label>
        <div className="col-md-3">
          <select name="day" id={id} defaultValue='Monday-Friday' className="form-control" title="hours" onChange={this.onChangeHoursValue} >
            <option value="Monday-Friday">Monday-Friday</option>
            <option value="Saturday-Sunday">Saturday-Sunday</option>
            <option value="Monday">Monday</option>
            <option value="Tuesday">Tuesday</option>
            <option value="Wednesday">Wednesday</option>
            <option value="Thursday">Thursday</option>
            <option value="Friday">Friday</option>
            <option value="Saturday">Saturday</option>
            <option value="Sunday">Sunday</option>

          </select>
        </div>
        <div className="col-md-2">
          <select name="from" id={id} defaultValue='8:00' className="form-control" title="hours" onChange={this.onChangeHoursValue}>

            {time.map((data) =>
              <option value={data.id} key={data.id}>{data.time}</option>
            )}

          </select>
        </div>

        <div className="col-md-2">
          <select name="to" id={id} defaultValue='20:00' className="form-control" title="hours" onChange={this.onChangeHoursValue} >

            {time.map((data) =>
              <option value={data.id} key={data.id}>{data.time}</option>
            )}
          </select>

        </div>
        <div className="col-md-2" >
          <button type="button" className="btn red" name={id} id={id} onClick={this.DelHourRow.bind(this)} > <i className="fa fa-button" id={id} > Delete </i>
          </button>
        </div>
      </div>
    }
      ;
    HoursData.push({
      'sequenceId': id,
      "day": "Monday-Friday",
      "from": "8:00",
      "to": "20:00"
    })
    stateChild.push(child);
    if (this._isMounted) {
      this.setState({ HoursChild: stateChild, HoursData: HoursData });
    }
  }

  async DelHourRow(e) {


    e.preventDefault();
    if (!window.confirm('Are You Sure You Want To Delete ? ')) {
      return false;
    }
    else {
      //  console.log(e.target, e.target.id );
      var index = e.target.id;
      var index2 = Number(index);
      var HoursData = this.state.HoursData;
      var stateChild = this.state.HoursChild;
      console.log(HoursData)

      var NewHourChild = [], NewHoursData = [];

      await stateChild.forEach(element => {
        if (element.id !== Number(index)) {
          NewHourChild.push(element)
        }

      });

      await HoursData.forEach(element => {

        if (element.sequenceId !== index2) {
          NewHoursData.push(element)
        }

      });
      console.log(NewHoursData)

      //  console.log(stateChild,index);
      //  delete stateChild[index];
      //  var Child=stateChild.filter(function(e){return e});

      //  console.log(stateChild,Child)
      if (this._isMounted) {
        this.setState({ HoursChild: NewHourChild, HoursData: NewHoursData });
      }
      // var array=[{name:'history',lastname:'zero'},{name:'a',lastname:'b'}]
      // console.log(JSON.stringify(array))
      // console.log(delete array[1])
      // console.log(JSON.stringify(array))
      // console.log(delete array[0])
      // console.log(JSON.stringify(array))
      // array.push({name:'history',lastname:'zero'})
      // array.push({name:'history',lastname:'zero'})

      // console.log(JSON.stringify(s))

    }
  }

  onChangeHoursValue = async (e) => {

    e.preventDefault();
    var index = Number(e.target.id);
    var name = e.target.name;
    var value = e.target.value;
    var HoursData = this.state.HoursData;
    var NewHoursData = [];
    await HoursData.forEach(element => {
      if (element.sequenceId === index) {
        element[name] = value;
        NewHoursData.push(element)
      }
      else {
        NewHoursData.push(element)
      }

    });

    if (this._isMounted) {
      await this.setState({ HoursData: NewHoursData });
    }

    console.log(this.state.HoursData);

  }

  handleAddressChange = address => {
    // console.log('change',address)
    if (this._isMounted) {
      this.setState({ address });
    }

    if (!address) {

      if (this._isMounted) {
        this.setState({ states: [], selectValue: { country: '', state: '1' } });
      }
      this.refs.city.value = '';
      this.refs.zip.value = '';
    }

    if (address) {
      axios.post(BaseURL + 'shipper/findAddress', {
        "address": address,
        'token': this.state.token
      }).then(response => {
        if (response.data.message == "fail") {
          // alert("Address already exists are you sure you want to proceed");
          const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: 'btn btn-success',
              cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
          })

          swalWithBootstrapButtons.fire({
            // title: 'Are you sure?',
            text: "Address already exists are you sure you want to proceed!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, proceed it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
          }).then((result) => {
            if (result.isConfirmed) {

            } else if (
              result.dismiss === Swal.DismissReason.cancel
            ) {
              address = ""
              this.setState({ address });
            }
          })
        }
        // if (response.data.message == "success") {
        //   console.log("success")
        // }
      })
    }


  };

  handleSelect = address => {

    var responseObj = [];
    if (this._isMounted) {
      this.refs.zip.value = '';
      this.refs.city.value = '';
      this.setState({ states: [], selectValue: { country: '', state: '1' } });
    }
    console.log(address)
    // console.log('select')
    geocodeByAddress(address)
      .then(results => {

        responseObj = results[0]['address_components'];
        var addressdata = {
          'state': '',
          'country': ''
        }

        if (responseObj[0].long_name) {

          var addr = responseObj[1];

          addr = addr ? addr.long_name : '';
          var CurrentAddress = responseObj[0].long_name + ' ' + addr;

          if (this._isMounted) {
            this.setState({ address: CurrentAddress })
          }

        }
        //  console.log(responseObj);
        responseObj.forEach(element => {

          if (element.types[0] === "locality") {
            // console.log('City',element);
            this.refs.city.value = element.long_name;
          }
          else if (element.types[0] === "administrative_area_level_1") {
            // console.log('State',element);
            addressdata.state = element.long_name;
          }
          else if (element.types[0] === "country") {
            // console.log('Country',element);
            addressdata.country = element.long_name;
          }
          else if (element.types[0] === "postal_code") {
            // console.log('Zip',element);
            this.refs.zip.value = element.long_name;
          }

        });
        this.handleCountryStateSelect(addressdata);
      })
      .catch(error => console.error('Error', error));

  };

  handleCountryStateSelect = async data => {

    var selectValue = this.state.selectValue;
    // console.log(data);
    await this.state.countries.forEach(element => {
      if (element.countryName === data.country) {
        console.log(element.countryName, data.country)
        selectValue['country'] = element.id;

        if (this._isMounted) {
          this.setState({ selectValue });
        }

      }
    });

    await axios.post(BaseURL + 'countries/states', {
      "countryId": selectValue.country,
      'token': this.state.token
    })
      .then(response => {
        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem('Token', '');
          this.props.history.push('/');
        }
        var arr = response.data.data;
        if (this._isMounted) {
          this.setState({ states: arr });
        }

      }).catch(function (error) {
        NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);
        console.log(error);
      })


    await this.state.states.forEach(element => {
      if (element.state.toLowerCase() === data.state.toLowerCase()) {
        console.log(data.state)
        selectValue['state'] = element.id;
        if (this._isMounted) {
          this.setState({ selectValue });
        }
      }
    });


  }

  changeState = e => {
    var name = e.target.name;
    var value = e.target.value;
    if (name === 'state') {
      var selectValue = this.state.selectValue;
      selectValue['state'] = value;
      if (this._isMounted) {
        this.setState({ selectValue });
      }
    }
    else if (name === 'instructions') {
      var selectValue = this.state.shiperFormData;
      selectValue['instructions'] = value;
      if (this._isMounted) {
        this.setState({ shiperFormData: selectValue });
      }
      this.handleChange(e);
    }
  }

  setHourChildDisableMode = (value) => {


    this.state.HoursChild.forEach(element => {
      var id = element.id;


      $("[id=" + id + "]:visible").each(function (i, e) {



        if (i !== 3) {
          value ? e.setAttribute('disabled', true) : e.removeAttribute('disabled');
        }
        else {
          value ? e.setAttribute('style', 'cursor: not-allowed ; pointer-events: none') : e.removeAttribute('style');
        }

      });

    });

  }

  CreateHoursChildFromData = (data) => {

    console.log(data);
    var childArray = [];
    const nonclick = {
      cursor: ' notAllowed',
      pointerEvents: 'none'
    }

    data.forEach(element => {
      var id = element.sequenceId;
      if (id === 0) {
        return false;
      }

      console.log(element);

      var child = {
        id: id, Child: <div className="form-group row" key={id} >
          <label className="col-md-3 control-label">Hours</label>
          <div className="col-md-3">
            <select name="day" id={id} defaultValue={element.day} className="form-control" title="hours" onChange={this.onChangeHoursValue} disabled={this.state.readonly}>

              <option value="Monday-Friday">Monday-Friday</option>
              <option value="Saturday-Sunday">Saturday-Sunday</option>
              <option value="Monday">Monday</option>
              <option value="Tuesday">Tuesday</option>
              <option value="Wednesday">Wednesday</option>
              <option value="Thursday">Thursday</option>
              <option value="Friday">Friday</option>
              <option value="Saturday">Saturday</option>
              <option value="Sunday">Sunday</option>


            </select>
          </div>
          <div className="col-md-2">
            <select name="from" id={id} defaultValue={element.from} className="form-control" title="hours" onChange={this.onChangeHoursValue} disabled={this.state.readonly}>

              {time.map((data) =>
                <option value={data.id} key={data.id}>{data.time}</option>
              )}

            </select>
          </div>

          <div className="col-md-2">
            <select name="to" id={id} defaultValue={element.to} className="form-control" title="hours" onChange={this.onChangeHoursValue} disabled={this.state.readonly}>

              {time.map((data) =>
                <option value={data.id} key={data.id}>{data.time}</option>
              )}
            </select>

          </div>
          <div className="col-md-2" >
            <button type="button" className="btn red" name={id} id={id} onClick={this.DelHourRow.bind(this)} style={nonclick}> <i className="fa fa-button" id={id} > Delete </i>
            </button>
          </div>
        </div>
      };
      childArray.push(child);


    });

    if (this._isMounted) {
      this.setState({ HoursChild: childArray })
    }
  }

  handleCheckBox = (e) => {
    var id = e.target.name;
    var className = document.getElementById(id).className;
    if (className) {
      this.refs[id].checked = false;
      document.getElementById(id).className = '';
    }
    else {
      this.refs[id].checked = true;
      document.getElementById(id).className = 'checked';
    }

  }

  handleDeleteActionButton = () => {




    if (window.confirm('Are You Sure You Want To Delete ?')) {


      axios.post(BaseURL + 'shipper/delete', {
        'token': this.state.token,
        "id": this.state.customerid,
        "addressId": this.state.addressid
      }).then(async response => {

        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem('Token', '');
          this.props.history.push('/');
        }
        else if (response.data.errorMessage) {
          NotificationManager.error(response.data.errorMessage, 'Error Message', 3000);

        }
        else if (response.data.message) {            //  NotificationManager.success(response.data.message, 'Message', 3000);

          this.props.history.push('/shipper');
        }

      }).catch(function (error) {
        NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);
        console.log(error);
      })

    }

  }

  render() {

    const msgNone = {
      display: 'none'
    }
    const msgShow = {
      display: 'block',
    }
    const nonclick = {
      cursor: ' notAllowed',
      pointerEvents: 'none'
    }

    const msgShowin = {
      display: 'inline-block',
      marginLeft: '10px'
    }


    const { HoursChild, selectValue, shiperFormData, HoursData } = this.state;

    return (
      <div className="page-container-bg-solid page-header-fixed page-sidebar-closed-hide-logo page-md">
        <Header title='| Shipper List' />
        <div className="page-container">
          <Sidebar />
          <div className="page-content-wrapper">

            <div className="page-content">

              <div className="page-head">



              </div>



              <div className="row">
                <div className="col-md-12">

                  <div className="tabbable-line boxless  tabbable-reversed">

                    <ul className="nav nav-tabs"  >
                      <li className="active border-green-jungle"> <a href="#tab_0" data-toggle="tab" onClick={this.FinishEditInfo.bind(this)}> Shipper </a> </li>
                      <li className="border-green-jungle"> <a href="#tab_1" data-toggle="tab" onClick={this.FinishEditInfo.bind(this)}> Contact </a> </li>
                      <li className="border-green-jungle"> <a href="#tab_2" data-toggle="tab" onClick={this.FinishEditInfo.bind(this)}> Load </a> </li>

                    </ul>
                    <div className="tab-content">
                      <div className="tab-pane active" id="tab_0">

                        <div className="portlet box yellow-gold">
                          <div className="portlet-title">
                            <div className="caption"> <i className="fa fa-user"></i>Update Shipper </div>

                            <div className="actions" style={this.state.readonly ? {} : nonclick} id='EditDiv'>
                              <div className="btn-group" style={this.state.hidden === false ? msgShow : msgNone} >
                                <button className="btn sbold white dark" onClick={this.EditForm.bind(this)} hidden={this.state.hidden} disabled={this.state.hidden}>
                                  <i className="fa fa-pencil"></i> Edit
                                </button>
                              </div>
                            </div>


                          </div>
                          <div className="portlet-body form">

                            <form onSubmit={this.submituserRegistrationForm.bind(this)} className="horizontal-form" autoComplete="off" >
                              <div className="form-body max_width800">
                                <div className="portlet-title">
                                  <h2 className="h3"> Shipper Info
                                    {/* <span style={{float: 'right', paddingLeft:' 8px', marginTop: '2px'}} >
                              <div className="btn-group btn-group-devided" data-toggle="buttons" style={this.state.readonly?nonclick:msgShow}>
                                <label className="btn btn-transparent dark btn-outline btn-circle btn-sm active" disabled={this.state.readonly} onClick={this.Actstatus.bind(this)}>
                                  <input type="radio" name="options" className="toggle" id="option1"   />
                                  Active</label>
                                <label className="btn btn-transparent dark btn-outline btn-circle btn-sm" disabled={this.state.readonly} onClick={this.status.bind(this)}>
                                  <input type="radio" name="options"  className="toggle"  id="option2" />
                                  Inactive</label>
                              </div>
                              </span> */}
                                  </h2>

                                  <div className="clearfix"></div>
                                  <hr />
                                </div>
                                <div id="abcc" className="form-group row">
                                  <label className="col-md-3 control-label"> Name <span className="font-red-thunderbird" >*</span></label>
                                  <div className="col-md-9">
                                    <input type="text" ref="customerName" onChange={this.handleChange} name="customerName" onChange={this.handleChange} className="form-control" placeholder="" readOnly={this.state.readonly} defaultValue={shiperFormData.name} />
                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.customerName}</div>

                                  </div>
                                </div>



                                <div className="portlet-title">
                                  <h2 className="h3"> Address Info </h2>
                                  <hr />
                                </div>
                                <PlacesAutocomplete
                                  searchOptions={searchOptions}
                                  value={this.state.address}
                                  onChange={this.handleAddressChange}
                                  onSelect={this.handleSelect}
                                  onError={(e) => console.log(e)}
                                  clearItemsOnError={true}

                                >
                                  {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                    <div>
                                      {/* <input
                                      {...getInputProps({
                                        placeholder: 'Search Location',
                                        className: 'location-search-input',
                                      })}
                                    /> */}
                                      <div className="form-group row">
                                        <label className="col-md-3 control-label">Address <span className="font-red-thunderbird" ></span></label>
                                        <div className="col-md-9">
                                          <input type="text" ref="address" {...getInputProps({
                                            className: 'location-search-input',
                                          })} name="address" className="form-control" placeholder="Search Location" readOnly={this.state.readonly} />
                                          <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.address}</div>
                                          <div className="autocomplete-dropdown-container">
                                            {loading && <div>Loading...</div>}
                                            {suggestions.map(suggestion => {
                                              const className = suggestion.active
                                                ? 'suggestion-item--active'
                                                : 'suggestion-item';
                                              // inline style for demonstration purpose

                                              return (
                                                <div
                                                  {...getSuggestionItemProps(suggestion, {
                                                    className,

                                                  })}
                                                >
                                                  <span>{suggestion.description}</span>
                                                </div>
                                              );
                                            })}
                                          </div>

                                        </div>
                                      </div>


                                    </div>
                                  )}
                                </PlacesAutocomplete>

                                <div className="form-group row">
                                  <label className="col-md-3 control-label">Address Line 2</label>
                                  <div className="col-md-9">
                                    <input type="text" ref="address2" onChange={this.handleChange} name="addressLineTwo" className="form-control" placeholder="" readOnly={this.state.readonly} defaultValue={shiperFormData.addressLineTwo} />
                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.addressLineTwo}</div>
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="col-md-3 control-label">Country <span className="font-red-thunderbird" ></span></label>
                                  <div className="col-md-9">


                                    <select ref="country_id" name="country" className="form-control" title="Country" value={selectValue.country} onChange={this.states.bind(this)} disabled={this.state.readonly} readOnly={this.state.readonly}>

                                      <option value="" disabled hidden>Select Country</option>
                                      {
                                        this.state.countries.map(function (data) {
                                          return <option value={data.id} key={data.id}> {data.countryName} </option>;
                                        })

                                      }

                                    </select>
                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.country}</div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">State <span className="font-red-thunderbird" ></span></label>
                                  <div className="col-md-9">
                                    <select ref="province_id" name="state" className="form-control" value={selectValue.state} disabled={this.state.readonly} readOnly={this.state.readonly} onChange={this.changeState}>

                                      {
                                        this.state.states.map(function (data) {
                                          return <option value={data.id} key={data.id}> {data.state} </option>;
                                        })

                                      }
                                    </select>
                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.state}</div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">City <span className="font-red-thunderbird" ></span></label>
                                  <div className="col-md-9">
                                    <input type="text" ref="city" onChange={this.handleChange} name="city" className="form-control" placeholder="" readOnly={this.state.readonly} defaultValue={shiperFormData.city} />
                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.city}</div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">Zip <span className="font-red-thunderbird" ></span></label>
                                  <div className="col-md-9">
                                    <input type="text" ref="zip" onChange={this.handleChange} name="zip" className="form-control" placeholder="" readOnly={this.state.readonly} defaultValue={shiperFormData.zip} />
                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.zip}</div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">Email</label>
                                  <div className="col-md-9">
                                    <input type="email" ref="email" onChange={this.handleChange} name="email" className="form-control" placeholder="" readOnly={this.state.readonly} defaultValue={shiperFormData.email} />
                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.email}</div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">Telephone</label>
                                  <div className="col-md-9 form-inline">
                                    <input type="text" ref="telephone" id='Telephone' onChange={this.AutoFormatContact} name="telephone" className="form-control" placeholder="xxx-xxx-xxxx" readOnly={this.state.readonly} defaultValue={shiperFormData.phone} />

                                    <span className="help-inline"> EXT </span>  <input type="text" name="ext" onChange={this.handleChange} ref="ext" className="form-control" placeholder="" readOnly={this.state.readonly} defaultValue={shiperFormData.ext} />
                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.telephone}</div>
                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.ext}</div>

                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">Fax</label>
                                  <div className="col-md-9">
                                    <input type="text" ref="fax" name="fax" id='fax' onChange={this.AutoFormatContact} className="form-control" placeholder="xxx-xxx-xxxx" readOnly={this.state.readonly} defaultValue={shiperFormData.fax} />
                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.fax}</div>
                                  </div>
                                </div>

                                <hr />

                                <div className="form-group row">
                                  <label className="col-md-3 control-label">Special Instructions</label>
                                  <div className="col-md-9">
                                    <textarea rows="3" className="form-control" name='instructions' ref='instructions' onChange={this.changeState} readOnly={this.state.readonly} value={shiperFormData.instructions ? shiperFormData.instructions : ''} />
                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.instructions}</div>
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="col-md-3 control-label">Shipping Schedule </label>
                                  <div className="col-md-9">
                                    <div className="checkbox-list">

                                      <span>
                                        <div className="checker" >
                                          <span className='' id='Shipping'>
                                            <input type="checkbox" ref='Shipping' name='Shipping' value="FCFS" onClick={this.handleCheckBox} disabled={this.state.readonly} />
                                          </span>
                                        </div>
                                      </span> FCFS &nbsp; &nbsp;
                                      <span>
                                        <div className="checker" >
                                          <span className='' id='Shipping1' >
                                            <input type="checkbox" ref='Shipping1' name='Shipping1' onClick={this.handleCheckBox} value="By Appointment" disabled={this.state.readonly} />
                                          </span>
                                        </div>
                                      </span> By Appointment
                                    </div>
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="col-md-3 control-label"><br /><br />Hours</label>
                                  <div className="col-md-3">
                                    <label className="control-label">Day</label>
                                    <select name="day" id="0" value={
                                      /* Start of Code Added by waqar Nawab on 22-03-2019*/
                                      HoursData !== null && HoursData.length > 0 ? HoursData[0]['day'] : ''}
                                      /* End of Code Added by waqar Nawab on 22-03-2019*/
                                      ref='day' className="form-control" title="Day" onChange={this.onChangeHoursValue} disabled={this.state.readonly}>

                                      <option value="Monday-Friday">Monday-Friday</option>
                                      <option value="Saturday-Sunday">Saturday-Sunday</option>
                                      <option value="Monday">Monday</option>
                                      <option value="Tuesday">Tuesday</option>
                                      <option value="Wednesday">Wednesday</option>
                                      <option value="Thursday">Thursday</option>
                                      <option value="Friday">Friday</option>
                                      <option value="Saturday">Saturday</option>
                                      <option value="Sunday">Sunday</option>

                                    </select>
                                  </div>

                                  <div className="col-md-2">
                                    <label className="control-label">From</label>
                                    <select name="from" id="0" value={
                                      /* Start of Code Added by waqar Nawab on 22-03-2019*/
                                      HoursData !== null && HoursData.length > 0 ? HoursData[0]['from'] : ''}
                                      /* End of Code Added by waqar Nawab on 22-03-2019*/
                                      ref='from' className="form-control" title="hours" onChange={this.onChangeHoursValue} disabled={this.state.readonly}>

                                      {time.map((data) =>
                                        <option value={data.id} key={data.id}>{data.time}</option>
                                      )}

                                    </select>
                                  </div>

                                  <div className="col-md-2">
                                    <label className="control-label">To</label>
                                    <select name="to" id="0" value={
                                      /* Start of Code Added by waqar Nawab on 22-03-2019*/
                                      HoursData !== null && HoursData.length > 0 ? HoursData[0]['to'] : ''}
                                      /* End of Code Added by waqar Nawab on 22-03-2019*/
                                      ref='to' className="form-control" title="hours" onChange={this.onChangeHoursValue} disabled={this.state.readonly}>

                                      {time.map((data) =>
                                        <option value={data.id} key={data.id}>{data.time}</option>
                                      )}
                                    </select>

                                  </div>
                                  <div className="col-md-2">
                                    <label className="control-label">&nbsp;</label>
                                    <br />
                                    <button type="button" className="btn green-meadow" onClick={this.AddHourRow.bind(this)} disabled={this.state.readonly}> <i className="fa fa-button"> Add </i>
                                    </button>
                                  </div>
                                </div>

                                {HoursChild.map((AppendChild) =>
                                  AppendChild.Child

                                )}

                                <div className="form-group row">
                                  <label className="col-md-3 control-label">Trailer Type</label>
                                  <div className="col-md-9">
                                    <div className="checkbox-list">

                                      <span>
                                        <div className="checker" >
                                          <span className='' id='trailerType' >
                                            <input type="checkbox" ref='trailerType' name="trailerType" onClick={this.handleCheckBox} value="Reefer" disabled={this.state.readonly} />
                                          </span>
                                        </div>
                                      </span> Reefer &nbsp; &nbsp;

                                      <span>
                                        <div className="checker" >
                                          <span className='' id='trailerType1' >
                                            <input type="checkbox" ref='trailerType1' name="trailerType1" onClick={this.handleCheckBox} value="Dry Van" disabled={this.state.readonly} />
                                          </span>
                                        </div>
                                      </span>  Dry Van

                                    </div>
                                  </div>
                                </div>

                              </div>
                              <div className="clearfix"></div>
                              <div className="form-actions right">

                                {
                                  this.state.customerid && this.state.readonly ?
                                    <button onClick={this.handleDeleteActionButton} type="button" className="btn default red"><i className="fa fa-trash-o"></i> Delete</button>
                                    : null
                                }

                                &nbsp;
                                &nbsp;


                                <button onClick={this.cancel.bind(this)} type="button" className="btn default">Cancel</button> &nbsp;
                                <button disabled={this.state.readonly} type="submit" className="btn green-meadow">
                                  {this.state.whileSave === false
                                    ? <i className='fa fa-save'></i>
                                    : <i className="fa fa-circle-o-notch fa-spin"></i>}
                                  Save
                                </button>
                              </div>

                            </form>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="tab_1">
                        <div className="portlet box yellow-gold">
                          <div className="portlet-title">
                            <div className="caption"> <i className="fa fa-envelope"></i> Contact Info</div>
                          </div>
                          <div className="portlet-body form ">

                            <form action="#" className="horizontal-form">
                              <div className="col-md-12">
                                <div className="mt10">
                                  <table className="table table-striped table-bordered table-hover table-checkable order-column dataTable no-footer">
                                    <thead>
                                      <tr>
                                        <th className="text-center"> Name </th>
                                        <th className="text-center w200"> Title </th>
                                        <th className="text-center"> Email </th>
                                        <th className="text-center"> Telephone </th>
                                        <th className="text-center"> Cell Phone </th>
                                        <th className="text-center"> Fax </th>
                                        <th className="text-center"> Action </th>

                                      </tr>
                                    </thead>
                                    <tbody>

                                      {this.state.Contacts.map(function (data) {
                                        return (
                                          <tr key={data.id} >
                                            <td className="text-center"> {data.name} </td>
                                            <td className="text-center"> {data.title} </td>
                                            <td className="text-center"> {data.email} </td>
                                            <td className="text-center"> {data.telephone} </td>
                                            <td className="text-center"> {data.cellphone} </td>
                                            <td className="text-center"> {data.fax} </td>
                                            <td className="text-center"><a data-toggle="modal" id={data.id}> <i className="fa fa-pencil" onClick={() => this.EditContact(data)}></i> </a>  &nbsp;
                                              <a> <i className="fa fa-trash-o" onClick={() => this.DelContact(data)}></i> </a></td>
                                          </tr>
                                        );
                                      }, this)}


                                    </tbody>
                                  </table>
                                </div>
                                <br />
                                <div className="mb-2">
                                  <a data-toggle="modal" href="#contact" className="btn  green-meadow" onClick={this.ClearContact.bind(this)}> Add Contact</a>
                                  <span style={this.state.isLoading === false ? msgNone : msgShowin}><i className="fa fa-circle-o-notch fa-spin"></i></span>
                                </div>
                              </div>
                              &nbsp;
                              <div className="clearfix"></div>
                            </form>

                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="tab_2">
                        <LoadTab
                          shipperId={this.state.customerid}
                        />
                      </div>


                    </div>
                  </div>





                </div>
              </div>

            </div>



          </div>


          <div className="modal fade" id="contact" data-backdrop="static" data-keyboard="false" tabIndex="-1" aria-hidden="true">
            <div className="modal-dialog  modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" id="closePopUp" data-dismiss="modal" aria-hidden="true" hidden={true}></button>
                  <button type="button" className="close" onClick={this.FinishEditInfo.bind(this)} data-dismiss="modal" aria-hidden="true"></button>
                  <h4 className="modal-title">Contact</h4>
                </div>
                <div className="modal-body">
                  <div className="portlet-body form">


                    <form onSubmit={this.SaveContact.bind(this)} className="horizontal-form">
                      <div className="form-body max_width800">
                        <br />

                        <div className="form-group row">
                          <label className="col-md-3 control-label">Name <span className="font-red-thunderbird" >*</span></label>
                          <div className="col-md-9">
                            <input type="text" ref="ContactName" pattern="[a-z A-Z0-9-'@!$#:;,.-_\x22 ]{1,200}" title="Please Enter Contact Name (Max limit: 200)" className="form-control" placeholder="" required />
                          </div>
                        </div>

                        <div className="form-group row">
                          <label className="col-md-3 control-label">Title</label>
                          <div className="col-md-9">
                            <input type="text" ref="ContactTitle" pattern="[a-z A-Z'(),-_.\x22 ]{1,200}" title="Please Enter title (Max limit: 200)" className="form-control" placeholder="" />
                          </div>
                        </div>

                        <div className="form-group row">
                          <label className="col-md-3 control-label">Email</label>
                          <div className="col-md-9">
                            <input type="email" ref="ContactEmail" className="form-control" placeholder="" />
                          </div>
                        </div>

                        <div className="form-group row">
                          <label className="col-md-3 control-label">Telephone</label>
                          <div className="col-md-9 form-inline">
                            <input type="text" ref="ContactTelephone" id='contactTelephone' pattern="[a-z A-Z0-9- ]{12}" title="Please Enter Full lenght Telephone (12 digits)" onChange={this.AutoFormatContact} className="form-control" placeholder="xxx-xxx-xxxx" />
                            <span className="help-inline"> EXT </span>  <input type="text" pattern="[0-9]{1,10}" title="Please Enter extention (Max 10 Digits Only)" ref="ContactExt" className="form-control" placeholder="" />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-md-3 control-label">Cell Phone</label>
                          <div className="col-md-9">
                            <input type="text" ref="ContactPhone" id='contactPhone' pattern="[a-z A-Z0-9- ]{12}" title="Please Enter Full lenght Cellphone (12 digits)" onChange={this.AutoFormatContact} className="form-control" placeholder="xxx-xxx-xxxx" />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-md-3 control-label">Fax</label>
                          <div className="col-md-9">
                            <input type="text" ref="ContactFax" id='contactFax' pattern="[a-z A-Z0-9- ]{12}" title="Please Enter Full lenght fax (12 digits)" onChange={this.AutoFormatContact} className="form-control" placeholder="xxx-xxx-xxxx" />
                          </div>
                        </div>
                      </div>
                      <div className="clearfix"></div>
                      <div className="modal-footer">
                        <button type="button" className="btn default" data-dismiss="modal" onClick={this.FinishEditInfo.bind(this)}>Cancel</button> &nbsp;
                        <button type="submit" className="btn green-meadow"> <i className="fa fa-check"></i> Save</button>
                      </div>
                    </form>
                  </div>
                </div>

              </div>

            </div>

          </div>



        </div>

      </div>

    );
  }
}


export default withRouter(EditShipper);