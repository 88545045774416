/* eslint-disable */

import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Sidebar from './sidebar';
import Header from './Head';
import $ from 'jquery';
import BaseURL from './config';
import DatePicker from "react-datepicker";
import TokenErrorMsg from './errorMsg';

import getEditinfo from './StartEditMode';
import finishEditinfo from './FinishEditMode';

import tenantUser from './TenantUserPermision';
import Select from 'react-virtualized-select';

const Cryptr = require('cryptr');
const cryptr = new Cryptr('@$#rgweR35*&YFSD)/');

class EditDriver extends Component {

  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      status: 1,
      toDashboard: false,
      countries: [],
      states: [],
      driverid: '',
      whileSave: false,
      readonly: true,
      hidden: false,
      addressid: '',
      isLoading: false,
      token: '',
      fields: {},
      errors: {},
      carrier: [],
      carrierdata: '',
      driverform: '',
      MedExpDate: new Date(),
      LicExpDate: new Date(),
      file: {
        License: '',
        Medcertificate: '',
      },
      Licenseload: true,
      Medicalload: true,
      licenseStates: [],
      role: 'Tenant',
      CarrierSelectedOption: '',
      CarrierOptions: []
    };
    this.handleChange = this.handleChange.bind(this);
    this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);
    this.submit = this.submit.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.AutoFormatContact = this.AutoFormatContact.bind(this);
  }

  onUnload = (event) => {

    event.preventDefault();
    var http = new XMLHttpRequest();

    var url = BaseURL + 'editModeInfo/finishEditMode';

    var params = "driverId=" + this.state.driverid + "&token=" + this.state.token;

    http.open("POST", url, true);

    http.setRequestHeader("Content-type", "application/x-www-form-urlencoded");


    if (this.state.driverid) {
      http.send(params);
      var start = new Date().getTime();
      while (new Date().getTime() < start + 350);
    }



  }



  async componentDidMount() {

    this._isMounted = true;
    window.addEventListener("beforeunload", this.onUnload)
    const token = localStorage.getItem('Token');

    var role = 'Tenant';
    const Role = localStorage.getItem('Role');
    if (Role) {
      role = cryptr.decrypt(Role);
    }
    const id = this.props.match.params.id;
    if (this._isMounted) {
      this.setState({
        'token': token,
        driverid: id,
        Licenseload: false,
        Medicalload: false,
        role: role
      });
    }
    var arr = {
      'carriers': [],
      'countries': [],
      'states': [],
      'licenseStates': []

    };

    var driverform = {
      'status': '',
      'firstname': '',
      'lastname': '',
      'middlename': '',
      'carrierid': '',

      'addressid': '',
      'address': '',
      'addressLineTwo': '',
      'countryid': '',
      'stateid': '',
      'city': '',
      'zip': '',
      'email': '',
      'phone': '',
      'ext': '',
      'fax': '',

      'license': '',
      'licensestate': '',
      'licexpdate': '',
      'medexpdate': '',
    };
    var card = '';
    var License = '', Medcertificate = '', options = [];

    await axios.post(BaseURL + 'driver/findById', { 'token': token, 'id': id })
      .then(response => {
        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem('Token', '');
          this.props.history.push('/');
        }
        console.log(response.data)
        arr = response.data.data;
        var element = response.data.data.driver;


        if (element.status === 0) {
          $("#inactiveLabel").addClass('active');
        }
        else {
          $("#activeLabel").addClass('active');
        }

        driverform = {
          'status': element.status,
          'firstname': element.firstName,
          'lastname': element.lastName,
          'middlename': element.middleName,
          'carrierid': element.carrierId,

          'addressid': element.address.id,
          'address': element.address.address,
          'addressLineTwo': element.address.addressLineTwo,
          'countryid': element.address.addressCountryId,
          'stateid': element.address.addressStateId,
          'city': element.address.city,
          'zip': element.address.zip,
          'email': element.address.email,
          'phone': element.address.telephone,
          'ext': element.address.ext,
          'fax': element.address.fax,

          'license': element.licenseNo,
          'licensestate': element.licenseStateId,
          'licexpdate': new Date(element.licenseExpirationDate),
          'medexpdate': new Date(element.medicalCertificateExpirationDate),
        }
        arr.carriers.forEach(element => {
          if (element.id === driverform.carrierid) {
            card = {
              'address': element.address.address,
              'city': element.address.city,
              'state': element.address.state.state,

            }
          }
        });

        response.data.data.driverDocuments.forEach(element => {

          var filename = element.documentName;
          if (filename.length > 15) {
            filename = filename.substring(0, 15) + '....'

          }

          if (Number(element.documentType) === 1) {
            License = { 'name': filename };

          }
          else if (Number(element.documentType) === 2) {
            Medcertificate = { 'name': filename };

          }

        });


      }).catch(function (error) {
        NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);
        console.log(error);
      })

    arr.carriers.forEach(element => {

      var obj = { value: element.id, label: element.carrierName }

      if (element.id === driverform.carrierid) {
        driverform.carrierid = obj
      }

      options.push(obj)
    });


    if (this._isMounted) {

      this.setState({
        CarrierSelectedOption: driverform.carrierid,
        driverform: driverform,
        licenseStates: arr.licenseStates,
        status: driverform.status,
        carrier: arr.carriers,
        countries: arr.countries,
        addressid: driverform.addressid,
        LicExpDate: driverform.licexpdate,
        MedExpDate: driverform.medexpdate,
        states: arr.states,
        carrierdata: card,
        file: {
          License: License,
          Medcertificate: Medcertificate,
        },
        Licenseload: true,
        Medicalload: true,
        CarrierOptions: options
      });
    }





    //  await axios.post(BaseURL+'carrierInformation/',{'token':token}     
    //  ).then(function (response) {                    
    //            carrdata=response.data.data       

    //          })

    //  if(carrdata){
    //    this.setState({carrier:carrdata});
    //  }

    //        var card;
    //         this.state.carrier.forEach(element => {
    //           if(element.id == driverform.carrierid){
    //               card={
    //               'address':element.address.address,
    //               'city':element.address.city,
    //               'state':element.address.state.state,

    //             }
    //           }
    //         });


    //           this.setState({carrierdata:card})

    //       await axios.post(BaseURL+'countries',{  'token':token})
    //         .then(function (response) {

    //           arr = response.data.data;
    //           data=true;
    //         })

    //         if(data){
    //         this.setState({
    //             countries:arr,
    //             addressid:driverform.addressid,
    //             LicExpDate:driverform.licexpdate,
    //             MedExpDate:driverform.medexpdate,
    //           });        
    //         }
    //         var states;
    //         await axios.post(BaseURL+'countries/states',{
    //           "countryId": driverform.countryid,
    //           'token':this.state.token
    //         }).then(function (response) {

    //                   states = response.data.data;

    //                 })
    //         this.setState({states:states});        






  }

  componentWillUnmount() {

    this._isMounted = false;
    window.removeEventListener("beforeunload", this.onUnload);
    var config = {
      "driverId": this.state.driverid,
      "token": this.state.token
    }
    if (config.driverId) {
      finishEditinfo(config, (res) => {
        console.log(res);

      }, (err) => {

        console.log(err)
      });
    }
  }

  AutoFormatContact(e) {


    function phone_formatting(ele, restore) {
      var new_number,
        // selection_start = ele.selectionStart,
        // selection_end = ele.selectionEnd,
        number = ele.value.replace(/\D/g, '');

      // automatically add dashes
      if (number.length > 2) {
        // matches: 123 || 123-4 || 123-45
        new_number = number.substring(0, 3) + '-';
        if (number.length === 4 || number.length === 5) {
          // matches: 123-4 || 123-45
          new_number += number.substr(3);
        }
        else if (number.length > 5) {
          // matches: 123-456 || 123-456-7 || 123-456-789
          new_number += number.substring(3, 6) + '-';
        }
        if (number.length > 6) {
          // matches: 123-456-7 || 123-456-789 || 123-456-7890
          new_number += number.substring(6);
        }
      }
      else {
        new_number = number;
      }


      ele.value = (new_number.length > 12) ? new_number.substring(0, 12) : new_number;



    }

    function phone_number_check(field, e) {
      var key_code = e.keyCode,
        key_string = String.fromCharCode(key_code),
        press_delete = false,
        dash_key = 189,
        delete_key = [8, 46],
        direction_key = [33, 34, 35, 36, 37, 38, 39, 40],
        selection_end = field.selectionEnd;

      // delete key was pressed
      if (delete_key.indexOf(key_code) > -1) {
        press_delete = true;
      }

      // only force formatting is a number or delete key was pressed
      if (key_string.match(/^\d+$/) || press_delete) {
        phone_formatting(field, press_delete);
      }
      // do nothing for direction keys, keep their default actions
      else if (direction_key.indexOf(key_code) > -1) {
        // do nothing
      }
      else if (dash_key === key_code) {
        if (selection_end === field.value.length) {
          field.value = field.value.slice(0, -1)
        }
        else {
          field.value = field.value.substring(0, (selection_end - 1)) + field.value.substr(selection_end)
          field.selectionEnd = selection_end - 1;
        }
      }
      // all other non numerical key presses, remove their value
      else {
        e.preventDefault();
        //    field.value = field.value.replace(/[^0-9\-]/g,'')
        phone_formatting(field, 'revert');
      }

    }

    const Validate = (e) => {
      if (!String.fromCharCode(e.keyCode).match(/^- $/)) {
        this.handleChange(e)
      }
    }

    document.getElementById(e.target.id).onkeyup = function (e) {
      phone_number_check(this, e);
      Validate(e)
    }
  }

  async handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    if (this._isMounted) {
      await this.setState({
        fields
      });
    }
    this.validateForm();
  }

  submituserRegistrationForm(e) {
    e.preventDefault();
    var send = false;

    if (this.validateAndSubmit() && this.validateForm()) {

      send = true;

    }
    if (send) {

      this.submit();
    }

  }

  validateAndSubmit() {

    // let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    // CustomerNAme
    if (!this.refs.firstName.value || !this.refs.firstName.value.trim().length > 0) {
      formIsValid = false;
      errors["firstName"] = "*Please Enter Driver Name";
      $("[name='firstName']").focus();

    }

    else if (!this.state.CarrierSelectedOption.value) {
      formIsValid = false;
      errors["carrier"] = "*Please Select Carrier";
      $("[name='options']").focus();

    }


    // Address
    else if (!this.refs.address.value || !this.refs.address.value.trim().length > 0) {
      formIsValid = false;
      errors["address"] = "*Please Enter Address";
      $("[name='address']").focus();

    }


    // City
    else if (!this.refs.city.value || !this.refs.city.value.trim().length > 0) {
      formIsValid = false;
      errors["city"] = "*Please Enter City";
      $("[name='city']").focus();

    }

    // Zip
    else if (!this.refs.zip.value || !this.refs.zip.value.trim().length > 0) {
      formIsValid = false;
      errors["zip"] = "*Please Enter Zip";
      $("[name='zip']").focus();

    }
    else if (!this.refs.driverLicense.value || !this.refs.driverLicense.value.trim().length > 0) {
      formIsValid = false;
      errors["DriverLicense"] = "*Please Enter Driver License";
      $("[name='DriverLicense']").focus();

    }




    if (this._isMounted) {
      this.setState({
        errors: errors
      });
    }
    return formIsValid;
  }



  validateForm() {

    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;


    // customerName
    if (typeof fields["firstName"] !== "undefined" && fields["firstName"]) {
      if (!fields["firstName"].match(/^[a-zA-Z#'".,-/ ]{1,200}$/)) {
        formIsValid = false;
        errors["firstName"] = "*Only Alphabets Allowed (Max Range: 200)";
      }
    }

    if (typeof fields["lastName"] !== "undefined" && fields["lastName"]) {
      if (!fields["lastName"].match(/^[a-zA-Z#'".,-/ ]{1,200}$/)) {
        formIsValid = false;
        errors["lastName"] = "*Only Alphabets Allowed (Max Range: 200)";
      }
    }

    if (typeof fields["middleName"] !== "undefined" && fields["middleName"]) {
      if (!fields["middleName"].match(/^[a-zA-Z#'".,-/ ]{1,200}$/)) {
        formIsValid = false;
        errors["middleName"] = "*Only Alphabets Allowed (Max Range: 200)";
      }
    }




    //address

    if (typeof fields["address"] !== "undefined" && fields["address"]) {
      if (!fields["address"].match(/^[a-zA-Z0-9#'"،.,-;/ ]{1,200}$/)) {
        formIsValid = false;
        errors["address"] = "*Please Enter Only Valid Characters (Max Range: 200)";
      }
    }



    //addressLineTwo

    if (typeof fields["addressLineTwo"] !== "undefined" && fields["addressLineTwo"]) {
      if (!fields["addressLineTwo"].match(/^[a-zA-Z0-9#'"،.,-;/ ]{1,200}$/)) {
        formIsValid = false;
        errors["addressLineTwo"] = "*Please Enter Only Valid Characters (Max Range: 200)";
      }
    }

    //city

    if (typeof fields["city"] !== "undefined" && fields["city"]) {
      if (!fields["city"].match(/^[a-zA-Z0-9#., ]{1,200}$/)) {
        formIsValid = false;
        errors["city"] = "*Please Enter Only Valid Characters (Max Range: 200)";
      }
    }

    //zip

    if (typeof fields["zip"] !== "undefined" && fields["zip"]) {
      if (!fields["zip"].match(/^[a-zA-Z0-9 ]{1,35}$/)) {
        formIsValid = false;
        errors["zip"] = "*Only Numbers Allowed in Zip (Max Range: 35)";
      }
    }

    //telephone

    if (typeof fields["telephone"] !== "undefined" && fields["telephone"]) {
      if (!fields["telephone"].match(/^[0-9- ]{12,30}$/)) {
        formIsValid = false;
        errors["telephone"] = "*Please Enter only valid Telephone Number ( Range: 10)";
      }
    }

    //Ext

    if (typeof fields["ext"] !== "undefined" && fields["ext"]) {
      if (!fields["ext"].match(/^[0-9]{1,20}$/)) {
        formIsValid = false;
        errors["ext"] = "*Only Numbers Allowed (Max Range: 20)";
      }
    }
    //Fax

    if (typeof fields["fax"] !== "undefined" && fields["fax"]) {
      if (!fields["fax"].match(/^[0-9- ]{12,30}$/)) {
        formIsValid = false;
        errors["fax"] = "*Please Enter only valid fax Number ( Range: 10)";
      }
    }

    //email

    if (typeof fields["email"] !== "undefined" && fields["email"]) {
      if (!fields["email"].match(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/)) {
        formIsValid = false;
        errors["email"] = "*Please Enter Valid Email ";
      }
    }

    if (typeof fields["DriverLicense"] !== "undefined" && fields["DriverLicense"]) {
      if (!fields["DriverLicense"].match(/^[a-zA-Z0-9'-.#$ ]{1,30}$/)) {
        formIsValid = false;
        errors["DriverLicense"] = "*Please Enter Valid License  (Max Range: 30)";
      }
    }

    if (typeof this.state.LicExpDate !== "undefined" && this.state.LicExpDate) {
      if (this.state.LicExpDate.getFullYear() < 2000 || this.state.LicExpDate.getFullYear() > 2099) {
        formIsValid = false;
        errors["LicExpDate"] = "*Enter Valid Date ( Year Between 2000-2099 )";
      }
    }

    if (typeof this.state.MedExpDate !== "undefined" && this.state.MedExpDate) {
      if (this.state.MedExpDate.getFullYear() < 2000 || this.state.MedExpDate.getFullYear() > 2099) {
        formIsValid = false;
        errors["MedExpDate"] = "*Enter Valid Date ( Year Between 2000-2099 )";
      }
    }



    if (this._isMounted) {
      this.setState({
        errors: errors
      });
    }
    return formIsValid;


  }

  EditForm() {

    var div = document.getElementById('EditDiv');
    div.setAttribute("style", " cursor: not-allowed; pointer-events: none")

    var config = {
      "driverId": this.state.driverid,
      "token": this.state.token
    }

    getEditinfo(config, (res) => {
      console.log(res);
      if (res.data.status === 0) {
        if (this._isMounted) {
          this.setState({ readonly: false });
        }
      }
      else {
        div.removeAttribute("style")
        NotificationManager.warning(res.data.message, 'Warning Message', 3000);
      }
    }, (err) => {

      console.log(err)
    });

  }


  async states() {
    var arr;
    var data = false;

    var formData = this.state.driverform;
    formData['countryid'] = this.refs.country_id.value;
    formData['stateid'] = 1;

    if (this._isMounted) {
      this.setState({ driverform: formData });
    }


    await axios.post(BaseURL + 'countries/states', {
      "countryId": this.refs.country_id.value,
      'token': this.state.token
    })
      .then(response => {
        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem('Token', '');
          this.props.history.push('/');
        }
        arr = response.data.data;
        data = true;
      }).catch(function (error) {
        NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);
        console.log(error);
      })
    if (data && this._isMounted) {

      this.setState({ states: arr });
    }

  }




  async cancel() {
    if (window.confirm(' Are you sure you want to cancel ? '))
      this.props.history.push('/driver');

  }


  async submit() {
    if (this._isMounted) {
      this.setState({ whileSave: true, hidden: false, readonly: true })

    }

    const firstName = this.refs.firstName.value.trim();
    const lastName = this.refs.lastName.value.trim();
    const middleName = this.refs.middleName.value.trim();
    const carrierId = this.state.CarrierSelectedOption.value;

    const address = this.refs.address.value.trim();
    const address2 = this.refs.address2.value.trim();
    const country_id = this.refs.country_id.value;
    const province_id = this.refs.province_id.value;
    const city = this.refs.city.value.trim();
    const zip = this.refs.zip.value.trim();
    const email = this.refs.email.value.trim();
    const telephone = this.refs.telephone.value.trim();
    const fax = this.refs.fax.value.trim();
    const ext = this.refs.ext.value.trim();

    const bprovince_id = this.refs.bprovince_id.value;
    const driverLicense = this.refs.driverLicense.value.trim();



    var driver = {
      'token': this.state.token,
      'id': this.state.driverid,
      "firstName": firstName,
      "lastName": lastName,
      "middleName": middleName,
      "licenseNo": driverLicense,
      "licenseExpirationDate": this.state.LicExpDate,
      "medicalCertificateExpirationDate": this.state.MedExpDate,
      "status": this.state.status,
      "carrierId": carrierId,
      "licenseStateId": bprovince_id,

      "address": {
        'id': this.state.addressid,
        "address": address,
        "addressLineTwo": address2,
        "city": city,
        "zip": zip,
        "email": email,
        "telephone": telephone,
        "ext": ext,
        "fax": fax,
        "state": {
          "id": province_id,
          "country": {
            "id": country_id

          }
        }
      }
    }

    if (this.state.driverid) {


      await axios.post(BaseURL + 'driver/update', driver)
        .then(response => {
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem('Token', '');
            this.props.history.push('/');
          }
          else if (response.data.status === 1) {
            NotificationManager.error(response.data.message, 'Error', 3000);
            return;
          }
          else if (response.data.status === 0) {
            NotificationManager.error(response.data.message, 'Error', 3000);
            return;
          }
          console.log(response.data);
          NotificationManager.success('Driver upated Successfully', 'Edited', 3000);
        }).catch(function (error) {
          NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);
          console.log(error);
        })
      if (this._isMounted) {
        this.setState({
          whileSave: false
        })
      }


    }



  }




  loadcarrierinfo(e) {


    if (e.value) {

      var id = e.value;
      var data;

      this.state.carrier.forEach(element => {
        if (element.id === Number(id)) {
          data = {
            'address': element.address.address,
            'city': element.address.city,
            'state': element.address.state.state,

          }
        }
      });

      if (data && this._isMounted) {
        this.setState({ carrierdata: data })
      }
    }
    else {
      if (this._isMounted) {
        this.setState({ carrierdata: '' })
      }
    }

  }


  onFileChange = async (event) => {

    let errors = this.state.errors;

    event.preventDefault();
    event.persist();
    var file = await event.target.files[0];

    function getFileExtension(filename) {
      return filename.split('.').pop();
    }

    if (event.target.files[0]) {

      var filename = { 'name': file.name };


      if (file.type !== 'application/pdf' && file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/gif' && file.type !== 'image/tif' && getFileExtension(file.name) !== 'docx' && getFileExtension(file.name) !== 'doc' && getFileExtension(file.name) !== 'odt' && getFileExtension(file.name) !== 'xls' && getFileExtension(file.name) !== 'xlsx' && getFileExtension(file.name) !== 'ppt' && getFileExtension(file.name) !== 'pptx') {
        errors[event.target.name] = 'Please Upload only PDF ,Images, Document files ';
        if (this._isMounted) {
          this.setState({ errors: errors })
        }
        return false;
      }
      else if (file.size > 1000000) {
        errors[event.target.name] = 'Please Upload A File Less Than 1MB ( Max size for file: 1MB ) ';
        if (this._isMounted) {
          this.setState({ errors: errors })
        }
        return false;
      }
      else {
        errors[event.target.name] = '';
        if (this._isMounted) {
          this.setState({ errors: errors })
        }
      }

      if (filename.name.length > 15) {
        filename = {
          'name': filename.name.substring(0, 15) + '....'
        }
      }
      console.log(file);

      var doctype = event.target.id;
      var id = event.target.id;
      console.log(id);





      if (Number(id) === 1) {
        if (this._isMounted) {
          this.setState({

            Licenseload: false

          });
        }

      }
      else if (Number(id) === 2) {
        if (this._isMounted) {
          this.setState({
            Medicalload: false
          });
        }

      }



      console.log('enter in upload file');
      let data = new FormData();
      await data.append('file', file);
      await data.append('fileName', file.name);
      await data.append("driverId", this.state.driverid);
      await data.append("type", file.type);
      await data.append("documentType", doctype);



      await axios.post(BaseURL + 'driverDocuments/uploadFileToS3', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'token': this.state.token
        }
      })
        .then(response => {
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem('Token', '');
            this.props.history.push('/');
          }
          console.log(response.data)
          NotificationManager.success('Document Uploaded Successfully', 'Upload', 3000);

        }).catch(function (error) {
          NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);
          console.log(error);
        })

      if (Number(id) === 1) {
        if (this._isMounted) {
          await this.setState({
            Licenseload: true,
            file: {
              License: filename,
              Medcertificate: this.state.file.Medcertificate,
            }
          });
        }

      }
      else if (Number(id) === 2) {

        if (this._isMounted) {
          await this.setState({
            Medicalload: true,
            file: {
              License: this.state.file.License,
              Medcertificate: filename,


            }
          });
        }

      }


    }

  }

  deleteFile = (e) => {
    e.preventDefault();
    e.persist();
    var doctype = e.target.id;
    var id = e.target.id;
    console.log(id);



    if (window.confirm('Are You Sure To Remove Docment ?')) {


      console.log('enter in delete file');
      axios.post(BaseURL + 'driverDocuments/deleteFile', {
        "driverId": this.state.driverid,
        "documentType": doctype,
        'token': this.state.token

      })
        .then(response => {
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem('Token', '');
            this.props.history.push('/');
          }
          NotificationManager.success('Document Deleted Successfully', 'Delete', 3000);
          console.log(response.data)

        }).catch(function (error) {
          NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);
          console.log(error);
        })

      if (Number(id) === 1) {
        if (this._isMounted) {
          this.setState({

            file: {
              License: '',
              Medcertificate: this.state.file.Medcertificate,
            }
          });
        }

      }
      else if (Number(id) === 2) {
        if (this._isMounted) {
          this.setState({

            file: {
              License: this.state.file.License,
              Medcertificate: '',

            }
          });
        }

      }


    }


  }


  download = async (e) => {

    e.preventDefault();
    e.persist();
    var doctype = await e.target.id;
    var id = await e.target.id;


    if (Number(id) === 1) {
      if (this._isMounted) {
        this.setState({

          Licenseload: false

        });
      }

    }
    else if (Number(id) === 2) {
      if (this._isMounted) {
        this.setState({
          Medicalload: false
        });
      }

    }







    console.log(doctype);
    console.log('enter in download file');

    await axios.post(BaseURL + 'driverDocuments/getDocuments', {
      "driverId": this.state.driverid,
      'token': this.state.token
    })
      .then(response => {
        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem('Token', '');
          this.props.history.push('/');
        }

        response.data.documents.forEach(element => {
          if (element.documentType === doctype) {
            console.log(element);
            window.open(element.documentUrl, '_blank')

          }

        });

      }).catch(function (error) {
        NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);
        console.log(error);
      })

    if (Number(id) === 1) {
      if (this._isMounted) {
        this.setState({

          Licenseload: true

        });
      }

    }
    else if (Number(id) === 2) {
      if (this._isMounted) {
        this.setState({
          Medicalload: true
        });
      }

    }

  }

  selectState(e) {

    e.preventDefault();
    var formData = this.state.driverform;

    if (e.target.name === 'state' && this._isMounted) {
      formData['stateid'] = this.refs.province_id.value;
      this.setState({ driverform: formData });
    }
    else if (e.target.name === 'bstate' && this._isMounted) {
      formData['licensestate'] = this.refs.bprovince_id.value;
      this.setState({ driverform: formData });
    }


  }


  handleChangeCarrier = (selectedOption) => {

    if (this._isMounted) {

      var event = selectedOption ? selectedOption : '';
      console.log(selectedOption)
      this.setState({ CarrierSelectedOption: event });
      this.loadcarrierinfo(event);

    }

  }


  render() {

    const msgNone = {
      display: 'none'
    }
    const msgShow = {
      display: 'block',
    }

    const nonclick = {
      cursor: ' notAllowed',
      pointerEvents: 'none'
    }

    const { role } = this.state;

    return (
      <div className="page-container-bg-solid page-header-fixed page-sidebar-closed-hide-logo page-md">
        <Header title='| Driver' />
        <div className="page-container">
          <Sidebar />
          <div className="page-content-wrapper">

            <div className="page-content">

              <div className="page-head">



              </div>



              <div className="row">
                <div className="col-md-12">

                  <div className="tabbable-line boxless  tabbable-reversed">
                    <ul className="nav nav-tabs" hidden={this.state.hidden}>
                      <li className="active border-green-jungle"> <a href="#tab_0" data-toggle="tab"> Driver </a> </li>
                      <li className="border-green-jungle"> <a href="#tab_1" data-toggle="tab" > Documents </a> </li>
                    </ul>
                    <div className="tab-content">
                      <div className="tab-pane active" id="tab_0">

                        <div className="portlet box yellow-gold">
                          <div className="portlet-title">
                            <div className="caption"> <i className="fa fa-user"></i>Update Driver Information</div>
                            <div className="actions" style={this.state.readonly ? {} : nonclick} id='EditDiv'>
                              {role !== tenantUser ?
                                <div className="btn-group" >
                                  <button className="btn sbold white dark" onClick={this.EditForm.bind(this)} hidden={this.state.hidden} disabled={this.state.hidden}>
                                    <i className="fa fa-pencil"></i> Edit
                                  </button>
                                </div>
                                : null}
                            </div>
                          </div>
                          <div className="portlet-body form">

                            <form onSubmit={this.submituserRegistrationForm.bind(this)} className="horizontal-form" autoComplete="off" >
                              <div className="form-body max_width800">
                                <div className="portlet-title">
                                  <h2 className="h3"> Driver Info
                                    <span style={{ float: 'right', paddingLeft: ' 8px', marginTop: '2px' }} >
                                      <div className="btn-group btn-group-devided" data-toggle="buttons" style={this.state.readonly ? nonclick : msgShow} >
                                        <label className="btn btn-transparent dark btn-outline btn-circle btn-sm " id='activeLabel' disabled={this.state.readonly} onClick={() => { if (this._isMounted) { this.setState({ status: 1 }) } }}>
                                          <input type="radio" name="options" className="toggle" id="option1" />
                                          Active</label>
                                        <label className="btn btn-transparent dark btn-outline btn-circle btn-sm" id='inactiveLabel' disabled={this.state.readonly} onClick={() => { if (this._isMounted) { this.setState({ status: 0 }) } }}>
                                          <input type="radio" name="options1" className="toggle" id="option2" />
                                          Inactive</label>
                                      </div>
                                    </span>
                                  </h2>

                                  <div className="clearfix"></div>
                                  <hr />
                                </div>

                                <div className="form-group row">
                                  <label className="col-md-3 control-label">First Name: <span className="font-red-thunderbird" >*</span></label>
                                  <div className="col-md-9">
                                    <input type="text" ref="firstName" onChange={this.handleChange} name="firstName" className="form-control" placeholder="" defaultValue={this.state.driverform.firstname} readOnly={this.state.readonly} />
                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.firstName}</div>
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="col-md-3 control-label">Middle Name: <span className="font-red-thunderbird" ></span></label>
                                  <div className="col-md-9">
                                    <input type="text" ref="middleName" onChange={this.handleChange} name="middleName" className="form-control" placeholder="" defaultValue={this.state.driverform.middlename} readOnly={this.state.readonly} />
                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.middleName}</div>
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="col-md-3 control-label">Last Name: <span className="font-red-thunderbird" ></span></label>
                                  <div className="col-md-9">
                                    <input type="text" ref="lastName" onChange={this.handleChange} name="lastName" className="form-control" placeholder="" defaultValue={this.state.driverform.lastname} readOnly={this.state.readonly} />
                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.lastName}</div>
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="col-md-3 control-label">Carrier <span className="font-red-thunderbird" >*</span></label>
                                  <div className="col-md-9">
                                    <div className="form-group row">
                                      <div className="col-md-12">
                                        {/* <select className="form-control" ref='carrier' name='carrier' value={this.state.driverform.carrierid} onChange={this.loadcarrierinfo.bind(this)} disabled={this.state.readonly} >
                                 
                                                                       
                                              {
                                                this.state.carrier.map(function (data) {
                                                // if(this.state.driverform.carrierid === data.id)
                                                //   return <option value={data.id} selected> {data.carrierName} </option>;
                                                  return <option value={data.id} key={data.id}> {data.carrierName} </option>;
                                                },this)

                                              }
                                </select> */}

                                        <Select
                                          // disabled={this.state.readonly}
                                          disabled
                                          value={this.state.CarrierSelectedOption}
                                          options={this.state.CarrierOptions}
                                          onChange={this.handleChangeCarrier}
                                          placeholder='Select Carrier'
                                        />
                                        <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.carrier}</div>
                                      </div>

                                    </div>
                                    <div className="row" style={this.state.carrierdata.address ? msgShow : msgNone}>
                                      <div className="col-md-12">
                                        <div className="card_info">
                                          <div className="row">
                                            <div className="col-md-12">

                                              <p>
                                                <i className="fa fa-map-marker" aria-hidden="true"></i>&nbsp;
                                                {this.state.carrierdata.address}</p>
                                            </div>
                                          </div>
                                          <div className="row">
                                            <div className="col-md-6">

                                              <p><i className="fa fa-building" aria-hidden="true"></i>&nbsp;
                                                {this.state.carrierdata.city}</p>
                                            </div>
                                            <div className="col-md-6">

                                              <p><i className="fa fa-globe" aria-hidden="true"></i> &nbsp;
                                                {this.state.carrierdata.state}</p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="portlet-title">
                                  <h2 className="h3"> Address Info </h2>
                                  <hr />
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">Address <span className="font-red-thunderbird" >*</span></label>
                                  <div className="col-md-9">
                                    <input type="text" ref="address" onChange={this.handleChange} name="address" className="form-control" placeholder="" defaultValue={this.state.driverform.address} readOnly={this.state.readonly} />
                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.address}</div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">Address Line 2</label>
                                  <div className="col-md-9">
                                    <input type="text" ref="address2" onChange={this.handleChange} name="addressLineTwo" className="form-control" placeholder="" defaultValue={this.state.driverform.addressLineTwo} readOnly={this.state.readonly} />
                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.addressLineTwo}</div>
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="col-md-3 control-label">Country <span className="font-red-thunderbird" >*</span></label>
                                  <div className="col-md-9">


                                    <select name="country" ref="country_id" className="form-control" title="Country" value={this.state.driverform.countryid} onChange={this.states.bind(this)} disabled={this.state.readonly} readOnly={this.state.readonly}>


                                      {
                                        this.state.countries.map(function (data) {
                                          // if(this.state.driverform.countryid === data.id)
                                          // return <option value={data.id} selected> {data.countryName} </option>;
                                          return <option value={data.id} key={data.id}> {data.countryName} </option>;
                                        }, this)

                                      }

                                    </select>
                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.country}</div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">State <span className="font-red-thunderbird" >*</span></label>
                                  <div className="col-md-9">
                                    <select name="state" ref="province_id" className="form-control" value={this.state.driverform.stateid} onChange={this.selectState.bind(this)} disabled={this.state.readonly} readOnly={this.state.readonly}>

                                      {
                                        this.state.states.map(function (data) {
                                          // if(this.state.driverform.stateid === data.id)
                                          // return <option value={data.id} selected> {data.state} </option>;
                                          return <option value={data.id} key={data.id}> {data.state} </option>;
                                        }, this)

                                      }
                                    </select>
                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.state}</div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">City <span className="font-red-thunderbird" >*</span></label>
                                  <div className="col-md-9">
                                    <input type="text" ref="city" onChange={this.handleChange} name="city" className="form-control" placeholder="" defaultValue={this.state.driverform.city} readOnly={this.state.readonly} />
                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.city}</div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">Zip <span className="font-red-thunderbird" >*</span></label>
                                  <div className="col-md-9">
                                    <input type="text" ref="zip" onChange={this.handleChange} name="zip" className="form-control" placeholder="" defaultValue={this.state.driverform.zip} readOnly={this.state.readonly} />
                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.zip}</div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">Email</label>
                                  <div className="col-md-9">
                                    <input type="email" ref="email" onChange={this.handleChange} name="email" className="form-control" placeholder="" defaultValue={this.state.driverform.email} readOnly={this.state.readonly} />
                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.email}</div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">Telephone</label>
                                  <div className="col-md-9 form-inline">
                                    <input type="text" ref="telephone" id='Telephone' onChange={this.AutoFormatContact} name="telephone" className="form-control" defaultValue={this.state.driverform.phone} placeholder="xxx-xxx-xxxx" readOnly={this.state.readonly} />
                                    <span className="help-inline"> EXT </span>  <input type="text" name="ext" onChange={this.handleChange} ref="ext" className="form-control" placeholder="" defaultValue={this.state.driverform.ext} readOnly={this.state.readonly} />
                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.telephone}</div>
                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.ext}</div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">Fax</label>
                                  <div className="col-md-9">
                                    <input type="text" ref="fax" name="fax" id='fax' onChange={this.AutoFormatContact} className="form-control" defaultValue={this.state.driverform.fax} placeholder="xxx-xxx-xxxx" readOnly={this.state.readonly} />
                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.fax}</div>
                                  </div>
                                </div>


                                <div className="portlet-title">
                                  <h2 className="h3 checkbox-inline" style={{ padding: '0', margin: '0' }}> Driver Qualification File </h2>
                                  <hr />
                                </div>
                                <div className="portlet-title">
                                  <h4 className="h4" style={{ fontWeight: '700' }}> Driver License Info </h4>
                                  <br />
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">License No.<span className="font-red-thunderbird" >*</span></label>
                                  <div className="col-md-9">
                                    <input type="text" ref="driverLicense" onChange={this.handleChange} name="DriverLicense" className="form-control" placeholder="" defaultValue={this.state.driverform.license} readOnly={this.state.readonly} />
                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.DriverLicense}</div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">State <span className="font-red-thunderbird" >*</span></label>
                                  <div className="col-md-9">
                                    <select name="bstate" ref="bprovince_id" className="form-control" value={this.state.driverform.licensestate} onChange={this.selectState.bind(this)} disabled={this.state.readonly} readOnly={this.state.readonly}>

                                      {
                                        this.state.licenseStates.map(function (data) {
                                          // if(this.state.driverform.licensestate === data.id)
                                          // return <option value={data.id} selected> {data.state} </option>;
                                          return <option value={data.id} key={data.id}> {data.state} </option>;
                                        }, this)

                                      }
                                    </select>
                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.state}</div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">Expiration Date</label>
                                  <div className="col-md-9">

                                    <DatePicker
                                      className='form-control'
                                      selected={this.state.LicExpDate}
                                      onChange={async (date) => {
                                        if (this._isMounted) {
                                          await this.setState({
                                            LicExpDate: date
                                          });
                                        }
                                        console.log(date);
                                        this.validateForm();
                                      }}
                                      readOnly={this.state.readonly} />
                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.LicExpDate}</div>

                                  </div>
                                </div>




                                <div className="portlet-title">
                                  <h4 className="h4" style={{ fontWeight: '700' }}> Medical Certificate </h4>
                                  <br />
                                </div>

                                <div className="form-group row">
                                  <label className="col-md-3 control-label">Expiration Date</label>
                                  <div className="col-md-9">

                                    <DatePicker
                                      className='form-control'
                                      selected={this.state.MedExpDate}
                                      onChange={async (date) => {
                                        if (this._isMounted) {
                                          await this.setState({
                                            MedExpDate: date
                                          });
                                        }
                                        console.log(this.state.MedExpDate);
                                        this.validateForm();
                                      }}
                                      readOnly={this.state.readonly} />
                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.MedExpDate}</div>

                                  </div>
                                </div>




                              </div>
                              <div className="clearfix"></div>
                              <div className="form-actions right">
                                <button onClick={this.cancel.bind(this)} type="button" className="btn default">Cancel</button> &nbsp;

                                {role !== tenantUser ?
                                  <button disabled={this.state.readonly} type="submit" className="btn green-meadow">
                                    {this.state.whileSave === false
                                      ? <i className='fa fa-save'></i>
                                      : <i className="fa fa-circle-o-notch fa-spin"></i>}
                                    Save
                                  </button>
                                  : null}
                              </div>

                            </form>
                          </div>
                        </div>
                      </div>

                      <div className="tab-pane" id="tab_1">
                        <div className="portlet box yellow-gold">
                          <div className="portlet-title">
                            <div className="caption"> <i className="fa fa-file"></i> Documents </div>
                          </div>
                          <div className="portlet-body form">

                            <form action="#" className="horizontal-form" _lpchecked="1">
                              <div className="form-body max_width800">
                                <div className="form-group row">
                                  <label className="col-md-4 control-label">Driver License </label>
                                  <div className="col-md-8 fileinput ">


                                    <div className="input-group input-large doc_custom">
                                      <div className="form-control uneditable-input input-fixed input-medium" data-trigger="fileinput">
                                        <i className="fa fa-file fileinput-exists"></i>&nbsp;
                                        <span className="fileinput-filename">{this.state.file.License.name}</span>
                                      </div>
                                      {role !== tenantUser ?
                                        <span className="input-group-addon btn default btn-file">
                                          <span className="fileinput-new"> Upload file </span>
                                          <input type="file" id='1' name='license' onChange={this.onFileChange} /> </span>
                                        : null}
                                      {
                                        this.state.Licenseload ?
                                          <button hidden={this.state.file.License.name ? false : true} className="input-group-addon btn green fileinput-exists" id='1' onClick={this.download}> <i id='1' style={{ color: '#fff' }} className="fa fa-eye"></i> </button> :
                                          <button className="input-group-addon btn green fileinput-exists" id='1' onClick={(e) => e.preventDefault()}> <i id='1' style={{ color: '#fff' }} className='fa fa-circle-o-notch fa-spin'></i> </button>
                                      }
                                      {role !== tenantUser ?
                                        <button hidden={this.state.file.License.name ? false : true} className="input-group-addon btn red fileinput-exists" id='1' onClick={this.deleteFile}> <i id='1' style={{ color: '#fff' }} className="fa fa-trash"></i> </button>
                                        : null}
                                    </div>
                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.license}</div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-4 control-label">Medical Certificate</label>

                                  <div className="col-md-8 fileinput ">

                                    <div className="input-group input-large doc_custom">
                                      <div className="form-control uneditable-input input-fixed input-medium" data-trigger="fileinput">
                                        <i className="fa fa-file fileinput-exists"></i>&nbsp;
                                        <span className="fileinput-filename">{this.state.file.Medcertificate.name}</span>
                                      </div>
                                      {role !== tenantUser ?
                                        <span className="input-group-addon btn default btn-file">
                                          <span className="fileinput-new"> Upload file </span>
                                          <input type="file" id='2' name='certificate' onChange={this.onFileChange} /> </span>
                                        : null}
                                      {
                                        this.state.Medicalload ?
                                          <button hidden={this.state.file.Medcertificate.name ? false : true} className="input-group-addon btn green fileinput-exists" id='2' onClick={this.download}> <i id='2' style={{ color: '#fff' }} className="fa fa-eye"></i> </button> :
                                          <button className="input-group-addon btn green fileinput-exists" id='1' onClick={(e) => e.preventDefault()}> <i id='1' style={{ color: '#fff' }} className='fa fa-circle-o-notch fa-spin'></i> </button>


                                      }

                                      {role !== tenantUser ?
                                        <button hidden={this.state.file.Medcertificate.name ? false : true} className="input-group-addon btn red fileinput-exists" id='2' onClick={this.deleteFile}> <i id='2' style={{ color: '#fff' }} className="fa fa-trash"></i> </button>
                                        : null}
                                    </div>

                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.certificate}</div>
                                  </div>

                                </div>


                              </div>
                              <div className="clearfix"></div>

                            </form>

                          </div>
                        </div>
                      </div>






                    </div>
                  </div>





                </div>
              </div>

            </div>



          </div>

        </div>


      </div>



    );
  }
}

export default withRouter(EditDriver);


