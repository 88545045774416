/* eslint-disable */

import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import $ from 'jquery';
import Sidebar from './sidebar';
import Header from './Head';
import Map from './Map';
import "./custom-sidebar.css";
import DatePicker from "react-datepicker";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import timezone from 'node-google-timezone';
import moment from 'moment-timezone';
import 'moment-timezone';

const searchOptions = {
  componentRestrictions: { country: ['us'] },
}

const initialState = {
  soureAddress: '',
  destinationAddress: '',
  soureLatLng: '',
  destinationLatLng: '',
  sourceClock: {
    checkedOutTime: '',
    timeZone: '',
    timeZoneName: ''
  },
  destinationClock: {
    timeZone: '',
    timeZoneName: ''
  },
  totalMiles: 0,
  truckAvgSpeed: 50,
  extraMinutesForDriver: 30,
  estimatedDelveryDateTime: '',
  wayPoints: [],
  stopLimitError: '',

};

class Tools extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;

  }

  componentDidMount() {
    // document.getElementById('customSidebar').classList.add("open_sidebar");
    $('body').addClass('page-sidebar-closed');

  }

  componentWillUnmount() {
    $('body').removeClass('page-sidebar-closed');

  }

  handleSideBarToggle = () => {
    var value = document.getElementById('customSidebar').classList.value.search("open_sidebar");
    if (value === -1) {
      document.getElementById('customSidebar').classList.add("open_sidebar");
      document.getElementById('hamburger_icon').classList.add("fa-arrow-left");
      document.getElementById('hamburger_icon').classList.remove("fa-arrow-right");


    }
    else {
      document.getElementById('customSidebar').classList.remove("open_sidebar");
      document.getElementById('hamburger_icon').classList.remove("fa-arrow-left");
      document.getElementById('hamburger_icon').classList.add("fa-arrow-right");

    }

  }

  handleSourceAddressChange = soureAddress => {
    this.setState({ soureAddress });
  };

  handleSourceSelect = soureAddress => {
    this.setState({ soureAddress });
    geocodeByAddress(soureAddress)
      .then(results => getLatLng(results[0]))
      .then(soureLatLng => {
        this.setState({ soureLatLng });
        this.doTimeZoneApiCall(soureLatLng, this.setSourceClock);

      })

  };

  handleDestAddressChange = destinationAddress => {
    this.setState({ destinationAddress });
  };

  handleDestSelect = destinationAddress => {
    this.setState({ destinationAddress });
    geocodeByAddress(destinationAddress)
      .then(results => getLatLng(results[0]))
      .then(destinationLatLng => {
        this.setState({ destinationLatLng });
        this.doTimeZoneApiCall(destinationLatLng, this.setDestinationClock);
      })
  };

  doTimeZoneApiCall = (soureLatLng, func) => {

    timezone.key('AIzaSyDIPwhthYzVPP6RkJG9sl903Bk98XsCi0g');
    timezone.data(soureLatLng.lat, soureLatLng.lng, 1, function (err, tz) {
      func(tz)
    });
  }

  setSourceClock = (timeZone) => {

    if (timeZone.raw_response.status === "OK") {
      var checkedOutTime = moment.tz(new Date(), timeZone.raw_response.timeZoneId);
      var sourceClock = {
        checkedOutTime,
        timeZone: timeZone.raw_response.timeZoneId,
        timeZoneName: timeZone.raw_response.timeZoneName
      }

      this.setState({
        sourceClock
      })
    }
  }

  setDestinationClock = (timeZone) => {
    if (timeZone.raw_response.status === "OK") {
      var destinationClock = {
        timeZone: timeZone.raw_response.timeZoneId,
        timeZoneName: timeZone.raw_response.timeZoneName
      }
      this.setState({
        destinationClock
      })
    }
  }

  setTotalMiles = (miles) => {

    this.setState({
      totalMiles: miles
    }, () => this.calcuEstimationTime())
  }

  calcuEstimationTime = () => {

    var { totalMiles, truckAvgSpeed, extraMinutesForDriver, sourceClock, destinationClock, soureLatLng, destinationLatLng } = this.state;


    if (soureLatLng && destinationLatLng) {
      var driverHrs = Math.ceil((totalMiles / truckAvgSpeed).toFixed(2));
      var driverMinutes = driverHrs * 60;
      var extraMinutes = Math.ceil((driverMinutes / 720).toFixed(2)) * extraMinutesForDriver;
      driverMinutes = driverMinutes + extraMinutes;

      var estimatedDelveryDateTime = sourceClock.checkedOutTime.clone().tz(destinationClock.timeZone);

      estimatedDelveryDateTime.add(driverMinutes, 'minutes');

      this.setState({
        estimatedDelveryDateTime: estimatedDelveryDateTime.format('MM/DD/YYYY HH:mm')
      })

      console.log(driverMinutes, extraMinutes, sourceClock.checkedOutTime, destinationClock.timeZone)
    }

  }

  addIntermediateStops = () => {

    var wayPoints = [...this.state.wayPoints];

    if (wayPoints.length >= 9) {
      var stopLimitError = "Max Intermediate Stop's Limit is 9";
      this.setState({ stopLimitError });
      return;
    }

    wayPoints.push({
      value: '',
      LatLng: ''
    });
    this.setState({
      wayPoints
    });
  }

  delIntermediateStop = (index) => {
    var wayPoints = [...this.state.wayPoints];
    wayPoints.splice(index, 1);
    this.setState({ wayPoints, stopLimitError: '' });
  }

  getFormattedWayPoints = (wayPoints) => {
    var newWayPoints = [];
    wayPoints.forEach(element => {
      if (element.LatLng) {
        newWayPoints.push(element)
      }
    });

    return newWayPoints;
  }

  reset = () => {
    this.setState(initialState)
  }

  changeTruckAvgSpeed = (truckAvgSpeed) => {
    this.setState({
      truckAvgSpeed
    },
      () => this.calcuEstimationTime()
    )
  }

  changeDriverExtraMinutes = (extraMinutesForDriver) => {
    this.setState({
      extraMinutesForDriver
    },
      () => this.calcuEstimationTime()
    )
  }

  render() {

    var wayPoints = this.state.wayPoints;

    return (

      <div className="page-container-bg-solid page-header-fixed page-sidebar-closed-hide-logo page-md">
        <Header title='| Tools' />
        <div className="page-container">
          <Sidebar />


          <div className="page-content-wrapper">

            <div className="page-content">

              <div className="row">
                <div className="col-md-12 tools_map">
                  <nav className="navbar" id="sidebar-wrapper" role="navigation">
                    {/* <button type="button" className="hamburger is-closed" data-toggle="offcanvas" onClick={this.handleSideBarToggle}>
              <i className="fa fa-arrow-left" id="hamburger_icon" aria-hidden="true"></i>
            </button> */}
                    <ul className="nav sidebar-nav" id="customSidebar">
                      <div className="input_wrapper">
                        <button type="button" className="btn btn-success btn-sm" style={{ borderRadius: "15px" }} onClick={this.reset}>RESET</button>
                        <div className="map_icon">
                        </div>
                        <form>
                          <div className="form-inline">
                            <label>
                              <i className="fa fa-circle-thin" aria-hidden="true"></i>
                              <PlacesAutocomplete
                                searchOptions={searchOptions}
                                value={this.state.soureAddress}
                                onChange={this.handleSourceAddressChange}
                                onSelect={this.handleSourceSelect}
                                onError={(e) => console.log(e)}
                                clearItemsOnError={true}
                              >
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                  <div>
                                    <input type="text" ref="currLoc" {...getInputProps({
                                      className: 'location-search-input',
                                    })} name="currLoc" className="form-control" placeholder="Choose Starting Point..." readOnly={this.state.readonly} />


                                    <div className="autocomplete-dropdown-map-container" >
                                      {loading && <div>Loading...</div>}
                                      {suggestions.map(suggestion => {
                                        const className = suggestion.active
                                          ? 'suggestion-item--active'
                                          : 'suggestion-item';
                                        return (
                                          <div
                                            {...getSuggestionItemProps(suggestion, {
                                              className,

                                            })}
                                          >
                                            <span >{suggestion.description}</span>
                                          </div>
                                        );
                                      })}
                                    </div>

                                  </div>

                                )}
                              </PlacesAutocomplete>
                              {/* <input type="text" className="form-control" name="currLoc" placeholder="Choose Starting Point..." /> */}
                            </label>
                          </div>

                          {
                            this.state.sourceClock.checkedOutTime ?
                              <div className="checkout_time">
                                <DatePicker
                                  // disabled={this.state.readonly }
                                  // className='form-control'                                                                                                                 
                                  selected={new Date(this.state.sourceClock.checkedOutTime.format("MM/DD/YYYY HH:mm"))}
                                  showTimeSelect
                                  // showTimeInput
                                  placeholderText="Checkout Time..."
                                  timeInputLabel="Input Time:"
                                  timeFormat="HH:mm"
                                  timeIntervals={30}
                                  dateFormat={"MM/dd/yyyy HH:mm"}
                                  timeCaption="Time"
                                  onChange={
                                    (checkedOutTime) => {
                                      var sourceClock = this.state.sourceClock;
                                      sourceClock.checkedOutTime.set(moment(checkedOutTime).toObject());
                                      this.setState(
                                        { sourceClock },
                                        () => this.calcuEstimationTime()
                                      )
                                    }}
                                />
                              </div> : null

                          }


                          {
                            this.state.sourceClock.timeZone ?
                              (<div style={{ marginLeft: "25px" }}>
                                <h6 style={{ color: "#fff" }}><b>Starting Point Time Zone:</b></h6>
                                <h6 style={{ color: "#fff" }}>&nbsp;{this.state.sourceClock.timeZone}</h6>
                                <h6 style={{ color: "#fff" }}><b>Starting Point Time Zone Name:</b></h6>
                                <h6 style={{ color: "#fff" }}>&nbsp;{this.state.sourceClock.timeZoneName}</h6>
                              </div>) : null
                          }



                          {
                            wayPoints.map((wayPoint, index) => {
                              return (
                                <div className="form-inline" key={index}>
                                  <label>
                                    <a onClick={() => { this.delIntermediateStop(index) }}><i className="fa fa-times" aria-hidden="true"></i></a>

                                    <PlacesAutocomplete
                                      searchOptions={searchOptions}
                                      value={wayPoint.value}
                                      onChange={(address) => {
                                        var newWayPoints = wayPoints;
                                        newWayPoints[index]['value'] = address;
                                        this.setState({
                                          wayPoints: newWayPoints
                                        })
                                      }}

                                      onSelect={(address) => {
                                        var newWayPoints = wayPoints;
                                        newWayPoints[index]['value'] = address;
                                        this.setState({
                                          wayPoints: newWayPoints
                                        })
                                        geocodeByAddress(address)
                                          .then(results => getLatLng(results[0]))
                                          .then(soureLatLng => {
                                            newWayPoints[index]['LatLng'] = soureLatLng
                                            this.setState({
                                              wayPoints: newWayPoints
                                            })
                                          })
                                      }}
                                      onError={(e) => console.log(e)}
                                      clearItemsOnError={true}

                                    >
                                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                        <div>
                                          <input type="text" ref="currLoc" {...getInputProps({
                                            className: 'location-search-input',
                                          })} name="currLoc" className="form-control" placeholder="Search Intermediate Stop" readOnly={this.state.readonly} />


                                          <div className="autocomplete-dropdown-map-container" >
                                            {loading && <div>Loading...</div>}
                                            {suggestions.map(suggestion => {
                                              const className = suggestion.active
                                                ? 'suggestion-item--active'
                                                : 'suggestion-item';
                                              return (
                                                <div
                                                  {...getSuggestionItemProps(suggestion, {
                                                    className,

                                                  })}
                                                >
                                                  <span >{suggestion.description}</span>
                                                </div>
                                              );
                                            })}
                                          </div>

                                        </div>

                                      )}
                                    </PlacesAutocomplete>
                                  </label>
                                </div>
                              );
                            })
                          }
                          <div style={{ color: 'white' }}>{this.state.stopLimitError}</div>
                          <div className="form-inline">
                            <label>
                              <a onClick={this.addIntermediateStops}><i className="fa fa-plus-circle" aria-hidden="true"></i></a>
                              <input type="text" style={{ borderBottom: 'none' }} placeholder="Add Intermediate Stop (Max limit is 9)" readOnly />
                            </label>
                          </div>

                          <div className="form-inline">
                            <label><i className="fa fa-map-marker" aria-hidden="true"></i>
                              <PlacesAutocomplete
                                searchOptions={searchOptions}
                                value={this.state.destinationAddress}
                                onChange={this.handleDestAddressChange}
                                onSelect={this.handleDestSelect}
                                onError={(e) => console.log(e)}
                                clearItemsOnError={true}

                              >
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                  <div>
                                    <input type="text" ref="currLoc" {...getInputProps({
                                      className: 'location-search-input',
                                    })} name="currLoc" className="form-control" placeholder="Choose destination..." readOnly={this.state.readonly} />


                                    <div className="autocomplete-dropdown-map-container" >
                                      {loading && <div>Loading...</div>}
                                      {suggestions.map(suggestion => {
                                        const className = suggestion.active
                                          ? 'suggestion-item--active'
                                          : 'suggestion-item';
                                        return (
                                          <div
                                            {...getSuggestionItemProps(suggestion, {
                                              className,

                                            })}
                                          >
                                            <span >{suggestion.description}</span>
                                          </div>
                                        );
                                      })}
                                    </div>

                                  </div>

                                )}
                              </PlacesAutocomplete>

                              {
                                this.state.destinationClock.timeZone ?
                                  (<div style={{ marginLeft: "25px" }}>
                                    <h6 style={{ color: "#fff" }}><b>Destination Point Time Zone:</b></h6>
                                    <h6 style={{ color: "#fff" }}>&nbsp;{this.state.destinationClock.timeZone}</h6>
                                    <h6 style={{ color: "#fff" }}><b>Destination Point Time Zone Name:</b></h6>
                                    <h6 style={{ color: "#fff" }}>&nbsp;{this.state.destinationClock.timeZoneName}</h6>
                                  </div>) : null
                              }
                            </label>
                          </div>
                        </form>
                        <br />
                      </div>

                      <div className="date_wapper">
                        <h6><b>Total Miles</b></h6>
                        <div>
                          {this.state.totalMiles}
                        </div>

                        <h6><b>Truck average speed (Max limit is 150)</b></h6>
                        <div className="btn-group btn-group-sm" role="group" aria-label="Basic example">
                          <button type="button" className="btn btn-secondary" onClick={() => this.changeTruckAvgSpeed(45)}>45</button>
                          <button type="button" className="btn btn-secondary" onClick={() => this.changeTruckAvgSpeed(50)}>50</button>
                          <button type="button" className="btn btn-secondary" onClick={() => this.changeTruckAvgSpeed(55)}>55</button>
                          <button type="button" className="btn btn-secondary" onClick={() => this.changeTruckAvgSpeed(60)}>60</button>
                          <button type="button" className="btn btn-secondary" onClick={() => this.changeTruckAvgSpeed(65)}>65</button>
                          <button type="button" className="btn btn-secondary" onClick={() => this.changeTruckAvgSpeed(70)}>70</button>
                        </div>
                        <br />  <br />

                        <div className="input-group">
                          <input type="number" style={{ zIndex: 0 }} className="form-control" placeholder="Mph..." value={this.state.truckAvgSpeed} aria-describedby="basic-addon2"
                            onChange={(e) => {
                              if (e.target.value < 1 || e.target.value > 150) {
                                return;
                              }

                              var truckAvgSpeed = parseFloat(e.target.value);
                              this.setState({
                                truckAvgSpeed
                              },
                                () => this.calcuEstimationTime()
                              )
                            }} />
                          <span className="input-group-addon" id="basic-addon2">MPH</span>
                        </div>
                        <h6><b>Extra Minutes 0 - 12 hrs (Max limit is 120)</b></h6>

                        <div className="btn-group btn-group-sm" role="group" aria-label="Basic example">
                          <button type="button" className="btn btn-secondary" onClick={() => this.changeDriverExtraMinutes(0)}>0</button>
                          <button type="button" className="btn btn-secondary" onClick={() => this.changeDriverExtraMinutes(30)}>30</button>
                          <button type="button" className="btn btn-secondary" onClick={() => this.changeDriverExtraMinutes(60)}>60</button>
                          <button type="button" className="btn btn-secondary" onClick={() => this.changeDriverExtraMinutes(90)}>90</button>
                          <button type="button" className="btn btn-secondary" onClick={() => this.changeDriverExtraMinutes(120)}>120</button>
                        </div>

                        <div>
                          <input type="number" className="form-control" placeholder="" value={this.state.extraMinutesForDriver} aria-describedby="basic-addon2"
                            onChange={(e) => {
                              if (e.target.value < 1 || e.target.value > 120) {
                                return;
                              }
                              var extraMinutesForDriver = parseFloat(e.target.value);
                              this.setState({
                                extraMinutesForDriver
                              },
                                () => this.calcuEstimationTime()
                              )
                            }} />
                        </div>

                        <h6><b>Estimated Destination Delivery Time</b></h6><br />
                        <span> {this.state.estimatedDelveryDateTime}</span>


                      </div>

                    </ul>
                  </nav>
                  <div className="map_wrapper">
                    <Map
                      soureLatLng={this.state.soureLatLng}
                      destinationLatLng={this.state.destinationLatLng}
                      wayPoints={this.getFormattedWayPoints(wayPoints)}
                      setTotalMiles={this.setTotalMiles}
                    />
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>

    );
  }
}

export default withRouter(Tools);