/* eslint-disable */ 

import React from 'react';
import Loader from 'react-loader-spinner';
import currencyFormatter from 'currency-formatter';
import CurrencyInput from 'react-currency-input';
import tenantUser from "./TenantUserPermision";


      export default ({
       additionalCharges,
       Documents,
       totalAmount,
       submitForm,
       resetAdditionalChargesPopUp,
       addCharges,
       deleteCharges,
       setPaymentType,
       handleInputChange,
       InputValues,
       download, 
       handleCheckBox,
       paymentType,    
       invoiceURL, 
       deleteInvoice, 
       invoiceLoader, 
       loadRate,
       invoiceFileName,
       quickPay,
       inputQuickPay,
       invoiceTo,
       setInvoiceTo,
       factoringCompany,
       invoiceStatus,
       setInvoiceStatus,
       role   
  })=>   <React.Fragment>          
              <div className="modal-content">
                <div className="modal-header">  
                  <h4 className="modal-title">Invoice Details</h4>
                </div>
                <div className="modal-body"> 
                      <div className="portlet-body form"> 
                      
                        <form   className="horizontal-form" autoComplete="off" >
                          <div className="form-body max_width800">                                      
                                             
                            
                        <div className="portlet-title">
                          <h2 className="h3"> Amount Details: </h2>
                          <hr/>
                        </div> 

                           <div className="form-group row">
                              <label className="col-md-3 control-label"><b>Total Amount: </b><span className="font-red-thunderbird" ></span></label>
                              <div className="col-md-9">
                                {totalAmount} 
                                                            
                            
                              </div>
                            </div>

                            <hr/>

                            <div className="form-group row">
                              <label className="col-md-3 control-label">Load Rate: <span className="font-red-thunderbird" ></span></label>
                              <div className="col-md-9">
                                {currencyFormatter.format(loadRate, { code: 'USD' })}                             
                            
                              </div>
                            </div>

                            {
                              additionalCharges.map(( additionalCharge , index )=>{

                                

                               return <div className="form-group row" key={index}>
                                <label className="col-md-3 control-label"> { additionalCharge.chargesType }: <span className="font-red-thunderbird" ></span></label>
                                <div className="col-md-3">
                                   { additionalCharge.amount }  

                                                       
                              
                                </div>

                                {role !== tenantUser ?
                                <div className="col-md-6">
                                   <button type='button' className="btn  red" 
                                      onClick={(e)=>{

                                        e.preventDefault();

                                        if(window.confirm('Are you sure you want to delete ?'))
                                        {

                                          deleteCharges( index );

                                        }


                                      }}>Delete</button>    
                                </div> : null
                                }                             
                              </div>

                              })

                            }

                            {role !== tenantUser ? 
                            <div className="form-group row">
                              <label className="col-md-3 control-label">&nbsp;</label>
                              <div className="col-md-7">
                                  <p>
                                    <a data-toggle="modal" href="#additionalCharges"                                     
                                    onClick={
                                      (e) => {
                                        e.preventDefault();
                                      }
                                      } 
                                    className="btn  green-meadow"> <i className="fa fa-plus"></i> Add Additional Charges</a>
                                  </p>
                              </div>
                            </div> : null
                            }

                        <div className="portlet-title">
                          <h2 className="h3"> Status Details: </h2>
                          <hr/>
                        </div> 

                        <div className="form-group row">
                              <label className="col-md-3 control-label">Select Status:</label>
                              <div className="col-md-9">
                                 <select className="form-control" value={invoiceStatus} 
                                 onChange = {(e) => {
                                   e.preventDefault();
                                   var value = e.target.value;
                                   setInvoiceStatus(value);
                                 }}
                                 >
                                   <option value='Processed'>Processed</option>
                                   <option value='Delete'>Delete</option>
                                   <option value='Paid'>Paid</option>
                                 </select>
                              </div>
                         </div>


    

                        <div className="portlet-title">
                          <h2 className="h3"> Documents Details: </h2>
                          <hr/>
                        </div> 


                        {Documents.map((element, index)=>{
                        
                        return( element.fileName ? 
                        <div className="form-group row" key={element.documentNo}>
                          <label className="col-md-3 control-label">{element.label}</label>
                         
                          <div className="col-md-1">

                          <div className="checkbox-list" style={{ display: 'inline-block', verticalAlign: '22px', paddingTop:'5px'}}>
                                  <label className="checkbox-inline">
                                  <div className="checker" >
                                     <span className={element.checked ? 'checked':''} >
                                      <input type="checkbox"  onClick={()=>handleCheckBox( index )} value="option1" />
                                      </span>
                                      </div>
                                     </label>
                          </div>

                          </div>
                          

                          <div className="col-md-6">
                          
                          <div className="fileinput ">    

                              <div className="input-group input-large doc_custom">
                                  <div className="form-control uneditable-input input-fixed input-medium" data-trigger="fileinput">
                                      <i className="fa fa-file fileinput-exists"></i>&nbsp;
                                      <span className="fileinput-filename">{element.fileName}</span>
                                  </div>
                               
                                      {
                                        element.showIcons?
                                        <button hidden={element.fileName ?false:true }   className="input-group-addon btn green fileinput-exists" 
                                          onClick={(e)=>{
                                             e.preventDefault();
                                             download(index,Documents[index])
                                            }}> 
                                            <i   style={{color:'#fff'}}  className="fa fa-eye"></i> </button>:
                                        <button className="input-group-addon btn green fileinput-exists"   onClick={(e)=>e.preventDefault() }> <i   style={{color:'#fff'}}  className='fa fa-circle-o-notch fa-spin'></i> </button> 
                                      }                                    
                                     
                                     
                                      
                              </div> 
                              <div style= {{color: 'red'}} className="errorMsg">{element.invoiceError}</div>                                                   
                          </div>
                         </div>
                        </div>: null
                        );
                                    }) }

                        
                        <div className="portlet-title">
                          <h2 className="h3"> Invoice To: </h2>
                          <hr/>
                        </div>

                        
                        <div className="form-group row">
                              <label className="col-md-3 control-label">Select Invoice Type: <span className="font-red-thunderbird" ></span></label>
                              <div className="col-md-9">

                              <span style={{marginTop: '2px', marginTop: '6px',position:'relative',zIndex:0}}>
                                <div className="btn-group btn-group-devided" data-toggle="buttons">
                                  <label className=  { invoiceTo === 'Customer' ? "btn btn-transparent dark btn-outline btn-circle btn-sm active" : 'btn btn-transparent dark btn-outline btn-circle btn-sm'}  
                                  onClick={
                                    (e)=>{
                                      e.preventDefault();
                                      setInvoiceTo('Customer')                                      
                                  }
                                    }>
                                    <input type="radio" name="options" className="toggle" id="option1" />
                                    Customer</label>
                                  {factoringCompany ? 
                                  <label className=  { invoiceTo === 'Factoring Company' ? "btn btn-transparent dark btn-outline btn-circle btn-sm active" : 'btn btn-transparent dark btn-outline btn-circle btn-sm'} 
                                  onClick={
                                    (e)=>{
                                      e.preventDefault();
                                      setInvoiceTo('Factoring Company')
                                     }
                                    }>
                                    <input type="radio" name="options" className="toggle" id="option2"/>
                                    Factoring Company </label>
                                    :null}
                                </div>
                              </span>
                              
                              </div>
                          </div>


                        { factoringCompany && invoiceTo === 'Factoring Company' ?
                          <div className="form-group row">
                          <label className="col-md-3 control-label"> <span className="font-red-thunderbird" ></span></label>
                          <div className="col-md-9">
                              
                              <div className="row" >
                                  <div className="col-md-12">                                    
                                      <div className="card_info">
                                          <div className="row">
                                            <div className="col-md-12"> 
                                            
                                              <p>
                                                <i className="fa fa-map-marker" aria-hidden="true"></i>&nbsp;
                                                 {factoringCompany.address.address}</p>
                                            </div>
                                          </div>
                                          <div className="row">
                                            <div className="col-md-6"> 
                                             
                                              <p><i className="fa fa-building" aria-hidden="true"></i>&nbsp;
                                                 {factoringCompany.address.city}</p>
                                            </div>
                                            <div className="col-md-6"> 
                                             
                                              <p><i className="fa fa-globe" aria-hidden="true"></i> &nbsp;
                                                 {factoringCompany.address.state.state}</p>
                                            </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                        </div> : null }




                  { invoiceTo === 'Customer' ?   
                        <React.Fragment>
                        <div className="portlet-title">
                          <h2 className="h3"> Payment Type: </h2>
                          <hr/>
                        </div> 

                            <div className="form-group row">
                              <label className="col-md-3 control-label">Select Payment Type: <span className="font-red-thunderbird" ></span></label>
                              <div className="col-md-9">

                              <span style={{marginTop: '2px', marginTop: '6px',position:'relative',zIndex:0}}>
                                <div className="btn-group btn-group-devided" data-toggle="buttons">
                                  <label className=  { paymentType === 'Quick Pay' ? "btn btn-transparent dark btn-outline btn-circle btn-sm active" : 'btn btn-transparent dark btn-outline btn-circle btn-sm'} id='dry' 
                                  onClick={
                                    (e)=>{
                                      e.preventDefault();

                                      // paymentType='quickPay';
                                     
                                      // this.setState({paymentType})

                                      setPaymentType('Quick Pay')
                                      
                                  }
                                    }>
                                    <input type="radio" name="options" className="toggle" id="option1" />
                                    QUICK PAY</label>
                                  <label className=  { paymentType === 'Regular Pay' ? "btn btn-transparent dark btn-outline btn-circle btn-sm active" : 'btn btn-transparent dark btn-outline btn-circle btn-sm'} id='reefer'
                                  onClick={
                                    (e)=>{

                                      e.preventDefault();



                                      // paymentType='regularPay';
                                   
                                      // this.setState({paymentType})    
                                      
                                      setPaymentType('Regular Pay')

                                     }
                                    }>
                                    <input type="radio" name="options" className="toggle" id="option2"/>
                                    REGULAR PAY</label>
                                </div>
                              </span>
                              
                              </div>
                            </div>
                            </React.Fragment>
                            : null }

                       { paymentType === 'Quick Pay' && invoiceTo === 'Customer' ?

                          <div className="form-group row">
                            <label className="col-md-3 control-label">Quick Pay %</label>
                            <div className="col-md-9">
                            <input type="number" value={quickPay} className="form-control" placeholder="" 
                            onChange={(event)=>{
                              event.preventDefault();
                              inputQuickPay(event.target.value);
                            }}/>
                            </div>
                          </div> 
                          : null }                        

                         <div className="portlet-title">
                          <h2 className="h3"> Invoice Details: </h2>
                          <hr/>
                        </div>


               

                            <div className="form-group row">
                              <label className="col-md-3 control-label">Generate Invoice: <span className="font-red-thunderbird" ></span></label>
                              {role !== tenantUser ?
                              <div className="col-md-2">
                                 <button type='button' className="btn  green-meadow" 
                               onClick={(e)=>{

                                    e.preventDefault();
                                 
                                     submitForm()
                                    }
                                  
                                 }>INVOICE</button>                                
                                
                           

                               </div> : null
                               } 

                             {invoiceURL ? 

                              <div className="col-md-6">
                          
                                <div className="fileinput ">    

                                    <div className="input-group input-large doc_custom">
                                        <div className="form-control uneditable-input input-fixed input-medium" data-trigger="fileinput">
                                            <i className="fa fa-file fileinput-exists"></i>&nbsp;
                                            <span className="fileinput-filename">{invoiceFileName}</span>
                                        </div>
                                    
                                                                                       
                                              <button className="input-group-addon btn green fileinput-exists" 
                                                onClick = {
                                                  (e)=>{
                                                    e.preventDefault();
                                                    window.open(invoiceURL,'_blank') 
               
                                                  }
                                                }
                                                  > 
                                                  <i   style={{color:'#fff'}}  className="fa fa-eye"></i> </button>

                                              {role !== tenantUser ?
                                              <button   className="input-group-addon btn red fileinput-exists"  
                                                onClick = {
                                                  (e)=>{
                                                    e.preventDefault();
                
                                                    deleteInvoice();
                
                                                  }
                                                }
                                                  > 
                                                  <i  style={{color:'#fff'}} className="fa fa-trash"></i> 
                                              </button> : null
                                              }
                                                                               
                                          
                                          
                                            
                                    </div> 
                                                                                     
                                </div>
                              </div>: null}



                            </div>

                        

                                 


                          

                           
                          </div>      
                          <div className="clearfix"></div>
                          <div className="form-actions right">   
                          <div style={ invoiceLoader ? {
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            top: '0',
                            background: '#FFFFFF7F',
                            zIndex: '9',
                            left: '0',
                            textAlign: 'center',
                            padding: '20% 0 0',
                            lineHeight: '100%',
                            
                          } : {display:'none'}
                        }>
                                 <Loader 
                                    type="Bars"
                                    color="#A91926"
                                    height="100"	
                                    width="100"
                                  />   
                          </div>     

                          </div>
                        
                        </form>
                    </div>

    </div> 

  </div>
  

<div className="modal fade" id="additionalCharges" data-backdrop="static" data-keyboard="false" tabIndex="-1"  aria-hidden="true" >
<div className="modal-dialog  modal-lg">
    <div className="modal-content">
        <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal" aria-hidden="true"            
            onClick={
              (e)=>{
                e.preventDefault();              
                resetAdditionalChargesPopUp(e);
              }
             }
            ></button>
            <h4 className="modal-title">Additional Charges</h4>
        </div>
        <div className="modal-body"> 
          <div className="form-group row">
              <label className="col-md-3 control-label">Charges Type:</label>
              <div className="col-md-8">
                  <select className="form-control" name='chargesType' value={InputValues.chargesType} 
                  onChange={(e)=> handleInputChange(e.target.name ,e.target.value) }  >
                    
                      <option value='Lumper'>Lumper</option>
                      <option value='Layover'>Layover</option>
                      <option value='Detention'>Detention</option>
                      <option value='Other'>Other</option>
                  </select>
              </div>
            
            </div>

            <div className="form-group row">
              <label className="col-md-3 control-label">Amount $:</label>
              <div className="col-md-8">

              <CurrencyInput precision="2" prefix="$" name='amount' value={InputValues.amount}
                   onChangeEvent={
                     (event, maskedvalue, floatvalue)=>{
                      //  console.log(maskedvalue, floatvalue)
                      handleInputChange(event.target.name ,maskedvalue);                                 
                       

                     }
                  } 
                   className="form-control"  placeholder=""/>

              </div>
            
            </div>

        </div>
        <div className="modal-footer">
            <button type="button" className="btn default" data-dismiss="modal"
             onClick={
              (e)=>{
                e.preventDefault();               
                resetAdditionalChargesPopUp(e);
              }
             }>Cancel</button> &nbsp;
            <button type="submit" className="btn green-meadow"  data-dismiss="modal" 
             onClick= {
               (e) => {
                e.preventDefault();               
                addCharges(e);
               }
                
                } > <i className="fa fa-check" ></i> Add</button>
        </div>
  </div>

</div>
</div>
</React.Fragment>


      
     
    

//     );
// }
// }

// export default withRouter(InvoiceTab);