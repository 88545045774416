/* eslint-disable */

import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import MUiTable from "./MuiTable";
import axios from "axios";
import Sidebar from "./sidebar";
import Header from "./Head";
import BaseURL from "./config";
import { NotificationManager } from "react-notifications";
import f from "./Loading_icon.gif";
import TokenErrorMsg from "./errorMsg";
import { CSVLink } from 'react-csv';
// import tenantUser from './TenantUserPermision';
import Tick from './green-tick.jpg';
import Cross from './red-cross.jpg';
import { CircularProgress } from '@material-ui/core';
import Select from "react-virtualized-select";

import moment from "moment";
import DatePicker from "react-datepicker";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import currencyFormatter from "currency-formatter";
import { getPersistentTableState, setPersistentTableState } from './LocalStorage';

class Accouting extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      readyForCsvDownload: false,
      readyCsvDownloadFile: false,
      advanceSearchFormValues: {
        sfCustomerName: "",
        sfCarrierName: "",
        // sfPickupCity: "",
        // sfDeliveryCity: "",
        sfShipper: "",
        sfReciever: "",
      },
      csvReportData: {
        data: [],
        headers: [
          { label: 'Load#', key: 'load_number' },
          { label: 'Customer Name', key: 'customer_name' },
          { label: 'Cutomer Load#', key: 'customer_load_number' },
          { label: 'Carrier Name', key: 'carrier_name' },
          { label: 'Dispatch Carrier', key: 'dispatch_carrier' },
          { label: 'Pickup Date', key: 'pickup_date' },
          { label: 'Rate', key: 'rate' },
          { label: 'Dispatch Rate', key: 'dispatch_rate' },
          { label: 'Accounting Verified', key: 'accounting_verified'},
          { label: 'Status', key: 'status' },
        ],
        filename: 'AllAccountingDetails.csv',
      },
      showAdvanceFilter: false,
      data: [],
      open: false,
      status: "",
      isLoading: false,
      page: 0,
      rowsPerPage: 50,
      btnVal: "Show All",
      searchVal: false,
      token: "",
      offset: 0,
      limit: 50,
      order: "ASC",
      sortByColumn: "internalLoadNo",
      count: "",
      startDate: null,
      endDate: null,
      search: '',
      role: ''
    };
    this.csvLinkEl = React.createRef();
    this.startEditing = this.startEditing.bind(this);
  }

  columns = [
    {
      name: "ID",
      options: {
        display: false
      }
    },

    {
      name: "internalLoadNo",
      label: "Load#",
      options: {
        sort: true
      }
    },
    {
      name: "customerName",
      label: "Customer",
      options: {
        sort: true
      }
    },
    {
      name: "customerLoadNo",
      label: "Customer Load#",
      options: {
        sort: true
      }
    },

    {
      name: "carrierName",
      label: "Carrier",
      options: {
        sort: true
      }
    },
    {
      name: "dispatchCarrier",
      label: "Dispatch Carrier",
      options: {
        sort: true
      }
    },
    {
      name: "scheduleFrom",
      label: "Pickup Date",
      options: {
        sort: true
      }
    },
    {
      name: "rate",
      label: "Rate",
      options: {
        sort: true
      }
    },
    {
      name: "dispatchRate",
      label: "Dispatch Rate",
      options: {
        sort: true
      }
    },
    {
      name: "accountingVerified",
      label: "Accounting Verified",
      options: {
        sort: true
      }
    },
    {
      name: "loadStatus",
      label: "Status",
      options: {
        sort: true
      }
    },
    {
      name: "accountingVerified",
      label: "Accounting Verified",
      options: {
        display:false
      }
    },
  ];

  startDateHandler(startDate) {
    this.setState({ startDate });
  }
  endDateHandler(endDate) {
    this.setState({ endDate });
  }
  handleChangeCarrier = (selectedOption) => {
    var event = selectedOption ? selectedOption.value : null;

    var selectedValue = selectedOption ? selectedOption.label : null;
    let setValue = this.state.advanceSearchFormValues;
    setValue.sfCarrierName = selectedValue;
    this.setState({ advanceSearchFormValues: setValue });

    if (this._isMounted) {
      this.setState({ CarrierSelectedOption: event });
    }
  };
  handleChangeCustomer = async (selectedOption) => {

    var event = selectedOption ? selectedOption.value : null;
    var selectedValue = selectedOption ? selectedOption.label : null;
    let setValue = this.state.advanceSearchFormValues;
    setValue.sfCustomerName = selectedValue;
    this.setState({ advanceSearchFormValues: setValue });

    if (event) {
      if (this._isMounted) {
        this.setState({ CustomerSelectedOption: event });
      }

      this.state.customerOptionsData.forEach((element) => {
        if (element.id === event) {
          this.setState({
            customerCard: {
              address: element.address.address,
              city: element.address.city,
              state: element.address.state.state,
            },
          });
        }
      });

      var arr = [],
        options = [];
      await axios
        .post(BaseURL + "customerContact/findByCustomerId", {
          token: this.state.token,
          customerId: event,
        })
        .then((response) => {
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem("Token", "");
            this.props.history.push("/");
          }
          arr = response.data.data;
        })
        .catch(function (error) {
          NotificationManager.error(
            "Something Went Wrong Server Is Not Responding ",
            "Error Message",
            3000
          );
          console.log(error);
        });

      arr.forEach((element) => {
        var obj = { value: element.id, label: element.name };

        options.push(obj);
      });

      if (this._isMounted) {
        this.setState({
          ContactOptions: options,
          contactOptionsData: arr,
          contactCard: {},
        });
      }
    } else {
      if (this._isMounted) {
        this.setState({
          CustomerSelectedOption: event,
          ContactOptions: [],
          ContactSelectedOption: event,
          customerCard: {},
          contactCard: {},
        });
      }
    }
  };
  async componentDidMount() {

    const token = localStorage.getItem("Token");
    this._isMounted = true;

    const tableState = getPersistentTableState('accounting');

    this.columns.find(col => col.name === tableState.sortByColumn)
      .options
      .sortDirection = tableState.order.toLowerCase();

    if (this._isMounted) {
      await this.setState({
        token: token,
        order: tableState.order,
        sortByColumn: tableState.sortByColumn,
        btnVal: tableState.btnVal,
        status: tableState.status,
        search: tableState.search,
        isLoading: true,
        searchVal: tableState.search || tableState.startDate || tableState.endDate ? true : false,
        page: tableState.page,
        offset: tableState.offset,
        limit: tableState.limit,
        rowsPerPage: tableState.limit,
        startDate: tableState.startDate === null ? null : new Date(tableState.startDate),
        endDate: tableState.endDate === null ? null : new Date(tableState.endDate),
      });
      tableState.search || tableState.startDate || tableState.endDate ? this.search() : this.fetchPaginationData();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async search() {
    let csvExportAppend = [];
    var arr = [],
      count;

    this.setLocalStorage();

    await axios
      .post(BaseURL + "customerLoad/accounting/findByName", {
        searchKeyword: this.refs.search.value.trim(),
        offset: this.state.offset,
        limit: this.state.limit,
        sortOrder: this.state.order,
        sortBy: this.state.sortByColumn,
        loadStatus: this.state.status,
        token: this.state.token,
        startDate: this.state.startDate === null
          ? null
          : moment(this.state.startDate).format("YYYY-MM-DD"),
        endDate: this.state.endDate === null
          ? null
          : moment(this.state.endDate).format("YYYY-MM-DD")
      })
      .then(response => {
        console.log(response);
        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem("Token", "");
          this.props.history.push("/");
        }

        count = response.data.data.count;
        for (const key in response.data.data.rows) {
          if (response.data.data.rows.hasOwnProperty(key)) {
            const element = response.data.data.rows[key];

            var PickupDate = "";

            element.loadPickupAndDeliveryDetails.forEach(stop => {
              if (stop.stopType === "pickup") {
                var date = moment(stop.scheduleFrom).format("MM/DD/YYYY");

                PickupDate = PickupDate ? PickupDate : date;
              }
            });

            var Obj = [
              element.id,
              element.internalLoadNo,
              element.customer ? element.customer.customerName : "",
              element.customerLoadNo,
              element.carrier ? element.carrier.carrierName : "",
              element.dispatches[0]
                ? element.dispatches[0].carrier.carrierName
                : "",
              PickupDate,
              currencyFormatter.format(element.rate, { code: "USD" }),
              element.dispatches[0]
                ? currencyFormatter.format(
                  element.dispatches[0].dispatchRate,
                  { code: "USD" }
                )
                : "",
              element.accountingVerified ?
                <img style={{ width: "15px", height: "15px" }} src={Tick} alt='Active' />
                :
                <img style={{ width: "15px", height: "15px" }} src={Cross} alt='Inactive' />,
              element.loadStatus,
              element.accountingVerified
            ];
            arr.push(Obj);
          }
        }

        for (const key in response.data.data.exportData) {
          if (response.data.data.exportData.hasOwnProperty(key)) {
            const element = response.data.data.exportData[key];

            var PickupDate = "";

            element.loadPickupAndDeliveryDetails.forEach(stop => {
              if (stop.stopType === "pickup") {
                var date = moment(stop.scheduleFrom).format("MM/DD/YYYY");

                PickupDate = PickupDate ? PickupDate : date;
              }
            });

            var Obj = [
              element.id,
              element.internalLoadNo,
              element.customer ? element.customer.customerName : "",
              element.customerLoadNo,
              element.carrier ? element.carrier.carrierName : "",
              element.dispatches[0]
                ? element.dispatches[0].carrier.carrierName
                : "",
              PickupDate,
              currencyFormatter.format(element.rate, { code: "USD" }),
              element.dispatches[0]
                ? currencyFormatter.format(
                  element.dispatches[0].dispatchRate,
                  { code: "USD" }
                )
                : "",
              element.accountingVerified ?
                <img style={{ width: "15px", height: "15px" }} src={Tick} alt='Active' />
                :
                <img style={{ width: "15px", height: "15px" }} src={Cross} alt='Inactive' />,
              element.loadStatus,
              element.accountingVerified
            ];
            csvExportAppend.push(Obj);
          }
        }
      })
      .catch(function (error) {
        NotificationManager.error(
          "Something Went Wrong Server Is Not Responding ",
          "Error Message",
          3000
        );
        console.log(error);
      });

    let newCsvArrayData = []

    csvExportAppend.forEach((ee) => {
      newCsvArrayData.push(
        {
          "load_number": ee[1],
          "customer_name": ee[2],
          "customer_load_number": ee[3],
          "carrier_name": ee[4],
          "dispatch_carrier": ee[5],
          "pickup_date": ee[6],
          "rate": ee[7],
          "dispatch_rate": ee[8],
          "accounting_verified": ee[11],
          "status": ee[10],
        }
      )
    })
    let csvData = this.state.csvReportData;
    csvData.data = newCsvArrayData;
    this.setState({ csvData, readyCsvDownloadFile: true })

    if (this._isMounted) {
      this.setState({ data: arr, isLoading: false, count });
    }

  }
  downloadCsvHandler = () => {
    this.setState({ readyForCsvDownload: true })
    let Obj = {
      token: this.state.token
    };
    let arr = [];
    axios
      .post(BaseURL + "customerLoad/accounting/exportToCsvAccounting", Obj, {
        headers: { "Content-Type": "application/json" },
      })
      .then(response => {
        console.log(response.data);
        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem("Token", "");
          this.props.history.push("/");
        }

        for (const key in response.data.data.rows) {
          if (response.data.data.rows.hasOwnProperty(key)) {
            const element = response.data.data.rows[key];

            var PickupDate = "";

            element.loadPickupAndDeliveryDetails.forEach(stop => {
              if (stop.stopType === "pickup") {
                var date = moment(stop.scheduleFrom).format("MM/DD/YYYY");

                PickupDate = PickupDate ? PickupDate : date;
              }
            });

            var Obj = [
              element.id,
              element.internalLoadNo,
              element.customer ? element.customer.customerName : "",
              element.customerLoadNo,
              element.carrier ? element.carrier.carrierName : "",
              element.dispatches[0]
                ? element.dispatches[0].carrier.carrierName
                : "",
              PickupDate,
              currencyFormatter.format(element.rate, { code: "USD" }),
              element.dispatches[0]
                ? currencyFormatter.format(element.dispatches[0].dispatchRate, {
                  code: "USD"
                })
                : "",
              element.accountingVerified ?
                <img style={{ width: "15px", height: "15px" }} src={Tick} alt='Active' />
                :
                <img style={{ width: "15px", height: "15px" }} src={Cross} alt='Inactive' />,
              element.loadStatus,
              element.accountingVerified
            ];
            arr.push(Obj);
          }
        }

        let newCsvArrayData = []

        arr.forEach((ee) => {
          newCsvArrayData.push(
            {
              "load_number": ee[1],
              "customer_name": ee[2],
              "customer_load_number": ee[3],
              "carrier_name": ee[4],
              "dispatch_carrier": ee[5],
              "pickup_date": ee[6],
              "rate": ee[7],
              "dispatch_rate": ee[8],
              "accounting_verified": ee[11],
              "status": ee[10],
            }
          )
        })
        let csvData = this.state.csvReportData;
        csvData.data = newCsvArrayData;
        this.setState({ csvData, readyCsvDownloadFile: true })

      })
      .catch(function (error) {
        NotificationManager.error(
          "Something Went Wrong Server Is Not Responding ",
          "Error Message",
          3000
        );
        console.log(error);
      });
  }
  filterHandler = () => {
    const value = this.state.showAdvanceFilter;
    this.setState({ showAdvanceFilter: !value });
  }
  // 123123
  advanceSearchInputHandler = (e) => {
    const { name, value } = e.target;
    this.setState({ advanceSearchFormValues: { ...this.state.advanceSearchFormValues, [name]: value } });
  }
  advanceFilterHandler = async (e) => {
    this.setState({ isLoading: true });
    e.preventDefault();
    let obj = {
      offset: this.state.offset,
      limit: this.state.limit,
      token: this.state.token,
      customerName: this.state.advanceSearchFormValues.sfCustomerName,
      carrierName: this.state.advanceSearchFormValues.sfCarrierName,
      shipperName: this.state.advanceSearchFormValues.sfShipper,
      receiverName: this.state.advanceSearchFormValues.sfReciever,
      // pickupCity: this.state.advanceSearchFormValues.sfPickupCity,
      // deliveryCity: this.state.advanceSearchFormValues.sfDeliveryCity,
      startDate: this.state.startDate === null
        ? null
        : moment(this.state.startDate).format("YYYY-MM-DD"),
      endDate: this.state.endDate === null
        ? null
        : moment(this.state.endDate).format("YYYY-MM-DD")
    }

    if (
      !this.state.advanceSearchFormValues.sfCustomerName &&
      !this.state.advanceSearchFormValues.sfCarrierName &&
      !this.state.advanceSearchFormValues.sfShipper &&
      !this.state.advanceSearchFormValues.sfReciever &&
      // !this.state.advanceSearchFormValues.sfPickupCity &&
      // !this.state.advanceSearchFormValues.sfDeliveryCity &&
      !this.state.startDate &&
      !this.state.endDate
    ) {
      alert("Search input is required.");
      return
    } else {
      var csvExportAppend = [];
      var arr = [],
        count;
      var myCount = 0
      await axios
        .post(BaseURL + "customerLoad/accounting/findByFilter", obj)
        .then(response => {
          this.setState({ isLoading: false });
          console.log(response);
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem("Token", "");
            this.props.history.push("/");
          }

          count = response.data.data.rows.count;
          for (const key in response.data.data.rows.rows) {
            if (response.data.data.rows.rows.hasOwnProperty(key)) {
              const element = response.data.data.rows.rows[key];

              var PickupDate = "";

              element.loadPickupAndDeliveryDetails.forEach(stop => {
                if (stop.stopType === "pickup") {
                  var date = moment(stop.scheduleFrom).format("MM/DD/YYYY");

                  PickupDate = PickupDate ? PickupDate : date;
                }
              });

              var Obj = [
                element.id,
                element.internalLoadNo,
                element.customer ? element.customer.customerName : "",
                element.customerLoadNo,
                element.carrier ? element.carrier.carrierName : "",
                element.dispatches[0]
                  ? element.dispatches[0].carrier.carrierName
                  : "",
                PickupDate,
                currencyFormatter.format(element.rate, { code: "USD" }),
                element.dispatches[0]
                  ? currencyFormatter.format(
                    element.dispatches[0].dispatchRate,
                    { code: "USD" }
                  )
                  : "",
                element.accountingVerified ?
                  <img style={{ width: "15px", height: "15px" }} src={Tick} alt='Active' />
                  :
                  <img style={{ width: "15px", height: "15px" }} src={Cross} alt='Inactive' />,
                element.loadStatus,
                element.accountingVerified
              ];
              arr.push(Obj);
            }
          }
          for (const key in response.data.data.exportData) {
            if (response.data.data.exportData.hasOwnProperty(key)) {
              const element = response.data.data.exportData[key];

              var PickupDate = "";

              element.loadPickupAndDeliveryDetails.forEach(stop => {
                if (stop.stopType === "pickup") {
                  var date = moment(stop.scheduleFrom).format("MM/DD/YYYY");

                  PickupDate = PickupDate ? PickupDate : date;
                }
              });

              var Obj = [
                element.id,
                element.internalLoadNo,
                element.customer ? element.customer.customerName : "",
                element.customerLoadNo,
                element.carrier ? element.carrier.carrierName : "",
                element.dispatches[0]
                  ? element.dispatches[0].carrier.carrierName
                  : "",
                PickupDate,
                currencyFormatter.format(element.rate, { code: "USD" }),
                element.dispatches[0]
                  ? currencyFormatter.format(
                    element.dispatches[0].dispatchRate,
                    { code: "USD" }
                  )
                  : "",
                element.accountingVerified ?
                  <img style={{ width: "15px", height: "15px" }} src={Tick} alt='Active' />
                  :
                  <img style={{ width: "15px", height: "15px" }} src={Cross} alt='Inactive' />,
                element.loadStatus,
                element.accountingVerified
              ];
              csvExportAppend.push(Obj);
            }
          }
        })
        .catch(function (error) {
          NotificationManager.error(
            "Something Went Wrong Server Is Not Responding ",
            "Error Message",
            3000
          );
          console.log(error);
        });
      let newCsvArrayData = []

      csvExportAppend.forEach((ee) => {
        newCsvArrayData.push(
          {
            "load_number": ee[1],
            "customer_name": ee[2],
            "customer_load_number": ee[3],
            "carrier_name": ee[4],
            "dispatch_carrier": ee[5],
            "pickup_date": ee[6],
            "rate": ee[7],
            "dispatch_rate": ee[8],
            "accounting_verified": ee[11],
            "status": ee[10],
          }
        )
      })
      let csvData = this.state.csvReportData;
      csvData.data = newCsvArrayData;
      this.setState({ csvData, readyCsvDownloadFile: true })
      if (this._isMounted) {
        this.setState({ data: arr, isLoading: false, count });
      }
    }
  }
  advanceFilterClear = (e) => {
    e.preventDefault();
    this.setState({
      startDate: null,
      endDate: null,
      search: '',
      readyCsvDownloadFile: false,
    });
    this.setState({
      advanceSearchFormValues: {
        sfCustomerName: "",
        sfCarrierName: "",
        // sfPickupCity: "",
        // sfDeliveryCity: "",
        sfShipper: "",
        sfReciever: "",
      }
    });
    this.showall("Show All", e);
    this.setState({ showAdvanceFilter: false });

  }

  async showall(theme, e) {
    if (this._isMounted) {

      await this.setState({
        searchVal: false,
        isLoading: true,
        status: "",
        open: false,
        anchorEl: null,
        btnVal: theme,
        page: 0,
        offset: 0,
        order: "ASC",
        sortByColumn: "internalLoadNo",
      });
      let startDate = this.state.startDate;
      let endDate = this.state.endDate;
      let search = this.state.search;

      search || startDate || endDate ? this.search() : this.fetchPaginationData();
    }
  }

  startEditing(colData, cellMeta) {
    if (window.getSelection().toString()) {
      return false;
    } else if (cellMeta.colIndex === 1) {
      var data = this.state.data;
      var id = data[cellMeta.dataIndex][0];
      // console.log('Cell meta ',colData,cellMeta,data[cellMeta.dataIndex])
      this.props.history.push("/EditLoad/" + id + "/accounting");
    }
  }

  handleSearch = async (e) => {
    if (this._isMounted) {
      this.setState({ search: e.target.value });
    }
  };

  handleKeyPress = e => {
    if (event.key === "Enter") {
      this.SetSearchParameters();
    }
  };

  SetSearchParameters = async () => {
    if (this._isMounted && (this.refs.search.value.trim() || this.state.startDate || this.state.endDate)) {
      await this.setState({
        isLoading: true,
        page: 0,
        offset: 0,
        order: "ASC",
        sortByColumn: "internalLoadNo",
        searchVal: true
      });

      this.search();
    } else {
      alert("Search/Date Input is required");
    }
  };

  handleToggle = event => {
    if (this._isMounted) {
      var target = event.currentTarget;
      this.setState(state => ({ open: !state.open, anchorEl: target }));
    }
  };

  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }
    if (this._isMounted) {
      this.setState({ open: false });
    }
  };

  async handleChangeDropDown(theme, e) {
    if (this._isMounted) {

      await this.setState({
        searchVal: false,
        isLoading: true,
        status: theme,
        open: false,
        anchorEl: null,
        btnVal: theme,
        page: 0,
        offset: 0,
        order: "ASC",
        sortByColumn: "internalLoadNo",
      });
      let startDate = this.state.startDate;
      let endDate = this.state.endDate;
      let search = this.state.search;

      search || startDate || endDate ? this.search() : this.fetchPaginationData();
    }
  }

  onTableChange = async (action, tableState) => {
    console.log("action", action);
    console.log("state", tableState);

    switch (action) {
      case "changePage":
        this.changePage(tableState);
        break;

      case "changeRowsPerPage":
        this.changeRowsPerPage(tableState);
        break;

      case "sort":
        this.sort(tableState);
        break;

      default:
        break;
    }
  };

  changePage = async tableState => {
    var offset = this.state.offset;

    if (tableState.page > this.state.page) {
      // Next Icon Clicked >
      var tempOffset = offset + tableState.rowsPerPage;
      if (this._isMounted) {
        await this.setState({
          offset: tempOffset, //50
          limit: tableState.rowsPerPage, // 76
          page: tableState.page
        });

        (await this.state.searchVal)
          ? this.search()
          : this.fetchPaginationData();
      }
    } else {
      var tempLimit = tableState.rowsPerPage;
      var tempOffset = offset - tempLimit;
      if (tempOffset < 0) {
        tempOffset = 0;
      }

      if (this._isMounted) {
        await this.setState({
          limit: tempLimit,
          offset: tempOffset,
          page: tableState.page
        });

        (await this.state.searchVal)
          ? this.search()
          : this.fetchPaginationData();
      }
    }
  };

  changeRowsPerPage = async tableState => {
    if (this._isMounted) {
      await this.setState({
        limit: tableState.rowsPerPage, // 100
        offset: 0,
        rowsPerPage: tableState.rowsPerPage,
        page: 0
      });
      this.state.searchVal ? this.search() : this.fetchPaginationData();
    }
  };

  sort = async tableState => {
    var sortByColumn = this.state.sortByColumn;
    var order = this.state.order;

    var sortOrder =
      tableState["columns"][tableState.activeColumn].name === sortByColumn
        ? order === "ASC"
          ? "DESC"
          : "ASC"
        : "ASC";

    if (this._isMounted) {
      await this.setState({
        order: sortOrder,
        sortByColumn: tableState["columns"][tableState.activeColumn].name
      });

      this.state.searchVal ? this.search() : this.fetchPaginationData();
    }
  };

  fetchPaginationData = () => {
    //console.log('TableState Data',state.data)
    var postData = {
      token: this.state.token,
      loadStatus: this.state.status,
      offset: this.state.offset,
      limit: this.state.limit,
      sortOrder: this.state.order,
      sortBy: this.state.sortByColumn,
      // startDate:
      //   this.state.startDate === null
      //     ? null
      //     : moment(this.state.startDate).format("YYYY-MM-DD"),
      // endDate:
      //   this.state.endDate === null
      //     ? null
      //     : moment(this.state.endDate).format("YYYY-MM-DD")
    };

    var arr = [];

    console.log("state", postData);
    this.setLocalStorage();

    var status, count;
    axios
      .post(BaseURL + "customerLoad/accounting", postData, {
        headers: { "Content-Type": "application/json" }
      })
      .then(response => {
        console.log(response.data);
        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem("Token", "");
          this.props.history.push("/");
        }

        count = response.data.data.count;

        for (const key in response.data.data.rows) {
          if (response.data.data.rows.hasOwnProperty(key)) {
            const element = response.data.data.rows[key];

            var PickupDate = "";

            element.loadPickupAndDeliveryDetails.forEach(stop => {
              if (stop.stopType === "pickup") {
                var date = moment(stop.scheduleFrom).format("MM/DD/YYYY");

                PickupDate = PickupDate ? PickupDate : date;
              }
            });

            var Obj = [
              element.id,
              element.internalLoadNo,
              element.customer ? element.customer.customerName : "",
              element.customerLoadNo,
              element.carrier ? element.carrier.carrierName : "",
              element.dispatches[0]
                ? element.dispatches[0].carrier.carrierName
                : "",
              PickupDate,
              currencyFormatter.format(element.rate, { code: "USD" }),
              element.dispatches[0]
                ? currencyFormatter.format(element.dispatches[0].dispatchRate, {
                  code: "USD"
                })
                : "",
              element.accountingVerified ?
                <img style={{ width: "15px", height: "15px" }} src={Tick} alt='Active' />
                :
                <img style={{ width: "15px", height: "15px" }} src={Cross} alt='Inactive' />,
              element.loadStatus,
              element.accountingVerified
            ];
            arr.push(Obj);
          }
        }

        if (this._isMounted) {
          this.setState({ data: arr, isLoading: false, count });
        }

        // console.log(arr);
      })
      .catch(function (error) {
        NotificationManager.error(
          "Something Went Wrong Server Is Not Responding ",
          "Error Message",
          3000
        );
        console.log(error);
      });
  };

  setLocalStorage = () => {

    const table = {
      order: this.state.order,
      sortByColumn: this.state.sortByColumn,
      btnVal: this.state.btnVal,
      status: this.state.status,
      search: this.state.search,
      page: this.state.page,
      offset: this.state.offset,
      limit: this.state.limit,
      startDate: this.state.startDate,
      endDate: this.state.endDate
    };

    setPersistentTableState('accounting', table);

    this.columns = this.columns.map(col => {
      col.name === table.sortByColumn ?
        col.options.sortDirection = table.order.toLowerCase() :
        delete col.options.sortDirection;
      return col;
    });

  }

  render() {
    const {
      isLoading,
      data,
      rowsPerPage,
      open,
      searchVal,
      count,
      page,
      showAdvanceFilter,
      readyCsvDownloadFile,
      readyForCsvDownload,
      csvReportData,
    } = this.state;

    const options = {
      responsive: "scroll",
      selectableRows: false,
      rowHover: true,
      print: false,
      download: false,
      filter: false,
      search: false,
      rowsPerPageOptions: [25, 50, 100],
      rowsPerPage: rowsPerPage,
      viewColumns: false,
      textLabels: {
        body: {
          toolTip: ""
        }
      },
      onCellClick: this.startEditing,

      serverSide: true,
      count: count,
      page: page,
      onTableChange: this.onTableChange
    };

    const crossN = {
      display: "none"
    };
    const crossS = {
      display: "block"
    };
    return (
      <div className="page-container-bg-solid page-header-fixed page-sidebar-closed-hide-logo page-md">
        <Header title="| Accounting" />
        <div className="page-container">
          <Sidebar />
          <div className="page-content-wrapper">
            <div className="page-content">
              <div className="row">
                <div className="col-md-12">
                  <div className="portlet box yellow-gold">
                    <div className="portlet-title">
                      <div className="caption">
                        <i className="fa fa-bar-chart"></i>
                        <span className="caption-subject">
                          Accounting Details
                          {/* <i className="fa fa-filter filter" onClick={this.filterHandler}></i> */}
                        </span>
                      </div>
                      <div className="actions">
                        <div className="btn-group">
                          {readyCsvDownloadFile ? (
                            <>
                              <CSVLink
                                ref={this.csvLinkEl}
                                style={{ marginRight: "5px" }}
                                className="btn sbold white dark"
                                {...csvReportData}
                              >
                                Download Csv
                                <i className="fa fa-download downloadCsv"></i>
                              </CSVLink>
                            </>
                          ) : null
                          // <>
                          //   <button
                          //     style={{ marginRight: "5px" }}
                          //     className="btn sbold white dark"
                          //     onClick={this.downloadCsvHandler}
                          //     disabled={this.state.readyForCsvDownload}
                          //   >
                          //     {
                          //       readyForCsvDownload ?
                          //         (
                          //           <>
                          //             <CircularProgress size={16} style={{ 'color': 'white' }} /> Loading ...
                          //           </>
                          //         )
                          //         :
                          //         (
                          //           <>
                          //             Export to CSV
                          //           </>
                          //         )
                          //     }
                          //   </button>
                          // </>
                          }
                          <Link
                            to="/AddLoad"
                            id="ab"
                            className="btn sbold white dark"
                          >
                            Add New <i className="fa fa-plus"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="portlet-body">
                      <div className="table-toolbar">
                        <div
                          style={{
                            textAlign: "right",
                            paddingRight: " 10px",
                            paddingBottom: "8px"
                          }}
                        >
                          {!showAdvanceFilter ? (
                            <span
                              style={{
                                float: "left",
                                paddingLeft: "8px",
                                display: "flex",
                                alignItems: "center",
                                backgroundColor: "#fff"
                              }}
                            >
                              <div className="btnShowall">
                                <Button
                                  buttonRef={(node) => {
                                    this.anchorEl = node
                                  }}
                                  aria-owns={
                                    open ? "menu-list-grow" : undefined
                                  }
                                  aria-haspopup="true"
                                  onClick={this.handleToggle}
                                >
                                  {this.state.btnVal} &nbsp;&nbsp;
                                  <span className="fa fa-chevron-down"></span>
                                </Button>
                                <Popper
                                  open={open}
                                  anchorEl={this.anchorEl}
                                  transition
                                  disablePortal
                                >
                                  {({ TransitionProps, placement }) => (
                                    <Grow
                                      {...TransitionProps}
                                      id="menu-list-grow"
                                      style={{
                                        transformOrigin:
                                          placement === "bottom"
                                            ? "center top"
                                            : "center bottom"
                                      }}
                                    >
                                      <Paper>
                                        <ClickAwayListener
                                          onClickAway={this.handleClose}
                                        >
                                          <MenuList>
                                            <MenuItem
                                              style={{
                                                paddingTop: "6px",
                                                paddingBottom: "6px"
                                              }}
                                              onClick={(e) =>
                                                this.showall("Show All", e)
                                              }
                                            >
                                              Show All
                                            </MenuItem>
                                            <MenuItem
                                              style={{
                                                paddingTop: "6px",
                                                paddingBottom: "6px"
                                              }}
                                              onClick={(e) =>
                                                this.handleChangeDropDown(
                                                  "Pending",
                                                  e
                                                )
                                              }
                                            >
                                              Pending
                                            </MenuItem>
                                            <MenuItem
                                              style={{
                                                paddingTop: "6px",
                                                paddingBottom: "6px"
                                              }}
                                              onClick={(e) =>
                                                this.handleChangeDropDown(
                                                  "Dispatched",
                                                  e
                                                )
                                              }
                                            >
                                              Dispatched
                                            </MenuItem>
                                            <MenuItem
                                              style={{
                                                paddingTop: "6px",
                                                paddingBottom: "6px"
                                              }}
                                              onClick={(e) =>
                                                this.handleChangeDropDown(
                                                  "In-Transit",
                                                  e
                                                )
                                              }
                                            >
                                              In-Transit
                                            </MenuItem>
                                            <MenuItem
                                              style={{
                                                paddingTop: "6px",
                                                paddingBottom: "6px"
                                              }}
                                              onClick={(e) =>
                                                this.handleChangeDropDown(
                                                  "Delivered",
                                                  e
                                                )
                                              }
                                            >
                                              Delivered
                                            </MenuItem>
                                            <MenuItem
                                              style={{
                                                paddingTop: "6px",
                                                paddingBottom: "6px"
                                              }}
                                              onClick={(e) =>
                                                this.handleChangeDropDown(
                                                  "Completed",
                                                  e
                                                )
                                              }
                                            >
                                              Completed
                                            </MenuItem>
                                            <MenuItem
                                              style={{
                                                paddingTop: "6px",
                                                paddingBottom: "6px"
                                              }}
                                              onClick={(e) =>
                                                this.handleChangeDropDown(
                                                  "Invoiced",
                                                  e
                                                )
                                              }
                                            >
                                              Invoiced
                                            </MenuItem>
                                            <MenuItem
                                              style={{
                                                paddingTop: "6px",
                                                paddingBottom: "6px"
                                              }}
                                              onClick={(e) =>
                                                this.handleChangeDropDown(
                                                  "Closed",
                                                  e
                                                )
                                              }
                                            >
                                              Closed
                                            </MenuItem>
                                            <MenuItem
                                              style={{
                                                paddingTop: "6px",
                                                paddingBottom: "6px"
                                              }}
                                              onClick={(e) =>
                                                this.handleChangeDropDown(
                                                  "Cancel",
                                                  e
                                                )
                                              }
                                            >
                                              Cancel
                                            </MenuItem>
                                          </MenuList>
                                        </ClickAwayListener>
                                      </Paper>
                                    </Grow>
                                  )}
                                </Popper>
                              </div>
                              <div
                                style={{ marginLeft: "20px", width: "140px" }}
                              >
                                <DatePicker
                                  // disabled={this.state.readonly}
                                  className="form-control"
                                  selected={this.state.startDate}
                                  dateFormat="yyyy-MM-dd"
                                  placeholderText="Start Date"
                                  onChange={(date) =>
                                    this.startDateHandler(date)
                                  }
                                  isClearable
                                />
                              </div>
                              <div
                                className="text-center"
                                style={{ margin: "0 10px" }}
                              >
                                TO
                              </div>
                              <div style={{ width: "140px" }}>
                                <DatePicker
                                  // disabled={this.state.readonly}
                                  className="form-control"
                                  selected={this.state.endDate}
                                  dateFormat="yyyy-MM-dd"
                                  placeholderText="End Date"
                                  onChange={(date) => this.endDateHandler(date)}
                                  isClearable
                                />
                              </div>
                            </span>
                          ) : (
                            <>
                              <span
                                style={{
                                  float: "left",
                                  paddingLeft: "8px",
                                  width: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                  backgroundColor: "#fff"
                                }}
                              >
                                <div className="btnShowall">
                                  <Button
                                    buttonRef={(node) => {
                                      this.anchorEl = node
                                    }}
                                    aria-owns={
                                      open ? "menu-list-grow" : undefined
                                    }
                                    aria-haspopup="true"
                                    onClick={this.handleToggle}
                                  >
                                    {this.state.btnVal} &nbsp;&nbsp;
                                    <span className="fa fa-chevron-down"></span>
                                  </Button>
                                  <Popper
                                    open={open}
                                    anchorEl={this.anchorEl}
                                    transition
                                    disablePortal
                                  >
                                    {({ TransitionProps, placement }) => (
                                      <Grow
                                        {...TransitionProps}
                                        id="menu-list-grow"
                                        style={{
                                          transformOrigin:
                                            placement === "bottom"
                                              ? "center top"
                                              : "center bottom"
                                        }}
                                      >
                                        <Paper>
                                          <ClickAwayListener
                                            onClickAway={this.handleClose}
                                          >
                                            <MenuList>
                                              <MenuItem
                                                style={{
                                                  paddingTop: "6px",
                                                  paddingBottom: "6px"
                                                }}
                                                onClick={(e) =>
                                                  this.showall("Show All", e)
                                                }
                                              >
                                                Show All
                                              </MenuItem>
                                              <MenuItem
                                                style={{
                                                  paddingTop: "6px",
                                                  paddingBottom: "6px"
                                                }}
                                                onClick={(e) =>
                                                  this.handleChangeDropDown(
                                                    "Pending",
                                                    e
                                                  )
                                                }
                                              >
                                                Pending
                                              </MenuItem>
                                              <MenuItem
                                                style={{
                                                  paddingTop: "6px",
                                                  paddingBottom: "6px"
                                                }}
                                                onClick={(e) =>
                                                  this.handleChangeDropDown(
                                                    "Dispatched",
                                                    e
                                                  )
                                                }
                                              >
                                                Dispatched
                                              </MenuItem>
                                              <MenuItem
                                                style={{
                                                  paddingTop: "6px",
                                                  paddingBottom: "6px"
                                                }}
                                                onClick={(e) =>
                                                  this.handleChangeDropDown(
                                                    "In-Transit",
                                                    e
                                                  )
                                                }
                                              >
                                                In-Transit
                                              </MenuItem>
                                              <MenuItem
                                                style={{
                                                  paddingTop: "6px",
                                                  paddingBottom: "6px"
                                                }}
                                                onClick={(e) =>
                                                  this.handleChangeDropDown(
                                                    "Delivered",
                                                    e
                                                  )
                                                }
                                              >
                                                Delivered
                                              </MenuItem>
                                              <MenuItem
                                                style={{
                                                  paddingTop: "6px",
                                                  paddingBottom: "6px"
                                                }}
                                                onClick={(e) =>
                                                  this.handleChangeDropDown(
                                                    "Completed",
                                                    e
                                                  )
                                                }
                                              >
                                                Completed
                                              </MenuItem>
                                              <MenuItem
                                                style={{
                                                  paddingTop: "6px",
                                                  paddingBottom: "6px"
                                                }}
                                                onClick={(e) =>
                                                  this.handleChangeDropDown(
                                                    "Invoiced",
                                                    e
                                                  )
                                                }
                                              >
                                                Invoiced
                                              </MenuItem>
                                              <MenuItem
                                                style={{
                                                  paddingTop: "6px",
                                                  paddingBottom: "6px"
                                                }}
                                                onClick={(e) =>
                                                  this.handleChangeDropDown(
                                                    "Closed",
                                                    e
                                                  )
                                                }
                                              >
                                                Closed
                                              </MenuItem>
                                              <MenuItem
                                                style={{
                                                  paddingTop: "6px",
                                                  paddingBottom: "6px"
                                                }}
                                                onClick={(e) =>
                                                  this.handleChangeDropDown(
                                                    "Cancel",
                                                    e
                                                  )
                                                }
                                              >
                                                Cancel
                                              </MenuItem>
                                            </MenuList>
                                          </ClickAwayListener>
                                        </Paper>
                                      </Grow>
                                    )}
                                  </Popper>
                                </div>
                                <div
                                  style={{ marginLeft: "20px", width: "140px" }}
                                >
                                  <DatePicker
                                    // disabled={this.state.readonly}
                                    className="form-control"
                                    selected={this.state.startDate}
                                    dateFormat="yyyy-MM-dd"
                                    placeholderText="Start Date"
                                    onChange={(date) =>
                                      this.startDateHandler(date)
                                    }
                                    isClearable
                                  />
                                </div>
                                <div
                                  className="text-center"
                                  style={{ margin: "0 10px" }}
                                >
                                  TO
                                </div>
                                <div style={{ width: "140px" }}>
                                  <DatePicker
                                    // disabled={this.state.readonly}
                                    className="form-control"
                                    selected={this.state.endDate}
                                    dateFormat="yyyy-MM-dd"
                                    placeholderText="End Date"
                                    onChange={(date) =>
                                      this.endDateHandler(date)
                                    }
                                    isClearable
                                  />
                                </div>
                              </span>
                              {/* 123123 advance filter 123123 */}
                              <form>
                                <div>
                                  {/* <div class="form-row">
                                        <div class="col-12 col-md-6">
                                          <label for="inputEmail4">Customer Name</label>
                                          <input name="sfCustomerName" onChange={this.advanceSearchInputHandler} value={this.state.advanceSearchFormValues.sfCustomerName} type="" className="form-control" placeholder="Customer Name" />
                                        </div>
                                        <div class="col-12 col-md-6">
                                          <label for="inputEmail4">Carrier Name</label>
                                          <input name="sfCarrierName" onChange={this.advanceSearchInputHandler} value={this.state.advanceSearchFormValues.sfCarrierName} type="" className="form-control" placeholder="Carrier Name" />
                                        </div>
                                      </div> */}
                                  <div class="row d-flex text-left">
                                    <div class="col-12 col-md-6">
                                      <label for="inputEmail4">
                                        Customer Name
                                      </label>
                                      {/* <input name="sfCustomerName" onChange={this.advanceSearchInputHandler} value={this.state.advanceSearchFormValues.sfCustomerName} type="" className="form-control" placeholder="Customer Name" /> */}
                                      <Select
                                        className="customeLeft"
                                        optionHeight={50}
                                        disabled={this.state.readonly}
                                        value={
                                          this.state.CustomerSelectedOption
                                          // this.state.advanceSearchFormValues.sfCustomerName
                                        }
                                        options={this.state.CustomerOptions}
                                        onChange={this.handleChangeCustomer}
                                        onInputChange={(input) => {
                                          if (input.trim().length > 1) {
                                            axios
                                              .post(
                                                BaseURL + "customer/findByName",
                                                {
                                                  status: 2,
                                                  searchKeyword: input,
                                                  token: this.state.token
                                                }
                                              )
                                              .then((response) => {
                                                if (
                                                  response.data.error ===
                                                  TokenErrorMsg
                                                ) {
                                                  localStorage.setItem(
                                                    "Token",
                                                    ""
                                                  )
                                                  this.props.history.push("/")
                                                }

                                                var arr = []

                                                response.data.data.rows.forEach(
                                                  (element) => {
                                                    var obj = {
                                                      value: element.id,
                                                      label:
                                                        element.customerName
                                                    }
                                                    arr.push(obj)
                                                  }
                                                )

                                                this.setState({
                                                  CustomerOptions: arr,
                                                  customerOptionsData:
                                                    response.data.data.rows
                                                })
                                              })
                                              .catch(function(error) {
                                                NotificationManager.error(
                                                  "Something Went Wrong Server Is Not Responding ",
                                                  "Error Message",
                                                  3000
                                                )
                                                console.log(error)
                                              })
                                          }
                                        }}
                                        placeholder="Search and Choose "
                                      />
                                    </div>

                                    <div class="col-12 col-md-6">
                                      <label for="inputEmail4">
                                        Carrier Name
                                      </label>
                                      {/* <input name="sfCarrierName" onChange={this.advanceSearchInputHandler} value={this.state.advanceSearchFormValues.sfCarrierName} type="" className="form-control" placeholder="Carrier Name" /> */}
                                      <Select
                                        optionHeight={50}
                                        disabled={this.state.readonly}
                                        value={this.state.CarrierSelectedOption}
                                        options={this.state.CarrierOptions}
                                        onChange={this.handleChangeCarrier}
                                        onInputChange={(input) => {
                                          if (input.trim().length > 1) {
                                            axios
                                              .post(
                                                BaseURL +
                                                  "carrierInformation/findByName",
                                                {
                                                  status: 2,
                                                  searchKeyword: input,
                                                  token: this.state.token
                                                }
                                              )
                                              .then((response) => {
                                                if (
                                                  response.data.error ===
                                                  TokenErrorMsg
                                                ) {
                                                  localStorage.setItem(
                                                    "Token",
                                                    ""
                                                  )
                                                  this.props.history.push("/")
                                                }

                                                var arr = []

                                                if (
                                                  response.data.data.count === 0
                                                ) {
                                                  let errors = {}
                                                  errors["inactiveValidation"] =
                                                    ""
                                                  this.setState({
                                                    errors: errors
                                                  })
                                                }

                                                response.data.data.rows.forEach(
                                                  (element) => {
                                                    if (element.status !== 0) {
                                                      let errors = {}
                                                      errors[
                                                        "inactiveValidation"
                                                      ] = ""
                                                      var obj = {
                                                        value: element.id,
                                                        label:
                                                          element.carrierName
                                                      }

                                                      arr.push(obj)
                                                      this.setState({
                                                        errors: errors
                                                      })
                                                    } else if (
                                                      element.status === 0
                                                    ) {
                                                      let errors = {}
                                                      console.log(
                                                        element,
                                                        "element 3"
                                                      )
                                                      errors[
                                                        "inactiveValidation"
                                                      ] =
                                                        "*Carrier exist with inactive status"
                                                      this.setState({
                                                        errors: errors
                                                      })
                                                    }
                                                  }
                                                )

                                                this.setState({
                                                  CarrierOptions: arr,
                                                  loadData:
                                                    response.data.data.rows
                                                })
                                              })
                                              .catch(function(error) {
                                                NotificationManager.error(
                                                  "Something Went Wrong Server Is Not Responding ",
                                                  "Error Message",
                                                  3000
                                                )
                                                console.log(error)
                                              })
                                          } else {
                                            let errors = {}
                                            errors["inactiveValidation"] = ""
                                            this.setState({
                                              errors: errors
                                            })
                                          }
                                        }}
                                        placeholder="Search and Choose "
                                      />
                                    </div>
                                  </div>
                                  {/* 
                                      <div class="row d-flex text-left">
                                        <div class="col-12 col-md-6">
                                          <label for="inputEmail4">Shipper Name</label>
                                          <input name="sfShipper" onChange={this.advanceSearchInputHandler} value={this.state.advanceSearchFormValues.sfShipper} type="" className="form-control" placeholder="Shipper" />
                                        </div>
                                        <div class="col-12 col-md-6">
                                          <label for="inputEmail4">Receiver Name</label>
                                          <input name="sfReciever" onChange={this.advanceSearchInputHandler} value={this.state.advanceSearchFormValues.sfReciever} type="" className="form-control" placeholder="Receiver" />
                                        </div>
                                      </div> */}
                                  {/* <div class="row d-flex text-left">
                                        <div class="col-12 col-md-6">
                                          <label for="inputEmail4">Pickup City</label>
                                          <input name="sfPickupCity" onChange={this.advanceSearchInputHandler} value={this.state.advanceSearchFormValues.sfPickupCity} type="" className="form-control" placeholder="Pickup City" />
                                        </div>
                                        <div class="col-12 col-md-6">
                                          <label for="inputEmail4">Delivery City</label>
                                          <input name="sfDeliveryCity" onChange={this.advanceSearchInputHandler} value={this.state.advanceSearchFormValues.sfDeliveryCity} type="" className="form-control" placeholder="Delivery City" />
                                        </div>
                                      </div> */}

                                  <div className="filterSerchDiv">
                                    <button
                                      type="submit"
                                      style={{ marginRight: "5px" }}
                                      className="btn green-meadow filterSerchBtn"
                                      onClick={this.advanceFilterHandler}
                                    >
                                      Search
                                    </button>
                                    <button
                                      className="btn"
                                      onClick={this.advanceFilterClear}
                                    >
                                      Clear
                                    </button>
                                  </div>
                                </div>
                              </form>
                            </>
                          )}

                          <div style={{ marginRight: "0px" }}>
                            {!showAdvanceFilter ? (
                              <div className="searchField form-control">
                                <input
                                  id="search-field"
                                  ref="search"
                                  name="searchinput"
                                  type="text"
                                  placeholder="Search..."
                                  onChange={this.handleSearch}
                                  onKeyPress={this.handleKeyPress}
                                  value={this.state.search}
                                />
                                <i
                                  className="fa fa-chevron-down"
                                  style={{
                                    position: "absolute",
                                    right: "10px",
                                    top: "8px",
                                    cursor: "pointer"
                                  }}
                                  onClick={this.filterHandler}
                                ></i>
                              </div>
                            ) : null}

                            {!showAdvanceFilter ? (
                              <>
                                <input
                                  name="searchbutton"
                                  style={{ marginRight: "10px" }}
                                  className="btn green-meadow"
                                  type="submit"
                                  value="SEARCH"
                                  onClick={this.SetSearchParameters}
                                />
                                <button
                                  // style={{marginRight: "10px"}}
                                  className="btn"
                                  type="button"
                                  onClick={async (e) => {
                                    await this.setState({
                                      startDate: null,
                                      endDate: null,
                                      search: ""
                                    })
                                    this.showall("Show All", e)
                                  }}
                                >
                                  Clear
                                </button>
                              </>
                            ) : null}
                            {/* <img alt='' id="excel_export" src="../assets/apps/img/excel_button.png" title="Export data to Excel" />  */}
                          </div>
                        </div>
                      </div>
                      {isLoading ? (
                        <p style={{ textAlign: "center" }}>
                          <img src={f} alt="Loader" />
                        </p>
                      ) : (
                        <MUiTable
                          data={data}
                          columns={this.columns}
                          options={options}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Accouting);
