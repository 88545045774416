/* eslint-disable */

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
import Sidebar from "./sidebar";
import Header from "./Head";
import $ from "jquery";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import BaseURL from "./config";
import CurrencyInput from "react-currency-input";
import getEditinfo from "./StartEditMode";
import finishEditinfo from "./FinishEditMode";
import dispatcherUser from "./permissions/DispatcherUserPermision";
import accountingUser from "./permissions/AccountingUserPermision";
import TokenErrorMsg from "./errorMsg";

import TruckTab from "./TruckTab";

import TrailerTab from "./TrailerTab";
import DriverTab from "./DriverTab";

import tenantUser from "./TenantUserPermision";
import tenantSubRole from "./TenantUserSubRolePermission";

import Select from "react-virtualized-select";
import "react-select/dist/react-select.css";
import "react-virtualized/styles.css";
import "react-virtualized-select/styles.css";
import LoadTab from "./LoadTab";
import BillingTab from "./BillingTab";
import AccountingTab from "./AccountingTab";

import Tick from "./green-tick.jpg";
import Cross from "./red-cross.jpg";

const Cryptr = require("cryptr");
const cryptr = new Cryptr("@$#rgweR35*&YFSD)/");

class Editcarrier extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saveBtnDisable: null,
      carrierData: "",
      businesstype: [
        { id: 1, name: "Individual/sole proprietor" },
        { id: 2, name: "S Corporation" },
        { id: 3, name: "C Corporation" },
        { id: 4, name: "LLC" },
      ],
      copyload: false,
      status: 1,
      errorMsg: false,
      countries: [],
      states: [],
      bstates: [],
      Contacts: [],
      customerid: "",
      whileSave: false,
      UpdateContactid: "",
      readonly: true,
      hidden: false,
      billingFormData: {
        dispatchType: 2,
        dispatchPercentage: 0,
        dispatchFee: 0,
      },
      billingInfoReadOnly: true,
      dispatchTypeOptions: [
        { id: 1, name: "Dispatch Fee" },
        { id: 2, name: "Dispatch Percentage" },
      ],
      addressid: "",
      billaddid: "",
      fields: {},
      errors: {},
      isLoading: false,
      token: "",
      AccountType: 1,
      bankid: "",
      bankaddressid: "",
      bankinfohidden: false,
      bankinforeadonly: true,
      bankstates: [],
      companies: [],
      agents: [],
      companydata: {},
      agentdata: {},
      effdate: new Date(),
      expdate: new Date(),
      InsuranceInfo: [],
      LiabilityTypes: [],
      EditInsuranceLiability: {
        id: "",
        company: "",
        agent: "",
        type: "",
      },
      file: {
        operation: "",
        w9: "",
        insur: "",
        copy: "",
        carb: "",
      },
      operationload: false,
      w9load: false,
      insuranceload: false,
      carbload: false,

      hideinsuranceinfobutton: true,
      insuranceinforeadonly: false,
      bankformdata: {},
      carrierformdata: {},
      CheckEditInfo: {},
      // role: "Tenant",
      role: "",
      // subRole: "Premium or Basic",
      subRole: "",
      CurrencyValue: "",

      factoringCompanyId: null,
      factoringCompanies: [],
      factorySelectionOptions: [],
      factoryCompanyCard: {},
      isFactorySelected: "NO",
      showSelectedRowOnTabsBoolean: false,
      showSelectedRowOnTabs: {},
    };

    this.handleChange = this.handleChange.bind(this);
    this.submituserRegistrationForm = this.submituserRegistrationForm.bind(
      this
    );
    this.submit = this.submit.bind(this);
    this.billingSubmitForm = this.billingSubmitForm.bind(this);
    this.banksubmitform = this.banksubmitform.bind(this);
    this.validateBillingSubmit = this.validateBillingSubmit.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.validateInsuranceInfo = this.validateInsuranceInfo.bind(this);
    this.submitbankRegistrationForm = this.submitbankRegistrationForm.bind(
      this
    );
    this.validatebankSubmit = this.validatebankSubmit.bind(this);
    this.AutoFormatContact = this.AutoFormatContact.bind(this);
    this.AutoFormatEIN = this.AutoFormatEIN.bind(this);
  }

  AutoFormatContact(e) {
    function phone_formatting(ele, restore) {
      var new_number,
        // selection_start = ele.selectionStart,
        // selection_end = ele.selectionEnd,
        number = ele.value.replace(/\D/g, "");

      // automatically add dashes
      if (number.length > 2) {
        // matches: 123 || 123-4 || 123-45
        new_number = number.substring(0, 3) + "-";
        if (number.length === 4 || number.length === 5) {
          // matches: 123-4 || 123-45
          new_number += number.substr(3);
        } else if (number.length > 5) {
          // matches: 123-456 || 123-456-7 || 123-456-789
          new_number += number.substring(3, 6) + "-";
        }
        if (number.length > 6) {
          // matches: 123-456-7 || 123-456-789 || 123-456-7890
          new_number += number.substring(6);
        }
      } else {
        new_number = number;
      }

      // if value is heigher than 12, last number is dropped
      // if inserting a number before the last character, numbers
      // are shifted right, only 12 characters will show
      ele.value =
        new_number.length > 12 ? new_number.substring(0, 12) : new_number;

      // restore cursor selection,
      // prevent it from going to the end
      // UNLESS
      // cursor was at the end AND a dash was added

      // if (new_number.slice(-1) === '-' && restore === false
      //     && (new_number.length === 8 && selection_end === 7)
      //         || (new_number.length === 4 && selection_end === 3)) {
      //     selection_start = new_number.length;
      //     selection_end = new_number.length;
      // }
      // else if (restore === 'revert') {
      //   selection_start--;
      //   selection_end--;
      // }
      // ele.setSelectionRange(selection_start, selection_end);
    }

    function phone_number_check(field, e) {
      var key_code = e.keyCode,
        key_string = String.fromCharCode(key_code),
        press_delete = false,
        dash_key = 189,
        delete_key = [8, 46],
        direction_key = [33, 34, 35, 36, 37, 38, 39, 40],
        selection_end = field.selectionEnd;

      // delete key was pressed
      if (delete_key.indexOf(key_code) > -1) {
        press_delete = true;
      }

      // only force formatting is a number or delete key was pressed
      if (key_string.match(/^\d+$/) || press_delete) {
        phone_formatting(field, press_delete);
      }
      // do nothing for direction keys, keep their default actions
      else if (direction_key.indexOf(key_code) > -1) {
        // do nothing
      } else if (dash_key === key_code) {
        if (selection_end === field.value.length) {
          field.value = field.value.slice(0, -1);
        } else {
          field.value =
            field.value.substring(0, selection_end - 1) +
            field.value.substr(selection_end);
          field.selectionEnd = selection_end - 1;
        }
      }
      // all other non numerical key presses, remove their value
      else {
        e.preventDefault();
        //    field.value = field.value.replace(/[^0-9\-]/g,'')
        phone_formatting(field, "revert");
      }
    }

    const Validate = (e) => {
      if (!String.fromCharCode(e.keyCode).match(/^- $/)) {
        this.handleChange(e);
      }
    };

    document.getElementById(e.target.id).onkeyup = function(e) {
      phone_number_check(this, e);
      Validate(e);
    };
  }

  AutoFormatEIN(e) {
    function phone_formatting(ele, restore) {
      var new_number,
        // selection_start = ele.selectionStart,
        // selection_end = ele.selectionEnd,
        number = ele.value.replace(/\D/g, "");

      // automatically add dashes
      if (number.length > 1) {
        // matches: 123 || 123-4 || 123-45
        new_number = number.substring(0, 2) + "-";
        if (number.length === 3 || number.length === 4) {
          // matches: 123-4 || 123-45
          new_number += number.substr(2);
        } else if (number.length > 4) {
          // matches: 123-456 || 123-456-7 || 123-456-789
          new_number += number.substring(2, 5);
        }
        if (number.length > 5) {
          // matches: 123-456-7 || 123-456-789 || 123-456-7890
          new_number += number.substring(5);
        }
      } else {
        new_number = number;
      }

      ele.value =
        new_number.length > 50 ? new_number.substring(0, 50) : new_number;
    }

    function phone_number_check(field, e) {
      var key_code = e.keyCode,
        key_string = String.fromCharCode(key_code),
        press_delete = false,
        dash_key = 189,
        delete_key = [8, 46],
        direction_key = [33, 34, 35, 36, 37, 38, 39, 40],
        selection_end = field.selectionEnd;

      // delete key was pressed
      if (delete_key.indexOf(key_code) > -1) {
        press_delete = true;
      }

      // only force formatting is a number or delete key was pressed
      if (key_string.match(/^\d+$/) || press_delete) {
        phone_formatting(field, press_delete);
      }
      // do nothing for direction keys, keep their default actions
      else if (direction_key.indexOf(key_code) > -1) {
        // do nothing
      } else if (dash_key === key_code) {
        if (selection_end === field.value.length) {
          field.value = field.value.slice(0, -1);
        } else {
          field.value =
            field.value.substring(0, selection_end - 1) +
            field.value.substr(selection_end);
          field.selectionEnd = selection_end - 1;
        }
      }
      // all other non numerical key presses, remove their value
      else {
        e.preventDefault();
        field.value = field.value.replace(/[^0-9\-]/g, "");
        phone_formatting(field, "revert");
      }
    }

    const Validate = (e) => {
      if (!String.fromCharCode(e.keyCode).match(/^- $/)) {
        this.handleChange(e);
      }
    };

    document.getElementById(e.target.id).onkeyup = function(e) {
      phone_number_check(this, e);
      Validate(e);
    };
  }

  async handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields,
    });
    this.validateForm();
  }

  submituserRegistrationForm(e) {
    e.preventDefault();
    var send = false;
    if (this.validateAndSubmit(e) && this.validateForm(e)) {
      send = true;
    }
    if (send) {
      this.submit();
      send = false;
    }
  }
  //submitBillingRegistrationForm....
  submitBillingRegistrationForm(e) {
    e.preventDefault();
    var send = false;
    if (this.validateForm(e)) {
      // if (this.validateBillingSubmit(e) && this.validateForm(e)) {
      send = true;
    }
    if (send) {
      this.billingSubmitForm();
      send = false;
    }
  }
  //......
  submitbankRegistrationForm(e) {
    e.preventDefault();
    var send = false;

    if (this.validatebankSubmit() && this.validateForm()) {
      send = true;
    }
    if (send) {
      this.banksubmitform();
      send = false;
    }
  }

  validateAndSubmit() {
    let errors = {};
    let formIsValid = true;

    // CustomerNAme
    if (
      !this.refs.customerName.value ||
      !this.refs.customerName.value.trim().length > 0
    ) {
      formIsValid = false;
      errors["customerName"] = "*Please Enter Carrier Name";
      $("[name='customerName']").focus();
    } else if (!this.refs.mc.value || !this.refs.mc.value.trim().length > 0) {
      formIsValid = false;
      errors["mcNo"] = "*Please Enter M.C.#";
      $("[name='mcNo']").focus();
    } else if (!this.refs.businesstype.value) {
      formIsValid = false;
      errors["businesstype"] = "*Please Select Business Type";
      $("[name='businesstype']").focus();
    }

    // Address
    else if (
      !this.refs.address.value ||
      !this.refs.address.value.trim().length > 0
    ) {
      formIsValid = false;
      errors["address"] = "*Please Enter Address";
      $("[name='address']").focus();
    }

    // Country
    else if (!this.refs.country_id.value) {
      formIsValid = false;
      errors["country"] = "*Please Select Country";
      $("[name='country']").focus();
    }

    // State
    else if (!this.refs.province_id.value) {
      formIsValid = false;
      errors["state"] = "*Please Select State";
      $("[name='state']").focus();
    }

    // City
    else if (!this.refs.city.value || !this.refs.city.value.trim().length > 0) {
      formIsValid = false;
      errors["city"] = "*Please Enter City";
      $("[name='city']").focus();
    }

    // Zip
    else if (!this.refs.zip.value || !this.refs.zip.value.trim().length > 0) {
      formIsValid = false;
      errors["zip"] = "*Please Enter Zip";
      $("[name='zip']").focus();
    }

    // BillingAddress
    else if (
      !this.refs.billing.value ||
      !this.refs.billing.value.trim().length > 0
    ) {
      formIsValid = false;
      errors["billingAddress"] = "*Please Enter Billing Address";
      $("[name='billingAddress']").focus();
    }

    // Country
    else if (!this.refs.billing_country_id.value) {
      formIsValid = false;
      errors["billingAddressCountry"] = "*Please Select Country";
      $("[name='billingAddressCountry']").focus();
    }

    // State
    else if (!this.refs.billing_province_id.value) {
      formIsValid = false;
      errors["billingAddressState"] = "*Please Select State";
      $("[name='billingAddressState']").focus();
    }

    // City
    else if (
      !this.refs.b_city.value ||
      !this.refs.b_city.value.trim().length > 0
    ) {
      formIsValid = false;
      errors["billingAddressCity"] = "*Please Enter City";
      $("[name='billingAddressCity']").focus();
    }

    // Zip
    else if (
      !this.refs.b_zip.value ||
      !this.refs.b_zip.value.trim().length > 0
    ) {
      formIsValid = false;
      errors["billingAddressZip"] = "*Please Enter Zip";
      $("[name='billingAddressZip']").focus();
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  }
  //validateBillingSubmit.....
  validateBillingSubmit() {
    let errors = {};
    let formIsValid = true;

    // dispatchPercentage
    if (
      !this.refs.dispatchPercentage.value ||
      !this.refs.dispatchPercentage.value.trim().length > 0
    ) {
      formIsValid = false;
      errors["dispatchPercentage"] = "*Please Enter Dispatch Percentage";
      $("[name='dispatchPercentage']").focus();
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  }
  //...............

  validatebankSubmit() {
    let errors = {};
    let formIsValid = true;

    if (
      !this.refs.accountname.value ||
      !this.refs.accountname.value.trim().length > 0
    ) {
      formIsValid = false;
      errors["accountName"] = "*Please Enter Customer Name";
      $("[name='accountName']").focus();
    }

    // Address
    else if (
      !this.refs.bankaddress.value ||
      !this.refs.bankaddress.value.trim().length > 0
    ) {
      formIsValid = false;
      errors["baddress"] = "*Please Enter Address";
      $("[name='baddress']").focus();
    }

    // Country
    else if (!this.refs.bankcountry_id.value) {
      formIsValid = false;
      errors["bcountry"] = "*Please Select Country";
      $("[name='bcountry']").focus();
    }

    // State
    else if (!this.refs.bankprovince_id.value) {
      formIsValid = false;
      errors["bstate"] = "*Please Select State";
      $("[name='bstate']").focus();
    }

    // City
    else if (
      !this.refs.bankcity.value ||
      !this.refs.bankcity.value.trim().length > 0
    ) {
      formIsValid = false;
      errors["bcity"] = "*Please Enter City";
      $("[name='bcity']").focus();
    }

    // Zip
    else if (
      !this.refs.bankzip.value ||
      !this.refs.bankzip.value.trim().length > 0
    ) {
      formIsValid = false;
      errors["bzip"] = "*Please Enter Zip";
      $("[name='bzip']").focus();
    } else {
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    // dispatchPercentage
    // if (
    //   typeof fields["dispatchPercentage"] !== "undefined" &&
    //   fields["dispatchPercentage"]
    // ) {
    //   if (
    //     !fields["dispatchPercentage"].match(
    //       /(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/
    //     )
    //   ) {
    //     formIsValid = false;
    //     errors["dispatchPercentage"] =
    //       "*Percentage Accept Numeric Value And Not Greater Than 100";
    //   }
    // }

    // customerName
    if (
      typeof fields["customerName"] !== "undefined" &&
      fields["customerName"]
    ) {
      if (!fields["customerName"].match(/^[a-zA-Z0-9#'".$,-/ ]{1,200}$/)) {
        formIsValid = false;
        errors["customerName"] = "*Only Alphabets Allowed (Max Range: 200)";
      }
    }

    if (typeof fields["accountName"] !== "undefined" && fields["accountName"]) {
      if (!fields["accountName"].match(/^[a-zA-Z0-9a-zA-Z#'".,-/ ]{1,200}$/)) {
        formIsValid = false;
        errors["accountName"] = "*Only Alphabets Allowed (Max Range: 200)";
      }
    }

    //DotNo
    if (typeof fields["dotNo"] !== "undefined" && fields["dotNo"]) {
      if (!fields["dotNo"].match(/^[a-zA-Z0-9#'.,-/ ]{1,200}$/)) {
        formIsValid = false;
        errors["dotNo"] = "*Only Alpha-Numeric Allowed (Max Range: 200)";
      } else if (
        !this.state.readonly &&
        fields["dotNo"].trim().length > 0 &&
        fields["dotNo"].trim().length < 200
      ) {
        errors["dotNo"] = "";
        this.setState({ errors });

        axios
          .post(BaseURL + "carrierInformation/findByDotNo", {
            // "id": this.state.loadid,
            dotNo: fields["dotNo"].trim(),
            token: this.state.token,
          })
          .then((response) => {
            if (response.data.error === TokenErrorMsg) {
              localStorage.setItem("Token", "");
              this.props.history.push("/");
            }

            console.log("Load resonse ...:", response.data);

            response.data.error
              ? (errors["dotInternalload"] =
                  "*Record Already Exist With this DOT No")
              : (errors["dotInternalload"] = "");

            this.setState({ errors });
          })
          .catch(function(error) {
            NotificationManager.error(
              "Something Went Wrong Server Is Not Responding ",
              "Error Message",
              3000
            );
            console.log(error);
          });
      } else {
        errors["dotNo"] = "";
        errors["dotInternalload"] = "";
        this.setState({ errors });
      }
    }

    //legal name
    if (typeof fields["legalname"] !== "undefined" && fields["legalname"]) {
      if (!fields["legalname"].match(/^[a-zA-Z0-9 ]{1,200}$/)) {
        formIsValid = false;
        errors["legalname"] = "*Only Alpha-Numeric Allowed (Max Range: 200)";
      }
    }
    //ScAc
    if (typeof fields["scac"] !== "undefined" && fields["scac"]) {
      if (!fields["scac"].match(/^[a-zA-Z0-9 ]{1,200}$/)) {
        formIsValid = false;
        errors["scac"] = "*Only Alpha-Numeric Allowed (Max Range: 200)";
      }
    }
    //CARB
    if (typeof fields["carb"] !== "undefined" && fields["carb"]) {
      if (!fields["carb"].match(/^[a-zA-Z0-9 ]{1,200}$/)) {
        formIsValid = false;
        errors["carb"] = "*Only Alpha-Numeric Allowed (Max Range: 200)";
      }
    }
    //FID
    if (typeof fields["fid"] !== "undefined" && fields["fid"]) {
      if (!fields["fid"].match(/^[0-9-]{0,50}$/)) {
        formIsValid = false;
        errors["fid"] = "*Only Valid EIN Allowed ";
      }
    }

    //mcNo

    if (typeof fields["mcNo"] !== "undefined" && fields["mcNo"]) {
      if (!fields["mcNo"].match(/^[a-zA-Z0-9#.,-/ ]{1,200}$/)) {
        formIsValid = false;
        errors["mcNo"] = "*Only Alpha-Numeric Allowed (Max Range: 200)";
      } else if (
        !this.state.readonly &&
        fields["mcNo"].trim().length > 0 &&
        fields["mcNo"].trim().length < 200
      ) {
        errors["mcNo"] = "";
        this.setState({ errors });

        axios
          .post(BaseURL + "carrierInformation/findByMcNo", {
            // "id": this.state.loadid,
            mcNo: fields["mcNo"].trim(),
            token: this.state.token,
          })
          .then((response) => {
            if (response.data.message === "fail") {
              this.state.saveBtnDisable = false;
            }
            if (response.data.message === "success") {
              this.state.saveBtnDisable = true;
            }
            if (response.data.error === TokenErrorMsg) {
              localStorage.setItem("Token", "");
              this.props.history.push("/");
            }

            console.log("Load resonse ...:", response.data);

            response.data.error
              ? (errors["mcInternalload"] =
                  // "*Record Already Exist With this M.C No"
                  "*Carrier with same MC# exists and is active, Please InActive of existing carrier")
              : (errors["mcInternalload"] = "");

            this.setState({ errors });
          })
          .catch(function(error) {
            NotificationManager.error(
              "Something Went Wrong Server Is Not Responding ",
              "Error Message",
              3000
            );
            console.log(error);
          });
      } else {
        errors["mcNo"] = "";
        errors["mcInternalload"] = "";
        this.setState({ errors });
      }
    }

    //address
    if (typeof fields["baddress"] !== "undefined" && fields["baddress"]) {
      if (!fields["baddress"].match(/^[a-zA-Z0-9#'"،.,-/ ]{1,200}$/)) {
        formIsValid = false;
        errors["baddress"] =
          "*Please Enter Only Valid Characters (Max Range: 200)";
      }
    }

    if (typeof fields["address"] !== "undefined" && fields["address"]) {
      if (!fields["address"].match(/^[a-zA-Z0-9#'"،.,-;/ ]{1,200}$/)) {
        formIsValid = false;
        errors["address"] =
          "*Please Enter Only Valid Characters (Max Range: 200)";
      }
    }

    //addressLineTwo

    if (
      typeof fields["addressLineTwo"] !== "undefined" &&
      fields["addressLineTwo"]
    ) {
      if (!fields["addressLineTwo"].match(/^[a-zA-Z0-9#'"،.,-;/ ]{1,200}$/)) {
        formIsValid = false;
        errors["addressLineTwo"] =
          "*Please Enter Only Valid Characters (Max Range: 200)";
      }
    }

    //city
    if (typeof fields["bcity"] !== "undefined" && fields["bcity"]) {
      if (!fields["bcity"].match(/^[a-zA-Z0-9#., ]{1,200}$/)) {
        formIsValid = false;
        errors["bcity"] =
          "*Please Enter Only Valid Characters (Max Range: 200)";
      }
    }

    if (typeof fields["city"] !== "undefined" && fields["city"]) {
      if (!fields["city"].match(/^[a-zA-Z0-9#., ]{1,200}$/)) {
        formIsValid = false;
        errors["city"] = "*Please Enter Only Valid Characters (Max Range: 200)";
      }
    }

    //zip

    if (typeof fields["zip"] !== "undefined" && fields["zip"]) {
      if (!fields["zip"].match(/^[a-zA-Z0-9 ]{1,35}$/)) {
        formIsValid = false;
        errors["zip"] = "*Only Numbers Allowed in Zip (Max Range: 35)";
      }
    }

    if (typeof fields["telephone"] !== "undefined" && fields["telephone"]) {
      if (!fields["telephone"].match(/^[0-9- ]{12,30}$/)) {
        formIsValid = false;
        errors["telephone"] =
          "*Please Enter only valid Telephone Number ( Range: 10)";
      }
    }

    if (typeof fields["btelephone"] !== "undefined" && fields["btelephone"]) {
      if (!fields["btelephone"].match(/^[0-9- ]{12,30}$/)) {
        formIsValid = false;
        errors["btelephone"] =
          "*Please Enter only valid Telephone Number ( Range: 10)";
      }
    }

    if (typeof fields["bzip"] !== "undefined" && fields["bzip"]) {
      if (!fields["bzip"].match(/^[a-zA-Z0-9 ]{1,35}$/)) {
        formIsValid = false;
        errors["bzip"] = "*Only Numbers Allowed in Zip (Max Range: 35)";
      }
    }

    //telephone

    //Ext

    if (typeof fields["ext"] !== "undefined" && fields["ext"]) {
      if (!fields["ext"].match(/^[0-9]{1,20}$/)) {
        formIsValid = false;
        errors["ext"] = "*Only Numbers Allowed (Max Range: 20)";
      }
    }
    //Fax

    if (typeof fields["fax"] !== "undefined" && fields["fax"]) {
      if (!fields["fax"].match(/^[0-9- ]{12,30}$/)) {
        formIsValid = false;
        errors["fax"] = "*Please Enter only valid fax Number ( Range: 10)";
      }
    }
    if (typeof fields["bfax"] !== "undefined" && fields["bfax"]) {
      if (!fields["bfax"].match(/^[0-9- ]{12,30}$/)) {
        formIsValid = false;
        errors["bfax"] = "*Please Enter only valid fax Number ( Range: 10)";
      }
    }

    //email

    if (typeof fields["email"] !== "undefined" && fields["email"]) {
      if (
        !fields["email"].match(
          /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/
        )
      ) {
        formIsValid = false;
        errors["email"] = "*Please Enter Valid Email (Max Range: 100)";
      }
    }

    // Billing Address Validations

    //address

    if (
      typeof this.refs.billing.value !== "undefined" &&
      this.refs.billing.value
    ) {
      if (!this.refs.billing.value.match(/^[a-zA-Z0-9#'"،.,-;/ ]{1,200}$/)) {
        formIsValid = false;
        errors["billingAddress"] =
          "*Please Enter Only Valid Characters (Max Range: 200)";
      }
    }

    //addressLineTwo

    if (
      typeof this.refs.billing2.value !== "undefined" &&
      this.refs.billing2.value
    ) {
      if (!this.refs.billing2.value.match(/^[a-zA-Z0-9#'"،.,-;/ ]{1,200}$/)) {
        formIsValid = false;
        errors["billingAddressLineTwo"] =
          "*Please Enter Only Valid Characters (Max Range: 200)";
      }
    }

    //city

    if (
      typeof this.refs.b_city.value !== "undefined" &&
      this.refs.b_city.value
    ) {
      if (!this.refs.b_city.value.match(/^[a-zA-Z0-9#., ]{1,200}$/)) {
        formIsValid = false;
        errors["billingAddressCity"] =
          "*Please Enter Only Valid Characters (Max Range: 200)";
      }
    }

    //zip

    if (typeof this.refs.b_zip.value !== "undefined" && this.refs.b_zip.value) {
      if (!this.refs.b_zip.value.match(/^[a-zA-Z0-9 ]{1,35}$/)) {
        formIsValid = false;
        errors["billingAddressZip"] = "*Only Numbers Allowed (Max Range: 35)";
      }
    }

    //telephone

    if (
      typeof this.refs.b_telephone.value !== "undefined" &&
      this.refs.b_telephone.value
    ) {
      if (!this.refs.b_telephone.value.match(/^[0-9- ]{12,30}$/)) {
        formIsValid = false;
        errors["billingAddressTelephone"] =
          "*Please Enter only valid Telephone Number ( Range: 10)";
      }
    }

    //Ext

    if (typeof this.refs.b_ext.value !== "undefined" && this.refs.b_ext.value) {
      if (!this.refs.b_ext.value.match(/^[0-9]{1,20}$/)) {
        formIsValid = false;
        errors["billingAddressExt"] = "*Only Numbers Allowed (Max Range: 20)";
      }
    }
    //Fax

    if (typeof this.refs.b_fax.value !== "undefined" && this.refs.b_fax.value) {
      if (!this.refs.b_fax.value.match(/^[0-9- ]{12,30}$/)) {
        formIsValid = false;
        errors["billingAddressFax"] =
          "*Please Enter only valid fax Number ( Range: 10)";
      }
    }

    //email

    if (
      typeof this.refs.b_email.value !== "undefined" &&
      this.refs.b_email.value
    ) {
      if (
        !this.refs.b_email.value.match(
          /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/
        )
      ) {
        formIsValid = false;
        errors["billingAddressEmail"] =
          "*Please Enter Valid Email (Max Range: 100)";
      }
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  validateInsuranceInfo() {
    let errors = {};
    let formIsValid = true;

    if (typeof this.state.effdate !== "undefined" && this.state.effdate) {
      if (
        this.state.effdate.getFullYear() < 2000 ||
        this.state.effdate.getFullYear() > 2099
      ) {
        formIsValid = false;
        errors["effdate"] = "*Enter Valid Date ( Year Between 2000-2099 )";
      }
    }

    if (typeof this.state.expdate !== "undefined" && this.state.expdate) {
      var eff = this.state.effdate.toLocaleDateString();
      var exp = this.state.expdate.toLocaleDateString();

      if (
        this.state.expdate.getFullYear() < 2000 ||
        this.state.expdate.getFullYear() > 2099
      ) {
        formIsValid = false;
        errors["expdate"] = "*Enter Valid Date ( Year Between 2000-2099 )";
      }
      // else if (  exp < eff   ) {
      //   formIsValid = false;

      //   errors["expdate"] = "*Expiration Date Cannot be Less than  Effective Date";
      // }
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  onUnload = (event) => {
    event.preventDefault();
    var http = new XMLHttpRequest();
    var params;
    var url = BaseURL + "editModeInfo/finishEditMode";

    if (this.state.CheckEditInfo.carrierId)
      params =
        "carrierId=" + this.state.customerid + "&token=" + this.state.token;
    else if (this.state.CheckEditInfo.bankDetailId)
      params =
        "bankDetailId=" + this.state.bankid + "&token=" + this.state.token;
    else if (this.state.CheckEditInfo.carrierContactId)
      params =
        "carrierContactId=" +
        this.state.CheckEditInfo.carrierContactId +
        "&token=" +
        this.state.token;
    else if (this.state.CheckEditInfo.insuranceLiabilityId)
      params =
        "insuranceLiabilityId=" +
        this.state.CheckEditInfo.insuranceLiabilityId +
        "&token=" +
        this.state.token;

    http.open("POST", url, true);

    http.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

    if (params) {
      http.send(params);
      var start = new Date().getTime();
      while (new Date().getTime() < start + 350);
    }
  };

  async componentDidMount() {
    window.addEventListener("beforeunload", this.onUnload);

    const id = this.props.match.params.id;

    var arr = {
        countries: [],
        addressStates: [],
        billingAddressStates: [],
        carrierContacts: [],
        insuranceAgents: [],
        liabilityTypes: [],
        insuranceLiabilities: [],
        factoringCompanies: [],
      },
      carrierdata = {};
    var operation = "",
      w9 = "",
      insur = "",
      copy = "",
      carb = "";

    var bankid = "",
      addressid = "",
      bankdata = {},
      AccountType = 1,
      bankstates = [];

    var role = "Tenant";
    var subRole = "Basic";
    const Role = localStorage.getItem("Role");
    const SubRole = localStorage.getItem("SubRole");
    if (Role) {
      role = cryptr.decrypt(Role);
      this.setState({ role: role });
    }

    if (SubRole) {
      subRole = cryptr.decrypt(SubRole);
      this.setState({ subRole: subRole });
    }

    const token = localStorage.getItem("Token");
    await this.setState({ token: token, customerid: id, role: role });

    // await axios.post(BaseURL+'countries',{
    // 'token':token})
    // .then(function (response) {
    //   arr = response.data.data;
    //   data=true;
    // })
    // if(data){
    //   this.setState({countries:arr});
    // }

    await axios
      .post(BaseURL + "carrierInformation/findById", {
        id: id,
        token: this.state.token,
      })
      .then((response) => {
        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem("Token", "");
          this.props.history.push("/");
        }

        var carrier = response.data.data.carrier;
        arr = response.data.data;
        console.log("Carrier", response.data);
        carrierdata = {
          status: carrier.status,
          businessTypeId: carrier.businessTypeId,
          carrierName: carrier.carrierName,
          legalName: carrier.legalName,
          mcNo: carrier.mcNo,
          dotNo: carrier.dotNo,
          scacCode: carrier.scacCode,
          carbNo: carrier.legalName,
          fidEinNo: carrier.fidEinNo,
          sameAsBillingAddress: carrier.sameAsBillingAddress,

          address: carrier.address.address,
          addressid: carrier.address.id,
          addressLineTwo: carrier.address.addressLineTwo,
          addressCountryId: carrier.address.addressCountryId,
          addressStateId: carrier.address.addressStateId,
          city: carrier.address.city,
          zip: carrier.address.zip,
          email: carrier.address.email,
          telephone: carrier.address.telephone,
          ext: carrier.address.ext,
          fax: carrier.address.fax,

          baddress: carrier.billingAddress.address,
          baddressid: carrier.billingAddress.id,
          baddressLineTwo: carrier.billingAddress.addressLineTwo,
          baddressCountryId: carrier.billingAddress.billingAddressCountryId,
          baddressStateId: carrier.billingAddress.billingAddressStateId,
          bcity: carrier.billingAddress.city,
          bzip: carrier.billingAddress.zip,
          bemail: carrier.billingAddress.email,
          btelephone: carrier.billingAddress.telephone,
          bext: carrier.billingAddress.ext,
          bfax: carrier.billingAddress.fax,
          factoringCompanyId: carrier.factoringCompanyId,
          isFactorySelected: carrier.isFactorySelected,
        };
        var bankdetail = response.data.data.carrierBankDetail;
        if (bankdetail) {
          bankdata = {
            accountName: bankdetail.accountName,
            bankName: bankdetail.bankName,
            routingNo: bankdetail.routingNo,
            accountNo: bankdetail.accountNo,
            address: bankdetail.address.address,
            addressLineTwo: bankdetail.address.addressLineTwo,
            countryId: bankdetail.address.addressCountryId,
            stateId: bankdetail.address.addressStateId,
            city: bankdetail.address.city,
            zip: bankdetail.address.zip,
            email: bankdetail.address.email,
            telephone: bankdetail.address.telephone,
            ext: bankdetail.address.ext,
            fax: bankdetail.address.fax,
          };
          bankid = bankdetail.id;
          addressid = bankdetail.bankAddressId;
          AccountType = bankdetail.accountType;
          bankstates = arr.bankDetailStates;
        }

        response.data.data.carrierDocuments.forEach((element) => {
          var filename = element.documentName;
          if (filename.length > 15) {
            filename = filename.substring(0, 15) + "....";
          }

          if (Number(element.documentType) === 1) {
            operation = { name: filename };
          } else if (Number(element.documentType) === 2) {
            w9 = { name: filename };
          } else if (Number(element.documentType) === 3) {
            insur = { name: filename };
          } else if (Number(element.documentType) === 4) {
            copy = { name: filename };
          } else if (Number(element.documentType) === 5) {
            carb = { name: filename };
          }
        });

        this.setState({
          showSelectedRowOnTabsBoolean: true,
        });
        this.setState({
          showSelectedRowOnTabs: response.data.data.carrier,
        });
      })
      .catch(function(error) {
        NotificationManager.error(
          "Something Went Wrong Server Is Not Responding ",
          "Error Message",
          3000
        );
        console.log(error);
      });

    if (carrierdata.status === 0) {
      $("#inactiveLabel").addClass("active");
    } else {
      $("#activeLabel").addClass("active");
    }

    var factorySelectionOptions = [];

    arr.factoringCompanies["rows"].forEach((element) => {
      var obj = { value: element.id, label: element.companyName };
      factorySelectionOptions.push(obj);
    });

    await this.setState({
      carrierData: arr,
      carrierformdata: carrierdata,
      status: carrierdata.status,
      addressid: carrierdata.addressid,
      billaddid: carrierdata.baddressid,
      countries: arr.countries,
      states: arr.addressStates,
      bstates: arr.billingAddressStates,
      Contacts: arr.carrierContacts,
      companies: arr.insuranceAgents,
      LiabilityTypes: arr.liabilityTypes,
      InsuranceInfo: arr.insuranceLiabilities,
      bankstates: bankstates,
      bankid: bankid,
      bankaddressid: addressid,
      bankformdata: bankdata,
      AccountType: AccountType,
      file: {
        operation: operation,
        w9: w9,
        insur: insur,
        copy: copy,
        carb: carb,
      },
      operationload: true,
      w9load: true,
      insuranceload: true,
      copyload: true,
      carbload: true,

      factoringCompanies: arr.factoringCompanies["rows"],
      factoringCompanyId: carrierdata.factoringCompanyId
        ? carrierdata.factoringCompanyId
        : "",

      factorySelectionOptions: factorySelectionOptions,
      isFactorySelected: carrierdata.isFactorySelected
        ? carrierdata.isFactorySelected
        : "NO",
    });

    this.loadFactoryCompanyCard({ value: carrierdata.factoringCompanyId });

    if (carrierdata.sameAsBillingAddress === 1) {
      console.log(arr);
      this.setState({ errorMsg: true });
    }

    //   var state,bstate;

    //   await axios.post(BaseURL+'countries/states',{
    //     "countryId": carrierdata.addressCountryId,
    //        'token':this.state.token
    //   })
    //   .then(function (response) {
    //     state = response.data.data;
    //   })

    //  await this.setState({states:state});

    // await axios.post(BaseURL+'countries/states',{
    //   "countryId": carrierdata.baddressCountryId,
    //      'token':this.state.token
    // })
    // .then(function (response) {
    //   bstate = response.data.data;      })

    // await this.setState({bstates:bstate});

    //   await axios.post(BaseURL+'carrierContact/findByCarrierId', {
    //     "carrierId" :id,
    //     'token':this.state.token
    //   } )
    //   .then(function (response) {
    //      contact=response.data.data;
    //      data=true;

    //   })

    //  await this.setState({Contacts:contact});

    // await axios.post(BaseURL+'insuranceLiabilities/findAllCompanies',{
    //   'token':token})
    //   .then(function (response) {
    //     Companies= response.data.data;

    //   })

    //     this.setState({companies:Companies});

    // await axios.post(BaseURL+'insuranceLiabilities/findAllLiabilityTypes',{
    //   'token':token})
    //   .then(function (response) {
    //     console.log(response.data);
    //     liabTypes= response.data.data;

    //   })
    //   await this.setState({LiabilityTypes:liabTypes});

    //   await axios.post(BaseURL+'insuranceLiabilities/findByCarrierId',
    //   {
    //     "carrierId":id,
    //     "token": this.state.token
    //   }
    //    )
    //   .then(function (response) {
    //     console.log(response.data)
    //     insurancedata=response.data.data;

    //   })

    //  await this.setState({InsuranceInfo:insurancedata});

    // await axios.post(BaseURL+'carrierDocuments/getDocuments',{
    //   "carrierId":   this.state.customerid,
    //   'token':this.state.token
    // })
    // .then(function (response) {
    //   console.log(response.data);

    //      response.data.documents.forEach(element => {

    //       var filename=  element.documentName ;
    //       if(filename.length>15)
    //       {
    //         filename=filename.substring(0, 15)+'....'

    //       }

    //   if(element.documentType == 1){
    //      operation={'name':filename};

    //   }
    //   else if(element.documentType == 2){
    //     w9={'name':filename};

    //   }
    //   else if(element.documentType == 3){
    //     insur={'name':filename};
    //   }
    //   else if(element.documentType == 4){
    //     copy={'name':filename};
    //   }
    //   });
    // })

    // await axios.post(BaseURL+'carrierBankDetails/findById',
    // {
    //   "carrierId":id,
    //   "token": this.state.token
    // }
    //  )
    // .then(function (response) {

    //   if(response.data.data){

    //     bankdata={
    //       'accountName':response.data.data.accountName,
    //       'bankName':response.data.data.bankName,
    //       'routingNo':response.data.data.routingNo,
    //       'accountNo':response.data.data.accountNo,
    //       'address':response.data.data.address.address,
    //       'addressLineTwo':response.data.data.address.addressLineTwo,
    //       'countryId':response.data.data.address.state.countryId,
    //       'stateId':response.data.data.address.state.id,
    //       'city':response.data.data.address.city,
    //       'zip':response.data.data.address.zip,
    //       'email':response.data.data.address.email,
    //       'telephone':response.data.data.address.telephone,
    //       'ext':response.data.data.address.ext,
    //       'fax':response.data.data.address.fax,
    //        };
    //         bankid= response.data.data.id;
    //         addressid=  response.data.data.bankAddressId;
    //         AccountType= response.data.data.accountType;
    //   }

    //   console.log(response.data)

    // })

    //   if(bankdata.countryId){
    //   await axios.post(BaseURL+'countries/states',{
    //     "countryId": bankdata.countryId,
    //        'token':this.state.token
    //   })
    //   .then(function (response) {
    //     bankstates = response.data.data;
    //   })
    // }

    if (Number(AccountType) === 2) {
      $("#banktype2").addClass("active");
      $("#banktype").removeClass("active");
      // $("#banktype").addClass('inactive');
    }

    // await this.setState({

    //   bankid:bankid,
    //   bankaddressid:addressid,
    //   bankformdata:bankdata,
    //   bankstates:bankstates,
    //   AccountType:AccountType,
    //   file:{
    //     operation:operation,
    //     w9:w9,
    //     insur:insur,
    //     copy:copy
    //   },
    //   operationload:true,
    //   w9load:true,
    //   insuranceload:true,
    //   copyload:true,

    // });
    //Billing.......
    await axios
      .post(BaseURL + "carrierInformation/getBillingDetail", {
        id: this.state.customerid,
        token: this.state.token,
      })
      .then((res) => {
        // console.log("BillingTabRes.....: ", res.data);
        var billingFormData = this.state.billingFormData;
        billingFormData.dispatchPercentage = res.data.dispatchPercentage || 0;
        billingFormData.dispatchType = res.data.dispatchType || 2;
        billingFormData.dispatchFee = res.data.dispatchFee || 0;
        this.setState({
          billingFormData,
        });
      })
      .catch((err) => {
        console.log("BillingTabError.....: ", err);
      });

    //............
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onUnload);
    var config = this.state.CheckEditInfo;
    if (config.token) {
      finishEditinfo(
        config,
        (res) => {
          console.log(res);
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  async states() {
    var arr;
    var data = false;

    await axios
      .post(BaseURL + "countries/states", {
        countryId: this.refs.country_id.value,
        token: this.state.token,
      })
      .then((response) => {
        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem("Token", "");
          this.props.history.push("/");
        }
        arr = response.data.data;
        data = true;
      })
      .catch(function(error) {
        NotificationManager.error(
          "Something Went Wrong Server Is Not Responding ",
          "Error Message",
          3000
        );
        console.log(error);
      });
    if (data) {
      this.setState({ states: arr });
    }
  }

  async bstates() {
    var arr;
    var data = false;

    await axios
      .post(BaseURL + "countries/states", {
        countryId: this.refs.billing_country_id.value,
        token: this.state.token,
      })
      .then((response) => {
        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem("Token", "");
          this.props.history.push("/");
        }
        arr = response.data.data;
        data = true;
      })
      .catch(function(error) {
        NotificationManager.error(
          "Something Went Wrong Server Is Not Responding ",
          "Error Message",
          3000
        );
        console.log(error);
      });
    if (data) {
      this.setState({ bstates: arr });
    }
  }

  async bankstates() {
    var arr;
    var data = false;

    await axios
      .post(BaseURL + "countries/states", {
        countryId: this.refs.bankcountry_id.value,
        token: this.state.token,
      })
      .then((response) => {
        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem("Token", "");
          this.props.history.push("/");
        }
        arr = response.data.data;
        data = true;
      })
      .catch(function(error) {
        NotificationManager.error(
          "Something Went Wrong Server Is Not Responding ",
          "Error Message",
          3000
        );
        console.log(error);
      });

    if (data) {
      this.setState({ bankstates: arr });
    }
  }

  async billaddress() {
    if (this.state.errorMsg === false) {
      await this.setState({ errorMsg: true });
      var arr;
      await axios
        .post(BaseURL + "countries/states", {
          countryId: this.refs.country_id.value,
          token: this.state.token,
        })
        .then((response) => {
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem("Token", "");
            this.props.history.push("/");
          }
          arr = response.data.data;
        })
        .catch(function(error) {
          NotificationManager.error(
            "Something Went Wrong Server Is Not Responding ",
            "Error Message",
            3000
          );
          console.log(error);
        });
      await this.setState({ bstates: arr });

      this.refs.billing.value = this.refs.address.value;
      this.refs.billing2.value = this.refs.address2.value;
      this.refs.billing_country_id.value = this.refs.country_id.value;
      this.refs.billing_province_id.value = this.refs.province_id.value;
      this.refs.b_city.value = this.refs.city.value;
      this.refs.b_zip.value = this.refs.zip.value;
      this.refs.b_email.value = this.refs.email.value;
      this.refs.b_telephone.value = this.refs.telephone.value;
      this.refs.b_fax.value = this.refs.fax.value;
      this.refs.b_ext.value = this.refs.ext.value;
    } else {
      this.refs.billing.value = "";
      this.refs.billing2.value = "";
      this.refs.billing_country_id.value = "";
      this.refs.billing_province_id.value = "";
      this.refs.b_city.value = "";
      this.refs.b_zip.value = "";
      this.refs.b_email.value = "";
      this.refs.b_telephone.value = "";
      this.refs.b_fax.value = "";
      this.refs.b_ext.value = "";
      this.setState({
        errorMsg: false,
      });
    }
  }

  async cancel() {
    if (window.confirm(" Are you sure you want to cancel ? "))
      this.props.history.push("/carrier");
  }

  async submit() {
    this.setState({
      whileSave: true,
      hidden: false,
      readonly: true,
    });

    const customerName = this.refs.customerName.value.trim();
    const dot_number = this.refs.dot_number.value.trim();
    const mc = this.refs.mc.value.trim();
    const legal = this.refs.legalname.value.trim();
    const scac = this.refs.scac.value.trim();
    const carb = this.refs.carb.value.trim();
    const fid = this.refs.fid.value.trim();
    const BusinessType = this.refs.businesstype.value;

    const address = this.refs.address.value.trim();
    const address2 = this.refs.address2.value.trim();
    const country_id = this.refs.country_id.value;
    const province_id = this.refs.province_id.value;
    const city = this.refs.city.value.trim();
    const zip = this.refs.zip.value.trim();
    const email = this.refs.email.value.trim();
    const telephone = this.refs.telephone.value.trim();
    const fax = this.refs.fax.value.trim();
    const ext = this.refs.ext.value.trim();

    const billing = this.refs.billing.value.trim();
    const billing2 = this.refs.billing2.value.trim();
    const billing_country_id = this.refs.billing_country_id.value;
    const billing_province_id = this.refs.billing_province_id.value;
    const b_city = this.refs.b_city.value.trim();
    const b_zip = this.refs.b_zip.value.trim();
    const b_email = this.refs.b_email.value.trim();
    const b_telephone = this.refs.b_telephone.value.trim();
    const b_fax = this.refs.b_fax.value.trim();
    const b_ext = this.refs.b_ext.value.trim();

    if (this.state.errorMsg === false) {
      var customer = {
        token: this.state.token,
        status: this.state.status,
        id: this.state.customerid,
        carrierName: customerName,
        dotNo: dot_number,
        sameAsBillingAddress: 0,
        mcNo: mc,
        legalName: legal,
        scacCode: scac,
        carbNo: carb,
        fidEinNo: fid,
        businessTypeId: BusinessType,
        factoringCompanyIdentifier: this.state.factoringCompanyId,
        isFactorySelected: this.state.isFactorySelected,
        address: {
          id: this.state.addressid,
          address: address,
          addressLineTwo: address2,
          city: city,
          zip: zip,
          email: email,
          telephone: telephone,
          ext: ext,
          fax: fax,
          state: {
            id: province_id,
            country: {
              id: country_id,
            },
          },
        },
        billingAddress: {
          id: this.state.billaddid,
          address: billing,
          addressLineTwo: billing2,
          city: b_city,
          zip: b_zip,
          email: b_email,
          telephone: b_telephone,
          ext: b_ext,
          fax: b_fax,
          state: {
            id: billing_province_id,
            country: {
              id: billing_country_id,
            },
          },
        },
      };

      if (this.state.customerid) {
        await axios
          .post(BaseURL + "carrierInformation/update", customer)
          .then((response) => {
            console.log(response);
            if (response.data.error === TokenErrorMsg) {
              localStorage.setItem("Token", "");
              this.props.history.push("/");
            } else if (response.data.status === 1) {
              NotificationManager.error(response.data.message, "Error", 3000);
              return;
            } else if (response.data.status === 0) {
              NotificationManager.error(response.data.message, "Error", 3000);
              return;
            }
            if (response.data.message == "exist") {
              $("#inactiveLabel").addClass("active");
              $("#activeLabel").removeClass("active");
              this.setState({ status: 0 });
              NotificationManager.error(
                "Carrier with same MC# exists and is active, Please InActive of existing carrier",
                "Error Message",
                3000
              );
            } else {
              NotificationManager.success(
                "New Carrier Updated Successfully",
                "Updated",
                3000
              );
            }
          })
          .catch(function(error) {
            NotificationManager.error(
              "Something Went Wrong Server Is Not Responding ",
              "Error Message",
              3000
            );
            console.log(error);
          });
        this.setState({
          whileSave: false,
        });
      }
    } else {
      var billcustomer = {
        token: this.state.token,
        status: this.state.status,
        id: this.state.customerid,
        carrierName: customerName,
        dotNo: dot_number,
        sameAsBillingAddress: 1,
        mcNo: mc,
        legalName: legal,
        scacCode: scac,
        carbNo: carb,
        fidEinNo: fid,
        businessTypeId: BusinessType,
        factoringCompanyIdentifier: this.state.factoringCompanyId,
        isFactorySelected: this.state.isFactorySelected,
        address: {
          id: this.state.addressid,
          address: address,
          addressLineTwo: address2,
          city: city,
          zip: zip,
          email: email,
          telephone: telephone,
          ext: ext,
          fax: fax,
          state: {
            id: province_id,
            country: {
              id: country_id,
            },
          },
        },
        billingAddress: {
          id: this.state.billaddid,
          address: billing,
          addressLineTwo: billing2,
          city: b_city,
          zip: b_zip,
          email: b_email,
          telephone: b_telephone,
          ext: b_ext,
          fax: b_fax,
          state: {
            id: billing_province_id,
            country: {
              id: billing_country_id,
            },
          },
        },
      };
      console.log(billcustomer);

      if (this.state.customerid) {
        await axios
          .post(BaseURL + "carrierInformation/update", billcustomer)
          .then((response) => {
            console.log(response);
            if (response.data.error === TokenErrorMsg) {
              localStorage.setItem("Token", "");
              this.props.history.push("/");
            } else if (response.data.status === 1) {
              NotificationManager.error(response.data.message, "Error", 3000);
              return;
            } else if (response.data.status === 0) {
              NotificationManager.error(response.data.message, "Error", 3000);
              return;
            }
            if (response.data.message == "exist") {
              $("#inactiveLabel").addClass("active");
              $("#activeLabel").removeClass("active");
              this.setState({ status: 0 });
              NotificationManager.error(
                "Carrier with same MC# exists and is active, Please InActive of existing carrier",
                "Error Message",
                3000
              );
            } else {
              NotificationManager.success(
                "New Carrier Updated Successfully",
                "Updated",
                3000
              );
            }
          })
          .catch(function(error) {
            NotificationManager.error(
              "Something Went Wrong Server Is Not Responding ",
              "Error Message",
              3000
            );
            console.log(error);
          });
        this.setState({
          whileSave: false,
        });
      }
    }
  }

  //billingSubmitForm............
  async billingSubmitForm() {
    this.setState({
      whileSave: true,
      billingInfoReadOnly: true,
    });

    var dispatchType = this.state.billingFormData.dispatchType;
    var dispatchPercentage = this.state.billingFormData.dispatchPercentage;
    var dispatchFee = this.state.billingFormData.dispatchFee;

    var customer = {
      token: this.state.token,
      id: this.state.customerid,
      dispatchType: dispatchType,
      dispatchPercentage:
        dispatchPercentage === 0 || dispatchType === 1
          ? null
          : dispatchPercentage,
      dispatchFee: dispatchFee === 0 || dispatchType === 2 ? null : dispatchFee,
    };

    if (customer.id) {
      await axios
        .post(BaseURL + "carrierInformation/updateBillingDetail", customer)
        .then((response) => {
          console.log(response);
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem("Token", "");
            this.props.history.push("/");
          } else if (response.data.status === 1) {
            NotificationManager.error(response.data.message, "Error", 3000);
            return;
          } else if (response.data.status === 0) {
            NotificationManager.error(response.data.message, "Error", 3000);
            return;
          }
          NotificationManager.success(
            "New Billing Updated Successfully",
            "Updated",
            3000
          );
        })
        .catch(function(error) {
          NotificationManager.error(
            "Something Went Wrong Server Is Not Responding ",
            "Error Message",
            3000
          );
          console.log(error);
        });
      this.setState({
        whileSave: false,
      });
    }
  }
  //.................

  async banksubmitform() {
    this.setState({
      whileSave: true,
      bankinfohidden: false,
      bankinforeadonly: true,
    });

    var bankid, addressid;
    const accountname = this.refs.accountname.value.trim();
    const bankname = this.refs.bankname.value.trim();
    const routing = this.refs.routing.value.trim();
    const account = this.refs.account.value.trim();

    const address = this.refs.bankaddress.value.trim();
    const address2 = this.refs.bankaddress2.value.trim();
    const country_id = this.refs.bankcountry_id.value;
    const province_id = this.refs.bankprovince_id.value;
    const city = this.refs.bankcity.value.trim();
    const zip = this.refs.bankzip.value.trim();
    const email = this.refs.bankemail.value.trim();
    const telephone = this.refs.banktelephone.value.trim();
    const fax = this.refs.bankfax.value.trim();
    const ext = this.refs.bankext.value.trim();

    var customer = {
      token: this.state.token,
      accountType: this.state.AccountType,
      id: this.state.bankid,
      accountName: accountname,
      bankName: bankname,
      routingNo: routing,
      accountNo: account,
      carrierId: this.state.customerid,
      address: {
        id: this.state.bankaddressid,
        address: address,
        addressLineTwo: address2,
        city: city,
        zip: zip,
        email: email,
        telephone: telephone,
        ext: ext,
        fax: fax,
        state: {
          id: province_id,
          country: {
            id: country_id,
          },
        },
      },
    };

    if (this.state.bankid) {
      await axios
        .post(BaseURL + "carrierBankDetails/update", customer)
        .then((response) => {
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem("Token", "");
            this.props.history.push("/");
          } else if (response.data.status === 1) {
            NotificationManager.error(response.data.message, "Error", 3000);
            return;
          } else if (response.data.status === 0) {
            NotificationManager.error(response.data.message, "Error", 3000);
            return;
          }
          NotificationManager.success(
            "Bank info Updated Successfully",
            "Updated",
            3000
          );
          console.log(response.data);
        })
        .catch(function(error) {
          NotificationManager.error(
            "Something Went Wrong Server Is Not Responding ",
            "Error Message",
            3000
          );
          console.log(error);
        });
      this.setState({
        whileSave: false,
      });
    } else {
      await axios
        .post(BaseURL + "carrierBankDetails/save", customer)
        .then((response) => {
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem("Token", "");
            this.props.history.push("/");
          } else if (response.data.status === 1) {
            NotificationManager.error(response.data.message, "Error", 3000);
            return;
          } else if (response.data.status === 0) {
            NotificationManager.error(response.data.message, "Error", 3000);
            return;
          }
          console.log(response.data);
          bankid = response.data.data.id;
          addressid = response.data.data.bankAddressId;
          NotificationManager.success(
            "New Bank info Added Successfully",
            "Added",
            3000
          );
        })
        .catch(function(error) {
          NotificationManager.error(
            "Something Went Wrong Server Is Not Responding ",
            "Error Message",
            3000
          );
          console.log(error);
        });
      this.setState({
        bankid: bankid,
        whileSave: false,
        bankaddressid: addressid,
      });
    }
  }

  async SaveContact(event) {
    this.setState({
      isLoading: true,
    });

    event.preventDefault();
    $("#closePopUp").click();

    var arr;
    var data = false;
    var custid = this.state.customerid;

    if (this.state.UpdateContactid) {
      var CustomerContact = {
        token: this.state.token,
        id: this.state.UpdateContactid,
        name: this.refs.ContactName.value.trim(),
        title: this.refs.ContactTitle.value.trim(),
        email: this.refs.ContactEmail.value.trim(),
        telephone: this.refs.ContactTelephone.value.trim(),
        ext: this.refs.ContactExt.value.trim(),
        cellphone: this.refs.ContactPhone.value.trim(),
        fax: this.refs.ContactFax.value.trim(),
      };

      await axios
        .post(BaseURL + "carrierContact/update", CustomerContact)
        .then((response) => {
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem("Token", "");
            this.props.history.push("/");
          }
          console.log(response.data);
          NotificationManager.success(
            "Carrier Contact Infomation Update Successfully",
            "Customer Info",
            3000
          );
        })
        .catch(function(error) {
          NotificationManager.error(
            "Something Went Wrong Server Is Not Responding ",
            "Error Message",
            3000
          );
          console.log(error);
        });

      this.setState({
        UpdateContactid: "",
        whileSave: false,
        isLoading: false,
      });
    } else {
      var CustomerContactUpdate = {
        token: this.state.token,
        carrierId: custid,
        name: this.refs.ContactName.value.trim(),
        title: this.refs.ContactTitle.value.trim(),
        email: this.refs.ContactEmail.value.trim(),
        telephone: this.refs.ContactTelephone.value.trim(),
        ext: this.refs.ContactExt.value.trim(),
        cellphone: this.refs.ContactPhone.value.trim(),
        fax: this.refs.ContactFax.value.trim(),
      };

      await axios
        .post(BaseURL + "carrierContact/save", CustomerContactUpdate)
        .then((response) => {
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem("Token", "");
            this.props.history.push("/");
          }
          console.log(response.data);

          NotificationManager.success(
            "Carrier Contact Infomation Saved",
            "Customer Info",
            3000
          );
        })
        .catch(function(error) {
          NotificationManager.error(
            "Something Went Wrong Server Is Not Responding ",
            "Error Message",
            3000
          );
          console.log(error);
        });
      this.setState({
        whileSave: false,
        isLoading: false,
      });
    }

    await axios
      .post(BaseURL + "carrierContact/findByCarrierId", {
        carrierId: custid,
        token: this.state.token,
      })
      .then((response) => {
        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem("Token", "");
          this.props.history.push("/");
        }
        arr = response.data.data;
        data = true;
      })
      .catch(function(error) {
        NotificationManager.error(
          "Something Went Wrong Server Is Not Responding ",
          "Error Message",
          3000
        );
        console.log(error);
      });

    if (data) {
      this.setState({ Contacts: arr });
      this.refs.ContactName.value = "";
      this.refs.ContactTitle.value = "";
      this.refs.ContactEmail.value = "";
      this.refs.ContactTelephone.value = "";
      this.refs.ContactExt.value = "";
      this.refs.ContactPhone.value = "";
      this.refs.ContactFax.value = "";
    }
  }

  async ClearContact() {
    await this.setState({
      UpdateContactid: "",
    });

    this.refs.ContactName.value = "";
    this.refs.ContactTitle.value = "";
    this.refs.ContactEmail.value = "";
    this.refs.ContactTelephone.value = "";
    this.refs.ContactExt.value = "";
    this.refs.ContactPhone.value = "";
    this.refs.ContactFax.value = "";
  }

  async EditContact(e) {
    var a = document.getElementById(e.id);
    a.setAttribute("style", " cursor: not-allowed; pointer-events: none");

    var config = {
      carrierContactId: e.id,
      token: this.state.token,
    };

    getEditinfo(
      config,
      (res) => {
        console.log(res);
        if (res.data.status === 0) {
          this.refs.ContactName.value = e.name;
          this.refs.ContactTitle.value = e.title;
          this.refs.ContactEmail.value = e.email;
          this.refs.ContactTelephone.value = e.telephone;
          this.refs.ContactExt.value = e.ext;
          this.refs.ContactPhone.value = e.cellphone;
          this.refs.ContactFax.value = e.fax;

          a.href = "#contact";
          a.click();

          this.setState({
            UpdateContactid: e.id,
            CheckEditInfo: config,
          });

          a.removeAttribute("href");
          a.removeAttribute("style");
        } else {
          a.removeAttribute("style");
          NotificationManager.warning(
            res.data.message,
            "Warning Message",
            3000
          );
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  EditForm() {
    var div = document.getElementById("EditDiv");
    div.setAttribute("style", " cursor: not-allowed; pointer-events: none");

    var config = {
      carrierId: this.state.customerid,
      token: this.state.token,
    };

    getEditinfo(
      config,
      (res) => {
        console.log(res);
        if (res.data.status === 0) {
          this.setState({
            readonly: false,
            CheckEditInfo: config,
          });
        } else {
          div.removeAttribute("style");
          NotificationManager.warning(
            res.data.message,
            "Warning Message",
            3000
          );
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  // EditBillingForm .........
  EditBillingForm() {
    var div = document.getElementById("editBillingDiv");
    div.setAttribute("style", " cursor: not-allowed; pointer-events: none");

    var config = {
      carrierBillingId: this.state.customerid,
      token: this.state.token,
    };

    getEditinfo(
      config,
      (res) => {
        console.log(res);
        if (res.data.status === 0) {
          this.setState({
            billingInfoReadOnly: false,
            CheckEditInfo: config,
          });
        } else {
          div.removeAttribute("style");
          NotificationManager.warning(
            res.data.message,
            "Warning Message",
            3000
          );
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  //............

  async DelContact(e) {
    if (!window.confirm("Are you sure you want to delete Contact?")) {
      return false;
    }
    this.setState({
      isLoading: true,
    });
    var arr;

    await axios
      .post(BaseURL + "carrierContact/delete", {
        id: e.id,
        token: this.state.token,
      })
      .then((response) => {
        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem("Token", "");
          this.props.history.push("/");
        }
        console.log(response.data);
        NotificationManager.success(
          response.data.message,
          "Contact Info",
          3000
        );
      })
      .catch(function(error) {
        NotificationManager.error(
          "Something Went Wrong Server Is Not Responding ",
          "Error Message",
          3000
        );
        console.log(error);
      });

    this.setState({
      isLoading: false,
    });

    await axios
      .post(BaseURL + "carrierContact/findByCarrierId", {
        carrierId: this.state.customerid,
        token: this.state.token,
      })
      .then((response) => {
        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem("Token", "");
          this.props.history.push("/");
        }
        arr = response.data.data;
      })
      .catch(function(error) {
        NotificationManager.error(
          "Something Went Wrong Server Is Not Responding ",
          "Error Message",
          3000
        );
        console.log(error);
      });

    this.setState({ Contacts: arr });
  }

  async loadagent(e) {
    var companies = this.state.companies;
    var id = this.refs.companies.value;

    for (const key in companies) {
      if (companies.hasOwnProperty(key)) {
        const element = companies[key];
        if (Number(id) === element.id) {
          var obj = {
            address: element.address.address,
            city: element.address.city,
            state: element.address.state.state,
          };

          this.setState({ companydata: obj });
        }
      }
    }
    var agent;
    await axios
      .post(BaseURL + "insuranceLiabilities/findInsuranceAgentsByCompanyId", {
        insuranceAgentId: this.refs.companies.value,
        token: this.state.token,
      })
      .then((response) => {
        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem("Token", "");
          this.props.history.push("/");
        }
        console.log(response.data.data);
        agent = response.data.data;
      })
      .catch(function(error) {
        NotificationManager.error(
          "Something Went Wrong Server Is Not Responding ",
          "Error Message",
          3000
        );
        console.log(error);
      });

    this.setState({ agents: agent, agentdata: {} });

    this.refs.agent.value = "";
  }

  async loadagentinfo(e) {
    var agents = this.state.agents;

    for (const key in agents) {
      if (agents.hasOwnProperty(key)) {
        const element = agents[key];
        if (Number(this.refs.agent.value) === element.id) {
          this.setState({ agentdata: element });
        }
      }
    }
  }

  async submitInsuranceInfo(event) {
    event.preventDefault();

    if (!this.validateInsuranceInfo()) {
      return false;
    }

    $("#close").click();

    var arr = "",
      agent = null;
    if (this.refs.agent.value) {
      agent = this.refs.agent.value;
    }

    var insuranceinfo = {
      carrierId: this.state.customerid,
      id: this.state.EditInsuranceLiability.id,
      liabilitiesInsuranceAgentId: this.refs.companies.value,
      insuranceAgentContactId: agent,
      policyNo: this.refs.policy.value.trim(),
      limit: this.refs.limit.getMaskedValue(),
      insurer: this.refs.insurer.value.trim(),
      effectiveDate: this.state.effdate,
      expirationDate: this.state.expdate,
      liabilityTypeId: this.refs.liabilityType.value,
      isGeneralLiability: 0,
      isAutomobileLiability: 0,
      isMotorcargoLiability: 0,
      isPhysicalDamageLiability: 0,
      token: this.state.token,
    };

    if (this.state.EditInsuranceLiability.id) {
      await axios
        .post(BaseURL + "insuranceLiabilities/update", insuranceinfo)
        .then((response) => {
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem("Token", "");
            this.props.history.push("/");
          }
          NotificationManager.success(
            "Insurance info Updated Successfully",
            "Updated",
            3000
          );
          console.log(response.data);
        })
        .catch(function(error) {
          NotificationManager.error(
            "Something Went Wrong Server Is Not Responding ",
            "Error Message",
            3000
          );
          console.log(error);
        });
    } else {
      await axios
        .post(BaseURL + "insuranceLiabilities/save", insuranceinfo)
        .then((response) => {
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem("Token", "");
            this.props.history.push("/");
          }
          NotificationManager.success(
            "Insurance info Added Successfully",
            "Added",
            3000
          );
          console.log(response.data);
        })
        .catch(function(error) {
          NotificationManager.error(
            "Something Went Wrong Server Is Not Responding ",
            "Error Message",
            3000
          );
          console.log(error);
        });
    }

    this.state.effdate = await new Date();
    this.state.expdate = await new Date();

    await axios
      .post(BaseURL + "insuranceLiabilities/findByCarrierId", {
        carrierId: this.state.customerid,
        token: this.state.token,
      })
      .then((response) => {
        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem("Token", "");
          this.props.history.push("/");
        }
        console.log(response.data);
        arr = response.data.data;
      })
      .catch(function(error) {
        NotificationManager.error(
          "Something Went Wrong Server Is Not Responding ",
          "Error Message",
          3000
        );
        console.log(error);
      });

    if (arr) {
      await this.setState({
        InsuranceInfo: arr,
        companydata: {},
        agentdata: {},
        EditInsuranceLiability: {
          id: "",
        },
      });
    }

    this.refs.companies.value = await "";
    this.refs.agent.value = await "";
    this.refs.policy.value = await "";
    this.refs.limit.value = await "";
    this.refs.insurer.value = await "";
    this.refs.liabilityType.value = await "";
  }

  async ClearInsuranceInfo() {
    await this.setState({
      effdate: new Date(),
      expdate: new Date(),
      insuranceinforeadonly: false,
      hideinsuranceinfobutton: true,
      companydata: {},
      agentdata: {},
      agents: [],
      EditInsuranceLiability: {
        id: "",
      },
      errors: {},
      CurrencyValue: 0,
    });

    this.refs.companies.value = await "";
    this.refs.agent.value = await "";
    this.refs.policy.value = await "";
    this.refs.limit.value = await "";
    this.refs.insurer.value = await "";
    this.refs.liabilityType.value = await "";
  }

  async EditInsuranceInfo(data) {
    console.log(data);

    await this.setState({
      agentdata: "",
      effdate: new Date(data.effectiveDate),
      expdate: new Date(data.expirationDate),
      insuranceinforeadonly: true,
      hideinsuranceinfobutton: false,
      EditInsuranceLiability: {
        id: data.id,
        company: data.liabilitiesInsuranceAgentId,
        agent: data.insuranceAgentContactId,
        type: data.liabilityTypeId,
      },
      CurrencyValue: data.limit,
    });
    var obj;

    this.state.carrierData.insuranceAgents.forEach((agents) => {
      if (agents.id === data.liabilitiesInsuranceAgentId) {
        obj = {
          address: agents.address.address,
          city: agents.address.city,
          state: agents.address.state.state,
        };
      }
    });
    this.setState({ companydata: obj });

    var agent = "";
    await axios
      .post(BaseURL + "insuranceLiabilities/findInsuranceAgentsByCompanyId", {
        insuranceAgentId: this.state.EditInsuranceLiability.company,
        token: this.state.token,
      })
      .then((response) => {
        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem("Token", "");
          this.props.history.push("/");
        }
        console.log(response.data.data);
        agent = response.data.data;
      })
      .catch(function(error) {
        NotificationManager.error(
          "Something Went Wrong Server Is Not Responding ",
          "Error Message",
          3000
        );
        console.log(error);
      });

    await this.setState({ agents: agent });

    var agents = this.state.agents;

    for (const key in agents) {
      if (agents.hasOwnProperty(key)) {
        const element = agents[key];
        if (this.state.EditInsuranceLiability.agent === element.id) {
          this.setState({ agentdata: element });
        }
      }
    }

    this.refs.companies.value = await this.state.EditInsuranceLiability.company;
    if (this.state.EditInsuranceLiability.agent) {
      this.refs.agent.value = await this.state.EditInsuranceLiability.agent;
    } else {
      this.refs.agent.value = "";
    }

    this.refs.policy.value = await data.policyNo;
    this.refs.insurer.value = await data.insurer;
    this.refs.liabilityType.value = await this.state.EditInsuranceLiability
      .type;
    console.log("curr", this.state.CurrencyValue);
  }

  async delInsuranceInfo() {
    if (
      window.confirm("Are you sure you want to delete Insurance Information?")
    ) {
      $("#closeInsuranceLiabilityPopup").click();

      var arr;
      await axios
        .post(BaseURL + "insuranceLiabilities/delete", {
          id: this.state.EditInsuranceLiability.id,
          token: this.state.token,
        })
        .then((response) => {
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem("Token", "");
            this.props.history.push("/");
          }
          console.log(response.data);

          NotificationManager.success(response.data.message, "Deleted", 3000);
        })
        .catch(function(error) {
          NotificationManager.error(
            "Something Went Wrong Server Is Not Responding ",
            "Error Message",
            3000
          );
          console.log(error);
        });

      await axios
        .post(BaseURL + "insuranceLiabilities/findByCarrierId", {
          carrierId: this.state.customerid,
          token: this.state.token,
        })
        .then((response) => {
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem("Token", "");
            this.props.history.push("/");
          }

          console.log(response.data);
          arr = response.data.data;
        })
        .catch(function(error) {
          NotificationManager.error(
            "Something Went Wrong Server Is Not Responding ",
            "Error Message",
            3000
          );
          console.log(error);
        });

      if (arr) {
        await this.setState({
          InsuranceInfo: arr,
          EditInsuranceLiability: {
            id: "",
          },
        });
      }
    } else {
      return false;
    }
  }

  Editbankinfo() {
    if (this.state.bankid) {
      var div = document.getElementById("BankEditDiv");
      div.setAttribute("style", " cursor: not-allowed; pointer-events: none");

      var config = {
        bankDetailId: this.state.bankid,
        token: this.state.token,
      };

      getEditinfo(
        config,
        (res) => {
          console.log(res);
          if (res.data.status === 0) {
            this.setState({
              bankinforeadonly: false,
              CheckEditInfo: config,
            });
          } else {
            div.removeAttribute("style");
            NotificationManager.warning(
              res.data.message,
              "Warning Message",
              3000
            );
          }
        },
        (err) => {
          console.log(err);
        }
      );
    } else {
      this.setState({
        bankinforeadonly: false,
      });
    }
  }

  onFileChange = async (event) => {
    let errors = this.state.errors;

    event.preventDefault();
    event.persist();
    var file = await event.target.files[0];

    function getFileExtension(filename) {
      return filename.split(".").pop();
    }

    if (event.target.files[0]) {
      var filename = { name: file.name };

      if (
        file.type !== "application/pdf" &&
        file.type !== "image/jpeg" &&
        file.type !== "image/png" &&
        file.type !== "image/jpg" &&
        file.type !== "image/gif" &&
        file.type !== "image/tif" &&
        getFileExtension(file.name) !== "docx" &&
        getFileExtension(file.name) !== "doc" &&
        getFileExtension(file.name) !== "odt" &&
        getFileExtension(file.name) !== "xls" &&
        getFileExtension(file.name) !== "xlsx" &&
        getFileExtension(file.name) !== "ppt" &&
        getFileExtension(file.name) !== "pptx"
      ) {
        errors[event.target.name] =
          "Please Upload only PDF ,Images, Document files ";
        this.setState({ errors: errors });
        return false;
      } else if (file.size > 1000000) {
        errors[event.target.name] =
          "Please Upload A File Less Than 1MB ( Max size for file: 1MB ) ";
        this.setState({ errors: errors });
        return false;
      } else {
        errors[event.target.name] = "";
        this.setState({ errors: errors });
      }

      if (filename.name.length > 15) {
        filename = {
          name: filename.name.substring(0, 15) + "....",
        };
      }
      console.log(file);

      var doctype = event.target.id;
      var id = event.target.id;
      console.log(id);

      if (Number(id) === 1) {
        this.setState({
          operationload: false,
        });
      } else if (Number(id) === 2) {
        this.setState({
          w9load: false,
        });
      } else if (Number(id) === 3) {
        this.setState({
          insuranceload: false,
        });
      } else if (Number(id) === 4) {
        this.setState({
          copyload: false,
        });
      } else if (Number(id) === 5) {
        this.setState({
          carbload: false,
        });
      }

      console.log("enter in upload file");
      let data = new FormData();
      await data.append("file", file);
      await data.append("fileName", file.name);
      await data.append("carrierId", this.state.customerid);
      await data.append("type", file.type);
      await data.append("documentType", doctype);

      await axios
        .post(BaseURL + "carrierDocuments/uploadFileToS3", data, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: localStorage.getItem("Token"),
          },
        })
        .then((response) => {
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem("Token", "");
            this.props.history.push("/");
          }
          console.log(response.data);
          NotificationManager.success(
            "Document Uploaded Successfully",
            "Upload",
            3000
          );
        })
        .catch(function(error) {
          NotificationManager.error(
            "Something Went Wrong Server Is Not Responding ",
            "Error Message",
            3000
          );
          console.log(error);
        });

      if (Number(id) === 1) {
        await this.setState({
          operationload: true,
          file: {
            operation: filename,
            w9: this.state.file.w9,
            insur: this.state.file.insur,
            copy: this.state.file.copy,
            carb: this.state.file.carb,
          },
        });
      } else if (Number(id) === 2) {
        await this.setState({
          w9load: true,
          file: {
            operation: this.state.file.operation,
            w9: filename,
            insur: this.state.file.insur,
            copy: this.state.file.copy,
            carb: this.state.file.carb,
          },
        });
      } else if (Number(id) === 3) {
        await this.setState({
          insuranceload: true,
          file: {
            operation: this.state.file.operation,
            w9: this.state.file.w9,
            insur: filename,
            copy: this.state.file.copy,
            carb: this.state.file.carb,
          },
        });
      } else if (Number(id) === 4) {
        await this.setState({
          copyload: true,
          file: {
            operation: this.state.file.operation,
            w9: this.state.file.w9,
            insur: this.state.file.insur,
            copy: filename,
            carb: this.state.file.carb,
          },
        });
      } else if (Number(id) === 5) {
        await this.setState({
          carbload: true,
          file: {
            operation: this.state.file.operation,
            w9: this.state.file.w9,
            insur: this.state.file.insur,
            copy: this.state.file.copy,
            carb: filename,
          },
        });
      }
    }
  };

  deleteFile = (e) => {
    e.preventDefault();
    e.persist();
    var doctype = e.target.id;
    var id = e.target.id;
    console.log(id);

    if (window.confirm("Are You Sure To Remove Docment ?")) {
      console.log("enter in delete file");
      axios
        .post(BaseURL + "carrierDocuments/deleteFile", {
          carrierId: this.state.customerid,
          documentType: doctype,
          token: this.state.token,
        })
        .then((response) => {
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem("Token", "");
            this.props.history.push("/");
          }
          NotificationManager.success(
            "Document Deleted Successfully",
            "Delete",
            3000
          );
          console.log(response.data);
        })
        .catch(function(error) {
          NotificationManager.error(
            "Something Went Wrong Server Is Not Responding ",
            "Error Message",
            3000
          );
          console.log(error);
        });

      if (Number(id) === 1) {
        this.setState({
          file: {
            operation: "",
            w9: this.state.file.w9,
            insur: this.state.file.insur,
            copy: this.state.file.copy,
            carb: this.state.file.carb,
          },
        });
      } else if (Number(id) === 2) {
        this.setState({
          file: {
            operation: this.state.file.operation,
            w9: "",
            insur: this.state.file.insur,
            copy: this.state.file.copy,
            carb: this.state.file.carb,
          },
        });
      } else if (Number(id) === 3) {
        this.setState({
          file: {
            operation: this.state.file.operation,
            w9: this.state.file.w9,
            insur: "",
            copy: this.state.file.copy,
            carb: this.state.file.carb,
          },
        });
      } else if (Number(id) === 4) {
        this.setState({
          file: {
            operation: this.state.file.operation,
            w9: this.state.file.w9,
            insur: this.state.file.insur,
            copy: "",
            carb: this.state.file.carb,
          },
        });
      } else if (Number(id) === 5) {
        this.setState({
          file: {
            operation: this.state.file.operation,
            w9: this.state.file.w9,
            insur: this.state.file.insur,
            copy: this.state.file.copy,
            carb: "",
          },
        });
      }
    }
  };

  download = async (e) => {
    e.preventDefault();
    e.persist();
    var doctype = await e.target.id;
    var id = await e.target.id;

    if (Number(id) === 1) {
      this.setState({
        operationload: false,
      });
    } else if (Number(id) === 2) {
      this.setState({
        w9load: false,
      });
    } else if (Number(id) === 3) {
      this.setState({
        insuranceload: false,
      });
    } else if (Number(id) === 4) {
      this.setState({
        copyload: false,
      });
    } else if (Number(id) === 5) {
      this.setState({
        carbload: false,
      });
    }

    console.log(doctype);
    console.log("enter in download file");

    await axios
      .post(BaseURL + "carrierDocuments/getDocuments", {
        carrierId: this.state.customerid,
        token: this.state.token,
      })
      .then((response) => {
        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem("Token", "");
          this.props.history.push("/");
        }

        response.data.documents.forEach((element) => {
          if (element.documentType === doctype) {
            console.log(element);
            window.open(element.documentUrl, "_blank");
          }
        });
      })
      .catch(function(error) {
        NotificationManager.error(
          "Something Went Wrong Server Is Not Responding ",
          "Error Message",
          3000
        );
        console.log(error);
      });

    if (Number(id) === 1) {
      this.setState({
        operationload: true,
      });
    } else if (Number(id) === 2) {
      this.setState({
        w9load: true,
      });
    } else if (Number(id) === 3) {
      this.setState({
        insuranceload: true,
      });
    } else if (Number(id) === 4) {
      this.setState({
        copyload: true,
      });
    } else if (Number(id) === 5) {
      this.setState({
        carbload: true,
      });
    }
  };

  ResetInsuranceForm() {
    this.ClearInsuranceInfo();
    this.FinishEditInfo();
  }

  FinishEditInfo() {
    this.setState({ readonly: true });
    var config = this.state.CheckEditInfo;
    if (config.token) {
      finishEditinfo(
        config,
        (res) => {
          console.log(res);
          this.setState({
            CheckEditInfo: {},
            bankinforeadonly: true,
            billingInfoReadOnly: true,
          });
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  EditInsuranceLiability(e) {
    var a = document.getElementById("LiabilityButton");
    a.setAttribute("style", " cursor: not-allowed; pointer-events: none");

    var config = {
      insuranceLiabilityId: this.state.EditInsuranceLiability.id,
      token: this.state.token,
    };

    console.log(config);
    getEditinfo(
      config,
      (res) => {
        if (res.data.status === 0) {
          this.setState({
            insuranceinforeadonly: false,
            CheckEditInfo: config,
          });
          console.log(res);
          a.removeAttribute("style");
        } else {
          a.removeAttribute("style");
          NotificationManager.warning(
            res.data.message,
            "Warning Message",
            3000
          );
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  ChangeFactoryCompany = (selectedOption) => {
    var event = selectedOption ? selectedOption.value : null;
    console.log(selectedOption);
    this.setState({ factoringCompanyId: event });
    this.loadFactoryCompanyCard(selectedOption);
  };

  loadFactoryCompanyCard = (cardInfo) => {
    if (cardInfo && cardInfo.value) {
      var id = cardInfo.value;
      var data;

      this.state.factoringCompanies.forEach((element) => {
        if (element.id === Number(id)) {
          data = {
            address: element.address.address,
            city: element.address.city,
            state: element.address.state.state,
          };
        }
      });

      if (data) {
        this.setState({ factoryCompanyCard: data });
      }
    } else {
      this.setState({ factoryCompanyCard: "" });
    }
  };

  render() {
    const msgNone = {
      display: "none",
    };
    const msgShow = {
      display: "block",
    };
    const nonclick = {
      cursor: " notAllowed",
      pointerEvents: "none",
    };

    const msgShowin = {
      display: "inline-block",
      marginLeft: "10px",
    };

    const {
      role,
      subRole,
      showSelectedRowOnTabsBoolean,
      showSelectedRowOnTabs,
    } = this.state;
    console.log(role, "role user ...");

    return (
      <div className="page-container-bg-solid page-header-fixed page-sidebar-closed-hide-logo page-md">
        <Header title="| Carrier" />
        <div className="page-container">
          <Sidebar />
          <div className="page-content-wrapper">
            <div className="page-content">
              <div className="page-head"></div>

              <div className="row">
                <div className="col-md-12">
                  <div className="tabbable-line boxless  tabbable-reversed">
                    <ul className="nav nav-tabs">
                      <li className="active border-green-jungle">
                        <a
                          href="#tab_0"
                          data-toggle="tab"
                          onClick={this.FinishEditInfo.bind(this)}
                        >
                          Carrier Information
                        </a>
                      </li>
                      <li className="border-green-jungle">
                        <a
                          href="#tab_1"
                          data-toggle="tab"
                          onClick={this.FinishEditInfo.bind(this)}
                        >
                          Contact
                        </a>
                      </li>
                      <li className="border-green-jungle">
                        <a
                          href="#tab_2"
                          data-toggle="tab"
                          onClick={this.FinishEditInfo.bind(this)}
                        >
                          Insurance Information
                        </a>
                      </li>
                      <li className="border-green-jungle">
                        <a
                          href="#tab_3"
                          data-toggle="tab"
                          onClick={this.FinishEditInfo.bind(this)}
                        >
                          Documents
                        </a>
                      </li>
                      <li className="border-green-jungle">
                        <a
                          href="#tab_4"
                          data-toggle="tab"
                          onClick={this.FinishEditInfo.bind(this)}
                        >
                          Bank Details
                        </a>
                      </li>

                      {role !== tenantUser ? (
                        <li className="border-green-jungle">
                          <a
                            href="#tab_15"
                            data-toggle="tab"
                            onClick={this.FinishEditInfo.bind(this)}
                          >
                            Driver
                          </a>
                        </li>
                      ) : null}

                      {role !== tenantUser ? (
                        <li className="border-green-jungle">
                          <a
                            href="#tab_5"
                            data-toggle="tab"
                            onClick={this.FinishEditInfo.bind(this)}
                          >
                            Truck
                          </a>
                        </li>
                      ) : null}
                      {role !== tenantUser ? (
                        <li className="border-green-jungle">
                          <a
                            href="#tab_6"
                            data-toggle="tab"
                            onClick={this.FinishEditInfo.bind(this)}
                          >
                            Trailer
                          </a>
                        </li>
                      ) : null}

                      {role !== tenantUser ? (
                        <li className="border-green-jungle">
                          <a
                            href="#tab_20"
                            data-toggle="tab"
                            onClick={this.FinishEditInfo.bind(this)}
                          >
                            Load
                          </a>
                        </li>
                      ) : null}
                      {role !== tenantUser &&
                      role !== dispatcherUser &&
                      role !== accountingUser ? (
                        <li className="border-green-jungle">
                          <a
                            href="#tab_21"
                            data-toggle="tab"
                            onClick={this.FinishEditInfo.bind(this)}
                          >
                            Dispatch Fee
                          </a>
                        </li>
                      ) : null}
                      {role !== tenantUser &&
                      role !== dispatcherUser &&
                      role !== accountingUser ? (
                        <li className="border-green-jungle">
                          <a
                            href="#tab_22"
                            data-toggle="tab"
                            onClick={this.FinishEditInfo.bind(this)}
                          >
                            Billing
                          </a>
                        </li>
                      ) : null}
                      {role !== tenantUser && role !== dispatcherUser ? (
                        <li className="border-green-jungle">
                          <a
                            href="#tab_23"
                            data-toggle="tab"
                            onClick={this.FinishEditInfo.bind(this)}
                          >
                            Accounting
                          </a>
                        </li>
                      ) : null}
                    </ul>
                    <div className="tab-content">
                      <div className="tab-pane active" id="tab_0">
                        <div className="portlet box yellow-gold">
                          <div className="portlet-title">
                            <div className="caption">
                              <i className="fa fa-user"></i> Carrier Information
                            </div>
                            <div
                              className="actions"
                              style={this.state.readonly ? {} : nonclick}
                              id="EditDiv"
                            >
                              {role !== tenantUser ? (
                                <div className="btn-group">
                                  <button
                                    className="btn sbold white dark"
                                    onClick={this.EditForm.bind(this)}
                                    hidden={this.state.hidden}
                                    disabled={this.state.hidden}
                                  >
                                    <i className="fa fa-pencil"></i> Edit
                                  </button>
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="portlet-body form">
                            <form
                              onSubmit={this.submituserRegistrationForm.bind(
                                this
                              )}
                              className="horizontal-form"
                              autoComplete="off"
                            >
                              <div className="form-body max_width800">
                                <div className="portlet-title">
                                  <h2 className="h3">
                                    Carrier Info
                                    <span
                                      style={{
                                        float: "right",
                                        paddingLeft: " 8px",
                                      }}
                                    >
                                      <div
                                        className="btn-group btn-group-devided"
                                        data-toggle="buttons"
                                        style={
                                          this.state.readonly
                                            ? nonclick
                                            : msgShow
                                        }
                                      >
                                        <label
                                          className="btn btn-transparent dark btn-outline btn-circle btn-sm "
                                          id="activeLabel"
                                          disabled={this.state.readonly}
                                          onClick={() =>
                                            this.setState({ status: 1 })
                                          }
                                        >
                                          <input
                                            type="radio"
                                            name="options"
                                            className="toggle"
                                            id="option1"
                                          />
                                          Active
                                        </label>
                                        <label
                                          className="btn btn-transparent dark btn-outline btn-circle btn-sm"
                                          id="inactiveLabel"
                                          disabled={this.state.readonly}
                                          onClick={() =>
                                            this.setState({ status: 0 })
                                          }
                                        >
                                          <input
                                            type="radio"
                                            name="options"
                                            className="toggle"
                                            id="option2"
                                          />
                                          Inactive
                                        </label>
                                      </div>
                                    </span>
                                  </h2>
                                  <div className="clearfix"></div>
                                  <hr />
                                </div>
                                <div id="abcc" className="form-group row">
                                  <label className="col-md-3 control-label">
                                    Carrier Name (DBA):
                                    <span className="font-red-thunderbird">
                                      *
                                    </span>
                                  </label>
                                  <div className="col-md-9">
                                    <input
                                      type="text"
                                      ref="customerName"
                                      onChange={this.handleChange}
                                      name="customerName"
                                      className="form-control"
                                      placeholder=""
                                      readOnly={this.state.readonly}
                                      defaultValue={
                                        this.state.carrierformdata.carrierName
                                      }
                                    />
                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.customerName}
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">
                                    Legal Name
                                  </label>
                                  <div className="col-md-9">
                                    <input
                                      type="text"
                                      ref="legalname"
                                      onChange={this.handleChange}
                                      name="legalname"
                                      className="form-control"
                                      placeholder=""
                                      readOnly={this.state.readonly}
                                      defaultValue={
                                        this.state.carrierformdata.legalName
                                      }
                                    />
                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.legalname}
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">
                                    M.C.#{" "}
                                    <span className="font-red-thunderbird">
                                      *
                                    </span>
                                  </label>
                                  <div className="col-md-9">
                                    <input
                                      type="text"
                                      ref="mc"
                                      name="mcNo"
                                      onChange={this.handleChange}
                                      className="form-control"
                                      placeholder=""
                                      readOnly={this.state.readonly}
                                      defaultValue={
                                        this.state.carrierformdata.mcNo
                                      }
                                    />
                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.mcNo}
                                    </div>
                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.mcInternalload}
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">
                                    DOT #
                                  </label>
                                  <div className="col-md-9">
                                    <input
                                      type="text"
                                      ref="dot_number"
                                      onChange={this.handleChange}
                                      name="dotNo"
                                      className="form-control"
                                      placeholder=""
                                      readOnly={this.state.readonly}
                                      defaultValue={
                                        this.state.carrierformdata.dotNo
                                      }
                                    />
                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.dotNo}
                                    </div>
                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.dotInternalload}
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">
                                    SCAC Code
                                  </label>
                                  <div className="col-md-9">
                                    <input
                                      type="text"
                                      ref="scac"
                                      name="scac"
                                      onChange={this.handleChange}
                                      className="form-control"
                                      placeholder=""
                                      readOnly={this.state.readonly}
                                      defaultValue={
                                        this.state.carrierformdata.scacCode
                                      }
                                    />
                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.scac}
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">
                                    CARB#
                                  </label>
                                  <div className="col-md-9">
                                    <input
                                      type="text"
                                      ref="carb"
                                      name="carb"
                                      onChange={this.handleChange}
                                      className="form-control"
                                      placeholder=""
                                      readOnly={this.state.readonly}
                                      defaultValue={
                                        this.state.carrierformdata.carbNo
                                      }
                                    />
                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.carb}
                                    </div>
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="col-md-3 control-label">
                                    Business Type:
                                    <span className="font-red-thunderbird">
                                      *
                                    </span>
                                  </label>
                                  <div
                                    className="col-md-9"
                                    disabled={this.state.readonly}
                                  >
                                    <select
                                      ref="businesstype"
                                      name="business"
                                      className="form-control"
                                      disabled={this.state.readonly}
                                      readOnly={this.state.readonly}
                                    >
                                      {this.state.businesstype.map(function(
                                        data
                                      ) {
                                        if (
                                          this.state.carrierformdata
                                            .businessTypeId === data.id
                                        )
                                          return (
                                            <option value={data.id} selected>
                                              {data.name}
                                            </option>
                                          );
                                        return (
                                          <option value={data.id}>
                                            {data.name}
                                          </option>
                                        );
                                      },
                                      this)}
                                    </select>
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="col-md-3 control-label">
                                    FID / EIN #
                                  </label>
                                  <div className="col-md-9">
                                    <input
                                      type="text"
                                      ref="fid"
                                      name="fid"
                                      id="fid"
                                      onChange={this.AutoFormatEIN}
                                      className="form-control"
                                      placeholder="xx-xxxxxxx"
                                      readOnly={this.state.readonly}
                                      defaultValue={
                                        this.state.carrierformdata.fidEinNo
                                      }
                                    />
                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.fid}
                                    </div>
                                  </div>
                                </div>

                                <div className="portlet-title">
                                  <h2 className="h3"> Address Info </h2>
                                  <hr />
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">
                                    Address
                                    <span className="font-red-thunderbird">
                                      *
                                    </span>
                                  </label>
                                  <div className="col-md-9">
                                    <input
                                      type="text"
                                      ref="address"
                                      onChange={this.handleChange}
                                      name="address"
                                      className="form-control"
                                      placeholder=""
                                      readOnly={this.state.readonly}
                                      defaultValue={
                                        this.state.carrierformdata.address
                                      }
                                    />
                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.address}
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">
                                    Address Line 2
                                  </label>
                                  <div className="col-md-9">
                                    <input
                                      type="text"
                                      ref="address2"
                                      onChange={this.handleChange}
                                      name="addressLineTwo"
                                      className="form-control"
                                      placeholder=""
                                      readOnly={this.state.readonly}
                                      defaultValue={
                                        this.state.carrierformdata
                                          .addressLineTwo
                                      }
                                    />
                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.addressLineTwo}
                                    </div>
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="col-md-3 control-label">
                                    Country
                                    <span className="font-red-thunderbird">
                                      *
                                    </span>
                                  </label>
                                  <div className="col-md-9">
                                    <select
                                      ref="country_id"
                                      name="country"
                                      className="form-control"
                                      title="Country"
                                      onChange={this.states.bind(this)}
                                      disabled={this.state.readonly}
                                      readOnly={this.state.readonly}
                                    >
                                      {this.state.countries.map(function(data) {
                                        if (
                                          this.state.carrierformdata
                                            .addressCountryId === data.id
                                        )
                                          return (
                                            <option value={data.id} selected>
                                              {data.countryName}
                                            </option>
                                          );
                                        return (
                                          <option value={data.id}>
                                            {data.countryName}
                                          </option>
                                        );
                                      }, this)}
                                    </select>
                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.country}
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">
                                    State
                                    <span className="font-red-thunderbird">
                                      *
                                    </span>
                                  </label>
                                  <div className="col-md-9">
                                    <select
                                      ref="province_id"
                                      name="state"
                                      className="form-control"
                                      defaultValue="1"
                                      disabled={this.state.readonly}
                                      readOnly={this.state.readonly}
                                    >
                                      {this.state.states.map(function(data) {
                                        if (
                                          this.state.carrierformdata
                                            .addressStateId === data.id
                                        )
                                          return (
                                            <option value={data.id} selected>
                                              {data.state}
                                            </option>
                                          );
                                        return (
                                          <option value={data.id}>
                                            {data.state}
                                          </option>
                                        );
                                      }, this)}
                                    </select>
                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.state}
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">
                                    City
                                    <span className="font-red-thunderbird">
                                      *
                                    </span>
                                  </label>
                                  <div className="col-md-9">
                                    <input
                                      type="text"
                                      ref="city"
                                      onChange={this.handleChange}
                                      name="city"
                                      className="form-control"
                                      placeholder=""
                                      readOnly={this.state.readonly}
                                      defaultValue={
                                        this.state.carrierformdata.city
                                      }
                                    />
                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.city}
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">
                                    Zip
                                    <span className="font-red-thunderbird">
                                      *
                                    </span>
                                  </label>
                                  <div className="col-md-9">
                                    <input
                                      type="text"
                                      ref="zip"
                                      onChange={this.handleChange}
                                      name="zip"
                                      className="form-control"
                                      placeholder=""
                                      readOnly={this.state.readonly}
                                      defaultValue={
                                        this.state.carrierformdata.zip
                                      }
                                    />
                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.zip}
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">
                                    Email
                                  </label>
                                  <div className="col-md-9">
                                    <input
                                      type="email"
                                      ref="email"
                                      onChange={this.handleChange}
                                      name="email"
                                      className="form-control"
                                      placeholder=""
                                      readOnly={this.state.readonly}
                                      defaultValue={
                                        this.state.carrierformdata.email
                                      }
                                    />
                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.email}
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">
                                    Telephone
                                  </label>
                                  <div className="col-md-9 form-inline">
                                    <input
                                      type="text"
                                      ref="telephone"
                                      id="phone"
                                      onChange={this.AutoFormatContact}
                                      name="telephone"
                                      className="form-control"
                                      placeholder="xxx-xxx-xxxx"
                                      readOnly={this.state.readonly}
                                      defaultValue={
                                        this.state.carrierformdata.telephone
                                      }
                                    />
                                    <span className="help-inline"> EXT </span>
                                    <input
                                      type="text"
                                      name="ext"
                                      onChange={this.handleChange}
                                      ref="ext"
                                      className="form-control"
                                      placeholder=""
                                      readOnly={this.state.readonly}
                                      defaultValue={
                                        this.state.carrierformdata.ext
                                      }
                                    />
                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.telephone}
                                    </div>
                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.ext}
                                    </div>
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="col-md-3 control-label">
                                    Fax
                                  </label>
                                  <div className="col-md-9">
                                    <input
                                      type="text"
                                      ref="fax"
                                      name="fax"
                                      id="fax"
                                      onChange={this.AutoFormatContact}
                                      className="form-control"
                                      placeholder="xxx-xxx-xxxx"
                                      readOnly={this.state.readonly}
                                      defaultValue={
                                        this.state.carrierformdata.fax
                                      }
                                    />
                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.fax}
                                    </div>
                                  </div>
                                </div>
                                <div className="portlet-title">
                                  <h2
                                    className="h3 checkbox-inline"
                                    style={{ padding: "0", margin: "0" }}
                                  >
                                    Billing Address
                                  </h2>
                                  <label className="checkbox-inline">
                                    <div className="checkbox">
                                      <span>
                                        <input
                                          type="checkbox"
                                          checked={this.state.errorMsg}
                                          name="optionsRadios2"
                                          value="option1"
                                          onClick={this.billaddress.bind(this)}
                                          disabled={this.state.readonly}
                                          readOnly={this.state.readonly}
                                        />
                                      </span>
                                      Same as Mailing Address
                                    </div>
                                  </label>
                                  <hr />
                                </div>

                                <span>
                                  <div className="form-group row">
                                    <label className="col-md-3 control-label">
                                      Address
                                      <span className="font-red-thunderbird">
                                        *
                                      </span>
                                    </label>
                                    <div className="col-md-9">
                                      <input
                                        type="text"
                                        ref="billing"
                                        onChange={this.handleChange}
                                        name="billingAddress"
                                        className="form-control"
                                        placeholder=""
                                        readOnly={this.state.readonly}
                                        defaultValue={
                                          this.state.carrierformdata.baddress
                                        }
                                      />
                                      <div
                                        style={{ color: "red" }}
                                        className="errorMsg"
                                      >
                                        {this.state.errors.billingAddress}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label className="col-md-3 control-label">
                                      Address Line 2
                                    </label>
                                    <div className="col-md-9">
                                      <input
                                        type="text"
                                        onChange={this.handleChange}
                                        name="billingAddressLineTwo"
                                        ref="billing2"
                                        className="form-control"
                                        placeholder=""
                                        readOnly={this.state.readonly}
                                        defaultValue={
                                          this.state.carrierformdata
                                            .baddressLineTwo
                                        }
                                      />
                                      <div
                                        style={{ color: "red" }}
                                        className="errorMsg"
                                      >
                                        {
                                          this.state.errors
                                            .billingAddressLineTwo
                                        }
                                      </div>
                                    </div>
                                  </div>

                                  <div className="form-group row">
                                    <label className="col-md-3 control-label">
                                      Country
                                      <span className="font-red-thunderbird">
                                        *
                                      </span>
                                    </label>
                                    <div className="col-md-9">
                                      <select
                                        name="billingAddressCountry"
                                        ref="billing_country_id"
                                        className="form-control"
                                        title="Country"
                                        onChange={this.bstates.bind(this)}
                                        disabled={this.state.readonly}
                                        readOnly={this.state.readonly}
                                      >
                                        {this.state.countries.map(function(
                                          data
                                        ) {
                                          if (
                                            this.state.carrierformdata
                                              .baddressCountryId === data.id
                                          )
                                            return (
                                              <option value={data.id} selected>
                                                {data.countryName}
                                              </option>
                                            );
                                          return (
                                            <option value={data.id}>
                                              {data.countryName}
                                            </option>
                                          );
                                        },
                                        this)}
                                      </select>
                                      <div
                                        style={{ color: "red" }}
                                        className="errorMsg"
                                      >
                                        {
                                          this.state.errors
                                            .billingAddressCountry
                                        }
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label className="col-md-3 control-label">
                                      State
                                      <span className="font-red-thunderbird">
                                        *
                                      </span>
                                    </label>
                                    <div className="col-md-9">
                                      <select
                                        name="billingAddressState"
                                        ref="billing_province_id"
                                        className="form-control"
                                        defaultValue="1"
                                        readOnly={this.state.readonly}
                                        disabled={this.state.readonly}
                                      >
                                        {this.state.bstates.map(function(data) {
                                          if (
                                            this.state.carrierformdata
                                              .baddressStateId === data.id
                                          )
                                            return (
                                              <option value={data.id} selected>
                                                {data.state}
                                              </option>
                                            );
                                          return (
                                            <option value={data.id}>
                                              {data.state}
                                            </option>
                                          );
                                        }, this)}
                                      </select>
                                      <div
                                        style={{ color: "red" }}
                                        className="errorMsg"
                                      >
                                        {this.state.errors.billingAddressState}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label className="col-md-3 control-label">
                                      City
                                      <span className="font-red-thunderbird">
                                        *
                                      </span>
                                    </label>
                                    <div className="col-md-9">
                                      <input
                                        type="text"
                                        ref="b_city"
                                        onChange={this.handleChange}
                                        name="billingAddressCity"
                                        className="form-control"
                                        placeholder=""
                                        readOnly={this.state.readonly}
                                        defaultValue={
                                          this.state.carrierformdata.bcity
                                        }
                                      />
                                      <div
                                        style={{ color: "red" }}
                                        className="errorMsg"
                                      >
                                        {this.state.errors.billingAddressCity}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label className="col-md-3 control-label">
                                      Zip
                                      <span className="font-red-thunderbird">
                                        *
                                      </span>
                                    </label>
                                    <div className="col-md-9">
                                      <input
                                        type="text"
                                        ref="b_zip"
                                        onChange={this.handleChange}
                                        name="billingAddressZip"
                                        className="form-control"
                                        placeholder=""
                                        readOnly={this.state.readonly}
                                        defaultValue={
                                          this.state.carrierformdata.bzip
                                        }
                                      />
                                      <div
                                        style={{ color: "red" }}
                                        className="errorMsg"
                                      >
                                        {this.state.errors.billingAddressZip}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label className="col-md-3 control-label">
                                      Email
                                    </label>
                                    <div className="col-md-9">
                                      <input
                                        type="email"
                                        ref="b_email"
                                        onChange={this.handleChange}
                                        name="billingAddressEmail"
                                        className="form-control"
                                        placeholder=""
                                        readOnly={this.state.readonly}
                                        defaultValue={
                                          this.state.carrierformdata.bemail
                                        }
                                      />
                                      <div
                                        style={{ color: "red" }}
                                        className="errorMsg"
                                      >
                                        {this.state.errors.billingAddressEmail}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label className="col-md-3 control-label">
                                      Telephone
                                    </label>
                                    <div className="col-md-9 form-inline">
                                      <input
                                        type="text"
                                        id="phone2"
                                        onChange={this.AutoFormatContact}
                                        name="billingAddressTelephone"
                                        ref="b_telephone"
                                        className="form-control"
                                        placeholder="xxx-xxx-xxxx"
                                        readOnly={this.state.readonly}
                                        defaultValue={
                                          this.state.carrierformdata.btelephone
                                        }
                                      />
                                      <span className="help-inline"> EXT </span>
                                      <input
                                        name="billingAddressExt"
                                        onChange={this.handleChange}
                                        type="text"
                                        ref="b_ext"
                                        className="form-control"
                                        placeholder=""
                                        readOnly={this.state.readonly}
                                        defaultValue={
                                          this.state.carrierformdata.bext
                                        }
                                      />
                                      <div
                                        style={{ color: "red" }}
                                        className="errorMsg"
                                      >
                                        {
                                          this.state.errors
                                            .billingAddressTelephone
                                        }
                                      </div>
                                      <div
                                        style={{ color: "red" }}
                                        className="errorMsg"
                                      >
                                        {this.state.errors.billingAddressExt}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label className="col-md-3 control-label">
                                      Fax
                                    </label>
                                    <div className="col-md-9">
                                      <input
                                        type="text"
                                        ref="b_fax"
                                        id="billfax"
                                        onChange={this.AutoFormatContact}
                                        name="billingAddressFax"
                                        className="form-control"
                                        placeholder="xxx-xxx-xxxx"
                                        readOnly={this.state.readonly}
                                        defaultValue={
                                          this.state.carrierformdata.bfax
                                        }
                                      />
                                      <div
                                        style={{ color: "red" }}
                                        className="errorMsg"
                                      >
                                        {this.state.errors.billingAddressFax}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="form-group row">
                                    <label className="col-md-3 control-label">
                                      Select Factoring Company
                                    </label>
                                    <div className="col-md-9">
                                      <span style={{ marginTop: "2px" }}>
                                        <div
                                          className="btn-group btn-group-devided"
                                          data-toggle="buttons"
                                          style={
                                            this.state.readonly
                                              ? nonclick
                                              : msgShow
                                          }
                                        >
                                          <label
                                            disabled={this.state.readonly}
                                            className={
                                              this.state.isFactorySelected ===
                                              "YES"
                                                ? "btn btn-transparent dark btn-outline btn-circle btn-sm active"
                                                : "btn btn-transparent dark btn-outline btn-circle btn-sm "
                                            }
                                            id="banktype"
                                            onClick={() =>
                                              this.setState({
                                                isFactorySelected: "YES",
                                              })
                                            }
                                          >
                                            <input
                                              type="radio"
                                              name="options"
                                              className="toggle"
                                              id="1"
                                            />
                                            YES
                                          </label>
                                          <label
                                            disabled={this.state.readonly}
                                            className={
                                              this.state.isFactorySelected ===
                                              "NO"
                                                ? "btn btn-transparent dark btn-outline btn-circle btn-sm active"
                                                : "btn btn-transparent dark btn-outline btn-circle btn-sm "
                                            }
                                            id="banktype2"
                                            onClick={() =>
                                              this.setState({
                                                isFactorySelected: "NO",
                                                factoringCompanyId: null,
                                                factoryCompanyCard: {},
                                              })
                                            }
                                          >
                                            <input
                                              type="radio"
                                              name="options"
                                              className="toggle"
                                              id="2"
                                            />
                                            NO
                                          </label>
                                        </div>
                                      </span>
                                    </div>
                                  </div>

                                  {this.state.isFactorySelected === "YES" ? (
                                    <div className="form-group row">
                                      <label className="col-md-3 control-label">
                                        Factoring Company
                                        <span className="font-red-thunderbird"></span>
                                      </label>
                                      <div className="col-md-9">
                                        <div className="form-group row">
                                          <div className="col-md-12">
                                            <Select
                                              disabled={this.state.readonly}
                                              value={
                                                this.state.factoringCompanyId
                                              }
                                              options={
                                                this.state
                                                  .factorySelectionOptions
                                              }
                                              onChange={
                                                this.ChangeFactoryCompany
                                              }
                                              placeholder="Select Factory Company"
                                            />
                                            {/* <div style= {{color: 'red'}} className="errorMsg" >{this.state.errors.carrier}</div> */}
                                          </div>
                                        </div>
                                        <div
                                          className="row"
                                          style={
                                            this.state.factoryCompanyCard
                                              .address
                                              ? msgShow
                                              : msgNone
                                          }
                                        >
                                          <div className="col-md-12">
                                            <div className="card_info">
                                              <div className="row">
                                                <div className="col-md-12">
                                                  <p>
                                                    <i
                                                      className="fa fa-map-marker"
                                                      aria-hidden="true"
                                                    ></i>
                                                    &nbsp;
                                                    {
                                                      this.state
                                                        .factoryCompanyCard
                                                        .address
                                                    }
                                                  </p>
                                                </div>
                                              </div>
                                              <div className="row">
                                                <div className="col-md-6">
                                                  <p>
                                                    <i
                                                      className="fa fa-building"
                                                      aria-hidden="true"
                                                    ></i>
                                                    &nbsp;
                                                    {
                                                      this.state
                                                        .factoryCompanyCard.city
                                                    }
                                                  </p>
                                                </div>
                                                <div className="col-md-6">
                                                  <p>
                                                    <i
                                                      className="fa fa-globe"
                                                      aria-hidden="true"
                                                    ></i>
                                                    &nbsp;
                                                    {
                                                      this.state
                                                        .factoryCompanyCard
                                                        .state
                                                    }
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                </span>
                              </div>
                              <div className="clearfix"></div>
                              <div className="form-actions right">
                                <button
                                  onClick={this.cancel.bind(this)}
                                  type="button"
                                  className="btn default"
                                >
                                  Cancel
                                </button>
                                &nbsp;
                                {role !== tenantUser ? (
                                  <button
                                    disabled={this.state.readonly}
                                    // disabled={this.state.readonly || this.state.saveBtnDisable == false}
                                    type="submit"
                                    className="btn green-meadow"
                                  >
                                    {this.state.whileSave === false ? (
                                      <i className="fa fa-save"></i>
                                    ) : (
                                      <i className="fa fa-circle-o-notch fa-spin"></i>
                                    )}
                                    Save
                                  </button>
                                ) : null}
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="tab_1">
                        {showSelectedRowOnTabsBoolean ? (
                          <>
                            <div className="showRowForEdit">
                              <span className="showRowForEdit-items">
                                <b>Carrier Name: </b>
                                {showSelectedRowOnTabs.carrierName}
                              </span>
                              <span className="showRowForEdit-items">
                                <b>MC# </b>
                                {showSelectedRowOnTabs.mcNo}
                              </span>
                              <span className="showRowForEdit-items">
                                <b>Dot# </b>
                                {showSelectedRowOnTabs.dotNo
                                  ? showSelectedRowOnTabs.dotNo
                                  : ""}
                              </span>
                              <span className="showRowForEdit-items">
                                <b>Status: </b>
                                {showSelectedRowOnTabs.status === 0 ? (
                                  <img
                                    style={{ width: "15px", height: "15px" }}
                                    src={Cross}
                                    alt="Inactive"
                                  />
                                ) : (
                                  <img
                                    style={{ width: "15px", height: "15px" }}
                                    src={Tick}
                                    alt="Active"
                                  />
                                )}
                              </span>
                              <span className="showRowForEdit-items">
                                {/* {showRowPickupDate} */}
                              </span>
                              <span className="showRowForEdit-items">
                                {/* {showRowPickupCityState} */}
                              </span>
                              <span className="showRowForEdit-items">
                                {/* {showRowDeliveryDate} */}
                              </span>
                              <span className="showRowForEdit-items">
                                {/* {showRowDeliveryCityState} */}
                              </span>
                              <span className="showRowForEdit-items">
                                {
                                  // showSelectedRowOnTabs.customerLoad.loadStatus
                                }
                              </span>
                            </div>
                          </>
                        ) : null}
                        <div className="portlet box yellow-gold">
                          <div className="portlet-title">
                            <div className="caption">
                              <i className="fa fa-envelope"></i> Contact Info
                            </div>
                          </div>
                          <div className="portlet-body form ">
                            <form action="#" className="horizontal-form">
                              <div className="col-md-12">
                                <div className="mt10">
                                  <table className="table table-striped table-bordered table-hover table-checkable order-column dataTable no-footer">
                                    <thead>
                                      <tr>
                                        <th className="text-center"> Name </th>
                                        <th className="text-center w200">
                                          Title
                                        </th>
                                        <th className="text-center"> Email </th>
                                        <th className="text-center">
                                          Telephone
                                        </th>
                                        <th className="text-center">
                                          Cell Phone
                                        </th>
                                        <th className="text-center"> Fax </th>
                                        {role !== tenantUser ? (
                                          <th className="text-center">
                                            Action
                                          </th>
                                        ) : null}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.Contacts.map(function(data) {
                                        return (
                                          <tr key={data.id}>
                                            <td className="text-center">
                                              {data.name}
                                            </td>
                                            <td className="text-center w200">
                                              {data.title}
                                            </td>
                                            <td className="text-center">
                                              {data.email}
                                            </td>
                                            <td className="text-center">
                                              {data.telephone}
                                            </td>
                                            <td className="text-center">
                                              {data.cellphone}
                                            </td>
                                            <td className="text-center">
                                              {data.fax}
                                            </td>
                                            {role !== tenantUser ? (
                                              <td className="text-center">
                                                <a
                                                  data-toggle="modal"
                                                  id={data.id}
                                                >
                                                  <i
                                                    className="fa fa-pencil"
                                                    onClick={() =>
                                                      this.EditContact(data)
                                                    }
                                                  ></i>
                                                </a>
                                                &nbsp;
                                                <a>
                                                  <i
                                                    className="fa fa-trash-o"
                                                    onClick={() =>
                                                      this.DelContact(data)
                                                    }
                                                  ></i>
                                                </a>
                                              </td>
                                            ) : null}
                                          </tr>
                                        );
                                      }, this)}
                                    </tbody>
                                  </table>
                                </div>
                                <br />
                                {role !== tenantUser ? (
                                  <div className="mb-2">
                                    <a
                                      data-toggle="modal"
                                      href="#contact"
                                      className="btn  green-meadow"
                                      onClick={this.ClearContact.bind(this)}
                                    >
                                      Add Contact
                                    </a>
                                    <span
                                      style={
                                        this.state.isLoading === false
                                          ? msgNone
                                          : msgShowin
                                      }
                                    >
                                      <i className="fa fa-circle-o-notch fa-spin"></i>
                                    </span>
                                  </div>
                                ) : null}
                              </div>
                              &nbsp;
                              <div className="clearfix"></div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="tab_2">
                        {showSelectedRowOnTabsBoolean ? (
                          <>
                            <div className="showRowForEdit">
                              <span className="showRowForEdit-items">
                                <b>Carrier Name: </b>
                                {showSelectedRowOnTabs.carrierName}
                              </span>
                              <span className="showRowForEdit-items">
                                <b>MC# </b>
                                {showSelectedRowOnTabs.mcNo}
                              </span>
                              <span className="showRowForEdit-items">
                                <b>Dot# </b>
                                {showSelectedRowOnTabs.dotNo
                                  ? showSelectedRowOnTabs.dotNo
                                  : ""}
                              </span>
                              <span className="showRowForEdit-items">
                                <b>Status: </b>
                                {showSelectedRowOnTabs.status === 0 ? (
                                  <img
                                    style={{ width: "15px", height: "15px" }}
                                    src={Cross}
                                    alt="Inactive"
                                  />
                                ) : (
                                  <img
                                    style={{ width: "15px", height: "15px" }}
                                    src={Tick}
                                    alt="Active"
                                  />
                                )}
                              </span>
                              <span className="showRowForEdit-items">
                                {/* {showRowPickupDate} */}
                              </span>
                              <span className="showRowForEdit-items">
                                {/* {showRowPickupCityState} */}
                              </span>
                              <span className="showRowForEdit-items">
                                {/* {showRowDeliveryDate} */}
                              </span>
                              <span className="showRowForEdit-items">
                                {/* {showRowDeliveryCityState} */}
                              </span>
                              <span className="showRowForEdit-items">
                                {
                                  // showSelectedRowOnTabs.customerLoad.loadStatus
                                }
                              </span>
                            </div>
                          </>
                        ) : null}
                        <div className="portlet box yellow-gold">
                          <div className="portlet-title">
                            <div className="caption">
                              <i className="fa fa-gift"></i> Insurance
                              Information
                            </div>
                          </div>
                          <div className="portlet-body form">
                            <form
                              action="#"
                              className="horizontal-form"
                              _lpchecked="1"
                            >
                              <div className="col-md-12">
                                <div className="table-responsive mt10">
                                  <table className="table table-striped table-bordered table-hover table-checkable order-column dataTable no-footer">
                                    <thead>
                                      <tr>
                                        <th className="text-center w200">
                                          Type
                                        </th>
                                        <th className="text-center">
                                          Company Name
                                        </th>
                                        <th className="text-center"> Agent </th>
                                        <th className="text-center">Insurer</th>
                                        <th className="text-center">Policy#</th>
                                        <th className="text-center">
                                          Effective Date:
                                        </th>
                                        <th className="text-center">
                                          Expiration Date:
                                        </th>
                                        {role !== tenantUser ? (
                                          <th className="text-center">
                                            Action
                                          </th>
                                        ) : null}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.InsuranceInfo.map(function(
                                        data
                                      ) {
                                        return (
                                          <tr key={data.id}>
                                            <td className="text-center w200">
                                              {data.liabilityType.liabilityType}
                                            </td>
                                            <td className="text-center">
                                              {data.insuranceAgent.companyName}
                                            </td>

                                            <td className="text-center">
                                              {data.insuranceAgentContact
                                                ? data.insuranceAgentContact
                                                    .name
                                                : ""}
                                            </td>

                                            <td className="text-center">
                                              {data.insurer}
                                            </td>
                                            <td className="text-center">
                                              {data.policyNo}
                                            </td>
                                            <td className="text-center">
                                              {new Date(
                                                data.effectiveDate
                                              ).toLocaleDateString()}
                                            </td>
                                            <td className="text-center">
                                              {new Date(
                                                data.expirationDate
                                              ).toLocaleDateString()}
                                            </td>
                                            {role !== tenantUser ? (
                                              <td className="text-center">
                                                <a
                                                  data-toggle="modal"
                                                  href="#general_liability"
                                                >
                                                  <i
                                                    className="fa fa-eye"
                                                    aria-hidden="true"
                                                    onClick={() =>
                                                      this.EditInsuranceInfo(
                                                        data
                                                      )
                                                    }
                                                  ></i>
                                                </a>
                                              </td>
                                            ) : null}
                                          </tr>
                                        );
                                      },
                                      this)}
                                    </tbody>
                                  </table>
                                </div>

                                {role !== tenantUser ? (
                                  <div className="form-group row">
                                    <div className="col-md-9">
                                      <div className="form-group row">
                                        <div className="col-md-9">
                                          <a
                                            data-toggle="modal"
                                            href="#general_liability"
                                            className="btn  green-meadow"
                                            onClick={this.ClearInsuranceInfo.bind(
                                              this
                                            )}
                                          >
                                            <i className="fa fa-plus"></i> Add
                                          </a>
                                        </div>
                                        <div className="col-md-3"></div>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                              <div className="clearfix"></div>
                            </form>
                          </div>
                        </div>
                      </div>

                      <div className="tab-pane" id="tab_3">
                        {showSelectedRowOnTabsBoolean ? (
                          <>
                            <div className="showRowForEdit">
                              <span className="showRowForEdit-items">
                                <b>Carrier Name: </b>
                                {showSelectedRowOnTabs.carrierName}
                              </span>
                              <span className="showRowForEdit-items">
                                <b>MC# </b>
                                {showSelectedRowOnTabs.mcNo}
                              </span>
                              <span className="showRowForEdit-items">
                                <b>Dot# </b>
                                {showSelectedRowOnTabs.dotNo
                                  ? showSelectedRowOnTabs.dotNo
                                  : ""}
                              </span>
                              <span className="showRowForEdit-items">
                                <b>Status: </b>
                                {showSelectedRowOnTabs.status === 0 ? (
                                  <img
                                    style={{ width: "15px", height: "15px" }}
                                    src={Cross}
                                    alt="Inactive"
                                  />
                                ) : (
                                  <img
                                    style={{ width: "15px", height: "15px" }}
                                    src={Tick}
                                    alt="Active"
                                  />
                                )}
                              </span>
                              <span className="showRowForEdit-items">
                                {/* {showRowPickupDate} */}
                              </span>
                              <span className="showRowForEdit-items">
                                {/* {showRowPickupCityState} */}
                              </span>
                              <span className="showRowForEdit-items">
                                {/* {showRowDeliveryDate} */}
                              </span>
                              <span className="showRowForEdit-items">
                                {/* {showRowDeliveryCityState} */}
                              </span>
                              <span className="showRowForEdit-items">
                                {
                                  // showSelectedRowOnTabs.customerLoad.loadStatus
                                }
                              </span>
                            </div>
                          </>
                        ) : null}
                        <div className="portlet box yellow-gold">
                          <div className="portlet-title">
                            <div className="caption">
                              <i className="fa fa-file"></i> Documents
                            </div>
                          </div>
                          <div className="portlet-body form">
                            <form
                              action="#"
                              className="horizontal-form"
                              _lpchecked="1"
                            >
                              <div className="form-body max_width800">
                                <div className="form-group row">
                                  <label className="col-md-4 control-label">
                                    Operating Authority (copy)
                                  </label>
                                  <div className="col-md-8 fileinput ">
                                    <div className="input-group input-large doc_custom">
                                      <div
                                        className="form-control uneditable-input input-fixed input-medium"
                                        data-trigger="fileinput"
                                      >
                                        <i className="fa fa-file fileinput-exists"></i>
                                        &nbsp;
                                        <span className="fileinput-filename">
                                          {this.state.file.operation.name}
                                        </span>
                                      </div>
                                      {console.log("role ==========>", role)}
                                      {console.log(
                                        "SubRole ============>",
                                        subRole
                                      )}
                                      {role !== tenantUser ||
                                      (role == tenantUser &&
                                        subRole == tenantSubRole) ? (
                                        <span className="input-group-addon btn default btn-file">
                                          <span className="fileinput-new">
                                            Upload file
                                          </span>
                                          <input
                                            type="file"
                                            id="1"
                                            name="operauth"
                                            onChange={this.onFileChange}
                                          />
                                        </span>
                                      ) : null}
                                      {this.state.operationload ? (
                                        <button
                                          hidden={
                                            this.state.file.operation.name
                                              ? false
                                              : true
                                          }
                                          className="input-group-addon btn green fileinput-exists"
                                          id="1"
                                          onClick={this.download}
                                        >
                                          <i
                                            id="1"
                                            style={{ color: "#fff" }}
                                            class="fa fa-eye"
                                          ></i>
                                        </button>
                                      ) : (
                                        <button
                                          className="input-group-addon btn green fileinput-exists"
                                          id="1"
                                          onClick={(e) => e.preventDefault()}
                                        >
                                          <i
                                            id="1"
                                            style={{ color: "#fff" }}
                                            class="fa fa-circle-o-notch fa-spin"
                                          ></i>
                                        </button>
                                      )}
                                      {role !== tenantUser ||
                                          (role == tenantUser &&
                                            subRole == tenantSubRole) ? (
                                        <button
                                          hidden={
                                            this.state.file.operation.name
                                              ? false
                                              : true
                                          }
                                          className="input-group-addon btn red fileinput-exists"
                                          id="1"
                                          onClick={this.deleteFile}
                                        >
                                          <i
                                            id="1"
                                            style={{ color: "#fff" }}
                                            class="fa fa-trash"
                                          ></i>
                                        </button>
                                      ) : null}
                                    </div>
                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.operauth}
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-4 control-label">
                                    W9 Form
                                  </label>

                                  <div className="col-md-8 fileinput ">
                                    <div className="input-group input-large doc_custom">
                                      <div
                                        className="form-control uneditable-input input-fixed input-medium"
                                        data-trigger="fileinput"
                                      >
                                        <i className="fa fa-file fileinput-exists"></i>
                                        &nbsp;
                                        <span className="fileinput-filename">
                                          {this.state.file.w9.name}
                                        </span>
                                      </div>
                                      {role !== tenantUser ||
                                      (role == tenantUser &&
                                        subRole == tenantSubRole) ? (
                                        <span className="input-group-addon btn default btn-file">
                                          <span className="fileinput-new">
                                            Upload file
                                          </span>
                                          <input
                                            type="file"
                                            id="2"
                                            name="w9"
                                            onChange={this.onFileChange}
                                          />
                                        </span>
                                      ) : null}

                                      {this.state.w9load ? (
                                        <button
                                          hidden={
                                            this.state.file.w9.name
                                              ? false
                                              : true
                                          }
                                          className="input-group-addon btn green fileinput-exists"
                                          id="2"
                                          onClick={this.download}
                                        >
                                          <i
                                            id="2"
                                            style={{ color: "#fff" }}
                                            class="fa fa-eye"
                                          ></i>
                                        </button>
                                      ) : (
                                        <button
                                          className="input-group-addon btn green fileinput-exists"
                                          id="1"
                                          onClick={(e) => e.preventDefault()}
                                        >
                                          <i
                                            id="1"
                                            style={{ color: "#fff" }}
                                            class="fa fa-circle-o-notch fa-spin"
                                          ></i>
                                        </button>
                                      )}

                                      {role !== tenantUser ||
                                          (role == tenantUser &&
                                            subRole == tenantSubRole) ? (
                                        <button
                                          hidden={
                                            this.state.file.w9.name
                                              ? false
                                              : true
                                          }
                                          className="input-group-addon btn red fileinput-exists"
                                          id="2"
                                          onClick={this.deleteFile}
                                        >
                                          <i
                                            id="2"
                                            style={{ color: "#fff" }}
                                            class="fa fa-trash"
                                          ></i>
                                        </button>
                                      ) : null}
                                    </div>
                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.w9}
                                    </div>
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="col-md-4 control-label">
                                    Insurance Certificate (copy)
                                  </label>

                                  <div className="col-md-8 fileinput ">
                                    <div className="input-group input-large doc_custom">
                                      <div
                                        className="form-control uneditable-input input-fixed input-medium"
                                        data-trigger="fileinput"
                                      >
                                        <i className="fa fa-file fileinput-exists"></i>
                                        &nbsp;
                                        <span className="fileinput-filename">
                                          {this.state.file.insur.name}
                                        </span>
                                      </div>
                                      {role !== tenantUser ||
                                      (role == tenantUser &&
                                        subRole == tenantSubRole) ? (
                                        <span className="input-group-addon btn default btn-file">
                                          <span className="fileinput-new">
                                            Upload file
                                          </span>
                                          <input
                                            type="file"
                                            id="3"
                                            name="insur"
                                            onChange={this.onFileChange}
                                          />
                                        </span>
                                      ) : null}
                                      {this.state.insuranceload ? (
                                        <button
                                          hidden={
                                            this.state.file.insur.name
                                              ? false
                                              : true
                                          }
                                          className="input-group-addon btn green fileinput-exists"
                                          id="3"
                                          onClick={this.download}
                                        >
                                          <i
                                            id="3"
                                            style={{ color: "#fff" }}
                                            class="fa fa-eye"
                                          ></i>
                                        </button>
                                      ) : (
                                        <button
                                          className="input-group-addon btn green fileinput-exists"
                                          id="1"
                                          onClick={(e) => e.preventDefault()}
                                        >
                                          <i
                                            id="1"
                                            style={{ color: "#fff" }}
                                            class="fa fa-circle-o-notch fa-spin"
                                          ></i>
                                        </button>
                                      )}
                                      {role !== tenantUser ||
                                          (role == tenantUser &&
                                            subRole == tenantSubRole) ? (
                                        <button
                                          hidden={
                                            this.state.file.insur.name
                                              ? false
                                              : true
                                          }
                                          className="input-group-addon btn red fileinput-exists"
                                          id="3"
                                          onClick={this.deleteFile}
                                        >
                                          <i
                                            id="3"
                                            style={{ color: "#fff" }}
                                            class="fa fa-trash"
                                          ></i>
                                        </button>
                                      ) : null}
                                    </div>
                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.insur}
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-4 control-label">
                                    Void Check (copy)
                                  </label>

                                  <div className="col-md-8 fileinput ">
                                    <div className="input-group input-large doc_custom">
                                      <div
                                        className="form-control uneditable-input input-fixed input-medium"
                                        data-trigger="fileinput"
                                      >
                                        <i className="fa fa-file fileinput-exists"></i>
                                        &nbsp;
                                        <span className="fileinput-filename">
                                          {this.state.file.copy.name}
                                        </span>
                                      </div>
                                      {role !== tenantUser ||
                                      (role == tenantUser &&
                                        subRole == tenantSubRole) ? (
                                        <span className="input-group-addon btn default btn-file">
                                          <span className="fileinput-new">
                                            Upload file
                                          </span>
                                          <input
                                            type="file"
                                            id="4"
                                            name="copy"
                                            onChange={this.onFileChange}
                                          />
                                        </span>
                                      ) : null}
                                      {this.state.copyload ? (
                                        <button
                                          hidden={
                                            this.state.file.copy.name
                                              ? false
                                              : true
                                          }
                                          className="input-group-addon btn green fileinput-exists"
                                          id="4"
                                          onClick={this.download}
                                        >
                                          <i
                                            id="4"
                                            style={{ color: "#fff" }}
                                            class="fa fa-eye"
                                          ></i>
                                        </button>
                                      ) : (
                                        <button
                                          className="input-group-addon btn green fileinput-exists"
                                          id="1"
                                          onClick={(e) => e.preventDefault()}
                                        >
                                          <i
                                            id="1"
                                            style={{ color: "#fff" }}
                                            class="fa fa-circle-o-notch fa-spin"
                                          ></i>
                                        </button>
                                      )}
                                      {role !== tenantUser ||
                                          (role == tenantUser &&
                                            subRole == tenantSubRole) ? (
                                        <button
                                          hidden={
                                            this.state.file.copy.name
                                              ? false
                                              : true
                                          }
                                          className="input-group-addon btn red fileinput-exists"
                                          id="4"
                                          onClick={this.deleteFile}
                                        >
                                          <i
                                            id="4"
                                            style={{ color: "#fff" }}
                                            class="fa fa-trash"
                                          ></i>
                                        </button>
                                      ) : null}
                                    </div>
                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.copy}
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-4 control-label">
                                    CARB Certificates
                                  </label>

                                  <div className="col-md-8 fileinput ">
                                    <div className="input-group input-large doc_custom">
                                      <div
                                        className="form-control uneditable-input input-fixed input-medium"
                                        data-trigger="fileinput"
                                      >
                                        <i className="fa fa-file fileinput-exists"></i>
                                        &nbsp;
                                        <span className="fileinput-filename">
                                          {this.state.file.carb.name}
                                        </span>
                                      </div>
                                      {role !== tenantUser ||
                                      (role == tenantUser &&
                                        subRole == tenantSubRole) ? (
                                        <span className="input-group-addon btn default btn-file">
                                          <span className="fileinput-new">
                                            Upload file
                                          </span>
                                          <input
                                            type="file"
                                            id="5"
                                            name="carb"
                                            onChange={this.onFileChange}
                                          />
                                        </span>
                                      ) : null}
                                      {this.state.carbload ? (
                                        <button
                                          hidden={
                                            this.state.file.carb.name
                                              ? false
                                              : true
                                          }
                                          className="input-group-addon btn green fileinput-exists"
                                          id="5"
                                          onClick={this.download}
                                        >
                                          <i
                                            id="5"
                                            style={{ color: "#fff" }}
                                            className="fa fa-eye"
                                          ></i>
                                        </button>
                                      ) : (
                                        <button
                                          className="input-group-addon btn green fileinput-exists"
                                          id="1"
                                          onClick={(e) => e.preventDefault()}
                                        >
                                          <i
                                            id="1"
                                            style={{ color: "#fff" }}
                                            className="fa fa-circle-o-notch fa-spin"
                                          ></i>
                                        </button>
                                      )}

                                      {role !== tenantUser ||
                                          (role == tenantUser &&
                                            subRole == tenantSubRole) ? (
                                        <button
                                          hidden={
                                            this.state.file.carb.name
                                              ? false
                                              : true
                                          }
                                          className="input-group-addon btn red fileinput-exists"
                                          id="5"
                                          onClick={this.deleteFile}
                                        >
                                          <i
                                            id="5"
                                            style={{ color: "#fff" }}
                                            className="fa fa-trash"
                                          ></i>
                                        </button>
                                      ) : null}
                                    </div>
                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.carb}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="clearfix"></div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane " id="tab_4">
                        {showSelectedRowOnTabsBoolean ? (
                          <>
                            <div className="showRowForEdit">
                              <span className="showRowForEdit-items">
                                <b>Carrier Name: </b>
                                {showSelectedRowOnTabs.carrierName}
                              </span>
                              <span className="showRowForEdit-items">
                                <b>MC# </b>
                                {showSelectedRowOnTabs.mcNo}
                              </span>
                              <span className="showRowForEdit-items">
                                <b>Dot# </b>
                                {showSelectedRowOnTabs.dotNo
                                  ? showSelectedRowOnTabs.dotNo
                                  : ""}
                              </span>
                              <span className="showRowForEdit-items">
                                <b>Status: </b>
                                {showSelectedRowOnTabs.status === 0 ? (
                                  <img
                                    style={{ width: "15px", height: "15px" }}
                                    src={Cross}
                                    alt="Inactive"
                                  />
                                ) : (
                                  <img
                                    style={{ width: "15px", height: "15px" }}
                                    src={Tick}
                                    alt="Active"
                                  />
                                )}
                              </span>
                              <span className="showRowForEdit-items">
                                {/* {showRowPickupDate} */}
                              </span>
                              <span className="showRowForEdit-items">
                                {/* {showRowPickupCityState} */}
                              </span>
                              <span className="showRowForEdit-items">
                                {/* {showRowDeliveryDate} */}
                              </span>
                              <span className="showRowForEdit-items">
                                {/* {showRowDeliveryCityState} */}
                              </span>
                              <span className="showRowForEdit-items">
                                {
                                  // showSelectedRowOnTabs.customerLoad.loadStatus
                                }
                              </span>
                            </div>
                          </>
                        ) : null}
                        <div className="portlet box yellow-gold">
                          <div className="portlet-title">
                            <div className="caption">
                              <i className="fa fa-file"></i> Bank Details
                            </div>
                            <div
                              className="actions"
                              style={
                                this.state.bankinforeadonly ? {} : nonclick
                              }
                              id="BankEditDiv"
                            >
                              {role !== tenantUser ? (
                                <div className="btn-group">
                                  <button
                                    className="btn sbold white dark"
                                    onClick={this.Editbankinfo.bind(this)}
                                  >
                                    <i className="fa fa-pencil"></i> Edit
                                  </button>
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="portlet-body form">
                            <form
                              onSubmit={this.submitbankRegistrationForm.bind(
                                this
                              )}
                              className="horizontal-form"
                              autoComplete="off"
                            >
                              <div className="form-body max_width800">
                                <div className="portlet-title">
                                  <h2 className="h3"> Bank Info </h2>
                                  <div className="clearfix"></div>
                                  <hr />
                                </div>
                                <div id="abcc" className="form-group row">
                                  <label className="col-md-3 control-label">
                                    Account Name:
                                    <span className="font-red-thunderbird">
                                      *
                                    </span>
                                  </label>
                                  <div className="col-md-9">
                                    <input
                                      type="text"
                                      ref="accountname"
                                      onChange={this.handleChange}
                                      name="accountName"
                                      className="form-control"
                                      placeholder=""
                                      readOnly={this.state.bankinforeadonly}
                                      defaultValue={
                                        this.state.bankformdata.accountName
                                      }
                                    />
                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.accountName}
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">
                                    Bank Name:
                                  </label>
                                  <div className="col-md-9">
                                    <input
                                      type="text"
                                      ref="bankname"
                                      onChange={this.handleChange}
                                      name="legalname"
                                      className="form-control"
                                      placeholder=""
                                      readOnly={this.state.bankinforeadonly}
                                      defaultValue={
                                        this.state.bankformdata.bankName
                                      }
                                    />
                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.legalname}
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">
                                    Routing#
                                  </label>
                                  <div className="col-md-9">
                                    <input
                                      type="text"
                                      ref="routing"
                                      name="mcNo"
                                      onChange={this.handleChange}
                                      className="form-control"
                                      placeholder=""
                                      readOnly={this.state.bankinforeadonly}
                                      defaultValue={
                                        this.state.bankformdata.routingNo
                                      }
                                    />
                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.mcNo}
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">
                                    Account#
                                  </label>
                                  <div className="col-md-9">
                                    <input
                                      type="text"
                                      ref="account"
                                      onChange={this.handleChange}
                                      name="dotNo"
                                      className="form-control"
                                      placeholder=""
                                      readOnly={this.state.bankinforeadonly}
                                      defaultValue={
                                        this.state.bankformdata.accountNo
                                      }
                                    />
                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.dotNo}
                                    </div>
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="col-md-3 control-label">
                                    Account Type:
                                  </label>
                                  <div className="col-md-9">
                                    <span style={{ marginTop: "2px" }}>
                                      <div
                                        className="btn-group btn-group-devided"
                                        data-toggle="buttons"
                                        style={
                                          this.state.bankinforeadonly
                                            ? nonclick
                                            : msgShow
                                        }
                                      >
                                        <label
                                          disabled={this.state.bankinforeadonly}
                                          className="btn btn-transparent dark btn-outline btn-circle btn-sm active"
                                          id="banktype"
                                          onClick={() =>
                                            this.setState({ AccountType: 1 })
                                          }
                                        >
                                          <input
                                            type="radio"
                                            name="options"
                                            className="toggle"
                                            id="1"
                                          />
                                          Checking
                                        </label>
                                        <label
                                          disabled={this.state.bankinforeadonly}
                                          className="btn btn-transparent dark btn-outline btn-circle btn-sm"
                                          id="banktype2"
                                          onClick={() =>
                                            this.setState({ AccountType: 2 })
                                          }
                                        >
                                          <input
                                            type="radio"
                                            name="options"
                                            className="toggle"
                                            id="2"
                                          />
                                          Saving
                                        </label>
                                      </div>
                                    </span>
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="col-md-3 control-label">
                                    Address
                                    <span className="font-red-thunderbird">
                                      *
                                    </span>
                                  </label>
                                  <div className="col-md-9">
                                    <input
                                      type="text"
                                      ref="bankaddress"
                                      onChange={this.handleChange}
                                      name="baddress"
                                      className="form-control"
                                      placeholder=""
                                      readOnly={this.state.bankinforeadonly}
                                      defaultValue={
                                        this.state.bankformdata.address
                                      }
                                    />
                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.baddress}
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">
                                    Address Line 2
                                  </label>
                                  <div className="col-md-9">
                                    <input
                                      type="text"
                                      ref="bankaddress2"
                                      onChange={this.handleChange}
                                      name="addressLineTwo"
                                      className="form-control"
                                      placeholder=""
                                      readOnly={this.state.bankinforeadonly}
                                      defaultValue={
                                        this.state.bankformdata.addressLineTwo
                                      }
                                    />
                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.addressLineTwo}
                                    </div>
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="col-md-3 control-label">
                                    Country
                                    <span className="font-red-thunderbird">
                                      *
                                    </span>
                                  </label>
                                  <div className="col-md-9">
                                    <select
                                      ref="bankcountry_id"
                                      name="bcountry"
                                      className="form-control"
                                      title="Country"
                                      onChange={this.bankstates.bind(this)}
                                      disabled={this.state.bankinforeadonly}
                                      readOnly={this.state.bankinforeadonly}
                                    >
                                      <option value="" selected disabled hidden>
                                        Select Country
                                      </option>
                                      ;
                                      {this.state.countries.map(function(data) {
                                        if (
                                          this.state.bankformdata.countryId ===
                                          data.id
                                        )
                                          return (
                                            <option value={data.id} selected>
                                              {data.countryName}
                                            </option>
                                          );
                                        return (
                                          <option value={data.id}>
                                            {data.countryName}
                                          </option>
                                        );
                                      }, this)}
                                    </select>
                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.bcountry}
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">
                                    State
                                    <span className="font-red-thunderbird">
                                      *
                                    </span>
                                  </label>
                                  <div className="col-md-9">
                                    <select
                                      ref="bankprovince_id"
                                      name="bstate"
                                      className="form-control"
                                      defaultValue="1"
                                      disabled={this.state.bankinforeadonly}
                                      readOnly={this.state.bankinforeadonly}
                                    >
                                      {this.state.bankstates.map(function(
                                        data
                                      ) {
                                        if (
                                          this.state.bankformdata.stateId ===
                                          data.id
                                        )
                                          return (
                                            <option value={data.id} selected>
                                              {data.state}
                                            </option>
                                          );
                                        return (
                                          <option value={data.id}>
                                            {data.state}
                                          </option>
                                        );
                                      },
                                      this)}
                                    </select>
                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.bstate}
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">
                                    City
                                    <span className="font-red-thunderbird">
                                      *
                                    </span>
                                  </label>
                                  <div className="col-md-9">
                                    <input
                                      type="text"
                                      ref="bankcity"
                                      onChange={this.handleChange}
                                      name="bcity"
                                      className="form-control"
                                      placeholder=""
                                      readOnly={this.state.bankinforeadonly}
                                      defaultValue={
                                        this.state.bankformdata.city
                                      }
                                    />
                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.bcity}
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">
                                    Zip
                                    <span className="font-red-thunderbird">
                                      *
                                    </span>
                                  </label>
                                  <div className="col-md-9">
                                    <input
                                      type="text"
                                      ref="bankzip"
                                      onChange={this.handleChange}
                                      name="bzip"
                                      className="form-control"
                                      placeholder=""
                                      readOnly={this.state.bankinforeadonly}
                                      defaultValue={this.state.bankformdata.zip}
                                    />
                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.bzip}
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">
                                    Email
                                  </label>
                                  <div className="col-md-9">
                                    <input
                                      type="email"
                                      ref="bankemail"
                                      onChange={this.handleChange}
                                      name="email"
                                      className="form-control"
                                      placeholder=""
                                      readOnly={this.state.bankinforeadonly}
                                      defaultValue={
                                        this.state.bankformdata.email
                                      }
                                    />
                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.email}
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">
                                    Telephone
                                  </label>
                                  <div className="col-md-9 form-inline">
                                    <input
                                      type="text"
                                      ref="banktelephone"
                                      id="bphone"
                                      onChange={this.AutoFormatContact}
                                      name="btelephone"
                                      className="form-control"
                                      placeholder="xxx-xxx-xxxx"
                                      readOnly={this.state.bankinforeadonly}
                                      defaultValue={
                                        this.state.bankformdata.telephone
                                      }
                                    />
                                    <span className="help-inline"> EXT </span>
                                    <input
                                      type="text"
                                      name="ext"
                                      onChange={this.handleChange}
                                      ref="bankext"
                                      className="form-control"
                                      placeholder=""
                                      readOnly={this.state.bankinforeadonly}
                                      defaultValue={this.state.bankformdata.ext}
                                    />
                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.btelephone}
                                    </div>
                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.ext}
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">
                                    Fax
                                  </label>
                                  <div className="col-md-9">
                                    <input
                                      type="text"
                                      ref="bankfax"
                                      name="bfax"
                                      id="bankfax"
                                      onChange={this.AutoFormatContact}
                                      className="form-control"
                                      placeholder="xxx-xxx-xxxx"
                                      readOnly={this.state.bankinforeadonly}
                                      defaultValue={this.state.bankformdata.fax}
                                    />
                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.bfax}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="clearfix"></div>
                              <div className="form-actions right">
                                <button
                                  onClick={this.cancel.bind(this)}
                                  type="button"
                                  className="btn default"
                                >
                                  Cancel
                                </button>
                                &nbsp;
                                {role !== tenantUser ? (
                                  <button
                                    disabled={this.state.bankinforeadonly}
                                    type="submit"
                                    className="btn green-meadow"
                                  >
                                    {this.state.whileSave === false ? (
                                      <i className="fa fa-save"></i>
                                    ) : (
                                      <i className="fa fa-circle-o-notch fa-spin"></i>
                                    )}
                                    Save
                                  </button>
                                ) : null}
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>

                      {role !== tenantUser ? (
                        <div className="tab-pane" id="tab_5">
                          {showSelectedRowOnTabsBoolean ? (
                            <>
                              <div className="showRowForEdit">
                                <span className="showRowForEdit-items">
                                  <b>Carrier Name: </b>
                                  {showSelectedRowOnTabs.carrierName}
                                </span>
                                <span className="showRowForEdit-items">
                                  <b>MC# </b>
                                  {showSelectedRowOnTabs.mcNo}
                                </span>
                                <span className="showRowForEdit-items">
                                  <b>Dot# </b>
                                  {showSelectedRowOnTabs.dotNo
                                    ? showSelectedRowOnTabs.dotNo
                                    : ""}
                                </span>
                                <span className="showRowForEdit-items">
                                  <b>Status: </b>
                                  {showSelectedRowOnTabs.status === 0 ? (
                                    <img
                                      style={{ width: "15px", height: "15px" }}
                                      src={Cross}
                                      alt="Inactive"
                                    />
                                  ) : (
                                    <img
                                      style={{ width: "15px", height: "15px" }}
                                      src={Tick}
                                      alt="Active"
                                    />
                                  )}
                                </span>
                                <span className="showRowForEdit-items">
                                  {/* {showRowPickupDate} */}
                                </span>
                                <span className="showRowForEdit-items">
                                  {/* {showRowPickupCityState} */}
                                </span>
                                <span className="showRowForEdit-items">
                                  {/* {showRowDeliveryDate} */}
                                </span>
                                <span className="showRowForEdit-items">
                                  {/* {showRowDeliveryCityState} */}
                                </span>
                                <span className="showRowForEdit-items">
                                  {
                                    // showSelectedRowOnTabs.customerLoad.loadStatus
                                  }
                                </span>
                              </div>
                            </>
                          ) : null}
                          {this.state.carrierData.truckMakes ? (
                            <TruckTab
                              Trucks={{
                                Truck: this.state.carrierData.trucks,
                                makes: this.state.carrierData.truckMakes,
                                states: this.state.carrierData.states,
                              }}
                              CarrierId={this.state.customerid}
                            />
                          ) : null}
                        </div>
                      ) : null}

                      {role !== tenantUser ? (
                        <div className="tab-pane" id="tab_6">
                          {showSelectedRowOnTabsBoolean ? (
                            <>
                              <div className="showRowForEdit">
                                <span className="showRowForEdit-items">
                                  <b>Carrier Name: </b>
                                  {showSelectedRowOnTabs.carrierName}
                                </span>
                                <span className="showRowForEdit-items">
                                  <b>MC# </b>
                                  {showSelectedRowOnTabs.mcNo}
                                </span>
                                <span className="showRowForEdit-items">
                                  <b>Dot# </b>
                                  {showSelectedRowOnTabs.dotNo
                                    ? showSelectedRowOnTabs.dotNo
                                    : ""}
                                </span>
                                <span className="showRowForEdit-items">
                                  <b>Status: </b>
                                  {showSelectedRowOnTabs.status === 0 ? (
                                    <img
                                      style={{ width: "15px", height: "15px" }}
                                      src={Cross}
                                      alt="Inactive"
                                    />
                                  ) : (
                                    <img
                                      style={{ width: "15px", height: "15px" }}
                                      src={Tick}
                                      alt="Active"
                                    />
                                  )}
                                </span>
                                <span className="showRowForEdit-items">
                                  {/* {showRowPickupDate} */}
                                </span>
                                <span className="showRowForEdit-items">
                                  {/* {showRowPickupCityState} */}
                                </span>
                                <span className="showRowForEdit-items">
                                  {/* {showRowDeliveryDate} */}
                                </span>
                                <span className="showRowForEdit-items">
                                  {/* {showRowDeliveryCityState} */}
                                </span>
                                <span className="showRowForEdit-items">
                                  {
                                    // showSelectedRowOnTabs.customerLoad.loadStatus
                                  }
                                </span>
                              </div>
                            </>
                          ) : null}
                          {this.state.carrierData.trailerMakes ? (
                            <TrailerTab
                              Trailer={{
                                Trailer: this.state.carrierData.trailers,
                                makes: this.state.carrierData.trailerMakes,
                                states: this.state.carrierData.states,
                              }}
                              CarrierId={this.state.customerid}
                            />
                          ) : null}
                        </div>
                      ) : null}
                      {role !== tenantUser ? (
                        <div className="tab-pane" id="tab_15">
                          {showSelectedRowOnTabsBoolean ? (
                            <>
                              <div className="showRowForEdit">
                                <span className="showRowForEdit-items">
                                  <b>Carrier Name: </b>
                                  {showSelectedRowOnTabs.carrierName}
                                </span>
                                <span className="showRowForEdit-items">
                                  <b>MC# </b>
                                  {showSelectedRowOnTabs.mcNo}
                                </span>
                                <span className="showRowForEdit-items">
                                  <b>Dot# </b>
                                  {showSelectedRowOnTabs.dotNo
                                    ? showSelectedRowOnTabs.dotNo
                                    : ""}
                                </span>
                                <span className="showRowForEdit-items">
                                  <b>Status: </b>
                                  {showSelectedRowOnTabs.status === 0 ? (
                                    <img
                                      style={{ width: "15px", height: "15px" }}
                                      src={Cross}
                                      alt="Inactive"
                                    />
                                  ) : (
                                    <img
                                      style={{ width: "15px", height: "15px" }}
                                      src={Tick}
                                      alt="Active"
                                    />
                                  )}
                                </span>
                                <span className="showRowForEdit-items">
                                  {/* {showRowPickupDate} */}
                                </span>
                                <span className="showRowForEdit-items">
                                  {/* {showRowPickupCityState} */}
                                </span>
                                <span className="showRowForEdit-items">
                                  {/* {showRowDeliveryDate} */}
                                </span>
                                <span className="showRowForEdit-items">
                                  {/* {showRowDeliveryCityState} */}
                                </span>
                                <span className="showRowForEdit-items">
                                  {
                                    // showSelectedRowOnTabs.customerLoad.loadStatus
                                  }
                                </span>
                              </div>
                            </>
                          ) : null}
                          {this.state.carrierData.countries ? (
                            <DriverTab
                              Driver={{
                                Driver: this.state.carrierData.driver,
                                countries: this.state.carrierData.countries,
                                states: this.state.carrierData.states,
                              }}
                              CarrierId={this.state.customerid}
                            />
                          ) : null}
                        </div>
                      ) : null}

                      {role !== tenantUser ? (
                        <div className="tab-pane " id="tab_20">
                          {showSelectedRowOnTabsBoolean ? (
                            <>
                              <div className="showRowForEdit">
                                <span className="showRowForEdit-items">
                                  <b>Carrier Name: </b>
                                  {showSelectedRowOnTabs.carrierName}
                                </span>
                                <span className="showRowForEdit-items">
                                  <b>MC# </b>
                                  {showSelectedRowOnTabs.mcNo}
                                </span>
                                <span className="showRowForEdit-items">
                                  <b>Dot# </b>
                                  {showSelectedRowOnTabs.dotNo
                                    ? showSelectedRowOnTabs.dotNo
                                    : ""}
                                </span>
                                <span className="showRowForEdit-items">
                                  <b>Status: </b>
                                  {showSelectedRowOnTabs.status === 0 ? (
                                    <img
                                      style={{ width: "15px", height: "15px" }}
                                      src={Cross}
                                      alt="Inactive"
                                    />
                                  ) : (
                                    <img
                                      style={{ width: "15px", height: "15px" }}
                                      src={Tick}
                                      alt="Active"
                                    />
                                  )}
                                </span>
                                <span className="showRowForEdit-items">
                                  {/* {showRowPickupDate} */}
                                </span>
                                <span className="showRowForEdit-items">
                                  {/* {showRowPickupCityState} */}
                                </span>
                                <span className="showRowForEdit-items">
                                  {/* {showRowDeliveryDate} */}
                                </span>
                                <span className="showRowForEdit-items">
                                  {/* {showRowDeliveryCityState} */}
                                </span>
                                <span className="showRowForEdit-items">
                                  {
                                    // showSelectedRowOnTabs.customerLoad.loadStatus
                                  }
                                </span>
                              </div>
                            </>
                          ) : null}
                          <LoadTab carrierId={this.state.customerid} />
                        </div>
                      ) : null}
                      {role !== tenantUser ? (
                        <div className="tab-pane " id="tab_21">
                          {showSelectedRowOnTabsBoolean ? (
                            <>
                              <div className="showRowForEdit">
                                <span className="showRowForEdit-items">
                                  <b>Carrier Name: </b>
                                  {showSelectedRowOnTabs.carrierName}
                                </span>
                                <span className="showRowForEdit-items">
                                  <b>MC# </b>
                                  {showSelectedRowOnTabs.mcNo}
                                </span>
                                <span className="showRowForEdit-items">
                                  <b>Dot# </b>
                                  {showSelectedRowOnTabs.dotNo
                                    ? showSelectedRowOnTabs.dotNo
                                    : ""}
                                </span>
                                <span className="showRowForEdit-items">
                                  <b>Status: </b>
                                  {showSelectedRowOnTabs.status === 0 ? (
                                    <img
                                      style={{ width: "15px", height: "15px" }}
                                      src={Cross}
                                      alt="Inactive"
                                    />
                                  ) : (
                                    <img
                                      style={{ width: "15px", height: "15px" }}
                                      src={Tick}
                                      alt="Active"
                                    />
                                  )}
                                </span>
                                <span className="showRowForEdit-items">
                                  {/* {showRowPickupDate} */}
                                </span>
                                <span className="showRowForEdit-items">
                                  {/* {showRowPickupCityState} */}
                                </span>
                                <span className="showRowForEdit-items">
                                  {/* {showRowDeliveryDate} */}
                                </span>
                                <span className="showRowForEdit-items">
                                  {/* {showRowDeliveryCityState} */}
                                </span>
                                <span className="showRowForEdit-items">
                                  {
                                    // showSelectedRowOnTabs.customerLoad.loadStatus
                                  }
                                </span>
                              </div>
                            </>
                          ) : null}
                          <div className="portlet box yellow-gold">
                            <div className="portlet-title">
                              <div className="caption">
                                <i className="fa fa-dollar"></i> Dispatch
                                Details
                              </div>
                              <div
                                className="actions"
                                style={
                                  this.state.billingInfoReadOnly
                                    ? null
                                    : nonclick
                                }
                                id="editBillingDiv"
                              >
                                <div className="btn-group">
                                  <button
                                    className="btn sbold white dark"
                                    onClick={this.EditBillingForm.bind(this)}
                                  >
                                    <i className="fa fa-pencil"></i> Edit
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="portlet-body form">
                              <form
                                onSubmit={this.submitBillingRegistrationForm.bind(
                                  this
                                )}
                                className="horizontal-form"
                                autoComplete="off"
                              >
                                <div className="form-body max_width800">
                                  <div className="portlet-title">
                                    <h2 className="h3">Dispatch Info</h2>
                                    <div className="clearfix"></div>
                                    <hr />
                                  </div>

                                  <div className="form-group row">
                                    <label className="col-md-3 control-label">
                                      Dispatch Fee Type:{" "}
                                      <span className="font-red-thunderbird">
                                        *
                                      </span>
                                    </label>
                                    <div className="col-md-9">
                                      <select
                                        className="form-control"
                                        ref="dispatchType"
                                        disabled={
                                          this.state.billingInfoReadOnly
                                        }
                                        onChange={(e) => {
                                          e.preventDefault();
                                          var billingFormData = this.state
                                            .billingFormData;
                                          billingFormData.dispatchType = Number(
                                            e.target.value
                                          );
                                          this.setState({ billingFormData });
                                        }}
                                        value={
                                          this.state.billingFormData
                                            .dispatchType
                                        }
                                      >
                                        {this.state.dispatchTypeOptions.map(
                                          function(data) {
                                            return (
                                              <option value={data.id}>
                                                {data.name}
                                              </option>
                                            );
                                          }
                                        )}
                                      </select>
                                    </div>
                                  </div>
                                  {this.state.billingFormData.dispatchType ===
                                  2 ? (
                                    <div className="form-group row">
                                      <label className="col-md-3 control-label">
                                        Dispatch Percentage
                                        <span className="font-red-thunderbird"></span>
                                      </label>
                                      <div className="col-md-9">
                                        <CurrencyInput
                                          precision="2"
                                          suffix="%"
                                          ref="dispatchPercentage"
                                          value={
                                            this.state.billingFormData
                                              .dispatchPercentage
                                          }
                                          className="form-control"
                                          readOnly={
                                            this.state.billingInfoReadOnly
                                          }
                                          placeholder=""
                                          onChangeEvent={(
                                            event,
                                            maskedvalue,
                                            floatvalue
                                          ) => {
                                            var billingFormData = this.state
                                              .billingFormData;
                                            billingFormData.dispatchPercentage = floatvalue;
                                            this.setState({ billingFormData });
                                          }}
                                        />
                                        <div
                                          style={{ color: "red" }}
                                          className="errorMsg"
                                        >
                                          {this.state.errors.dispatchPercentage}
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="form-group row">
                                      <label className="col-md-3 control-label">
                                        Dispatch Fee
                                        <span className="font-red-thunderbird"></span>
                                      </label>
                                      <div className="col-md-9">
                                        <CurrencyInput
                                          precision="2"
                                          prefix="$"
                                          ref="dispatchFee"
                                          value={
                                            this.state.billingFormData
                                              .dispatchFee
                                          }
                                          className="form-control"
                                          readOnly={
                                            this.state.billingInfoReadOnly
                                          }
                                          placeholder=""
                                          onChangeEvent={(
                                            event,
                                            maskedvalue,
                                            floatvalue
                                          ) => {
                                            var billingFormData = this.state
                                              .billingFormData;
                                            billingFormData.dispatchFee = floatvalue;
                                            this.setState({ billingFormData });
                                          }}
                                        />
                                        <div
                                          style={{ color: "red" }}
                                          className="errorMsg"
                                        >
                                          {this.state.errors.dispatchFee}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  {/* <div id="abcc" className="form-group row">
                                    <label className="col-md-3 control-label">
                                      Dispatch Percentage:
                                      <span className="font-red-thunderbird">
                                        *
                                      </span>
                                    </label>
                                    <div className="col-md-9">
                                      <input
                                        type="text"
                                        ref="dispatchPercentage"
                                        onChange={this.handleChange}
                                        name="dispatchPercentage"
                                        className="form-control"
                                        placeholder=""
                                        readOnly={
                                          this.state.billingInfoReadOnly
                                        }
                                        defaultValue={
                                          this.state.billingFormData.dispatchPercentage
                                        }
                                      />
                                      <div
                                        style={{ color: "red" }}
                                        className="errorMsg"
                                      >
                                        {this.state.errors.dispatchPercentage}
                                      </div>
                                    </div>
                                  </div> */}
                                </div>
                                <div className="clearfix"></div>
                                <div className="form-actions right">
                                  <button
                                    onClick={this.cancel.bind(this)}
                                    type="button"
                                    className="btn default"
                                  >
                                    Cancel
                                  </button>
                                  &nbsp;
                                  <button
                                    disabled={this.state.billingInfoReadOnly}
                                    type="submit"
                                    className="btn green-meadow"
                                  >
                                    {this.state.whileSave === false ? (
                                      <i className="fa fa-save"></i>
                                    ) : (
                                      <i className="fa fa-circle-o-notch fa-spin"></i>
                                    )}
                                    Save
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {role !== tenantUser ? (
                        <div className="tab-pane " id="tab_22">
                          {showSelectedRowOnTabsBoolean ? (
                            <>
                              <div className="showRowForEdit">
                                <span className="showRowForEdit-items">
                                  <b>Carrier Name: </b>
                                  {showSelectedRowOnTabs.carrierName}
                                </span>
                                <span className="showRowForEdit-items">
                                  <b>MC# </b>
                                  {showSelectedRowOnTabs.mcNo}
                                </span>
                                <span className="showRowForEdit-items">
                                  <b>Dot# </b>
                                  {showSelectedRowOnTabs.dotNo
                                    ? showSelectedRowOnTabs.dotNo
                                    : ""}
                                </span>
                                <span className="showRowForEdit-items">
                                  <b>Status: </b>
                                  {showSelectedRowOnTabs.status === 0 ? (
                                    <img
                                      style={{ width: "15px", height: "15px" }}
                                      src={Cross}
                                      alt="Inactive"
                                    />
                                  ) : (
                                    <img
                                      style={{ width: "15px", height: "15px" }}
                                      src={Tick}
                                      alt="Active"
                                    />
                                  )}
                                </span>
                                <span className="showRowForEdit-items">
                                  {/* {showRowPickupDate} */}
                                </span>
                                <span className="showRowForEdit-items">
                                  {/* {showRowPickupCityState} */}
                                </span>
                                <span className="showRowForEdit-items">
                                  {/* {showRowDeliveryDate} */}
                                </span>
                                <span className="showRowForEdit-items">
                                  {/* {showRowDeliveryCityState} */}
                                </span>
                                <span className="showRowForEdit-items">
                                  {
                                    // showSelectedRowOnTabs.customerLoad.loadStatus
                                  }
                                </span>
                              </div>
                            </>
                          ) : null}
                          <BillingTab carrierId={this.state.customerid} />
                        </div>
                      ) : null}
                      {role !== tenantUser ? (
                        <div className="tab-pane " id="tab_23">
                          {showSelectedRowOnTabsBoolean ? (
                            <>
                              <div className="showRowForEdit">
                                <span className="showRowForEdit-items">
                                  <b>Carrier Name: </b>
                                  {showSelectedRowOnTabs.carrierName}
                                </span>
                                <span className="showRowForEdit-items">
                                  <b>MC# </b>
                                  {showSelectedRowOnTabs.mcNo}
                                </span>
                                <span className="showRowForEdit-items">
                                  <b>Dot# </b>
                                  {showSelectedRowOnTabs.dotNo
                                    ? showSelectedRowOnTabs.dotNo
                                    : ""}
                                </span>
                                <span className="showRowForEdit-items">
                                  <b>Status: </b>
                                  {showSelectedRowOnTabs.status === 0 ? (
                                    <img
                                      style={{ width: "15px", height: "15px" }}
                                      src={Cross}
                                      alt="Inactive"
                                    />
                                  ) : (
                                    <img
                                      style={{ width: "15px", height: "15px" }}
                                      src={Tick}
                                      alt="Active"
                                    />
                                  )}
                                </span>
                                <span className="showRowForEdit-items">
                                  {/* {showRowPickupDate} */}
                                </span>
                                <span className="showRowForEdit-items">
                                  {/* {showRowPickupCityState} */}
                                </span>
                                <span className="showRowForEdit-items">
                                  {/* {showRowDeliveryDate} */}
                                </span>
                                <span className="showRowForEdit-items">
                                  {/* {showRowDeliveryCityState} */}
                                </span>
                                <span className="showRowForEdit-items">
                                  {
                                    // showSelectedRowOnTabs.customerLoad.loadStatus
                                  }
                                </span>
                              </div>
                            </>
                          ) : null}
                          <AccountingTab carrierId={this.state.customerid} />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="contact"
            data-backdrop="static"
            data-keyboard="false"
            tabIndex="-1"
            aria-hidden="true"
          >
            <div className="modal-dialog  modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    id="closePopUp"
                    data-dismiss="modal"
                    aria-hidden="true"
                    hidden="true"
                  ></button>
                  <button
                    type="button"
                    className="close"
                    onClick={this.FinishEditInfo.bind(this)}
                    data-dismiss="modal"
                    aria-hidden="true"
                  ></button>
                  <h4 className="modal-title">Contact</h4>
                </div>
                <div className="modal-body">
                  <div className="portlet-body form">
                    <form
                      onSubmit={this.SaveContact.bind(this)}
                      className="horizontal-form"
                    >
                      <div className="form-body max_width800">
                        <br />

                        <div className="form-group row">
                          <label className="col-md-3 control-label">
                            Name <span className="font-red-thunderbird">*</span>
                          </label>
                          <div className="col-md-9">
                            <input
                              type="text"
                              ref="ContactName"
                              pattern="[a-z A-Z0-9-'@!$#:;,. ]{1,200}"
                              title="Please Enter Contact Name (Max limit: 200)"
                              className="form-control"
                              placeholder=""
                              required
                            />
                          </div>
                        </div>

                        <div className="form-group row">
                          <label className="col-md-3 control-label ">
                            Title
                          </label>
                          <div className="col-md-9">
                            <input
                              type="text"
                              ref="ContactTitle"
                              pattern="[a-z A-Z'(),-_.\x22 ]{1,200}"
                              title="Please Enter title (Max limit: 200)"
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>

                        <div className="form-group row">
                          <label className="col-md-3 control-label">
                            Email
                          </label>
                          <div className="col-md-9">
                            <input
                              type="email"
                              ref="ContactEmail"
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>

                        <div className="form-group row">
                          <label className="col-md-3 control-label">
                            Telephone
                          </label>
                          <div className="col-md-9 form-inline">
                            <input
                              type="text"
                              ref="ContactTelephone"
                              id="contactTelephone"
                              pattern="[a-z A-Z0-9- ]{12}"
                              title="Please Enter Full lenght Telephone (12 digits)"
                              onChange={this.AutoFormatContact}
                              className="form-control"
                              placeholder="xxx-xxx-xxxx"
                            />
                            <span className="help-inline"> EXT </span>
                            <input
                              type="text"
                              pattern="[0-9]{1,10}"
                              title="Please Enter extention (Max 10 Digits Only)"
                              ref="ContactExt"
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-md-3 control-label">
                            Cell Phone
                          </label>
                          <div className="col-md-9">
                            <input
                              type="text"
                              ref="ContactPhone"
                              id="contactPhone"
                              pattern="[a-z A-Z0-9- ]{12}"
                              title="Please Enter Full lenght Cellphone (12 digits)"
                              onChange={this.AutoFormatContact}
                              className="form-control"
                              placeholder="xxx-xxx-xxxx"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-md-3 control-label">Fax</label>
                          <div className="col-md-9">
                            <input
                              type="text"
                              ref="ContactFax"
                              id="contactFax"
                              pattern="[a-z A-Z0-9- ]{12}"
                              title="Please Enter Full lenght fax (12 digits)"
                              onChange={this.AutoFormatContact}
                              className="form-control"
                              placeholder="xxx-xxx-xxxx"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="clearfix"></div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn default"
                          data-dismiss="modal"
                          onClick={this.FinishEditInfo.bind(this)}
                        >
                          Cancel
                        </button>
                        &nbsp;
                        <button type="submit" className="btn green-meadow">
                          <i className="fa fa-check"></i> Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="general_liability"
            data-backdrop="static"
            data-keyboard="false"
            tabIndex="-1"
            aria-hidden="true"
          >
            <div className="modal-dialog  modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    id="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                    hidden="true"
                  ></button>
                  <button
                    type="button"
                    className="close"
                    onClick={this.ResetInsuranceForm.bind(this)}
                    data-dismiss="modal"
                    aria-hidden="true"
                  ></button>
                  <h4 className="modal-title">Insurance Liability</h4>
                </div>
                <div className="modal-body">
                  <div className="portlet-body form">
                    <form
                      action="#"
                      className="horizontal-form"
                      onSubmit={this.submitInsuranceInfo.bind(this)}
                    >
                      <div className="form-body max_width800">
                        <div
                          className="text-right"
                          hidden={this.state.hideinsuranceinfobutton}
                        >
                          <button
                            type="button"
                            style={{ display: "none" }}
                            id="closeInsuranceLiabilityPopup"
                            data-dismiss="modal"
                            aria-hidden="true"
                          ></button>
                          <button
                            type="button"
                            className="btn default"
                            onClick={this.delInsuranceInfo.bind(this)}
                            disabled={!this.state.insuranceinforeadonly}
                          >
                            Delete
                          </button>
                          &nbsp;
                          <button
                            type="button"
                            className="btn green-meadow"
                            id="LiabilityButton"
                            onClick={this.EditInsuranceLiability.bind(this)}
                            disabled={!this.state.insuranceinforeadonly}
                          >
                            Edit
                          </button>
                        </div>
                        <div className="form-group row mt10">
                          <label className="col-md-3 control-label">
                            Choose Company:
                            <span className="font-red-thunderbird">*</span>
                          </label>
                          <div className="col-md-9">
                            <div className="form-group row">
                              <div className="col-md-12">
                                <select
                                  ref="companies"
                                  className="form-control"
                                  onChange={this.loadagent.bind(this)}
                                  disabled={this.state.insuranceinforeadonly}
                                  required
                                >
                                  <option value="" selected disabled hidden>
                                    Select Company
                                  </option>
                                  {this.state.companies.map(function(data) {
                                    return (
                                      <option value={data.id}>
                                        {data.companyName}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>

                            <div
                              className="row"
                              style={
                                this.state.companydata.address
                                  ? msgShow
                                  : msgNone
                              }
                            >
                              <div className="col-md-12">
                                <div className="card_info">
                                  <div className="row">
                                    <div className="col-md-12">
                                      <p>
                                        <i
                                          className="fa fa-map-marker"
                                          aria-hidden="true"
                                        ></i>
                                        &nbsp;
                                        {this.state.companydata.address}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-6">
                                      <p>
                                        <i
                                          className="fa fa-building"
                                          aria-hidden="true"
                                        ></i>
                                        &nbsp;
                                        {this.state.companydata.city}
                                      </p>
                                    </div>
                                    <div className="col-md-6">
                                      <p>
                                        <i
                                          className="fa fa-globe"
                                          aria-hidden="true"
                                        ></i>
                                        &nbsp;
                                        {this.state.companydata.state}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-md-3 control-label">
                            Choose Agent:
                            <span className="font-red-thunderbird"></span>
                          </label>
                          <div className="col-md-9">
                            <div className="form-group row">
                              <div className="col-md-12">
                                <select
                                  className="form-control"
                                  ref="agent"
                                  onChange={this.loadagentinfo.bind(this)}
                                  disabled={this.state.insuranceinforeadonly}
                                >
                                  <option value="" selected disabled hidden>
                                    Select Agent
                                  </option>
                                  {this.state.agents.map(function(data) {
                                    return (
                                      <option value={data.id}>
                                        {data.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                            <div
                              className="row"
                              style={
                                this.state.agentdata.name ? msgShow : msgNone
                              }
                            >
                              <div className="col-md-12">
                                <div className="card_info">
                                  <div className="row">
                                    <div className="col-md-6">
                                      <p>
                                        <i
                                          className="fa fa-user"
                                          aria-hidden="true"
                                        ></i>
                                        &nbsp;
                                        {this.state.agentdata.name}
                                      </p>
                                    </div>
                                    <div className="col-md-6">
                                      <p>
                                        <i
                                          className="fa fa-envelope-o"
                                          aria-hidden="true"
                                        ></i>
                                        &nbsp;
                                        {this.state.agentdata.email}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-6">
                                      <p>
                                        <i
                                          className="fa fa-phone"
                                          aria-hidden="true"
                                        ></i>
                                        &nbsp;
                                        {this.state.agentdata.telephone}
                                      </p>
                                    </div>
                                    <div className="col-md-6">
                                      <p>
                                        <i
                                          className="fa fa-fax"
                                          aria-hidden="true"
                                        ></i>
                                        &nbsp;
                                        {this.state.agentdata.fax}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-md-3 control-label">
                            Type <span className="font-red-thunderbird">*</span>
                          </label>
                          <div className="col-md-9">
                            <select
                              className="form-control"
                              ref="liabilityType"
                              disabled={this.state.insuranceinforeadonly}
                              required
                            >
                              <option value="" selected disabled hidden>
                                Select liability
                              </option>
                              {this.state.LiabilityTypes.map(function(data) {
                                return (
                                  <option value={data.id}>
                                    {data.liabilityType}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-md-3 control-label">
                            Policy #
                          </label>
                          <div className="col-md-9">
                            <input
                              type="text"
                              ref="policy"
                              className="form-control"
                              pattern="[a-zA-Z0-9- ]{0,20}"
                              title="Please Enter Alphanumeric (Max: 20 digits)"
                              placeholder=""
                              readOnly={this.state.insuranceinforeadonly}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-md-3 control-label">
                            Limit($)
                          </label>
                          <div className="col-md-9">
                            <CurrencyInput
                              precision="2"
                              prefix=""
                              ref="limit"
                              value={this.state.CurrencyValue}
                              className="form-control"
                              readOnly={this.state.insuranceinforeadonly}
                              placeholder=""
                            />
                            {/* <input type="text" ref="limit" className="form-control" readOnly={this.state.insuranceinforeadonly} placeholder="" /> */}
                          </div>
                        </div>

                        <div className="form-group row">
                          <label className="col-md-3 control-label">
                            Insurer
                          </label>
                          <div className="col-md-9">
                            <input
                              type="text"
                              ref="insurer"
                              className="form-control"
                              pattern="[a-zA-Z0-9- ]{0,50}"
                              title="Please Enter Alphanumeric (Max: 50 digits)"
                              readOnly={this.state.insuranceinforeadonly}
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-md-3 control-label">
                            Effective Date
                          </label>
                          <div className="col-md-9">
                            <DatePicker
                              className="form-control"
                              selected={this.state.effdate}
                              onChange={async (date) => {
                                await this.setState({
                                  effdate: date,
                                });
                                console.log(this.state.effdate);
                                this.validateInsuranceInfo();
                              }}
                              readOnly={this.state.insuranceinforeadonly}
                            />

                            <div style={{ color: "red" }} className="errorMsg">
                              {this.state.errors.effdate}
                            </div>

                            {/* <input className="form-control form-control-inline input-medium date-picker" size="16" type="text" value="" /> */}
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-md-3 control-label">
                            Expiration Date
                          </label>
                          <div className="col-md-9">
                            <DatePicker
                              className="form-control"
                              selected={this.state.expdate}
                              onChange={async (date) => {
                                await this.setState({
                                  expdate: date,
                                });
                                console.log(this.state.expdate);
                                this.validateInsuranceInfo();
                              }}
                              readOnly={this.state.insuranceinforeadonly}
                            />
                            <div style={{ color: "red" }} className="errorMsg">
                              {this.state.errors.expdate}
                            </div>
                            {/* <input className="form-control form-control-inline input-medium date-picker" size="16" type="text" value="" /> */}
                          </div>
                        </div>
                      </div>
                      <div className="clearfix"></div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn default"
                          data-dismiss="modal"
                          onClick={this.ResetInsuranceForm.bind(this)}
                        >
                          Cancel
                        </button>
                        &nbsp;
                        <button
                          type="submit"
                          className="btn green-meadow"
                          disabled={this.state.insuranceinforeadonly}
                        >
                          <i className="fa fa-check"></i> Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Editcarrier);
