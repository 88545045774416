/* eslint-disable */

import React, { Component } from "react";
import "./login.css";
import logo from "./Logo.png";
import axios from "axios";
import { withRouter } from "react-router-dom";
import BaseURL from "./config";

const Cryptr = require("cryptr");
const cryptr = new Cryptr("@$#rgweR35*&YFSD)/");

class Userlogin extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      mode: "login",

      errorMsg: true,
    };
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleSave() {
    console.log("forgot page");
    if (this._isMounted) {
      this.setState({ mode: "forgotView" });
    }
  }
  backhandle() {
    if (this._isMounted) {
      this.setState({ mode: "login" });
    }
  }
  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      this.Submit(event);
    }
  };
  async Submit(event) {
    event.preventDefault();

    var username = this.refs.username.value;
    var password = this.refs.password.value;
    var move = false;
    var userType = "";
    var subRole = "";

    await axios
      .post(
        BaseURL + "users/login",
        {
          email: username,
          password: password,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then(function(response) {
        if (response.data.role) {
          userType = response.data.role;
          subRole = response.data.subRole;
          var encryptedRole = "";
          var encryptedSubRole = "";
          if (userType) {
            encryptedRole = cryptr.encrypt(userType);
            encryptedSubRole = cryptr.encrypt(subRole);
          }
          localStorage.setItem("Token", response.data.data);
          localStorage.setItem("Role", encryptedRole);
          localStorage.setItem("SubRole", encryptedSubRole);
          localStorage.setItem("name", response.data.firstName);

          move = true;
        } else {
          localStorage.setItem("Token", "");
        }
      })
      .catch(function(error) {
        // NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);
        console.log(error);
      });

    if (move) {
      userType === "Tenant"
        ? this.props.history.push("/carrier")
        : this.props.history.push("/dashboard");
    }

    if (username && password) {
      if (this._isMounted) {
        this.setState({
          errorMsg: false,
        });
      }
    }
    username = "";
    password = "";
  }
  render() {
    const msgNone = {
      display: "none",
    };
    const msgShow = {
      display: "block",
      color: "#be4b49",
      fontSize: "12px",
      fontWeight: "700",
    };

    if (this.state.mode === "login") {
      return (
        <div className="login-holder">
          <div className="login">
            <div className="logo">
              <a>
                <img src={logo} />
              </a>
            </div>

            <div className="content">
              <form className="login-form" onSubmit={this.Submit.bind(this)}>
                <h3 className="form-title green-meadow">Sign In</h3>
                <div className="alert alert-danger display-hide">
                  <button className="close" data-close="alert"></button>
                  <span> Enter any username and password. </span>
                </div>

                <div className="form-group">
                  <label className="control-label visible-ie8 visible-ie9">
                    Username
                  </label>
                  <input
                    className="form-control form-control-solid placeholder-no-fix"
                    autoFocus={true}
                    type="text"
                    autoComplete="off"
                    placeholder="Email"
                    ref="username"
                    required
                  />{" "}
                </div>

                <div className="form-group">
                  <label className="control-label visible-ie8 visible-ie9">
                    Password
                  </label>
                  <input
                    className="form-control form-control-solid placeholder-no-fix"
                    onKeyPress={this.handleKeyPress}
                    type="password"
                    autoComplete="off"
                    placeholder="Password"
                    ref="password"
                    required
                  />{" "}
                </div>
                <span style={this.state.errorMsg === true ? msgNone : msgShow}>
                  Invalid Username or Password
                </span>
                <div className="form-actions">
                  <button
                    type="submit"
                    className="btn green uppercase green-meadow"
                  >
                    Login
                  </button>
                  {/* <label className="rememberme check">
                                <input type="checkbox" name="remember" value="1" />Remember </label>
                            <a href="" id="forget-password" className="forget-password" onClick={this.handleSave}>Forgot Password?</a> */}
                </div>

                {/* <div className="create-account">
                            <p>
                                <a href="" id="register-btns" className="uppercase">Create an account</a>
                            </p>
                        </div> */}
              </form>
            </div>
            <div className="copyright"> 2018 © Bridge18 </div>
          </div>
        </div>
      );
    } else if (this.state.mode === "forgotView") {
      return (
        <div className="login-holder">
          <div className="login">
            <div className="logo">
              <a href=""> Bridge18 </a>
            </div>

            <div className="content">
              <form className="forget-form" style={{ display: "block" }}>
                {/* <h3 className="green-meadow"  > Forget Password ?</h3> */}
                <p> Enter your e-mail address below to reset your password. </p>
                <div className="form-group">
                  <input
                    className="form-control placeholder-no-fix"
                    type="text"
                    autoComplete="off"
                    placeholder="Email"
                    name="email"
                  />{" "}
                </div>
                <div className="form-actions">
                  <button
                    type="button"
                    id="back-btn"
                    className="btn btn-default"
                    onClick={this.backhandle.bind(this)}
                  >
                    Back
                  </button>
                  <button
                    type="submit"
                    className="btn btn-success uppercase pull-right green-meadow"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
            <div className="copyright"> 2018 © Bridge18 </div>
          </div>
        </div>
      );
    }
  }
}

export default withRouter(Userlogin);
