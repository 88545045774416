/* eslint-disable */

import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import axios from 'axios';
import Sidebar from './sidebar';
import Header from './Head';
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import $ from 'jquery';
import BaseURL from './config';
import LoadTab from './LoadTab';

import TokenErrorMsg from './errorMsg';

import getEditinfo from './StartEditMode';
import finishEditinfo from './FinishEditMode';

class EditCustomer extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      saveBtnDisable: true,
      status: 1,
      errorMsg: false,
      formdata: '',
      countries: [],
      states: [],
      bstates: [],
      currcountry: '',
      Contacts: [],
      customerid: '',
      UpdateContactid: '',
      isLoading: false,
      readonly: true,
      token: '',
      fields: {},
      errors: {},
      file: {
        carrierSetup: '',
        quickPay: '',
      },
      carrierSetupload: true,
      quickPayload: true,
      CheckEditInfo: {},


    };
    this.handleChange = this.handleChange.bind(this);
    this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);
    this.submit = this.submit.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.AutoFormatContact = this.AutoFormatContact.bind(this);
  }




  onUnload = (event) => {

    event.preventDefault();
    var params;
    var http = new XMLHttpRequest();

    var url = BaseURL + 'editModeInfo/finishEditMode';

    if (this.state.CheckEditInfo.customerId)
      params = "customerId=" + this.state.customerid + "&token=" + this.state.token;
    else
      params = "customerContactId=" + this.state.UpdateContactid + "&token=" + this.state.token;

    http.open("POST", url, true);

    http.setRequestHeader("Content-type", "application/x-www-form-urlencoded");


    if (params) {
      http.send(params);
      var start = new Date().getTime();
      while (new Date().getTime() < start + 350);
    }



  }



  async componentDidMount() {
    this._isMounted = true;
    window.addEventListener("beforeunload", this.onUnload)

    const token = localStorage.getItem('Token');



    var data = {
      'id': '',
      'status': '',
      'customername': '',
      'sameAsBillingAddress': '',
      'dotNo': '',
      'mcNo': '',
      'addressid': '',
      'address': '',
      'addressLineTwo': '',
      // 'country':element.address.state.country.countryName,
      'countryid': '',
      'state': '',
      'stateid': '',
      'city': '',
      'zip': '',
      'email': '',
      'phone': '',
      'ext': '',
      'fax': '',

      'bid': '',
      'baddress': '',
      'baddressLineTwo': '',
      // 'bcountry':element.billingAddress.state.country.countryName,
      'bcountryid': '',
      'bstate': '',
      'bstateid': '',
      'bcity': '',
      'bzip': '',
      'bemail': '',
      'bphone': '',
      'bext': '',
      'bfax': '',

    };
    var arr = {
      'countries': [],
      'addressStates': [],
      'billingAddressStates': [],
      'customerContact': []

    };
    const id = this.props.match.params.id;
    if (this._isMounted) {
      await this.setState({ customerid: id, token: token });
    };

    var carrierSetup = '', quickPay = '';
    await axios.post(BaseURL + 'customer/findById', {
      'id': id,
      'token': token

    })
      .then(response => {
        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem('Token', '');
          this.props.history.push('/');
        }
        console.log(response.data);
        arr = response.data.data;

        // var element =response.data.data;
        var element = response.data.data.customer;
        var status = element.status;

        if (status === 0) {
          $("#inactiveLabel").addClass('active');
        }
        else {
          $("#activeLabel").addClass('active');
        }

        data = {
          'id': element.id,
          'status': element.status,
          'customername': element.customerName,
          'sameAsBillingAddress': element.sameAsBillingAddress,
          'dotNo': element.dotNo,
          'mcNo': element.mcNo,
          'addressid': element.address.id,
          'address': element.address.address,
          'addressLineTwo': element.address.addressLineTwo,
          // 'country':element.address.state.country.countryName,
          'countryid': element.address.state.countryId,
          'state': element.address.state.state,
          'stateid': element.address.state.id,
          'city': element.address.city,
          'zip': element.address.zip,
          'email': element.address.email,
          'phone': element.address.telephone,
          'ext': element.address.ext,
          'fax': element.address.fax,

          'bid': element.billingAddress.id,
          'baddress': element.billingAddress.address,
          'baddressLineTwo': element.billingAddress.addressLineTwo,
          // 'bcountry':element.billingAddress.state.country.countryName,
          'bcountryid': element.billingAddress.state.countryId,
          'bstate': element.billingAddress.state.state,
          'bstateid': element.billingAddress.state.id,
          'bcity': element.billingAddress.city,
          'bzip': element.billingAddress.zip,
          'bemail': element.billingAddress.email,
          'bphone': element.billingAddress.telephone,
          'bext': element.billingAddress.ext,
          'bfax': element.billingAddress.fax,

        };

        response.data.data.customerDocuments.forEach(element => {

          var filename = element.documentName;
          if (filename.length > 15) {
            filename = filename.substring(0, 15) + '....'

          }

          if (Number(element.documentType) === 1) {
            carrierSetup = { 'name': filename };

          }
          else if (Number(element.documentType) === 2) {
            quickPay = { 'name': filename };

          }

        });







      }).catch(function (error) {
        NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);
        console.log(error);
      })




    if (this._isMounted) {
      await this.setState({
        formdata: data,
        status: data.status,
        countries: arr.countries,
        states: arr.addressStates,
        bstates: arr.billingAddressStates,
        Contacts: arr.customerContact,
        file: {
          carrierSetup: carrierSetup,
          quickPay: quickPay,
        },

      });

      if (data.sameAsBillingAddress === 1) {
        document.getElementById('sameBillAddress').className = 'checked';
        if (this._isMounted) {
          await this.setState({ errorMsg: true });
        }
      }

    }








    // var arr,contact;
    // var fetch=false;
    // await axios.post(BaseURL+'countries',{ 'token':token})
    //         .then(function (response) {

    //           arr = response.data.data;
    //           fetch=true;
    //         })

    //         if(fetch){

    //          this.setState({countries:arr});

    //         }

    // var states
    // await axios.post(BaseURL+'countries/states',{
    //   "countryId": data.countryid,
    //   'token':token
    // })
    //         .then(function (response) {

    //          states = response.data.data;

    //         })

    // this.setState({states:states});
    //  var barr
    //  await axios.post(BaseURL+'countries/states',{
    // "countryId":  data.bcountryid,
    // 'token':token
    //   })
    //       .then(function (response) {

    //         barr = response.data.data;

    //       })

    //  this.setState({bstates:barr});






    // await axios.post(BaseURL+'customerContact/findByCustomerId', {
    //   "customerId" :id,
    //   'token':token
    // })
    // .then(function (response) {
    //   console.log(response.data)
    //   contact=response.data.data;

    // })


    //  await this.setState({Contacts:contact});



  }


  async componentWillUnmount() {

    this._isMounted = false;
    window.removeEventListener("beforeunload", this.onUnload);
    var config = this.state.CheckEditInfo;


    if (config.token) {
      finishEditinfo(config, (res) => {

      }, (err) => {

        console.log(err)
      });

    }
  }

  finishEditinfo(config, callback, errorcallback) {
    debugger
    axios.post(BaseURL + 'editModeInfo/finishEditMode', config)
      .then(res => {
        //do something
        if (callback !== null) {
          callback(res);

        }
      })
      .catch(err => {
        // catch error
        if (errorcallback !== null) {
          errorcallback(err);

        }
      })
  }

  AutoFormatContact(e) {



    function phone_formatting(ele, restore) {
      var new_number,
        // selection_start = ele.selectionStart,
        // selection_end = ele.selectionEnd,
        number = ele.value.replace(/\D/g, '');

      // automatically add dashes
      if (number.length > 2) {
        // matches: 123 || 123-4 || 123-45
        new_number = number.substring(0, 3) + '-';
        if (number.length === 4 || number.length === 5) {
          // matches: 123-4 || 123-45
          new_number += number.substr(3);
        }
        else if (number.length > 5) {
          // matches: 123-456 || 123-456-7 || 123-456-789
          new_number += number.substring(3, 6) + '-';
        }
        if (number.length > 6) {
          // matches: 123-456-7 || 123-456-789 || 123-456-7890
          new_number += number.substring(6);
        }
      }
      else {
        new_number = number;
      }

      // if value is heigher than 12, last number is dropped
      // if inserting a number before the last character, numbers
      // are shifted right, only 12 characters will show
      ele.value = (new_number.length > 12) ? new_number.substring(0, 12) : new_number;

      // restore cursor selection,
      // prevent it from going to the end
      // UNLESS
      // cursor was at the end AND a dash was added


      // if (new_number.slice(-1) === '-' && restore === false
      //     && (new_number.length === 8 && selection_end === 7)
      //         || (new_number.length === 4 && selection_end === 3)) {
      //     selection_start = new_number.length;
      //     selection_end = new_number.length;
      // }
      // else if (restore === 'revert') {
      //   selection_start--;
      //   selection_end--;
      // }
      // ele.setSelectionRange(selection_start, selection_end);

    }

    function phone_number_check(field, e) {
      var key_code = e.keyCode,
        key_string = String.fromCharCode(key_code),
        press_delete = false,
        dash_key = 189,
        delete_key = [8, 46],
        direction_key = [33, 34, 35, 36, 37, 38, 39, 40],
        selection_end = field.selectionEnd;

      // delete key was pressed
      if (delete_key.indexOf(key_code) > -1) {
        press_delete = true;
      }

      // only force formatting is a number or delete key was pressed
      if (key_string.match(/^\d+$/) || press_delete) {
        phone_formatting(field, press_delete);
      }
      // do nothing for direction keys, keep their default actions
      else if (direction_key.indexOf(key_code) > -1) {
        // do nothing
      }
      else if (dash_key === key_code) {
        if (selection_end === field.value.length) {
          field.value = field.value.slice(0, -1)
        }
        else {
          field.value = field.value.substring(0, (selection_end - 1)) + field.value.substr(selection_end)
          field.selectionEnd = selection_end - 1;
        }
      }
      // all other non numerical key presses, remove their value
      else {
        e.preventDefault();
        //    field.value = field.value.replace(/[^0-9\-]/g,'')
        phone_formatting(field, 'revert');
      }

    }

    const Validate = (e) => {
      if (!String.fromCharCode(e.keyCode).match(/^- $/)) {
        this.handleChange(e)
      }
    }

    document.getElementById(e.target.id).onkeyup = function (e) {
      phone_number_check(this, e);
      Validate(e)
    }
  }


  async handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    if (this._isMounted) {
      await this.setState({
        fields
      });
    }
    this.validateForm();
  }

  submituserRegistrationForm(e) {
    e.preventDefault();
    var send = false;
    if (this.validateAndSubmit() && this.validateForm()) {

      send = true;

    }
    if (send) {

      this.submit();
    }

  }

  validateAndSubmit() {

    let errors = {};
    let formIsValid = true;



    // CustomerNAme
    if (!this.refs.customerName.value || !this.refs.customerName.value.trim().length > 0) {
      formIsValid = false;
      errors["customerName"] = "*Please Enter Customer Name";
      $("[name='customerName']").focus();

    }

    else if (!this.refs.mc.value || !this.refs.mc.value.trim().length > 0) {
      formIsValid = false;
      errors["mcNo"] = "*Please Enter M.C.#";
      $("[name='mcNo']").focus();

    }

    // Address
    else if (!this.refs.address.value || !this.refs.address.value.trim().length > 0) {
      formIsValid = false;
      errors["address"] = "*Please Enter Address";
      $("[name='address']").focus();

    }


    // Country
    else if (!this.refs.country_id.value) {
      formIsValid = false;
      errors["country"] = "*Please Select Country";
      $("[name='country']").focus();

    }

    // State
    else if (!this.refs.province_id.value) {
      formIsValid = false;
      errors["state"] = "*Please Select State";
      $("[name='state']").focus();

    }

    // City
    else if (!this.refs.city.value || !this.refs.city.value.trim().length > 0) {
      formIsValid = false;
      errors["city"] = "*Please Enter City";
      $("[name='city']").focus();

    }

    // Zip
    else if (!this.refs.zip.value || !this.refs.zip.value.trim().length > 0) {
      formIsValid = false;
      errors["zip"] = "*Please Enter Zip";
      $("[name='zip']").focus();

    }




    // BillingAddress
    else if (!this.refs.billing.value || !this.refs.billing.value.trim().length > 0) {
      formIsValid = false;
      errors["billingAddress"] = "*Please Enter Billing Address";
      $("[name='billingAddress']").focus();

    }


    // Country
    else if (!this.refs.billing_country_id.value) {
      formIsValid = false;
      errors["billingAddressCountry"] = "*Please Select Country";
      $("[name='billingAddressCountry']").focus();

    }

    // State
    else if (!this.refs.billing_province_id.value) {
      formIsValid = false;
      errors["billingAddressState"] = "*Please Select State";
      $("[name='billingAddressState']").focus();

    }

    // City
    else if (!this.refs.b_city.value || !this.refs.b_city.value.trim().length > 0) {
      formIsValid = false;
      errors["billingAddressCity"] = "*Please Enter City";
      $("[name='billingAddressCity']").focus();

    }

    // Zip
    else if (!this.refs.b_zip.value || !this.refs.b_zip.value.trim().length > 0) {
      formIsValid = false;
      errors["billingAddressZip"] = "*Please Enter Zip";
      $("[name='billingAddressZip']").focus();

    }
    if (this._isMounted) {
      this.setState({
        errors: errors
      });
    }
    return formIsValid;
  }


  validateForm() {

    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;


    // customerName
    if (typeof fields["customerName"] !== "undefined" && fields["customerName"]) {
      if (!fields["customerName"].length > 200) {
        formIsValid = false;
        errors["customerName"] = "*Only Alphabets Allowed (Max Range: 200)";
      }
    }

    //DotNo
    if (typeof fields["dotNo"] !== "undefined" && fields["dotNo"]) {
      if (!fields["dotNo"].match(/^[a-zA-Z0-9#'.,-/ ]{1,200}$/)) {
        formIsValid = false;
        errors["dotNo"] = "*Only Alpha-Numeric Allowed (Max Range: 200)";
      }
    }


    //mcNo

    if (typeof fields["mcNo"] !== "undefined" && fields["mcNo"]) {
      if (!fields["mcNo"].match(/^[a-zA-Z0-9#.,-/ ]{1,200}$/)) {
        formIsValid = false;
        errors["mcNo"] = "*Only Alpha-Numeric Allowed (Max Range: 200)";
      }
      else if ((!this.state.readonly) && (fields["mcNo"].trim().length > 0) && (fields["mcNo"].trim().length < 200)) {

        errors['mcNo'] = '';
        this.setState({ errors });

        axios.post(BaseURL + 'customer/findByMcNo', {
          // "id": this.state.loadid,
          "mcNo": fields["mcNo"].trim(),
          'token': this.state.token
        }).then(response => {
          if (response.data.message === "fail") {
            this.state.saveBtnDisable = false;
          }
          if (response.data.message === "success") {
            this.state.saveBtnDisable = true;
          }
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem('Token', '');
            this.props.history.push('/');
          }


          console.log("Load resonse ...:", response.data)


          // response.data.error ? errors['mcInternalload'] = '*Record Already Exist With this M.C No' : errors['mcInternalload'] = '';
          response.data.error ? errors['mcInternalload'] = '*Customer with same MC# exists and is active, Please InActive of existing customer' : errors['mcInternalload'] = '';

          this.setState({ errors })



        }).catch(function (error) {
          NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);
          console.log(error);
        })

      }
      else {
        errors['mcNo'] = '';
        errors['mcInternalload'] = '';
        this.setState({ errors })
      }

    }
    //address

    if (typeof fields["address"] !== "undefined" && fields["address"]) {
      if (!fields["address"].match(/^[a-zA-Z0-9#'"،.,-;/ ]{1,200}$/)) {
        formIsValid = false;
        errors["address"] = "*Please Enter Only Valid Characters (Max Range: 200)";
      }
    }



    //addressLineTwo

    if (typeof fields["addressLineTwo"] !== "undefined" && fields["addressLineTwo"]) {
      if (!fields["addressLineTwo"].match(/^[a-zA-Z0-9#'"،.,-;/ ]{1,200}$/)) {
        formIsValid = false;
        errors["addressLineTwo"] = "*Please Enter Only Valid Characters (Max Range: 200)";
      }
    }

    //city

    if (typeof fields["city"] !== "undefined" && fields["city"]) {
      if (!fields["city"].match(/^[a-zA-Z0-9#., ]{1,200}$/)) {
        formIsValid = false;
        errors["city"] = "*Please Enter Only Valid Characters (Max Range: 200)";
      }
    }

    //zip

    if (typeof fields["zip"] !== "undefined" && fields["zip"]) {
      if (!fields["zip"].match(/^[a-zA-Z0-9-_ ]{1,35}$/)) {
        formIsValid = false;
        errors["zip"] = "*Only Numbers Allowed in Zip (Max Range: 35)";
      }
    }

    //telephone

    if (typeof fields["telephone"] !== "undefined" && fields["telephone"]) {
      if (!fields["telephone"].match(/^[0-9- ]{12,30}$/)) {
        formIsValid = false;
        errors["telephone"] = "*Please Enter only valid Telephone Number ( Range: 10)";
      }
    }

    //Ext

    if (typeof fields["ext"] !== "undefined" && fields["ext"]) {
      if (!fields["ext"].match(/^[0-9]{1,20}$/)) {
        formIsValid = false;
        errors["ext"] = "*Only Numbers Allowed (Max Range: 20)";
      }
    }
    //Fax

    if (typeof fields["fax"] !== "undefined" && fields["fax"]) {
      if (!fields["fax"].match(/^[0-9- ]{12,30}$/)) {
        formIsValid = false;
        errors["fax"] = "*Please Enter only valid fax Number ( Range: 10)";
      }
    }

    //email

    if (typeof fields["email"] !== "undefined" && fields["email"]) {
      if (!fields["email"].match(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/)) {
        formIsValid = false;
        errors["email"] = "*Please Enter Valid Email (Max Range: 100)";
      }
    }




    // Billing Address Validations

    //address

    if (typeof this.refs.billing.value !== "undefined" && this.refs.billing.value) {
      if (!this.refs.billing.value.match(/^[a-zA-Z0-9#'"،.,-;/ ]{1,200}$/)) {
        formIsValid = false;
        errors["billingAddress"] = "*Please Enter Only Valid Characters (Max Range: 200)";
      }
    }



    //addressLineTwo

    if (typeof this.refs.billing2.value !== "undefined" && this.refs.billing2.value) {
      if (!this.refs.billing2.value.match(/^[a-zA-Z0-9#'"،.,-;/ ]{1,200}$/)) {
        formIsValid = false;
        errors["billingAddressLineTwo"] = "*Please Enter Only Valid Characters (Max Range: 200)";
      }
    }

    //city

    if (typeof this.refs.b_city.value !== "undefined" && this.refs.b_city.value) {
      if (!this.refs.b_city.value.match(/^[a-zA-Z0-9#., ]{1,200}$/)) {
        formIsValid = false;
        errors["billingAddressCity"] = "*Please Enter Only Valid Characters (Max Range: 200)";
      }
    }

    //zip

    if (typeof this.refs.b_zip.value !== "undefined" && this.refs.b_zip.value) {
      if (!this.refs.b_zip.value.match(/^[a-zA-Z0-9-_ ]{1,35}$/)) {
        formIsValid = false;
        errors["billingAddressZip"] = "*Only Numbers Allowed (Max Range: 35)";
      }
    }

    //telephone

    if (typeof this.refs.b_telephone.value !== "undefined" && this.refs.b_telephone.value) {
      if (!this.refs.b_telephone.value.match(/^[0-9- ]{12,30}$/)) {
        formIsValid = false;
        errors["billingAddressTelephone"] = "*Please Enter only valid Telephone Number ( Range: 10)";
      }
    }

    //Ext

    if (typeof this.refs.b_ext.value !== "undefined" && this.refs.b_ext.value) {
      if (!this.refs.b_ext.value.match(/^[0-9]{1,20}$/)) {
        formIsValid = false;
        errors["billingAddressExt"] = "*Only Numbers Allowed (Max Range: 20)";
      }
    }
    //Fax

    if (typeof this.refs.b_fax.value !== "undefined" && this.refs.b_fax.value) {
      if (!this.refs.b_fax.value.match(/^[0-9- ]{12,30}$/)) {
        formIsValid = false;
        errors["billingAddressFax"] = "*Please Enter only valid fax Number ( Range: 10)";
      }
    }

    //email

    if (typeof this.refs.b_email.value !== "undefined" && this.refs.b_email.value) {
      if (!this.refs.b_email.value.match(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/)) {
        formIsValid = false;
        errors["billingAddressEmail"] = "*Please Enter Valid Email (Max Range: 100)";
      }
    }
    if (this._isMounted) {
      this.setState({
        errors: errors
      });
    }
    return formIsValid;

  }


  async states() {
    var arr;
    var data = false;

    var formData = this.state.formdata;
    formData['countryid'] = this.refs.country_id.value;
    formData['stateid'] = 1;
    if (this._isMounted) {
      this.setState({ formdata: formData });
    }

    await axios.post(BaseURL + 'countries/states', {
      "countryId": this.refs.country_id.value,
      'token': this.state.token
    })
      .then(response => {
        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem('Token', '');
          this.props.history.push('/');
        }
        arr = response.data.data;
        data = true;
      }).catch(function (error) {
        NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);
        console.log(error);
      })
    if (data && this._isMounted) {
      this.setState({ states: arr });
    }

  }

  async bstates() {
    var arr;
    var data = false;

    var formData = this.state.formdata;
    formData['bcountryid'] = this.refs.billing_country_id.value;
    formData['bstateid'] = 1;

    if (this._isMounted) {
      this.setState({ formdata: formData });
    }

    await axios.post(BaseURL + 'countries/states', {
      "countryId": this.refs.billing_country_id.value,
      'token': this.state.token
    })
      .then(response => {
        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem('Token', '');
          this.props.history.push('/');
        }
        arr = response.data.data;
        data = true;
      }).catch(function (error) {
        NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);
        console.log(error);
      })
    if (data && this._isMounted) {
      this.setState({ bstates: arr });
    }

  }

  async billaddress() {


    if (this.state.errorMsg === false) {
      document.getElementById('sameBillAddress').className = 'checked';

      if (this._isMounted) {
        await this.setState({ errorMsg: true });
      }
      this.state.formdata.sameAsBillingAddress = true;
      var arr;
      await axios.post(BaseURL + 'countries/states', {
        "countryId": this.refs.country_id.value,
        'token': this.state.token
      })
        .then(response => {
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem('Token', '');
            this.props.history.push('/');
          }

          arr = response.data.data;

        }).catch(function (error) {
          NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);
          console.log(error);
        })

      if (this._isMounted) {
        await this.setState({ bstates: arr });
      }
      this.refs.billing.value = this.refs.address.value;
      this.refs.billing2.value = this.refs.address2.value;
      this.refs.billing_country_id.value = this.refs.country_id.value;
      this.refs.billing_province_id.value = this.refs.province_id.value;
      this.refs.b_city.value = this.refs.city.value;
      this.refs.b_zip.value = this.refs.zip.value;
      this.refs.b_email.value = this.refs.email.value;
      this.refs.b_telephone.value = this.refs.telephone.value;
      this.refs.b_fax.value = this.refs.fax.value;
      this.refs.b_ext.value = this.refs.ext.value;


    }
    else {

      document.getElementById('sameBillAddress').className = '';
      this.state.formdata.bstateid = '';
      this.state.formdata.bstate = '';
      this.refs.billing.value = '';
      this.refs.billing2.value = '';
      this.refs.billing_country_id.value = '';
      this.refs.billing_province_id.value = '';
      this.refs.b_city.value = '';
      this.refs.b_zip.value = '';
      this.refs.b_email.value = '';
      this.refs.b_telephone.value = '';
      this.refs.b_fax.value = '';
      this.refs.b_ext.value = '';
      this.state.formdata.sameAsBillingAddress = false;
      if (this._isMounted) {
        await this.setState({ errorMsg: false })
      }
    };


  }

  cancel() {

    if (window.confirm(' Are you sure you want to cancel ? '))
      this.props.history.push('/dashboard');

  }

  async submit(event) {


    if (this._isMounted) {
      this.setState({ readonly: true });
    }
    const customerName = this.refs.customerName.value.trim();
    const dot_number = this.refs.dot_number.value.trim();
    const mc = this.refs.mc.value.trim();
    const status = this.state.status;
    const address = this.refs.address.value.trim();
    const address2 = this.refs.address2.value.trim();
    const country_id = this.refs.country_id.value.trim();
    const province_id = this.refs.province_id.value.trim();
    const city = this.refs.city.value.trim();
    const zip = this.refs.zip.value.trim();
    const email = this.refs.email.value.trim();
    const telephone = this.refs.telephone.value.trim();
    const fax = this.refs.fax.value.trim();
    const ext = this.refs.ext.value.trim();

    const billing = this.refs.billing.value.trim();
    const billing2 = this.refs.billing2.value.trim();
    const billing_country_id = this.refs.billing_country_id.value.trim();
    const billing_province_id = this.refs.billing_province_id.value.trim();
    const b_city = this.refs.b_city.value.trim();
    const b_zip = this.refs.b_zip.value.trim();
    const b_email = this.refs.b_email.value.trim();
    const b_telephone = this.refs.b_telephone.value.trim();
    const b_fax = this.refs.b_fax.value.trim();
    const b_ext = this.refs.b_ext.value.trim();

    if (this.state.errorMsg === false) {

      var customer = {
        'token': this.state.token,
        "id": this.state.formdata.id,
        "customerName": customerName,
        "sameAsBillingAddress": 0,
        "dotNo": dot_number,
        "mcNo": mc,
        "status": status,
        "address": {
          "id": this.state.formdata.addressid,
          "address": address,
          "addressLineTwo": address2,
          "city": city,
          "zip": zip,
          "email": email,
          "telephone": telephone,
          "ext": ext,
          "fax": fax,
          "state": {
            "id": province_id,
            "country": {
              "id": country_id

            }
          }
        },
        "billingAddress": {
          "id": this.state.formdata.bid,
          "address": billing,
          "addressLineTwo": billing2,
          "city": b_city,
          "zip": b_zip,
          "email": b_email,
          "telephone": b_telephone,
          "ext": b_ext,
          "fax": b_fax,
          "state": {
            "id": billing_province_id,
            "country": {
              "id": billing_country_id
            }
          }
        }
      }



      await axios.post(BaseURL + 'customer/update', customer)
        .then(response => {
          console.log(response)
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem('Token', '');
            this.props.history.push('/');
          }
          else if (response.data.status === 1) {
            NotificationManager.warning(response.data.message, 'warning', 3000);
            return;
          }
          else if (response.data.status === 0) {
            NotificationManager.warning(response.data.message, 'warning', 3000);
            return;
          }
          if (response.data.message == "exist") {
            $("#inactiveLabel").addClass("active");
            $("#activeLabel").removeClass("active");
            this.setState({ status: 0 })
            NotificationManager.error(
              "Customer with same MC# exists and is active, Please InActive of existing customer",
              "Error Message",
              3000
            );
          } else {
            NotificationManager.success('Customer Edited Successfully with New Billing Address', 'Edited', 3000);
          }
          console.log(response);
        }).catch(function (error) {
          NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);
          console.log(error);
        })

    }

    else {

      var bcustomer = {
        'token': this.state.token,
        "id": this.state.formdata.id,
        "customerName": customerName,
        "sameAsBillingAddress": 1,
        "dotNo": dot_number,
        "mcNo": mc,
        "status": status,
        "address": {
          "id": this.state.formdata.addressid,
          "address": address,
          "addressLineTwo": address2,
          "city": city,
          "zip": zip,
          "email": email,
          "telephone": telephone,
          "ext": ext,
          "fax": fax,
          "state": {
            "id": province_id,
            "country": {
              "id": country_id

            }
          }
        },
        "billingAddress": {
          "id": this.state.formdata.bid,
          "address": billing,
          "addressLineTwo": billing2,
          "city": b_city,
          "zip": b_zip,
          "email": b_email,
          "telephone": b_telephone,
          "ext": b_ext,
          "fax": b_fax,
          "state": {
            "id": billing_province_id,
            "country": {
              "id": billing_country_id
            }
          }
        }
      }



      await axios.post(BaseURL + 'customer/update', bcustomer)
        .then(response => {
          console.log(response)

          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem('Token', '');
            this.props.history.push('/');
          }
          else if (response.data.status === 1) {
            NotificationManager.warning(response.data.message, 'warning', 3000);
            return;
          }
          else if (response.data.status === 0) {
            NotificationManager.warning(response.data.message, 'warning', 3000);
            return;
          }
          if (response.data.message == "exist") {
            $("#inactiveLabel").addClass("active");
            $("#activeLabel").removeClass("active");
            this.setState({ status: 0 })
            NotificationManager.error(
              "Customer with same MC# exists and is active, Please InActive of existing customer",
              "Error Message",
              3000
            );
          } else {
            NotificationManager.success('Customer Infomation Edited Successfully', 'Edited', 3000);
          }
        }).catch(function (error) {
          NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);
          console.log(error);
        })

    }

  }


  async EditContact(e) {

    var a = document.getElementById(e.id);
    a.setAttribute("style", " cursor: not-allowed; pointer-events: none")


    var config = {
      "customerContactId": e.id,
      "token": this.state.token
    }

    getEditinfo(config, (res) => {
      console.log(res);
      if (res.data.status === 0) {

        this.refs.ContactName.value = e.name;
        this.refs.ContactTitle.value = e.title;
        this.refs.ContactEmail.value = e.email;
        this.refs.ContactTelephone.value = e.telephone;
        this.refs.ContactExt.value = e.ext;
        this.refs.ContactPhone.value = e.cellphone;
        this.refs.ContactFax.value = e.fax;




        a.href = "#contact"
        a.click();

        if (this._isMounted) {
          this.setState({
            UpdateContactid: e.id,
            CheckEditInfo: config
          });
        }
        a.removeAttribute("href");
        a.removeAttribute("style");

      }
      else {

        a.removeAttribute("style");
        NotificationManager.warning(res.data.message, 'Warning Message', 3000);
      }

    }, (err) => {

      console.log(err)
    });



  }


  async SaveContact(event) {

    if (this._isMounted) {
      this.setState({
        isLoading: true
      });
    }
    event.preventDefault();
    $('#closePopUp').click();
    var custid = this.state.customerid;

    if (this.state.UpdateContactid) {

      var CustomerContact = {
        'token': this.state.token,
        "id": this.state.UpdateContactid,
        "name": this.refs.ContactName.value.trim(),
        "title": this.refs.ContactTitle.value.trim(),
        "email": this.refs.ContactEmail.value.trim(),
        "telephone": this.refs.ContactTelephone.value.trim(),
        "ext": this.refs.ContactExt.value.trim(),
        "cellphone": this.refs.ContactPhone.value.trim(),
        "fax": this.refs.ContactFax.value.trim()
      }

      await axios.post(BaseURL + 'customerContact/update', CustomerContact)
        .then(response => {
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem('Token', '');
            this.props.history.push('/');
          }
          console.log(response.data);
          NotificationManager.success('Customer Contact Infomation Edit Successfully', 'Customer Info', 3000);
        }).catch(function (error) {
          NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);
          console.log(error);
        })
      if (this._isMounted) {
        this.setState({ UpdateContactid: '', isLoading: false });
      }



    }
    else {

      var CustomerContactUpdate = {
        'token': this.state.token,
        "customerId": custid,
        "name": this.refs.ContactName.value.trim(),
        "title": this.refs.ContactTitle.value.trim(),
        "email": this.refs.ContactEmail.value.trim(),
        "telephone": this.refs.ContactTelephone.value.trim(),
        "ext": this.refs.ContactExt.value.trim(),
        "cellphone": this.refs.ContactPhone.value.trim(),
        "fax": this.refs.ContactFax.value.trim()
      }

      await axios.post(BaseURL + 'customerContact/save', CustomerContactUpdate)
        .then(response => {
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem('Token', '');
            this.props.history.push('/');
          }
          console.log(response.data);
          NotificationManager.success('Customer Contact Infomation Saved', 'Customer Info', 3000);

        }).catch(function (error) {
          NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);
          console.log(error);
        })
      if (this._isMounted) {
        this.setState({ isLoading: false });
      }
    }


    var contact;

    await axios.post(BaseURL + 'customerContact/findByCustomerId', {
      "customerId": custid,
      'token': this.state.token
    })
      .then(response => {
        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem('Token', '');
          this.props.history.push('/');
        }
        contact = response.data.data;

      }).catch(function (error) {
        NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);
        console.log(error);
      })

    if (this._isMounted) {
      this.setState({ Contacts: contact });
    }




  }

  async DelContact(e) {


    if (!window.confirm("Are you sure you want to delete Contact?")) {
      return false;
    }
    if (this._isMounted) {
      this.setState({
        isLoading: true
      });
    }
    var arr;

    await axios.post(BaseURL + 'customerContact/delete', {
      "id": e.id,
      'token': this.state.token
    })
      .then(response => {
        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem('Token', '');
          this.props.history.push('/');
        }
        console.log(response.data)
        NotificationManager.success(response.data.message, 'Customer Info', 3000);
      }).catch(function (error) {
        NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);
        console.log(error);
      })

    if (this._isMounted) {
      this.setState({
        isLoading: false
      });
    }


    await axios.post(BaseURL + 'customerContact/findByCustomerId', {
      "customerId": this.state.customerid,
      'token': this.state.token
    })
      .then(response => {
        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem('Token', '');
          this.props.history.push('/');
        }
        arr = response.data.data;
      }).catch(function (error) {
        NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);
        console.log(error);
      })
    if (this._isMounted) {
      this.setState({ Contacts: arr });
    }
  }
  async ClearContact() {
    if (this._isMounted) {
      await this.setState({
        UpdateContactid: '',

      });
    }

    this.refs.ContactName.value = '';
    this.refs.ContactTitle.value = '';
    this.refs.ContactEmail.value = '';
    this.refs.ContactTelephone.value = '';
    this.refs.ContactExt.value = '';
    this.refs.ContactPhone.value = '';
    this.refs.ContactFax.value = '';

  }

  async status(event) {
    if (this._isMounted) {
      await this.setState({ status: 0 });
    }

  }
  async Actstatus(event) {
    if (this._isMounted) {
      await this.setState({ status: 1 });
    }
  }

  EditForm() {

    var div = document.getElementById('EditDiv');
    div.setAttribute("style", " cursor: not-allowed; pointer-events: none")

    var config = {
      "customerId": this.state.customerid,
      "customerContactId": null,
      "carrierId": null,
      "carrierContactId": null,
      "insuranceLiabilityId": null,
      "carrierDocumentsId": null,
      "bankDetailId": null,
      "insuranceAgentId": null,
      "insuranceAgentContactId": null,
      "driverId": null,
      "driverDocumentId": null,
      "truckId": null,
      "truckDocumentId": null,
      "trailerId": null,
      "trailerDocumentId": null,
      "usersManagementId": null,
      "token": this.state.token
    }

    getEditinfo(config, (res) => {
      console.log(res);
      if (res.data.status === 0) {
        if (this._isMounted) {
          this.setState({
            readonly: false,
            CheckEditInfo: config
          });
        }


      }
      else {
        div.removeAttribute("style")
        NotificationManager.warning(res.data.message, 'Warning Message', 3000);

      }

    }, (err) => {

      console.log(err)
    });

  }

  FinishEditInfo() {
    var config = this.state.CheckEditInfo;
    if (config.token) {

      finishEditinfo(config, (res) => {
        console.log(res);
        if (this._isMounted) {
          this.setState({
            CheckEditInfo: {},
            readonly: true
          });
        }

      }, (err) => {

        console.log(err)
      });

    }

  }

  selectState(e) {

    e.preventDefault();
    var formData = this.state.formdata;

    if (e.target.name === 'state' && this._isMounted) {
      formData['stateid'] = this.refs.province_id.value;
      this.setState({ formdata: formData });
    }
    else if (e.target.name === 'billingAddressState' && this._isMounted) {
      formData['bstateid'] = this.refs.billing_province_id.value;
      this.setState({ formdata: formData });
    }




  }



  onFileChange = async (event) => {

    let errors = this.state.errors;

    event.preventDefault();
    event.persist();
    var file = await event.target.files[0];

    function getFileExtension(filename) {
      return filename.split('.').pop();
    }

    if (event.target.files[0]) {

      var filename = { 'name': file.name };


      if (file.type !== 'application/pdf' && file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/gif' && file.type !== 'image/tif' && getFileExtension(file.name) !== 'docx' && getFileExtension(file.name) !== 'doc' && getFileExtension(file.name) !== 'odt' && getFileExtension(file.name) !== 'xls' && getFileExtension(file.name) !== 'xlsx' && getFileExtension(file.name) !== 'ppt' && getFileExtension(file.name) !== 'pptx') {
        errors[event.target.name] = 'Please Upload only PDF ,Images, Document files ';
        if (this._isMounted) {
          this.setState({ errors: errors })
        }
        return false;
      }
      else if (file.size > 1000000) {
        errors[event.target.name] = 'Please Upload A File Less Than 1MB ( Max size for file: 1MB ) ';
        if (this._isMounted) {
          this.setState({ errors: errors })
        }
        return false;
      }
      else {
        errors[event.target.name] = '';
        if (this._isMounted) {
          this.setState({ errors: errors })
        }
      }

      if (filename.name.length > 15) {
        filename = {
          'name': filename.name.substring(0, 15) + '....'
        }
      }
      console.log(file);

      var doctype = event.target.id;
      var id = event.target.id;
      console.log(id);





      if (Number(id) === 1) {
        if (this._isMounted) {
          this.setState({
            carrierSetupload: false
          });
        }

      }
      else if (Number(id) === 2) {
        if (this._isMounted) {
          this.setState({
            quickPayload: false
          });
        }

      }



      console.log('enter in upload file');
      let data = new FormData();
      await data.append('file', file);
      await data.append('fileName', file.name);
      await data.append("customerId", this.state.customerid);
      await data.append("type", file.type);
      await data.append("documentType", doctype);



      await axios.post(BaseURL + 'customerDocuments/uploadFileToS3', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'token': this.state.token
        }
      })
        .then(response => {
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem('Token', '');
            this.props.history.push('/');
          }
          console.log(response.data)
          NotificationManager.success('Document Uploaded Successfully', 'Upload', 3000);

        }).catch(function (error) {
          NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);
          console.log(error);
        })

      if (Number(id) === 1) {
        if (this._isMounted) {
          await this.setState({
            carrierSetupload: true,
            file: {
              carrierSetup: filename,
              quickPay: this.state.file.quickPay,
            }
          });
        }

      }
      else if (Number(id) === 2) {

        if (this._isMounted) {
          await this.setState({
            quickPayload: true,
            file: {
              carrierSetup: this.state.file.carrierSetup,
              quickPay: filename,


            }
          });
        }

      }


    }

  }

  deleteFile = (e) => {
    e.preventDefault();
    e.persist();
    var doctype = e.target.id;
    var id = e.target.id;
    console.log(id);



    if (window.confirm('Are You Sure To Remove Docment ?')) {


      console.log('enter in delete file');
      axios.post(BaseURL + 'customerDocuments/deleteFile', {
        "customerId": this.state.customerid,
        "documentType": doctype,
        'token': this.state.token

      })
        .then(response => {
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem('Token', '');
            this.props.history.push('/');
          }
          NotificationManager.success('Document Deleted Successfully', 'Delete', 3000);
          console.log(response.data)

        }).catch(function (error) {
          NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);
          console.log(error);
        })

      if (Number(id) === 1) {
        if (this._isMounted) {
          this.setState({

            file: {
              carrierSetup: '',
              quickPay: this.state.file.quickPay,
            }
          });
        }

      }
      else if (Number(id) === 2) {
        if (this._isMounted) {
          this.setState({

            file: {
              carrierSetup: this.state.file.carrierSetup,
              quickPay: '',

            }
          });
        }

      }


    }


  }


  download = async (e) => {

    e.preventDefault();
    e.persist();
    var doctype = await e.target.id;
    var id = await e.target.id;


    if (Number(id) === 1) {
      if (this._isMounted) {
        this.setState({

          carrierSetupload: false

        });
      }

    }
    else if (Number(id) === 2) {
      if (this._isMounted) {
        this.setState({
          quickPayload: false
        });
      }

    }







    console.log(doctype);
    console.log('enter in download file');

    await axios.post(BaseURL + 'customerDocuments/getDocuments', {
      "customerId": this.state.customerid,
      'token': this.state.token
    })
      .then(response => {
        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem('Token', '');
          this.props.history.push('/');
        }

        response.data.documents.forEach(element => {
          if (element.documentType === doctype) {
            console.log(element);
            window.open(element.documentUrl, '_blank')

          }

        });

      }).catch(function (error) {
        NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);
        console.log(error);
      })

    if (Number(id) === 1) {
      if (this._isMounted) {
        this.setState({

          carrierSetupload: true

        });
      }

    }
    else if (Number(id) === 2) {
      if (this._isMounted) {
        this.setState({
          quickPayload: true
        });
      }

    }

  }

  handleDeleteActionButton = () => {




    if (window.confirm('Are You Sure You Want To Delete ?')) {

      axios.post(BaseURL + 'customer/delete', {
        'token': this.state.token,
        "id": this.state.formdata.id,
        "addressId": this.state.formdata.addressid,
        "billingAddressId": this.state.formdata.bid
      }).then(response => {

        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem('Token', '');
          this.props.history.push('/');
        }
        else if (response.data.errorMessage) {
          NotificationManager.error(response.data.errorMessage, 'Error Message', 3000);

        }
        else if (response.data.message) {           //  NotificationManager.success(response.data.message, 'Message', 3000);

          // NotificationManager.success(response.data.message, 'Error Message', 3000);
          this.props.history.push('/dashboard')

        }

      }).catch(function (error) {
        NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);
        console.log(error);
      })

    }

  }


  render() {

    const stateid = this.state.formdata.stateid;

    const bstateid = this.state.formdata.bstateid;


    const msgNone = {
      display: 'none'
    }
    const nonclick = {
      cursor: ' notAllowed',
      pointerEvents: 'none'
    }
    const msgShow = {
      display: 'block',
    }

    const msgShowin = {
      display: 'inline-block',
      marginLeft: '10px'
    }



    return (

      <div className="page-container-bg-solid page-header-fixed page-sidebar-closed-hide-logo page-md">
        <Header title='| Customer List' />
        <div className="page-container">
          <Sidebar />
          <div className="page-content-wrapper">

            <div className="page-content">

              <div className="page-head">



              </div>



              <div className="row">
                <div className="col-md-12">
                  <div className="tabbable-line boxless  tabbable-reversed">
                    <ul className="nav nav-tabs">
                      <li className="active border-green-jungle"> <a href="#tab_0" data-toggle="tab" onClick={this.FinishEditInfo.bind(this)}> Customer </a> </li>
                      <li className="border-green-jungle"> <a href="#tab_1" data-toggle="tab" onClick={this.FinishEditInfo.bind(this)}> Contact </a> </li>
                      <li className="border-green-jungle"> <a href="#tab_2" data-toggle="tab" onClick={this.FinishEditInfo.bind(this)}> Documents </a> </li>
                      <li className="border-green-jungle"> <a href="#tab_3" data-toggle="tab" onClick={this.FinishEditInfo.bind(this)}> Load </a> </li>

                    </ul>
                    <div className="tab-content">
                      <div className="tab-pane active" id="tab_0">
                        <div className="portlet box yellow-gold">
                          <div className="portlet-title">
                            <div className="caption"> <i className="fa fa-user"></i>Update Customer </div>

                            <div className="actions" style={this.state.readonly ? {} : nonclick} id='EditDiv'>
                              <div className="btn-group" >
                                <button className="btn sbold white dark" onClick={this.EditForm.bind(this)} >
                                  <i className="fa fa-pencil"></i> Edit
                                </button>
                              </div>
                            </div>


                          </div>
                          <div className="portlet-body form">

                            <form className="horizontal-form" autoComplete="off" onSubmit={this.submituserRegistrationForm.bind(this)}>
                              <div className="form-body max_width800">
                                <div className="portlet-title" >
                                  <h2 className="h3" > Customer Info
                                    <span style={{ float: 'right', paddingLeft: ' 8px', marginTop: '6px' }} >
                                      <div className="btn-group btn-group-devided" data-toggle="buttons" style={this.state.readonly ? nonclick : msgShow}>
                                        <label className="btn btn-transparent dark btn-outline btn-circle btn-sm" id="activeLabel" disabled={this.state.readonly} onClick={this.Actstatus.bind(this)}>
                                          <input type="radio" name="options" className="toggle" id="option1" />
                                          Active</label>
                                        <label className="btn btn-transparent dark btn-outline btn-circle btn-sm" id="inactiveLabel" disabled={this.state.readonly} onClick={this.status.bind(this)}>
                                          <input type="radio" defaultValue={this.state.formdata.customername} name="options" className="toggle" id="option2" />
                                          Inactive</label>
                                      </div>
                                    </span>
                                  </h2>

                                  <div className="clearfix"></div>
                                  <hr />
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">Customer Name <span className="font-red-thunderbird" >*</span></label>
                                  <div className="col-md-9">
                                    <input type="text" ref="customerName" onChange={this.handleChange} name="customerName" className="form-control" placeholder="" defaultValue={this.state.formdata.customername} readOnly={this.state.readonly} />
                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.customerName}</div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">Dot Number</label>
                                  <div className="col-md-9">
                                    <input type="text" ref="dot_number" onChange={this.handleChange} name="dotNo" className="form-control" placeholder="" defaultValue={this.state.formdata.dotNo} readOnly={this.state.readonly} />
                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.dotNo}</div>
                                  </div>

                                </div>

                                <div className="form-group row">
                                  <label className="col-md-3 control-label">M.C. # / F.F. # <span className="font-red-thunderbird" >*</span></label>

                                  <div className="col-md-9">
                                    <input type="text" ref="mc" name='mcNo' onChange={this.handleChange} className="form-control" placeholder="" defaultValue={this.state.formdata.mcNo} readOnly={this.state.readonly} />
                                    {/* <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.mcNo}</div> */}
                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.mcNo}</div>
                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.mcInternalload}</div>
                                  </div>
                                </div>
                                <div className="portlet-title">
                                  <h2 className="h3"> Address Info </h2>
                                  <hr />
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">Address <span className="font-red-thunderbird" >*</span></label>
                                  <div className="col-md-9">
                                    <input type="text" ref="address" onChange={this.handleChange} name="address" className="form-control" placeholder="" defaultValue={this.state.formdata.address} readOnly={this.state.readonly} />
                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.address}</div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">Address Line 2</label>
                                  <div className="col-md-9">
                                    <input type="text" ref="address2" onChange={this.handleChange} name="addressLineTwo" className="form-control" placeholder="" defaultValue={this.state.formdata.addressLineTwo} readOnly={this.state.readonly} />
                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.addressLineTwo}</div>
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="col-md-3 control-label">Country <span className="font-red-thunderbird" >*</span></label>
                                  <div className="col-md-9">




                                    <select name="country" ref="country_id" className="form-control" title="Country" value={this.state.formdata.countryid} onChange={this.states.bind(this)} readOnly={this.state.readonly} disabled={this.state.readonly}>


                                      {
                                        this.state.countries.map(function (data) {

                                          //  if(this.state.formdata.countryid === data.id)
                                          //   return   <option value={data.id} key={data.id} selected> {data.countryName} </option>;
                                          return <option value={data.id} key={data.id}> {data.countryName} </option>;


                                        },
                                          this
                                        )

                                      }
                                    </select>
                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.country}</div>

                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">State <span className="font-red-thunderbird" >*</span></label>
                                  <div className="col-md-9">

                                    <select name="state" ref="province_id" className="form-control" value={stateid} onChange={this.selectState.bind(this)} readOnly={this.state.readonly} disabled={this.state.readonly}>


                                      {
                                        this.state.states.map(function (dat) {
                                          // if(stateid=== dat.id)
                                          //  return <option value={dat.id} key={dat.id} selected >{dat.state}</option>;
                                          return <option value={dat.id} key={dat.id}> {dat.state} </option>;
                                        }, this)

                                      }
                                    </select>
                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.state}</div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">City <span className="font-red-thunderbird" >*</span></label>
                                  <div className="col-md-9">
                                    <input type="text" ref="city" onChange={this.handleChange} name="city" className="form-control" placeholder="" defaultValue={this.state.formdata.city} readOnly={this.state.readonly} />
                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.city}</div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">Zip <span className="font-red-thunderbird" >*</span></label>
                                  <div className="col-md-9">
                                    <input type="text" ref="zip" onChange={this.handleChange} name="zip" className="form-control" placeholder="" defaultValue={this.state.formdata.zip} readOnly={this.state.readonly} />
                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.zip}</div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">Email</label>
                                  <div className="col-md-9">
                                    <input type="email" ref="email" onChange={this.handleChange} name="email" className="form-control" placeholder="" defaultValue={this.state.formdata.email} readOnly={this.state.readonly} />
                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.email}</div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">Telephone</label>
                                  <div className="col-md-9 form-inline">
                                    <input type="text" ref="telephone" id='Telephone' onChange={this.AutoFormatContact} name="telephone" className="form-control" placeholder="xxx-xxx-xxxx" defaultValue={this.state.formdata.phone} readOnly={this.state.readonly} />
                                    <span className="help-inline"> EXT </span>  <input type="text" name="ext" onChange={this.handleChange} ref="ext" className="form-control" placeholder="" defaultValue={this.state.formdata.ext} readOnly={this.state.readonly} />
                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.telephone}</div>
                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.ext}</div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">Fax</label>
                                  <div className="col-md-9">
                                    <input type="text" ref="fax" name="fax" id='fax' onChange={this.AutoFormatContact} className="form-control" placeholder="xxx-xxx-xxxx" defaultValue={this.state.formdata.fax} readOnly={this.state.readonly} />
                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.fax}</div>
                                  </div>
                                </div>
                                <div className="portlet-title">
                                  <h2 className="h3 checkbox-inline" style={{ padding: '0', margin: '0' }}> Billing Address  </h2>
                                  <label className="checkbox-inline">
                                    <div className="checkbox"><span>
                                      <div className="checker" >
                                        <span className='' id='sameBillAddress'>
                                          <input type="checkbox" checked={this.state.errorMsg} name="optionsRadios2" value="option1" onClick={this.billaddress.bind(this)} disabled={this.state.readonly} readOnly={this.state.readonly} />
                                        </span>
                                      </div>
                                    </span> Same as Mailing Address</div>
                                  </label>
                                  <hr />
                                </div>


                                <span>
                                  <div className="form-group row">
                                    <label className="col-md-3 control-label">Address <span className="font-red-thunderbird" >*</span></label>
                                    <div className="col-md-9">
                                      <input type="text" ref="billing" onChange={this.handleChange} name="billingAddress" className="form-control" placeholder="" defaultValue={this.state.formdata.baddress} readOnly={this.state.readonly} />
                                      <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.billingAddress}</div>
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label className="col-md-3 control-label">Address Line 2 </label>
                                    <div className="col-md-9">
                                      <input type="text" ref="billing2" onChange={this.handleChange} name="billingAddressLineTwo" className="form-control" placeholder="" defaultValue={this.state.formdata.baddressLineTwo} readOnly={this.state.readonly} />
                                      <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.billingAddressLineTwo}</div>
                                    </div>
                                  </div>

                                  <div className="form-group row">
                                    <label className="col-md-3 control-label">Country <span className="font-red-thunderbird" >*</span></label>
                                    <div className="col-md-9">
                                      <select name="billingAddressCountry" ref="billing_country_id" className="form-control" value={this.state.formdata.bcountryid} onChange={this.bstates.bind(this)} readOnly={this.state.readonly} disabled={this.state.readonly}>
                                        {
                                          this.state.countries.map(function (data) {

                                            //  if(this.state.formdata.bcountryid === data.id)
                                            //   return   <option value={data.id} key={data.id} selected> {data.countryName} </option>;
                                            return <option value={data.id} key={data.id}> {data.countryName} </option>;


                                          },
                                            this
                                          )

                                        }
                                      </select>
                                      <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.billingAddressCountry}</div>
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label className="col-md-3 control-label">State <span className="font-red-thunderbird" >*</span></label>
                                    <div className="col-md-9">
                                      <select name="billingAddressState" ref="billing_province_id" className="form-control" value={bstateid} onChange={this.selectState.bind(this)} readOnly={this.state.readonly} disabled={this.state.readonly}>


                                        {
                                          this.state.bstates.map(function (bdata) {
                                            // if(bstateid=== bdata.id)
                                            // return  <option value={bdata.id} key={bdata.id} selected >{bdata.state}</option>;
                                            return <option value={bdata.id} key={bdata.id}> {bdata.state} </option>;
                                          }, this)

                                        }
                                      </select>
                                      <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.billingAddressState}</div>
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label className="col-md-3 control-label">City <span className="font-red-thunderbird" >*</span></label>
                                    <div className="col-md-9">
                                      <input type="text" ref="b_city" onChange={this.handleChange} name="billingAddressCity" className="form-control" placeholder="" defaultValue={this.state.formdata.bcity} readOnly={this.state.readonly} />
                                      <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.billingAddressCity}</div>
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label className="col-md-3 control-label">Zip <span className="font-red-thunderbird" >*</span></label>
                                    <div className="col-md-9">
                                      <input type="text" ref="b_zip" onChange={this.handleChange} name="billingAddressZip" className="form-control" placeholder="" defaultValue={this.state.formdata.bzip} readOnly={this.state.readonly} />
                                      <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.billingAddressZip}</div>
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label className="col-md-3 control-label">Email</label>
                                    <div className="col-md-9">
                                      <input type="email" ref="b_email" onChange={this.handleChange} name="billingAddressEmail" className="form-control" placeholder="" defaultValue={this.state.formdata.bemail} readOnly={this.state.readonly} />
                                      <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.billingAddressEmail}</div>
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label className="col-md-3 control-label">Telephone</label>
                                    <div className="col-md-9 form-inline">
                                      <input type="text" ref="b_telephone" id='b_telephone' onChange={this.AutoFormatContact} name="billingAddressTelephone" className="form-control" placeholder="xxx-xxx-xxxx" defaultValue={this.state.formdata.bphone} readOnly={this.state.readonly} />
                                      <span className="help-inline"> EXT </span>  <input type="text" name="billingAddressExt" onChange={this.handleChange} ref="b_ext" className="form-control" placeholder="" defaultValue={this.state.formdata.bext} readOnly={this.state.readonly} />
                                      <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.billingAddressTelephone}</div>
                                      <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.billingAddressExt}</div>
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label className="col-md-3 control-label">Fax</label>
                                    <div className="col-md-9">
                                      <input type="text" ref="b_fax" id='b_fax' onChange={this.AutoFormatContact} name="billingAddressFax" className="form-control" placeholder="xxx-xxx-xxxx" defaultValue={this.state.formdata.bfax} readOnly={this.state.readonly} />
                                      <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.billingAddressFax}</div>
                                    </div>
                                  </div>
                                </span>
                                {/* <div className="portlet-title">
                              <h2 className="h3"> Contact Info  </h2>
                              <hr/>
                            </div>
                            <div className="form-group row">
                              <label className="col-md-3 control-label">Name</label>
                              <div className="col-md-9">
                              <input type="text" id="name" className="form-control" placeholder=""/>
                              </div>
                            </div>
    
                            <div className="form-group row">
                              <label className="col-md-3 control-label">Title</label>
                              <div className="col-md-9">
                              <input type="text" id="title" className="form-control" placeholder=""/>
                              </div>
                            </div>
    
                            <div className="form-group row">
                              <label className="col-md-3 control-label">Email</label>
                              <div className="col-md-9">
                              <input type="email" id="email" className="form-control" placeholder=""/>
                              </div>
                            </div>
    
                            <div className="form-group row">
                              <label className="col-md-3 control-label">Telephone</label>
                              <div className="col-md-9 form-inline">
                              <input type="text" id="telephone" className="form-control" placeholder=""/><span className="help-inline"> EXT </span>  <input type="text" id="ext" className="form-control" placeholder=""/>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-md-3 control-label">Cell Phone</label>
                              <div className="col-md-9">
                              <input type="text" id="telephone" className="form-control" placeholder=""/>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-md-3 control-label">Fax</label>
                              <div className="col-md-9">
                              <input type="text" id="fax" className="form-control" placeholder=""/>
                              </div>
                            </div>
                            <div className="form-group row">
                              <div className="col-md-3"></div>
                                <div className="col-md-9">
                                  <button type="button" className="btn green-meadow">Add Contact</button> 
                                </div>
                            </div>
                             */}
                              </div>
                              <div className="clearfix"></div>
                              <div className="form-actions right">

                                {
                                  this.state.formdata.id && this.state.readonly ?
                                    <button onClick={this.handleDeleteActionButton} type="button" className="btn default red"><i className="fa fa-trash-o"></i> Delete</button>
                                    : null
                                }

                                &nbsp;
                                &nbsp;

                                <button onClick={this.cancel.bind(this)} type="button" className="btn default">Cancel</button> &nbsp;

                                <button type="submit" className="btn green-meadow" disabled={this.state.readonly || this.state.saveBtnDisable == false}> <i className="fa fa-check"></i> Save</button>
                              </div>
                            </form>


                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="tab_1">
                        <div className="portlet box yellow-gold">
                          <div className="portlet-title">
                            <div className="caption"> <i className="fa fa-envelope"></i> Contact Info</div>
                          </div>
                          <div className="portlet-body form ">

                            <form action="#" className="horizontal-form">
                              <div className="col-md-12">
                                <div className="mt10">
                                  <table className="table table-striped table-bordered table-hover table-checkable order-column dataTable no-footer">
                                    <thead>
                                      <tr>
                                        <th className="text-center"> Name </th>
                                        <th className="text-center w200"> Title </th>
                                        <th className="text-center"> Email </th>
                                        <th className="text-center"> Telephone </th>
                                        <th className="text-center"> Cell Phone </th>
                                        <th className="text-center"> Fax </th>
                                        <th className="text-center"> Action </th>

                                      </tr>
                                    </thead>
                                    <tbody>

                                      {this.state.Contacts.map(function (data) {
                                        return (
                                          <tr key={data.id} >
                                            <td className="text-center"> {data.name} </td>
                                            <td className="text-center"> {data.title} </td>
                                            <td className="text-center"> {data.email} </td>
                                            <td className="text-center"> {data.telephone} </td>
                                            <td className="text-center"> {data.cellphone} </td>
                                            <td className="text-center"> {data.fax} </td>
                                            <td className="text-center"><a data-toggle="modal" id={data.id}> <i className="fa fa-pencil" onClick={() => this.EditContact(data)}></i> </a>  &nbsp;
                                              <a> <i className="fa fa-trash-o" onClick={() => this.DelContact(data)}></i> </a></td>
                                          </tr>
                                        );
                                      }, this)}


                                    </tbody>
                                  </table>
                                </div>
                                <div className="mb-2">
                                  <a data-toggle="modal" href="#contact" className="btn  green-meadow" onClick={this.ClearContact.bind(this)}> Add Contact</a>
                                  <span style={this.state.isLoading === false ? msgNone : msgShowin}><i className="fa fa-circle-o-notch fa-spin"></i></span>
                                </div>
                              </div>
                              &nbsp;
                              <div className="clearfix"></div>

                            </form>

                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="tab_2">
                        <div className="portlet box yellow-gold">
                          <div className="portlet-title">
                            <div className="caption"> <i className="fa fa-file"></i> Documents </div>
                          </div>
                          <div className="portlet-body form">

                            <form action="#" className="horizontal-form" _lpchecked="1">
                              <div className="form-body max_width800">
                                <div className="form-group row">
                                  <label className="col-md-4 control-label">Carrier Setup</label>
                                  <div className="col-md-8 fileinput ">


                                    <div className="input-group input-large doc_custom">
                                      <div className="form-control uneditable-input input-fixed input-medium" data-trigger="fileinput">
                                        <i className="fa fa-file fileinput-exists"></i>&nbsp;
                                        <span className="fileinput-filename">{this.state.file.carrierSetup.name}</span>
                                      </div>

                                      <span className="input-group-addon btn default btn-file">
                                        <span className="fileinput-new"> Upload file </span>
                                        <input type="file" id='1' name='carrierSetup' onChange={this.onFileChange} /> </span>

                                      {
                                        this.state.carrierSetupload ?
                                          <button hidden={this.state.file.carrierSetup.name ? false : true} className="input-group-addon btn green fileinput-exists" id='1' onClick={this.download}> <i id='1' style={{ color: '#fff' }} className="fa fa-eye"></i> </button> :
                                          <button className="input-group-addon btn green fileinput-exists" id='1' onClick={(e) => e.preventDefault()}> <i id='1' style={{ color: '#fff' }} className='fa fa-circle-o-notch fa-spin'></i> </button>
                                      }

                                      <button hidden={this.state.file.carrierSetup.name ? false : true} className="input-group-addon btn red fileinput-exists" id='1' onClick={this.deleteFile}> <i id='1' style={{ color: '#fff' }} className="fa fa-trash"></i> </button>

                                    </div>
                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.carrierSetup}</div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-4 control-label">Quick Pay Forms</label>

                                  <div className="col-md-8 fileinput ">

                                    <div className="input-group input-large doc_custom">
                                      <div className="form-control uneditable-input input-fixed input-medium" data-trigger="fileinput">
                                        <i className="fa fa-file fileinput-exists"></i>&nbsp;
                                        <span className="fileinput-filename">{this.state.file.quickPay.name}</span>
                                      </div>

                                      <span className="input-group-addon btn default btn-file">
                                        <span className="fileinput-new"> Upload file </span>
                                        <input type="file" id='2' name='quickPay' onChange={this.onFileChange} /> </span>

                                      {
                                        this.state.quickPayload ?
                                          <button hidden={this.state.file.quickPay.name ? false : true} className="input-group-addon btn green fileinput-exists" id='2' onClick={this.download}> <i id='2' style={{ color: '#fff' }} className="fa fa-eye"></i> </button> :
                                          <button className="input-group-addon btn green fileinput-exists" id='1' onClick={(e) => e.preventDefault()}> <i id='1' style={{ color: '#fff' }} className='fa fa-circle-o-notch fa-spin'></i> </button>


                                      }


                                      <button hidden={this.state.file.quickPay.name ? false : true} className="input-group-addon btn red fileinput-exists" id='2' onClick={this.deleteFile}> <i id='2' style={{ color: '#fff' }} className="fa fa-trash"></i> </button>

                                    </div>

                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.quickPay}</div>
                                  </div>

                                </div>


                              </div>
                              <div className="clearfix"></div>

                            </form>

                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="tab_3">
                        <LoadTab
                          customerId={this.state.customerid}
                        />
                      </div>



                    </div>

                  </div>

                </div>
              </div>
            </div>
            <div className="modal fade" id="contact" data-backdrop="static" data-keyboard="false" tabIndex="-1" aria-hidden="true">
              <div className="modal-dialog  modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <button type="button" id="closePopUp" data-dismiss="modal" aria-hidden="true" hidden={true}></button>
                    <button type="button" onClick={this.FinishEditInfo.bind(this)} className="close" data-dismiss="modal" aria-hidden="true"></button>
                    <h4 className="modal-title">Contact</h4>
                  </div>
                  <div className="modal-body">
                    <div className="portlet-body form">

                      <form onSubmit={this.SaveContact.bind(this)} className="horizontal-form">
                        <div className="form-body max_width800">
                          <br />

                          <div className="form-group row">
                            <label className="col-md-3 control-label">Name <span className="font-red-thunderbird" >*</span></label>
                            <div className="col-md-9">
                              <input type="text" ref="ContactName" pattern="[a-z A-Z0-9-'@!$#:;,. ]{1,200}" title="Please Enter Contact Name (Max limit: 200)" className="form-control" placeholder="" required />
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-md-3 control-label">Title</label>
                            <div className="col-md-9">
                              <input type="text" ref="ContactTitle" pattern="[a-z A-Z'(),-_.\x22 ]{1,200}" title="Please Enter title (Max limit: 200)" className="form-control" placeholder="" />
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-md-3 control-label">Email</label>
                            <div className="col-md-9">
                              <input type="email" ref="ContactEmail" className="form-control" placeholder="" />
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-md-3 control-label">Telephone</label>
                            <div className="col-md-9 form-inline">
                              <input type="text" ref="ContactTelephone" id='contactTelephone' pattern="[a-z A-Z0-9- ]{12}" title="Please Enter Full lenght Telephone (12 digits)" onChange={this.AutoFormatContact} className="form-control" placeholder="xxx-xxx-xxxx" />
                              <span className="help-inline"> EXT </span>  <input type="text" pattern="[0-9]{1,10}" title="Please Enter extention (Max 10 Digits Only)" ref="ContactExt" className="form-control" placeholder="" />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-md-3 control-label">Cell Phone</label>
                            <div className="col-md-9">
                              <input type="text" ref="ContactPhone" id='contactPhone' pattern="[a-z A-Z0-9- ]{12}" title="Please Enter Full lenght Cellphone (12 digits)" onChange={this.AutoFormatContact} className="form-control" placeholder="xxx-xxx-xxxx" />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-md-3 control-label">Fax</label>
                            <div className="col-md-9">
                              <input type="text" ref="ContactFax" id='contactFax' pattern="[a-z A-Z0-9- ]{12}" title="Please Enter Full lenght fax (12 digits)" onChange={this.AutoFormatContact} className="form-control" placeholder="xxx-xxx-xxxx" />
                            </div>
                          </div>
                        </div>
                        <div className="clearfix"></div>
                        <div className="modal-footer">
                          <button type="button" className="btn default" data-dismiss="modal" onClick={this.FinishEditInfo.bind(this)}>Cancel</button> &nbsp;
                          <button type="submit" className="btn green-meadow"> <i className="fa fa-check"></i> Save</button>
                        </div>
                      </form>

                    </div>
                  </div>

                </div>

              </div>

            </div>
          </div>
        </div>

      </div>



    );

  }
}


export default withRouter(EditCustomer);