/* eslint-disable */ 

import React, { Component } from 'react';
import { withRouter} from "react-router-dom";
import MUiTable from './MuiTable'
import { NotificationManager} from 'react-notifications';
import DatePicker from "react-datepicker";
import axios from 'axios';
import BaseURL from './config';
import $ from 'jquery';
import f from './Loading_icon.gif';
import  getEditinfo    from './StartEditMode';
import  finishEditinfo    from './FinishEditMode';
import Tick from './green-tick.jpg';
import Cross from './red-cross.jpg';
import TokenErrorMsg from './errorMsg';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from "@material-ui/core/MenuItem";


 class TrailerTab extends Component {
    _isMounted=true;
    constructor(props){
        super(props);
        this.state = {
            btnVal: "Show All",
            Status:2,
            status:1,
            readonly:false,
            carrierId:'',
            trailerid:'',
            token:'',
            data: [],            
            page: 0,
            rowsPerPage: 50,    
            searchVal: false,

           
            fields: {},
            errors: {},           
            make:[],
            states:[],   
            whileSave: false,
            file:{
                License:'',       
              },
           Licenseload:true,  
           LicExpDate: new Date(),
           modifyLicExpDate:'',
           hidden:true,  
           TrailerFormData:{state:''},
           Trailertype:'',
           isLoading:false,
           open: false,
           load:true,
           offset: 0,
           limit: 50,
           order:'ASC',
           sortByColumn:'trailerNo',
           count:'',

        };
        this.handleChange = this.handleChange.bind(this);
        this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);
        this.submitEditRegistrationForm = this.submitEditRegistrationForm.bind(this);
        this.submit=this.submit.bind(this);
        this.validateForm=this.validateForm.bind(this); 
        this.startEditing=this.startEditing.bind(this);
        
    }

  
    columns = [
      {name:"ID",options: {  
     display:false,
    }},
     {name:"trailerNo",
     label:"Trailer Number",options: { 
     sortDirection	:'asc',  
     sort: true,    
    }}, 
    {name:"make",
    label:"Make",options: {   
     sort: true,
    }}, 
    {name:"model",
    label:"Model",options: {   
     sort: true,
    }},
    {name:"licensePlateNo",
    label:"License Plate #",options: {   
     sort: true,
    }},
    {name:"year",
    label:"Year",options: {   
     sort: true,
    }},
    {name:"Status",options: {   
      sort: false,
     }},
    ];
    
onUnload=  (event) =>{ 
  
    event.preventDefault();  
    var http = new XMLHttpRequest();
     
   var url = BaseURL+'editModeInfo/finishEditMode';
   
   var params = "trailerId="+this.state.trailerid+"&token="+this.state.token;
   
   http.open("POST", url, true);
   
   http.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
   
   if(this.state.trailerid){

   
   http.send(params);
   var start = new Date().getTime();
     while (new Date().getTime() < start + 350);  
      
   }
   }
   
   
   async componentDidMount(){
      
     window.addEventListener("beforeunload", this.onUnload)  
    var token=localStorage.getItem('Token');    
   
     this.setState({'token':token});  
     
     console.log(this.props.Trailer);        
        
    if(this.props.Trailer.Trailer.rows)
    {
     var arr=[],status='' ,count;
     count = this.props.Trailer.Trailer.count;
     for (const key in this.props.Trailer.Trailer.rows) {
       
         if (this.props.Trailer.Trailer.rows.hasOwnProperty(key)) {
           const element = this.props.Trailer.Trailer.rows[key];

           if(element.status === 1){
            status=<img style={{ width:"15px", height:"15px"}} src={Tick} alt='Active'/>
           } 
           else   {
            status=<img style={{ width:"15px", height:"15px"}} src={Cross} alt='Inactive'/>
           };
            var Obj=[
               element.id,
               element.trailerNo,
               element.make.make,
               element.model,                     
               element.licensePlateNo,
               element.year,                  
               status,             
         
            ]
           
           arr.push(Obj);
           
         }
        
       }

       this.setState({
         count,
         data:arr,
         carrierId:this.props.CarrierId,
         make:this.props.Trailer.makes,
         states:this.props.Trailer.states, 
         
      });    
    }      
           
  }
  
  componentWillUnmount() {
  
   
    var config={
      "trailerId": this.state.truckid,   
      "token":this.state.token
     }
     
     if(this.state.truckid)
     {     
        finishEditinfo(config, (res) => {
        console.log(res);
        
            },(err) => {
                
                console.log(err)
            });
     }
  
    
  }

  
async search(){
  
  if(this.refs.search.value.trim()){


 
    var arr=[],count;
    var status;
    await axios.post(BaseURL+'trailer/findByName',{
      "searchKeyword": this.refs.search.value.trim(),
      "carrierId":this.state.carrierId,
      "offset": this.state.offset,
      "limit": this.state.limit,
      "sortOrder": this.state.order,
      "sortBy": this.state.sortByColumn,
      "status":this.state.Status,
      'token':this.state.token
    }).then(response=> {   
      console.log(response.data.data)
      if(response.data.error=== TokenErrorMsg){     
       localStorage.setItem('Token','') ;      
       this.props.history.push('/') ;       
           }
      
      
      count =response.data.data.count;

      for (const key in response.data.data.rows) {
        
       if (response.data.data.rows.hasOwnProperty(key)) {
         const element = response.data.data.rows[key];
         
         if(element.status === 1){
           status=<img style={{ width:"15px", height:"15px"}} src={Tick} alt='Active'/>
          } 
          else   {
           status=<img style={{ width:"15px", height:"15px"}} src={Cross} alt='Inactive'/>
          };
            var Obj=[
                     element.id,
                     element.trailerNo,
                     element.make.make,
                     element.model,                     
                     element.licensePlateNo,
                     element.year,                  
                     status,   
            ]
         
         arr.push(Obj);
         
       }
      
     }

    
     
    // console.log(arr);
    }).catch(function (error) {
      NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);         
      console.log(error);
    })         
            
        if(this._isMounted){
        this.setState({data:arr,isLoading:false,count});
        }

  }
  else{
    alert('Please Type Something in Search Bar')
  }
 
  
 


}

async showall(theme, e){

  this.refs.search.value='';
  if(this._isMounted){

    await  this.setState({
        searchVal: false,
        isLoading: true,
        Status:2,
        open: false,
        anchorEl: null,
        btnVal: theme,
        page:0,
        offset: 0,
        order:'ASC',
        sortByColumn:'trailerNo',  
        

      })
      this.fetchPaginationData();
     
    }
  
  
}
    
    
     async startEditing (colData, cellMeta){
           
      if(window.getSelection().toString()){
        return false;
      }
      else if(cellMeta.colIndex !== 1)
      {
       return;
      }
       this.setState({   
        readonly:true            
       });

       var data=this.state.data;
       var id =data[cellMeta.dataIndex][0];
     
       
       var arr={
        'carriers':[],     
        'states':[],    
        'make':[],           
        'trailer':{
        'id':'',
        'licenseExpirationDate':null,
        'status':'',
        'trailerType':''
          },
          'trailerDocuments':[],
          
      };

      $("#trailerviewtab1").removeClass('active');
      $("#tab_14").removeClass('active');

      $('#tab_13').addClass('active');
      $('#trailerviewtab0').addClass('active');     
     
     
        document.getElementById('trailercarrierinfo').click(); 
   
      
    
        await axios.post(BaseURL+'trailer/findById',{ 'id':id, 'token':this.state.token})
              .then(response=> { 

                if(response.data.error=== TokenErrorMsg){     
                 localStorage.setItem('Token','') ;      
                 this.props.history.push('/') ;       
                     }

                  console.log(response.data);              
                
                 arr = response.data.data;
                 if(arr.trailer.status === 0){
                    $("#opt1").removeClass('active');
                  $("#opt2").addClass('active');
                 
                    }
                  else{
                    $("#opt2").removeClass('active');
                   $("#opt1").addClass('active');
                  }
              
              }).catch(function (error) {
                NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);         
                console.log(error);
              })
              
              var License='';             
              
              arr.trailerDocuments.forEach(element => {
    
                var filename=  element.documentName ;
                if(filename.length>15)
                {
                  filename=filename.substring(0, 15)+'....'
                  
                }        
    
            if(Number(element.documentType) === 1){
               License={'name':filename};                  
      
            }       
                  
            });                 
        
              this.setState({         
                 make:arr.make,
                 states:arr.states,
                 TrailerFormData:arr.trailer,     
                 modifyLicExpDate: new Date(arr.trailer.licenseExpirationDate),
                 trailerid:id,
                 status:arr.trailer.status,
                 Trailertype:arr.trailer.trailerType,
                 file:{
                  License:License,       
                 },
                 readonly:true            
                });
      
    };
    
    handleSearch = async () => {

      if (this.refs.search.value) {
        this.setState({ searchVal: true });
      }
      else{
        this.showall('Show All',this)
      }
    }
    
    
     async  cancel(){      
        
        var config={
            "trailerId": this.state.trailerid,   
            "token":this.state.token
           }
           
           if(config.trailerId)
           {     
              finishEditinfo(config, (res) => {
              console.log(res);
              
                  },(err) => {
                      
                      console.log(err)
                  });
              this.setState({ hidden:true,  readonly:false,status:1,trailerid:'',TrailerFormData:{state:''}, LicExpDate: new Date() , errors:{} , Trailertype:'Dry Van', file:{    License:'' }  })
           }
            else{
               
                this.setState({
                    readonly:false,
                    status:1,
                    hidden:true,  
                    LicExpDate: new Date(),
                    errors:{},
                    file:{    License:'' },
                    Trailertype:'Dry Van',
                })    
           }
           
           document.getElementById('trailerform').reset();
           document.getElementById('trailerviewform').reset();
          
            $("#trailerinactive").removeClass('active');
            $("#traileractive").addClass('active');
         
           
      }
 
     EditForm(e){       
      
        var div = document.getElementById('trailerEditDiv');
        var viewdiv = document.getElementById('trailerviewEditDiv');

        div.setAttribute("style"," cursor: not-allowed; pointer-events: none");
        viewdiv.setAttribute("style"," cursor: not-allowed; pointer-events: none")
      
        var config={
          "trailerId": this.state.trailerid,   
          "token":this.state.token
         }
      
         getEditinfo(config, (res) => {
          console.log(res);
          if(res.data.status === 0){
             this.setState({readonly:false});
             viewdiv.removeAttribute("style")
             div.removeAttribute("style")
          }
          else {
            viewdiv.removeAttribute("style")
            div.removeAttribute("style")
            NotificationManager.warning(res.data.message, 'Warning Message', 3000);   
          }
      },(err) => {
         
        console.log(err)
      }); 
      
      }
    
    
   async handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    await this.setState({
      fields
    });
    this.validateForm();
  }
  
  submituserRegistrationForm(e) {

    e.preventDefault();
    var send=false;
   
    if (this.validateAndSubmit()  && this.validateForm()  ) {
      
      send=true;
       
    }
    if(send)
    {     
      this.submit();
      this.refs.trailer.focus();
     
    }
    
  }

  submitEditRegistrationForm(e) {

    e.preventDefault();
    var send=false;
   
    if (this.EditvalidateAndSubmit()  && this.validateForm()  ) {
      
      send=true;
       
    }
    if(send)
    {     
      this.submit();
      this.refs.modifytrailer.focus();
     
    }
    
  }
  
  validateAndSubmit(){

    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
  
   
  
    if (!this.refs.trailer.value || !this.refs.trailer.value.trim().length>0) {
      formIsValid = false;
      errors["trailer"] = "*Please Enter Trailer #";
      $("[name='trailer']").focus();
     
    }
   
    else if (!this.refs.trailertype.value) {
      formIsValid = false;
      errors["trailertype"] = "*Please Select Trailer Type";
      $("[name='trailertype']").focus();
     
    }
    else if (!this.refs.trailersize.value) {
      formIsValid = false;
      errors["trailersize"] = "*Please Select Trailer Size";
      $("[name='trailersize']").focus();
     
    }  
     
    else if (!this.refs.make.value) {
      formIsValid = false;
      errors["make"] = "*Please Select Make";
      $("[name='make']").focus();
     
    }
    else if (!this.refs.year.value) {
      formIsValid = false;
      errors["year"] = "*Please Select Year";
      $("[name='year']").focus();
     
    }
     
    else if (!this.refs.driverLicense.value || !this.refs.driverLicense.value.trim().length>0) {
      formIsValid = false;
      errors["DriverLicense"] = "*Please Enter License No";
      $("[name='DriverLicense']").focus();
     
    }     
      
      else if (!this.refs.province_id.value) {
          formIsValid = false;
          errors["state"] = "*Please Select State";
          $("[name='state']").focus();
         
        }
      else  if (this.state.Trailertype === 'Reefer' && !this.refs.refeermake.value) {
          formIsValid = false;
          errors["refeermake"] = "*Please Select Make";
          $("[name='refeermake']").focus();
         
        }
        else  if (this.state.Trailertype === 'Reefer' && !this.refs.refeeryear.value) {
          formIsValid = false;
          errors["refeeryear"] = "*Please Select Year";
          $("[name='refeeryear']").focus();
         
        }
        
  
  
  
      
      this.setState({
        errors: errors
      });
      return formIsValid;
  }

  EditvalidateAndSubmit(){
  
   
    let errors = {};
  let formIsValid = true;

 

  if (!this.refs.modifytrailer.value || !this.refs.modifytrailer.value.trim().length>0) {
    formIsValid = false;
    errors["modifytrailer"] = "*Please Enter Trailer #";
    $("[name='modifytrailer']").focus();
   
  }

   
  else if (!this.refs.modifymake.value) {
    formIsValid = false;
    errors["modifymake"] = "*Please Select Make";
    $("[name='modifymake']").focus();
   
  }  
   
    else if (!this.refs.modifydriverLicense.value || !this.refs.modifydriverLicense.value.trim().length>0) {
      formIsValid = false;
      errors["modifyDriverLicense"] = "*Please Enter Driver License";
      $("[name='modifyDriverLicense']").focus();
     
    }    
    
    else if (!this.refs.modifyprovince_id.value) {
        formIsValid = false;
        errors["modifystate"] = "*Please Select State";
        $("[name='modifystate']").focus();
       
      }
    else  if (this.state.Trailertype === 'Reefer' && !this.refs.modifyrefeermake.value) {
        formIsValid = false;
        errors["modifyrefeermake"] = "*Please Select Make";
        $("[name='modifyrefeermake']").focus();
       
      }
      else  if (this.state.Trailertype === 'Reefer' && !this.refs.modifyrefeeryear.value) {
        formIsValid = false;
        errors["modifyrefeeryear"] = "*Please Select Year";
        $("[name='modifyrefeeryear']").focus();
       
      }
      



    
    this.setState({
      errors: errors
    });
    return formIsValid;
  }
  
  
  validateForm() {

    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
  
  
  
      if (typeof fields["trailer"] !== "undefined" && fields["trailer"]) {
      if (!fields["trailer"].match(/^[a-zA-Z0-9#'".,-/ ]{1,200}$/)) {
          formIsValid = false;
          errors["trailer"] = "*Only Alphanumeric Allowed (Max Range: 200)";
      }
      }
  
      if (typeof fields["color"] !== "undefined" && fields["color"]) {
          if (!fields["color"].match(/^[a-zA-Z0-9#'".,-/ ]{1,200}$/)) {
          formIsValid = false;
          errors["color"] = "*Only Alphanumeric Allowed (Max Range: 200)";
          }
          }
      
      if (typeof fields["model"] !== "undefined" && fields["model"]) {
          if (!fields["model"].match(/^[a-zA-Z0-9#'".,-/ ]{1,200}$/)) {
              formIsValid = false;
              errors["model"] = "*Only Alphanumeric Allowed (Max Range: 200)";
          }
          }
  
          if (typeof fields["refeermodel"] !== "undefined" && fields["refeermodel"]) {
              if (!fields["refeermodel"].match(/^[a-zA-Z0-9#'".,-/ ]{1,200}$/)) {
                  formIsValid = false;
                  errors["refeermodel"] = "*Only Alphanumeric Allowed (Max Range: 200)";
              }
              }
  
      if (typeof fields["year"] !== "undefined" && fields["year"]) {
          if (!fields["year"].match(/^[a-zA-Z0-9#'".,-/ ]{1,200}$/)) {
              formIsValid = false;
              errors["year"] = "*Only Alphanumeric Allowed (Max Range: 200)";
          }
          }
          if (typeof fields["refeeryear"] !== "undefined" && fields["refeeryear"]) {
              if (!fields["refeeryear"].match(/^[a-zA-Z0-9#'".,-/ ]{1,200}$/)) {
                  formIsValid = false;
                  errors["refeeryear"] = "*Only Alphanumeric Allowed (Max Range: 200)";
              }
              }
  
      if (typeof fields["vin"] !== "undefined" && fields["vin"]) {
          if (!fields["vin"].match(/^[a-zA-Z0-9#'".,-/ ]{1,200}$/)) {
              formIsValid = false;
              errors["vin"] = "*Only Alphanumeric Allowed (Max Range: 200)";
          }
          }  
  
    if (typeof fields["DriverLicense"] !== "undefined" && fields["DriverLicense"]) {
      if (!fields["DriverLicense"].match(/^[a-zA-Z0-9'-.#$ ]{1,30}$/)) {
        formIsValid = false;
        errors["DriverLicense"] = "*Please Enter Valid License  (Max Range: 30)";
      }
      }
  
      if (typeof this.state.LicExpDate !== "undefined" && this.state.LicExpDate) {
        if ( this.state.LicExpDate.getFullYear() < 2000 || this.state.LicExpDate.getFullYear() > 2099 ) {
          formIsValid = false;
          errors["LicExpDate"] = "*Enter Valid Date ( Year Between 2000-2099 )";
        }
        }



        if (typeof fields["modifytrailer"] !== "undefined" && fields["modifytrailer"]) {
            if (!fields["modifytrailer"].match(/^[a-zA-Z0-9#'".,-/ ]{1,200}$/)) {
                formIsValid = false;
                errors["modifytrailer"] = "*Only Alphanumeric Allowed (Max Range: 200)";
            }
            }
        
            if (typeof fields["modifycolor"] !== "undefined" && fields["modifycolor"]) {
                if (!fields["modifycolor"].match(/^[a-zA-Z0-9#'".,-/ ]{1,200}$/)) {
                formIsValid = false;
                errors["modifycolor"] = "*Only Alphanumeric Allowed (Max Range: 200)";
                }
                }
            
            if (typeof fields["modifymodel"] !== "undefined" && fields["modifymodel"]) {
                if (!fields["modifymodel"].match(/^[a-zA-Z0-9#'".,-/ ]{1,200}$/)) {
                    formIsValid = false;
                    errors["modifymodel"] = "*Only Alphanumeric Allowed (Max Range: 200)";
                }
                }
        
                if (typeof fields["modifyrefeermodel"] !== "undefined" && fields["modifyrefeermodel"]) {
                    if (!fields["modifyrefeermodel"].match(/^[a-zA-Z0-9#'".,-/ ]{1,200}$/)) {
                        formIsValid = false;
                        errors["modifyrefeermodel"] = "*Only Alphanumeric Allowed (Max Range: 200)";
                    }
                    }
        
            if (typeof fields["modifyyear"] !== "undefined" && fields["modifyyear"]) {
                if (!fields["modifyyear"].match(/^[a-zA-Z0-9#'".,-/ ]{1,200}$/)) {
                    formIsValid = false;
                    errors["modifyyear"] = "*Only Alphanumeric Allowed (Max Range: 200)";
                }
                }
                if (typeof fields["modifyrefeeryear"] !== "undefined" && fields["modifyrefeeryear"]) {
                    if (!fields["modifyrefeeryear"].match(/^[a-zA-Z0-9#'".,-/ ]{1,200}$/)) {
                        formIsValid = false;
                        errors["modifyrefeeryear"] = "*Only Alphanumeric Allowed (Max Range: 200)";
                    }
                    }
        
            if (typeof fields["modifyvin"] !== "undefined" && fields["modifyvin"]) {
                if (!fields["modifyvin"].match(/^[a-zA-Z0-9#'".,-/ ]{1,200}$/)) {
                    formIsValid = false;
                    errors["modifyvin"] = "*Only Alphanumeric Allowed (Max Range: 200)";
                }
                }  
        
          if (typeof fields["modifyDriverLicense"] !== "undefined" && fields["modifyDriverLicense"]) {
            if (!fields["modifyDriverLicense"].match(/^[a-zA-Z0-9'-.#$ ]{1,30}$/)) {
              formIsValid = false;
              errors["modifyDriverLicense"] = "*Please Enter Valid License  (Max Range: 30)";
            }
            }
        
            if (typeof this.state.modifyLicExpDate !== "undefined" && this.state.modifyLicExpDate) {
              if ( this.state.modifyLicExpDate.getFullYear() < 2000 || this.state.modifyLicExpDate.getFullYear() > 2099 ) {
                formIsValid = false;
                errors["modifyLicExpDate"] = "*Enter Valid Date ( Year Between 2000-2099 )";
              }
              }
  
    
  
    this.setState({
      errors: errors
    });
    return formIsValid;
  
  
  }

  async submit(){

    this.setState({ whileSave: true ,hidden:false,readonly:true})
  
    var custId;

    const trailerNo =  this.refs.trailer.value.trim();
    const model =  this.refs.model.value.trim();
    const year =  this.refs.year.value.trim();
    const vin=this.refs.vin.value.trim();
    const color=this.refs.color.value.trim();
    const carrierid=this.state.carrierId;
    const makeid=this.refs.make.value;
    const trailertype=this.refs.trailertype.value;
    const trailersize=this.refs.trailersize.value;
    const province_id =  this.refs.province_id.value;
    const driverLicense = this.refs.driverLicense.value.trim();
    var refeermakeid=this.refs.refeermake.value;
    const refeermodel =  this.refs.refeermodel.value.trim();
    const refeeryear =  this.refs.refeeryear.value.trim();

    const modifytrailerNo =  this.refs.modifytrailer.value.trim();
    const modifymodel =  this.refs.modifymodel.value.trim();
    const modifyyear =  this.refs.modifyyear.value.trim();
    const modifyvin=this.refs.modifyvin.value.trim();
    const modifycolor=this.refs.modifycolor.value.trim() ; 
    const modifymakeid=this.refs.modifymake.value;
    const modifytrailertype=this.refs.modifytrailertype.value.trim();
    const modifytrailersize=this.refs.modifytrailersize.value.trim();
    const modifyprovince_id =  this.refs.modifyprovince_id.value;
    const modifydriverLicense = this.refs.modifydriverLicense.value.trim();
    var modifyrefeermakeid=this.refs.modifyrefeermake.value;
    const modifyrefeermodel =  this.refs.modifyrefeermodel.value.trim();
    const modifyrefeeryear =  this.refs.modifyrefeeryear.value.trim();
  
   if(!this.refs.refeermake.value){
       refeermakeid=null;
   }
      
      var trailer={
     'token':this.state.token,
     'id': this.state.trailerid,
     "trailerNo": trailerNo,
     "model": model,
     'trailerType':trailertype,
     'trailerSize':trailersize,
     'status':this.state.status,
     "year": year,
     "licenseExpirationDate": this.state.LicExpDate,
     "vinNo": vin,
     "color": color,
     "licensePlateNo": driverLicense ,
     "licenseStateId": province_id,
     "makeId": makeid,
     "carrierId": carrierid,

     "reeferMake": refeermakeid,
     'reeferYear':refeeryear,
     'reeferModel':refeermodel,
     'Trailertype':''     
  }

  var modifytrailer={
    'token':this.state.token,
    'id': this.state.trailerid,
    "trailerNo": modifytrailerNo,
    "model": modifymodel,
    'trailerType':modifytrailertype,
    'trailerSize':modifytrailersize,
    'status':this.state.status,
    "year": modifyyear,
    "licenseExpirationDate": this.state.modifyLicExpDate,
    "vinNo": modifyvin,
    "color": modifycolor,
    "licensePlateNo": modifydriverLicense ,
    "licenseStateId": modifyprovince_id,
    "makeId": modifymakeid,
    "carrierId": carrierid,

    "reeferMake": modifyrefeermakeid,
    'reeferYear':modifyrefeeryear,
    'reeferModel':modifyrefeermodel,
    'Trailertype':''     
 }

  if(this.state.trailerid){

 var trailerData=this.state.TrailerFormData.trailerNo;
   await axios.post(BaseURL+'trailer/update',trailerData? modifytrailer: trailer )
          .then(response=> {           
           if(response.data.error=== TokenErrorMsg){     
            localStorage.setItem('Token','') ;      
            this.props.history.push('/') ;       
                }
            console.log(response.data);
            NotificationManager.success('Trailer upated Successfully', 'Edited', 3000);
          }).catch(function (error) {
           NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);         
           console.log(error);
         })
    this.setState({
      whileSave: false
    })


 }

  else{
     
  await axios.post(BaseURL+'trailer/save', trailer )
          .then(response=> {           
           if(response.data.error=== TokenErrorMsg){     
            localStorage.setItem('Token','') ;      
            this.props.history.push('/') ;       
                }
            console.log(response.data);
            custId= response.data.data.id;               
           
            NotificationManager.success('New Trailer Added Successfully', 'Added', 3000);
          }).catch(function (error) {
           NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);         
           console.log(error);
         })
   this.setState({
     trailerid:custId,
     whileSave: false,     
    
   });
 }

 this.showall("Show All", this);
       
}
 

  onFileChange =async (event) => {

    let errors = this.state.errors; 
    
    event.preventDefault();
    event.persist();
    var file=await event.target.files[0];
    function getFileExtension(filename) {
      return filename.split('.').pop();
    }

    if(event.target.files[0])
    {

    var filename= {'name': file.name };


    if (file.type !=='application/pdf' && file.type !=='image/jpeg' && file.type !=='image/png' && file.type !=='image/jpg' && file.type !=='image/gif' && file.type !=='image/tif' && getFileExtension(file.name) !=='docx' && getFileExtension(file.name) !=='doc' && getFileExtension(file.name) !=='odt' && getFileExtension(file.name) !=='xls' && getFileExtension(file.name) !=='xlsx' && getFileExtension(file.name) !=='ppt' && getFileExtension(file.name) !=='pptx') {
    errors[event.target.name]='Please Upload only PDF ,Images, Document files ';
    this.setState({errors:errors})
    return false;
      }
      else if (file.size> 1000000) {
        errors[event.target.name]='Please Upload A File Less Than 1MB ( Max size for file: 1MB ) ';
        this.setState({errors:errors})
        return false;
          }
    else{
      errors[event.target.name]='';
      this.setState({errors:errors})
    }

    if(filename.name.length>15)
    {
      filename={
        'name':filename.name.substring(0, 15)+'....'
      }
    }
    console.log(file);

    var doctype= event.target.id;
    var id=event.target.id;
    console.log(id);

    

   
    
      if(Number(id) === 1){

        this.setState({
  
          Licenseload:false
         
       });
  
     }
    
    
    

   console.log('enter in upload file');
    let data = new FormData();
    await data.append('file', file);
    await data.append('fileName', file.name);
    await data.append("trailerId",  this.state.trailerid);
    await data.append("type",  file.type);
    await data.append("documentType", doctype);   
   


    await axios.post(BaseURL+'trailerDocuments/uploadFileToS3', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'token':this.state.token
      }
    })
    .then(response=> {           
      if(response.data.error === TokenErrorMsg){     
       localStorage.setItem('Token','') ;      
       this.props.history.push('/') ;       
           }
      console.log(response.data)
      NotificationManager.success('Document Uploaded Successfully', 'Upload', 3000);

    }).catch(function (error) {
      NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);         
      console.log(error);
    })

    if(Number(id) === 1){

      await this.setState({
        Licenseload:true,
        file:{
        License:filename,       
        }
      });

    }
   
  
    
  }

  }

  deleteFile=(e)=>{
    e.preventDefault();
    e.persist();
    var doctype=e.target.id;
    var id=e.target.id;
    console.log(id);
    


    if(window.confirm('Are You Sure To Remove Docment ?'))
    {

      
      console.log('enter in delete file');
        axios.post(BaseURL+'trailerDocuments/deleteFile', {
          "trailerId":   this.state.trailerid,
          "documentType": doctype,
          'token':this.state.token

        })
        .then(response=> {           
          if(response.data.error=== TokenErrorMsg){     
           localStorage.setItem('Token','') ;      
           this.props.history.push('/') ;       
               }
          NotificationManager.success('Document Deleted Successfully', 'Delete', 3000);
          console.log(response.data)

        }).catch(function (error) {
          NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);         
          console.log(error);
        })

        if(Number(id) === 1){

          this.setState({
         
           file:{
           License:'',
          
           }
         });
    
       }
        
     
    
    }

    
  }
  

  download =async (e) => { 

    e.preventDefault();    
    e.persist();   
    var doctype= await e.target.id;
    var id= await e.target.id;


    if(Number(id) === 1){

      this.setState({

        Licenseload:false
       
     });

   }
   
 

    console.log(doctype);
    console.log('enter in download file');

    await axios.post(BaseURL+'trailerDocuments/getDocuments',{
      "trailerId":   this.state.trailerid,
      'token':this.state.token
    })
    .then(response=> {           
      if(response.data.error=== TokenErrorMsg){     
       localStorage.setItem('Token','') ;      
       this.props.history.push('/') ;       
           }
      
         response.data.documents.forEach(element => {
      if(element.documentType === doctype){
       console.log( element );
       window.open(element.documentUrl,'_blank')       

      }  
         
      });   

    }).catch(function (error) {
      NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);         
      console.log(error);
    })

    if(Number(id) === 1){

      this.setState({

        Licenseload:true
       
     });
   }

   
        
  }
 
  ResetTabs(){    
    $("#trailertab1").removeClass('active');
    $("#tab_12").removeClass('active');

    $('#tab_11').addClass('active');
    $('#trailertab0').addClass('active'); 
  }

  handleKeyPress = e => {
    if (event.key === 'Enter') {
      this.SetSearchParameters()
    }
  };
  SetSearchParameters=async ()=>{

    if(this._isMounted && this.refs.search.value.trim()){
     
      await this.setState({
        
         isLoading: true,
         page:0,
         offset: 0,
         order:'ASC',
         sortByColumn:'trailerNo',  
       })
  
       this.search();
     }
     else{
      alert('Please Type Something in Search Bar')    
     }
  }

     
handleToggle = event => {

  if(this._isMounted){
  this.setState(state => ({ open: !state.open, anchorEl: event.currentTarget }));
  }

};

handleClose = event => {

  if (this.anchorEl.contains(event.target)) {
    return;
  }
  if(this._isMounted){
  this.setState({ open: false });
  }

};



async active(theme, e){ 
   

  if(this._isMounted){
   this.refs.search.value='';

   await  this.setState({
      searchVal: false,
       isLoading: true,
       Status:1,
       open: false,
       anchorEl: null,
       btnVal: theme,
       page:0,
       offset: 0,
       order:'ASC',
       sortByColumn:'trailerNo',  
       

     })
     this.fetchPaginationData();
    
   }

}

async Inactive(theme, e){


  if(this._isMounted){
   this.refs.search.value='';
   await  this.setState({
       searchVal: false,
       isLoading: true,
       Status:0,
       open: false,
       anchorEl: null,
       btnVal: theme,
       page:0,
       offset: 0,
       order:'ASC',
       sortByColumn:'trailerNo',  
       

     })
     this.fetchPaginationData();
    
   }
    
     

}


onTableChange= async (action, tableState) => {

console.log('action',action);
console.log('state',tableState)


switch (action) {

case 'changePage':

 this.changePage(tableState);
 break;


case 'changeRowsPerPage':

 this.changeRowsPerPage(tableState);
 break;

case 'sort':

 this.sort(tableState);
 break;

default:
 break;

}
}


changePage=async (tableState)=>{

var offset=this.state.offset;


if(tableState.page > this.state.page) // Next Icon Clicked >
{ 
 
 var tempOffset = offset+tableState.rowsPerPage
 if(this._isMounted){

 await this.setState({
   offset: tempOffset, //50
   limit: tableState.rowsPerPage, // 76
  
 
 });

 
 await  this.state.searchVal ? this.search() :this.fetchPaginationData();
 this.setState({
   page:tableState.page,
   
 })
}
}
else{
 var tempLimit = tableState.rowsPerPage;
 var tempOffset = offset-tempLimit;
 if(tempOffset < 0){
   tempOffset = 0
 }

if(this._isMounted){

 await this.setState({
   limit: tempLimit,
   offset: tempOffset, 
  
 
 });


   await   this.state.searchVal ? this.search() :this.fetchPaginationData();

   this.setState({
   page:tableState.page
 })
}
 



}

}


changeRowsPerPage=async (tableState)=>{

if(this._isMounted){
 await this.setState({
   limit:tableState.rowsPerPage, // 100
   offset: 0,
   rowsPerPage: tableState.rowsPerPage,
   page: 0     ,
 
 });
 this.state.searchVal ?this.search() :this.fetchPaginationData();

}

}


sort=async (tableState)=>{

var sortByColumn=this.state.sortByColumn;
var order=this.state.order;

var sortOrder = tableState['columns'][tableState.activeColumn].name ===  sortByColumn ? (order==='ASC' ? 'DESC' : 'ASC') : 'ASC' ;


if(this._isMounted){

await this.setState({
order:sortOrder,
sortByColumn:tableState['columns'][tableState.activeColumn].name    

});  


this.state.searchVal ?this.search() :this.fetchPaginationData();

}

}

fetchPaginationData=()=>{


//console.log('TableState Data',state.data)
var postData= {
'token':this.state.token,
'status':this.state.Status,
"offset": this.state.offset,
"limit": this.state.limit,
"sortOrder": this.state.order,
"sortBy": this.state.sortByColumn,
'carrierId':this.state.carrierId,
};

var arr=[];
//console.log('state',arr)
var status,count;
axios.post(BaseURL+'trailer/findTrailersByCarrierId',postData
,
{ 
headers: { 'Content-Type': 'application/json' }
}
).then(response=> {   
       console.log(response.data.data)
       if(response.data.error=== TokenErrorMsg){     
        localStorage.setItem('Token','') ;      
        this.props.history.push('/') ;       
            }
       
       
       count =response.data.data.count;

       for (const key in response.data.data.rows) {
         
        if (response.data.data.rows.hasOwnProperty(key)) {
          const element = response.data.data.rows[key];
          
          if(element.status === 1){
            status=<img style={{ width:"15px", height:"15px"}} src={Tick} alt='Active'/>
           } 
           else   {
            status=<img style={{ width:"15px", height:"15px"}} src={Cross} alt='Inactive'/>
           };
             var Obj=[
                      element.id,
                      element.trailerNo,
                      element.make.make,
                      element.model,                     
                      element.licensePlateNo,
                      element.year,                  
                      status,   
             ]
          
          arr.push(Obj);
          
        }
       
      }

      if(this._isMounted){
       this.setState({data:arr, isLoading: false ,count});
      }
      
     // console.log(arr);
     }).catch(function (error) {
       NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);         
       console.log(error);
     })         
     
   
     


}
    render() {

        const msgNone = {
            display: 'none'
          }
          const msgShow = {
            display: 'block',
          }
          const nonclick={
            cursor:' notAllowed',
            pointerEvents: 'none'
          }

          var year=[];

          for(var i=1900;i<2091;i++){
            year.push(i);
           }

        const {isLoading,  data, rowsPerPage, open, searchVal ,count,page} = this.state;
        const options = {      
          responsive: "scroll",
          selectableRows: false,
          rowHover:true,
          print:false,
          download:false,
          filter:false,
          search:false,
          rowsPerPageOptions :[25,50,100],
          rowsPerPage:rowsPerPage,
          viewColumns:false,
          textLabels: {
            body: {          
              toolTip: "",
            }},       
           onCellClick:this.startEditing,


           serverSide:true,
           count: count,
           page: page,
           onTableChange: this.onTableChange,
          
        };
         
       return(
         <div className="portlet box yellow-gold">
        <div className="portlet-title">
          <div className="caption"> <i className="fa fa-truck"></i> <span className="caption-subject"> Trailer </span> </div>
          <div className="actions">
            <div className="btn-group">
                {/* <a className="btn btn-circle btn-icon-only dark " data-toggle="modal" href="#basic2" style={{margin:" 0px 10px 0 0", lineHeight: "24px",  padding: "0 0", width: "28px", height: "28px"}}>
                    <i className="fa fa-question"></i>
                </a> */}
              <a  data-toggle="modal" href="#trailer_info" className="btn sbold white dark" onClick={this.ResetTabs.bind(this)} > Add New <i className="fa fa-plus"></i> </a>
              <a  data-toggle="modal" href="#viewtrailer_info" className="btn sbold white dark" id='trailercarrierinfo' style={{display:'none'}}> </a>
           
            </div>
          </div>
        </div>
        <div className="portlet-body">
          <div className="table-toolbar">
            <div style={{textAlign: 'right', paddingRight:' 10px', paddingBottom: '8px'}}>
            <span style={{float: "left", paddingLeft: "8px",  marginTop: "6px"}}>
              <div className="btnShowall">
                  <Button
                    buttonRef={node => {
                      this.anchorEl = node;
                    }}
                    aria-owns={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={this.handleToggle}
                  >
                    {this.state.btnVal} &nbsp;&nbsp;
                          <span className="fa fa-chevron-down"></span>
                  </Button>
               
                  <Popper open={open} anchorEl={this.anchorEl} transition disablePortal className="dropdown">
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        id="menu-list-grow"
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={this.handleClose}>
                            <MenuList>
                              <MenuItem onClick={(e) => this.showall("Show All", e)}>Show All</MenuItem>
                              <MenuItem onClick={(e) => this.active("Active", e)}>Active</MenuItem>
                              <MenuItem onClick={(e) => this.Inactive("Inactive", e)}>Inactive</MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </div>
                
              </span>
              <div style={{marginRight: '0px'}}>
               
                <div className="searchField form-control">
                  <input id="search-field" ref="search" name="searchinput" type="text" placeholder="Search..." onChange={this.handleSearch} onKeyPress={this.handleKeyPress}/>
                  <span style={searchVal ? msgShow : msgNone} className="removeSearchBtn" onClick={(e) => this.showall("Show All", e)}><i className="fa fa-close"></i></span>
                </div>
                <input name="searchbutton" style={{marginRight: "10px"}} className="btn green-meadow" type="submit" value="SEARCH" onClick={this.SetSearchParameters}/>
               
                {/* <img alt='' id="excel_export" src="../assets/apps/img/excel_button.png" title="Export data to Excel" />  */}
              
                </div>
            </div>
          </div>
          {isLoading ? <p style={{textAlign:"center"}}><img src={f} alt="Loader"/></p>:
          // <MuiThemeProvider theme={this.getMuiTheme()}>
          
          //     <div className='muitable'>
          //         <MUIDataTable
          //         // title={"ACME Employee list"}
          //         data={data}
          //         columns={this.columns}
          //         options={options}
          //       />
          //     </div>
          // </MuiThemeProvider >
          <MUiTable
           data={data}
           columns={this.columns}
           options={options}
          />
          }
          
        </div>
        
        <div class="modal fade" id="trailer_info" data-backdrop="static" data-keyboard="false" tabIndex="-1"  aria-hidden="true">
       <div class="modal-dialog  modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" onClick={this.cancel.bind(this)} aria-hidden="true"></button>
             
            </div>
            <div class="modal-body"> 
             
            <ul class="nav nav-tabs" hidden={this.state.hidden}>
              <li class="active border-green-jungle" id='trailertab0'> <a href="#tab_11" data-toggle="tab">Trailer  </a> </li>
              <li class="border-green-jungle" id='trailertab1'> <a href="#tab_12" data-toggle="tab"> Documents </a> </li>
             
            </ul>
            <div class="tab-content">
              <div class="tab-pane active" id="tab_11">
              <div className="portlet box yellow-gold">
                      <div className="portlet-title">
                        <div className="caption"> <i className="fa fa-truck"></i>Add Trailer Information</div>
                        <div className="actions" style={this.state.readonly?{}:nonclick} >
                          <div className="btn-group" style={this.state.hidden === false ? msgShow : msgNone} >
                              <button className="btn sbold white dark" id='trailerEditDiv' onClick={this.EditForm.bind(this)}  hidden={this.state.hidden} disabled ={this.state.hidden}>
                                  <i className="fa fa-pencil"></i> Edit
                              </button>
                          </div>
                        </div>
                      </div>

                      <div className="portlet-body form"> 
                      
                      <form  onSubmit={this.submituserRegistrationForm.bind(this)} id='trailerform' className="horizontal-form" autoComplete="off" >
                        <div className="form-body max_width800">
                          <div className="portlet-title">
                            <h2 className="h3"> Trailer Info 
                            <span style={{float: 'right', paddingLeft:' 8px', marginTop: '2px'}} >
                            <div className="btn-group btn-group-devided" data-toggle="buttons" style={this.state.readonly?nonclick:msgShow} >
                              <label className="btn btn-transparent dark btn-outline btn-circle btn-sm active" id='traileractive' disabled={this.state.readonly} onClick={()=>this.setState({status:1})}>
                                <input type="radio" name="options" className="toggle" id="option1"   />
                                Active</label>
                              <label className="btn btn-transparent dark btn-outline btn-circle btn-sm" id='trailerinactive' disabled={this.state.readonly} onClick={()=>this.setState({status:0})}>
                                <input type="radio" name="options"  className="toggle"  id="option2" />
                                Inactive</label>
                            </div>
                            </span>
                             </h2>
                            
                            <div className="clearfix"></div>
                            <hr/>
                          </div>

                          <div className="form-group row">
                            <label className="col-md-3 control-label">Trailer #: <span className="font-red-thunderbird" >*</span></label>
                            <div className="col-md-9">
                            <input type="text" ref="trailer" onChange={this.handleChange} name="trailer" className="form-control" placeholder=""   readOnly={this.state.readonly}/>
                            <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.trailer}</div>
                            </div>
                          </div>                          
                          
                      

                      <div className="form-group row">
                          <label className="col-md-3 control-label">Trailer Type:<span className="font-red-thunderbird" >*</span></label>
                          <div className="col-md-9" disabled={this.state.readonly}>
                             
                            <select name="trailertype" ref="trailertype" className="form-control" defaultValue='' onChange={()=>this.setState({Trailertype:this.refs.trailertype.value})}   disabled={this.state.readonly} readOnly={this.state.readonly}>
                            <option value=""  disabled hidden>Select Type</option>                              
                            <option value="Dry Van"> Dry Van </option>
                            <option value="Reefer"> Reefer </option>
                            </select>
                            <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.trailertype}</div>
                             
                          </div>
                       </div>

                       <div className="form-group row">
                          <label className="col-md-3 control-label">Trailer Size:<span className="font-red-thunderbird" >*</span></label>
                          <div className="col-md-9" disabled={this.state.readonly}>
                              
                            <select name="trailersize" ref="trailersize" className="form-control" defaultValue=''   disabled={this.state.readonly} readOnly={this.state.readonly}>
                            <option value=""  disabled hidden>Select Size</option>                              
                            <option value="53'"> 53' </option>
                            <option value="48'"> 48' </option>
                            </select>
                            <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.trailersize}</div>

                             
                          </div>
                       </div>

                      <div className="form-group row">
                            <label className="col-md-3 control-label">Make: <span className="font-red-thunderbird" >*</span></label>
                            <div className="col-md-9">
                            <select name="make" ref="make" className="form-control" defaultValue=''    disabled={this.state.readonly} readOnly={this.state.readonly}>
                            <option value=""  disabled hidden>Select Make</option>                              
                              {
                                this.state.make.map(function (data) {
                                  return <option value={data.id}> {data.make} </option>;
                                })

                              }
                            </select>
                            <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.make}</div>
                            </div>
                          </div>

                         <div className="form-group row">
                            <label className="col-md-3 control-label">Model: <span className="font-red-thunderbird" ></span></label>
                            <div className="col-md-9">
                            <input type="text" ref="model" onChange={this.handleChange} name="model" className="form-control" placeholder=""   readOnly={this.state.readonly}/>
                            <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.model}</div>
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-md-3 control-label">Year: <span className="font-red-thunderbird" >*</span></label>
                            <div className="col-md-9">
                            <select name="year" ref="year" className="form-control" defaultValue={new Date().getFullYear()}    disabled={this.state.readonly} readOnly={this.state.readonly}>
                            <option value=""  disabled hidden>Select Year</option>                            
                              {
                                year.map(function (data) {
                                 
                                  return <option value={data}> {data} </option>;
                                })

                              }
                            </select>
                            <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.year}</div>
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-md-3 control-label">Vin #: <span className="font-red-thunderbird" ></span></label>
                            <div className="col-md-9">
                            <input type="text" ref="vin" onChange={this.handleChange} name="vin" className="form-control" placeholder=""   readOnly={this.state.readonly}/>
                            <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.vin}</div>
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-md-3 control-label">Color: <span className="font-red-thunderbird" ></span></label>
                            <div className="col-md-9">
                            <input type="text" ref="color" onChange={this.handleChange} name="color" className="form-control" placeholder=""   readOnly={this.state.readonly}/>
                            <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.color}</div>
                            </div>
                          </div>



                          

                         <div class="portlet-title">
                        <h2 class="h3 checkbox-inline" style={{padding: '0',margin:'0'}}> License Plate Information </h2>
                        <hr/>
                        </div>                          
                        <div className="form-group row">
                            <label className="col-md-3 control-label">License  Plate No.<span className="font-red-thunderbird" >*</span></label>
                            <div className="col-md-9">
                            <input type="text" ref="driverLicense" onChange={this.handleChange} name="DriverLicense" className="form-control" placeholder=""   readOnly={this.state.readonly}/>
                            <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.DriverLicense}</div>
                            </div>
                         </div>
                         <div className="form-group row">
                            <label className="col-md-3 control-label">State <span className="font-red-thunderbird" >*</span></label>
                            <div className="col-md-9">
                            <select name="state" ref="province_id" className="form-control" defaultValue='' disabled={this.state.readonly} readOnly={this.state.readonly}>
                            <option value=""  disabled hidden>Select State</option>                              
                              {
                                this.state.states.map(function (data) {
                                  return <option value={data.id}> {data.state} </option>;
                                })

                              }
                            </select>
                            <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.state}</div>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-md-3 control-label">Expiration Date</label>
                            <div className="col-md-9">

                                     <DatePicker
                                        className='form-control'                                                                                                                                                    
                                        selected={this.state.LicExpDate}
                                        onChange={ async (date)=> {
                                         await this.setState({
                                          LicExpDate: date
                                        });
                                      console.log(date);
                                      this.validateForm();
                                    }}
                                      readOnly={this.state.readonly}                                      />
                               <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.LicExpDate}</div>
                          
                            </div>
                          </div> 
                      
                      <div  hidden={this.state.Trailertype === 'Reefer'?false:true}>
                       <div class="portlet-title">
                        <h2 class="h4" style={{fontWeight:'700'}}> Reefer Info</h2>
                        <hr/>
                      </div>

                           <div className="form-group row">
                            <label className="col-md-3 control-label">Make: <span className="font-red-thunderbird" >*</span></label>
                            <div className="col-md-9">
                            <select name="refeermake" ref="refeermake" className="form-control" defaultValue=''   disabled={this.state.readonly} readOnly={this.state.readonly}>
                                  <option value=""  disabled hidden>Select Make</option>  
                                  <option value='Carrier'>Carrier  </option>
                                  <option value='Thermo king'>Thermo king </option>
                                  <option value='Other'>Other  </option>
                            </select>
                            <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.refeermake}</div>

                            </div>
                          </div>

                         <div className="form-group row">
                            <label className="col-md-3 control-label">Model: <span className="font-red-thunderbird" ></span></label>
                            <div className="col-md-9">
                            <input type="text" ref="refeermodel" onChange={this.handleChange} name="refeermodel" className="form-control" placeholder=""   readOnly={this.state.readonly}/>
                            <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.refeermodel}</div>
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-md-3 control-label">Year: <span className="font-red-thunderbird" >*</span></label>
                            <div className="col-md-9">
                            <select name="refeeryear" ref="refeeryear" className="form-control"  defaultValue={new Date().getFullYear()}   disabled={this.state.readonly} readOnly={this.state.readonly}>
                            <option value="" selected disabled hidden>Select Year</option>                            
                              {
                                year.map(function (data) {                                 
                                  return <option value={data}> {data} </option>;
                                })

                              }
                            </select>
             
                            <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.refeeryear}</div>
                            </div>
                          </div>

                          </div>



                      
                         
                        </div>      
                        <div className="clearfix"></div>
                        <div className="form-actions right">
                          <button  onClick={ this.cancel.bind(this)} type="button"  data-dismiss="modal" className="btn default">Cancel</button> &nbsp;
                          <button  disabled={this.state.readonly} type="submit" className="btn green-meadow">
                            {this.state.whileSave === false 
                                ? <i className='fa fa-save'></i> 
                                : <i className="fa fa-circle-o-notch fa-spin"></i>}                              
                            Save 
                          </button>
                        </div>
                         
                      </form>
                  </div>
              
                   
                     </div>
                
              </div>
              <div class="tab-pane" id="tab_12">
              <div className="portlet box yellow-gold">
                  <div className="portlet-title">
                    <div className="caption"> <i className="fa fa-file"></i> Documents </div>
                  </div>
                  <div className="portlet-body form">
                  
                    <form action="#" className="horizontal-form" _lpchecked="1">
                      <div className="form-body max_width800">
                        <div className="form-group row">
                          <label className="col-md-4 control-label"> Registration Document </label>
                          <div className="col-md-8 fileinput ">


                              <div className="input-group input-large doc_custom">
                                  <div className="form-control uneditable-input input-fixed input-medium" data-trigger="fileinput">
                                      <i className="fa fa-file fileinput-exists"></i>&nbsp;
                                      <span className="fileinput-filename">{this.state.file.License.name}</span>
                                  </div>
                                  <span className="input-group-addon btn default btn-file">
                                      <span className="fileinput-new"> Upload file </span>                                      
                                      <input type="file" id='1' name='doc' onChange= {this.onFileChange}/> </span>
                                      {
                                        this.state.Licenseload?
                                        <button hidden={this.state.file.License.name?false:true }   className="input-group-addon btn green fileinput-exists"  id='1' onClick={this.download}> <i id='1'  style={{color:'#fff'}}  class="fa fa-eye"></i> </button>:
                                        <button className="input-group-addon btn green fileinput-exists"  id='1' > <i id='1'  style={{color:'#fff'}}  class='fa fa-circle-o-notch fa-spin'></i> </button> 
                                      }
                                     
                                      <button  hidden={this.state.file.License.name?false:true} className="input-group-addon btn red fileinput-exists"  id='1' onClick={this.deleteFile}> <i id='1' style={{color:'#fff'}} class="fa fa-trash"></i> </button>
                                     
                               </div>    
                               <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.doc}</div>                                                
                          </div>
                        </div>
                    
                        
                        
                      </div>
                      <div className="clearfix"></div>
                                  
                    </form>
                  
                  </div>
                </div>
                </div>
              </div>
           
             
            </div>
          </div>
            </div>
        
       
    </div>
      <div class="modal fade" id="viewtrailer_info" data-backdrop="static" data-keyboard="false" tabIndex="-1"  aria-hidden="true">
       <div class="modal-dialog  modal-lg"> 
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal"  onClick={this.cancel.bind(this)} aria-hidden="true"></button>
             
            </div>
            <div class="modal-body"> 
              
            <ul class="nav nav-tabs">
              <li class="active border-green-jungle" id='trailerviewtab0'> <a href="#tab_13" data-toggle="tab">Trailer  </a> </li>
              <li class="border-green-jungle" id='trailerviewtab1'> <a href="#tab_14" data-toggle="tab"> Documents </a> </li>             
            </ul>

            <div class="tab-content">
              <div class="tab-pane active" id="tab_13">
              <div className="portlet box yellow-gold">
                      <div className="portlet-title">
                        <div className="caption"> <i className="fa fa-truck"></i> Trailer Information</div>
                        <div className="actions"style={this.state.readonly?{}:nonclick} >
                          <div className="btn-group"  >
                              <button className="btn sbold white dark" id='trailerviewEditDiv' onClick={this.EditForm.bind(this)} >
                                  <i className="fa fa-pencil"></i> Edit
                              </button>
                          </div>
                        </div>
                      </div>
                      <div className="portlet-body form"> 
                      
                      <form  onSubmit={this.submitEditRegistrationForm.bind(this)} id='trailerviewform' className="horizontal-form" autoComplete="off" >
                        <div className="form-body max_width800">
                          <div className="portlet-title">
                            <h2 className="h3"> Trailer Info 
                            <span style={{float: 'right', paddingLeft:' 8px', marginTop: '2px' }} >
                            <div className="btn-group btn-group-devided" data-toggle="buttons" style={this.state.readonly?nonclick:msgShow} >
                              <label className="btn btn-transparent dark btn-outline btn-circle btn-sm " id='opt1' disabled={this.state.readonly} onClick={()=>this.setState({status:1})}>
                                <input type="radio" name="options" className="toggle" id="option1"   />
                                Active</label>
                              <label className="btn btn-transparent dark btn-outline btn-circle btn-sm" id='opt2' disabled={this.state.readonly} onClick={()=>this.setState({status:0})}>
                                <input type="radio" name="options"  className="toggle"  id="option2" />
                                Inactive</label>
                            </div>
                            </span>
                             </h2>
                            
                            <div className="clearfix"></div>
                            <hr/>
                          </div>

                          <div className="form-group row">
                            <label className="col-md-3 control-label">Trailer #: <span className="font-red-thunderbird" >*</span></label>
                            <div className="col-md-9">
                            <input type="text" ref="modifytrailer" onChange={this.handleChange} name="modifytrailer" className="form-control" placeholder="" defaultValue={this.state.TrailerFormData.trailerNo}  readOnly={this.state.readonly}/>
                            <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.modifytrailer}</div>
                            </div>
                          </div>                          
                          
                      

                      <div className="form-group row">
                          <label className="col-md-3 control-label">Trailer Type:<span className="font-red-thunderbird" >*</span></label>
                          <div className="col-md-9" disabled={this.state.readonly}>
                             
                            <select name="modifytrailertype" ref="modifytrailertype" className="form-control" title="State/Province" onChange={()=>this.setState({Trailertype:this.refs.modifytrailertype.value})}   disabled={this.state.readonly} readOnly={this.state.readonly}>
                            <option value={this.state.TrailerFormData.trailerType} selected disabled hidden>{this.state.TrailerFormData.trailerType}</option>                              

                            <option value="Dry Van"> Dry Van </option>
                            <option value="Reefer"> Reefer </option>
                            </select>
                            <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.modifytrailertype}</div>
                             
                          </div>
                       </div>

                       <div className="form-group row">
                          <label className="col-md-3 control-label">Trailer Size:<span className="font-red-thunderbird" >*</span></label>
                          <div className="col-md-9" disabled={this.state.readonly}>
                          

                            <select name="modifytrailersize" ref="modifytrailersize" className="form-control"     disabled={this.state.readonly} readOnly={this.state.readonly}>
                            <option value={this.state.TrailerFormData.trailerSize} selected disabled hidden>{this.state.TrailerFormData.trailerSize}</option>                              
                            <option value="53'"> 53' </option>
                            <option value="48'"> 48' </option>
                            </select>
                            <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.modifytrailersize}</div>
                             
                          </div>
                       </div>

                      <div className="form-group row">
                            <label className="col-md-3 control-label">Make: <span className="font-red-thunderbird" >*</span></label>
                            <div className="col-md-9">
                            <select name="modifymake" ref="modifymake" className="form-control"   disabled={this.state.readonly} readOnly={this.state.readonly}>
                                                     
                              {
                                this.state.make.map(function (data) {
                                  if(this.state.TrailerFormData.makeId===data.id)
                                  return <option value={data.id} selected> {data.make} </option>;
                                  return <option value={data.id}> {data.make} </option>;
                                },this)

                              }
                            </select>
                            <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.modifymake}</div>
                            </div>
                          </div>

                         <div className="form-group row">
                            <label className="col-md-3 control-label">Model: <span className="font-red-thunderbird" ></span></label>
                            <div className="col-md-9">
                            <input type="text" ref="modifymodel" onChange={this.handleChange} name="modifymodel" className="form-control" defaultValue={this.state.TrailerFormData.model} placeholder=""   readOnly={this.state.readonly}/>
                            <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.modifymodel}</div>
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-md-3 control-label">Year: <span className="font-red-thunderbird" >*</span></label>
                            <div className="col-md-9">
                              <select name="modifyyear" ref="modifyyear" className="form-control"     disabled={this.state.readonly} readOnly={this.state.readonly}>                                                     
                                  {
                                    year.map(function (data) {
                                      if( Number(this.state.TrailerFormData.year) === data)
                                      return <option value={data} selected> {data} </option>;
                                      return <option value={data}> {data} </option>;
                                    },this)    
                                  }
                               </select>
                            <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.modifyyear}</div>
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-md-3 control-label">Vin #: <span className="font-red-thunderbird" ></span></label>
                            <div className="col-md-9">
                            <input type="text" ref="modifyvin" onChange={this.handleChange} name="modifyvin" className="form-control" defaultValue={this.state.TrailerFormData.vinNo} placeholder=""   readOnly={this.state.readonly}/>
                            <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.modifyvin}</div>
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-md-3 control-label">Color: <span className="font-red-thunderbird" ></span></label>
                            <div className="col-md-9">
                            <input type="text" ref="modifycolor" onChange={this.handleChange} name="modifycolor" className="form-control" defaultValue={this.state.TrailerFormData.color} placeholder=""   readOnly={this.state.readonly}/>
                            <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.modifycolor}</div>
                            </div>
                          </div>



                          

                         <div class="portlet-title">
                        <h2 class="h3 checkbox-inline" style={{padding: '0',margin:'0'}}> License Plate Information </h2>
                        <hr/>
                        </div>                          
                        <div className="form-group row">
                            <label className="col-md-3 control-label">License  Plate No.<span className="font-red-thunderbird" >*</span></label>
                            <div className="col-md-9">
                            <input type="text" ref="modifydriverLicense" onChange={this.handleChange} name="modifyDriverLicense" className="form-control" defaultValue={this.state.TrailerFormData.licensePlateNo} placeholder=""   readOnly={this.state.readonly}/>
                            <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.modifyDriverLicense}</div>
                            </div>
                         </div>
                         <div className="form-group row">
                            <label className="col-md-3 control-label">State <span className="font-red-thunderbird" >*</span></label>
                            <div className="col-md-9">
                            <select name="modifystate" ref="modifyprovince_id" className="form-control"     disabled={this.state.readonly} readOnly={this.state.readonly}>
                                                       
                              {
                                this.state.states.map(function (data) {
                                  if(this.state.TrailerFormData.state.id === data.id)
                                  return <option value={data.id} selected> {data.state} </option>;
                                  return <option value={data.id}> {data.state} </option>;
                                },this)

                              }
                            </select>
                            <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.modifystate}</div>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-md-3 control-label">Expiration Date</label>
                            <div className="col-md-9">

                                    <DatePicker
                                        className='form-control'                                                                                                                                                    
                                        selected={this.state.modifyLicExpDate}
                                        onChange={ async (date)=> {
                                         await this.setState({
                                            modifyLicExpDate: date
                                        });
                                      console.log(date);
                                      this.validateForm();
                                    }}
                                      readOnly={this.state.readonly}                                      />
                               <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.modifyLicExpDate}</div>
                          
                            </div>
                          </div> 
                      
                      <div  hidden={this.state.Trailertype === "Reefer"?false:true}>
                       <div class="portlet-title">
                        <h2 class="h4" style={{fontWeight:'700'}}> Reefer Info</h2>
                        <hr/>
                      </div>

                           <div className="form-group row">
                            <label className="col-md-3 control-label">Make: <span className="font-red-thunderbird" >*</span></label>
                            <div className="col-md-9">
                            <select name="modifyrefeermake" ref="modifyrefeermake" className="form-control"     disabled={this.state.readonly} readOnly={this.state.readonly}>
                                  
                                  <option value={this.state.TrailerFormData.reeferMake} selected disabled hidden>{this.state.TrailerFormData.reeferMake}</option>  
                                  
                                  
                                  <option value='Carrier'>Carrier  </option>
                                  <option value='Thermo king'>Thermo king  </option>
                                  <option value='Other'>Other  </option>
                            </select>

                            <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.modifyrefeermake}</div>

                            </div>
                          </div>

                         <div className="form-group row">
                            <label className="col-md-3 control-label">Model: <span className="font-red-thunderbird" ></span></label>
                            <div className="col-md-9">
                            <input type="text" ref="modifyrefeermodel" onChange={this.handleChange} name="modifyrefeermodel" defaultValue={this.state.TrailerFormData.reeferModel} className="form-control" placeholder=""   readOnly={this.state.readonly}/>
                            <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.modifyrefeermodel}</div>
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-md-3 control-label">Year: <span className="font-red-thunderbird" >*</span></label>
                            <div className="col-md-9">
                            <select name="modifyrefeeryear" ref="modifyrefeeryear" className="form-control"     disabled={this.state.readonly} readOnly={this.state.readonly}>
                                                       
                              {
                                year.map(function (data) {
                                  if( Number(this.state.TrailerFormData.reeferYear) === data )
                                  return <option value={data} selected> {data} </option>;
                                  return <option value={data}> {data} </option>;
                                },this)

                              }
                            </select>
             
                            <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.modifyrefeeryear}</div>
                            </div>
                          </div>
                          </div>


                      
                         
                        </div>      
                        <div className="clearfix"></div>
                        <div className="form-actions right">
                          <button  onClick={ this.cancel.bind(this)} type="button"  data-dismiss="modal"  className="btn default">Cancel</button> &nbsp;
                          <button  disabled={this.state.readonly} type="submit" className="btn green-meadow">
                            {this.state.whileSave === false 
                                ? <i className='fa fa-save'></i> 
                                : <i className="fa fa-circle-o-notch fa-spin"></i>}                              
                            Save 
                          </button>
                        </div>
                        
                      </form>
                  </div>
                 </div>
                
              </div>
              <div class="tab-pane" id="tab_14">
              <div className="portlet box yellow-gold">
                  <div className="portlet-title">
                    <div className="caption"> <i className="fa fa-file"></i> Documents </div>
                  </div>
                  <div className="portlet-body form">
                  
                    <form action="#" className="horizontal-form" _lpchecked="1">
                      <div className="form-body max_width800">
                        <div className="form-group row">
                          <label className="col-md-4 control-label"> Registration Document</label>
                          <div className="col-md-8 fileinput ">


                              <div className="input-group input-large doc_custom">
                                  <div className="form-control uneditable-input input-fixed input-medium" data-trigger="fileinput">
                                      <i className="fa fa-file fileinput-exists"></i>&nbsp;
                                      <span className="fileinput-filename">{this.state.file.License.name}</span>
                                  </div>
                                  <span className="input-group-addon btn default btn-file">
                                      <span className="fileinput-new"> Upload file </span>                                      
                                      <input type="file" id='1' name='doc' onChange= {this.onFileChange}/> </span>
                                      {
                                        this.state.Licenseload?
                                        <button hidden={this.state.file.License.name?false:true }   className="input-group-addon btn green fileinput-exists"  id='1' onClick={this.download}> <i id='1'  style={{color:'#fff'}}  class="fa fa-eye"></i> </button>:
                                        <button className="input-group-addon btn green fileinput-exists"  id='1' > <i id='1'  style={{color:'#fff'}}  class='fa fa-circle-o-notch fa-spin'></i> </button> 
                                      }
                                     
                                      <button  hidden={this.state.file.License.name?false:true} className="input-group-addon btn red fileinput-exists" id='1' onClick={this.deleteFile}> <i id='1' style={{color:'#fff'}} class="fa fa-trash"></i> </button>
                                     
                                      </div>
                                      <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.doc}</div>                                                  
                          </div>
                        </div>
                    
                        
                        
                      </div>
                      <div className="clearfix"></div>
                                 
                    </form>
                  
                  </div>
                </div>
                </div>
              </div>
           
             
            </div>
          </div>
            </div>
        </div>
       
    </div>
   
     

     
    );
}
}

export default withRouter(TrailerTab);