
/* eslint-disable */

import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";

import axios from 'axios';
import MUiTable from './MuiTable'

import Sidebar from './sidebar';
import Header from './Head';
import BaseURL from './config';
import TokenErrorMsg from './errorMsg';
import Tick from './green-tick.jpg';
import Cross from './red-cross.jpg';
import f from './Loading_icon.gif';
import { NotificationManager } from 'react-notifications';
import Select from "react-virtualized-select";

import tenantUser from './TenantUserPermision';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from "@material-ui/core/MenuItem";
import { getPersistentTableState, setPersistentTableState } from './LocalStorage';


const Cryptr = require('cryptr');
const cryptr = new Cryptr('@$#rgweR35*&YFSD)/');





class Carrier extends Component {
  _isMounted = false
  constructor(props) {
    super(props)
    this.state = {
      CustomerSelectedOption: null,
      CustomerOptions: [],
      CarrierSelectedOption: null,
      CarrierOptions: [],

      advanceSearchFormValues: {
        sfCustomerName: "",
        sfCarrierName: "",
        // sfPickupCity: "",
        // sfDeliveryCity: "",
        sfShipper: "",
        sfReciever: ""
      },
      showAdvanceFilter: false,
      page: 0,
      data: [],
      status: 2,
      isLoading: false,
      rowsPerPage: 50,
      btnVal: "Show All",
      searchVal: false,
      token: "",
      role: "Tenant",
      open: false,
      offset: 0,
      limit: 50,
      order: "ASC",
      sortByColumn: "carrierName",
      count: "",
      search: ""
    }
    this.startEditing = this.startEditing.bind(this)
  }

  columns = [
    {
      name: "ID",
      options: {
        display: false
      }
    },
    {
      name: "carrierName",
      label: "Carrier Name",
      options: {
        sort: true
      }
    },
    {
      name: "mcNo",
      label: "MC#",
      options: {
        sort: true
      }
    },
    {
      name: "carrierName",
      label: "C-N bk pending",
      options: {
        sort: true,
        display: false
      }
    },
    {
      name: "address",
      label: "Address",
      options: {
        sort: true
      }
    },
    {
      name: "email",
      label: "Email",
      options: {
        sort: true
      }
    },
    {
      name: "telephone",
      label: "Telephone",
      options: {
        sort: true
      }
    },
    {
      name: "Status",
      options: {
        sort: false
      }
    }
  ]

  handleChangeCarrier = (selectedOption) => {
    var event = selectedOption ? selectedOption.value : null

    var selectedValue = selectedOption ? selectedOption.label : null
    let setValue = this.state.advanceSearchFormValues
    setValue.sfCarrierName = selectedValue
    this.setState({ advanceSearchFormValues: setValue })

    if (this._isMounted) {
      this.setState({ CarrierSelectedOption: event })
    }
  }
  handleChangeCustomer = async (selectedOption) => {
    var event = selectedOption ? selectedOption.value : null
    var selectedValue = selectedOption ? selectedOption.label : null
    let setValue = this.state.advanceSearchFormValues
    setValue.sfCustomerName = selectedValue
    this.setState({ advanceSearchFormValues: setValue })

    if (event) {
      if (this._isMounted) {
        this.setState({ CustomerSelectedOption: event })
      }

      this.state.customerOptionsData.forEach((element) => {
        if (element.id === event) {
          this.setState({
            customerCard: {
              address: element.address.address,
              city: element.address.city,
              state: element.address.state.state
            }
          })
        }
      })

      var arr = [],
        options = []
      await axios
        .post(BaseURL + "customerContact/findByCustomerId", {
          token: this.state.token,
          customerId: event
        })
        .then((response) => {
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem("Token", "")
            this.props.history.push("/")
          }
          arr = response.data.data
        })
        .catch(function(error) {
          NotificationManager.error(
            "Something Went Wrong Server Is Not Responding ",
            "Error Message",
            3000
          )
          console.log(error)
        })

      arr.forEach((element) => {
        var obj = { value: element.id, label: element.name }

        options.push(obj)
      })

      if (this._isMounted) {
        this.setState({
          ContactOptions: options,
          contactOptionsData: arr,
          contactCard: {}
        })
      }
    } else {
      if (this._isMounted) {
        this.setState({
          CustomerSelectedOption: event,
          ContactOptions: [],
          ContactSelectedOption: event,
          customerCard: {},
          contactCard: {}
        })
      }
    }
  }
  async componentDidMount() {
    const token = localStorage.getItem("Token")
    this._isMounted = true

    var role = "Tenant"
    const Role = localStorage.getItem("Role")
    if (Role) {
      role = cryptr.decrypt(Role)
      console.log(role, "000000000000000000000000")
    }

    const tableState = getPersistentTableState("carrier")

    this.columns.find(
      (col) => col.name === tableState.sortByColumn
    ).options.sortDirection = tableState.order.toLowerCase()

    if (this._isMounted) {
      await this.setState({
        token: token,
        order: tableState.order,
        sortByColumn: tableState.sortByColumn,
        btnVal: tableState.btnVal,
        status: tableState.status,
        search: tableState.search,
        isLoading: true,
        searchVal: tableState.search ? true : false,
        page: tableState.page,
        offset: tableState.offset,
        limit: tableState.limit,
        rowsPerPage: tableState.limit,
        role: role
      })
      tableState.search ? this.search() : this.fetchPaginationData()
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  async search() {
    if (this.refs.search.value.trim()) {
      var arr = [],
        count
      var status
      this.setLocalStorage()

      await axios
        .post(BaseURL + "carrierInformation/findByName", {
          searchKeyword: this.refs.search.value.trim(),
          offset: this.state.offset,
          limit: this.state.limit,
          sortOrder: this.state.order,
          sortBy: this.state.sortByColumn,
          status: this.state.status,
          token: this.state.token
        })
        .then((response) => {
          console.log(response)
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem("Token", "")
            this.props.history.push("/")
          }

          count = response.data.data.count

          for (const key in response.data.data.rows) {
            if (response.data.data.rows.hasOwnProperty(key)) {
              const element = response.data.data.rows[key]

              if (element.status === 1) {
                status = (
                  <img
                    style={{ width: "15px", height: "15px" }}
                    src={Tick}
                    alt="Active"
                  />
                )
              } else {
                status = (
                  <img
                    style={{ width: "15px", height: "15px" }}
                    src={Cross}
                    alt="Inactive"
                  />
                )
              }
              console.log(
                element,
                "elementelementelementelementelementelementelementelementelementelementelementelement"
              )
              var Obj = [
                element.id,
                element.carrierName,
                element.mcNo,
                element.carrierName,
                element.address.address +
                  ", " +
                  (element.address.addressLineTwo
                    ? element.address.addressLineTwo + ", "
                    : "") +
                  element.address.city +
                  ", " +
                  element.address.state.state +
                  ", " +
                  element.address.zip,
                element.address.email,
                element.address.telephone,
                status
              ]

              arr.push(Obj)
            }
          }
        })
        .catch(function(error) {
          NotificationManager.error(
            "Something Went Wrong Server Is Not Responding ",
            "Error Message",
            3000
          )
          console.log(error)
        })

      if (this._isMounted) {
        this.setState({ data: arr, isLoading: false, count })
      }
    } else {
      alert("Please Type Something in Search Bar")
    }
  }

  async showall(theme, e) {
    this.refs.search ? (this.refs.search.value = "") : ""
    if (this._isMounted) {
      await this.setState({
        searchVal: false,
        isLoading: true,
        status: 2,
        open: false,
        anchorEl: null,
        btnVal: theme,
        page: 0,
        offset: 0,
        order: "ASC",
        sortByColumn: "carrierName",
        search: "",
        solumn: "carrierName"
      })
      this.fetchPaginationData()
    }
  }

  startEditing(colData, cellMeta) {
    if (window.getSelection().toString()) {
      return false
    } else if (cellMeta.colIndex === 1) {
      var data = this.state.data
      var id = data[cellMeta.dataIndex][0]
      // console.log('Cell meta ',colData,cellMeta,data[cellMeta.dataIndex])
      this.props.history.push("/Editcarrier/" + id)
    }
  }

  handleSearch = async (e) => {
    if (this.refs.search.value) {
      if (this._isMounted) {
        this.setState({ searchVal: true, search: e.target.value })
      }
    } else {
      this.showall("Show All", this)
    }
  }

  handleKeyPress = (e) => {
    if (event.key === "Enter") {
      this.SetSearchParameters()
    }
  }
  SetSearchParameters = async () => {
    if (this._isMounted && this.refs.search.value.trim()) {
      await this.setState({
        isLoading: true,
        page: 0,
        offset: 0,
        order: "ASC",
        sortByColumn: "carrierName"
      })
      solumn: "carrierName", this.search()
    } else {
      alert("Please Type Something in Search Bar")
    }
  }

  handleToggle = (event) => {
    if (this._isMounted) {
      this.setState((state) => ({
        open: !state.open,
        anchorEl: event.currentTarget
      }))
    }
  }

  handleClose = (event) => {
    if (this.anchorEl.contains(event.target)) {
      return
    }
    if (this._isMounted) {
      this.setState({ open: false })
    }
  }

  async active(theme, e) {
    if (this._isMounted) {
      this.refs.search.value = ""

      await this.setState({
        searchVal: false,
        isLoading: true,
        status: 1,
        open: false,
        anchorEl: null,
        btnVal: theme,
        page: 0,
        offset: 0,
        order: "ASC",
        sortByColumn: "carrierName",
        search: "",
        solumn: "carrierName"
      })
      this.fetchPaginationData()
    }
  }

  async Inactive(theme, e) {
    if (this._isMounted) {
      this.refs.search.value = ""
      await this.setState({
        searchVal: false,
        isLoading: true,
        status: 0,
        open: false,
        anchorEl: null,
        btnVal: theme,
        page: 0,
        offset: 0,
        order: "ASC",
        sortByColumn: "carrierName",
        search: "",
        solumn: "carrierName"
      })
      this.fetchPaginationData()
    }
  }

  onTableChange = async (action, tableState) => {
    console.log("action", action)
    console.log("state", tableState)

    switch (action) {
      case "changePage":
        this.changePage(tableState)
        break

      case "changeRowsPerPage":
        this.changeRowsPerPage(tableState)
        break

      case "sort":
        this.sort(tableState)
        break

      default:
        break
    }
  }

  changePage = async (tableState) => {
    var offset = this.state.offset

    if (tableState.page > this.state.page) {
      // Next Icon Clicked >
      var tempOffset = offset + tableState.rowsPerPage
      if (this._isMounted) {
        await this.setState({
          offset: tempOffset, //50
          limit: tableState.rowsPerPage, // 76
          page: tableState.page
        })
        ;(await this.state.searchVal)
          ? this.search()
          : this.fetchPaginationData()
      }
    } else {
      var tempLimit = tableState.rowsPerPage
      var tempOffset = offset - tempLimit
      if (tempOffset < 0) {
        tempOffset = 0
      }

      if (this._isMounted) {
        await this.setState({
          limit: tempLimit,
          offset: tempOffset,
          page: tableState.page
        })
        ;(await this.state.searchVal)
          ? this.search()
          : this.fetchPaginationData()
      }
    }
  }

  changeRowsPerPage = async (tableState) => {
    if (this._isMounted) {
      await this.setState({
        limit: tableState.rowsPerPage, // 100
        offset: 0,
        rowsPerPage: tableState.rowsPerPage,
        page: 0
      })
      this.state.searchVal ? this.search() : this.fetchPaginationData()
    }
  }

  sort = async (tableState) => {
    var sortByColumn = this.state.sortByColumn
    var order = this.state.order

    var sortOrder =
      tableState["columns"][tableState.activeColumn].name === sortByColumn
        ? order === "ASC"
          ? "DESC"
          : "ASC"
        : "ASC"

    if (this._isMounted) {
      await this.setState({
        order: sortOrder,
        sortByColumn: tableState["columns"][tableState.activeColumn].name
      })

      this.state.searchVal ? this.search() : this.fetchPaginationData()
    }
  }

  fetchPaginationData = () => {
    //console.log('TableState Data',state.data)
    var postData = {
      token: this.state.token,
      status: this.state.status,
      offset: this.state.offset,
      limit: this.state.limit,
      sortOrder: this.state.order,
      sortBy: this.state.sortByColumn
    }

    this.setLocalStorage()

    var arr = []
    //console.log('state',arr)
    var status, count
    axios
      .post(BaseURL + "carrierInformation/", postData, {
        headers: { "Content-Type": "application/json" }
      })
      .then((response) => {
        console.log(response.data.data)
        console.log(
          response.data.data,
          "response.data.dataresponse.data.dataresponse.data.dataresponse.data.dataresponse.data.dataresponse.data.data"
        )
        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem("Token", "")
          this.props.history.push("/")
        }

        count = response.data.data.count

        for (const key in response.data.data.rows) {
          if (response.data.data.rows.hasOwnProperty(key)) {
            const element = response.data.data.rows[key]

            if (element.status === 1) {
              status = (
                <img
                  style={{ width: "15px", height: "15px" }}
                  src={Tick}
                  alt="Active"
                />
              )
            } else {
              status = (
                <img
                  style={{ width: "15px", height: "15px" }}
                  src={Cross}
                  alt="Inactive"
                />
              )
            }
            console.log(
              element,
              "elementelementelementelementelementelementelementelementelementelementelementelement"
            )
            var Obj = [
              element.id,
              element.carrierName,
              element.mcNo,
              element.carrierName,
              element.address.address +
                ", " +
                (element.address.addressLineTwo
                  ? element.address.addressLineTwo + ", "
                  : "") +
                element.address.city +
                ", " +
                element.address.state.state +
                ", " +
                element.address.zip,
              element.address.email,
              element.address.telephone,
              status
            ]

            arr.push(Obj)
          }
        }

        if (this._isMounted) {
          this.setState({ data: arr, isLoading: false, count })
        }

        // console.log(arr);
      })
      .catch(function(error) {
        NotificationManager.error(
          "Something Went Wrong Server Is Not Responding ",
          "Error Message",
          3000
        )
        console.log(error)
      })
  }

  setLocalStorage = () => {
    const table = {
      order: this.state.order,
      sortByColumn: this.state.sortByColumn,
      btnVal: this.state.btnVal,
      status: this.state.status,
      search: this.state.search,
      page: this.state.page,
      offset: this.state.offset,
      limit: this.state.limit
    }

    setPersistentTableState("carrier", table)

    this.columns = this.columns.map((col) => {
      col.name === table.sortByColumn
        ? (col.options.sortDirection = table.order.toLowerCase())
        : delete col.options.sortDirection
      return col
    })
  }

  filterHandler = () => {
    const value = this.state.showAdvanceFilter
    this.setState({ showAdvanceFilter: !value })
  }
  // 123123
  advanceSearchInputHandler = (e) => {
    const { name, value } = e.target
    console.log(name, value)
    this.setState({
      advanceSearchFormValues: {
        ...this.state.advanceSearchFormValues,
        [name]: value
      }
    })
  }
  advanceFilterHandler = async (e) => {
    this.setState({ isLoading: true })

    e.preventDefault()
    if (
      !this.state.advanceSearchFormValues.sfCustomerName &&
      !this.state.advanceSearchFormValues.sfCarrierName &&
      !this.state.advanceSearchFormValues.sfShipper &&
      !this.state.advanceSearchFormValues.sfReciever
      // !this.state.advanceSearchFormValues.sfPickupCity &&
      // !this.state.advanceSearchFormValues.sfDeliveryCity
    ) {
      alert("Search input is required.")
      return
    } else {
      let obj = {
        // offset: this.state.offset,
        // limit: this.state.limit,
        token: this.state.token,
        carrierName: this.state.advanceSearchFormValues.sfCarrierName,
        customerName: this.state.advanceSearchFormValues.sfCustomerName,
        shipperName: this.state.advanceSearchFormValues.sfShipper,
        receiverName: this.state.advanceSearchFormValues.sfReciever
        // pickupCity: this.state.advanceSearchFormValues.sfPickupCity,
        // deliveryCity: this.state.advanceSearchFormValues.sfDeliveryCity,
      }
      var status
      var arr = [],
        count
      var myCount = 0
      await axios
        .post(BaseURL + "carrierInformation/findByFilter", obj)
        .then((response) => {
          this.setState({ isLoading: false })
          console.log(response)
          // debugger;
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem("Token", "")
            this.props.history.push("/")
          }

          count = response.data.data.count

          for (const key in response.data.data.rows) {
            if (response.data.data.rows.hasOwnProperty(key)) {
              const element = response.data.data.rows[key]

              if (element.status === 1) {
                status = (
                  <img
                    style={{ width: "15px", height: "15px" }}
                    src={Tick}
                    alt="Active"
                  />
                )
              } else {
                status = (
                  <img
                    style={{ width: "15px", height: "15px" }}
                    src={Cross}
                    alt="Inactive"
                  />
                )
              }

              var Obj = [
                element.id,
                element.carrierName,
                element.mcNo,
                element.carrierName,
                element.address.address +
                  ", " +
                  (element.address.addressLineTwo
                    ? element.address.addressLineTwo + ", "
                    : "") +
                  element.address.city +
                  ", " +
                  element.address.state.state +
                  ", " +
                  element.address.zip,
                element.address.email,
                element.address.telephone,
                status
              ]

              // let cusName = element.customerLoads[0] ? element.customerLoads[0].customer.customerName : "";
              // let shipperName = element.customerLoads[0].loadPickupAndDeliveryDetails[0].shipper ? element.customerLoads[0].loadPickupAndDeliveryDetails[0].shipper.name : "";
              // let reciverName = element.customerLoads[0].loadPickupAndDeliveryDetails[1].shipper ? element.customerLoads[0].loadPickupAndDeliveryDetails[1].shipper.name : "";
              // let pickCity = element.customerLoads[0].loadPickupAndDeliveryDetails[0] ? element.customerLoads[0].loadPickupAndDeliveryDetails[0].shipper.address.city : "";
              // let deliveryCity = element.customerLoads[0].loadPickupAndDeliveryDetails[1] ? element.customerLoads[0].loadPickupAndDeliveryDetails[1].shipper.address.city : "";

              // if (
              //   Obj[1] === this.state.advanceSearchFormValues.sfCarrierName ||
              //   cusName === this.state.advanceSearchFormValues.sfCustomerName ||
              //   shipperName === this.state.advanceSearchFormValues.sfShipper
              //   // reciverName === this.state.advanceSearchFormValues.sfReciever
              //   // pickCity === this.state.advanceSearchFormValues.sfPickupCity ||
              //   // deliveryCity === this.state.advanceSearchFormValues.sfDeliveryCity
              // ) {
              arr.push(Obj)
              // }
            }
          }
        })
        .catch(function(error) {
          NotificationManager.error(
            "Something Went Wrong Server Is Not Responding ",
            "Error Message",
            3000
          )
          console.log(error)
        })

      if (this._isMounted) {
        this.setState({ data: arr, isLoading: false, count })
      }
    }
  }
  advanceFilterClear = (e) => {
    e.preventDefault()
    this.setState({
      advanceSearchFormValues: {
        sfCustomerName: "",
        sfCarrierName: "",
        // sfPickupCity: "",
        // sfDeliveryCity: "",
        sfShipper: "",
        sfReciever: ""
      }
    })
    this.showall("Show All", e)
    this.setState({ showAdvanceFilter: false })
  }

  render() {
    const {
      role,
      isLoading,
      data,
      rowsPerPage,
      open,
      searchVal,
      count,
      page,
      showAdvanceFilter
    } = this.state
    console.log(role, "111111111111")
    const options = {
      responsive: "scroll",
      selectableRows: false,
      rowHover: true,
      print: false,
      download: false,
      filter: false,
      search: false,
      rowsPerPageOptions: [25, 50, 100],
      rowsPerPage: rowsPerPage,
      viewColumns: false,
      textLabels: {
        body: {
          toolTip: ""
        }
      },
      onCellClick: this.startEditing,

      serverSide: true,
      count: count,
      page: page,
      onTableChange: this.onTableChange
    }

    // const emptyRows =
    //   rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    const crossN = {
      display: "none"
    }
    const crossS = {
      display: "block"
    }

    return (
      <div className="page-container-bg-solid page-header-fixed page-sidebar-closed-hide-logo page-md">
        <Header title="| Carrier" />
        <div className="page-container">
          <Sidebar />
          <div className="page-content-wrapper">
            <div className="page-content">
              <div className="row">
                <div className="col-md-12">
                  <div className="portlet box yellow-gold">
                    <div className="portlet-title">
                      <div className="caption">
                        {" "}
                        <i className="fa fa-truck"></i>
                        <span className="caption-subject">
                          Carrier
                          {/* <i className="fa fa-filter filter" onClick={this.filterHandler}></i> */}
                        </span>
                      </div>
                      <div className="actions">
                        <div className="btn-group">
                          {role !== tenantUser ? (
                            <Link
                              to="/AddCarrier"
                              id="ab"
                              className="btn sbold white dark"
                            >
                              {" "}
                              Add New <i className="fa fa-plus"></i>{" "}
                            </Link>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="portlet-body">
                      <div className="table-toolbar">
                        {role !== tenantUser ? (
                          <div
                            style={{
                              textAlign: "right",
                              paddingRight: " 10px",
                              paddingBottom: "8px"
                            }}
                          >
                            {!showAdvanceFilter ? (
                              <span
                                style={{
                                  float: "left",
                                  paddingLeft: "8px",
                                  marginTop: "6px"
                                }}
                              >
                                <div className="btnShowall">
                                  <Button
                                    buttonRef={(node) => {
                                      this.anchorEl = node
                                    }}
                                    aria-owns={
                                      open ? "menu-list-grow" : undefined
                                    }
                                    aria-haspopup="true"
                                    onClick={this.handleToggle}
                                  >
                                    {this.state.btnVal} &nbsp;&nbsp;
                                    <span className="fa fa-chevron-down"></span>
                                  </Button>
                                  <Popper
                                    open={open}
                                    anchorEl={this.anchorEl}
                                    transition
                                    disablePortal
                                  >
                                    {({ TransitionProps, placement }) => (
                                      <Grow
                                        {...TransitionProps}
                                        id="menu-list-grow"
                                        style={{
                                          transformOrigin:
                                            placement === "bottom"
                                              ? "center top"
                                              : "center bottom"
                                        }}
                                      >
                                        <Paper>
                                          <ClickAwayListener
                                            onClickAway={this.handleClose}
                                          >
                                            <MenuList>
                                              <MenuItem
                                                onClick={(e) =>
                                                  this.showall("Show All", e)
                                                }
                                              >
                                                Show All
                                              </MenuItem>
                                              <MenuItem
                                                onClick={(e) =>
                                                  this.active("Active", e)
                                                }
                                              >
                                                Active
                                              </MenuItem>
                                              <MenuItem
                                                onClick={(e) =>
                                                  this.Inactive("Inactive", e)
                                                }
                                              >
                                                Inactive
                                              </MenuItem>
                                            </MenuList>
                                          </ClickAwayListener>
                                        </Paper>
                                      </Grow>
                                    )}
                                  </Popper>
                                </div>
                              </span>
                            ) : (
                              <>
                                {/* 123123 advance filter 123123 */}
                                <form>
                                  <div>
                                    {/* <div class="form-row">
                                          <div class="col-12 col-md-6">
                                            <label for="inputEmail4">Carrier Name</label>
                                            <input name="sfCarrierName" onChange={this.advanceSearchInputHandler} value={this.state.advanceSearchFormValues.sfCarrierName} type="" className="form-control" placeholder="Carrier Name" />
                                          </div>
                                          <div class="col-12 col-md-6">
                                            <label for="inputEmail4">Customer Name</label>
                                            <input name="sfCustomerName" onChange={this.advanceSearchInputHandler} value={this.state.advanceSearchFormValues.sfCustomerName} type="" className="form-control" placeholder="Customer Name" />
                                          </div>
                                        </div> */}

                                    <div class="row d-flex text-left">
                                      <div class="col-12 col-md-6">
                                        <label for="inputEmail4">
                                          Carrier Name
                                        </label>
                                        {/* <input name="sfCarrierName" onChange={this.advanceSearchInputHandler} value={this.state.advanceSearchFormValues.sfCarrierName} type="" className="form-control" placeholder="Carrier Name" /> */}
                                        <Select
                                          optionHeight={50}
                                          disabled={this.state.readonly}
                                          value={
                                            this.state.CarrierSelectedOption
                                          }
                                          options={this.state.CarrierOptions}
                                          onChange={this.handleChangeCarrier}
                                          onInputChange={(input) => {
                                            if (input.trim().length > 1) {
                                              axios
                                                .post(
                                                  BaseURL +
                                                    "carrierInformation/findByName",
                                                  {
                                                    status: 2,
                                                    searchKeyword: input,
                                                    token: this.state.token
                                                  }
                                                )
                                                .then((response) => {
                                                  if (
                                                    response.data.error ===
                                                    TokenErrorMsg
                                                  ) {
                                                    localStorage.setItem(
                                                      "Token",
                                                      ""
                                                    )
                                                    this.props.history.push("/")
                                                  }

                                                  var arr = []

                                                  if (
                                                    response.data.data.count ===
                                                    0
                                                  ) {
                                                    let errors = {}
                                                    errors[
                                                      "inactiveValidation"
                                                    ] = ""
                                                    this.setState({
                                                      errors: errors
                                                    })
                                                  }

                                                  response.data.data.rows.forEach(
                                                    (element) => {
                                                      if (
                                                        element.status !== 0
                                                      ) {
                                                        let errors = {}
                                                        errors[
                                                          "inactiveValidation"
                                                        ] = ""
                                                        var obj = {
                                                          value: element.id,
                                                          label:
                                                            element.carrierName
                                                        }

                                                        arr.push(obj)
                                                        this.setState({
                                                          errors: errors
                                                        })
                                                      } else if (
                                                        element.status === 0
                                                      ) {
                                                        let errors = {}
                                                        console.log(
                                                          element,
                                                          "element 3"
                                                        )
                                                        errors[
                                                          "inactiveValidation"
                                                        ] =
                                                          "*Carrier exist with inactive status"
                                                        this.setState({
                                                          errors: errors
                                                        })
                                                      }
                                                    }
                                                  )

                                                  this.setState({
                                                    CarrierOptions: arr,
                                                    loadData:
                                                      response.data.data.rows
                                                  })
                                                })
                                                .catch(function(error) {
                                                  NotificationManager.error(
                                                    "Something Went Wrong Server Is Not Responding ",
                                                    "Error Message",
                                                    3000
                                                  )
                                                  console.log(error)
                                                })
                                            } else {
                                              let errors = {}
                                              errors["inactiveValidation"] = ""
                                              this.setState({
                                                errors: errors
                                              })
                                            }
                                          }}
                                          placeholder="Search and Choose "
                                        />
                                      </div>

                                      <div class="col-12 col-md-6">
                                        <label for="inputEmail4">
                                          Customer Name
                                        </label>
                                        {/* <input name="sfCustomerName" onChange={this.advanceSearchInputHandler} value={this.state.advanceSearchFormValues.sfCustomerName} type="" className="form-control" placeholder="Customer Name" /> */}
                                        <Select
                                          className="customeLeft"
                                          optionHeight={50}
                                          disabled={this.state.readonly}
                                          value={
                                            this.state.CustomerSelectedOption
                                            // this.state.advanceSearchFormValues.sfCustomerName
                                          }
                                          options={this.state.CustomerOptions}
                                          onChange={this.handleChangeCustomer}
                                          onInputChange={(input) => {
                                            if (input.trim().length > 1) {
                                              axios
                                                .post(
                                                  BaseURL +
                                                    "customer/findByName",
                                                  {
                                                    status: 2,
                                                    searchKeyword: input,
                                                    token: this.state.token
                                                  }
                                                )
                                                .then((response) => {
                                                  if (
                                                    response.data.error ===
                                                    TokenErrorMsg
                                                  ) {
                                                    localStorage.setItem(
                                                      "Token",
                                                      ""
                                                    )
                                                    this.props.history.push("/")
                                                  }

                                                  var arr = []

                                                  response.data.data.rows.forEach(
                                                    (element) => {
                                                      var obj = {
                                                        value: element.id,
                                                        label:
                                                          element.customerName
                                                      }
                                                      arr.push(obj)
                                                    }
                                                  )

                                                  this.setState({
                                                    CustomerOptions: arr,
                                                    customerOptionsData:
                                                      response.data.data.rows
                                                  })
                                                })
                                                .catch(function(error) {
                                                  NotificationManager.error(
                                                    "Something Went Wrong Server Is Not Responding ",
                                                    "Error Message",
                                                    3000
                                                  )
                                                  console.log(error)
                                                })
                                            }
                                          }}
                                          placeholder="Search and Choose "
                                        />
                                      </div>
                                    </div>

                                    <div class="row d-flex text-left">
                                      <div class="col-12 col-md-6">
                                        <label for="inputEmail4">
                                          Shipper Name
                                        </label>
                                        <input
                                          name="sfShipper"
                                          onChange={
                                            this.advanceSearchInputHandler
                                          }
                                          value={
                                            this.state.advanceSearchFormValues
                                              .sfShipper
                                          }
                                          type=""
                                          className="form-control"
                                          placeholder="Shipper"
                                        />
                                      </div>
                                      <div class="col-12 col-md-6">
                                        <label for="inputEmail4">
                                          Receiver Name
                                        </label>
                                        <input
                                          name="sfReciever"
                                          onChange={
                                            this.advanceSearchInputHandler
                                          }
                                          value={
                                            this.state.advanceSearchFormValues
                                              .sfReciever
                                          }
                                          type=""
                                          className="form-control"
                                          placeholder="Receiver"
                                        />
                                      </div>
                                    </div>
                                    {/* <div class="row d-flex text-left">
                                          <div class="col-12 col-md-6">
                                            <label for="inputEmail4">Pickup City</label>
                                            <input name="sfPickupCity" onChange={this.advanceSearchInputHandler} value={this.state.advanceSearchFormValues.sfPickupCity} type="" className="form-control" placeholder="Pickup City" />
                                          </div>
                                          <div class="col-12 col-md-6">
                                            <label for="inputEmail4">Delivery City</label>
                                            <input name="sfDeliveryCity" onChange={this.advanceSearchInputHandler} value={this.state.advanceSearchFormValues.sfDeliveryCity} type="" className="form-control" placeholder="Delivery City" />
                                          </div>
                                        </div> */}

                                    <div className="filterSerchDiv">
                                      <button
                                        type="submit"
                                        style={{ marginRight: "5px" }}
                                        className="btn green-meadow filterSerchBtn"
                                        onClick={this.advanceFilterHandler}
                                      >
                                        Search
                                      </button>
                                      <button
                                        className="btn"
                                        onClick={this.advanceFilterClear}
                                      >
                                        Clear
                                      </button>
                                    </div>
                                  </div>
                                </form>
                              </>
                            )}

                            {!showAdvanceFilter ? (
                              <>
                                <div style={{ marginRight: "0px" }}>
                                  <div className="searchField form-control">
                                    <input
                                      id="search-field"
                                      ref="search"
                                      name="searchinput"
                                      type="text"
                                      placeholder="Search..."
                                      value={this.state.search}
                                      onChange={this.handleSearch}
                                      onKeyPress={this.handleKeyPress}
                                    />
                                    {/* <i
                                      className="fa fa-chevron-down"
                                      style={{
                                        position: "absolute",
                                        right: "10px",
                                        top: "8px",
                                        cursor: "pointer"
                                      }}
                                      onClick={this.filterHandler}
                                    ></i> */}
                                    <span
                                      style={searchVal ? crossS : crossN}
                                      className="removeSearchBtn"
                                      onClick={(e) =>
                                        this.showall("Show All", e)
                                      }
                                    >
                                      <i className="fa fa-close"></i>
                                    </span>
                                  </div>
                                  <input
                                    name="searchbutton"
                                    style={{ marginRight: "10px" }}
                                    className="btn green-meadow"
                                    type="submit"
                                    value="SEARCH"
                                    onClick={this.SetSearchParameters}
                                  />
                                  <input
                                    type="button"
                                    className="btn green-meadow"
                                    style={{ display: "none" }}
                                    value="SHOW ALL"
                                    onClick={this.showall.bind(this)}
                                  />
                                  {/* <img alt='' id="excel_export" src="../assets/apps/img/excel_button.png" title="Export data to Excel" />  */}
                                </div>
                              </>
                            ) : null}
                          </div>
                        ) : null}
                      </div>
                      {isLoading ? (
                        <p style={{ textAlign: "center" }}>
                          <img src={f} alt="Loader" />
                        </p>
                      ) : (
                        <MUiTable
                          TableName="CarrierTable muitable"
                          data={data}
                          columns={this.columns}
                          options={options}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Carrier);