/* eslint-disable */

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
import Sidebar from "./sidebar";
import Header from "./Head";
import $ from "jquery";
import BaseURL from "./config";
import TokenErrorMsg from "./errorMsg";
import Select from "react-virtualized-select";
import DatePicker from "react-datepicker";
import CurrencyInput from "react-currency-input";
import moment from "moment";
import Customer from "./LoadCustomer";
import AddShipper from "./LoadShipper";
import Addcarrier from "./LoadCarrier";
import InvoiceTab from "./InvoiceTab";

import DispatchTab from "./DispatchTab";
import getEditinfo from "./StartEditMode";
import finishEditinfo from "./FinishEditMode";
import currencyFormatter from "currency-formatter";
const Cryptr = require("cryptr");
const cryptr = new Cryptr("@$#rgweR35*&YFSD)/");

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import dispatcherUser from "./permissions/DispatcherUserPermision";
import accountingUser from "./permissions/AccountingUserPermision";

const searchOptions = {
  componentRestrictions: { country: ["us"] },
  types: ["(regions)"],
};

const optionsDropdown = [
  { label: "Line Haul", value: 1 },
  { label: "TONU", value: 2 },
]
const optionsUsersList = [
]

function getTimeZone() {
  var offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
  return (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ":" + ("00" + (o % 60)).slice(-2);

}

class QuickAddLoad extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      role: "",
      selectRateTypeValue: { label: "Line Haul", value: 0 },
      salesPerson1: '',
      salesPerson2: '',
      countries: [],
      selectedCountryId: null,
      states: [],
      status: 1,
      loadid: "",
      whileSave: false,
      readonly: false,
      hidden: true,
      isLoading: false,
      token: "",
      fields: {},
      errors: {},
      CustomerSelectedOption: null,
      CustomerOptions: [],
      ContactSelectedOption: null,
      ContactOptions: [],
      CarrierSelectedOption: null,
      CarrierOptions: [],

      loadData: [],
      carrierdata: {},

      customerOptionsData: [],
      customerCard: {},
      contactOptionsData: [],
      contactCard: {},
      rateValue: 0.0,

      StopData: [
        {
          ShipperOptions: [],
          shipperId: null,
          stopNo: 1,
          stopType: "pickup",
          isAddressAvailable: "0",
          address: "",
          scheduleFrom: moment().set({ hour: 0, minute: 0, second: 0 }),
          scheduleTo: moment().set({ hour: 0, minute: 0, second: 0 }),
          puNo: "",
          notes: "",
          shipperOptionsData: [],
          shipperCard: {},
          checkedIn: "0",
          checkedOut: "0",
          checkedInTime: moment().set({ hour: 0, minute: 0, second: 0 }),
          checkedOutTime: moment().set({ hour: 0, minute: 0, second: 0 }),
          dispatchCheckBox: false,
        },
        {
          ShipperOptions: [],
          shipperId: null,
          stopNo: 2,
          isAddressAvailable: "0",
          address: "",
          stopType: "pickup",
          scheduleFrom: moment().set({ hour: 0, minute: 0, second: 0 }),
          scheduleTo: moment().set({ hour: 0, minute: 0, second: 0 }),
          puNo: "",
          notes: "",
          shipperOptionsData: [],
          shipperCard: {},
          checkedIn: "0",
          checkedOut: "0",
          checkedInTime: moment().set({ hour: 0, minute: 0, second: 0 }),
          checkedOutTime: moment().set({ hour: 0, minute: 0, second: 0 }),
          dispatchCheckBox: false,
        },
      ],
      freightType: "",
      temperatureType: "",
      cycle: "", //cont
      CheckEditInfo: {},

      Documents: [
        {
          label: "Rate Sheet",
          docType: 1,
          documentNo: 1,
          fileName: "",
          error: "",
          showIcons: true,
          file: "",
          s3FileKey: "",
          checked: false,
          documentId: "",
          invoiceError: "",
          fileExtension: "",
        },
        {
          label: "BOL (Bill of Loading)",
          docType: 2,
          documentNo: 2,
          fileName: "",
          error: "",
          showIcons: true,
          file: "",
          s3FileKey: "",
          checked: false,
          documentId: "",
          invoiceError: "",
          fileExtension: "",
        },
        {
          label: "POD (Proof of Delivery)",
          docType: 3,
          documentNo: 3,
          fileName: "",
          error: "",
          showIcons: true,
          file: "",
          s3FileKey: "",
          checked: false,
          documentId: "",
          invoiceError: "",
          fileExtension: "",
        },
        {
          label: "Lumper Receipt",
          docType: 4,
          documentNo: 4,
          fileName: "",
          error: "",
          showIcons: true,
          file: "",
          s3FileKey: "",
          checked: false,
          documentId: "",
          invoiceError: "",
          fileExtension: "",
        },
      ],
      loadStatus: "Pending",

      additionalCharges: [],
      totalAmount: 0.0,
      paymentType: "Quick Pay",
      invoicePopUpInputValues: {
        chargesType: "Lumper",
        amount: "",
      },

      invoiceId: null,
      invoiceURL: null,
      invoiceS3FileKey: null,
      invoiceLoader: false,
      invoiceFileName: "",
      quickPay: "",
      invoiceTo: "Customer",
      invoiceStatus: "Processed",
      internalLoadNo: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.submituserRegistrationForm = this.submituserRegistrationForm.bind(
      this
    );
    this.submit = this.submit.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.AutoFormatContact = this.AutoFormatContact.bind(this);

    this.onFileChange = this.onFileChange.bind(this);
  }
  onUnload = (event) => {
    event.preventDefault();
    var http = new XMLHttpRequest();

    var url = BaseURL + "editModeInfo/finishEditMode";

    var params =
      "customerLoadId=" + this.state.loadid + "&token=" + this.state.token;

    http.open("POST", url, true);

    http.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

    if (!this.state.readonly) {
      http.send(params);
      var start = new Date().getTime();
      while (new Date().getTime() < start + 350);
    }
  };

  AutoFormatContact(e) {
    function phone_formatting(ele, restore) {
      var new_number,
        // selection_start = ele.selectionStart,
        // selection_end = ele.selectionEnd,
        number = ele.value.replace(/\D/g, "");

      // automatically add dashes
      if (number.length > 2) {
        // matches: 123 || 123-4 || 123-45
        new_number = number.substring(0, 3) + "-";
        if (number.length === 4 || number.length === 5) {
          // matches: 123-4 || 123-45
          new_number += number.substr(3);
        } else if (number.length > 5) {
          // matches: 123-456 || 123-456-7 || 123-456-789
          new_number += number.substring(3, 6) + "-";
        }
        if (number.length > 6) {
          // matches: 123-456-7 || 123-456-789 || 123-456-7890
          new_number += number.substring(6);
        }
      } else {
        new_number = number;
      }

      // if value is heigher than 12, last number is dropped
      // if inserting a number before the last character, numbers
      // are shifted right, only 12 characters will show
      ele.value =
        new_number.length > 12 ? new_number.substring(0, 12) : new_number;

      // restore cursor selection,
      // prevent it from going to the end
      // UNLESS
      // cursor was at the end AND a dash was added

      // if (new_number.slice(-1) === '-' && restore === false
      //     && (new_number.length === 8 && selection_end === 7)
      //         || (new_number.length === 4 && selection_end === 3)) {
      //     selection_start = new_number.length;
      //     selection_end = new_number.length;
      // }
      // else if (restore === 'revert') {
      //   selection_start--;
      //   selection_end--;
      // }
      // ele.setSelectionRange(selection_start, selection_end);
    }

    function phone_number_check(field, e) {
      var key_code = e.keyCode,
        key_string = String.fromCharCode(key_code),
        press_delete = false,
        dash_key = 189,
        delete_key = [8, 46],
        direction_key = [33, 34, 35, 36, 37, 38, 39, 40],
        selection_end = field.selectionEnd;

      // delete key was pressed
      if (delete_key.indexOf(key_code) > -1) {
        press_delete = true;
      }

      // only force formatting is a number or delete key was pressed
      if (key_string.match(/^\d+$/) || press_delete) {
        phone_formatting(field, press_delete);
      }
      // do nothing for direction keys, keep their default actions
      else if (direction_key.indexOf(key_code) > -1) {
        // do nothing
      } else if (dash_key === key_code) {
        if (selection_end === field.value.length) {
          field.value = field.value.slice(0, -1);
        } else {
          field.value =
            field.value.substring(0, selection_end - 1) +
            field.value.substr(selection_end);
          field.selectionEnd = selection_end - 1;
        }
      }
      // all other non numerical key presses, remove their value
      else {
        e.preventDefault();
        //    field.value = field.value.replace(/[^0-9\-]/g,'')
        phone_formatting(field, "revert");
      }
    }

    const Validate = (e) => {
      if (!String.fromCharCode(e.keyCode).match(/^- $/)) {
        this.handleChange(e);
      }
    };

    document.getElementById(e.target.id).onkeyup = function (e) {
      phone_number_check(this, e);
      Validate(e);
    };
  }

  async componentDidMount() {
    const Role = localStorage.getItem("Role");
    if (Role) {
      const role = cryptr.decrypt(Role);
      this.setState({ role: role });
    }

    this._isMounted = true;
    window.addEventListener("beforeunload", this.onUnload);
    var token = localStorage.getItem("Token");

    if (this._isMounted) {
      await this.setState({ token: token });
      // this.getLastLoadNo();
      this.getUsersList();
    }
  }

  handleAddressChange = (address, index) => {
    if (this._isMounted) {
      this.handleSelect(address, index);
    }
  };

  handleSelect = (address, index) => {
    if (this._isMounted) {
      let StopData = this.state.StopData;
      StopData[index].address = address;
      this.setState({ StopData });
      geocodeByAddress(address)
        .then((results) => getLatLng(results[0]))
        .then((latLng) => this.setState({ addressLatLng: latLng }))
        .catch((error) => console.error("Error", error));
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("beforeunload", this.onUnload);
    var config = {
      customerLoadId: this.state.loadid,
      token: this.state.token,
    };

    if (config.customerLoadId) {
      finishEditinfo(
        config,
        (res) => {
          console.log(res);
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  getUsersList = () => {
    let value = 0
    var postData = {
      'token': this.state.token,
      'status': 2,
      "offset": 0,
      "limit": 50,
      "sortOrder": "ASC",
      "sortBy": "firstName",
    };
    if (this.state.role === "Admin" || this.state.role === dispatcherUser || this.state.role === accountingUser) {
      axios.post(BaseURL + 'users/', postData
        ,
        {
          headers: { 'Content-Type': 'application/json' }
        }
      ).then(response => {
        console.log(response.data.data)
        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem('Token', '');
          this.props.history.push('/');
        }
        let array = response.data.data.users.rows
        array.forEach(user => {
          optionsUsersList.push({ label: user.firstName + ' ' + user.lastName, value: value })
          value = value + 1
        })
      })
    }
  }
  
  getLastLoadNo = () => {
    var params = {
      token: this.state.token,
    };

    axios
      .post(BaseURL + "customerLoad/getLastLoadNo", params)
      .then((response) => {
        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem("Token", "");
          this.props.history.push("/");
        } else {
          this.setState({
            internalLoadNo: parseInt(response.data.data.internalLoadNo) + 1,
          });
          //////////////////////
          // save load after getting load no
          ////////////////////////
          const internalload = this.refs.internalload.value.trim();
          const load = this.refs.load.value.trim();
          const customerId = this.state.CustomerSelectedOption;
          const contactId = this.state.ContactSelectedOption;
          const rate = this.state.rateValue;
          const carrierId = this.state.CarrierSelectedOption;
          const freightType = this.state.freightType;

          const temperature = "";
          const temperatureType = this.state.temperatureType;
          const cycle = this.state.cycle;
          const loadStatus = this.state.loadStatus;

          const trailerType = false;
          const trailerType1 = false;
          const Shipping = false;
          const Shipping1 = false;
          const driverRequirements = false;
          const driverRequirements1 = false;
          const loadPickupAndDeliveryDetails = this.state.StopData;

          if (this.state.salesPerson1 == null) {
            this.state.salesPerson1 = { label: "Select...", value: 0 };
          }
          if (this.state.salesPerson2 == null) {
            this.state.salesPerson2 = { label: "Select...", value: 0 }
          }
          
          var Load = {
            token: this.state.token,
            id: this.state.loadid,
            internalLoadNo: internalload,
            customerLoadNo: load,
            rate: rate,
            rateType: this.state.selectRateTypeValue.label,
            salesDetails: {
              salesPerson1: this.state.salesPerson1.label != "Select..." ? this.state.salesPerson1.label : '',
              salesPerson2: this.state.salesPerson2.label != "Select..." ? this.state.salesPerson2.label : '',
            },
            loadStatus: loadStatus,
            freightType: freightType,
            temperature: temperature,
            temperatureType: temperatureType,
            cycle: cycle,
            trailerType: Shipping,
            trailerTypeOne: Shipping1,
            trailerSize: trailerType,
            trailerSizeOne: trailerType1,
            driverRequirements: driverRequirements,
            driverRequirementsOne: driverRequirements1,
            customerId: customerId,
            customerContactId: contactId,
            carrierId: carrierId,
            loadPickupAndDeliveryDetails: loadPickupAndDeliveryDetails,
          };

          Load.loadPickupAndDeliveryDetails.forEach(element => {
            element.scheduleFrom = new Date(element.scheduleFrom).toISOString()
            element.scheduleTo = new Date(element.scheduleTo).toISOString()
          })

          axios
            .post(BaseURL + "customerLoad/save", Load)
            .then((response) => {
              if (response.data.error === TokenErrorMsg) {
                localStorage.setItem("Token", "");
                this.props.history.push("/");
              } else if (response.data.error === "Load Already Exist") {
                this.setState({ readonly: false });
                NotificationManager.error(
                  "Customer Load Already Exist With This Number",
                  "Error",
                  3000
                );
              } else {
                console.log(response.data);
                var custId = response.data.data.id;
                NotificationManager.success(
                  "Customer Load Added Successfully",
                  "Added",
                  3000
                );
                this.setState({ hidden: false, loadid: custId });
              }
            })
            .catch(function (error) {
              NotificationManager.error(
                "Something Went Wrong Server Is Not Responding ",
                "Error Message",
                3000
              );
              console.log(error);
            });

        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  async handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    if (this._isMounted) {
      await this.setState({
        fields,
      });
    }
    this.validateForm();
  }

  submituserRegistrationForm(e) {
    e.preventDefault();
    var send = false;

    if (this.validateAndSubmit() && this.validateForm()) {
      send = true;
    }

    if (send) {
      // this.getLastLoadNo();
      this.submit();
    }
  }

  validateAndSubmit() {
    let fields = this.state.fields;
    let errors = this.state.errors;
    let formIsValid = true;

    if (!this.state.CustomerSelectedOption) {
      formIsValid = false;
      errors["customer"] = "*Please Select Customer";
      $("[name='load']").focus();
    } else if (
      !this.refs.load.value ||
      !this.refs.load.value.trim().length > 0
    ) {
      formIsValid = false;
      errors["load"] = "*Please Enter Customers Load";
      errors["customer"] = "";
      $("[name='load']").focus();
    }

    if (this._isMounted) {
      this.setState({
        errors: errors,
      });
    }
    return formIsValid;
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (typeof fields["load"] !== "undefined" && fields["load"]) {
      if (fields["load"].length > 200) {
        formIsValid = false;

        errors["load"] = "*Please Enter Only Valid Characters (Max Range: 200)";
      }
    }

    if (
      typeof fields["internalload"] !== "undefined" &&
      fields["internalload"]
    ) {
      if (fields["internalload"].length > 200) {
        formIsValid = false;

        errors["internalload"] =
          "*Please Enter Only Valid Characters (Max Range: 200)";
      }
    }

    if (typeof fields["rate"] !== "undefined" && fields["rate"]) {
      if (fields["rate"].length > 200) {
        formIsValid = false;
        errors["rate"] = "*Please Enter Only Valid Characters (Max Range: 200)";
      }
    }

    if (typeof fields["temperature"] !== "undefined" && fields["temperature"]) {
      if (fields["temperature"].length > 200) {
        formIsValid = false;
        errors["temperature"] =
          "*Please Enter Only Valid Characters (Max Range: 200)";
      }
    }

    if (this.state.errors.internalload) {
      formIsValid = false;
      errors["internalload"] = this.state.errors.internalload;
      this.refs.internalload.focus();
    }

    if (this.state.errors.internalloadlimit) {
      formIsValid = false;
      errors["internalloadlimit"] = this.state.errors.internalloadlimit;
      this.refs.internalload.focus();
    }

    if (this.state.errors.load) {
      formIsValid = false;
      errors["load"] = "*Record Already Exist With this Load No";
      this.refs.load.focus();
    }

    if (this._isMounted) {
      this.setState({
        errors: errors,
      });
    }
    return formIsValid;
  }

  EditForm() {
    var div = document.getElementById("EditDiv");
    div.setAttribute("style", " cursor: not-allowed; pointer-events: none");

    var config = {
      customerLoadId: this.state.loadid,
      token: this.state.token,
    };

    getEditinfo(
      config,
      (res) => {
        console.log(res);
        if (res.data.status === 0) {
          if (this._isMounted) {
            this.setState({
              readonly: false,
              CheckEditInfo: config,
            });
            this.state.freightType === "reefer"
              ? this.refs.Shipping1.setAttribute("disabled", true)
              : {};
          }
        } else {
          div.removeAttribute("style");
          NotificationManager.warning(
            res.data.message,
            "Warning Message",
            3000
          );
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  async cancel() {
    if (window.confirm(" Are you sure you want to cancel ? "))
      this.props.history.push("/load");
  }

  submit() {
    
    if (this.state.salesPerson1 == null) {
      this.state.salesPerson1 = { label: "Select...", value: 0 };
    }
    if (this.state.salesPerson2 == null) {
      this.state.salesPerson2 = { label: "Select...", value: 0 }
    }

    const internalload = this.refs.internalload.value.trim();
    const load = this.refs.load.value.trim();
    const customerId = this.state.CustomerSelectedOption;
    const contactId = this.state.ContactSelectedOption;
    const rate = this.state.rateValue;
    const carrierId = this.state.CarrierSelectedOption;
    const freightType = this.state.freightType;

    const temperature = "";
    const temperatureType = this.state.temperatureType;
    const cycle = this.state.cycle;
    const loadStatus = this.state.loadStatus;

    const trailerType = false;
    const trailerType1 = false;
    const Shipping = false;
    const Shipping1 = false;
    const driverRequirements = false;
    const driverRequirements1 = false;
    const loadPickupAndDeliveryDetails = this.state.StopData;

    var Load = {
      token: this.state.token,
      id: this.state.loadid,
      internalLoadNo: internalload,
      customerLoadNo: load,
      rate: rate,
      rateType: this.state.selectRateTypeValue.label,
      salesDetails: {
        salesPerson1: this.state.salesPerson1.label != "Select..." ? this.state.salesPerson1.label : '',
        salesPerson2: this.state.salesPerson2.label != "Select..." ? this.state.salesPerson2.label : '',
      },
      loadStatus: loadStatus,
      freightType: freightType,
      temperature: temperature,
      temperatureType: temperatureType,
      cycle: cycle,
      trailerType: Shipping,
      trailerTypeOne: Shipping1,
      trailerSize: trailerType,
      trailerSizeOne: trailerType1,
      driverRequirements: driverRequirements,
      driverRequirementsOne: driverRequirements1,
      customerId: customerId,
      customerContactId: contactId,
      carrierId: carrierId,
      loadPickupAndDeliveryDetails: loadPickupAndDeliveryDetails,
    };

    console.log(Load);

    this.setState({ readonly: true });

    if (this.state.loadid) {
      axios
        .post(BaseURL + "customerLoad/update", Load)
        .then((response) => {
          console.log(response.data);
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem("Token", "");
            this.props.history.push("/");
          } else if (response.data.error === "Load Already Exist") {
            this.setState({ readonly: false });
            NotificationManager.error(
              "Customer Load Already Exist With This Number",
              "Error",
              3000
            );
          } else {
            this.setState({ hidden: false });
            NotificationManager.success(
              "Customer Load Upated Successfully",
              "Edited",
              3000
            );
          }
        })
        .catch(function (error) {
          NotificationManager.error(
            "Something Went Wrong Server Is Not Responding ",
            "Error Message",
            3000
          );
          console.log(error);
        });
    } else {
      this.getLastLoadNo();

    }
  }

  async SaveContact(event) {
    event.preventDefault();

    if (this._isMounted) {
      this.setState({
        isLoading: true,
      });
    }

    $("#closePopUp").click();
    var arr;
    var data = false;
    var selectvalue = this.state.CustomerSelectedOption;

    if (selectvalue) {
      var CustomerContactUpdate = {
        token: this.state.token,
        customerId: selectvalue,
        name: this.refs.ContactName.value.trim(),
        title: this.refs.ContactTitle.value.trim(),
        email: this.refs.ContactEmail.value.trim(),
        telephone: this.refs.ContactTelephone.value.trim(),
        ext: this.refs.ContactExt.value.trim(),
        cellphone: this.refs.ContactPhone.value.trim(),
        fax: this.refs.ContactFax.value.trim(),
      };
      this.ClearContact();

      await axios
        .post(BaseURL + "customerContact/save", CustomerContactUpdate)
        .then((response) => {
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem("Token", "");
            this.props.history.push("/");
          }
          console.log(response.data);

          NotificationManager.success(
            "Customer Contact Infomation Saved",
            "Customer Info",
            3000
          );
        })
        .catch(function (error) {
          NotificationManager.error(
            "Something Went Wrong Server Is Not Responding ",
            "Error Message",
            3000
          );
          console.log(error);
        });

      this.handleChangeCustomer({ value: selectvalue });
      if (this._isMounted) {
        this.setState({
          whileSave: false,
          isLoading: false,
        });
      }
    }
  }

  ClearContact = async () => {
    this.refs.ContactName.value = "";
    this.refs.ContactTitle.value = "";
    this.refs.ContactEmail.value = "";
    this.refs.ContactTelephone.value = "";
    this.refs.ContactExt.value = "";
    this.refs.ContactPhone.value = "";
    this.refs.ContactFax.value = "";
  };

  handleChangeCarrier = (selectedOption) => {
    var event = selectedOption ? selectedOption.value : null;

    if (this._isMounted) {
      this.setState({ CarrierSelectedOption: event });
    }
    this.loadcarrierinfo(event);
  };

  loadcarrierinfo(e) {
    if (e) {
      var id = e;
      var data;

      this.state.loadData.forEach((element) => {
        if (element.id === Number(id)) {
          data = {
            factoringCompany: element.factoringCompany,
            address: element.address.address,
            city: element.address.city,
            state: element.address.state.state,
          };
        }
      });

      if (data && this._isMounted) {
        data.factoringCompany
          ? this.setState({ carrierdata: data, invoiceTo: "Factoring Company" })
          : this.setState({ carrierdata: data });
      }
    } else {
      if (this._isMounted) {
        this.setState({ carrierdata: "" });
      }
    }
  }

  handleChangeCustomer = async (selectedOption) => {
    var event = selectedOption ? selectedOption.value : null;

    if (event) {
      if (this._isMounted) {
        this.setState({ CustomerSelectedOption: event });
      }

      this.state.customerOptionsData.forEach((element) => {
        if (element.id === event) {
          this.setState({
            customerCard: {
              address: element.address.address,
              city: element.address.city,
              state: element.address.state.state,
            },
          });
        }
      });

      var arr = [],
        options = [];
      await axios
        .post(BaseURL + "customerContact/findByCustomerId", {
          token: this.state.token,
          customerId: event,
        })
        .then((response) => {
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem("Token", "");
            this.props.history.push("/");
          }
          arr = response.data.data;
        })
        .catch(function (error) {
          NotificationManager.error(
            "Something Went Wrong Server Is Not Responding ",
            "Error Message",
            3000
          );
          console.log(error);
        });

      arr.forEach((element) => {
        var obj = { value: element.id, label: element.name };

        options.push(obj);
      });

      if (this._isMounted) {
        this.setState({
          ContactOptions: options,
          contactOptionsData: arr,
          contactCard: {},
        });
      }
    } else {
      if (this._isMounted) {
        this.setState({
          CustomerSelectedOption: event,
          ContactOptions: [],
          ContactSelectedOption: event,
          customerCard: {},
          contactCard: {},
        });
      }
    }
  };

  handleChangeContact = (selectedOption) => {
    var event = selectedOption ? selectedOption.value : null;

    if (this._isMounted) {
      event === null
        ? this.setState({ ContactSelectedOption: event, contactCard: {} })
        : this.setState({ ContactSelectedOption: event });
    }

    this.state.contactOptionsData.forEach((element) => {
      if (element.id === event) {
        this.setState({
          contactCard: {
            name: element.name,
            email: element.email,
            telephone: element.telephone,
            fax: element.fax,
          },
        });
      }
    });
  };

  addStopChild = async (e) => {
    e.preventDefault();

    var StopData = await this.state.StopData;

    await StopData.push({
      ShipperOptions: [],
      shipperId: null,
      stopNo: StopData.length + 1,
      stopType: "pickup",
      isAddressAvailable: "0",
      address: "",
      scheduleFrom: moment().set({ hour: 0, minute: 0, second: 0 }),
      scheduleTo: moment().set({ hour: 0, minute: 0, second: 0 }),
      puNo: "",
      notes: "",
      shipperOptionsData: [],
      shipperCard: {},
      checkedIn: "0",
      checkedOut: "0",
      checkedInTime: moment().set({ hour: 0, minute: 0, second: 0 }),
      checkedOutTime: moment().set({ hour: 0, minute: 0, second: 0 }),
      dispatchCheckBox: false,
    });

    if (this._isMounted) {
      this.setState({
        StopData: StopData,
      });
    }
  };

  handleCheckBox = (e) => {
    var id = e.target.name;
    var className = document.getElementById(id).className;
    if (className) {
      this.refs[id].checked = false;
      document.getElementById(id).className = "";
    } else {
      this.refs[id].checked = true;
      document.getElementById(id).className = "checked";
    }
  };

  onFileChange(index, element) {
    console.log(element);

    var Documents = this.state.Documents;

    var file = element.file;

    function getFileExtension(filename) {
      return filename.split(".").pop();
    }

    Documents[index]["fileExtension"] = getFileExtension(file.name);

    var filename = { name: file.name };

    if (
      file.type !== "application/pdf" &&
      file.type !== "image/jpeg" &&
      file.type !== "image/png" &&
      file.type !== "image/jpg" &&
      file.type !== "image/gif" &&
      file.type !== "image/tif" &&
      getFileExtension(file.name) !== "docx" &&
      getFileExtension(file.name) !== "doc" &&
      getFileExtension(file.name) !== "odt" &&
      getFileExtension(file.name) !== "xls" &&
      getFileExtension(file.name) !== "xlsx" &&
      getFileExtension(file.name) !== "ppt" &&
      getFileExtension(file.name) !== "pptx"
    ) {
      Documents[index]["error"] =
        "Please Upload only PDF ,Images, Document files ";
      this.setState({ Documents });

      return false;
    } else if (file.size > 1000000) {
      Documents[index]["error"] =
        "Please Upload A File Less Than 1MB ( Max size for file: 1MB ) ";
      this.setState({ Documents });
      return false;
    } else {
      Documents[index]["error"] = "";
      this.setState({ Documents });
    }

    if (filename.name.length > 15) {
      filename = {
        name: filename.name.substring(0, 15) + "....",
      };
    }
    console.log(file);

    var doctype = element.docType;

    Documents[index]["showIcons"] = false;
    this.setState({
      Documents,
    });

    console.log("enter in upload file");
    let data = new FormData();
    data.append("file", file);
    data.append("fileName", file.name);
    data.append("loadId", this.state.loadid);
    data.append("type", file.type);
    data.append("documentType", doctype);
    data.append("documentNo", element.documentNo);

    axios
      .post(BaseURL + "loadDocuments/uploadFileToS3", data, {
        headers: {
          "Content-Type": "multipart/form-data",
          token: this.state.token,
        },
      })
      .then((response) => {
        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem("Token", "");
          this.props.history.push("/");
        }
        console.log(response.data);
        NotificationManager.success(
          "Document Uploaded Successfully",
          "Upload",
          3000
        );

        Documents[index]["showIcons"] = true;
        Documents[index]["fileName"] = filename.name;
        Documents[index]["s3FileKey"] = response.data.data.s3FileKey;
        Documents[index]["documentId"] = response.data.data.id;

        this.setState({
          Documents,
        });
      })
      .catch(function (error) {
        NotificationManager.error(
          "Something Went Wrong Server Is Not Responding ",
          "Error Message",
          3000
        );
        console.log(error);
      });
  }

  deleteFile(index, element) {
    var Documents = this.state.Documents;

    var doctype = element.docType;

    if (window.confirm("Are You Sure To Remove Docment ?")) {
      console.log("enter in delete file");
      axios
        .post(BaseURL + "loadDocuments/deleteFile", {
          loadId: this.state.loadid,
          documentNo: element.documentNo,
          documentType: doctype,
          token: this.state.token,
        })
        .then((response) => {
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem("Token", "");
            this.props.history.push("/");
          }
          NotificationManager.success(
            "Document Deleted Successfully",
            "Delete",
            3000
          );
          console.log(response.data);

          Documents[index]["fileName"] = "";
          Documents[index]["s3FileKey"] = "";
          Documents[index]["checked"] = false;
          Documents[index]["documentId"] = "";
          this.setState({ Documents });
        })
        .catch(function (error) {
          NotificationManager.error(
            "Something Went Wrong Server Is Not Responding ",
            "Error Message",
            3000
          );
          console.log(error);
        });
    }
  }

  download = (index, element) => {
    var Documents = this.state.Documents;

    var documentNo = element.documentNo;

    Documents[index]["showIcons"] = false;
    this.setState({
      Documents,
    });

    console.log("enter in download file");

    axios
      .post(BaseURL + "loadDocuments/getDocuments", {
        loadId: this.state.loadid,
        token: this.state.token,
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem("Token", "");
          this.props.history.push("/");
        }

        response.data.documents.forEach((element) => {
          if (element.documentNo === documentNo) {
            console.log(element);
            window.open(element.documentUrl, "_blank");
          }
        });

        Documents[index]["showIcons"] = true;
        this.setState({
          Documents,
        });
      })
      .catch(function (error) {
        NotificationManager.error(
          "Something Went Wrong Server Is Not Responding ",
          "Error Message",
          3000
        );
        console.log(error);
      });
  };

  FinishEditInfo() {
    var config = this.state.CheckEditInfo;
    if (config.token) {
      this.setState({ readonly: true });

      finishEditinfo(
        config,
        (res) => {
          console.log(res);

          this.setState({
            CheckEditInfo: {},
          });
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  addInvoiceCharges = (e) => {
    e.preventDefault();

    var {
      additionalCharges,
      totalAmount,
      invoicePopUpInputValues,
    } = this.state;

    var ChargeType = invoicePopUpInputValues.chargesType;
    var Amount = invoicePopUpInputValues.amount
      ? currencyFormatter.unformat(invoicePopUpInputValues.amount, {
        code: "USD",
      })
      : 0;

    totalAmount =
      currencyFormatter.unformat(totalAmount, { code: "USD" }) + Amount;

    // totalAmount = totalAmount.toFixed(2);
    totalAmount = currencyFormatter.format(totalAmount, { code: "USD" });

    var additionalCharges = [...additionalCharges];

    additionalCharges.push({
      chargesType: ChargeType,
      amount: invoicePopUpInputValues.amount || "$0.00",
    });

    console.log("add case", totalAmount, invoicePopUpInputValues.amount);

    this.setState({ additionalCharges, totalAmount });

    this.resetAdditionalChargesPopUp();
  };

  deleteInvoiceCharges = (index) => {
    var { additionalCharges, totalAmount } = this.state;
    var additionalCharges = [...additionalCharges];

    console.log("del case", totalAmount, additionalCharges[index]["amount"]);

    totalAmount =
      currencyFormatter.unformat(totalAmount, { code: "USD" }) -
      currencyFormatter.unformat(additionalCharges[index]["amount"], {
        code: "USD",
      });
    // console.log(totalAmount );

    // totalAmount = totalAmount.toFixed(2)
    totalAmount = currencyFormatter.format(totalAmount, { code: "USD" });

    additionalCharges.splice(index, 1);

    this.setState({ additionalCharges, totalAmount });
  };

  resetAdditionalChargesPopUp = () => {
    // this.refs.chargesType.value='Lumper';
    // this.refs.amount.value='' ;

    var invoicePopUpInputValues = {
      chargesType: "Lumper",
      amount: "",
    };
    this.setState({ invoicePopUpInputValues });
  };

  setInvoicePaymentType = (paymentType) => {
    paymentType === "Regular Pay"
      ? this.setState({ paymentType, quickPay: "" })
      : this.setState({ paymentType });
  };

  handleInvoicePopUpInputChange = (name, value) => {
    var invoicePopUpInputValues = this.state.invoicePopUpInputValues;

    invoicePopUpInputValues[name] = value;

    this.setState({ invoicePopUpInputValues });
  };

  submitInvoiceForm = () => {
    var invoiceError = false;

    this.state.Documents.forEach((element) => {
      if (element.invoiceError) {
        invoiceError = true;
      }
    });

    if (!invoiceError) {
      var customer = {},
        carrier = {},
        Docs = [],
        shipper = null;

      this.setState({ invoiceLoader: true });
      this.refs.ToploadStatus.focus();

      this.state.customerOptionsData.forEach((element) => {
        if (element.id === this.state.CustomerSelectedOption) {
          customer = element;
        }
      });

      this.state.loadData.forEach((element) => {
        if (element.id === this.state.CarrierSelectedOption) {
          carrier = element;
        }
      });

      this.state.Documents.forEach((element) => {
        if (element.checked) {
          Docs.push({
            s3FileKey: element.s3FileKey,
            documentId: element.documentId,
          });
        }
      });

      var stopData = JSON.parse(JSON.stringify(this.state.StopData));

      stopData.forEach((element) => {
        element.shipperOptionsData.forEach((elem) => {
          if (element.shipperId === elem.id) {
            shipper = elem;
            element.shipperInfo = shipper;
          }
        });
      });

      var invoice = {
        token: this.state.token,
        loadId: this.state.loadid,
        totalAmount: this.state.totalAmount,
        paymentType: this.state.paymentType,
        additionalCharges: this.state.additionalCharges,

        customer: customer,
        carrier: carrier,
        internalLoadNo: this.refs.internalload.value,
        customerLoadNo: this.refs.load.value,
        loadPickupAndDeliveryDetails: stopData,
        rate: currencyFormatter.format(this.state.rateValue, { code: "USD" }),
        invoiceDocuments: Docs,
        invoiceId: this.state.invoiceId,
        s3FileKey: this.state.invoiceS3FileKey,
        quickPay: this.state.quickPay,
        invoiceTo: this.state.invoiceTo,
        factoringCompany: this.state.carrierdata.factoringCompany
          ? this.state.carrierdata.factoringCompany
          : null,
        invoiceStatus: this.state.invoiceStatus,
      };

      console.log(invoice);

      axios
        .post(BaseURL + "invoice/save", invoice)
        .then((response) => {
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem("Token", "");
            this.props.history.push("/");
          }

          console.log(response);

          this.setState({
            invoiceFileName: response.data.invoice.fileName,
            invoiceS3FileKey: response.data.invoice.s3FileKey,
            invoiceURL: response.data.invoice.invoiceURL,
            invoiceId: response.data.invoice.id,
            invoiceLoader: false,
          });

          NotificationManager.success(
            "Invoice Created Successfully",
            "Created",
            3000
          );
        })
        .catch((error) => {
          this.setState({ invoiceLoader: false });
          NotificationManager.error(
            "Something Went Wrong Server Is Not Responding ",
            "Error Message",
            3000
          );
          console.log(error);
        });
    }
  };

  handleInvoiceDocsCheckBox = (index) => {
    var Documents = [...this.state.Documents];

    console.log(Documents[index]["fileExtension"]);

    if (Documents[index]["checked"]) {
      Documents[index]["invoiceError"] = "";
      Documents[index]["checked"] = false;
      this.setState({ Documents });
    } else {
      if (
        Documents[index]["fileExtension"] !== "pdf" &&
        Documents[index]["fileExtension"] !== "PDF"
      ) {
        Documents[index]["invoiceError"] = "Please Select PDF Document Only";
      }
      Documents[index]["checked"] = true;
      this.setState({ Documents });
    }
  };

  deleteInvoice = () => {
    if (!window.confirm("Are you sure you want to delete ?")) {
      return;
    }

    axios
      .post(BaseURL + "invoice/delete", {
        invoiceId: this.state.invoiceId,
        s3FileKey: this.state.invoiceS3FileKey,
        token: this.state.token,
      })
      .then((response) => {
        console.log(response.data);

        this.setState({
          invoiceId: null,
          invoiceS3FileKey: null,
          invoiceURL: null,
          invoiceFileName: "",
        });

        NotificationManager.success(
          "Invoice Deleted Successfully",
          "Delete",
          3000
        );

        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem("Token", "");
          this.props.history.push("/");
        }
      })
      .catch(function (error) {
        NotificationManager.error(
          "Something Went Wrong Server Is Not Responding ",
          "Error Message",
          3000
        );
        console.log(error);
      });
  };

  inputQuickPay = (value) => {
    this.setState({ quickPay: value });
  };

  setInvoiceTo = (value) => {
    value === "Customer"
      ? this.setState({ invoiceTo: value })
      : this.setState({
        invoiceTo: value,
        quickPay: "",
        paymentType: "Quick Pay",
      });
  };

  handleDispatchStopsCheckBox = (index) => {
    var StopData = this.state.StopData;
    StopData[index]["dispatchCheckBox"] = StopData[index]["dispatchCheckBox"]
      ? false
      : true;
    this.setState(StopData);
  };

  resetDispatchStopsCheckBox = () => {
    var StopData = this.state.StopData;
    StopData.forEach((element) => {
      element.dispatchCheckBox = false;
    });
    this.setState({ StopData });
  };

  checkedDispatchStopsCheckBox = (ids) => {
    var idsArray = ids ? ids.split(",") : [];
    var StopData = this.state.StopData;
    StopData.forEach((element) => {
      idsArray.forEach((id) => {
        if (Number(id) === Number(element.stopNo)) {
          element.dispatchCheckBox = true;
        }
      });
    });

    this.setState({ StopData });
  };

  setInvoiceStatus = (value) => {
    this.setState({ invoiceStatus: value });
  };

  render() {
    const msgNone = {
      display: "none",
    };
    const msgShow = {
      display: "block",
    };

    const nonclick = {
      cursor: " notAllowed",
      pointerEvents: "none",
    };

    const {
      StopData,
      Documents,
      loadid,
      totalAmount,
      additionalCharges,
      invoicePopUpInputValues,
      paymentType,
      loadStatus,
      invoiceURL,
      invoiceLoader,
      invoiceFileName,
      quickPay,
      invoiceTo,
      role
    } = this.state;

    return (
      <div className="page-container-bg-solid page-header-fixed page-sidebar-closed-hide-logo page-md">
        <Header title="| Load" />
        <div className="page-container">
          <Sidebar editMode={!this.state.readonly} />
          <div className="page-content-wrapper">
            <div className="page-content">
              <div className="page-head"></div>

              <div className="row">
                <div className="col-md-12">
                  <div className="tabbable-line boxless  tabbable-reversed">
                    <ul
                      className="nav nav-tabs col-md-8"
                      hidden={this.state.hidden}
                    >
                      <li className="active border-green-jungle">
                        <a
                          href="#tab_0"
                          data-toggle="tab"
                          onClick={this.FinishEditInfo.bind(this)}
                        >
                          Load
                        </a>
                      </li>
                      <li className="border-green-jungle">
                        <a
                          href="#tab_1"
                          data-toggle="tab"
                          onClick={this.FinishEditInfo.bind(this)}
                        >
                          Documents
                        </a>
                      </li>
                      <li className="border-green-jungle">
                        <a
                          href="#tab_2"
                          data-toggle="tab"
                          onClick={this.FinishEditInfo.bind(this)}
                        >
                          Dispatch
                        </a>
                      </li>
                      <li className="border-green-jungle">
                        <a
                          href="#tab_3"
                          data-toggle="tab"
                          onClick={this.FinishEditInfo.bind(this)}
                        >
                          Invoice
                        </a>
                      </li>
                    </ul>

                    <div className="form-group row" hidden={this.state.hidden}>
                      <label
                        className="col-md-1 control-label"
                        style={{ paddingTop: "7px" }}
                      >
                        Status: <span className="font-red-thunderbird"></span>
                      </label>
                      <div className="col-md-3">
                        <select
                          ref="ToploadStatus"
                          value={loadStatus}
                          className="form-control"
                          onChange={() => {
                            var value = this.refs.ToploadStatus.value;

                            axios
                              .post(BaseURL + "customerLoad/updateStatus", {
                                id: this.state.loadid,
                                loadStatus: value,
                                token: this.state.token,
                              })
                              .then((response) => {
                                if (response.data.error === TokenErrorMsg) {
                                  localStorage.setItem("Token", "");
                                  this.props.history.push("/");
                                }

                                console.log(response.data);

                                this.setState({ loadStatus: value });
                              })
                              .catch(function (error) {
                                NotificationManager.error(
                                  "Something Went Wrong Server Is Not Responding ",
                                  "Error Message",
                                  3000
                                );
                                console.log(error);
                              });
                          }}
                        >
                          <option value="Pending">Pending</option>
                          <option value="Dispatched">Dispatched</option>
                          <option value="In-Transit">In-Transit</option>
                          <option value="Delivered">Delivered</option>
                          <option value="Completed">Completed</option>
                          <option value="Invoiced">Invoiced</option>
                          <option value="Closed">Closed</option>
                          <option value="Cancel">Cancel</option>
                        </select>
                      </div>
                    </div>

                    <div className="tab-content">
                      <div className="tab-pane active" id="tab_0">
                        <div className="portlet box yellow-gold">
                          <div className="portlet-title">
                            <div className="caption">
                              <i className="fa fa-cubes"></i>Quick Add Load
                            </div>
                            <div
                              className="actions"
                              style={this.state.readonly ? {} : nonclick}
                              id="EditDiv"
                            >
                              <div
                                className="btn-group"
                                style={
                                  this.state.hidden === false
                                    ? msgShow
                                    : msgNone
                                }
                              >
                                <button
                                  className="btn sbold white dark"
                                  onClick={this.EditForm.bind(this)}
                                  hidden={this.state.hidden}
                                  disabled={this.state.hidden}
                                >
                                  <i className="fa fa-pencil"></i> Edit
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="portlet-body form">
                            <form
                              onSubmit={this.submituserRegistrationForm.bind(
                                this
                              )}
                              className="horizontal-form"
                              autoComplete="off"
                            >
                              <div className="form-body max_width800">
                                <div className="portlet-title">
                                  <h2 className="h3"> Load Details</h2>

                                  <div className="clearfix"></div>
                                  <hr />
                                </div>

                                <div className="form-group row">
                                  <label className="col-md-3 control-label">
                                    Load #:
                                    <span className="font-red-thunderbird"></span>
                                  </label>
                                  <div className="col-md-9">
                                    <input
                                      type="text"
                                      ref="internalload"
                                      name="internalload"
                                      className="form-control"
                                      disabled
                                      placeholder=""
                                      value={this.state.internalLoadNo}
                                      readOnly={this.state.readonly}
                                      onChange={(event) => {
                                        this.setState({
                                          internalLoadNo: event.target.value,
                                        });

                                        const reg = /^[0-9]{0,12}$/;
                                        const validateText = reg.test(
                                          event.target.value.trim()
                                        );
                                        if (
                                          !this.state.readonly &&
                                          !validateText
                                        ) {
                                          var errors = this.state.errors;
                                          errors["internalloadlimit"] =
                                            "*Only Numerics Allowed (Max Range: 12)";
                                          this.setState({ errors });
                                        } else if (
                                          !this.state.readonly &&
                                          validateText &&
                                          event.target.value.trim().length >
                                          0 &&
                                          event.target.value.trim().length < 13
                                        ) {
                                          var errors = this.state.errors;
                                          errors["internalloadlimit"] = "";
                                          this.setState({ errors });

                                          axios
                                            .post(
                                              BaseURL +
                                              "customerLoad/findByLoadNo",
                                              {
                                                id: this.state.loadid,
                                                internalLoadNo:
                                                  event.target.value,
                                                token: this.state.token,
                                              }
                                            )
                                            .then((response) => {
                                              if (
                                                response.data.error ===
                                                TokenErrorMsg
                                              ) {
                                                localStorage.setItem(
                                                  "Token",
                                                  ""
                                                );
                                                this.props.history.push("/");
                                              }

                                              console.log(response.data);

                                              response.data.error
                                                ? (errors["internalload"] =
                                                  "*Record Already Exist With this Load No")
                                                : (errors["internalload"] = "");

                                              this.setState({ errors });
                                            })
                                            .catch(function (error) {
                                              NotificationManager.error(
                                                "Something Went Wrong Server Is Not Responding ",
                                                "Error Message",
                                                3000
                                              );
                                              console.log(error);
                                            });
                                        } else {
                                          var errors = this.state.errors;
                                          errors["internalloadlimit"] = "";
                                          errors["internalload"] = "";
                                          this.setState({ errors });
                                        }
                                      }}
                                    />
                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.internalload}
                                    </div>
                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.internalloadlimit}
                                    </div>
                                  </div>
                                </div>

                                <div className="portlet-title">
                                  <h2 className="h3"> Customer Details: </h2>
                                  <hr />
                                </div>

                                <div className="form-group row">
                                  <label className="col-md-3 control-label">
                                    Customer:
                                    <span className="font-red-thunderbird">
                                      *
                                    </span>
                                  </label>
                                  <div className="col-md-9">
                                    <div className="form-group row">
                                      <div className="col-md-9">
                                        <Select
                                          optionHeight={50}
                                          disabled={this.state.readonly}
                                          value={
                                            this.state.CustomerSelectedOption
                                          }
                                          options={this.state.CustomerOptions}
                                          onChange={this.handleChangeCustomer}
                                          onInputChange={(input) => {
                                            if (input.trim().length > 1) {
                                              axios
                                                .post(
                                                  BaseURL +
                                                  "customer/findByName",
                                                  {
                                                    status: 2,
                                                    searchKeyword: input,
                                                    token: this.state.token,
                                                  }
                                                )
                                                .then((response) => {
                                                  if (
                                                    response.data.error ===
                                                    TokenErrorMsg
                                                  ) {
                                                    localStorage.setItem(
                                                      "Token",
                                                      ""
                                                    );
                                                    this.props.history.push(
                                                      "/"
                                                    );
                                                  }

                                                  var arr = [];

                                                  response.data.data.rows.forEach(
                                                    (element) => {
                                                      var obj = {
                                                        value: element.id,
                                                        label:
                                                          element.customerName +
                                                          (element.address
                                                            .address
                                                            ? ", " +
                                                            element.address
                                                              .address
                                                            : "") +
                                                          (element.address.city
                                                            ? ", " +
                                                            element.address
                                                              .city
                                                            : "") +
                                                          (element.address.state
                                                            ? ", " +
                                                            element.address
                                                              .state.state
                                                            : "") +
                                                          (element.address.zip
                                                            ? ", " +
                                                            element.address
                                                              .zip
                                                            : ""),
                                                      };

                                                      arr.push(obj);
                                                    }
                                                  );

                                                  this.setState({
                                                    CustomerOptions: arr,
                                                    customerOptionsData:
                                                      response.data.data.rows,
                                                  });
                                                })
                                                .catch(function (error) {
                                                  NotificationManager.error(
                                                    "Something Went Wrong Server Is Not Responding ",
                                                    "Error Message",
                                                    3000
                                                  );
                                                  console.log(error);
                                                });
                                            }
                                          }}
                                          placeholder="Search and Choose "
                                        />
                                        <div
                                          style={{ color: "red" }}
                                          className="errorMsg"
                                        >
                                          {this.state.errors.customer}
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="row"
                                      style={
                                        this.state.customerCard.state
                                          ? msgShow
                                          : msgNone
                                      }
                                    >
                                      <div className="col-md-12">
                                        <div className="card_info">
                                          <div className="row">
                                            <div className="col-md-12">
                                              <p>
                                                <i
                                                  className="fa fa-map-marker"
                                                  aria-hidden="true"
                                                ></i>
                                                &nbsp;
                                                {
                                                  this.state.customerCard
                                                    .address
                                                }
                                              </p>
                                            </div>
                                          </div>
                                          <div className="row">
                                            <div className="col-md-6">
                                              <p>
                                                <i
                                                  className="fa fa-building"
                                                  aria-hidden="true"
                                                ></i>
                                                &nbsp;
                                                {this.state.customerCard.city}
                                              </p>
                                            </div>
                                            <div className="col-md-6">
                                              <p>
                                                <i
                                                  className="fa fa-globe"
                                                  aria-hidden="true"
                                                ></i>
                                                &nbsp;
                                                {this.state.customerCard.state}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">
                                    Customers Load #:
                                    <span className="font-red-thunderbird">
                                      *
                                    </span>
                                  </label>
                                  <div className="col-md-9">
                                    <input
                                      type="text"
                                      ref="load"
                                      name="load"
                                      className="form-control"
                                      placeholder=""
                                      readOnly={
                                        this.state.readonly ||
                                        (this.state.CustomerSelectedOption
                                          ? false
                                          : true)
                                      }
                                      onChange={(event) => {
                                        if (
                                          !this.state.readonly &&
                                          event.target.value.trim().length > 0
                                        ) {
                                          axios
                                            .post(
                                              BaseURL +
                                              "customerLoad/findLoadByCustomerAndCustomerLoad",
                                              {
                                                id: this.state.loadid,
                                                customerLoadNo:
                                                  event.target.value,
                                                customerId: this.state
                                                  .CustomerSelectedOption,
                                                token: this.state.token,
                                              }
                                            )
                                            .then((response) => {
                                              if (
                                                response.data.error ===
                                                TokenErrorMsg
                                              ) {
                                                localStorage.setItem(
                                                  "Token",
                                                  ""
                                                );
                                                this.props.history.push("/");
                                              }

                                              console.log(response.data);

                                              var errors = this.state.errors;
                                              response.data.error
                                                ? (errors["load"] =
                                                  "*Record Already Exist With this Load No")
                                                : (errors["load"] = "");

                                              this.setState({ errors });
                                            })
                                            .catch(function (error) {
                                              NotificationManager.error(
                                                "Something Went Wrong Server Is Not Responding ",
                                                "Error Message",
                                                3000
                                              );
                                              console.log(error);
                                            });
                                        }
                                      }}
                                    />
                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.load}
                                    </div>
                                  </div>
                                </div>
                                <div className="portlet-title">
                                  <h2 className="h3"> Rate Details: </h2>
                                  <hr />
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">
                                    Rate:
                                    <span className="font-red-thunderbird"></span>
                                  </label>
                                  <div className="col-md-9">
                                    {/* <input type="text" ref="rate" onChange={this.handleChange} name="rate" className="form-control" placeholder=""   readOnly={this.state.readonly}/> */}
                                    <CurrencyInput
                                      precision="2"
                                      prefix="$"
                                      ref="rate"
                                      value={this.state.rateValue}
                                      onChangeEvent={(
                                        event,
                                        maskedvalue,
                                        floatvalue
                                      ) => {
                                        var value = floatvalue;

                                        console.log(maskedvalue, floatvalue);

                                        var totalAmount = floatvalue;

                                        additionalCharges.forEach((element) => {
                                          totalAmount =
                                            totalAmount +
                                            currencyFormatter.unformat(
                                              element.amount,
                                              { code: "USD" }
                                            );
                                        });

                                        totalAmount = currencyFormatter.format(
                                          totalAmount,
                                          { code: "USD" }
                                        );

                                        value
                                          ? this.setState({
                                            rateValue: value,
                                            totalAmount,
                                          })
                                          : "";
                                      }}
                                      className="form-control"
                                      readOnly={this.state.readonly}
                                      placeholder=""
                                    />

                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.rate}
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">
                                    Type:
                                    <span className="font-red-thunderbird"></span>
                                  </label>
                                  <div className="col-md-9">
                                    <Select
                                      value={
                                        this.state.selectRateTypeValue
                                      }
                                      options={optionsDropdown}
                                      onChange={(selectValue) => this.setState({ selectRateTypeValue: selectValue })}
                                    />
                                    <div
                                      style={{ color: "red" }}
                                      className="errorMsg"
                                    >
                                      {this.state.errors.rate}
                                    </div>
                                  </div>
                                </div>


                                {role === "Admin" || role === dispatcherUser || role === accountingUser ? (
                                  <div className="portlet-title">
                                    <h2 className="h3"> Sales Details: </h2>
                                    <hr />
                                  </div>
                                ) : null}

                                {role === "Admin" || role === dispatcherUser || role === accountingUser ? (
                                  <div>
                                    <div className="form-group row">
                                      <label className="col-md-3 control-label">
                                        Sales Person 1:
                                        <span className="font-red-thunderbird"></span>
                                      </label>
                                      <div className="col-md-9">
                                        <Select
                                          value={
                                            this.state.salesPerson1
                                          }
                                          options={optionsUsersList}
                                          onChange={(selectValue) => this.setState({ salesPerson1: selectValue })}
                                        />
                                        <div
                                          style={{ color: "red" }}
                                          className="errorMsg"
                                        >
                                          {this.state.errors.rate}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                    <label className="col-md-3 control-label">
                                      Sales Person 2:
                                      <span className="font-red-thunderbird"></span>
                                    </label>
                                    <div className="col-md-9">
                                      <Select
                                        value={
                                          this.state.salesPerson2
                                        }
                                        options={optionsUsersList}
                                        onChange={(selectValue) => this.setState({ salesPerson2: selectValue })}
                                      />
                                      <div
                                        style={{ color: "red" }}
                                        className="errorMsg"
                                      >
                                        {this.state.errors.rate}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                ) : null}

                                <div className="portlet-title">
                                  <h2 className="h3">
                                    Pickup and Delivery Details:
                                  </h2>
                                  <hr />
                                </div>
                                {StopData.map((StopObj, index) => {
                                  return (
                                    <div key={StopObj.stopNo}>
                                      <div className="form-group row">
                                        <label className="col-md-3 control-label">
                                          <b>Stop {StopObj.stopNo}:</b>
                                        </label>
                                        <div className="col-md-9">
                                          <span
                                            style={{
                                              marginTop: "2px",
                                              marginTop: "6px",
                                              position: "relative",
                                              zIndex: 0,
                                            }}
                                          >
                                            <div
                                              className="btn-group btn-group-devided"
                                              data-toggle="buttons"
                                              style={
                                                this.state.readonly
                                                  ? nonclick
                                                  : {}
                                              }
                                            >
                                              <label
                                                className="btn btn-transparent dark btn-outline btn-circle btn-sm active"
                                                disabled={this.state.readonly}
                                                onClick={() => {
                                                  var stop = StopData;
                                                  stop[index]["stopType"] =
                                                    "pickup";
                                                  if (this._isMounted) {
                                                    this.setState({
                                                      StopData: stop,
                                                    });
                                                  }
                                                  console.log(
                                                    this.state.StopData
                                                  );
                                                }}
                                              >
                                                <input
                                                  type="radio"
                                                  name="options"
                                                  className="toggle"
                                                  id="option1"
                                                />
                                                &nbsp;PICKUP&nbsp;
                                              </label>
                                              <label
                                                className="btn btn-transparent dark btn-outline btn-circle btn-sm"
                                                disabled={this.state.readonly}
                                                onClick={() => {
                                                  var stop = StopData;
                                                  stop[index]["stopType"] =
                                                    "delivery";
                                                  if (this._isMounted) {
                                                    this.setState({
                                                      StopData: stop,
                                                    });
                                                  }
                                                  console.log(
                                                    this.state.StopData
                                                  );
                                                }}
                                              >
                                                <input
                                                  type="radio"
                                                  name="options"
                                                  className="toggle"
                                                  id="option2"
                                                />
                                                DELIVERY
                                              </label>
                                              <label
                                                className="btn btn-transparent dark btn-outline btn-circle btn-sm"
                                                disabled={this.state.readonly}
                                                onClick={() => {
                                                  var stop = StopData;
                                                  stop[index]["stopType"] =
                                                    "transit";
                                                  if (this._isMounted) {
                                                    this.setState({
                                                      StopData: stop,
                                                    });
                                                  }
                                                  console.log(
                                                    this.state.StopData
                                                  );
                                                }}
                                              >
                                                <input
                                                  type="radio"
                                                  name="options"
                                                  className="toggle"
                                                  id="option2"
                                                />
                                                Transit
                                              </label>
                                            </div>
                                          </span>
                                        </div>
                                      </div>

                                      <div className="form-group row">
                                        <label className="col-md-3 control-label">
                                          Schedule:
                                        </label>
                                        <div className="col-md-9">
                                          <div className="form-group row">
                                            <div
                                              className="col-md-5"
                                              style={{ position: "static" }}
                                            >
                                              <DatePicker
                                                disabled={this.state.readonly}
                                                className="form-control"
                                                selected={
                                                  new Date(
                                                    moment.utc(
                                                      StopData[index][
                                                      "scheduleFrom"
                                                      ]
                                                    ).format("MM/DD/YYYY HH:mm:ss")
                                                  )
                                                }
                                                showTimeSelect
                                                showTimeInput
                                                timeInputLabel="Input Time:"
                                                timeFormat="HH:mm"
                                                timeIntervals={30}
                                                dateFormat="MM/dd/yyyy HH:mm"
                                                timeCaption="Time"
                                                onChange={(date) => {
                                                  let getUTC = getTimeZone()
                                                  console.log(getUTC, "getUTC")

                                                  let splitUTC = getUTC.split(':')
                                                  let getHourse = splitUTC[0]
                                                  let getMints = splitUTC[1]

                                                  if (getHourse.includes("-")) {
                                                    let split = getHourse.split('-')
                                                    split = parseInt(split[1])
                                                    let finalMints = parseInt(getMints)
                                                    let getFinalDate = new Date(moment(date).utc().toDate())
                                                    let setDateFinal = getFinalDate.setHours(getFinalDate.getHours() - split);
                                                    setDateFinal = setDateFinal - (finalMints * 60 * 1000)
                                                    date = setDateFinal;
                                                  } else {
                                                    let split = getHourse.split('+')
                                                    split = parseInt(split[1])
                                                    let finalMints = parseInt(getMints)
                                                    let getFinalDate = new Date(moment(date).utc().toDate())
                                                    let setDateFinal = getFinalDate.setHours(getFinalDate.getHours() + split);
                                                    setDateFinal = setDateFinal - (finalMints * 60 * 1000)
                                                    date = setDateFinal;
                                                  }
                                                  console.log(date, "getUTC")
                                                  var stop = StopData;
                                                  stop[index][
                                                    "scheduleFrom"
                                                  ] = date;
                                                  stop[index][
                                                    "scheduleTo"
                                                  ] = date;
                                                  if (this._isMounted) {
                                                    this.setState({
                                                      StopData: stop,
                                                    });
                                                  }
                                                }}
                                              />
                                            </div>
                                            <div className="col-md-2 text-center">
                                              TO
                                            </div>
                                            <div
                                              className="col-md-5"
                                              style={{ position: "static" }}
                                            >
                                              <DatePicker
                                                disabled={this.state.readonly}
                                                className="form-control"
                                                selected={
                                                  new Date(
                                                    moment.utc(
                                                      StopData[index][
                                                      "scheduleTo"
                                                      ]
                                                    ).format("MM/DD/YYYY HH:mm:ss")
                                                  )
                                                }
                                                showTimeSelect
                                                showTimeInput
                                                timeInputLabel="Input Time:"
                                                timeFormat="HH:mm"
                                                timeIntervals={30}
                                                dateFormat="MM/dd/yyyy HH:mm"
                                                timeCaption="Time"
                                                onChange={(date) => {
                                                  let getUTC = getTimeZone()
                                                  console.log(getUTC, "getUTC")

                                                  let splitUTC = getUTC.split(':')
                                                  let getHourse = splitUTC[0]
                                                  let getMints = splitUTC[1]

                                                  if (getHourse.includes("-")) {
                                                    let split = getHourse.split('-')
                                                    split = parseInt(split[1])
                                                    let finalMints = parseInt(getMints)
                                                    let getFinalDate = new Date(moment(date).utc().toDate())
                                                    let setDateFinal = getFinalDate.setHours(getFinalDate.getHours() - split);
                                                    setDateFinal = setDateFinal - (finalMints * 60 * 1000)
                                                    date = setDateFinal;
                                                  } else {
                                                    let split = getHourse.split('+')
                                                    split = parseInt(split[1])
                                                    let finalMints = parseInt(getMints)
                                                    let getFinalDate = new Date(moment(date).utc().toDate())
                                                    let setDateFinal = getFinalDate.setHours(getFinalDate.getHours() + split);
                                                    setDateFinal = setDateFinal - (finalMints * 60 * 1000)
                                                    date = setDateFinal;
                                                  }
                                                  console.log(date, "getUTC")
                                                  var stop = StopData;
                                                  stop[index][
                                                    "scheduleTo"
                                                  ] = date;
                                                  if (this._isMounted) {
                                                    this.setState({
                                                      StopData: stop,
                                                    });
                                                  }
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="form-group row">
                                        <label className="col-md-3 control-label"></label>
                                        <div className="col-md-9">
                                          <div className="form-group row">
                                            <div
                                              className="col-md-5"
                                              style={{ position: "static" }}
                                            >
                                              <label>
                                                <div className="checker">
                                                  <span
                                                    className={
                                                      StopData[index][
                                                        "isAddressAvailable"
                                                      ] === "1"
                                                        ? "checked"
                                                        : ""
                                                    }
                                                  >
                                                    <input
                                                      type="checkbox"
                                                      onClick={(e) => {
                                                        const StopData = this
                                                          .state.StopData;
                                                        StopData[
                                                          index
                                                        ].isAddressAvailable =
                                                          StopData[index]
                                                            .isAddressAvailable ===
                                                            "0"
                                                            ? "1"
                                                            : "0";
                                                        this.setState({
                                                          StopData,
                                                        });
                                                      }}
                                                      value={
                                                        this.state.StopData[
                                                          index
                                                        ].isAddressAvailable
                                                      }
                                                      disabled={
                                                        this.state.readonly
                                                      }
                                                    />
                                                  </span>
                                                </div>
                                                Address &nbsp;
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {this.state.StopData[index]
                                        .isAddressAvailable === "1" ? (
                                        <div className="form-group row">
                                          <label className="col-md-3 control-label">
                                            {StopData[index]["stopType"] ===
                                              "pickup"
                                              ? "Shipper"
                                              : StopData[index]["stopType"] ===
                                                "delivery"
                                                ? "Receiver"
                                                : "Transit"}
                                            :
                                          </label>

                                          <div className="col-md-9">
                                            <div className="form-group row">
                                              {" "}
                                              <div className="col-md-12">
                                                <Select
                                                  optionHeight={50}
                                                  disabled={this.state.readonly}
                                                  value={
                                                    StopData[index]["shipperId"]
                                                  }
                                                  options={
                                                    StopData[index][
                                                    "ShipperOptions"
                                                    ]
                                                  }
                                                  onChange={(changeValue) => {
                                                    var value = changeValue
                                                      ? changeValue.value
                                                      : null;

                                                    var stop = StopData;
                                                    stop[index][
                                                      "shipperOptionsData"
                                                    ].forEach((element) => {
                                                      if (
                                                        element.id === value
                                                      ) {
                                                        var address = element.address
                                                          ? element.address
                                                            .address
                                                            ? element.address
                                                              .address
                                                            : ""
                                                          : "";
                                                        var city = element.address
                                                          ? element.address.city
                                                            ? element.address
                                                              .city
                                                            : ""
                                                          : "";
                                                        var state = element.address
                                                          ? element.address
                                                            .state
                                                            ? element.address
                                                              .state.state
                                                            : ""
                                                          : "";
                                                        var zip = element.address
                                                          ? element.address.zip
                                                            ? element.address
                                                              .zip
                                                            : ""
                                                          : "";
                                                        var telephone = element.telephone
                                                          ? element.address
                                                            .telephone
                                                            ? element.address
                                                              .telephone
                                                            : ""
                                                          : "";
                                                        var stateAbbreviation = element.address
                                                          ? element.address
                                                            .state
                                                            ? element.address
                                                              .state
                                                              .stateAbbrivation
                                                            : ""
                                                          : "";

                                                        stop[index][
                                                          "shipperCard"
                                                        ] = {
                                                          address: address,
                                                          city: city,
                                                          state: state,
                                                          telephone: telephone,
                                                          name: element.name,
                                                          stateAbbr: stateAbbreviation,
                                                          zip: zip,
                                                        };
                                                      }
                                                    });
                                                    stop[index][
                                                      "shipperId"
                                                    ] = value;
                                                    value
                                                      ? {}
                                                      : (stop[index][
                                                        "shipperCard"
                                                      ] = {});
                                                    if (this._isMounted) {
                                                      this.setState({
                                                        StopData: stop,
                                                      });
                                                    }
                                                  }}
                                                  onInputChange={(input) => {
                                                    if (
                                                      input.trim().length > 1
                                                    ) {
                                                      axios
                                                        .post(
                                                          BaseURL +
                                                          "shipper/findByName",
                                                          {
                                                            searchKeyword: input,
                                                            token: this.state
                                                              .token,
                                                          }
                                                        )
                                                        .then((response) => {
                                                          if (
                                                            response.data
                                                              .error ===
                                                            TokenErrorMsg
                                                          ) {
                                                            localStorage.setItem(
                                                              "Token",
                                                              ""
                                                            );
                                                            this.props.history.push(
                                                              "/"
                                                            );
                                                          }

                                                          var arr = [];

                                                          response.data.data.rows.forEach(
                                                            (element) => {
                                                              var CompleteAddress =
                                                                "";

                                                              var address = element.address
                                                                ? element
                                                                  .address
                                                                  .address
                                                                  ? ", " +
                                                                  element
                                                                    .address
                                                                    .address +
                                                                  ", "
                                                                  : ""
                                                                : "";
                                                              var city = element.address
                                                                ? element
                                                                  .address
                                                                  .city
                                                                  ? element
                                                                    .address
                                                                    .city +
                                                                  ", "
                                                                  : ""
                                                                : "";
                                                              var state = element.address
                                                                ? element
                                                                  .address
                                                                  .state
                                                                  ? element
                                                                    .address
                                                                    .state
                                                                    .state +
                                                                  ", "
                                                                  : ""
                                                                : "";
                                                              var zip = element.address
                                                                ? element
                                                                  .address.zip
                                                                  ? element
                                                                    .address
                                                                    .zip
                                                                  : ""
                                                                : "";

                                                              CompleteAddress =
                                                                address +
                                                                city +
                                                                state +
                                                                zip;

                                                              var obj = {
                                                                value:
                                                                  element.id,
                                                                label:
                                                                  element.name +
                                                                  CompleteAddress,
                                                              };

                                                              arr.push(obj);
                                                            }
                                                          );

                                                          var stop = StopData;
                                                          stop[index][
                                                            "ShipperOptions"
                                                          ] = arr;
                                                          stop[index][
                                                            "shipperOptionsData"
                                                          ] =
                                                            response.data.data.rows;

                                                          this.setState({
                                                            StopData: stop,
                                                          });
                                                        })
                                                        .catch(function (error) {
                                                          NotificationManager.error(
                                                            "Something Went Wrong Server Is Not Responding ",
                                                            "Error Message",
                                                            3000
                                                          );
                                                          console.log(error);
                                                        });
                                                    }
                                                  }}
                                                  placeholder="Search and Choose "
                                                />
                                              </div>
                                              {/* <div
                                                className="col-md-3"
                                                style={
                                                  this.state.readonly
                                                    ? nonclick
                                                    : {}
                                                }
                                              >
                                                <a
                                                  data-toggle="modal"
                                                  href="#shipper_info"
                                                  className="btn btn-block green-meadow"
                                                >
                                                  <i className="fa fa-plus"></i>
                                                  Add
                                                </a>
                                              </div> */}
                                            </div>{" "}
                                            <div
                                              className="row"
                                              style={
                                                StopData[index]["shipperCard"][
                                                  "address"
                                                ]
                                                  ? msgShow
                                                  : msgNone
                                              }
                                            >
                                              <div className="col-md-12">
                                                <div className="card_info">
                                                  <div className="row">
                                                    <div className="col-md-12">
                                                      <p>
                                                        <i
                                                          className="fa fa-map-marker"
                                                          aria-hidden="true"
                                                        ></i>
                                                        &nbsp;
                                                        {
                                                          StopData[index][
                                                          "shipperCard"
                                                          ]["address"]
                                                        }
                                                      </p>
                                                    </div>
                                                  </div>
                                                  <div className="row">
                                                    <div className="col-md-6">
                                                      <p>
                                                        <i
                                                          className="fa fa-building"
                                                          aria-hidden="true"
                                                        ></i>
                                                        &nbsp;
                                                        {
                                                          StopData[index][
                                                          "shipperCard"
                                                          ]["city"]
                                                        }
                                                      </p>
                                                    </div>
                                                    <div className="col-md-6">
                                                      <p>
                                                        <i
                                                          className="fa fa-globe"
                                                          aria-hidden="true"
                                                        ></i>
                                                        &nbsp;
                                                        {
                                                          StopData[index][
                                                          "shipperCard"
                                                          ]["state"]
                                                        }
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <>
                                          <div className="form-group row">
                                            <label className="col-md-3 control-label">
                                              Address
                                            </label>
                                            <div className="col-md-9">
                                              <PlacesAutocomplete
                                                searchOptions={searchOptions}
                                                value={
                                                  this.state.StopData[index]
                                                    .address
                                                }
                                                onChange={(address) =>
                                                  this.handleAddressChange(
                                                    address,
                                                    index
                                                  )
                                                }
                                                onSelect={(address) =>
                                                  this.handleSelect(
                                                    address,
                                                    index
                                                  )
                                                }
                                                onError={(e) => console.log(e)}
                                                clearItemsOnError={true}
                                              >
                                                {({
                                                  getInputProps,
                                                  suggestions,
                                                  getSuggestionItemProps,
                                                  loading,
                                                }) => (
                                                  <div className="form-group">
                                                    <div className="">
                                                      <input
                                                        type="text"
                                                        ref="address"
                                                        {...getInputProps({
                                                          className:
                                                            "location-search-input",
                                                        })}
                                                        name="address"
                                                        className="form-control"
                                                        placeholder="City/State"
                                                      />

                                                      <div
                                                        className="autocomplete-dropdown-container"
                                                        style={{
                                                          position: "absolute",
                                                          zIndex: 9,
                                                          background: "white",
                                                          width: "92%",
                                                        }}
                                                      >
                                                        {loading && (
                                                          <div className="suggestion-item">
                                                            Loading...
                                                          </div>
                                                        )}
                                                        {suggestions.map(
                                                          (suggestion) => {
                                                            const className = suggestion.active
                                                              ? "suggestion-item--active"
                                                              : "suggestion-item";
                                                            return (
                                                              <div
                                                                {...getSuggestionItemProps(
                                                                  suggestion,
                                                                  {
                                                                    className,
                                                                  }
                                                                )}
                                                              >
                                                                <span>
                                                                  {
                                                                    suggestion.description
                                                                  }
                                                                </span>
                                                              </div>
                                                            );
                                                          }
                                                        )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                )}
                                              </PlacesAutocomplete>
                                            </div>
                                          </div>
                                        </>
                                      )}
                                      <div className="form-group row">
                                        {/* <label className="col-md-3 control-label">
                                          {StopData[index]["stopType"] ===
                                          "pickup"
                                            ? "Shipper"
                                            : StopData[index]["stopType"] ===
                                              "delivery"
                                            ? "Receiver"
                                            : "Transit"}
                                          Status:
                                        </label> */}
                                        <div className="col-md-9">
                                          {/* <span style={{marginTop: '2px', marginTop: '6px' ,position:'relative',zIndex:0}}>
                                <div className="btn-group btn-group-devided" data-toggle="buttons" style={this.state.readonly ?nonclick:{}}>
                                  <label className="btn btn-transparent dark btn-outline btn-circle btn-sm active" 
                                  disabled={this.state.readonly }
                                   onClick={()=>{
                                     var stop =StopData;
                                     stop[index]['shipperStatus']='checkedin';
                                     if(this._isMounted){
                                     this.setState({StopData:stop});
                                     }
                                     }}
                                     >

                                    <input type="radio" name="options" className="toggle" id="option1"/>
                                    CHECKED IN</label>
                                  <label className="btn btn-transparent dark btn-outline btn-circle btn-sm" 
                                  disabled={this.state.readonly }
                                  onClick={()=>{
                                     var stop =StopData;
                                     stop[index]['shipperStatus']='checkedout';
                                     if(this._isMounted){
                                     this.setState({StopData:stop});
                                     }
                                     }}
                                     >
                                    <input type="radio" name="options" className="toggle" id="option2"/>
                                    CHECKED OUT</label>
                                </div>
                              </span> */}

                                          {/* <div
                                            className="checkbox-list"
                                            style={{ marginTop: "-4px" }}
                                          >
                                            <label className="checkbox-inline">
                                              <div className="checker">
                                                <span
                                                  className={
                                                    StopData[index][
                                                      "checkedIn"
                                                    ] === "1"
                                                      ? "checked"
                                                      : ""
                                                  }
                                                >
                                                  <input
                                                    type="checkbox"
                                                    onClick={() => {
                                                      var stop = StopData;
                                                      stop[index]["checkedIn"] =
                                                        stop[index][
                                                          "checkedIn"
                                                        ] === "0"
                                                          ? "1"
                                                          : "0";
                                                      stop[index][
                                                        "checkedIn"
                                                      ] === "1"
                                                        ? (stop[index][
                                                            "checkedInTime"
                                                          ] = moment())
                                                        : (stop[index][
                                                            "checkedInTime"
                                                          ] = moment().set({
                                                            hour: 0,
                                                            minute: 0,
                                                            second: 0,
                                                          }));
                                                      if (this._isMounted) {
                                                        this.setState({
                                                          StopData: stop,
                                                        });
                                                      }
                                                    }}
                                                    value="option1"
                                                    disabled={
                                                      this.state.readonly
                                                    }
                                                  />
                                                </span>
                                              </div>
                                              Checked In &nbsp;
                                            </label>

                                            <div
                                              style={{
                                                position: "static",
                                                display: "inline-block",
                                                marginLeft: "63px",
                                              }}
                                            >
                                              <DatePicker
                                                disabled={this.state.readonly}
                                                className="form-control"
                                                selected={
                                                  new Date(
                                                    // moment.utc(
                                                    StopData[index][
                                                      "checkedInTime"
                                                    ]
                                                    // ).format("MM/DD/YYYY HH:mm:ss")
                                                  )
                                                }
                                                showTimeSelect
                                                showTimeInput
                                                timeInputLabel="Input Time:"
                                                timeFormat="HH:mm"
                                                timeIntervals={30}
                                                dateFormat={
                                                  StopData[index][
                                                    "checkedIn"
                                                  ] === "1"
                                                    ? "MM/dd/yyyy HH:mm"
                                                    : "HH:mm"
                                                }
                                                timeCaption="Time"
                                                onChange={(date) => {
                                                  var stop = StopData;
                                                  stop[index][
                                                    "checkedInTime"
                                                  ] = date;
                                                  if (this._isMounted) {
                                                    this.setState({
                                                      StopData: stop,
                                                    });
                                                  }
                                                }}
                                              />
                                            </div>
                                          </div>

                                          <div
                                            className="checkbox-list"
                                            style={{ marginTop: "10px" }}
                                          >
                                            <label className="checkbox-inline">
                                              <div className="checker">
                                                <span
                                                  className={
                                                    StopData[index][
                                                      "checkedOut"
                                                    ] === "1"
                                                      ? "checked"
                                                      : ""
                                                  }
                                                >
                                                  <input
                                                    type="checkbox"
                                                    onClick={() => {
                                                      var stop = StopData;
                                                      stop[index][
                                                        "checkedOut"
                                                      ] =
                                                        stop[index][
                                                          "checkedOut"
                                                        ] === "0"
                                                          ? "1"
                                                          : "0";
                                                      stop[index][
                                                        "checkedOut"
                                                      ] === "1"
                                                        ? (stop[index][
                                                            "checkedOutTime"
                                                          ] = moment())
                                                        : (stop[index][
                                                            "checkedOutTime"
                                                          ] = moment().set({
                                                            hour: 0,
                                                            minute: 0,
                                                            second: 0,
                                                          }));
                                                      if (this._isMounted) {
                                                        this.setState({
                                                          StopData: stop,
                                                        });
                                                      }
                                                    }}
                                                    value="option1"
                                                    disabled={
                                                      this.state.readonly
                                                    }
                                                  />
                                                </span>
                                              </div>
                                              Checked Out
                                            </label>

                                            <div
                                              style={{
                                                position: "static",
                                                display: "inline-block",
                                                marginLeft: "60px",
                                              }}
                                            >
                                              <DatePicker
                                                disabled={this.state.readonly}
                                                className="form-control"
                                                selected={
                                                  new Date(
                                                    // moment.utc(
                                                    StopData[index][
                                                      "checkedOutTime"
                                                    ]
                                                    // ).format("MM/DD/YYYY HH:mm:ss")
                                                  )
                                                }
                                                showTimeSelect
                                                showTimeInput
                                                timeInputLabel="Input Time:"
                                                timeFormat="HH:mm"
                                                timeIntervals={30}
                                                dateFormat={
                                                  StopData[index][
                                                    "checkedOut"
                                                  ] === "1"
                                                    ? "MM/dd/yyyy HH:mm"
                                                    : "HH:mm"
                                                }
                                                timeCaption="Time"
                                                onChange={(date) => {
                                                  var stop = StopData;
                                                  stop[index][
                                                    "checkedOutTime"
                                                  ] = date;

                                                  if (this._isMounted) {
                                                    this.setState({
                                                      StopData: stop,
                                                    });
                                                  }
                                                }}
                                              />
                                            </div>
                                          </div>
                                        */}
                                        </div>
                                      </div>

                                      {/* <div className="form-group row">
                                        <label className="col-md-3 control-label">
                                          {StopData[index]["stopType"] ===
                                          "pickup"
                                            ? "PU"
                                            : "DEL"}
                                          #
                                        </label>
                                        <div className="col-md-9">
                                          <input
                                            type="text"
                                            id="pu"
                                            className="form-control"
                                            placeholder=""
                                            disabled={this.state.readonly}
                                            onChange={(e) => {
                                              var stop = StopData;
                                              stop[index]["puNo"] =
                                                e.target.value;
                                              if (this._isMounted) {
                                                this.setState({
                                                  StopData: stop,
                                                });
                                              }
                                            }}
                                          />
                                        </div>
                                      </div>
                                      */}
                                      <div className="form-group row">
                                        <label className="col-md-3 control-label">
                                          Notes:
                                        </label>
                                        <div className="col-md-9">
                                          <textarea
                                            id="notes"
                                            className="form-control"
                                            disabled={this.state.readonly}
                                            onChange={(e) => {
                                              var stop = StopData;
                                              stop[index]["notes"] =
                                                e.target.value;
                                              if (this._isMounted) {
                                                this.setState({
                                                  StopData: stop,
                                                });
                                              }
                                            }}
                                          ></textarea>
                                        </div>
                                      </div>

                                      {StopObj.stopNo !== 1 &&
                                        StopObj.stopNo !== 2 ? (
                                        <div className="form-group row">
                                          <div className="col-md-3"></div>
                                          <div className="col-md-9">
                                            <button
                                              type="button"
                                              className="btn red"
                                              disabled={this.state.readonly}
                                              onClick={() => {
                                                if (
                                                  !window.confirm(
                                                    "Are You Sure You Want To Delete ? "
                                                  )
                                                ) {
                                                  return false;
                                                } else {
                                                  var stop = StopData;
                                                  stop.splice(index, 1);
                                                  if (this._isMounted) {
                                                    this.setState({
                                                      StopData: stop,
                                                    });
                                                  }
                                                }
                                              }}
                                            >
                                              DELETE Stop
                                            </button>
                                          </div>
                                        </div>
                                      ) : null}
                                    </div>
                                  );
                                })}
                                <div className="form-group row">
                                  <div className="col-md-3"></div>
                                  <div className="col-md-9">
                                    <button
                                      type="button"
                                      className="btn green-meadow"
                                      disabled={this.state.readonly}
                                      onClick={this.addStopChild}
                                    >
                                      Add Stop
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div className="clearfix"></div>
                              <div className="form-actions right">
                                <button
                                  onClick={this.cancel.bind(this)}
                                  type="button"
                                  className="btn default"
                                >
                                  Cancel
                                </button>
                                &nbsp;
                                <button
                                  disabled={this.state.readonly}
                                  type="submit"
                                  className="btn green-meadow"
                                >
                                  {this.state.whileSave === false ? (
                                    <i className="fa fa-save"></i>
                                  ) : (
                                    <i className="fa fa-circle-o-notch fa-spin"></i>
                                  )}
                                  Save
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane " id="tab_1">
                        <div className="portlet box yellow-gold">
                          <div className="portlet-title">
                            <div className="caption">
                              <i className="fa fa-file"></i> Documents
                            </div>
                          </div>
                          <div className="portlet-body form">
                            <form
                              action="#"
                              className="horizontal-form"
                              _lpchecked="1"
                            >
                              <div className="form-body max_width800">
                                {Documents.map((element, index) => {
                                  return (
                                    <div
                                      className="form-group row"
                                      key={element.documentNo}
                                    >
                                      <label className="col-md-4 control-label">
                                        {element.label}
                                      </label>
                                      <div className="col-md-8 fileinput ">
                                        <div className="input-group input-large doc_custom">
                                          <div
                                            className="form-control uneditable-input input-fixed input-medium"
                                            data-trigger="fileinput"
                                          >
                                            <i className="fa fa-file fileinput-exists"></i>
                                            &nbsp;
                                            <span className="fileinput-filename">
                                              {element.fileName}
                                            </span>
                                          </div>
                                          <span className="input-group-addon btn default btn-file">
                                            <span className="fileinput-new">
                                              Upload file
                                            </span>
                                            <input
                                              type="file"
                                              onChange={(e) => {
                                                e.preventDefault();

                                                if (e.target.files[0]) {
                                                  Documents[index]["file"] =
                                                    e.target.files[0];
                                                  this.setState({ Documents });
                                                  this.onFileChange(
                                                    index,
                                                    Documents[index]
                                                  );
                                                }
                                              }}
                                            />
                                          </span>
                                          {element.showIcons ? (
                                            <button
                                              hidden={
                                                element.fileName ? false : true
                                              }
                                              className="input-group-addon btn green fileinput-exists"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                this.download(
                                                  index,
                                                  Documents[index]
                                                );
                                              }}
                                            >
                                              <i
                                                style={{ color: "#fff" }}
                                                className="fa fa-eye"
                                              ></i>
                                            </button>
                                          ) : (
                                            <button
                                              className="input-group-addon btn green fileinput-exists"
                                              onClick={(e) =>
                                                e.preventDefault()
                                              }
                                            >
                                              <i
                                                style={{ color: "#fff" }}
                                                className="fa fa-circle-o-notch fa-spin"
                                              ></i>
                                            </button>
                                          )}
                                          <button
                                            hidden={
                                              element.fileName ? false : true
                                            }
                                            className="input-group-addon btn red fileinput-exists"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              this.deleteFile(
                                                index,
                                                Documents[index]
                                              );
                                            }}
                                          >
                                            <i
                                              style={{ color: "#fff" }}
                                              className="fa fa-trash"
                                            ></i>
                                          </button>
                                        </div>
                                        <div
                                          style={{ color: "red" }}
                                          className="errorMsg"
                                        >
                                          {element.error}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                                <div className="">
                                  <a
                                    data-toggle="modal"
                                    href="#doc_pop"
                                    className="btn green-meadow"
                                  >
                                    <span
                                      className="md-click-circle md-click-animate"
                                      style={{
                                        height: "72px",
                                        width: "72px",
                                        top: "23px",
                                        left: "2px",
                                      }}
                                    ></span>
                                    <i className="fa fa-plus"></i> Add
                                  </a>
                                </div>
                              </div>
                              <div className="clearfix"></div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane " id="tab_2">
                        {this.state.loadid ? (
                          <DispatchTab
                            loadid={this.state.loadid}
                            stopData={StopData}
                            handleDispatchStopsCheckBox={
                              this.handleDispatchStopsCheckBox
                            }
                            resetDispatchStopsCheckBox={
                              this.resetDispatchStopsCheckBox
                            }
                            checkedDispatchStopsCheckBox={
                              this.checkedDispatchStopsCheckBox
                            }
                            loadNo={this.refs.internalload.value}
                            freightType={this.state.freightType}
                            temperatureType={this.state.temperatureType}
                            cycle={this.state.cycle}
                            temp={
                              this.refs.temperature
                                ? this.refs.temperature.value
                                : ""
                            }
                          />
                        ) : null}
                      </div>
                      <div className="tab-pane " id="tab_3">
                        {loadid ? (
                          <InvoiceTab
                            additionalCharges={additionalCharges}
                            Documents={[...Documents]}
                            totalAmount={totalAmount}
                            submitForm={this.submitInvoiceForm}
                            resetAdditionalChargesPopUp={
                              this.resetAdditionalChargesPopUp
                            }
                            addCharges={this.addInvoiceCharges}
                            deleteCharges={this.deleteInvoiceCharges}
                            setPaymentType={this.setInvoicePaymentType}
                            handleInputChange={
                              this.handleInvoicePopUpInputChange
                            }
                            InputValues={invoicePopUpInputValues}
                            download={this.download}
                            handleCheckBox={this.handleInvoiceDocsCheckBox}
                            paymentType={paymentType}
                            invoiceURL={invoiceURL}
                            deleteInvoice={this.deleteInvoice}
                            invoiceLoader={invoiceLoader}
                            loadRate={this.state.rateValue}
                            invoiceFileName={invoiceFileName}
                            quickPay={quickPay}
                            inputQuickPay={this.inputQuickPay}
                            invoiceTo={invoiceTo}
                            setInvoiceTo={this.setInvoiceTo}
                            factoringCompany={
                              this.state.carrierdata.factoringCompany
                            }
                            invoiceStatus={this.state.invoiceStatus}
                            setInvoiceStatus={this.setInvoiceStatus}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="contact"
              data-backdrop="static"
              data-keyboard="false"
              tabIndex="-1"
              aria-hidden="true"
            >
              <div className="modal-dialog  modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      id="closePopUp"
                      data-dismiss="modal"
                      aria-hidden="true"
                      hidden={true}
                    ></button>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-hidden="true"
                      onClick={this.ClearContact}
                    ></button>
                    <h4 className="modal-title">Contact</h4>
                  </div>
                  <div className="modal-body">
                    <div className="portlet-body form">
                      <form
                        onSubmit={this.SaveContact.bind(this)}
                        className="horizontal-form"
                      >
                        <div className="form-body max_width800">
                          <br />

                          <div className="form-group row">
                            <label className="col-md-3 control-label">
                              Name
                              <span className="font-red-thunderbird">*</span>
                            </label>
                            <div className="col-md-9">
                              <input
                                type="text"
                                ref="ContactName"
                                pattern="[a-z A-Z0-9-'@!$#:;,.-_\x22 ]{1,200}"
                                title="Please Enter Contact Name (Max limit: 200)"
                                className="form-control"
                                placeholder=""
                                required
                              />
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-md-3 control-label">
                              Title
                            </label>
                            <div className="col-md-9">
                              <input
                                type="text"
                                ref="ContactTitle"
                                pattern="[a-z A-Z'(),-_.\x22 ]{1,200}"
                                title="Please Enter title (Max limit: 200)"
                                className="form-control"
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-md-3 control-label">
                              Email
                            </label>
                            <div className="col-md-9">
                              <input
                                type="email"
                                ref="ContactEmail"
                                className="form-control"
                                placeholder=""
                              />
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-md-3 control-label">
                              Telephone
                            </label>
                            <div className="col-md-9 form-inline">
                              <input
                                type="text"
                                ref="ContactTelephone"
                                id="contactTelephone"
                                pattern="[a-z A-Z0-9- ]{12}"
                                title="Please Enter Full lenght Telephone (12 digits)"
                                onChange={this.AutoFormatContact}
                                className="form-control"
                                placeholder="xxx-xxx-xxxx"
                              />
                              <span className="help-inline"> EXT </span>
                              <input
                                type="text"
                                pattern="[0-9]{1,10}"
                                title="Please Enter extention (Max 10 Digits Only)"
                                ref="ContactExt"
                                className="form-control"
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-md-3 control-label">
                              Cell Phone
                            </label>
                            <div className="col-md-9">
                              <input
                                type="text"
                                ref="ContactPhone"
                                id="contactPhone"
                                pattern="[a-z A-Z0-9- ]{12}"
                                title="Please Enter Full lenght Cellphone (12 digits)"
                                onChange={this.AutoFormatContact}
                                className="form-control"
                                placeholder="xxx-xxx-xxxx"
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-md-3 control-label">
                              Fax
                            </label>
                            <div className="col-md-9">
                              <input
                                type="text"
                                ref="ContactFax"
                                id="contactFax"
                                pattern="[a-z A-Z0-9- ]{12}"
                                title="Please Enter Full lenght fax (12 digits)"
                                onChange={this.AutoFormatContact}
                                className="form-control"
                                placeholder="xxx-xxx-xxxx"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="clearfix"></div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn default"
                            onClick={this.ClearContact}
                            data-dismiss="modal"
                          >
                            Cancel
                          </button>
                          &nbsp;
                          <button type="submit" className="btn green-meadow">
                            <i className="fa fa-check"></i> Save
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="customer_info"
              data-backdrop="static"
              data-keyboard="false"
              tabIndex="-1"
              aria-hidden="true"
            >
              <div className="modal-dialog  modal-lg">
                <Customer />
              </div>
            </div>

            <div
              className="modal fade"
              id="shipper_info"
              data-backdrop="static"
              data-keyboard="false"
              tabIndex="-1"
              aria-hidden="true"
            >
              <div className="modal-dialog  modal-lg">
                <AddShipper />
              </div>
            </div>

            <div
              className="modal fade"
              id="carrier_info"
              data-backdrop="static"
              data-keyboard="false"
              tabIndex="-1"
              aria-hidden="true"
            >
              <div className="modal-dialog  modal-lg">
                <Addcarrier />
              </div>
            </div>

            <div
              className="modal fade"
              id="doc_pop"
              data-backdrop="static"
              data-keyboard="false"
              tabIndex="-1"
              aria-hidden="true"
            >
              <div className="modal-dialog  modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-hidden="true"
                      onClick={() => (this.refs.insertDocType.value = "1")}
                    ></button>
                    <h4 className="modal-title">Documents</h4>
                  </div>
                  <div className="modal-body">
                    <div className="form-group row">
                      <label className="col-md-3 control-label">
                        Documents Type:
                      </label>
                      <div className="col-md-8">
                        <select
                          className="form-control"
                          defaultValue="1"
                          ref="insertDocType"
                        >
                          <option value="1">Rate Sheet</option>
                          <option value="2">BOL (Bill of Loading)</option>
                          <option value="3">POD (Proof of Delivery)</option>
                          <option value="4">Lumper Receipt</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn default"
                      data-dismiss="modal"
                      onClick={() => (this.refs.insertDocType.value = "1")}
                    >
                      Cancel
                    </button>
                    &nbsp;
                    <button
                      type="submit"
                      className="btn green-meadow"
                      data-dismiss="modal"
                      onClick={(e) => {
                        e.preventDefault();

                        var docType = this.refs.insertDocType.value;
                        var label =
                          docType === "1"
                            ? "Rate Sheet"
                            : docType === "2"
                              ? "BOL (Bill of Loading)"
                              : docType === "3"
                                ? "POD (Proof of Delivery)"
                                : "Lumper Receipt";
                        var Documents = this.state.Documents;
                        var docNo = Documents.length + 1;

                        var obj = {
                          label: label,
                          docType: docType,
                          documentNo: docNo,
                          fileName: "",
                          error: "",
                          showIcons: true,
                          file: "",
                          s3FileKey: "",
                          checked: false,
                          documentId: "",
                          invoiceError: "",
                          fileExtension: "",
                        };

                        this.refs.insertDocType.value = "1";

                        Documents.push(obj);

                        this.setState({ Documents });
                      }}
                    >
                      <i className="fa fa-check"></i> Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(QuickAddLoad);
