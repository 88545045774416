/* eslint-disable */

import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Sidebar from './sidebar';
import Header from './Head';
import $ from 'jquery';
import BaseURL from './config';
import DatePicker from "react-datepicker";
import './date.css';
import TokenErrorMsg from './errorMsg';

import getEditinfo from './StartEditMode';
import finishEditinfo from './FinishEditMode';
import Select from 'react-virtualized-select';
import tenantUser from './TenantUserPermision';

const Cryptr = require('cryptr');
const cryptr = new Cryptr('@$#rgweR35*&YFSD)/');

class EditTrailer extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      status: 1,
      make: [],
      states: [],
      trailerid: '',
      whileSave: false,
      readonly: true,
      hidden: false,
      isLoading: false,
      token: '',
      fields: {},
      errors: {},
      carrier: [],
      carrierdata: '',
      LicExpDate: new Date(),
      file: {
        License: '',
      },
      Licenseload: false,
      TrailerFormData: { state: '' },
      Trailertype: '',
      role: 'Tenant',
      CarrierSelectedOption: '',
      CarrierOptions: []
    };
    this.handleChange = this.handleChange.bind(this);
    this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);
    this.submit = this.submit.bind(this);
    this.validateForm = this.validateForm.bind(this);
  }

  onUnload = (event) => {

    event.preventDefault();
    var http = new XMLHttpRequest();

    var url = BaseURL + 'editModeInfo/finishEditMode';

    var params = "trailerId=" + this.state.trailerid + "&token=" + this.state.token;

    http.open("POST", url, true);

    http.setRequestHeader("Content-type", "application/x-www-form-urlencoded");


    if (this.state.trailerid) {
      http.send(params);
      var start = new Date().getTime();
      while (new Date().getTime() < start + 350);
    }



  }



  async componentDidMount() {
    this._isMounted = true;
    window.addEventListener("beforeunload", this.onUnload)
    var token = localStorage.getItem('Token');
    var role = 'Tenant';
    const Role = localStorage.getItem('Role');
    if (Role) {
      role = cryptr.decrypt(Role);
    }
    if (this._isMounted) {

      this.setState({ 'token': token, role: role });
    }
    const id = this.props.match.params.id;


    var arr = {
      'carriers': [],
      'states': [],
      'make': [],
      'trailer': {
        'id': '',
        'licenseExpirationDate': null,
        'status': '',
        'trailerType': ''
      },
      'trailerDocuments': [],

    };

    await axios.post(BaseURL + 'trailer/findById', { 'token': token, 'id': id })
      .then(response => {
        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem('Token', '');
          this.props.history.push('/');
        }
        console.log(response.data);

        arr = response.data.data;

      }).catch(function (error) {
        NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);
        console.log(error);
      })

    var options = [];

    arr.carriers.forEach(element => {

      var obj = { value: element.id, label: element.carrierName }

      if (element.id === arr.trailer.carrierId) {
        arr.trailer.carrierId = obj;
      }

      options.push(obj)
    });

    if (this._isMounted) {
      this.setState({
        CarrierOptions: options,
        CarrierSelectedOption: arr.trailer.carrierId,
        TrailerFormData: arr.trailer,

      });
    }

    var data = '', License = '';
    arr.carriers.forEach(element => {
      if (element.id === arr.trailer.carrier.id) {
        data = {
          'address': element.address.address,
          'city': element.address.city,
          'state': element.address.state.state,

        }
      }
    });

    arr.trailerDocuments.forEach(element => {

      var filename = element.documentName;
      if (filename.length > 15) {
        filename = filename.substring(0, 15) + '....'

      }

      if (Number(element.documentType) === 1) {
        License = { 'name': filename };

      }

    });


    if (arr.trailer.status === 0) {
      $("#inactive").addClass('active');
    }
    else {
      $("#active").addClass('active');
    }



    if (this._isMounted) {

      this.setState({
        carrier: arr.carriers,
        make: arr.make,
        states: arr.states,
        trailerid: id,

        status: arr.trailer.status,
        LicExpDate: new Date(arr.trailer.licenseExpirationDate),
        file: {
          License: License,
        },
        Licenseload: true,
        carrierdata: data,
        Trailertype: arr.trailer.trailerType,


      });
    }

  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("beforeunload", this.onUnload);

    var config = {
      "trailerId": this.state.trailerid,
      "token": this.state.token
    }
    if (config.trailerId) {
      finishEditinfo(config, (res) => {
        console.log(res);

      }, (err) => {

        console.log(err)
      });
    }


  }



  async handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    if (this._isMounted) {

      await this.setState({
        fields
      });
    }
    this.validateForm();
  }

  submituserRegistrationForm(e) {
    e.preventDefault();
    var send = false;

    if (this.validateAndSubmit() && this.validateForm()) {

      send = true;

    }
    if (send) {

      this.submit();
    }

  }

  validateAndSubmit() {

    // let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;



    if (!this.refs.trailer.value || !this.refs.trailer.value.trim().length > 0) {
      formIsValid = false;
      errors["trailer"] = "*Please Enter Trailer #";
      $("[name='trailer']").focus();

    }


    else if (!this.state.CarrierSelectedOption.value) {
      formIsValid = false;
      errors["carrier"] = "*Please Select Carrier";
      $("[name='options']").focus();

    }

    else if (!this.refs.make.value) {
      formIsValid = false;
      errors["make"] = "*Please Select Make";
      $("[name='make']").focus();

    }

    else if (!this.refs.driverLicense.value || !this.refs.driverLicense.value.trim().length > 0) {
      formIsValid = false;
      errors["DriverLicense"] = "*Please Enter License No";
      $("[name='DriverLicense']").focus();

    }

    else if (!this.refs.province_id.value) {
      formIsValid = false;
      errors["state"] = "*Please Select State";
      $("[name='state']").focus();

    }
    else if (this.state.Trailertype === 'Reefer' && !this.refs.refeermake.value) {
      formIsValid = false;
      errors["refeermake"] = "*Please Select Make";
      $("[name='refeermake']").focus();

    }
    else if (this.state.Trailertype === 'Reefer' && !this.refs.refeeryear.value) {
      formIsValid = false;
      errors["refeeryear"] = "*Please Select Year";
      $("[name='refeeryear']").focus();

    }




    if (this._isMounted) {

      this.setState({
        errors: errors
      });
    }
    return formIsValid;
  }



  validateForm() {

    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;



    if (typeof fields["trailer"] !== "undefined" && fields["trailer"]) {
      if (!fields["trailer"].match(/^[a-zA-Z0-9#'".,-/ ]{1,200}$/)) {
        formIsValid = false;
        errors["trailer"] = "*Only Alphanumeric Allowed (Max Range: 200)";
      }
    }

    if (typeof fields["color"] !== "undefined" && fields["color"]) {
      if (!fields["color"].match(/^[a-zA-Z0-9#'".,-/ ]{1,200}$/)) {
        formIsValid = false;
        errors["color"] = "*Only Alphanumeric Allowed (Max Range: 200)";
      }
    }

    if (typeof fields["model"] !== "undefined" && fields["model"]) {
      if (!fields["model"].match(/^[a-zA-Z0-9#'".,-/ ]{1,200}$/)) {
        formIsValid = false;
        errors["model"] = "*Only Alphanumeric Allowed (Max Range: 200)";
      }
    }

    if (typeof fields["refeermodel"] !== "undefined" && fields["refeermodel"]) {
      if (!fields["refeermodel"].match(/^[a-zA-Z0-9#'".,-/ ]{1,200}$/)) {
        formIsValid = false;
        errors["refeermodel"] = "*Only Alphanumeric Allowed (Max Range: 200)";
      }
    }

    if (typeof fields["year"] !== "undefined" && fields["year"]) {
      if (!fields["year"].match(/^[a-zA-Z0-9#'".,-/ ]{1,200}$/)) {
        formIsValid = false;
        errors["year"] = "*Only Alphanumeric Allowed (Max Range: 200)";
      }
    }
    if (typeof fields["refeeryear"] !== "undefined" && fields["refeeryear"]) {
      if (!fields["refeeryear"].match(/^[a-zA-Z0-9#'".,-/ ]{1,200}$/)) {
        formIsValid = false;
        errors["refeeryear"] = "*Only Alphanumeric Allowed (Max Range: 200)";
      }
    }

    if (typeof fields["vin"] !== "undefined" && fields["vin"]) {
      if (!fields["vin"].match(/^[a-zA-Z0-9#'".,-/ ]{1,200}$/)) {
        formIsValid = false;
        errors["vin"] = "*Only Alphanumeric Allowed (Max Range: 200)";
      }
    }

    if (typeof fields["DriverLicense"] !== "undefined" && fields["DriverLicense"]) {
      if (!fields["DriverLicense"].match(/^[a-zA-Z0-9'-.#$ ]{1,30}$/)) {
        formIsValid = false;
        errors["DriverLicense"] = "*Please Enter Valid License  (Max Range: 30)";
      }
    }


    if (typeof this.state.LicExpDate !== "undefined" && this.state.LicExpDate) {
      if (this.state.LicExpDate.getFullYear() < 2000 || this.state.LicExpDate.getFullYear() > 2099) {
        formIsValid = false;
        errors["LicExpDate"] = "*Enter Valid Date ( Year Between 2000-2099 )";
      }
    }

    if (this._isMounted) {

      this.setState({
        errors: errors
      });
    }
    return formIsValid;


  }

  EditForm() {

    var div = document.getElementById('EditDiv');
    div.setAttribute("style", " cursor: not-allowed; pointer-events: none")
    var config = {
      "trailerId": this.state.trailerid,
      "token": this.state.token
    }

    getEditinfo(config, (res) => {
      console.log(res);
      if (res.data.status === 0) {
        if (this._isMounted) {

          this.setState({ readonly: false });
        }
      }
      else {
        div.removeAttribute("style")
        NotificationManager.warning(res.data.message, 'Warning Message', 3000);
      }
    }, (err) => {

      console.log(err)
    });

  }


  // async states()
  // {

  //   var arr;
  //   var data=false;

  //   await axios.post(BaseURL+'countries/states',{
  //     "countryId": this.refs.country_id.value,
  //     'token':this.state.token
  //   })
  //           .then(function (response) {

  //             arr = response.data.data;
  //             data=true;
  //           })
  //           this.refs.province_id.value=1;
  //           this.refs.bprovince_id.value=1;

  //           if(data){
  //           this.setState({states:arr});

  //           }

  // }



  async cancel() {
    if (window.confirm(' Are you sure you want to cancel ? '))
      this.props.history.push('/trailer');

  }


  async submit() {
    if (this._isMounted) {

      this.setState({ whileSave: true, hidden: false, readonly: true })
    }



    const trailerNo = this.refs.trailer.value.trim();
    const model = this.refs.model.value.trim();
    const year = this.refs.year.value.trim();
    const vin = this.refs.vin.value.trim();
    const color = this.refs.color.value.trim();
    const carrierid = this.state.CarrierSelectedOption.value;
    const makeid = this.refs.make.value;
    const trailertype = this.refs.trailertype.value.trim();
    const trailersize = this.refs.trailersize.value.trim();



    const province_id = this.refs.province_id.value;
    const driverLicense = this.refs.driverLicense.value.trim();

    var refeermakeid = this.refs.refeermake.value;


    const refeermodel = this.refs.refeermodel.value.trim();
    const refeeryear = this.refs.refeeryear.value.trim();
    if (!this.refs.refeermake.value) {
      refeermakeid = null;
    }

    var trailer = {
      'token': this.state.token,
      'id': this.state.trailerid,
      "trailerNo": trailerNo,
      "model": model,
      'trailerType': trailertype,
      'trailerSize': trailersize,
      'status': this.state.status,
      "year": year,
      "licenseExpirationDate": this.state.LicExpDate,
      "vinNo": vin,
      "color": color,
      "licensePlateNo": driverLicense,
      "licenseStateId": province_id,
      "makeId": makeid,
      "carrierId": carrierid,

      "reeferMake": refeermakeid,
      'reeferYear': refeeryear,
      'reeferModel': refeermodel,



    }

    if (this.state.trailerid) {


      await axios.post(BaseURL + 'trailer/update', trailer)
        .then(response => {
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem('Token', '');
            this.props.history.push('/');
          }
          else if (response.data.status === 1) {
            NotificationManager.error(response.data.message, 'Error', 3000);
            return;
          }
          else if (response.data.status === 0) {
            NotificationManager.error(response.data.message, 'Error', 3000);
            return;
          }
          console.log(response.data);
          NotificationManager.success('Trailer upated Successfully', 'Edited', 3000);
        }).catch(function (error) {
          NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);
          console.log(error);
        })

      if (this._isMounted) {

        this.setState({
          whileSave: false
        })
      }


    }


  }




  loadcarrierinfo(e) {


    if (e.value) {

      var id = e.value;
      var data;

      this.state.carrier.forEach(element => {
        if (element.id === Number(id)) {
          data = {
            'address': element.address.address,
            'city': element.address.city,
            'state': element.address.state.state,

          }
        }
      });

      if (data && this._isMounted) {
        this.setState({ carrierdata: data })
      }
    }
    else {
      if (this._isMounted) {
        this.setState({ carrierdata: '' })
      }
    }

  }

  onFileChange = async (event) => {

    let errors = this.state.errors;

    event.preventDefault();
    event.persist();
    var file = await event.target.files[0];

    function getFileExtension(filename) {
      return filename.split('.').pop();
    }

    if (event.target.files[0]) {

      var filename = { 'name': file.name };


      if (file.type !== 'application/pdf' && file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/gif' && file.type !== 'image/tif' && getFileExtension(file.name) !== 'docx' && getFileExtension(file.name) !== 'doc' && getFileExtension(file.name) !== 'odt' && getFileExtension(file.name) !== 'xls' && getFileExtension(file.name) !== 'xlsx' && getFileExtension(file.name) !== 'ppt' && getFileExtension(file.name) !== 'pptx') {
        errors[event.target.name] = 'Please Upload only PDF ,Images, Document files ';
        if (this._isMounted) {
          this.setState({ errors: errors })
        }
        return false;
      }
      else if (file.size > 1000000) {
        errors[event.target.name] = 'Please Upload A File Less Than 1MB ( Max size for file: 1MB ) ';
        if (this._isMounted) {
          this.setState({ errors: errors })
        }
        return false;
      }
      else {
        errors[event.target.name] = '';
        if (this._isMounted) {
          this.setState({ errors: errors })
        }
      }

      if (filename.name.length > 15) {
        filename = {
          'name': filename.name.substring(0, 15) + '....'
        }
      }
      console.log(file);

      var doctype = event.target.id;
      var id = event.target.id;
      console.log(id);




      if (Number(id) === 1) {
        if (this._isMounted) {

          this.setState({

            Licenseload: false

          });
        }

      }




      console.log('enter in upload file');
      let data = new FormData();
      await data.append('file', file);
      await data.append('fileName', file.name);
      await data.append("trailerId", this.state.trailerid);
      await data.append("type", file.type);
      await data.append("documentType", doctype);



      await axios.post(BaseURL + 'trailerDocuments/uploadFileToS3', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'token': this.state.token
        }
      })
        .then(response => {
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem('Token', '');
            this.props.history.push('/');
          }
          console.log(response.data)
          NotificationManager.success('Document Uploaded Successfully', 'Upload', 3000);

        }).catch(function (error) {
          NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);
          console.log(error);
        })

      if (Number(id) === 1) {
        if (this._isMounted) {

          await this.setState({
            Licenseload: true,
            file: {
              License: filename,
            }
          });
        }

      }



    }

  }

  deleteFile = (e) => {
    e.preventDefault();
    e.persist();
    var doctype = e.target.id;
    var id = e.target.id;
    console.log(id);



    if (window.confirm('Are You Sure To Remove Docment ?')) {


      console.log('enter in delete file');
      axios.post(BaseURL + 'trailerDocuments/deleteFile', {
        "trailerId": this.state.trailerid,
        "documentType": doctype,
        'token': this.state.token

      })
        .then(response => {
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem('Token', '');
            this.props.history.push('/');
          }
          NotificationManager.success('Document Deleted Successfully', 'Delete', 3000);
          console.log(response.data)

        }).catch(function (error) {
          NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);
          console.log(error);
        })

      if (Number(id) === 1) {
        if (this._isMounted) {

          this.setState({

            file: {
              License: '',

            }
          });
        }

      }



    }


  }


  download = async (e) => {

    e.preventDefault();
    e.persist();
    var doctype = await e.target.id;
    var id = await e.target.id;


    if (Number(id) === 1) {

      if (this._isMounted) {
        this.setState({

          Licenseload: false

        });
      }

    }



    console.log(doctype);

    console.log('enter in download file');

    await axios.post(BaseURL + 'trailerDocuments/getDocuments', {
      "trailerId": this.state.trailerid,
      'token': this.state.token
    })
      .then(response => {

        if (response.data.error === TokenErrorMsg) {
          console.log(response.data.error);
          localStorage.setItem('Token', '');
          this.props.history.push('/');
        }
        response.data.documents.forEach(element => {
          if (element.documentType === doctype) {
            console.log(element);
            window.open(element.documentUrl, '_blank')

          }

        });

      }).catch(function (error) {
        NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);
        console.log(error);
      })

    if (Number(id) === 1) {
      if (this._isMounted) {

        this.setState({

          Licenseload: true

        });
      }
    }



  }

  async setSelectOption(e) {

    e.preventDefault();
    var formData = this.state.TrailerFormData;
    var value = e.target.value;

    if (e.target.name === 'trailersize' && this._isMounted) {
      formData['trailerSize'] = value;
      this.setState({ TrailerFormData: formData });
    }
    else if (e.target.name === 'make' && this._isMounted) {
      formData['makeId'] = value;
      this.setState({ TrailerFormData: formData });
    }
    else if (e.target.name === 'year' && this._isMounted) {
      formData['year'] = value;
      this.setState({ TrailerFormData: formData });
    }
    else if (e.target.name === 'state' && this._isMounted) {
      formData.state.id = value;
      this.setState({ TrailerFormData: formData });
    }
    else if (e.target.name === 'refeermake' && this._isMounted) {
      formData['reeferMake'] = value;
      this.setState({ TrailerFormData: formData });
    }
    else if (e.target.name === 'refeeryear' && this._isMounted) {
      formData['reeferYear'] = value;
      this.setState({ TrailerFormData: formData });
    }
  }

  handleChangeCarrier = (selectedOption) => {

    if (this._isMounted) {

      var event = selectedOption ? selectedOption : '';
      console.log(selectedOption)
      this.setState({ CarrierSelectedOption: event });
      this.loadcarrierinfo(event);

    }

  }
  render() {

    const msgNone = {
      display: 'none'
    }
    const msgShow = {
      display: 'block',
    }

    const nonclick = {
      cursor: ' notAllowed',
      pointerEvents: 'none'
    }
    var year = [];
    for (var i = 1900; i < 2091; i++) {
      year.push(i.toString());
    }

    const { role } = this.state;

    return (
      <div className="page-container-bg-solid page-header-fixed page-sidebar-closed-hide-logo page-md">
        <Header title='| Trailer' />
        <div className="page-container">
          <Sidebar />
          <div className="page-content-wrapper">

            <div className="page-content">

              <div className="page-head">



              </div>



              <div className="row">
                <div className="col-md-12">

                  <div className="tabbable-line boxless  tabbable-reversed">

                    <ul className="nav nav-tabs"  >
                      <li className="active border-green-jungle"> <a href="#tab_0" data-toggle="tab"> Trailer </a> </li>
                      <li className="border-green-jungle"> <a href="#tab_1" data-toggle="tab" > Documents </a> </li>
                    </ul>
                    <div className="tab-content">
                      <div className="tab-pane active" id="tab_0">

                        <div className="portlet box yellow-gold">
                          <div className="portlet-title">
                            <div className="caption"> <i className="fa fa-file"></i>Update Trailer Information</div>
                            <div className="actions" style={this.state.readonly ? {} : nonclick} id='EditDiv'>
                              {role !== tenantUser ?
                                <div className="btn-group" >
                                  <button className="btn sbold white dark" onClick={this.EditForm.bind(this)} hidden={this.state.hidden} disabled={this.state.hidden}>
                                    <i className="fa fa-pencil"></i> Edit
                                  </button>
                                </div>
                                : null}
                            </div>
                          </div>
                          <div className="portlet-body form">

                            <form onSubmit={this.submituserRegistrationForm.bind(this)} className="horizontal-form" autoComplete="off" >
                              <div className="form-body max_width800">
                                <div className="portlet-title">
                                  <h2 className="h3"> Trailer Info
                                    <span style={{ float: 'right', paddingLeft: ' 8px', marginTop: '2px' }} >
                                      <div className="btn-group btn-group-devided" data-toggle="buttons" style={this.state.readonly ? nonclick : msgShow} >
                                        <label className="btn btn-transparent dark btn-outline btn-circle btn-sm " id='active' disabled={this.state.readonly} onClick={() => { if (this._isMounted) { this.setState({ status: 1 }) } }}>
                                          <input type="radio" name="options" className="toggle" id="option1" />
                                          Active</label>
                                        <label className="btn btn-transparent dark btn-outline btn-circle btn-sm" id='inactive' disabled={this.state.readonly} onClick={() => { if (this._isMounted) { this.setState({ status: 0 }) } }}>
                                          <input type="radio" name="options1" className="toggle" id="option2" />
                                          Inactive</label>
                                      </div>
                                    </span>
                                  </h2>

                                  <div className="clearfix"></div>
                                  <hr />
                                </div>

                                <div className="form-group row">
                                  <label className="col-md-3 control-label">Trailer #: <span className="font-red-thunderbird" >*</span></label>
                                  <div className="col-md-9">
                                    <input type="text" ref="trailer" onChange={this.handleChange} name="trailer" className="form-control" placeholder="" defaultValue={this.state.TrailerFormData.trailerNo} readOnly={this.state.readonly} />
                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.trailer}</div>
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="col-md-3 control-label">Carrier <span className="font-red-thunderbird" >*</span></label>
                                  <div className="col-md-9">
                                    <div className="form-group row">
                                      <div className="col-md-12">
                                        {/* <select className="form-control" ref='carrier' name='carrier' value={this.state.TrailerFormData.carrierId} onChange={this.loadcarrierinfo.bind(this)} disabled={this.state.readonly} >
                                 
                                                                   
                                              {
                                                this.state.carrier.map(function (data) {
                                                  // if(this.state.TrailerFormData.carrierId===data.id)
                                                  // return <option value={data.id} selected> {data.carrierName} </option>;
                                                  return <option value={data.id} key={data.id}> {data.carrierName} </option>;
                                                },this)

                                              }
                                </select> */}

                                        <Select
                                          // disabled={this.state.readonly}
                                          disabled
                                          value={this.state.CarrierSelectedOption}
                                          options={this.state.CarrierOptions}
                                          onChange={this.handleChangeCarrier}
                                          placeholder='Select Carrier'
                                        />
                                        <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.carrier}</div>
                                      </div>

                                    </div>
                                    <div className="row" style={this.state.carrierdata.address ? msgShow : msgNone}>
                                      <div className="col-md-12">
                                        <div className="card_info">
                                          <div className="row">
                                            <div className="col-md-12">

                                              <p>
                                                <i className="fa fa-map-marker" aria-hidden="true"></i>&nbsp;
                                                {this.state.carrierdata.address}</p>
                                            </div>
                                          </div>
                                          <div className="row">
                                            <div className="col-md-6">

                                              <p><i className="fa fa-building" aria-hidden="true"></i>&nbsp;
                                                {this.state.carrierdata.city}</p>
                                            </div>
                                            <div className="col-md-6">

                                              <p><i className="fa fa-globe" aria-hidden="true"></i> &nbsp;
                                                {this.state.carrierdata.state}</p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="col-md-3 control-label">Trailer Type:<span className="font-red-thunderbird" >*</span></label>
                                  <div className="col-md-9" disabled={this.state.readonly}>

                                    <select name="trailertype" ref="trailertype" className="form-control" value={this.state.Trailertype} onChange={() => { if (this._isMounted) { this.setState({ Trailertype: this.refs.trailertype.value }) } }} disabled={this.state.readonly} readOnly={this.state.readonly}>
                                      {/* <option value={this.state.TrailerFormData.trailerType} selected disabled hidden>{this.state.TrailerFormData.trailerType}</option>                               */}

                                      <option value="Dry Van"> Dry Van </option>
                                      <option value="Reefer"> Reefer </option>
                                    </select>
                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.trailertype}</div>

                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="col-md-3 control-label">Trailer Size:<span className="font-red-thunderbird" >*</span></label>
                                  <div className="col-md-9" disabled={this.state.readonly}>


                                    <select name="trailersize" ref="trailersize" className="form-control" onChange={this.setSelectOption.bind(this)} value={this.state.TrailerFormData.trailerSize} disabled={this.state.readonly} readOnly={this.state.readonly}>
                                      {/* <option value={this.state.TrailerFormData.trailerSize} selected disabled hidden>{this.state.TrailerFormData.trailerSize}</option>                               */}
                                      <option value="53'"> 53' </option>
                                      <option value="48'"> 48' </option>
                                    </select>
                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.trailersize}</div>

                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="col-md-3 control-label">Make: <span className="font-red-thunderbird" >*</span></label>
                                  <div className="col-md-9">
                                    <select name="make" ref="make" className="form-control" onChange={this.setSelectOption.bind(this)} value={this.state.TrailerFormData.makeId} disabled={this.state.readonly} readOnly={this.state.readonly}>

                                      {
                                        this.state.make.map(function (data) {
                                          // if(this.state.TrailerFormData.makeId===data.id)
                                          // return <option value={data.id} selected> {data.make} </option>;
                                          return <option value={data.id} key={data.id}> {data.make} </option>;
                                        }, this)

                                      }
                                    </select>
                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.make}</div>
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="col-md-3 control-label">Model: <span className="font-red-thunderbird" ></span></label>
                                  <div className="col-md-9">
                                    <input type="text" ref="model" onChange={this.handleChange} name="model" className="form-control" defaultValue={this.state.TrailerFormData.model} placeholder="" readOnly={this.state.readonly} />
                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.model}</div>
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="col-md-3 control-label">Year: <span className="font-red-thunderbird" >*</span></label>
                                  <div className="col-md-9">
                                    <select name="year" ref="year" className="form-control" onChange={this.setSelectOption.bind(this)} value={this.state.TrailerFormData.year} disabled={this.state.readonly} readOnly={this.state.readonly}>
                                      {
                                        year.map(function (data) {
                                          // if(this.state.TrailerFormData.year === data)
                                          // return <option value={data} selected> {data} </option>;
                                          return <option value={data} key={data}> {data} </option>;
                                        }, this)
                                      }
                                    </select>
                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.year}</div>
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="col-md-3 control-label">Vin #: <span className="font-red-thunderbird" ></span></label>
                                  <div className="col-md-9">
                                    <input type="text" ref="vin" onChange={this.handleChange} name="vin" className="form-control" defaultValue={this.state.TrailerFormData.vinNo} placeholder="" readOnly={this.state.readonly} />
                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.vin}</div>
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="col-md-3 control-label">Color: <span className="font-red-thunderbird" ></span></label>
                                  <div className="col-md-9">
                                    <input type="text" ref="color" onChange={this.handleChange} name="color" className="form-control" defaultValue={this.state.TrailerFormData.color} placeholder="" readOnly={this.state.readonly} />
                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.color}</div>
                                  </div>
                                </div>





                                <div className="portlet-title">
                                  <h2 className="h3 checkbox-inline" style={{ padding: '0', margin: '0' }}> License Plate Information </h2>
                                  <hr />
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">License  Plate No. <span className="font-red-thunderbird" >*</span></label>
                                  <div className="col-md-9">
                                    <input type="text" ref="driverLicense" onChange={this.handleChange} name="DriverLicense" className="form-control" defaultValue={this.state.TrailerFormData.licensePlateNo} placeholder="" readOnly={this.state.readonly} />
                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.DriverLicense}</div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">State <span className="font-red-thunderbird" >*</span></label>
                                  <div className="col-md-9">
                                    <select name="state" ref="province_id" className="form-control" onChange={this.setSelectOption.bind(this)} value={this.state.TrailerFormData.state.id} disabled={this.state.readonly} readOnly={this.state.readonly}>

                                      {
                                        this.state.states.map(function (data) {
                                          // if(this.state.TrailerFormData.state.id === data.id)
                                          // return <option value={data.id} selected> {data.state} </option>;
                                          return <option value={data.id} key={data.id}> {data.state} </option>;
                                        }, this)

                                      }
                                    </select>
                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.state}</div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3 control-label">Expiration Date</label>
                                  <div className="col-md-9">

                                    <DatePicker
                                      className='form-control'
                                      selected={this.state.LicExpDate}
                                      onChange={async (date) => {
                                        if (this._isMounted) {
                                          await this.setState({
                                            LicExpDate: date
                                          });
                                        }
                                        console.log(date);
                                        this.validateForm();
                                      }}
                                      readOnly={this.state.readonly} />
                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.LicExpDate}</div>

                                  </div>
                                </div>

                                <div hidden={this.state.Trailertype === "Reefer" ? false : true}>
                                  <div className="portlet-title">
                                    <h2 className="h4" style={{ fontWeight: '700' }}> Reefer Info</h2>
                                    <hr />
                                  </div>

                                  <div className="form-group row">
                                    <label className="col-md-3 control-label">Make: <span className="font-red-thunderbird" >*</span></label>
                                    <div className="col-md-9">
                                      <select name="refeermake" ref="refeermake" className="form-control" onChange={this.setSelectOption.bind(this)} value={this.state.TrailerFormData.reeferMake} disabled={this.state.readonly} readOnly={this.state.readonly}>

                                        {/* <option value={this.state.TrailerFormData.reeferMake} selected disabled hidden>{this.state.TrailerFormData.reeferMake}</option>   */}


                                        <option value='Carrier'>Carrier  </option>
                                        <option value='Thermo king'>Thermo king </option>
                                        <option value='Other'>Other  </option>
                                      </select>

                                      <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.refeermake}</div>

                                    </div>
                                  </div>

                                  <div className="form-group row">
                                    <label className="col-md-3 control-label">Model: <span className="font-red-thunderbird" ></span></label>
                                    <div className="col-md-9">
                                      <input type="text" ref="refeermodel" onChange={this.handleChange} name="refeermodel" defaultValue={this.state.TrailerFormData.reeferModel} className="form-control" placeholder="" readOnly={this.state.readonly} />
                                      <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.refeermodel}</div>
                                    </div>
                                  </div>

                                  <div className="form-group row">
                                    <label className="col-md-3 control-label">Year: <span className="font-red-thunderbird" >*</span></label>
                                    <div className="col-md-9">
                                      <select name="refeeryear" ref="refeeryear" className="form-control" onChange={this.setSelectOption.bind(this)} value={this.state.TrailerFormData.reeferYear} disabled={this.state.readonly} readOnly={this.state.readonly}>

                                        {
                                          year.map(function (data) {
                                            // if(this.state.TrailerFormData.reeferYear === data)
                                            // return <option value={data} selected> {data} </option>;
                                            return <option value={data} key={data}> {data} </option>;
                                          }, this)

                                        }
                                      </select>

                                      <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.refeeryear}</div>
                                    </div>
                                  </div>
                                </div>




                              </div>
                              <div className="clearfix"></div>
                              <div className="form-actions right">
                                <button onClick={this.cancel.bind(this)} type="button" className="btn default">Cancel</button> &nbsp;
                                {role !== tenantUser ?
                                  <button disabled={this.state.readonly} type="submit" className="btn green-meadow">
                                    {this.state.whileSave === false
                                      ? <i className='fa fa-save'></i>
                                      : <i className="fa fa-circle-o-notch fa-spin"></i>}
                                    Save
                                  </button>
                                  : null}
                              </div>

                            </form>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="tab_1">
                        <div className="portlet box yellow-gold">
                          <div className="portlet-title">
                            <div className="caption"> <i className="fa fa-file"></i> Documents </div>
                          </div>
                          <div className="portlet-body form">

                            <form action="#" className="horizontal-form" _lpchecked="1">
                              <div className="form-body max_width800">
                                <div className="form-group row">
                                  <label className="col-md-4 control-label"> Registration Document</label>
                                  <div className="col-md-8 fileinput ">


                                    <div className="input-group input-large doc_custom">
                                      <div className="form-control uneditable-input input-fixed input-medium" data-trigger="fileinput">
                                        <i className="fa fa-file fileinput-exists"></i>&nbsp;
                                        <span className="fileinput-filename">{this.state.file.License.name}</span>
                                      </div>
                                      {role !== tenantUser ?
                                        <span className="input-group-addon btn default btn-file">
                                          <span className="fileinput-new"> Upload file </span>
                                          <input type="file" id='1' name='doc' onChange={this.onFileChange} /> </span>
                                        : null}
                                      {
                                        this.state.Licenseload ?
                                          <button hidden={this.state.file.License.name ? false : true} className="input-group-addon btn green fileinput-exists" id='1' onClick={this.download}> <i id='1' style={{ color: '#fff' }} className="fa fa-eye"></i> </button> :
                                          <button className="input-group-addon btn green fileinput-exists" id='1' onClick={(e) => e.preventDefault()}> <i id='1' style={{ color: '#fff' }} className='fa fa-circle-o-notch fa-spin'></i> </button>
                                      }
                                      {role !== tenantUser ?
                                        <button hidden={this.state.file.License.name ? false : true} className="input-group-addon btn red fileinput-exists" id='1' onClick={this.deleteFile}> <i id='1' style={{ color: '#fff' }} className="fa fa-trash"></i> </button>
                                        : null}

                                    </div>
                                    <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.doc}</div>
                                  </div>
                                </div>



                              </div>
                              <div className="clearfix"></div>

                            </form>

                          </div>
                        </div>
                      </div>






                    </div>
                  </div>
                </div>





              </div>
            </div>

          </div>



        </div>

      </div>






    );
  }
}

export default withRouter(EditTrailer);