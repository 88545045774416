/* eslint-disable */

import React, { PureComponent, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import MUiTable from "./MuiTable";
import axios from "axios";
import Sidebar from "./sidebar";
import Header from "./Head";
import BaseURL from "./config";
import { NotificationManager } from "react-notifications";
import f from "./Loading_icon.gif";
import TokenErrorMsg from "./errorMsg";
import moment from "moment";
import DatePicker from "react-datepicker";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import currencyFormatter from "currency-formatter";
import { CSVLink } from 'react-csv';
import Select from "react-virtualized-select";
class BillingTab extends PureComponent {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      CustomerSelectedOption: null,
      CustomerOptions: [],
      customerOptionsData: {},
      readyForCsvDownload: false,
      readyCsvDownloadFile: false,
      advanceSearch: false,
      advanceSearchFormValues: {
        sfCustomerName: ""
      },
      showAdvanceFilter: false,
      readyForCsvDownload: false,
      readyCsvDownloadFile: false,
      csvReportData: {
        data: [],
        headers: [
          { label: 'Load #', key: 'load_number' },
          { label: 'Cairrier Name', key: 'carrier_name' },
          { label: 'Customer Load #', key: 'customer_load_number' },
          { label: 'Customer Name', key: 'customer_name' },
          { label: 'Pickup Date', key: 'pickup_date' },
          { label: 'Rate', key: 'rate' },
          { label: 'Dispatch Fee', key: 'dispatch_fee' },
          { label: 'Billing Amount', key: 'billing_amount' },
          { label: 'Billing status', key: 'billing_status' },
          { label: 'Invoice#', key:'invoice'},
          { label: 'Accounting Verified', key:'accounting_verified'}
        ],
        filename: 'AllBillingDetails.csv',
      },
      billingModal: false,
      data: [],
      open: false,
      status: "",
      isLoading: false,
      page: 0,
      rowsPerPage: 50,
      btnVal: "Show All",
      searchVal: false,
      token: "",
      offset: 0,
      limit: 50,
      order: "ASC",
      sortByColumn: "internalLoadNo",
      count: "",
      startDate: null,
      endDate: null,
      selectedId: null,
      search: ""
    };
    this.csvLinkEl = React.createRef();
    this.startEditing = this.startEditing.bind(this);
  }

  columns = [
    {
      name: "ID",
      options: {
        display: false
      }
    },

    {
      name: "internalLoadNo",
      label: "Load#",
      options: {
        sortDirection: "asc",
        sort: true
      }
    },
    {
      name: "carrierName",
      label: "Carrier",
      options: {
        sort: true
      }      
    },
    {
      name: "customerLoadNo",
      label: "Customer Load#",
      options: {
        sort: true
      }
    },
    {
      name: "customerName",
      label: "Customer",
      options: {
        sort: true
      }
    },
    {
      name: "scheduleFrom",
      label: "Pickup Date",
      options: {
        sort: true
      }
    },
    {
      name: "rate",
      label: "Rate",
      options: {
        sort: true
      }
    },
    {
      name: "dispatchPercentage",
      label: "Dispatch Fee",
      options: {
        sort: true
      }
    },
    {
      name: "billingAmount",
      label: "Billing Amount",
      options: {
        sort: true
      }
    },
    {
      name: "billingStatus",
      label: "Billing Status",
      options: {
        sort: true
      }
    },
    {
      name: "invoiceNo",
      label: "Invoice#",
      options: {
        sort: true
      }
    },
    {
      name: "billingVerified",
      label: "Billing Verified",
      options: {
        sort: true
      }
    },
    {
      name: "loadStatus",
      label: "Status",
      options: {
        // sort: true,
        display: false
      }
    }
  ];

  startDateHandler(startDate) {
    this.setState({ startDate });
  }
  endDateHandler(endDate) {
    this.setState({ endDate });
  }

  async componentDidMount() {
    const token = localStorage.getItem("Token");
    this._isMounted = true;

    if (this._isMounted) {
      await this.setState({ token: token });
      this.showall("Show All", this);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChangeCustomer = async (selectedOption) => {
    var event = selectedOption ? selectedOption.value : null;
    var selectedValue = selectedOption ? selectedOption.label : null;
    let setValue = this.state.advanceSearchFormValues;
    setValue.sfCustomerName = selectedValue;
    await this.setState({ advanceSearchFormValues: setValue });
    if (this._isMounted) {
      this.setState({ CustomerSelectedOption: event });
    }
  };

  async search() {
      var arr = [],
        count;
      let arrCsvAppend = []
      let newCsvArrayData = []

      await axios
        .post(BaseURL + "customerLoad/billing/findByName", {
          customerName: this.state.advanceSearchFormValues.sfCustomerName,
          advanceSearch: this.state.advanceSearch,
          searchKeyword: !this.state.advanceSearch ? this.refs.search.value.trim(): "",
          offset: this.state.offset,
          limit: this.state.limit,
          sortOrder: this.state.order,
          sortBy: this.state.sortByColumn,
          loadStatus: this.state.status,
          carrierId: this.props.carrierId,
          token: this.state.token,
          startDate: this.state.startDate === null
          ? null
          : moment(this.state.startDate).format("YYYY-MM-DD"),
          endDate: this.state.endDate === null
          ? null
          : moment(this.state.endDate).format("YYYY-MM-DD")
        })
        .then(response => {
          console.log(response);
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem("Token", "");
            this.props.history.push("/");
          }

          count = response.data.data.count;
          for (const key in response.data.data.rows) {
            if (response.data.data.rows.hasOwnProperty(key)) {
              const element = response.data.data.rows[key];
              var pickupDate = "";

              element.loadPickupAndDeliveryDetails.forEach(stop => {
                if (stop.stopType === "pickup") {
                  var date = moment(stop.scheduleFrom).format("MM/DD/YYYY");
                  
                  pickupDate = pickupDate ? pickupDate : date;
                }
              });


              element.dispatchPercentage = element.dispatchPercentage ? element.dispatchPercentage : 4;
              const dispatchFee = element.dispatchFee ?
                  currencyFormatter.format(element.dispatchFee, { code: "USD" })
                  :
                  currencyFormatter.format(element.dispatchPercentage, { code: "USD" }).substr(1) + '%';

              var Obj = [
                element.id,
                element.internalLoadNo,
                element.carrier ? element.carrier.carrierName : "",               
                element.customerLoadNo,
                element.customer ? element.customer.customerName : "",
                pickupDate,
                currencyFormatter.format(element.rate, { code: "USD" }),
                dispatchFee,
                currencyFormatter.format(element.billingAmount, {
                  code: "USD"
                }),
                // element.billingAmount,
                element.billingStatus,
                element.invoiceNo,
                element.billingVerified ? (
                  <p style={{ color: "#55BA47" }}>&#10004;</p>
                ) : (
                  <p style={{ color: "red" }}>&#10006;</p>
                ),
                element.loadStatus
              ];
              arr.push(Obj);
            }
          }
          for (const key in response.data.data.exportData) {
            if (response.data.data.exportData.hasOwnProperty(key)) {
              const element = response.data.data.exportData[key];
              var pickupDate = "";
  
              element.loadPickupAndDeliveryDetails.forEach(stop => {
                if (stop.stopType === "pickup") {
                  var date = moment(stop.scheduleFrom).format("MM/DD/YYYY");
  
                  pickupDate = pickupDate ? pickupDate : date;
                }
              });
  
  
              element.dispatchPercentage = element.dispatchPercentage ? element.dispatchPercentage : 4;
              const dispatchFee = element.dispatchFee ?
                currencyFormatter.format(element.dispatchFee, { code: "USD" })
                :
                currencyFormatter.format(element.dispatchPercentage, { code: "USD" }).substr(1) + '%';
  
              var Obj = [
                element.id,
                element.internalLoadNo,
                element.carrier ? element.carrier.carrierName : "",
                element.customerLoadNo,
                element.customer ? element.customer.customerName : "",
                pickupDate,
                currencyFormatter.format(element.rate, { code: "USD" }),
                dispatchFee,
                currencyFormatter.format(element.billingAmount, {
                  code: "USD"
                }),
                element.billingStatus,
                element.invoiceNo,
                element.accountingVerified,
                element.loadStatus,
                element.accountingVerified
              ];
              arrCsvAppend.push(Obj);
            }
          }
          arrCsvAppend.forEach((ee) => {
            newCsvArrayData.push(
              {
                "load_number": ee[1],
                "carrier_name": ee[2],
                "customer_load_number": ee[3],
                "customer_name": ee[4],
                "pickup_date": ee[5],
                "rate": ee[6],
                "dispatch_fee": ee[7],
                "billing_amount": ee[8],
                "billing_status": ee[9],
                "invoice": ee[10],
                "accounting_verified": ee[13]
              }
            )
          })
          let csvData = this.state.csvReportData;
          csvData.data = newCsvArrayData;
          this.setState({ csvData, readyCsvDownloadFile: true })
        })
        .catch(function(error) {
          NotificationManager.error(
            "Something Went Wrong Server Is Not Responding ",
            "Error Message",
            3000
          );
          console.log(error);
        });

      if (this._isMounted) {
        this.setState({ data: arr, isLoading: false, count });
      }
    
  }

  async showall(theme, e) {
    if (this._isMounted) {
      // this.refs.search.value = "";

      await this.setState({
        searchVal: false,
        isLoading: true,
        status: "",
        open: false,
        anchorEl: null,
        btnVal: theme,
        page: 0,
        offset: 0,
        order: "ASC",
        sortByColumn: "internalLoadNo",
      });
      let startDate = this.state.startDate;
      let endDate = this.state.endDate;
      let search = this.state.search;
      let advanceSearch = this.state.advanceSearch

      startDate || endDate || search || advanceSearch ? this.search() : this.fetchPaginationData();
    }
  }

  startEditing(colData, cellMeta) {
    if (window.getSelection().toString()) {
      return false;
    } else if (cellMeta.colIndex === 1) {
      var data = this.state.data;
      var id = data[cellMeta.dataIndex][0];
      // console.log('Cell Data ',colData)
      // console.log('Cell meta ',cellMeta,'Cell meta Data',data[cellMeta.dataIndex])
      // console.log('Cell ID ',id)
      this.props.history.push("/EditLoad/" + id + "/billing");

    }
  }

  handleSearch = async (e) => {
      if (this._isMounted) {
        this.setState({ searchVal: true, search: e.target.value});
      }
  };

  handleKeyPress = e => {
    if (event.key === "Enter") {
      this.SetSearchParameters();
    }
  };

  SetSearchParameters = async () => {
    if (this._isMounted && (this.refs.search.value.trim() || this.state.startDate || this.state.endDate || this.state.advanceSearch)) {
      await this.setState({
        isLoading: true,
        page: 0,
        offset: 0,
        order: "ASC",
        sortByColumn: "internalLoadNo",
        searchVal: true        
      });

      this.search();
    } else {
      alert("Search/Date Input is required");
    }
  };

  handleToggle = event => {
    if (this._isMounted) {
      var target = event.currentTarget;
      this.setState(state => ({ open: !state.open, anchorEl: target }));
    }
  };

  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }
    if (this._isMounted) {
      this.setState({ open: false });
    }
  };

  async handleChangeDropDown(theme, e) {
    if (this._isMounted) {
      // this.refs.search.value = "";

      await this.setState({
        searchVal: false,
        isLoading: true,
        status: theme,
        open: false,
        anchorEl: null,
        btnVal: theme,
        page: 0,
        offset: 0,
        order: "ASC",
        sortByColumn: "internalLoadNo",
      });
      let startDate = this.state.startDate;
      let endDate = this.state.endDate;
      let search = this.state.search
      let advanceSearch = this.state.advanceSearch

      startDate || endDate || search || advanceSearch ? this.search() : this.fetchPaginationData();
    }
  }

  onTableChange = async (action, tableState) => {
    console.log("action", action);
    console.log("state", tableState);

    switch (action) {
      case "changePage":
        this.changePage(tableState);
        break;

      case "changeRowsPerPage":
        this.changeRowsPerPage(tableState);
        break;

      case "sort":
        this.sort(tableState);
        break;

      default:
        break;
    }
  };

  changePage = async tableState => {
    var offset = this.state.offset;

    if (tableState.page > this.state.page) {
      // Next Icon Clicked >
      var tempOffset = offset + tableState.rowsPerPage;
      if (this._isMounted) {
        await this.setState({
          offset: tempOffset, //50
          limit: tableState.rowsPerPage // 76
        });

        (await this.state.searchVal || this.state.search || this.state.advanceSearch)
          ? this.search()
          : this.fetchPaginationData();
        this.setState({
          page: tableState.page
        });
      }
    } else {
      var tempLimit = tableState.rowsPerPage;
      var tempOffset = offset - tempLimit;
      if (tempOffset < 0) {
        tempOffset = 0;
      }

      if (this._isMounted) {
        await this.setState({
          limit: tempLimit,
          offset: tempOffset
        });

        (await this.state.searchVal || this.state.search || this.state.advanceSearch)
          ? this.search()
          : this.fetchPaginationData();

        this.setState({
          page: tableState.page
        });
      }
    }
  };

  changeRowsPerPage = async tableState => {
    if (this._isMounted) {
      await this.setState({
        limit: tableState.rowsPerPage, // 100
        offset: 0,
        rowsPerPage: tableState.rowsPerPage,
        page: 0
      });
      this.state.searchVal || this.state.search || this.state.advanceSearch ? this.search() : this.fetchPaginationData();
    }
  };

  sort = async tableState => {
    var sortByColumn = this.state.sortByColumn;
    var order = this.state.order;

    var sortOrder =
      tableState["columns"][tableState.activeColumn].name === sortByColumn
        ? order === "ASC"
          ? "DESC"
          : "ASC"
        : "ASC";

    if (this._isMounted) {
      await this.setState({
        order: sortOrder,
        sortByColumn: tableState["columns"][tableState.activeColumn].name
      });

      this.state.searchVal ? this.search() : this.fetchPaginationData();
    }
  };

  fetchPaginationData = () => {
    //console.log('TableState Data',state.data)
    var postData = {
      token: this.state.token,
      loadStatus: this.state.status,
      offset: this.state.offset,
      limit: this.state.limit,
      sortOrder: this.state.order,
      sortBy: this.state.sortByColumn,
      carrierId: this.props.carrierId,
      // startDate:
      //   this.state.startDate === null
      //     ? null
      //     : moment(this.state.startDate).format("YYYY-MM-DD"),
      // endDate:
      //   this.state.endDate === null
      //     ? null
      //     : moment(this.state.endDate).format("YYYY-MM-DD")
    };

    var arr = [];

    var status, count;
    axios
      .post(BaseURL + "customerLoad/billing", postData, {
        headers: { "Content-Type": "application/json" }
      })
      .then(response => {
        console.log("billing...", response.data);
        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem("Token", "");
          this.props.history.push("/");
        }

        count = response.data.data.count;

        for (const key in response.data.data.rows) {
          if (response.data.data.rows.hasOwnProperty(key)) {
            const element = response.data.data.rows[key];
            var pickupDate = "";

            element.loadPickupAndDeliveryDetails.forEach(stop => {
              if (stop.stopType === "pickup") {
                var date = moment(stop.scheduleFrom).format("MM/DD/YYYY");
               
                pickupDate = pickupDate ? pickupDate : date;
              }
            });

            element.dispatchPercentage = element.dispatchPercentage ? element.dispatchPercentage : 4;
            const dispatchFee = element.dispatchFee ?
                currencyFormatter.format(element.dispatchFee, { code: "USD" })
                :
                currencyFormatter.format(element.dispatchPercentage, { code: "USD" }).substr(1) + '%';

            var Obj = [
              element.id,
              element.internalLoadNo,
              element.carrier ? element.carrier.carrierName : "",   
              element.customerLoadNo,
              element.customer ? element.customer.customerName : "",
              pickupDate,
              currencyFormatter.format(element.rate, { code: "USD" }),
              dispatchFee,
              currencyFormatter.format(element.billingAmount, { code: "USD" }),
              //   element.billingAmount,
              element.billingStatus,
              element.invoiceNo,
              element.billingVerified ? (
                <p style={{ color: "#55BA47" }}>&#10004;</p>
              ) : (
                <p style={{ color: "red" }}>&#10006;</p>
              ),
              element.loadStatus
            ];
            arr.push(Obj);
          }
        }

        if (this._isMounted) {
          this.setState({ data: arr, isLoading: false, count });
        }

        // console.log(arr);
      })
      .catch(function(error) {
        NotificationManager.error(
          "Something Went Wrong Server Is Not Responding ",
          "Error Message",
          3000
        );
        console.log(error);
      });
  };

  filterHandler = async () => {
    const value = this.state.showAdvanceFilter;
    await this.setState({ 
      advanceSearch: true, 
      showAdvanceFilter: !value
    });
  }

  advanceFilterHandler = (e) => {
    e.preventDefault()
     this.setState({
       isLoading: true,
       page: 0,
       offset: 0,
       order: "ASC",
       sortByColumn: "internalLoadNo",
       searchVal: true,
     });
     this.search();
  }

  advanceFilterClear = (e) => {
    e.preventDefault();
    this.setState({
      startDate: null,
      endDate: null,
      search: '',
      readyCsvDownloadFile: false,
      advanceSearch: false,
      advanceSearchFormValues: {
        sfCustomerName: ""
      },
      CustomerSelectedOption: null,
      CarrierSelectedOption: null,
      searchVal: false
    });
    this.showall("Show All", e);
    this.setState({ showAdvanceFilter: false });
  }

  render() {
    const {
      isLoading,
      data,
      rowsPerPage,
      open,
      searchVal,
      count,
      page,
      showAdvanceFilter,
      readyCsvDownloadFile,
      csvReportData
    } = this.state;

    const options = {
      responsive: "scroll",
      selectableRows: false,
      rowHover: true,
      print: false,
      download: false,
      filter: false,
      search: false,
      rowsPerPageOptions: [25, 50, 100],
      rowsPerPage: rowsPerPage,
      viewColumns: false,
      textLabels: {
        body: {
          toolTip: ""
        }
      },
      onCellClick: this.startEditing,

      serverSide: true,
      count: count,
      page: page,
      onTableChange: this.onTableChange
    };

    const crossN = {
      display: "none"
    };
    const crossS = {
      display: "block"
    };

    return (
      <Fragment>       
        <div className="portlet box yellow-gold">
            <div className="portlet-title">
            <div className="caption">
                <i className="fa fa-dollar"></i>
                <span className="caption-subject">
                Billing Details
                </span>
            </div>
            <div className="actions">
              <div className="btn-group">
                {readyCsvDownloadFile ? 
                  (<>
                    <CSVLink id="777"
                      ref={this.csvLinkEl}
                      style={{ marginRight: "5px" }}
                      className="btn sbold white dark"
                      {...csvReportData}>
                      Download Csv
                      <i className="fa fa-download downloadCsv"></i>
                    </CSVLink>
                  </>) : null
                }
              </div>
            </div>
            </div>
            <div className="portlet-body">
            <div className="table-toolbar">
                <div
                style={{
                    textAlign: "right",
                    paddingRight: " 10px",
                    paddingBottom: "8px"
                }}
                >
                <span
                    style={{
                    float: "left",
                    paddingLeft: "8px",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#fff"
                    }}
                >
                    <div className="btnShowall">
                    <Button
                        buttonRef={node => {
                        this.anchorEl = node;
                        }}
                        aria-owns={
                        open ? "menu-list-grow" : undefined
                        }
                        aria-haspopup="true"
                        onClick={this.handleToggle}
                    >
                        {this.state.btnVal} &nbsp;&nbsp;
                        <span className="fa fa-chevron-down"></span>
                    </Button>
                    <Popper
                        open={open}
                        anchorEl={this.anchorEl}
                        transition
                        disablePortal
                    >
                        {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            id="menu-list-grow"
                            style={{
                            transformOrigin:
                                placement === "bottom"
                                ? "center top"
                                : "center bottom"
                            }}
                        >
                            <Paper>
                            <ClickAwayListener
                                onClickAway={this.handleClose}
                            >
                                <MenuList>
                                <MenuItem
                                    style={{
                                    paddingTop: "6px",
                                    paddingBottom: "6px"
                                    }}
                                    onClick={e =>
                                    this.showall("Show All", e)
                                    }
                                >
                                    Show All
                                </MenuItem>
                                <MenuItem
                                    style={{
                                    paddingTop: "6px",
                                    paddingBottom: "6px"
                                    }}
                                    onClick={e =>
                                    this.handleChangeDropDown(
                                        "Pending",
                                        e
                                    )
                                    }
                                >
                                    Pending
                                </MenuItem>
                                <MenuItem
                                    style={{
                                    paddingTop: "6px",
                                    paddingBottom: "6px"
                                    }}
                                    onClick={e =>
                                    this.handleChangeDropDown(
                                        "Dispatched",
                                        e
                                    )
                                    }
                                >
                                    Dispatched
                                </MenuItem>
                                <MenuItem
                                    style={{
                                    paddingTop: "6px",
                                    paddingBottom: "6px"
                                    }}
                                    onClick={e =>
                                    this.handleChangeDropDown(
                                        "In-Transit",
                                        e
                                    )
                                    }
                                >
                                    In-Transit
                                </MenuItem>
                                <MenuItem
                                    style={{
                                    paddingTop: "6px",
                                    paddingBottom: "6px"
                                    }}
                                    onClick={e =>
                                    this.handleChangeDropDown(
                                        "Delivered",
                                        e
                                    )
                                    }
                                >
                                    Delivered
                                </MenuItem>
                                <MenuItem
                                    style={{
                                    paddingTop: "6px",
                                    paddingBottom: "6px"
                                    }}
                                    onClick={e =>
                                    this.handleChangeDropDown(
                                        "Completed",
                                        e
                                    )
                                    }
                                >
                                    Completed
                                </MenuItem>
                                <MenuItem
                                    style={{
                                    paddingTop: "6px",
                                    paddingBottom: "6px"
                                    }}
                                    onClick={e =>
                                    this.handleChangeDropDown(
                                        "Invoiced",
                                        e
                                    )
                                    }
                                >
                                    Invoiced
                                </MenuItem>
                                <MenuItem
                                    style={{
                                    paddingTop: "6px",
                                    paddingBottom: "6px"
                                    }}
                                    onClick={e =>
                                    this.handleChangeDropDown(
                                        "Closed",
                                        e
                                    )
                                    }
                                >
                                    Closed
                                </MenuItem>
                                <MenuItem
                                  style={{
                                    paddingTop: "6px",
                                    paddingBottom: "6px"
                                  }}
                                  onClick={e =>
                                    this.handleChangeDropDown(
                                      "Cancel",
                                      e
                                    )
                                  }
                                >
                                  Cancel
                                </MenuItem>   
                                </MenuList>
                            </ClickAwayListener>
                            </Paper>
                        </Grow>
                        )}
                    </Popper>
                    </div>
                    <div
                    style={{ marginLeft: "20px", width: "140px" }}
                    >
                    <DatePicker
                        // disabled={this.state.readonly}
                        className="form-control"
                        selected={this.state.startDate}
                        dateFormat="yyyy-MM-dd"
                        placeholderText="Start Date"
                        onChange={date => this.startDateHandler(date)}
                        isClearable
                    />
                    </div>
                    <div
                    className="text-center"
                    style={{ margin: "0 10px" }}
                    >
                    TO
                    </div>
                    <div style={{ width: "140px" }}>
                    <DatePicker
                        // disabled={this.state.readonly}
                        className="form-control"
                        selected={this.state.endDate}
                        dateFormat="yyyy-MM-dd"
                        placeholderText="End Date"
                        onChange={date => this.endDateHandler(date)}
                        isClearable
                    />
                    </div>
                </span>

                {!showAdvanceFilter ? <div style={{ marginRight: "0px" }}>
                    <div
                    className="searchField form-control"
                    >
                    <input
                        id="search-field"
                        ref="search"
                        name="searchinput"
                        type="text"
                        placeholder="Search..."
                        value={this.state.search}
                        onChange={this.handleSearch}
                        onKeyPress={this.handleKeyPress}
                    />                  
                    <i className="fa fa-chevron-down"
                    style={{ position: "absolute", right: "10px", top: "8px", cursor: "pointer" }}
                    onClick={this.filterHandler}/>  
                    </div>
                    <input
                    name="searchbutton"
                    style={{ marginRight: "10px" }}
                    className="btn green-meadow"
                    type="submit"
                    value="SEARCH"
                    onClick={this.SetSearchParameters}
                    />
                    <button
                    // style={{marginRight: "10px"}}
                    className="btn" 
                    type="button" 
                    onClick = {
                      async (e) => {
                        await this.setState({
                          startDate: null,
                          endDate: null,
                          readyCsvDownloadFile: false,
                          search: '',
                          searchVal: false
                        });
                        this.showall("Show All", e);
                      }
                    }
                    >
                      Clear
                    </button>
                </div> : <div   style={{
                  textAlign: "right",
                  paddingRight: " 10px",
                  paddingBottom: "8px"
                }}
                >
                  <span                  
                   style={{
                    float: "left",
                    paddingLeft: "8px",
                    alignItems: "center",
                    backgroundColor: "#fff",
                    width: "100%"
                  }}>
                  <div className="text-left">
                      <div className="col-12 col-md-6">
                        <label>Customer Name</label>
                        <Select
                          className="customeLeft"
                          optionHeight={50}
                          disabled={this.state.readonly}
                          value={
                            this.state.CustomerSelectedOption
                            // this.state.advanceSearchFormValues.sfCustomerName
                          }
                          options={this.state.CustomerOptions}
                          onChange={this.handleChangeCustomer}
                          onInputChange={(input) => {
                            if (input.trim().length > 1) {
                              axios
                                .post(
                                  BaseURL +
                                  "customer/findByName",
                                  {
                                    status: 2,
                                    searchKeyword: input,
                                    token: this.state.token,
                                  }
                                )
                                .then((response) => {
                                  if (
                                    response.data.error ===
                                    TokenErrorMsg
                                  ) {
                                    localStorage.setItem(
                                      "Token",
                                      ""
                                    );
                                    this.props.history.push(
                                      "/"
                                    );
                                  }

                                  var arr = [];

                                  response.data.data.rows.forEach(
                                    (element) => {
                                      var obj = {
                                        value: element.id,
                                        label: element.customerName
                                      };
                                      arr.push(obj);
                                    }
                                  );

                                  this.setState({
                                    CustomerOptions: arr,
                                    customerOptionsData:
                                      response.data.data.rows,
                                  });
                                })
                                .catch(function (error) {
                                  NotificationManager.error(
                                    "Something Went Wrong Server Is Not Responding ",
                                    "Error Message",
                                    3000
                                  );
                                  console.log(error);
                                });
                            }
                          }}
                          placeholder="Search and Choose "
                        />
                      </div>
                    </div>
                    <div className="filterSerchDiv">
                      <button 
                      
                      style={{ marginRight: "5px" }} 
                      className="btn green-meadow filterSerchBtn" 
                      disabled={!this.state.advanceSearchFormValues.sfCustomerName} 
                      onClick={this.advanceFilterHandler}>
                        Search
                      </button>
                      <button className="btn" onClick={this.advanceFilterClear}>Clear</button>
                    </div>
                  </span>
                  </div> }
                </div>
            </div>
            {isLoading ? (
                <p style={{ textAlign: "center" }}>
                <img src={f} alt="Loader" />
                </p>
            ) : (
                <MUiTable
                data={data}
                columns={this.columns}
                options={options}
                />
            )}
            </div>
        </div>                 
      </Fragment>
    );
  }
}

export default withRouter(BillingTab);
