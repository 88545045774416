/* eslint-disable */ 

import React, { Component } from 'react';
import { Link ,withRouter } from "react-router-dom";
import MUiTable from './MuiTable'
import axios from 'axios';
import Sidebar from './sidebar';
import Header from'./Head';
import BaseURL from './config';
import { NotificationManager} from 'react-notifications';
import f from './Loading_icon.gif';
import TokenErrorMsg from './errorMsg';

import tenantUser from './TenantUserPermision';

import { getPersistentTableState, setPersistentTableState } from './LocalStorage';
const Cryptr = require('cryptr');
const cryptr = new Cryptr('@$#rgweR35*&YFSD)/');




class InsuranceAgent extends Component {

  _isMounted=false;
  constructor(props){
    super(props);
    this.state = {
      data: [],
      open: false,      
      status:2,      
      isLoading: false,      
      page: 0,
      rowsPerPage: 50,
      btnVal: "Show All",
      searchVal: false,
      token:'',
      role:'Tenant',
      offset: 0,
      limit: 50,
      order:'ASC',
      sortByColumn:'companyName',
      count:'',
      search: ''
    };
    this.startEditing=this.startEditing.bind(this);
}

columns = [
  {name:"ID", 
  options: {  
 display:false,
}},
 {name:"companyName",
 label: "Company Name",options: {  
 sort: true,    
}}, 
{name:"address",
label: "Address",options: {   
 sort: true,
}},
{name:"telephone",
label: "Telephone",options: {   
 sort: true,
}}];

async componentDidMount() {


  const token=localStorage.getItem('Token');
   this._isMounted=true;

   var role='Tenant';
  const Role = localStorage.getItem('Role');
  if(Role){
    role = cryptr.decrypt(Role);    
   }

   const tableState = getPersistentTableState('insuranceAgent');

   this.columns.find(col => col.name === tableState.sortByColumn)
   .options
   .sortDirection = tableState.order.toLowerCase();

   if (this._isMounted) {
     await this.setState({
       token: token,
       order: tableState.order,
       sortByColumn: tableState.sortByColumn,
       search: tableState.search,
       isLoading: true,
       searchVal: tableState.search ? true : false,
       page: tableState.page,
       offset: tableState.offset,
       limit: tableState.limit,
       rowsPerPage: tableState.limit,
       role: role
     });
     tableState.search ? this.search() : this.fetchPaginationData();
   }
 
        
}

componentWillUnmount(){
  this._isMounted=false
}


async search(){
  
  if(this.refs.search.value.trim()){


  
    var arr=[],count;
    this.setLocalStorage();

    await axios.post(BaseURL+'insuranceAgent/findByName',{
      "searchKeyword": this.refs.search.value.trim(),
      "offset": this.state.offset,
      "limit": this.state.limit,
      "sortOrder": this.state.order,
      "sortBy": this.state.sortByColumn,
      "status":this.state.status,
      'token':this.state.token
    })
            .then(response=> {  
              console.log(response)         
              if(response.data.error=== TokenErrorMsg){     
               localStorage.setItem('Token','') ;      
               this.props.history.push('/') ;       
                   }
              
               count =response.data.data.count;
  
              for (const key in response.data.data.rows) {
               if (response.data.data.rows.hasOwnProperty(key)) {
                 const element = response.data.data.rows[key];
                
                 var Obj=[
                  element.id,
                  element.companyName,
                
                  element.address.address+', '+(element.address.addressLineTwo ?element.address.addressLineTwo+', ':'')+element.address.city+', '+element.address.state.state+ ', '+element.address.zip ,    
                        
                  element.address.telephone,
         
              
                 ]
                 
                 arr.push(Obj);
                 
               }
             }
            }).catch(function (error) {
              NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);         
              console.log(error);
            })
            
            if(this._isMounted){
            this.setState({data:arr,isLoading:false,count});
            }

  }
  else{
    alert('Please Type Something in Search Bar')
  }
 
  
 


}

async showall(theme, e){

  this.refs.search.value='';
  if(this._isMounted){

    await  this.setState({
        searchVal: false,
        isLoading: true,
        status:2,
        open: false,
        anchorEl: null,
        btnVal: theme,
        page:0,
        offset: 0,
        order:'ASC',
        sortByColumn:'companyName',
        search: '',        

      })
      this.fetchPaginationData();
     
    }
  
  
}


  startEditing (colData, cellMeta){

    if(window.getSelection().toString()){
      return false;
    }
    
    else if(cellMeta.colIndex === 1)
    {
    var data=this.state.data;
    var id =data[cellMeta.dataIndex][0]
    // console.log('Cell meta ',colData,cellMeta,data[cellMeta.dataIndex])
    this.props.history.push('/EditAgent/'+id); 
    }
  
  }

  handleSearch = async (e) => {

    if (this.refs.search.value) {
      if(this._isMounted){
      this.setState({ searchVal: true, search: e.target.value });
      }
    }
    else{
      this.showall("Show All", this);
    }
     

  }

  handleKeyPress = e => {
    if (event.key === 'Enter') {
      this.SetSearchParameters()
    }
  };
  SetSearchParameters=async ()=>{

    if(this._isMounted && this.refs.search.value.trim()){
     
      await this.setState({
        
         isLoading: true,
         page:0,
         offset: 0,
         order:'ASC',
         sortByColumn:'companyName',  
       })
  
       this.search();
     }
     else{
      alert('Please Type Something in Search Bar')    
     }
  }
  handleToggle = event => {

    if(this._isMounted){
    this.setState(state => ({ open: !state.open, anchorEl: event.currentTarget }));
    }
  
  };
  
  handleClose = event => {
  
    if (this.anchorEl.contains(event.target)) {
      return;
    }
    if(this._isMounted){
    this.setState({ open: false });
    }
  
  };

 
  async active(theme, e){ 
   

    if(this._isMounted){
     this.refs.search.value='';

     await  this.setState({
        searchVal: false,
         isLoading: true,
         status:1,
         open: false,
         anchorEl: null,
         btnVal: theme,
         page:0,
         offset: 0,
         order:'ASC',
         sortByColumn:'companyName',  
         search: '',

       })
       this.fetchPaginationData();
      
     }

}

async Inactive(theme, e){

 
    if(this._isMounted){
     this.refs.search.value='';
     await  this.setState({
         searchVal: false,
         isLoading: true,
         status:0,
         open: false,
         anchorEl: null,
         btnVal: theme,
         page:0,
         offset: 0,
         order:'ASC',
         sortByColumn:'companyName',  
         search: '',

       })
       this.fetchPaginationData();
      
     }
      
       

}


onTableChange= async (action, tableState) => {

console.log('action',action);
console.log('state',tableState)


switch (action) {

 case 'changePage':

   this.changePage(tableState);
   break;


 case 'changeRowsPerPage':

   this.changeRowsPerPage(tableState);
   break;

 case 'sort':
 
   this.sort(tableState);
   break;

 default:
   break;
 
}
}


changePage=async (tableState)=>{

var offset=this.state.offset;


 if(tableState.page > this.state.page) // Next Icon Clicked >
 { 
   
   var tempOffset = offset+tableState.rowsPerPage
   if(this._isMounted){

   await this.setState({
     offset: tempOffset, //50
     limit: tableState.rowsPerPage, // 76
     page: tableState.page
    
   
   });

   await  this.state.searchVal ? this.search() :this.fetchPaginationData();

 }
 }
 else{
   var tempLimit = tableState.rowsPerPage;
   var tempOffset = offset-tempLimit;
   if(tempOffset < 0){
     tempOffset = 0
   }

if(this._isMounted){

   await this.setState({
     limit: tempLimit,
     offset: tempOffset, 
     page: tableState.page
   
   });


     await   this.state.searchVal ? this.search() :this.fetchPaginationData();

}
   
 
  

 }

}


changeRowsPerPage=async (tableState)=>{

if(this._isMounted){
   await this.setState({
     limit:tableState.rowsPerPage, // 100
     offset: 0,
     rowsPerPage: tableState.rowsPerPage,
     page: 0     ,
   
   });
   this.state.searchVal ?this.search() :this.fetchPaginationData();

 }

}


sort=async (tableState)=>{

var sortByColumn=this.state.sortByColumn;
var order=this.state.order;

var sortOrder = tableState['columns'][tableState.activeColumn].name ===  sortByColumn ? (order==='ASC' ? 'DESC' : 'ASC') : 'ASC' ;


if(this._isMounted){

await this.setState({
 order:sortOrder,
 sortByColumn:tableState['columns'][tableState.activeColumn].name    

});  


this.state.searchVal ?this.search() :this.fetchPaginationData();

}

}

fetchPaginationData=()=>{


//console.log('TableState Data',state.data)
var postData= {
'token':this.state.token,
'status':this.state.status,
"offset": this.state.offset,
"limit": this.state.limit,
"sortOrder": this.state.order,
"sortBy": this.state.sortByColumn,
};

this.setLocalStorage();


var arr=[];
//console.log('state',arr)
var count;
axios.post(BaseURL+'insuranceAgent/',postData
,
{ 
 headers: { 'Content-Type': 'application/json' }
}
).then(response=> {   
         console.log(response.data.data)
         if(response.data.error=== TokenErrorMsg){     
          localStorage.setItem('Token','') ;      
          this.props.history.push('/') ;       
              }
         
         
         count =response.data.data.count;

         for (const key in response.data.data.rows) {
           
          if (response.data.data.rows.hasOwnProperty(key)) {
            const element = response.data.data.rows[key];
            
            
              var Obj=[
               element.id,
               element.companyName,
              
               element.address.address+', '+(element.address.addressLineTwo ?element.address.addressLineTwo+', ':'')+element.address.city+', '+element.address.state.state+ ', '+element.address.zip ,    
                     
               element.address.telephone,             
           
              ]
            
            arr.push(Obj);
            
          }
         
        }

        if(this._isMounted){
         this.setState({data:arr, isLoading: false ,count});
        }
        
       // console.log(arr);
       }).catch(function (error) {
         NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);         
         console.log(error);
       })         
       
     
       


}

setLocalStorage = () => {

  const table = {
    order: this.state.order,
    sortByColumn: this.state.sortByColumn,
    search: this.state.search,
    page: this.state.page,
    offset: this.state.offset,
    limit: this.state.limit,
  };

  setPersistentTableState('insuranceAgent', table);

  this.columns = this.columns.map(col => {
    col.name === table.sortByColumn ?
    col.options.sortDirection = table.order.toLowerCase() :
    delete col.options.sortDirection;
    return col;
  });

}

    render() {
     
      const { role,isLoading, data, rowsPerPage, open, searchVal,count,page } = this.state;

      const options = {      
        responsive: "scroll",
        selectableRows: false,
        rowHover:true,
        print:false,
        download:false,
        filter:false,
        search:false,
        rowsPerPageOptions :[25,50,100],
        rowsPerPage:rowsPerPage,
        viewColumns:false,
        textLabels: {
          body: {          
            toolTip: "",
          }},       
         onCellClick:this.startEditing,

         serverSide:true,
         count: count,
         page: page,
         onTableChange: this.onTableChange,
        
      };

      const crossN = {
        display: 'none'
      }
      const crossS = {
        display: 'block'
      }
      if(isLoading){
        return (
          <div className="page-container-bg-solid page-header-fixed page-sidebar-closed-hide-logo page-md">
           <Header title='| Insurance Agent' />
          <div className="page-container"> 
            <Sidebar/>
            <div className="page-content-wrapper"> 
              
            <div className="page-content"> 
            
              <div className="row">
                <div className="col-md-12"> 
                  
                  <div className="portlet box yellow-gold">
                    <div className="portlet-title">
                      <div className="caption"> <i className="icon-briefcase"></i> <span className="caption-subject"> Insurance Agent </span> </div>
                      <div className="actions">
                        <div className="btn-group">

                        { role  !== tenantUser? 
                          <Link  to="/AddAgent" id="ab" className="btn sbold white dark"> Add New <i className="fa fa-plus"></i> </Link>
                          :null}
                      
                        </div>
                      </div>
                    </div>
                    <div className="portlet-body">
                    <div className="table-toolbar">
                    <div style={{textAlign: 'right', paddingRight:' 10px', paddingBottom: '8px'}}>
                    {/* <span style={{float: "left", paddingLeft: "8px",  marginTop: "6px"}}>
                    <div className="btnShowall">
                        <Button
                          buttonRef={node => {
                            this.anchorEl = node;
                          }}
                          aria-owns={open ? 'menu-list-grow' : undefined}
                          aria-haspopup="true"
                          onClick={this.handleToggle}
                        >
                          {this.state.btnVal} &nbsp;&nbsp;
                                <span className="fa fa-chevron-down"></span>
                        </Button>
                        <Popper open={open} anchorEl={this.anchorEl} transition disablePortal>
                          {({ TransitionProps, placement }) => (
                            <Grow
                              {...TransitionProps}
                              id="menu-list-grow"
                              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                            >
                              <Paper>
                                <ClickAwayListener onClickAway={this.handleClose}>
                                  <MenuList>
                                    <MenuItem onClick={(e) => this.showall("Show All", e)}>Show All</MenuItem>
                                    <MenuItem onClick={(e) => this.active("Active", e)}>Active</MenuItem>
                                    <MenuItem onClick={(e) => this.Inactive("Inactive", e)}>Inactive</MenuItem>
                                  </MenuList>
                                </ClickAwayListener>
                              </Paper>
                            </Grow>
                          )}
                        </Popper>
                      </div>
                      
                    </span> */}
                        <div style={{marginRight: '0px'}}>
                        <div className="searchField form-control">
                          <input id="search-field" ref="search" name="searchinput" type="text" placeholder="Search..." value={this.state.search} onChange={this.handleSearch} onKeyPress={this.handleKeyPress} />
                          <span style={searchVal ? crossS : crossN} className="removeSearchBtn" onClick={(e) => this.showall("Show All", e)}><i className="fa fa-close"></i></span>
                        </div>
                        <input name="searchbutton" style={{marginRight: "10px"}} className="btn green-meadow" type="submit" value="SEARCH" onClick={this.SetSearchParameters}/>
                        <input type="button" style={{display: "none"}} className="btn green-meadow" value="SHOW ALL" onClick={this.showall.bind(this)}/>
                        {/* <img alt='' id="excel_export" src="../assets/apps/img/excel_button.png" title="Export data to Excel" />  */}
                      
                        </div>
                    </div>
                    </div>                   
                   <p style={{textAlign:"center"}}><img src={f} alt='Loader'/></p>
                </div>
              </div>
              </div>
             </div>
             </div>
            </div>
          </div>
          </div>
        );
      }
     else{

       return (

<div className="page-container-bg-solid page-header-fixed page-sidebar-closed-hide-logo page-md">
<Header title='| Insurance Agent'/>
<div className="page-container"> 
  <Sidebar/>
<div className="page-content-wrapper"> 
   
<div className="page-content"> 
 
  <div className="row">
    <div className="col-md-12"> 
      
      <div className="portlet box yellow-gold">
        <div className="portlet-title">
          <div className="caption"> <i className="icon-briefcase"></i> <span className="caption-subject"> Insurance Agent</span> </div>
          <div className="actions">
            <div className="btn-group">

            { role  !== tenantUser? 
              <Link  to="/AddAgent" id="ab" className="btn sbold white dark"> Add New <i className="fa fa-plus"></i> </Link>
           :null }
           
            </div>
          </div>
        </div>
        <div className="portlet-body">
          <div className="table-toolbar">
            <div style={{textAlign: 'right', paddingRight:' 10px', paddingBottom: '8px'}}>

            {/* <span style={{float: "left", paddingLeft: "8px",  marginTop: "6px"}}>
              <div className="btnShowall">
                  <Button
                    buttonRef={node => {
                      this.anchorEl = node;
                    }}
                    aria-owns={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={this.handleToggle}
                  >
                    {this.state.btnVal} &nbsp;&nbsp;
                          <span className="fa fa-chevron-down"></span>
                  </Button>
                  <Popper open={open} anchorEl={this.anchorEl} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        id="menu-list-grow"
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={this.handleClose}>
                            <MenuList>
                              <MenuItem onClick={(e) => this.showall("Show All", e)}>Show All</MenuItem>
                              <MenuItem onClick={(e) => this.active("Active", e)}>Active</MenuItem>
                              <MenuItem onClick={(e) => this.Inactive("Inactive", e)}>Inactive</MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </div>
               
              </span> */}

              <div style={{marginRight: '0px'}}>
                <div className="searchField form-control">
                  <input id="search-field" ref="search" name="searchinput" type="text" placeholder="Search..." value={this.state.search} onChange={this.handleSearch} onKeyPress={this.handleKeyPress} />
                  <span style={searchVal ? crossS : crossN} className="removeSearchBtn" onClick={(e) => this.showall("Show All", e)}><i className="fa fa-close"></i></span>
                </div>
                <input name="searchbutton" style={{marginRight: "10px"}} className="btn green-meadow" type="submit" value="SEARCH" onClick={this.SetSearchParameters}/>
                <input type="button" className="btn green-meadow" style={{display: "none"}} value="SHOW ALL" onClick={this.showall.bind(this)}/>
                {/* <img alt='' id="excel_export" src="../assets/apps/img/excel_button.png" title="Export data to Excel" />  */}
               
                </div>
            </div>
          </div>
           
          <MUiTable
           TableName='AgentTable muitable'
           data={data}
           columns={this.columns}
           options={options}
          />

        </div>
      </div>
     
    </div>
  </div>
  
</div>

</div>

<div className="modal fade" id="basic" tabIndex="-1"  aria-hidden="true">
    <div className="modal-dialog">
        <div className="modal-content">
            <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-hidden="true"></button>
                <a className="btn btn-circle btn-icon-only btn-default pull-right dark" data-toggle="modal" href="#basic2"  style={{margin: '-9px 10px 0 0'}}>
                    <i className="fa fa-question"></i>
                </a>
                <h4 className="modal-title">Files</h4>
            </div>
            <div className="modal-body"> 
            	<p>To upload files click  <button type="button" className="btn btn-transparent dark btn-outline btn-circle btn-sm active ">ADD FILE</button> or drag and drop files anywhere in this window. </p>
            	<div className="table-responsive">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th> File </th>
                            <th> Size </th>
                            <th> Options </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td> Test </td>
                            <td> 16kb </td>
                            <td> Table cell </td>
                          </tr>
                          <tr>
                            <td> Test2 </td>
                            <td> 12kb </td>
                            <td> Table cell </td>
                          </tr>
                          <tr>
                            <td> Test3 </td>
                            <td> 18kb </td>
                            <td> Table cell </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn dark btn-outline" data-dismiss="modal">Close</button>
              
            </div>
        </div>
     
    </div>
   
</div>
<div className="modal fade" id="basic2" tabIndex="-1"  aria-hidden="true">
    <div className="modal-dialog">
        <div className="modal-content">
            <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 className="modal-title">Online Videos</h4>
            </div>
            <div className="modal-body"> 
            	<div className="table-responsive">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th> Category </th>
                            <th> Title </th>
                            <th> Length </th>
                            <th>View</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td> Administration		 </td>
                            <td> How to create a customer log in </td>
                            <td> 00:02:02 </td>
                            <td className="text-center">
                            	<i className="fa fa-video-camera"></i>
                            </td>
                          </tr>
                          <tr>
                            <td> Administration		 </td>
                            <td>How to add an agent office  </td>
                            <td> 00:05:02 </td>
                            <td className="text-center">
                            	<i className="fa fa-video-camera"></i>
                            </td>
                          </tr>
                          <tr>
                            <td> Administration		 </td>
                            <td> File Storage </td>
                            <td> 00:04:02 </td>
                            <td className="text-center">
                            	<i className="fa fa-video-camera"></i>
                            </td>
                          </tr>
                          <tr>
                            <td> Administration		 </td>
                            <td> How to create a customer</td>
                            <td> 00:02:02 </td>
                            <td className="text-center">
                            	<i className="fa fa-video-camera"></i>
                            </td>
                          </tr>
                          
                        </tbody>
                      </table>
                    </div>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn dark btn-outline" data-dismiss="modal">Close</button>
               
            </div>
        </div>
     
   
</div>
      </div>
      </div>
      </div>

  );}
}
}

export default withRouter(InsuranceAgent);