/* eslint-disable */ 

import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import axios from 'axios';

import Sidebar from './sidebar';
import Header from'./Head';
import $ from 'jquery';
import BaseURL from './config';

import { NotificationManager} from 'react-notifications';

import TokenErrorMsg from './errorMsg';





class Profile extends Component {
  _isMounted=false;
  constructor(props){
    super(props);
    this.state = {      
        fields: {},
        errors: {},
        ProfileData:{},
        id:'',
        validatePassword:'',
        disableButton:false,
        
    };
    
};


async componentDidMount() {  
  
  
  const token=localStorage.getItem('Token');
  this._isMounted=true;
  if(this._isMounted){
  await this.setState({token:token})
  }
  var arr={
    'id':'',
    'firstName':'',
    'lastName':'',
    'email':'',
    'userRole':'',
  };
  
  await axios.post(BaseURL+'users/getUserProfile',{   
    'token':this.state.token
  }, 
  { 
    headers: { 'Content-Type': 'application/json' }
  }
  ).then(response=> {
    console.log(response.data);     
    if(response.data.error === TokenErrorMsg){     
     localStorage.setItem('Token','') ;      
     this.props.history.push('/') ;       
         }
            console.log(response.data.data)            
            arr={
            'id':response.data.data.userProfile.id,
            'firstName':response.data.data.userProfile.firstName,
            'lastName':response.data.data.userProfile.lastName,
            'email':response.data.data.userProfile.email,
            'userRole':response.data.data.userProfile.userRole.userRole,                
            }          
        
           
          
         
          }).catch(function (error) {
            NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);         
            console.log(error);
          })
         
         
          
         if(this._isMounted){
         this.setState({ProfileData:arr,id:arr.id});
          }


       
        
}

componentWillUnmount(){
  this._isMounted=false
}

async handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    if(this._isMounted){
        await this.setState({
          fields
        });
    }
    this.validateForm();
  }

  validateForm() {

    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true; 
  
 
    if (typeof fields["password"] !== "undefined" && fields["password"]) {
    if (!fields["password"].match(/^[A-Za-z\d!$%@#£€*?&., ]{1,15}$/)) {
      formIsValid = false;
      errors["password"] = "*Only AlphaNumeric Allowed With Special Characters  (Range:  1 to 15)";
    }
    }

    if (typeof fields["Newpassword"] !== "undefined" && fields["Newpassword"]) {
        if (!fields["Newpassword"].match(/^[A-Za-z\d!$%@#£€*?&., ]{4,15}$/)) {
          formIsValid = false;
          errors["Newpassword"] = "*Minimum four characters, at least one letter (max: 15)";
        }
        }
    if (typeof fields["Repassword"] !== "undefined" && fields["Repassword"] ) {
        if (!fields["Repassword"].match(/^[a-zA-Z0-9#.$@!,-/\ ]{8,15}$/)) {
            formIsValid = false;
            errors["Repassword"] = "*Only AlphaNumeric Allowed With Special Characters  (Range:  8 to 15)";
        }
        }
        if (typeof fields["Repassword"] !== "undefined" && fields["Repassword"] ) {
            if (fields["Repassword"] !== fields["Newpassword"]) {
                formIsValid = false;
                errors["Repassword"] = "Please Match Password";
            }
            }
       if (typeof fields["password"] !== "undefined" && fields["password"] && typeof fields["Newpassword"] !== "undefined" && fields["Newpassword"] ) {
            if (fields["password"] === fields["Newpassword"]) {
                formIsValid = false;
                errors["Newpassword"] = "Your Current And New Password Is Same";
            }
            }
            if( this.state.errors.password &&   this.state.validatePassword === fields["password"]){
              errors["password"] = "Your Current Password Is Wrong";
              formIsValid = false;
             
            }
            
    
  if(this._isMounted){
      this.setState({
        errors: errors
      });
   }
    return formIsValid;
  
  }

  submituserRegistrationForm(e) {
    e.preventDefault();
    var send=false;
    if (this.validateAndSubmit() && this.validateForm() ) {
      
      send=true;
       
    }
    if(send)
    {
      console.log('enter yes');
      if(this._isMounted){
      this.setState({disableButton:true});
      }
      this.submit();
    }
    
  }

  validateAndSubmit(){

    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
  
   
    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = "*Please Enter Password ";
      $("[name='password']").focus();
     
    }
    else if (!fields["Newpassword"]) {
        formIsValid = false;
        errors["Newpassword"] = "*Please Enter New Password ";
        $("[name='Newpassword']").focus();
       
      }
      else if (!fields["Repassword"]) {
        formIsValid = false;
        errors["Repassword"] = "*Please Enter Re-type Password ";
        $("[name='Repassword']").focus();
       
      }
      if(this._isMounted){
      this.setState({
        errors: errors
      });
    }
      return formIsValid;
  }

  submit(e){

  var errors={};

  
    
    var password=this.refs.password.value;
    if(this._isMounted){
    this.setState({ validatePassword:password });  
     }
    var Newpassword=this.refs.Newpassword.value;
    

    axios.post(BaseURL+'users/updateUserPassword',{   
        'token':this.state.token,
        "id": this.state.id,       
        'currentPassword': password,
        "password": Newpassword,
      }).then(response=> {
                console.log(response.data)
              

                if(response.data.error==="Incorrect Current Password"){
                    NotificationManager.error('Current Password is Wrong ', 'Error Message', 3000);       
                    errors["password"] = "Your Current Password Is Wrong";
                    if(this._isMounted){
                       this.setState({ errors:errors ,disableButton:false});  
                    } 
                }
                else if(response.data.message === "Password Updated Sucessfully"){
                    NotificationManager.success('Please Login Again', 'Your Password Updated', 1500);
                  
                    
                    setTimeout( myFunction=>{
                        localStorage.setItem('Token','');
                        this.props.history.push('/');
                    }, 1500);
                    

                }

             
              }).catch(function (error) {
                NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);         
                console.log(error);
              })              
            
           
               

  }
   cancel() {

    this.props.history.push('/dashboard');
   
    }
  
    render() {     

     

    
       return (

        <div className="page-container-bg-solid page-header-fixed page-sidebar-closed-hide-logo page-md">
        { <Header title='| Profile '/>}
        <div className="page-container">
     
          <Sidebar/>
<div className="page-content-wrapper"> 
   
<div className="page-content"> 
 
  <div className="row">
    <div className="col-md-12"> 
      
      <div className="portlet box yellow-gold">
        <div className="portlet-title">
          <div className="caption"> <i className="fa fa-user"></i> <span className="caption-subject"> Profile</span> </div>
       </div>
       <div className="portlet-body form">
              
              <div className="">
              <div className="max_width700">
                <br/>
                <table className="table table-striped table-bordered table-hover table-checkable order-column">
                <tbody>
                  <tr>
                    <th width="40%" style={{textAlign: 'left',paddingLeft: '10px'}}> First Name </th>
                    <td>{this.state.ProfileData.firstName} </td>
                  </tr>
                  <tr>
                    <th style={{textAlign: 'left',paddingLeft: '10px'}}>Last Name</th>
                    <td>{this.state.ProfileData.lastName}</td>
                  </tr>
                  <tr>
                    <th style={{textAlign: 'left',paddingLeft: '10px'}}> Email Address </th>
                    <td>{this.state.ProfileData.email}</td>
                  </tr>
                  <tr>
                    <th style={{textAlign: 'left',paddingLeft: '10px'}}> User Type </th>
                    <td>{this.state.ProfileData.userRole} </td>
                  </tr>
                  </tbody>
              </table>
           
                <form action="#">
                  <div className="form-group">
                      <label className="control-label">Current Password</label>
                      <input type="password" name='password' ref='password' onChange={this.handleChange.bind(this)} className="form-control"/>
                      <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.password}</div>
                 </div>
                  <div className="form-group">
                      <label className="control-label">New Password</label>
                      <input type="password" name='Newpassword' ref='Newpassword' onChange={this.handleChange.bind(this)} className="form-control"/> 
                      <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.Newpassword}</div>
                  </div>
                  <div className="form-group">
                      <label className="control-label">Re-type New Password</label>
                      <input type="password" name='Repassword' ref='Repassword' onChange={this.handleChange.bind(this)} className="form-control"/> 
                      <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.Repassword}</div>
                    </div>
                    
              </form>
            </div>
          </div>
            <div className="form-actions right">
                         
                      <button className="btn default" onClick={this.cancel.bind(this)}> Close </button> &nbsp;
                      <button  className="btn green-meadow" style={this.state.disableButton?{ pointerEvents: 'none', cursor: 'default'}:{}} onClick={this.submituserRegistrationForm.bind(this)}> Change Password </button>
             </div>
            </div>
       </div>
       </div>
       </div>
       </div>
       </div>
      </div>    
      </div>

  );
}
}

export default  withRouter(Profile) ;