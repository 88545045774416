/* eslint-disable */

import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import $ from 'jquery';
import BaseURL from './config';
import DatePicker from "react-datepicker";
import TokenErrorMsg from './errorMsg';


import 'react-select/dist/react-select.css';
import 'react-virtualized/styles.css';
import 'react-virtualized-select/styles.css';


class AddDriver extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            status: 1,
            countries: [],
            states: [],
            driverid: '',
            whileSave: false,
            readonly: false,
            hidden: true,
            addressid: '',
            isLoading: false,
            token: '',
            fields: {},
            errors: {},
            carrier: [],
            carrierdata: '',
            MedExpDate: new Date(),
            LicExpDate: new Date(),
            file: {
                License: '',
                Medcertificate: '',
            },
            Licenseload: true,
            Medicalload: true,
            licenseStates: [],


        };
        this.handleChange = this.handleChange.bind(this);
        this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);
        this.submit = this.submit.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.AutoFormatContact = this.AutoFormatContact.bind(this);
    }


    async componentDidMount() {

        this._isMounted = true;
        const token = localStorage.getItem('Token');
        if (this._isMounted) {
            this.setState({ 'token': token });
        }

        var arr = {
            'countries': [],
            'licenseStates': [],

        };
        var data = false;
        await axios.post(BaseURL + 'driver/add', { 'token': token })
            .then(response => {

                if (response.data.error === TokenErrorMsg) {
                    localStorage.setItem('Token', '');
                    this.props.history.push('/');
                }
                arr = response.data.data;
                data = true;
            }).catch(function (error) {
                NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);
                console.log(error);
            })


        if (data && this._isMounted) {
            this.setState({
                countries: arr.countries,
                licenseStates: arr.licenseStates
            });
        }


    }


    componentWillUnmount() {
        this._isMounted = false;
    }

    AutoFormatContact(e) {


        function phone_formatting(ele, restore) {
            var new_number,
                // selection_start = ele.selectionStart,
                // selection_end = ele.selectionEnd,
                number = ele.value.replace(/\D/g, '');

            // automatically add dashes
            if (number.length > 2) {
                // matches: 123 || 123-4 || 123-45
                new_number = number.substring(0, 3) + '-';
                if (number.length === 4 || number.length === 5) {
                    // matches: 123-4 || 123-45
                    new_number += number.substr(3);
                }
                else if (number.length > 5) {
                    // matches: 123-456 || 123-456-7 || 123-456-789
                    new_number += number.substring(3, 6) + '-';
                }
                if (number.length > 6) {
                    // matches: 123-456-7 || 123-456-789 || 123-456-7890
                    new_number += number.substring(6);
                }
            }
            else {
                new_number = number;
            }

            // if value is heigher than 12, last number is dropped
            // if inserting a number before the last character, numbers
            // are shifted right, only 12 characters will show
            ele.value = (new_number.length > 12) ? new_number.substring(0, 12) : new_number;

            // restore cursor selection,
            // prevent it from going to the end
            // UNLESS
            // cursor was at the end AND a dash was added


            // if (new_number.slice(-1) === '-' && restore === false
            //     && (new_number.length === 8 && selection_end === 7)
            //         || (new_number.length === 4 && selection_end === 3)) {
            //     selection_start = new_number.length;
            //     selection_end = new_number.length;
            // }
            // else if (restore === 'revert') {
            //   selection_start--;
            //   selection_end--;
            // }
            // ele.setSelectionRange(selection_start, selection_end);

        }

        function phone_number_check(field, e) {
            var key_code = e.keyCode,
                key_string = String.fromCharCode(key_code),
                press_delete = false,
                dash_key = 189,
                delete_key = [8, 46],
                direction_key = [33, 34, 35, 36, 37, 38, 39, 40],
                selection_end = field.selectionEnd;

            // delete key was pressed
            if (delete_key.indexOf(key_code) > -1) {
                press_delete = true;
            }

            // only force formatting is a number or delete key was pressed
            if (key_string.match(/^\d+$/) || press_delete) {
                phone_formatting(field, press_delete);
            }
            // do nothing for direction keys, keep their default actions
            else if (direction_key.indexOf(key_code) > -1) {
                // do nothing
            }
            else if (dash_key === key_code) {
                if (selection_end === field.value.length) {
                    field.value = field.value.slice(0, -1)
                }
                else {
                    field.value = field.value.substring(0, (selection_end - 1)) + field.value.substr(selection_end)
                    field.selectionEnd = selection_end - 1;
                }
            }
            // all other non numerical key presses, remove their value
            else {
                e.preventDefault();
                //    field.value = field.value.replace(/[^0-9\-]/g,'')
                phone_formatting(field, 'revert');
            }

        }

        const Validate = (e) => {
            if (!String.fromCharCode(e.keyCode).match(/^- $/)) {
                this.handleChange(e)
            }
        }

        document.getElementById(e.target.id).onkeyup = function (e) {
            phone_number_check(this, e);
            Validate(e)
        }
    }

    async handleChange(e) {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        if (this._isMounted) {
            await this.setState({
                fields
            });
        }
        this.validateForm();
    }

    submituserRegistrationForm(e) {
        e.preventDefault();
        var send = false;

        if (this.validateAndSubmit() && this.validateForm()) {

            send = true;

        }
        if (send) {

            this.submit();
        }

    }

    validateAndSubmit() {

        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        // CustomerNAme
        if (!this.refs.firstName.value || !this.refs.firstName.value.trim().length > 0) {
            formIsValid = false;
            errors["firstName"] = "*Please Enter Driver Name";
            $("[name='firstName']").focus();

        }


        // Address
        else if (!this.refs.address.value || !this.refs.address.value.trim().length > 0) {
            formIsValid = false;
            errors["address"] = "*Please Enter Address";
            $("[name='address']").focus();

        }


        // Country
        else if (!this.refs.country_id.value) {
            formIsValid = false;
            errors["country"] = "*Please Select Country";
            $("[name='country']").focus();

        }

        // State
        else if (!this.refs.province_id.value) {
            formIsValid = false;
            errors["state"] = "*Please Select State";
            $("[name='state']").focus();

        }

        // City
        else if (!this.refs.city.value || !this.refs.city.value.trim().length > 0) {
            formIsValid = false;
            errors["city"] = "*Please Enter City";
            $("[name='city']").focus();

        }

        // Zip
        else if (!this.refs.zip.value || !this.refs.zip.value.trim().length > 0) {
            formIsValid = false;
            errors["zip"] = "*Please Enter Zip";
            $("[name='zip']").focus();

        }
        else if (!this.refs.driverLicense.value || !this.refs.driverLicense.value.trim().length > 0) {
            formIsValid = false;
            errors["DriverLicense"] = "*Please Enter Driver License";
            $("[name='DriverLicense']").focus();

        }
        else if (!this.refs.bprovince_id.value) {
            formIsValid = false;
            errors["bstate"] = "*Please Select State";
            $("[name='bstate']").focus();

        }




        if (this._isMounted) {
            this.setState({
                errors: errors
            });
        }
        return formIsValid;
    }



    validateForm() {

        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;




        // customerName
        if (typeof fields["firstName"] !== "undefined" && fields["firstName"]) {
            if (!fields["firstName"].match(/^[a-zA-Z#'".,-/ ]{1,200}$/)) {
                formIsValid = false;
                errors["firstName"] = "*Only Alphabets Allowed (Max Range: 200)";
            }
        }

        if (typeof fields["lastName"] !== "undefined" && fields["lastName"]) {
            if (!fields["lastName"].match(/^[a-zA-Z#'".,-/ ]{1,200}$/)) {
                formIsValid = false;
                errors["lastName"] = "*Only Alphabets Allowed (Max Range: 200)";
            }
        }

        if (typeof fields["middleName"] !== "undefined" && fields["middleName"]) {
            if (!fields["middleName"].match(/^[a-zA-Z#'".,-/ ]{1,200}$/)) {
                formIsValid = false;
                errors["middleName"] = "*Only Alphabets Allowed (Max Range: 200)";
            }
        }




        //address

        if (typeof fields["address"] !== "undefined" && fields["address"]) {
            if (!fields["address"].match(/^[a-zA-Z0-9#'"،.,-;/ ]{1,200}$/)) {
                formIsValid = false;
                errors["address"] = "*Please Enter Only Valid Characters (Max Range: 200)";
            }
        }



        //addressLineTwo

        if (typeof fields["addressLineTwo"] !== "undefined" && fields["addressLineTwo"]) {
            if (!fields["addressLineTwo"].match(/^[a-zA-Z0-9#'"،.,-;/ ]{1,200}$/)) {
                formIsValid = false;
                errors["addressLineTwo"] = "*Please Enter Only Valid Characters (Max Range: 200)";
            }
        }

        //city

        if (typeof fields["city"] !== "undefined" && fields["city"]) {
            if (!fields["city"].match(/^[a-zA-Z0-9#., ]{1,200}$/)) {
                formIsValid = false;
                errors["city"] = "*Please Enter Only Valid Characters (Max Range: 200)";
            }
        }

        //zip

        if (typeof fields["zip"] !== "undefined" && fields["zip"]) {
            if (!fields["zip"].match(/^[a-zA-Z0-9 ]{1,35}$/)) {
                formIsValid = false;
                errors["zip"] = "*Only Numbers Allowed in Zip (Max Range: 35)";
            }
        }

        //telephone

        if (typeof fields["telephone"] !== "undefined" && fields["telephone"]) {
            if (!fields["telephone"].match(/^[0-9- ]{12,30}$/)) {
                formIsValid = false;
                errors["telephone"] = "*Please Enter only valid Telephone Number ( Range: 10)";
            }
        }

        //Ext

        if (typeof fields["ext"] !== "undefined" && fields["ext"]) {
            if (!fields["ext"].match(/^[0-9]{1,20}$/)) {
                formIsValid = false;
                errors["ext"] = "*Only Numbers Allowed (Max Range: 20)";
            }
        }
        //Fax

        if (typeof fields["fax"] !== "undefined" && fields["fax"]) {
            if (!fields["fax"].match(/^[0-9- ]{12,30}$/)) {
                formIsValid = false;
                errors["fax"] = "*Please Enter only valid fax Number ( Range: 10)";
            }
        }

        //email

        if (typeof fields["email"] !== "undefined" && fields["email"]) {
            if (!fields["email"].match(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/)) {
                formIsValid = false;
                errors["email"] = "*Please Enter Valid Email ";
            }
        }

        if (typeof fields["DriverLicense"] !== "undefined" && fields["DriverLicense"]) {
            if (!fields["DriverLicense"].match(/^[a-zA-Z0-9'-.#$ ]{1,30}$/)) {
                formIsValid = false;
                errors["DriverLicense"] = "*Please Enter Valid License  (Max Range: 30)";
            }
        }

        if (typeof this.state.LicExpDate !== "undefined" && this.state.LicExpDate) {
            if (this.state.LicExpDate.getFullYear() < 2000 || this.state.LicExpDate.getFullYear() > 2099) {
                formIsValid = false;
                errors["LicExpDate"] = "*Enter Valid Date ( Year Between 2000-2099 )";
            }
        }

        if (typeof this.state.MedExpDate !== "undefined" && this.state.MedExpDate) {
            if (this.state.MedExpDate.getFullYear() < 2000 || this.state.MedExpDate.getFullYear() > 2099) {
                formIsValid = false;
                errors["MedExpDate"] = "*Enter Valid Date ( Year Between 2000-2099 )";
            }
        }




        if (this._isMounted) {
            this.setState({
                errors: errors
            });
        }
        return formIsValid;


    }


    async states() {
        var arr;
        var data = false;

        await axios.post(BaseURL + 'countries/states', {
            "countryId": this.refs.country_id.value,
            'token': this.state.token
        })
            .then(response => {
                if (response.data.error === TokenErrorMsg) {
                    localStorage.setItem('Token', '');
                    this.props.history.push('/');
                }
                arr = response.data.data;
                data = true;
            }).catch(function (error) {
                NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);
                console.log(error);
            })
        if (data && this._isMounted) {

            this.setState({ states: arr });
        }

    }


    async submit() {

        if (this._isMounted) {
            this.setState({ whileSave: true })
        }

        const firstName = this.refs.firstName.value.trim();
        const lastName = this.refs.lastName.value.trim();
        const middleName = this.refs.middleName.value.trim();
        const carrierId = this.props.carrierId;

        const address = this.refs.address.value.trim();
        const address2 = this.refs.address2.value.trim();
        const country_id = this.refs.country_id.value;
        const province_id = this.refs.province_id.value;
        const city = this.refs.city.value.trim();
        const zip = this.refs.zip.value.trim();
        const email = this.refs.email.value.trim();
        const telephone = this.refs.telephone.value.trim();
        const fax = this.refs.fax.value.trim();
        const ext = this.refs.ext.value.trim();

        const bprovince_id = this.refs.bprovince_id.value;
        const driverLicense = this.refs.driverLicense.value;



        var driver = {
            'token': this.state.token,
            "firstName": firstName,
            "lastName": lastName,
            "middleName": middleName,
            "licenseNo": driverLicense,
            "licenseExpirationDate": this.state.LicExpDate,
            "medicalCertificateExpirationDate": this.state.MedExpDate,
            "status": this.state.status,
            "carrierId": carrierId,
            "licenseStateId": bprovince_id,
            "address": {
                "address": address,
                "addressLineTwo": address2,
                "city": city,
                "zip": zip,
                "email": email,
                "telephone": telephone,
                "ext": ext,
                "fax": fax,
                "state": {
                    "id": province_id,
                    "country": {
                        "id": country_id

                    }
                }
            }
        }

        console.log(driver);
        this.resetForm();     



        await axios.post(BaseURL + 'driver/save', driver)
            .then(response => {
                if (response.data.error === TokenErrorMsg) {
                    localStorage.setItem('Token', '');
                    this.props.history.push('/');
                }                             
                this.props.loadDriverTruckTrailerDropdowns(carrierId);
                NotificationManager.success('New Driver Added Successfully', 'Added', 3000);
            }).catch(function (error) {
                NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);
                console.log(error);
            })
        if (this._isMounted) {
            this.setState({
                whileSave: false,

            });
        }


    }

    resetForm = () => {
        $('#closeDriverPopUp').click();
        document.getElementById('dispatchform').click();        
        this.setState({
            states: [],
            MedExpDate: new Date(),
            LicExpDate: new Date(),
            status: 1,
        });
        this.refs.driverForm.reset();
        $("#driverActiveLabel").addClass('active');
        $("#driverInActiveLabel").removeClass('active');

    }



    render() {

        const msgNone = {
            display: 'none'
        }
        const msgShow = {
            display: 'block',
        }

        const nonclick = {
            cursor: ' notAllowed',
            pointerEvents: 'none'
        }

        return (
            <div className="modal-content">
                <div className="modal-header">
                    <button type="button" id="closeDriverPopUp" data-dismiss="modal" aria-hidden="true" hidden={true}></button>
                    <button type="button" className="close" onClick={this.resetForm} aria-hidden="true"></button>
                    <h4 className="modal-title">Driver Info</h4>
                </div>
                <div className="modal-body">
                    <div className="portlet-body form">
                        <form onSubmit={this.submituserRegistrationForm.bind(this)} ref='driverForm' className="horizontal-form" autoComplete="off" >
                            <div className="form-body max_width800">
                                <div className="portlet-title">
                                    <h2 className="h3"> Driver Info
                                     <span style={{ float: 'right', paddingLeft: ' 8px', marginTop: '2px' }} >
                                            <div className="btn-group btn-group-devided" data-toggle="buttons" style={this.state.readonly ? nonclick : msgShow} >
                                                <label className="btn btn-transparent dark btn-outline btn-circle btn-sm active" id="driverActiveLabel" disabled={this.state.readonly} onClick={() => { if (this._isMounted) { this.setState({ status: 1 }) } }}>
                                                    <input type="radio" name="options" className="toggle"  />
                                                    Active</label>
                                                <label className="btn btn-transparent dark btn-outline btn-circle btn-sm" id="driverInActiveLabel" disabled={this.state.readonly} onClick={() => { if (this._isMounted) { this.setState({ status: 0 }) } }}>
                                                    <input type="radio" name="options1" className="toggle"  />
                                                    Inactive</label>
                                            </div>
                                        </span>
                                    </h2>

                                    <div className="clearfix"></div>
                                    <hr />
                                </div>


                                <div className="form-group row">
                                    <label className="col-md-3 control-label">First Name: <span className="font-red-thunderbird" >*</span></label>
                                    <div className="col-md-9">
                                        <input type="text" ref="firstName" onChange={this.handleChange} name="firstName" className="form-control" placeholder="" readOnly={this.state.readonly} />
                                        <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.firstName}</div>
                                    </div>
                                </div>


                                <div className="form-group row">
                                    <label className="col-md-3 control-label">Middle Name: <span className="font-red-thunderbird" ></span></label>
                                    <div className="col-md-9">
                                        <input type="text" ref="middleName" onChange={this.handleChange} name="middleName" className="form-control" placeholder="" readOnly={this.state.readonly} />
                                        <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.middleName}</div>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-md-3 control-label">Last Name: <span className="font-red-thunderbird" ></span></label>
                                    <div className="col-md-9">
                                        <input type="text" ref="lastName" onChange={this.handleChange} name="lastName" className="form-control" placeholder="" readOnly={this.state.readonly} />
                                        <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.lastName}</div>
                                    </div>
                                </div>


                                <div className="portlet-title">
                                    <h2 className="h3"> Address Info </h2>
                                    <hr />
                                </div>
                                <div className="form-group row">
                                    <label className="col-md-3 control-label">Address <span className="font-red-thunderbird" >*</span></label>
                                    <div className="col-md-9">
                                        <input type="text" ref="address" onChange={this.handleChange} name="address" className="form-control" placeholder="" readOnly={this.state.readonly} />
                                        <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.address}</div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-md-3 control-label">Address Line 2</label>
                                    <div className="col-md-9">
                                        <input type="text" ref="address2" onChange={this.handleChange} name="addressLineTwo" className="form-control" placeholder="" readOnly={this.state.readonly} />
                                        <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.addressLineTwo}</div>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-md-3 control-label">Country <span className="font-red-thunderbird" >*</span></label>
                                    <div className="col-md-9">


                                        <select name="country" ref="country_id" className="form-control" title="Country" defaultValue='' onChange={this.states.bind(this)} disabled={this.state.readonly} readOnly={this.state.readonly}>

                                            <option value="" disabled hidden>Select Country</option>
                                            {
                                                this.state.countries.map(function (data) {
                                                    return <option value={data.id} key={data.id}> {data.countryName} </option>;
                                                })

                                            }

                                        </select>
                                        <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.country}</div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-md-3 control-label">State <span className="font-red-thunderbird" >*</span></label>
                                    <div className="col-md-9">
                                        <select name="state" ref="province_id" className="form-control" defaultValue='1' disabled={this.state.readonly} readOnly={this.state.readonly}>
                                            <option value="" disabled hidden>Select State</option>
                                            {
                                                this.state.states.map(function (data) {
                                                    return <option value={data.id} key={data.id}> {data.state} </option>;
                                                })

                                            }
                                        </select>
                                        <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.state}</div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-md-3 control-label">City <span className="font-red-thunderbird" >*</span></label>
                                    <div className="col-md-9">
                                        <input type="text" ref="city" onChange={this.handleChange} name="city" className="form-control" placeholder="" readOnly={this.state.readonly} />
                                        <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.city}</div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-md-3 control-label">Zip <span className="font-red-thunderbird" >*</span></label>
                                    <div className="col-md-9">
                                        <input type="text" ref="zip" onChange={this.handleChange} name="zip" className="form-control" placeholder="" readOnly={this.state.readonly} />
                                        <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.zip}</div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-md-3 control-label">Email</label>
                                    <div className="col-md-9">
                                        <input type="email" ref="email" onChange={this.handleChange} name="email" className="form-control" placeholder="" readOnly={this.state.readonly} />
                                        <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.email}</div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-md-3 control-label">Telephone</label>
                                    <div className="col-md-9 form-inline">
                                        <input type="text" ref="telephone" id='Telephone' onChange={this.AutoFormatContact} name="telephone" className="form-control" placeholder="xxx-xxx-xxxx" readOnly={this.state.readonly} />
                                        <span className="help-inline"> EXT </span>  <input type="text" name="ext" onChange={this.handleChange} ref="ext" className="form-control" placeholder="" readOnly={this.state.readonly} />
                                        <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.telephone}</div>
                                        <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.ext}</div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-md-3 control-label">Fax</label>
                                    <div className="col-md-9">
                                        <input type="text" ref="fax" name="fax" id='fax' onChange={this.AutoFormatContact} className="form-control" placeholder="xxx-xxx-xxxx" readOnly={this.state.readonly} />
                                        <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.fax}</div>
                                    </div>
                                </div>


                                <div className="portlet-title">
                                    <h2 className="h3 checkbox-inline" style={{ padding: '0', margin: '0' }}> Driver Qualification File </h2>
                                    <hr />
                                </div>
                                <div className="portlet-title">
                                    <h4 className="h4" style={{ fontWeight: '700' }}> Driver License Info </h4>
                                    <br />
                                </div>
                                <div className="form-group row">
                                    <label className="col-md-3 control-label">License No. <span className="font-red-thunderbird" >*</span></label>
                                    <div className="col-md-9">
                                        <input type="text" ref="driverLicense" onChange={this.handleChange} name="DriverLicense" className="form-control" placeholder="" readOnly={this.state.readonly} />
                                        <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.DriverLicense}</div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-md-3 control-label">State <span className="font-red-thunderbird" >*</span></label>
                                    <div className="col-md-9">
                                        <select name="bstate" ref="bprovince_id" className="form-control" defaultValue='' disabled={this.state.readonly} readOnly={this.state.readonly}>
                                            <option value="" disabled hidden>Select State</option>
                                            {
                                                this.state.licenseStates.map(function (data) {
                                                    return <option value={data.id} key={data.id}> {data.state} </option>;
                                                })

                                            }
                                        </select>
                                        <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.bstate}</div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-md-3 control-label">Expiration Date</label>
                                    <div className="col-md-9">

                                        <DatePicker
                                            className='form-control'
                                            selected={this.state.LicExpDate}
                                            onChange={async (date) => {
                                                if (this._isMounted) {
                                                    await this.setState({
                                                        LicExpDate: date
                                                    });
                                                }
                                                console.log(date);
                                                this.validateForm();
                                            }}
                                            readOnly={this.state.readonly} />
                                        <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.LicExpDate}</div>
                                    </div>

                                </div>




                                <div className="portlet-title">
                                    <h4 className="h4" style={{ fontWeight: '700' }}> Medical Certificate </h4>
                                    <br />
                                </div>

                                <div className="form-group row">
                                    <label className="col-md-3 control-label">Expiration Date</label>
                                    <div className="col-md-9">

                                        <DatePicker
                                            className='form-control'
                                            selected={this.state.MedExpDate}
                                            onChange={async (date) => {
                                                if (this._isMounted) {
                                                    await this.setState({
                                                        MedExpDate: date
                                                    });
                                                }
                                                console.log(this.state.MedExpDate);
                                                this.validateForm();
                                            }}
                                            readOnly={this.state.readonly} />
                                        <div style={{ color: 'red' }} className="errorMsg">{this.state.errors.MedExpDate}</div>
                                    </div>
                                </div>

                            </div>
                            <div className="clearfix"></div>
                            <div className="form-actions right">
                                <button onClick={this.resetForm} type="button" className="btn default">Cancel</button> &nbsp;
                            <button disabled={this.state.readonly} type="submit" className="btn green-meadow">
                                    {this.state.whileSave === false
                                        ? <i className='fa fa-save'></i>
                                        : <i className="fa fa-circle-o-notch fa-spin"></i>}
                                    Save
                            </button>
                            </div>

                        </form>
                    </div>
                </div>
            </div>




        );
    }
}

export default withRouter(AddDriver);