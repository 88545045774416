/* eslint-disable */

import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import "./nav-style.css";
import tenantUser from "./TenantUserPermision";
import accountingUser from "./permissions/AccountingUserPermision";
import dispatcherUser from "./permissions/DispatcherUserPermision";

const Cryptr = require("cryptr");
const cryptr = new Cryptr("@$#rgweR35*&YFSD)/");

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      class: "nav-item",
      mode: "",
      role: ""
    };
    this.handleNavClick = this.handleNavClick.bind(this);
  }

  componentDidMount() {
    if (this.props.mode === "user") {
      this.setState({ mode: "user" });
    }

    const Role = localStorage.getItem("Role");
    if (Role) {
      const role = cryptr.decrypt(Role);
      this.setState({ role: role });
    }
  }

  logout(e) {
    e.preventDefault();
    localStorage.setItem("Token", "");
    this.props.push.history("/");
  }

  SubMenu(e) {
    var classname = this.state.class;
    //  var arrow=  document.getElementById('arrow').className;

    if (classname === "nav-item " || classname === "nav-item") {
      this.setState({ class: "open" });

      document.getElementById("arrow").classList.add("open");

      document.getElementById("display").style.display = "block";
    } else if (classname === "open") {
      this.setState({ class: "nav-item" });

      document.getElementById("arrow").classList.remove("open");

      document.getElementById("display").style.display = "none";
    }
  }

  handleNavClick(event) {
    event.preventDefault();

    if (this.props.editMode) {
      if (window.confirm("Unsaved Changes")) {
        this.props.history.push("/load");
      }
    } else {
      this.props.history.push("/load");
    }
  }

  render() {
    const { role } = this.state;
    return (
      <div>
        <div className="page-sidebar-wrapper">
          <div className="page-sidebar navbar-collapse collapse bg-blue-chambray">
            <ul
              className="page-sidebar-menu page-sidebar-menu-closed"
              id="toggler"
              data-keep-expanded="false"
              data-auto-scroll="true"
              data-slide-speed="200"
            >
              <li className="heading">
                <h3 className="uppercase">Admin</h3>
              </li>

              {role !== tenantUser ? (
                <li className="nav-item ">
                  <NavLink
                    to="/dashboard"
                    className="nav-NavLink "
                    activeClassName="selected"
                  >
                    <i className="fa fa-user"></i>
                    <span className="title">Customer</span>
                  </NavLink>
                </li>
              ) : null}

              <li className="nav-item">
                <NavLink
                  to="/carrier"
                  className="nav-NavLink "
                  activeClassName="selected"
                >
                  <i className="fa fa-truck"></i>
                  <span className="title">Carrier</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/insuranceAgent"
                  className="nav-NavLink "
                  activeClassName="selected"
                >
                  <i className="icon-briefcase"></i>
                  <span className="title">Insurance Agent</span>
                </NavLink>
              </li>
              <li className="nav-item ">
                <NavLink
                  to="/driver"
                  className="nav-NavLink "
                  activeClassName="selected"
                >
                  <i className="fa fa-user-plus"></i>
                  <span className="title">Driver</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/truck"
                  className="nav-NavLink "
                  activeClassName="selected"
                >
                  <i className="fa fa-truck"></i>
                  <span className="title">Truck</span>
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  to="/trailer"
                  className="nav-NavLink "
                  activeClassName="selected"
                >
                  <i className="fa fa-truck"></i>
                  <span className="title">Trailer</span>
                </NavLink>
              </li>

              {role !== tenantUser ? (
                <li className="nav-item ">
                  <NavLink
                    to="/Profile"
                    className="nav-NavLink "
                    activeClassName="selected"
                  >
                    <i className="fa fa-user-md"></i>
                    <span className="title">Profile</span>
                  </NavLink>
                </li>
              ) : null}

              {role !== tenantUser ? (
                <li
                  className="nav-item "
                  style={role === "Admin" ? {} : { display: "none" }}
                >
                  <NavLink
                    to="/TenantUsers"
                    className="nav-NavLink "
                    activeClassName="selected"
                  >
                    <i className="fa fa-user"></i>
                    <span className="title">Tenant Users</span>
                  </NavLink>
                </li>
              ) : null}

              {role !== tenantUser ? (
                <li
                  className={this.state.mode ? "open" : this.state.class}
                  id="nav-item"
                  onClick={this.SubMenu.bind(this)}
                  style={role === "Admin" ? {} : { display: "none" }}
                >
                  <a href="javascript:;" className="nav-link nav-toggle ">
                    <i className="fa fa-cog"></i>
                    <span className="title"> Admin</span>
                    <span
                      className={this.state.mode ? "arrow open" : "arrow"}
                      id="arrow"
                    ></span>
                  </a>

                  <ul
                    className="sub-menu"
                    id="display"
                    style={this.state.mode ? { display: "block" } : {}}
                  >
                    <li className="nav-item">
                      <NavLink to="/Admin" className="nav-link ">
                        <span className="title">User</span>
                      </NavLink>
                    </li>
                  </ul>
                </li>
              ) : null}
              {role !== tenantUser ? (
                <li className="nav-item ">
                  <NavLink
                    to="/shipper"
                    className="nav-NavLink "
                    activeClassName="selected"
                  >
                    <i className="fa fa-paper-plane"></i>
                    <span className="title">Shipper</span>
                  </NavLink>
                </li>
              ) : null}

              <li className="nav-item" onClick={this.handleNavClick}>
                <a
                  href="javascript:;"
                  className={
                    this.props.location.pathname === "/load"
                      ? "nav-link selected"
                      : "nav-link"
                  }
                >
                  <i className="fa fa-cubes" aria-hidden="true"></i>
                  <span className="title">Load</span>
                </a>
              </li>

              {role !== tenantUser ? (
                <li className="nav-item ">
                  <NavLink
                    to="/searchLoad"
                    className="nav-NavLink "
                    activeClassName="selected"
                  >
                    <i className="fa fa-search"></i>
                    <span className="title">Search Load</span>
                  </NavLink>
                </li>
              ) : null}


              {role !== tenantUser ? (
                <li className="nav-item ">
                  <NavLink
                    to="/factoringCompany"
                    className="nav-NavLink "
                    activeClassName="selected"
                  >
                    <i className="icon-briefcase"></i>
                    <span className="title">Factoring Company</span>
                  </NavLink>
                </li>
              ) : null}

              {role !== tenantUser && role !== dispatcherUser ? (
                <li className="nav-item ">
                  <NavLink
                    to="/accounting"
                    className="nav-NavLink "
                    activeClassName="selected"
                  >
                    <i className="fa fa-bar-chart"></i>
                    <span className="title">Accounting</span>
                  </NavLink>
                </li>
              ) : null}
              {role === "Admin" ? (
                <li className="nav-item ">
                  <NavLink
                    to="/billing"
                    className="nav-NavLink "
                    activeClassName="selected"
                  >
                    <i className="fa fa-dollar"></i>
                    <span className="title">Billing</span>
                  </NavLink>
                </li>
              ) : null}

              {role !== tenantUser ? (
                <li className="nav-item ">
                  <NavLink
                    to="/tools"
                    className="nav-NavLink "
                    activeClassName="selected"
                  >
                    <i className="fa fa-tasks"></i>
                    <span className="title">Tools</span>
                  </NavLink>
                </li>
              ) : null}
             <li className="nav-item ">
                  <NavLink
                    to="/Pickup-Deleivery-Details"
                    className="nav-NavLink "
                    activeClassName="selected"
                  >
                    <i className="fa fa-bar-chart"></i>
                    <span className="title">Pick-Up Delievery Details</span>
                  </NavLink>
                </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Sidebar);
