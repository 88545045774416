// const BaseURL="http://54.202.32.192/"; //Staging
// const BaseURL = "https://app.bridge18.com/";  // Live
// const BaseURL = 'http://192.168.1.142:3000/' //shahwar local
//  const BaseURL = 'http://192.168.0.175/'; //wajid local
// const BaseURL = 'http://localhost:4000/'; // local
const BaseURL = "https://stgapi.bridge18.com/";
// const BaseURL = 'http://localhost:3000/'; // local
// const BaseURL = "https://stg.bridge18.com/";



export default BaseURL;
