/* eslint-disable */ 

import React, { Component } from 'react';
import { withRouter} from "react-router-dom";
import axios from 'axios';
import { NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import $ from 'jquery';
import BaseURL from './config';
import TokenErrorMsg from './errorMsg';




  class Customer extends Component {

  _isMounted=false;
  constructor(props){
    super(props);
    this.state = {      
      status:1,
      errorMsg: false,
      countries:[],
      states:[],
      bstates:[],     
      customerid:'',
      whileSave: false,
      addressid:'',
      billaddid:'',
      fields: {},
      errors: {},
      isLoading: false,
      token:'',     
      }
    
      this.handleChange = this.handleChange.bind(this);
      this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);
      this.submit=this.submit.bind(this);
      this.validateForm=this.validateForm.bind(this);
      this.AutoFormatContact = this.AutoFormatContact.bind(this);
    };



    

async handleChange(e) {
  let fields = this.state.fields;
  fields[e.target.name] = e.target.value;
  if(this._isMounted){
  await this.setState({
    fields
  });
}
  this.validateForm();
}

submituserRegistrationForm(e) {
  e.preventDefault();
  var send=false;
  if (this.validateAndSubmit() && this.validateForm() ) {
    
    send=true;
     
  }
  if(send)
  {
   
    this.submit();
  }
  
}


validateAndSubmit(){

  // let fields = this.state.fields;
  let errors = {};
  let formIsValid = true;

  

  // CustomerNAme
  if (!this.refs.customerName.value || !this.refs.customerName.value.trim().length>0 ) {
    formIsValid = false;
    errors["customerName"] = "*Please Enter Customer Name";
    $("[name='customerName']").focus();
   
  }

    // Address
    else if (!this.refs.address.value || !this.refs.address.value.trim().length>0) {
      formIsValid = false;
      errors["address"] = "*Please Enter Address";
      $("[name='address']").focus();
     
    }


    // Country
    else if (!this.refs.country_id.value) {
      formIsValid = false;
      errors["country"] = "*Please Select Country";
      $("[name='country']").focus();
     
    }

     // State
     else if (!this.refs.province_id.value) {
      formIsValid = false;
      errors["state"] = "*Please Select State";
      $("[name='state']").focus();
     
    }

    // City
    else  if (!this.refs.city.value || !this.refs.city.value.trim().length>0) {
      formIsValid = false;
      errors["city"] = "*Please Enter City";
      $("[name='city']").focus();
     
    }

    // Zip
    else if (!this.refs.zip.value || !this.refs.zip.value.trim().length>0) {
      formIsValid = false;
      errors["zip"] = "*Please Enter Zip";
      $("[name='zip']").focus();
     
    }




    // BillingAddress
    else if (! this.refs.billing.value || !this.refs.billing.value.trim().length>0) {
      formIsValid = false;
      errors["billingAddress"] = "*Please Enter Billing Address";
      $("[name='billingAddress']").focus();
     
    }


    // Country
    else if (!this.refs.billing_country_id.value) {
      formIsValid = false;
      errors["billingAddressCountry"] = "*Please Select Country";
      $("[name='billingAddressCountry']").focus();
     
    }

     // State
     else if (!this.refs.billing_province_id.value) {
      formIsValid = false;
      errors["billingAddressState"] = "*Please Select State";
      $("[name='billingAddressState']").focus();
     
    }

    // City
    else if (! this.refs.b_city.value || !this.refs.b_city.value.trim().length>0) {
      formIsValid = false;
      errors["billingAddressCity"] = "*Please Enter City";
      $("[name='billingAddressCity']").focus();
     
    }

    // Zip
    else if (! this.refs.b_zip.value || !this.refs.b_zip.value.trim().length>0) {
      formIsValid = false;
      errors["billingAddressZip"] = "*Please Enter Zip";
      $("[name='billingAddressZip']").focus();
     
    }
    if(this._isMounted){
        this.setState({
          errors: errors
        });
     }
    return formIsValid;
}


validateForm() {

  let fields = this.state.fields;
  let errors = {};
  let formIsValid = true;


// customerName
  if (typeof fields["customerName"] !== "undefined" && fields["customerName"]) {
  if ( fields["customerName"].length> 200) {
    formIsValid = false;
    errors["customerName"] = "*Only Alphabets Allowed (Max Range: 200)";
  }
  }

//DotNo
if (typeof fields["dotNo"] !== "undefined" && fields["dotNo"]) {
  if (!fields["dotNo"].match(/^[a-zA-Z0-9#'.,-/ ]{1,200}$/)) {
    formIsValid = false;
    errors["dotNo"] = "*Only Alpha-Numeric Allowed (Max Range: 200)";
  }
  }

  
//mcNo

if (typeof fields["mcNo"] !== "undefined" && fields["mcNo"]) {
  if (!fields["mcNo"].match(/^[a-zA-Z0-9 ]{1,200}$/)) {
    formIsValid = false;
    errors["mcNo"] = "*Only Alpha-Numeric Allowed (Max Range: 200)";
  }
  }

  //address
  
  if (typeof fields["address"] !== "undefined" && fields["address"]) {
    if (!fields["address"].match(/^[a-zA-Z0-9#'"،.,-;/ ]{1,200}$/)) {
      formIsValid = false;
      errors["address"] = "*Please Enter Only Valid Characters (Max Range: 200)";
    }
    }


    
//addressLineTwo

if (typeof fields["addressLineTwo"] !== "undefined" && fields["addressLineTwo"]) {
  if (!fields["addressLineTwo"].match(/^[a-zA-Z0-9#'"،.,-;/ ]{1,200}$/)) {
    formIsValid = false;
    errors["addressLineTwo"] = "*Please Enter Only Valid Characters (Max Range: 200)";
  }
  }
  
//city

if (typeof fields["city"] !== "undefined" && fields["city"]) {
  if (!fields["city"].match(/^[a-zA-Z0-9#., ]{1,200}$/)) {
    formIsValid = false;
    errors["city"] = "*Please Enter Only Valid Characters (Max Range: 200)";
  }
  }

  //zip

if (typeof fields["zip"] !== "undefined" && fields["zip"]) {
  if (!fields["zip"].match(/^[a-zA-Z0-9-_ ]{1,35}$/)) {
    formIsValid = false;
    errors["zip"] = "*Only Numbers Allowed in Zip (Max Range: 35)";
  }
  }

  //telephone

if (typeof fields["telephone"] !== "undefined" && fields["telephone"]) {
  if (!fields["telephone"].match(/^[0-9- ]{12,30}$/)) {
    formIsValid = false;
    errors["telephone"] = "*Please Enter only valid Telephone Number ( Range: 10)";
  }
  }

  //Ext

if (typeof fields["ext"] !== "undefined" && fields["ext"]) {
  if (!fields["ext"].match(/^[0-9]{1,20}$/)) {
    formIsValid = false;
    errors["ext"] = "*Only Numbers Allowed (Max Range: 20)";
  }
  }
  //Fax

if (typeof fields["fax"] !== "undefined" && fields["fax"]) {
  if (!fields["fax"].match(/^[0-9- ]{12,30}$/)) {
    formIsValid = false;
    errors["fax"] = "*Please Enter only valid fax Number ( Range: 10)";
  }
  }

    //email

if (typeof fields["email"] !== "undefined" && fields["email"]) {
  if (!fields["email"].match(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/)) {
    formIsValid = false;
    errors["email"] = "*Please Enter Valid Email (Max Range: 100)";
  }
  }


  

  // Billing Address Validations

  //address
  
  if (typeof this.refs.billing.value !== "undefined" && this.refs.billing.value) {
    if (!this.refs.billing.value.match(/^[a-zA-Z0-9#'"،.,-;/ ]{1,200}$/)) {
      formIsValid = false;
      errors["billingAddress"] = "*Please Enter Only Valid Characters (Max Range: 200)";
    }
    }


    
//addressLineTwo

if (typeof this.refs.billing2.value !== "undefined" && this.refs.billing2.value) {
  if (!this.refs.billing2.value.match(/^[a-zA-Z0-9#'"،.,-;/ ]{1,200}$/)) {
    formIsValid = false;
    errors["billingAddressLineTwo"] = "*Please Enter Only Valid Characters (Max Range: 200)";
  }
  }
  
//city

if (typeof  this.refs.b_city.value !== "undefined" &&  this.refs.b_city.value) {
  if (! this.refs.b_city.value.match(/^[a-zA-Z0-9#., ]{1,200}$/)) {
    formIsValid = false;
    errors["billingAddressCity"] = "*Please Enter Only Valid Characters (Max Range: 200)";
  }
  }

  //zip

if (typeof this.refs.b_zip.value !== "undefined" && this.refs.b_zip.value) {
  if (!this.refs.b_zip.value.match(/^[a-zA-Z0-9-_ ]{1,35}$/)) {
    formIsValid = false;
    errors["billingAddressZip"] = "*Only Numbers Allowed (Max Range: 35)";
  }
  }

  //telephone

if (typeof  this.refs.b_telephone.value !== "undefined" &&  this.refs.b_telephone.value) {
  if (! this.refs.b_telephone.value.match(/^[0-9- ]{12,30}$/)) {
    formIsValid = false;
    errors["billingAddressTelephone"] =  "*Please Enter only valid Telephone Number ( Range: 10)";
  }
  }

  //Ext

if (typeof this.refs.b_ext.value !== "undefined" && this.refs.b_ext.value) {
  if (!this.refs.b_ext.value.match(/^[0-9]{1,20}$/)) {
    formIsValid = false;
    errors["billingAddressExt"] = "*Only Numbers Allowed (Max Range: 20)";
  }
  }
  //Fax

if (typeof this.refs.b_fax.value !== "undefined" && this.refs.b_fax.value) {
  if (!this.refs.b_fax.value.match(/^[0-9- ]{12,30}$/)) {
    formIsValid = false;
    errors["billingAddressFax"] =  "*Please Enter only valid fax Number ( Range: 10)";
  }
  }

    //email

if (typeof this.refs.b_email.value !== "undefined" && this.refs.b_email.value) {
  if (!this.refs.b_email.value.match(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/)) {
    formIsValid = false;
    errors["billingAddressEmail"] = "*Please Enter Valid Email (Max Range: 100)";
  }
  }

  if(this._isMounted){
      this.setState({
        errors: errors
      });
   }
  return formIsValid;

}

AutoFormatContact(e){



 

  function phone_formatting(ele,restore) {
    var new_number,
        // selection_start = ele.selectionStart,
        // selection_end = ele.selectionEnd,
        number = ele.value.replace(/\D/g,'');
    
    // automatically add dashes
    if (number.length > 2) {
      // matches: 123 || 123-4 || 123-45
      new_number = number.substring(0,3) + '-';
      if (number.length === 4 || number.length === 5) {
        // matches: 123-4 || 123-45
        new_number += number.substr(3);
      }
      else if (number.length > 5) {
        // matches: 123-456 || 123-456-7 || 123-456-789
        new_number += number.substring(3,6) + '-';
      }
      if (number.length > 6) {
        // matches: 123-456-7 || 123-456-789 || 123-456-7890
        new_number += number.substring(6);
      }
    }
    else {
      new_number = number;
    }
    
    // if value is heigher than 12, last number is dropped
    // if inserting a number before the last character, numbers
    // are shifted right, only 12 characters will show
    ele.value =  (new_number.length > 12) ? new_number.substring(0,12) : new_number;
    
    // restore cursor selection,
    // prevent it from going to the end
    // UNLESS
    // cursor was at the end AND a dash was added
  
    
    // if (new_number.slice(-1) === '-' && restore === false
    //     && (new_number.length === 8 && selection_end === 7)
    //         || (new_number.length === 4 && selection_end === 3)) {
    //     selection_start = new_number.length;
    //     selection_end = new_number.length;
    // }
    // else if (restore === 'revert') {
    //   selection_start--;
    //   selection_end--;
    // }
    // ele.setSelectionRange(selection_start, selection_end);
  
  }
    
  function phone_number_check(field,e) {
    var key_code = e.keyCode,
        key_string = String.fromCharCode(key_code),
        press_delete = false,
        dash_key = 189,
        delete_key = [8,46],
        direction_key = [33,34,35,36,37,38,39,40],
        selection_end = field.selectionEnd;
    
    // delete key was pressed
    if (delete_key.indexOf(key_code) > -1) {
      press_delete = true;
    }
    
    // only force formatting is a number or delete key was pressed
    if (key_string.match(/^\d+$/) || press_delete) {
      phone_formatting(field,press_delete);
    }
    // do nothing for direction keys, keep their default actions
    else if(direction_key.indexOf(key_code) > -1) {
      // do nothing
    }
    else if(dash_key === key_code) {
      if (selection_end === field.value.length) {
        field.value = field.value.slice(0,-1)
      }
      else {
        field.value = field.value.substring(0,(selection_end - 1)) + field.value.substr(selection_end)
        field.selectionEnd = selection_end - 1;
      }
    }
    // all other non numerical key presses, remove their value
    else {
      e.preventDefault();
  //    field.value = field.value.replace(/[^0-9\-]/g,'')
      phone_formatting(field,'revert');
    }
  
  }
  
  const Validate=(e)=> {
    if( !String.fromCharCode(e.keyCode).match(/^- $/))
    {
    this.handleChange(e)
    }
  }
 
  document.getElementById(e.target.id).onkeyup = function(e) {
    phone_number_check(this,e);
    Validate(e)
  }
   

}

 async componentDidMount(){

  this._isMounted=true;
  var arr=[];
  var data=false;
  const token=localStorage.getItem('Token');
  if(this._isMounted){
  await this.setState({token:token});
  }
  await axios.post(BaseURL+'countries',{
  'token':token})
  .then(response=> {           
    if(response.data.error=== TokenErrorMsg){     
     localStorage.setItem('Token','') ;      
     this.props.history.push('/') ;       
         }
    arr = response.data.data ?response.data.data :[];
    data=true;
  }).catch(function (error) {
    NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);         
    console.log(error);
  })
  if( data && this._isMounted ){ 
    this.setState({countries:arr});        
  }        
}


async componentWillUnmount() { 
  
  this._isMounted=false;
  
  
}



async states() {
  var arr;
  var data=false;
 
  await axios.post(BaseURL+'countries/states',{
    "countryId": this.refs.country_id.value,
       'token':this.state.token
  })
  .then(response=> {           
    if(response.data.error=== TokenErrorMsg){     
     localStorage.setItem('Token','') ;      
     this.props.history.push('/') ;       
         }   
    arr = response.data.data;
    data=true;
  }).catch(function (error) {
    NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);         
    console.log(error);
  })
  // document.getElementsByClassName('state').value=1;
  if(data && this._isMounted){ 
    this.setState({states:arr});  
  }
  
}

async bstates() {
  var arr;
  var data=false;
  
  await axios.post(BaseURL+'countries/states',{
    "countryId": this.refs.billing_country_id.value,
    'token':this.state.token
  })
  .then(response=> {           
    if(response.data.error=== TokenErrorMsg){     
     localStorage.setItem('Token','') ;      
     this.props.history.push('/') ;       
         }  
    arr = response.data.data;
    data=true;
  }).catch(function (error) {
    NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);         
    console.log(error);
  })
  if(data && this._isMounted){
    // document.getElementsByClassName('billingAddressState').value=1;
    this.setState({bstates:arr});  
  }
 
}

async billaddress() {

  if(this.state.errorMsg===false) {
    document.getElementById('sameBillAddress').className='checked';

    if(this._isMounted){
    await this.setState({errorMsg:true});
    }

    var arr;
    await axios.post(BaseURL+'countries/states',{
     "countryId": this.refs.country_id.value,
     'token':this.state.token
    })
    .then(response=> {           
      if(response.data.error=== TokenErrorMsg){     
       localStorage.setItem('Token','') ;      
       this.props.history.push('/') ;       
           }   
      arr = response.data.data;  
    }).catch(function (error) {
      NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);         
      console.log(error);
    })  
    if(this._isMounted){     
    await this.setState({bstates:arr});
    }
  
    this.refs.billing.value =  this.refs.address.value;
    this.refs.billing2.value =  this.refs.address2.value;
    this.refs.billing_country_id.value =  this.refs.country_id.value;
    this.refs.billing_province_id.value =  this.refs.province_id.value;
    this.refs.b_city.value =  this.refs.city.value;
    this.refs.b_zip.value =  this.refs.zip.value;
    this.refs.b_email.value =  this.refs.email.value;
    this.refs.b_telephone.value =  this.refs.telephone.value;
    this.refs.b_fax.value=  this.refs.fax.value;
    this.refs.b_ext.value=  this.refs.ext.value;

  }  else {
    document.getElementById('sameBillAddress').className='';
    this.refs.billing.value = '';
    this.refs.billing2.value =  '';
    this.refs.billing_country_id.value =  '';
    this.refs.billing_province_id.value =  '';
    this.refs.b_city.value =  '';
    this.refs.b_zip.value =  '';
    this.refs.b_email.value =  '';
    this.refs.b_telephone.value =  '';
    this.refs.b_fax.value= '';
    this.refs.b_ext.value= '';
    if(this._isMounted){
      this.setState({
        errorMsg:false
      })
    }
  };
  

}

   async submit(){

    if(this._isMounted){
        this.setState({ 
          whileSave: true,         
        })
    }
   
    
      const customerName =  this.refs.customerName.value.trim();
      const dot_number =  this.refs.dot_number.value.trim();
      const mc =  this.refs.mc.value.trim();
      const status=this.state.status;
      const address =  this.refs.address.value.trim();
      const address2 =  this.refs.address2.value.trim();
      const country_id =  this.refs.country_id.value.trim();
      const province_id =  this.refs.province_id.value.trim();
      const city =  this.refs.city.value.trim();
      const zip =  this.refs.zip.value.trim();
      const email =  this.refs.email.value.trim();
      const telephone =  this.refs.telephone.value.trim();
      const fax =  this.refs.fax.value.trim();
      const ext =  this.refs.ext.value.trim();

      const billing =  this.refs.billing.value.trim();
      const billing2 =  this.refs.billing2.value.trim();
      const billing_country_id =  this.refs.billing_country_id.value.trim();
      const billing_province_id =  this.refs.billing_province_id.value.trim();
      const b_city =  this.refs.b_city.value.trim();
      const b_zip =  this.refs.b_zip.value.trim();
      const b_email =  this.refs.b_email.value.trim();
      const b_telephone =  this.refs.b_telephone.value.trim();
      const b_fax =  this.refs.b_fax.value.trim();
      const b_ext =  this.refs.b_ext.value.trim();
      this.resetForm();

     if(this.state.errorMsg===false){
       
       var customer={
        
      'token':this.state.token,
      'id': this.state.customerid,
       "customerName":customerName,
       "dotNo": dot_number,
       "sameAsBillingAddress": 0,
       "mcNo": mc,
       "status": status,
       "address": {
          'id':this.state.addressid,
           "address": address,
           "addressLineTwo": address2,
           "city": city,
           "zip": zip,
           "email": email,
           "telephone": telephone,
           "ext": ext,
           "fax": fax,
           "state": {
               "id": province_id,
               "country": {
                   "id": country_id
                   
               }
           }
       },
       "billingAddress": {

           'id':this.state.billaddid,          
           "address": billing,
           "addressLineTwo": billing2,
           "city": b_city,
           "zip": b_zip,
           "email": b_email,
           "telephone": b_telephone,
           "ext": b_ext,
           "fax": b_fax,
           "state": {
               "id": billing_province_id,
               "country": {
                   "id": billing_country_id
               }
           }
       }
   }
 
  

 
      await axios.post(BaseURL+'customer/save', customer )
      .then(response=> {           
        if(response.data.error=== TokenErrorMsg){     
         localStorage.setItem('Token','') ;      
         this.props.history.push('/') ;       
             }
        console.log(response.data);        
        NotificationManager.success(' Customer Added Successfully ', 'Added', 3000); 
      }).catch(function (error) {
        NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);         
        console.log(error);
      })
      
      if(this._isMounted){
          this.setState({
          
            whileSave: false,      
          
          });
     }
    
        
  }

     else {
       
      var billcustomer={
        
        'token':this.state.token,
        'id': this.state.customerid,
         "customerName":customerName,
         "dotNo": dot_number,
         "sameAsBillingAddress": 1,
         "mcNo": mc,
         "status": status,
         "address": {
            'id':this.state.addressid,
             "address": address,
             "addressLineTwo": address2,
             "city": city,
             "zip": zip,
             "email": email,
             "telephone": telephone,
             "ext": ext,
             "fax": fax,
             "state": {
                 "id": province_id,
                 "country": {
                     "id": country_id
                     
                 }
             }
         },
         "billingAddress": {
  
             'id':this.state.billaddid,          
             "address": billing,
             "addressLineTwo": billing2,
             "city": b_city,
             "zip": b_zip,
             "email": b_email,
             "telephone": b_telephone,
             "ext": b_ext,
             "fax": b_fax,
             "state": {
                 "id": billing_province_id,
                 "country": {
                     "id": billing_country_id
                 }
             }
         }
     }
   
 
  

       
   await axios.post(BaseURL+'customer/save', billcustomer )
    .then(response=> {           
      if(response.data.error=== TokenErrorMsg){     
       localStorage.setItem('Token','') ;      
       this.props.history.push('/') ;       
           }
      console.log(response.data);       
     
      NotificationManager.success('Customer Added Successfully', 'Added', 3000);
    }).catch(function (error) {
      NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);         
      console.log(error);
    })
    
    if(this._isMounted){
    this.setState({       
        whileSave: false
    })
     }   
      
   }
 
  }


  async status (event) {
    if(this._isMounted){
    await this.setState({status:0});   
    }  
  }
   async Actstatus (event) {
    if(this._isMounted){
    await this.setState({status:1}); 
    }    
  }

  resetForm=()=>{
    $('#closePopUpCustomer').click();
    this.refs.customerform.reset();
    this.setState({states:[],bstates:[],status:1});
    document.getElementById('sameBillAddress').className='';
    $("#customerActiveLabel").addClass('active'); 
    $("#customerNonActiveLabel").removeClass('active'); 

  }

  render() {

    

    
    return (
        <div className="modal-content">
         <div className="modal-header">        
         <button type="button" className="close"  onClick={this.resetForm} aria-hidden="true"></button>
           <h4 className="modal-title">Customer Info</h4>
         </div>
                <div className="modal-body">     
                      <div className="portlet-body form"> 
                       <button type="button"  id="closePopUpCustomer" data-dismiss="modal" aria-hidden="true" hidden={true}></button>
                        <form onSubmit={this.submituserRegistrationForm.bind(this)} className="horizontal-form" autoComplete="off"  ref='customerform'>
                          <div className="form-body max_width800">
                            <div className="portlet-title">
                              <h2 className="h3"> Customer Info 
                              <span style={{float: 'right', paddingLeft:' 8px', marginTop: '2px'}} >
                              <div className="btn-group btn-group-devided" data-toggle="buttons" >
                                <label className="btn btn-transparent dark btn-outline btn-circle btn-sm active" id="customerActiveLabel"  onClick={this.Actstatus.bind(this)}>
                                  <input type="radio" name="options" className="toggle"    />
                                  Active</label>
                                <label className="btn btn-transparent dark btn-outline btn-circle btn-sm" id="customerNonActiveLabel"  onClick={this.status.bind(this)}>
                                  <input type="radio" name="options"  className="toggle"  />
                                  Inactive</label>
                              </div>
                              </span>
                              </h2>
                              
                              <div className="clearfix"></div>
                              <hr/>
                            </div>
                            <div id ="abcc" className="form-group row">
                              <label className="col-md-3 control-label">Customer Name <span className="font-red-thunderbird" >*</span></label>
                              <div className="col-md-9">
                              <input type="text" ref="customerName" onChange={this.handleChange} name="customerName"     className="form-control" placeholder="" />
                              <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.customerName}</div>

                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-md-3 control-label">Dot Number</label>
                              <div className="col-md-9">
                              <input type="text" ref="dot_number"  onChange={this.handleChange} name="dotNo" className="form-control" placeholder="" />
                              <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.dotNo}</div>
                              </div>
                            
                            </div>
    
                            <div className="form-group row">
                              <label className="col-md-3 control-label">M.C. # / F.F. #</label>
                              <div className="col-md-9">
                              <input type="text" ref="mc" name='mcNo'  onChange={this.handleChange}  className="form-control" placeholder="" />
                              <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.mcNo}</div>
                              </div>
                            </div>
                            <div className="portlet-title">
                              <h2 className="h3"> Address Info </h2>
                              <hr/>
                            </div>
                            <div className="form-group row">
                              <label className="col-md-3 control-label">Address <span className="font-red-thunderbird" >*</span></label>
                              <div className="col-md-9">
                              <input type="text" ref="address"  onChange={this.handleChange} name="address"  className="form-control" placeholder=""   />
                              <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.address}</div>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-md-3 control-label">Address Line 2</label>
                              <div className="col-md-9">
                              <input type="text" ref="address2"  onChange={this.handleChange} name="addressLineTwo"  className="form-control" placeholder="" />
                              <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.addressLineTwo}</div>
                              </div>
                            </div>
                          
                            <div className="form-group row">
                              <label className="col-md-3 control-label">Country <span className="font-red-thunderbird" >*</span></label>
                              <div className="col-md-9">

                              
                                <select   ref="country_id" name="country" className="form-control" title="Country" defaultValue='' onChange={this.states.bind(this) }    >

                                 <option value=""  disabled hidden>Select Country</option>                              
                                {
                                  this.state.countries.map(function (data) {
                                    return <option value={data.id} key={data.id}> {data.countryName} </option>;
                                  })

                                }
                                 
                                  
                                  
                                 
                                </select>
                                <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.country}</div>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-md-3 control-label">State <span className="font-red-thunderbird" >*</span></label>
                              <div className="col-md-9">
                              <select    ref="province_id" name="state" className="form-control" defaultValue='1'      >
                                                         
                                {
                                  this.state.states.map(function (data) {
                                    return <option value={data.id} key={data.id}> {data.state} </option>;
                                  })

                                }
                              </select>
                              <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.state}</div>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-md-3 control-label">City <span className="font-red-thunderbird" >*</span></label>
                              <div className="col-md-9">
                              <input type="text" ref="city"  onChange={this.handleChange} name="city"  className="form-control" placeholder=""   />
                              <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.city}</div>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-md-3 control-label">Zip <span className="font-red-thunderbird" >*</span></label>
                              <div className="col-md-9">
                              <input type="text" ref="zip"  onChange={this.handleChange} name="zip"  className="form-control" placeholder=""   />
                              <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.zip}</div>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-md-3 control-label">Email</label>
                              <div className="col-md-9">
                              <input type="email" ref="email"   onChange={this.handleChange} name="email" className="form-control" placeholder="" />
                              <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.email}</div>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-md-3 control-label">Telephone</label>
                              <div className="col-md-9 form-inline">
                              <input type="text" ref="telephone"  id='Telephone' onChange={this.AutoFormatContact} name="telephone"  className="form-control" placeholder="xxx-xxx-xxxx" />
                              
                              <span className="help-inline"> EXT </span>  <input type="text" name="ext"  onChange={this.handleChange}  ref="ext" className="form-control" placeholder="" />
                              <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.telephone}</div>
                              <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.ext}</div>
                              
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-md-3 control-label">Fax</label>
                              <div className="col-md-9">
                              <input type="text" ref="fax" name="fax"  id='fax' onChange={this.AutoFormatContact}  className="form-control" placeholder="xxx-xxx-xxxx" />
                              <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.fax}</div>
                              </div>
                            </div>
                            <div className="portlet-title">
                              <h2 className="h3 checkbox-inline" style={{padding: '0',margin: '0'}}> Billing Address  </h2>
                              {/* <label className="checkbox-inline" id="sameBillAddress">
                              <div className="checkbox"><span>
                                <input type="checkbox"  value='same billing'   />
                                </span> Same as Mailing Address</div>
                              </label> */}
                              <label className="checkbox-inline" >
                              <div className="checkbox">
                                <span>  <div className="checker" >
                                <span className='' id='sameBillAddress'><input type="checkbox"  onClick={this.billaddress.bind(this)}/>   </span>
                                </div> </span>
                                Same as Mailing Address</div></label>
                              <hr/>
                            </div>


                            <span >
                            <div className="form-group row">
                              <label className="col-md-3 control-label">Address <span className="font-red-thunderbird" >*</span></label>
                              <div className="col-md-9">
                              <input type="text" ref="billing"  onChange={this.handleChange} name="billingAddress"  className="form-control" placeholder=""   />
                              <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.billingAddress}</div>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-md-3 control-label">Address Line 2 </label>
                              <div className="col-md-9">
                              <input type="text"   onChange={this.handleChange} name="billingAddressLineTwo"  ref="billing2" className="form-control" placeholder="" />
                              <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.billingAddressLineTwo}</div>
                              </div>
                            </div>
                         
                            <div className="form-group row"> 
                              <label className="col-md-3 control-label">Country <span className="font-red-thunderbird" >*</span></label>
                              <div className="col-md-9">
                              <select  name="billingAddressCountry" ref="billing_country_id"  className="form-control" title="Country" defaultValue='' onChange={this.bstates.bind(this)}    >
                              <option value=""  disabled hidden>Select Country</option>                              
                                {
                                  this.state.countries.map(function (data) {
                                    return <option value={data.id} key={data.id}> {data.countryName} </option>;
                                  })

                                }
                              </select>
                              <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.billingAddressCountry}</div>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-md-3 control-label">State <span className="font-red-thunderbird" >*</span></label>
                              <div className="col-md-9">
                              <select   name="billingAddressState" ref="billing_province_id" defaultValue='1' className="form-control"     >
                                                       
                                {
                                  this.state.bstates.map(function (data) {
                                    return <option value={data.id} key={data.id}> {data.state} </option>;
                                  })

                                }
                              </select>
                              <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.billingAddressState}</div>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-md-3 control-label">City <span className="font-red-thunderbird" >*</span></label>
                              <div className="col-md-9">
                              <input type="text" ref="b_city"  onChange={this.handleChange} name="billingAddressCity"  className="form-control" placeholder=""   />
                              <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.billingAddressCity}</div>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-md-3 control-label">Zip <span className="font-red-thunderbird" >*</span></label>
                              <div className="col-md-9">
                              <input type="text" ref="b_zip"  onChange={this.handleChange} name="billingAddressZip"  className="form-control" placeholder=""   />
                              <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.billingAddressZip}</div>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-md-3 control-label">Email</label>
                              <div className="col-md-9">
                              <input type="email" ref="b_email"  onChange={this.handleChange} name="billingAddressEmail" className="form-control" placeholder="" />
                              <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.billingAddressEmail}</div>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-md-3 control-label">Telephone</label>
                              <div className="col-md-9 form-inline">
                              <input type="text"   id='b_Telephone' onChange={this.AutoFormatContact} name="billingAddressTelephone" ref="b_telephone"  className="form-control" placeholder="xxx-xxx-xxxx" />
                              
                              <span className="help-inline"> EXT </span>  <input name="billingAddressExt"  onChange={this.handleChange} type="text" ref="b_ext" className="form-control" placeholder="" />
                              <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.billingAddressTelephone}</div>
                              <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.billingAddressExt}</div>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-md-3 control-label">Fax</label>
                              <div className="col-md-9">
                              <input type="text" ref="b_fax"  id='b_fax' onChange={this.AutoFormatContact} name="billingAddressFax"  className="form-control" placeholder="xxx-xxx-xxxx" />
                              <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.billingAddressFax}</div>
                              </div>
                            </div>
                            </span>
                           
                          </div>
                          <div className="clearfix"></div>
                          <div className="form-actions right">
                            <button onClick={this.resetForm}  type="button" className="btn default">Cancel</button> &nbsp;
                            <button   type="submit" className="btn green-meadow">
                              {this.state.whileSave === false 
                                  ? <i className='fa fa-save'></i> 
                                  : <i className="fa fa-circle-o-notch fa-spin"></i>}                              
                              Save 
                            </button>
                          </div>
                            
                        </form>
                    </div>
                </div>
              </div>
                   

    );
}
}


export default withRouter(Customer);