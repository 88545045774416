/* eslint-disable */ 

import React, { Component } from 'react';
import { withRouter} from "react-router-dom";
import axios from 'axios';
import { NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Sidebar from './sidebar';
import Header from'./Head';
import $ from 'jquery';
import BaseURL from './config';
import TokenErrorMsg from './errorMsg';

import  getEditinfo    from './StartEditMode';
import  finishEditinfo    from './FinishEditMode';



  class EditUser extends Component {
  _isMounted=false
  constructor(props){
    super(props);
    this.state = {
      status:1,           
      userid:'',
      whileSave: false,     
      readonly:true,
      hidden:false,         
      isLoading: false,
      token:'',
      fields: {},
      errors: {},
      type:[]    ,
      UserFormData:{}
       
     

    };
    this.handleChange = this.handleChange.bind(this);
    this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);
    this.submit=this.submit.bind(this);
    this.validateForm=this.validateForm.bind(this); 
}

onUnload=  (event) =>{ 
  
  event.preventDefault();  
  var http = new XMLHttpRequest();
   
 var url = BaseURL+'editModeInfo/finishEditMode';
 
 var params = "usersManagementId="+this.state.userid+"&token="+this.state.token;
 
 http.open("POST", url, true);
 
 http.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
 
 
 if(this.state.userid){
  http.send(params);
  var start = new Date().getTime();
    while (new Date().getTime() < start + 350);  
 }
 
    
   
 }
 
 
 
 async componentDidMount(){
  this._isMounted=true;
   window.addEventListener("beforeunload", this.onUnload)  
  var token=localStorage.getItem('Token');
  const id=this.props.match.params.id;

  if(this._isMounted){
   this.setState({'token':token,userid:id});
  }

  

  await axios.post(BaseURL+'users/findById',{"id":id ,  'token':token})
          .then(response=> {
              console.log(response.data);
            
              var  role = response.data.data.userRole;
              var formdata =response.data.data.users ;
              if(formdata.status === 0){
                $("#inactiveLabel").addClass('active');
                  }
                else{
                 $("#activeLabel").addClass('active');
                }
                delete role[2];
                if(this._isMounted){
                 this.setState({type:role,UserFormData:formdata}); 
                }
          
          }).catch(function (error) {
            NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);         
            console.log(error);
          })
        
          
               
         

       
        
}

componentWillUnmount() {
  this._isMounted=false;
  window.removeEventListener("beforeunload", this.onUnload);
  var config={
    "usersManagementId": this.state.userid, 
    "token":this.state.token
   }
  if(config.usersManagementId){
   finishEditinfo(config, (res) => {
    console.log(res);
   
},(err) => {
   
  console.log(err)
});
}

  
}

async handleChange(e) {
  let fields = this.state.fields;
  fields[e.target.name] = e.target.value;
  if(this._isMounted){
  await this.setState({
    fields
  });
}
  this.validateForm();
}

submituserRegistrationForm(e) {
  e.preventDefault();
  var send=false;
 
  if (this.validateAndSubmit()  && this.validateForm()  ) {
    
    send=true;
     
  }
  if(send)
  {
   
    this.submit();
  }
  
}

validateAndSubmit(){

  // let fields = this.state.fields;
  let errors = {};
  let formIsValid = true;

 
  if (!this.refs.firstname.value || !this.refs.firstname.value.trim().length>0) {
    formIsValid = false;
    errors["firstname"] = "*Please Enter First Name";
    $("[name='firstname']").focus();
   
  }
 else if (!this.refs.lastname.value || !this.refs.lastname.value.trim().length>0) {
    formIsValid = false;
    errors["lastname"] = "*Please Enter Last Name";
    $("[name='lastname']").focus();
   
  }

  
  else if (!this.refs.type.value) {
    formIsValid = false;
    errors["type"] = "*Please Select User Type";
    $("[name='type']").focus();
   
  }
  else if (!this.refs.email.value ) {
    formIsValid = false;
    errors["email"] = "*Please Enter Email";
    $("[name='email']").focus();
   
  }
  else if (!this.refs.password.value || !this.refs.password.value.trim().length>0) {
    formIsValid = false;
    errors["password"] = "*Please Enter Password";
    $("[name='password']").focus();
   
  }
  else if (!this.refs.Repassword.value || !this.refs.Repassword.value.trim().length>0) {
    formIsValid = false;
    errors["Repassword"] = "*Please Enter Re-Password";
    $("[name='Repassword']").focus();
   
  }
 
  if(this._isMounted){
    this.setState({
      errors: errors
    });
  }
    return formIsValid;
}



validateForm() {

  let fields = this.state.fields;
  let errors = {};
  let formIsValid = true;



    if (typeof fields["firstname"] !== "undefined" && fields["firstname"]) {
    if (!fields["firstname"].match(/^[a-zA-Z0-9#'".,-/ ]{1,30}$/)) {
        formIsValid = false;
        errors["firstname"] = "*Only Alphanumeric Allowed (Max Range: 30)";
    }
    }
    if (typeof fields["lastname"] !== "undefined" && fields["lastname"]) {
        if (!fields["lastname"].match(/^[a-zA-Z0-9#'".,-/ ]{1,30}$/)) {
            formIsValid = false;
            errors["lastname"] = "*Only Alphanumeric Allowed (Max Range: 30)";
        }
        }

        
          
        
    if (typeof fields["email"] !== "undefined" && fields["email"]) {
      
        if (!fields["email"].match(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/)) {
            formIsValid = false;
            errors["email"] = "*Please Enter Valid Email ";
        } 
        }
        if (typeof fields["password"] !== "undefined" && fields["password"]) {
          if (!fields["password"].match(/^[A-Za-z\d!$%@#£€*?&., ]{4,15}$/)) {
            formIsValid = false;
            errors["password"] = "*Minimum four characters (max: 15)";
          }
          }
    if (typeof fields["Repassword"] !== "undefined" && fields["Repassword"] ) {
        if (fields["Repassword"] !== fields["password"]) {
            formIsValid = false;
            errors["Repassword"] = "Please Match Password";
        }
        }    

  if(this._isMounted){   
  this.setState({
    errors: errors
  });
}
  return formIsValid;


}

EditForm(){

  var div = document.getElementById('EditDiv');   
  div.setAttribute("style"," cursor: not-allowed; pointer-events: none")
  
  var config={
    "usersManagementId": this.state.userid,   
    "token":this.state.token
   }

   getEditinfo(config, (res) => {
    console.log(res);
    if(res.data.status === 0){
      if(this._isMounted){
       this.setState({readonly:false});
      }
    }
    else {
      div.removeAttribute("style")
      NotificationManager.warning(res.data.message, 'Warning Message', 3000);   
    }
},(err) => {
   
  console.log(err)
}); 

}



  async cancel(){
   if(window.confirm(' Are you sure you want to cancel ? '))
   this.props.history.push('/Admin');
 
  }


   async submit(){
    if(this._isMounted){
     this.setState({ whileSave: true ,hidden:false,readonly:true})  
    }
   
     const firstname =  this.refs.firstname.value.trim();
     const lastname =  this.refs.lastname.value.trim();
     const type =  this.refs.type.value;
     const email=this.refs.email.value.trim();
     const password=this.refs.password.value;     

     
       var user={
      'token':this.state.token,
      'id': this.state.userid,
      'status':this.state.status,
      "firstName":firstname ,
      "lastName":lastname ,
      "email":email,
      "password":password ,     
      "userRoleId": type,
      
   }
   
   console.log(user);
 
   if(this.state.userid){


    await axios.post(BaseURL+'users/update', user )
           .then(response=> {           
            if(response.data.error=== TokenErrorMsg){     
             localStorage.setItem('Token','') ;      
             this.props.history.push('/') ;       
                 }
            else if(response.data.status === 1)
            {
              NotificationManager.error(response.data.message, 'Error', 3000);
              return; 
            }
            else if(response.data.status === 0){
              NotificationManager.error(response.data.message, 'Error', 3000);
              return; 
            }
            else if(response.data.error === "User Already Exist with this email"){
              NotificationManager.error('User Already Exist With this Email Address', 'Error', 3000);
              return;
              }
             console.log(response.data);
             NotificationManager.success('User Upated Successfully', 'Edited', 3000);
           }).catch(function (error) {
            NotificationManager.error('Something Went Wrong Server Is Not Responding ', 'Error Message', 3000);         
            console.log(error);
          })
   if(this._isMounted){
     this.setState({
       whileSave: false
     })
    }


  }
   
        
}

setSelectOption(e){

  e.preventDefault();
  var formData = this.state.UserFormData;
  var value=e.target.value;
 
   if(this._isMounted ){
    formData['userRoleId'] = value;
    this.setState({UserFormData:formData});
  }

  
}

  render() {

  
    const msgShow = {
      display: 'block',
    }

  const nonclick={
    cursor:' notAllowed',
    pointerEvents: 'none'
  } 
 
   
    
    return (
      <div className="page-container-bg-solid page-header-fixed page-sidebar-closed-hide-logo page-md">
       <Header title='| User' />
     <div className="page-container"> 
       <Sidebar/>
        <div className="page-content-wrapper"> 
       
        <div className="page-content"> 
         
          <div className="page-head"> 
          
          
          
          </div>
        
        
        
          <div className="row">
            <div className="col-md-12">
           
            {/* <div className="tabbable-line boxless  tabbable-reversed"> */}
           
            {/* <ul className="nav nav-tabs"  hidden={this.state.hidden}>
              <li className="active border-green-jungle"> <a href="#tab_0" data-toggle="tab"> Truck </a> </li>
              <li className="border-green-jungle"> <a href="#tab_1" data-toggle="tab" > Documents </a> </li>
            </ul> */}
            <div className="tab-content">
              <div className="tab-pane active" id="tab_0">
            
                <div className="portlet box yellow-gold">
                      <div className="portlet-title">
                        <div className="caption"> <i className="fa fa-user"></i>Update User </div>
                        <div className="actions" style={this.state.readonly?{}:nonclick} id='EditDiv'>
                          <div className="btn-group" >
                              <button className="btn sbold white dark"  onClick={this.EditForm.bind(this)}  hidden={this.state.hidden} disabled ={this.state.hidden}>
                                  <i className="fa fa-pencil"></i> Edit
                              </button>
                          </div>
                        </div>
                      </div>
                      <div className="portlet-body form"> 
                      
                        <form  onSubmit={this.submituserRegistrationForm.bind(this)} className="horizontal-form" autoComplete="off" >
                          <div className="form-body max_width800">
                            <div className="portlet-title">
                              <h2 className="h3"> User Info 
                              <span style={{float: 'right', paddingLeft:' 8px', marginTop: '6px'}} >
                              <div className="btn-group btn-group-devided" data-toggle="buttons" style={this.state.readonly?nonclick:msgShow} >
                                <label className="btn btn-transparent dark btn-outline btn-circle btn-sm " id='activeLabel' disabled={this.state.readonly} onClick={()=>{if(this._isMounted){this.setState({status:1})}}}>
                                  <input type="radio" name="options" className="toggle" id="option1"   />
                                  Active</label>
                                <label className="btn btn-transparent dark btn-outline btn-circle btn-sm" id='inactiveLabel' disabled={this.state.readonly} onClick={()=>{if(this._isMounted){this.setState({status:0})}}}>
                                  <input type="radio" name="options"  className="toggle"  id="option2" />
                                  Inactive</label>
                              </div>
                              </span>
                               </h2>
                              
                              <div className="clearfix"></div>
                              <hr/>
                            </div>               
                                             
                            

                           <div className="form-group row">
                              <label className="col-md-3 control-label">First Name: <span className="font-red-thunderbird" >*</span></label>
                              <div className="col-md-9">
                              <input type="text" ref="firstname" onChange={this.handleChange} name="firstname" defaultValue={this.state.UserFormData.firstName} className="form-control" placeholder=""   readOnly={this.state.readonly}/>
                              <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.firstname}</div>
                              </div>
                            </div>

                            

                            <div className="form-group row">
                              <label className="col-md-3 control-label">Last Name: <span className="font-red-thunderbird" >*</span></label>
                              <div className="col-md-9">
                              <input type="text" ref="lastname" onChange={this.handleChange} name="lastname" defaultValue={this.state.UserFormData.lastName} className="form-control" placeholder=""   readOnly={this.state.readonly}/>
                              <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.lastname}</div>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-md-3 control-label">User Type <span className="font-red-thunderbird" >*</span></label>
                              <div className="col-md-9">
                              <select name="type" ref="type" className="form-control" value={this.state.UserFormData.userRoleId}  onChange={this.setSelectOption.bind(this)} disabled={this.state.readonly} readOnly={this.state.readonly}>
                                                          
                                {
                                  this.state.type.map(function (data) {
                                    // if(this.state.UserFormData.userRoleId === data.id)
                                    // return <option value={data.id} selected> {data.userRole} </option>;
                                    return <option value={data.id} key={data.id}> {data.userRole} </option>;
                                  },this)

                                }
                              </select>
                              <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.type}</div>
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-md-3 control-label">Email <span className="font-red-thunderbird" >*</span></label>
                              <div className="col-md-9">
                              <input type="email" ref="email" onChange={this.handleChange} name="email" defaultValue={this.state.UserFormData.email} className="form-control" placeholder=""   readOnly={this.state.readonly}/>
                              <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.email}</div>
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-md-3 control-label">Password <span className="font-red-thunderbird" >*</span></label>
                              <div className="col-md-9">
                              <input type="password" ref="password" onChange={this.handleChange} name="password" defaultValue={this.state.UserFormData.password} className="form-control" placeholder=""   readOnly={this.state.readonly}/>
                              <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.password}</div>
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-md-3 control-label">Re-Password <span className="font-red-thunderbird" >*</span></label>
                              <div className="col-md-9">
                              <input type="password" ref="Repassword" onChange={this.handleChange} name="Repassword" defaultValue={this.state.UserFormData.password}  className="form-control" placeholder=""   readOnly={this.state.readonly}/>
                              <div style= {{color: 'red'}} className="errorMsg">{this.state.errors.Repassword}</div>
                              </div>
                            </div>

                           
                          </div>      
                          <div className="clearfix"></div>
                          <div className="form-actions right">
                            <button  onClick={ this.cancel.bind(this)} type="button" className="btn default">Cancel</button> &nbsp;
                            <button  disabled={this.state.readonly} type="submit" className="btn green-meadow">
                              {this.state.whileSave === false 
                                  ? <i className='fa fa-save'></i> 
                                  : <i className="fa fa-circle-o-notch fa-spin"></i>}                              
                              Save 
                            </button>
                          </div>
                           
                        </form>
                    </div>
                     </div>
                     </div>                   
          

 
                     </div>
                     </div>
                     </div>



                   
                 
            </div>
          </div>
        
        </div>

        
      
      </div>
   


    

    );
}
}

export default withRouter(EditUser);