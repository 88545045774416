var defaultState = {
    customer: {
        order: 'ASC',
        sortByColumn: 'customerName',
        btnVal: "Show All",
        status: 2,
        search: '',
        page: 0,
        offset: 0,
        limit: 50,
    },
    carrier: {
        order: 'ASC',
        sortByColumn: 'carrierName',
        btnVal: "Show All",
        status: 2,
        search: '',
        page: 0,
        offset: 0,
        limit: 50,
    },
    insuranceAgent: {
        order: 'ASC',
        sortByColumn: 'companyName',
        search: '',
        page: 0,
        offset: 0,
        limit: 50,
    },
    driver: {
        order: 'ASC',
        sortByColumn: 'firstName',
        btnVal: "Show All",
        status: 2,
        search: '',
        page: 0,
        offset: 0,
        limit: 50,
    },
    truck: {
        order: 'ASC',
        sortByColumn: 'truckNo',
        btnVal: "Show All",
        status: 2,
        search: '',
        page: 0,
        offset: 0,
        limit: 50,
    },
    trailer: {
        order: 'ASC',
        sortByColumn: 'trailerNo',
        btnVal: "Show All",
        status: 2,
        search: '',
        page: 0,
        offset: 0,
        limit: 50,
    },
    tenantUser: {
        order: 'ASC',
        sortByColumn: 'firstName',
        btnVal: "Show All",
        status: 2,
        search: '',
        page: 0,
        offset: 0,
        limit: 50,
    },
    user: {
        order: 'ASC',
        sortByColumn: 'firstName',
        btnVal: "Show All",
        status: 2,
        search: '',
        page: 0,
        offset: 0,
        limit: 50,
    },
    shipper: {
        order: 'ASC',
        sortByColumn: 'name',
        search: '',
        page: 0,
        offset: 0,
        limit: 50,
    },
    factoringCompany: {
        order: 'ASC',
        sortByColumn: 'companyName',
        search: '',
        page: 0,
        offset: 0,
        limit: 50,
    },
    load: {
        order: 'ASC',
        sortByColumn: 'internalLoadNo',
        btnVal: "Show All",
        status: '',
        search: '',
        page: 0,
        offset: 0,
        limit: 50,
        startDate: null,
        endDate: null
    },
    accounting: {
        order: 'ASC',
        sortByColumn: 'internalLoadNo',
        btnVal: "Show All",
        status: '',
        search: '',
        page: 0,
        offset: 0,
        limit: 50,
        startDate: null,
        endDate: null
    },
    billing: {
        order: 'ASC',
        sortByColumn: 'internalLoadNo',
        btnVal: "Show All",
        status: '',
        search: '',
        page: 0,
        offset: 0,
        limit: 50,
        startDate: null,
        endDate: null
    },
    searchLoad: {
        order: 'ASC',
        sortByColumn: 'internalLoadNo',
        page: 0,
        offset: 0,
        limit: 50,
        address: '',
        addressLatLng: {},
        destAddress: '',
        destAddressLatLng: {},
        radius: '150',
        destRadius: '150'
    },
};

export function getPersistentTableState(tableName) {
    let persistentTableData = JSON.parse(localStorage.getItem('tablesState'));
    let tableData = (persistentTableData && persistentTableData[tableName]) ? persistentTableData[tableName] : defaultState[tableName];

    return tableData;
}

export function setPersistentTableState(tableName, tableAttributes) {
    let persistentTableData = JSON.parse(localStorage.getItem('tablesState'));
    let tableData = persistentTableData || defaultState;

    tableData[tableName] = tableAttributes;
    localStorage.setItem('tablesState', JSON.stringify(tableData));
}