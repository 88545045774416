/* eslint-disable */

import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Map from "./Map";
import MUIDataTable from "mui-datatables";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import timezone from "node-google-timezone";
import moment from "moment-timezone";
import "moment-timezone";
import "react-notifications/lib/notifications.css";
import Sidebar from "./sidebar";
import Header from "./Head";
import $ from "jquery";
import BaseURL from "./config";
import TokenErrorMsg from "./errorMsg";
import Select from "react-virtualized-select";
import DatePicker from "react-datepicker";
import CurrencyInput from "react-currency-input";
// import moment from "moment";
import tenantUser from "./TenantUserPermision";
import tenantSubRole from "./TenantUserSubRolePermission";
import Customer from "./LoadCustomer";
import AddShipper from "./LoadShipper";
import Addcarrier from "./LoadCarrier";
import DispatchTab from "./DispatchTab";
import InvoiceTab from "./InvoiceTab";
import "./tabID.css";
import getEditinfo from "./StartEditMode";
import finishEditinfo from "./FinishEditMode";
import currencyFormatter from "currency-formatter";
import { Button, Modal, Form } from "react-bootstrap";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import * as Scroll from "react-scroll";
const optionsDropdown = [
  { label: "Line Haul", value: 1 },
  { label: "TONU", value: 2 },
];
const optionsUsersList = [];

const columnsTable = ["User Name", "Action", "Type", "Time", "IP Address"];
const dataTable = [["Joe James", "Test Corp", "Yonkers", "NY", "192.9982"]];
const optionsTable = {
  selectableRows: false,
};

function isDST(d) {
  let jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
  let jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
  return Math.max(jan, jul) != d.getTimezoneOffset();
}
function getTimeZone() {
  var offset = new Date().getTimezoneOffset(),
    o = Math.abs(offset);
  return (
    (offset < 0 ? "+" : "-") +
    ("00" + Math.floor(o / 60)).slice(-2) +
    ":" +
    ("00" + (o % 60)).slice(-2)
  );

  // var d = new Date();
  // var offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
  // var timezone = (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2);

  // if (timezone < 0) {
  //   let tz = timezone.replace("-", "");
  //   if (isDST(d)) {
  //     timezone = parseInt(tz) + 1;
  //     timezone = '-0' + timezone;
  //   }
  // } else {
  //   let tz = timezone;
  //   if (isDST(d)) {
  //     timezone = parseInt(tz) + 1;
  //   }

  // }
  // return timezone + ":" + ("00" + (o % 60)).slice(-2);
}

const DragHandle = sortableHandle(() => (
  <button
    type="button"
    style={{
      background: "white",
      border: "none",
      focus: {
        border: "none",
      },
    }}
  >
    <i className="fa fa-sort"></i>
  </button>
));

const SortableItem = sortableElement(
  ({
    value,
    stopNo,
    props,
    readonly,
    indexValue,
    totalStops,
    stopDeleteHandler,
    sortUpHandler,
    sortDownHandler,
    dispatchedStops,
    address,
  }) => {
    const nonclick = {
      cursor: " notAllowed",
      pointerEvents: "none",
    };
    let foundStop = dispatchedStops.find((stop) => stop == stopNo);
    let isDispatched = foundStop === undefined ? false : true;

    return (
      <li style={{ display: "block" }}>
        <>
          <div style={{ marginBottom: "0px" }} className="form-group row">
            <div className="col-md-4 mt-1 d-flex">
              {/* <DragHandle /> */}

              {/* {indexValue === 0 ? (
                <span style={{ width: "34px" }}></span>
              ) : (
                <button
                  type="button"
                  className="no-focus"
                  style={{
                    background: "transparent",
                    marginLeft: "2px",
                    border: "none",
                  }}
                  onClick={() => sortUpHandler(indexValue)}
                >
                  <i className="fa fa-sort-up"></i>
                </button>
              )} */}
              {/* {indexValue === totalStops - 1 ? (
                <span style={{ width: "34px" }}></span>
              ) : (
                <button
                  type="button"
                  className="no-focus"
                  style={{
                    background: "transparent",
                    marginLeft: "2px",
                    border: "none",
                  }}
                  onClick={() => sortDownHandler(indexValue)}
                >
                  <i
                    style={{
                      transform: "translateY(-6px)",
                    }}
                    className="fa fa-sort-down"
                  ></i>
                </button>
              )} */}
              <span>{value}</span>
              <button
                className="no-focus"
                type="button"
                onClick={() => {
                  document.getElementById(
                    `item-${props.stopNo}`
                  ).style.display =
                    document.getElementById(`item-${props.stopNo}`).style
                      .display === ""
                      ? "none"
                      : "";
                }}
                style={{
                  background: "transparent",
                  marginLeft: "2px",
                  border: "none",
                }}
              >
                <i
                  className={
                    document.getElementById(`item-${props.stopNo}`) === null ||
                    document.getElementById(`item-${props.stopNo}`).style
                      .display === ""
                      ? "fa fa-expand"
                      : "fa fa-compress"
                  }
                ></i>
              </button>
              {/* {isDispatched === true ? (
                <button
                  data-toggle="tooltip"
                  title="This stop is dispatched"
                  type="button"
                  className="no-focus"
                  style={{
                    background: "transparent",
                    marginLeft: "2px",
                    border: "none",
                    cursor: "unset",
                  }}
                >
                  <i className="fa fa-paper-plane"></i>
                </button>
              ) : (
                ""
              )} */}
            </div>
            {/* <div className="col-md-6 d-flex"> */}
            {/* <span
                style={{
                  marginTop: "2px",
                  marginTop: "6px",
                  position: "relative",
                  zIndex: 0,
                }}
              >
                <div
                  className="btn-group btn-group-devided"
                  data-toggle="buttons"
                  style={readonly ? nonclick : {}}
                >
                  {props.stopType === "pickup" ? (
                    <label
                      className={"btn btn-success btn-sm btn-sm active"}
                      disabled={readonly}
                    >
                      <input
                        type="radio"
                        name="options"
                        className="toggle"
                        id="option1"
                      />
                      &nbsp;PICKUP&nbsp;
                    </label>
                  ) : (
                    ""
                  )}
                  {props.stopType === "delivery" ? (
                    <label
                      className={"btn btn-success btn-sm btn-sm active"}
                      disabled={readonly}
                    >
                      <input
                        type="radio"
                        name="options"
                        className="toggle"
                        id="option2"
                      />
                      DELIVERY
                    </label>
                  ) : (
                    ""
                  )}{" "}
                  {props.stopType === "transit" ? (
                    <label
                      className={"btn btn-success btn-sm btn-sm active"}
                      disabled={readonly}
                    >
                      <input
                        type="radio"
                        name="options"
                        className="toggle"
                        id="option2"
                      />
                      Transit
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              </span> */}
            {/* <span
                style={{
                  marginTop: "10px",
                  marginLeft: "10px",
                  position: "relative",
                  zIndex: 0,
                }}
              >
                Address: {address}{" "}
              </span> */}
            {/* </div> */}
            {/* <div className="col-md-2 text-right"> */}
            {/* {props.stopNo !== 1 && props.stopNo !== 2 ? (
                <button
                  onClick={() => stopDeleteHandler(indexValue)}
                  type="button"
                  className="btn btn-danger danger btn-outline btn-circle btn-"
                  disabled={readonly}
                >
                  DELETE Stop
                </button>
              ) : null} */}
            {/* </div> */}
          </div>
        </>
      </li>
    );
  }
);

const SortableContainer = sortableContainer(({ children }) => {
  return <ul style={{ paddingInlineStart: "0px" }}>{children}</ul>;
});

const Cryptr = require("cryptr");
const cryptr = new Cryptr("@$#rgweR35*&YFSD)/");

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import dispatcherUser from "./permissions/DispatcherUserPermision";
import accountingUser from "./permissions/AccountingUserPermision";
import { CodeSharp } from "@material-ui/icons";

const searchOptions = {
  componentRestrictions: { country: ["us"] },
  types: ["(regions)"],
};

class EditPickUpAndDelieveryDetails extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      auditTrailsResponse: [],
      data: [],
      soureAddress: "",
      destinationAddress: "",
      soureLatLng: "",
      destinationLatLng: "",
      sourceClock: {
        checkedOutTime: "",
        timeZone: "",
        timeZoneName: "",
      },
      destinationClock: {
        timeZone: "",
        timeZoneName: "",
      },
      totalMiles: 0,
      truckAvgSpeed: 50,
      extraMinutesForDriver: 30,
      estimatedDelveryDateTime: "",
      wayPoints: [],
      stopLimitError: "",
      mine1: "",
      mine2: "",

      // 123123
      myValue: "",
      currenOpenTab: "",
      selectRateTypeValue: {},
      salesPerson1: {},
      salesPerson2: {},
      countries: [],
      selectedCountryId: null,
      states: [],
      showSelectedRowOnTabs: [],
      showSelectedRowOnTabsBoolean: false,
      showRowDeliveryDate: "",
      showRowPickupDate: "",
      showRowPickupCityState: "",
      showRowDeliveryCityState: "",
      status: 1,
      loadid: "",
      whileSave: false,
      readonly: true,
      hidden: false,
      accountingInfoReadOnly: true,
      accountingInfoHidden: false,
      accountingLoad: null,
      billingInfoReadOnly: true,
      accountingCustomsLoad: null,
      accountingVerified: false,
      billingInfoHidden: false,
      accountingRate: null,
      isLoading: false,
      token: "",
      fields: {},
      errors: {},
      CustomerSelectedOption: null,
      CustomerOptions: [],
      ContactSelectedOption: null,
      RateSelectedOption: null,
      ContactOptions: [],
      CarrierSelectedOption: null,
      CarrierOptions: [],
      role: "Tenant",
      subRole: "Basic",
      loadData: [],
      carrierdata: {},

      customerOptionsData: [],
      customerCard: {},
      contactOptionsData: [],
      contactCard: {},
      dispatchPercentage: 0,
      billingLoad: null,
      billingCustomsLoad: null,
      billingRate: null,
      billingAmount: null,
      suggestedAmount: null,
      billingStatus: null,
      billingInvoice: null,
      billingVerified: null,
      dispatchFee: 0,
      dispatchType: 2,
      dispatchTypeOptions: [
        { id: 1, name: "Dispatch Fee" },
        { id: 2, name: "Dispatch Percentage" },
      ],
      puFields: [],
      StopData: [
        {
          ShipperOptions: [],
          shipperId: null,
          stopNo: 1,
          stopType: "",
          isAddressAvailable: "1",
          address: "",
          scheduleFTime: null,
          scheduleTTime: null,
          scheduleFrom: moment().set({ hour: 0, minute: 0, second: 0 }),
          scheduleTo: moment().set({ hour: 0, minute: 0, second: 0 }),
          puNo: [""],
          notes: "",
          shipperOptionsData: [],
          shipperCard: {},
          checkedIn: "0",
          checkedOut: "0",
          checkedInT: 0,
          checkedOutT: 0,
          checkedInTime: moment().set({ hour: 0, minute: 0, second: 0 }),
          checkedOutTime: moment().set({ hour: 0, minute: 0, second: 0 }),
          dispatchCheckBox: false,
          etaNote: "",
          driverNote: "",
          currentLocation: "",
          verified: false,
          eta: "",
          etaNote:"",
          driverCheckInNote:"",
          driverCheckoutNote:"",
          etaDateTime:"",
          milesLeft:0.0,
          etaUpdatedAt:null


        },
        {
          ShipperOptions: [],
          shipperId: null,
          stopNo: 2,
          isAddressAvailable: "1",
          address: "",
          stopType: "",
          scheduleFTime: null,
          scheduleTTime: null,
          scheduleFrom: moment().set({ hour: 0, minute: 0, second: 0 }),
          scheduleTo: moment().set({ hour: 0, minute: 0, second: 0 }),
          puNo: [""],
          notes: "",
          shipperOptionsData: [],
          shipperCard: {},
          checkedIn: "0",
          checkedOut: "0",
          checkedInT: 0,
          checkedOutT: 0,
          checkedInTime: moment().set({ hour: 0, minute: 0, second: 0 }),
          checkedOutTime: moment().set({ hour: 0, minute: 0, second: 0 }),
          dispatchCheckBox: false,
          etaNote: "",
          driverNote: "",
          currentLocation: "",
          verified: false,
          eta: "",
          etaNote:"",
          driverCheckInNote:"",
          driverCheckoutNote:"",
          etaDateTime:"",
          milesLeft:0.0,
          etaUpdatedAt:null
        },
      ],
      freightType: "",
      temperatureType: "F",
      cycle: "cont",
      loadFormData: {},
      CheckEditInfo: {},

      Documents: [
        {
          label: "Rate Sheet",
          docType: 1,
          documentNo: 1,
          fileName: "",
          error: "",
          showIcons: true,
          file: "",
          s3FileKey: "",
          checked: false,
          documentId: "",
          invoiceError: "",
          fileExtension: "",
        },
        {
          label: "BOL (Bill of Loading)",
          docType: 2,
          documentNo: 2,
          fileName: "",
          error: "",
          showIcons: true,
          file: "",
          s3FileKey: "",
          checked: false,
          documentId: "",
          invoiceError: "",
          fileExtension: "",
        },
        {
          label: "POD (Proof of Delivery)",
          docType: 3,
          documentNo: 3,
          fileName: "",
          error: "",
          showIcons: true,
          file: "",
          s3FileKey: "",
          checked: false,
          documentId: "",
          invoiceError: "",
          fileExtension: "",
        },
        {
          label: "Lumper Receipt",
          docType: 4,
          documentNo: 4,
          fileName: "",
          error: "",
          showIcons: true,
          file: "",
          s3FileKey: "",
          checked: false,
          documentId: "",
          invoiceError: "",
          fileExtension: "",
        },
      ],

      additionalCharges: [],
      totalAmount: 0.0,
      paymentType: "Quick Pay",
      invoicePopUpInputValues: {
        chargesType: "Lumper",
        amount: "",
      },
      invoiceId: null,
      invoiceURL: null,
      invoiceS3FileKey: null,
      invoiceLoader: false,
      invoiceFileName: "",
      quickPay: "",
      invoiceTo: "Customer",
      invoiceStatus: "Processed",
      //Individual Edit
      isLoadDetailsEditable: false,
      isCustomerDetailsEditable: false,
      isRateDetailsEditable: false,
      isSalesDetailsEditable: false,
      isCarrierDetailsEditable: false,
      isFreightDetailsEditable: false,
      isStopsDetailsEditable: false,
      isRequirementsDetailsEditable: false,
      dispatchedStops: [],
      commodity: "",
      tempValue: "",
      typeCommodity: false,
      selectedStopNo: "",
      currentLocationLatLng: {},
      currentLocationClock: {
        checkedOutTime: "",
        timeZone: "",
        timeZoneName: "",
      },
      currentLocation: "",
      locationChanged: false,
      etaChanged:null
    
    };

    this.handleChange = this.handleChange.bind(this);
    this.submituserRegistrationForm = this.submituserRegistrationForm.bind(
      this
    );
    this.submit = this.submit.bind(this);
    this.billingSubmitForm = this.billingSubmitForm.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.AutoFormatContact = this.AutoFormatContact.bind(this);
  }

  handleClose = () => {
    this.setState({ show: false });
  };
  handleShow = () => {
    this.setState({ show: true });
  };

  confirmation = (index) => {
    let StopData = this.state.StopData;
    StopData[index].currentLocation = this.state.currentLocation;
    this.setState({ show: false, StopData: StopData });
  };

  onUnload = (event) => {
    event.preventDefault();
    var http = new XMLHttpRequest();

    var url = BaseURL + "editModeInfo/finishEditMode";

    var params =
      "customerLoadId=" + this.state.loadid + "&token=" + this.state.token;

    http.open("POST", url, true);

    http.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

    if (!this.state.readonly) {
      http.send(params);
      var start = new Date().getTime();
      while (new Date().getTime() < start + 350);
    }
  };

  AutoFormatContact(e) {
    function phone_formatting(ele, restore) {
      var new_number,
        // selection_start = ele.selectionStart,
        // selection_end = ele.selectionEnd,
        number = ele.value.replace(/\D/g, "");

      // automatically add dashes
      if (number.length > 2) {
        // matches: 123 || 123-4 || 123-45
        new_number = number.substring(0, 3) + "-";
        if (number.length === 4 || number.length === 5) {
          // matches: 123-4 || 123-45
          new_number += number.substr(3);
        } else if (number.length > 5) {
          // matches: 123-456 || 123-456-7 || 123-456-789
          new_number += number.substring(3, 6) + "-";
        }
        if (number.length > 6) {
          // matches: 123-456-7 || 123-456-789 || 123-456-7890
          new_number += number.substring(6);
        }
      } else {
        new_number = number;
      }

      // if value is heigher than 12, last number is dropped
      // if inserting a number before the last character, numbers
      // are shifted right, only 12 characters will show
      ele.value =
        new_number.length > 12 ? new_number.substring(0, 12) : new_number;

      // restore cursor selection,
      // prevent it from going to the end
      // UNLESS
      // cursor was at the end AND a dash was added

      // if (new_number.slice(-1) === '-' && restore === false
      //     && (new_number.length === 8 && selection_end === 7)
      //         || (new_number.length === 4 && selection_end === 3)) {
      //     selection_start = new_number.length;
      //     selection_end = new_number.length;
      // }
      // else if (restore === 'revert') {
      //   selection_start--;
      //   selection_end--;
      // }
      // ele.setSelectionRange(selection_start, selection_end);
    }

    function phone_number_check(field, e) {
      var key_code = e.keyCode,
        key_string = String.fromCharCode(key_code),
        press_delete = false,
        dash_key = 189,
        delete_key = [8, 46],
        direction_key = [33, 34, 35, 36, 37, 38, 39, 40],
        selection_end = field.selectionEnd;

      // delete key was pressed
      if (delete_key.indexOf(key_code) > -1) {
        press_delete = true;
      }

      // only force formatting is a number or delete key was pressed
      if (key_string.match(/^\d+$/) || press_delete) {
        phone_formatting(field, press_delete);
      }
      // do nothing for direction keys, keep their default actions
      else if (direction_key.indexOf(key_code) > -1) {
        // do nothing
      } else if (dash_key === key_code) {
        if (selection_end === field.value.length) {
          field.value = field.value.slice(0, -1);
        } else {
          field.value =
            field.value.substring(0, selection_end - 1) +
            field.value.substr(selection_end);
          field.selectionEnd = selection_end - 1;
        }
      }
      // all other non numerical key presses, remove their value
      else {
        e.preventDefault();
        //    field.value = field.value.replace(/[^0-9\-]/g,'')
        phone_formatting(field, "revert");
      }
    }

    const Validate = (e) => {
      if (!String.fromCharCode(e.keyCode).match(/^- $/)) {
        this.handleChange(e);
      }
    };

    document.getElementById(e.target.id).onkeyup = function(e) {
      phone_number_check(this, e);
      Validate(e);
    };
  }

  // 123123 google -- total miles --start
  handleSourceSelect = (soureAddress) => {
    this.setState({ soureAddress });
    geocodeByAddress(soureAddress)
      .then((results) => getLatLng(results[0]))
      .then((soureLatLng) => {
        this.setState({ soureLatLng });
        this.doTimeZoneApiCall(soureLatLng, this.setSourceClock);
      });
  };
  doTimeZoneApiCall = (soureLatLng, func) => {
    console.log(soureLatLng, "------------------");
    timezone.key("AIzaSyDIPwhthYzVPP6RkJG9sl903Bk98XsCi0g");
    timezone.data(soureLatLng.lat, soureLatLng.lng, 1, function(err, tz) {
      func(tz);
    });
  };
  handleDestSelect = (destinationAddress) => {
    this.setState({ destinationAddress });
    geocodeByAddress(destinationAddress)
      .then((results) => getLatLng(results[0]))
      .then((destinationLatLng) => {
        this.setState({ destinationLatLng });
        this.doTimeZoneApiCall(destinationLatLng, this.setDestinationClock);
      });
  };
  setDestinationClock = (timeZone) => {
    if (timeZone.raw_response.status === "OK") {
      var destinationClock = {
        timeZone: timeZone.raw_response.timeZoneId,
        timeZoneName: timeZone.raw_response.timeZoneName,
      };
      this.setState({
        destinationClock,
      });
    }
  };
  setSourceClock = (timeZone) => {
    if (timeZone.raw_response.status === "OK") {
      var checkedOutTime = moment.tz(
        new Date(),
        timeZone.raw_response.timeZoneId
      );
      var sourceClock = {
        checkedOutTime,
        timeZone: timeZone.raw_response.timeZoneId,
        timeZoneName: timeZone.raw_response.timeZoneName,
      };

      this.setState({
        sourceClock,
      });
    }
  };
  setcurrentLocationClock = (timeZone) => {
    if (timeZone.raw_response.status === "OK") {
      var checkedOutTime = moment.tz(
        new Date(),
        timeZone.raw_response.timeZoneId
      );
      var sourceClock = {
        checkedOutTime,
        timeZone: timeZone.raw_response.timeZoneId,
        timeZoneName: timeZone.raw_response.timeZoneName,
      };

      this.setState({
        currentLocationClock: sourceClock,
      });
    }
  };
  setTotalMiles = (miles) => {
    this.setState({
      totalMiles: miles,
    });
  };
  setTotalMilesLeft = (miles) => {
    console.log(
      `====================Total Miles Left============================`,
      miles
    );
    var stop=this.state.StopData
    stop[this.state.selectedIndex].milesLeft=miles
    // stop[this.state.selectedIndex]["etaUpdatedAt"] =new Date()

    this.setState({
      milesLeft: Number(miles),
      StopData:stop
    });
  };
  getFormattedWayPoints = (wayPoints) => {
    var newWayPoints = [];
    wayPoints.forEach((element) => {
      if (element.LatLng) {
        newWayPoints.push(element);
      }
    });

    return newWayPoints;
  };
  // 123123 google -- total miles --end

  async componentDidMount() {
    this._isMounted = true;
    window.addEventListener("beforeunload", this.onUnload);
    var role = "Tenant";
    var subRole = "Basic";
    const Role = localStorage.getItem("Role");
    const SubRole = localStorage.getItem("SubRole");
    console.log(
      `---------------Total Miles-=${this.state.totalMiles}---------------------------------------------------`
    );
    if (Role) {
      role = cryptr.decrypt(Role);
    }

    if (SubRole) {
      subRole = cryptr.decrypt(SubRole);
      this.setState({ subRole: subRole });
    }

    var token = localStorage.getItem("Token");
    const id = this.props.match.params.id.split("-")[0];
    const stopNo = this.props.match.params.id.split("-")[1];
    console.log(`ID-----------------`, id);

    if (this._isMounted) {
      await this.setState({
        token: token,
        loadid: id,
        selectedStopNo: stopNo,
        role,
      });
      this.getUsersList();
    }

    this.findLoadById();

    await axios
      .post(BaseURL + "customerLoad/getBillingDetails", {
        id: id,
        token: token,
      })
      .then((res) => {
        // console.log("BillingTabRes.....: ", res.data);
        this.setState({
          dispatchPercentage: res.data.dispatchPercentage || 0,
          billingLoad: res.data.internalLoadNo,
          accountingLoad: res.data.internalLoadNo,
          billingCustomsLoad: res.data.customerLoadNo,
          accountingCustomsLoad: res.data.customerLoadNo,
          billingRate: res.data.rate,
          accountingRate: res.data.rate,
          billingAmount: res.data.billingAmount,
          billingStatus: res.data.billingStatus,
          billingInvoice: res.data.invoiceNo,
          billingVerified: res.data.billingVerified,
          accountingVerified: res.data.accountingVerified,
          dispatchFee: res.data.dispatchFee || 0,
          dispatchType: res.data.dispatchType || 2,
          suggestedAmount:
            res.data.dispatchType == 2
              ? (res.data.dispatchPercentage * parseInt(res.data.rate)) / 100
              : null,
        });
      })
      .catch((err) => {
        console.log("BillingTabError.....: ", err);
      });
  }

  getUsersList = () => {
    let value = 0;
    var postData = {
      token: this.state.token,
      status: 2,
      offset: 0,
      limit: 50,
      sortOrder: "ASC",
      sortBy: "firstName",
    };
    if (
      this.state.role === "Admin" ||
      this.state.role === dispatcherUser ||
      this.state.role === accountingUser
    ) {
      axios
        .post(BaseURL + "users/", postData, {
          headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem("Token", "");
            this.props.history.push("/");
          }
          let array = response.data.data.users.rows;
          array.forEach((user) => {
            optionsUsersList.push({
              label: user.firstName + " " + user.lastName,
              value: value,
            });
            value = value + 1;
          });
        });
    }
  };

  findLoadById = async () => {
    var arr = {
        carrier: {},
        customerContacts: [],
        documents: [],
        customerLoad: {
          loadPickupAndDeliveryDetails: [
            {
              ShipperOptions: [],
              shipperId: null,
              stopNo: 1,
              isAddressAvailable: "1",
              address: "",
              stopType: "",
              scheduleFrom: moment().set({ hour: 0, minute: 0, second: 0 }),
              scheduleTo: moment().set({ hour: 0, minute: 0, second: 0 }),
              puNo: "",
              notes: "",
              shipperOptionsData: [],
              shipperCard: {},
              checkedIn: "0",
              checkedOut: "0",
              checkedInT: 0,
              checkedOutT: 0,
              checkedInTime: moment().set({ hour: 0, minute: 0, second: 0 }),
              checkedOutTime: moment().set({ hour: 0, minute: 0, second: 0 }),
              dispatchCheckBox: false,
            },
            {
              ShipperOptions: [],
              shipperId: null,
              stopNo: 2,
              stopType: "",
              isAddressAvailable: "1",
              address: "",
              scheduleFrom: moment().set({ hour: 0, minute: 0, second: 0 }),
              scheduleTo: moment().set({ hour: 0, minute: 0, second: 0 }),
              puNo: "",
              notes: "",
              shipperOptionsData: [],
              shipperCard: {},
              checkedIn: "0",
              checkedOut: "0",
              checkedInT: 0,
              checkedOutT: 0,
              checkedInTime: moment().set({ hour: 0, minute: 0, second: 0 }),
              checkedOutTime: moment().set({ hour: 0, minute: 0, second: 0 }),
              dispatchCheckBox: false,
            },
          ],
        },
        customer: [],
        shipper: [],
      },
      options = [],
      option1 = [],
      option2 = [],
      contacts = [],
      customerOptionsData = [],
      customerCard = {},
      contactOptionsData = [],
      contactCard = {},
      carrierCard = {};

    await axios
      .post(BaseURL + "customerLoad/findById", {
        token: this.state.token,
        id: this.state.loadid,
      })
      .then((response) => {
        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem("Token", "");
          this.props.history.push("/");
        }

        // let audits = response.data.data.auditTrails;
        // let getAuditArray = [];
        // audits.forEach((audit) => {
        //   const format1 = "YYYY-MM-DD HH:mm:ss";
        //   audit.time = moment(audit.time).format(format1);
        //   let arr = [
        //     `${audit.usersDetail[0].email}`,
        //     `${audit.action}`,
        //     `${audit.type}`,
        //     `${audit.time}`,
        //     `${audit.ip}`,
        //   ];
        //   getAuditArray.push(arr);
        // });
        // this.setState({
        //   auditTrailsResponse: getAuditArray,
        // });

        this.setState({
          showSelectedRowOnTabs: response.data.data,
        });

        let PickUpDate = new Date(
          response.data.data.customerLoad.loadPickupAndDeliveryDetails[0].scheduleFrom
        ).toLocaleDateString();

        this.setState({
          showRowPickupDate: PickUpDate,
        });
        let DeliveryDate = new Date(
          response.data.data.customerLoad.loadPickupAndDeliveryDetails[1].scheduleTo
        ).toLocaleDateString();

        this.setState({
          showRowDeliveryDate: DeliveryDate,
        });

        if (response.data.data.shipper[0]) {
          this.setState({
            showRowPickupCityState: response.data.data.shipper[0].address.city,
          });
        }
        if (response.data.data.shipper[1]) {
          this.setState({
            showRowDeliveryCityState:
              response.data.data.shipper[1].address.city,
          });
        }

        this.setState({
          showSelectedRowOnTabsBoolean: true,
        });
        let pickDelivery =
          response.data.data.customerLoad.loadPickupAndDeliveryDetails;
        // 123123
        // pickDelivery.forEach(element => {
        function isJSON(str) {
          const convertToString = str.toString();

          try {
            let arr = JSON.parse(convertToString);

            if (Array.isArray(arr)) {
              return true;
            } else {
              return false;
            }
          } catch (error) {
            return false;
          }
        }
        let loadPickupDetails =
          response.data.data.customerLoad.loadPickupAndDeliveryDetails;
        loadPickupDetails =
          loadPickupDetails.length !== 0
            ? loadPickupDetails.map((loadPickup, index) => {
                if (isJSON(loadPickup.puNo)) {
                  loadPickup = {
                    ...loadPickup,
                    puNo: JSON.parse(loadPickup.puNo),
                    checkedInT: new Date(loadPickup.checkedInTime).getTime(),
                    checkedOutT: new Date(loadPickup.checkedOutTime).getTime(),
                  };
                  return loadPickup;
                } else {
                  // console.log("above",loadPickup)

                  loadPickup = {
                    ...loadPickup,
                    puNo: [loadPickup.puNo],
                    checkedInT: new Date(loadPickup.checkedInTime).getTime(),
                    checkedOutT: new Date(loadPickup.checkedOutTime).getTime(),
                  };

                  // console.log("else part running",loadPickup)
                  return loadPickup;
                }
              })
            : null;
        // let data = response.data.data
        let customerLoad = response.data.data.customerLoad;
        customerLoad = {
          ...customerLoad,
          loadPickupAndDeliveryDetails: loadPickupDetails,
        };

        let data = { ...response.data.data, customerLoad };
        // response.data.data.customerLoad.loadPickupAndDeliveryDetails=loadPickupDetails
        arr = response.data.data ? data : arr;
        // console.log(data, 'RESPONSE++++++++++++++++++')
        setTimeout(() => {
          this.state.StopData.forEach((ele, index) => {
            console.log(
              `---------------------------------------------------------------------`
            );
            console.log(this.state.selectedStopNo, ele);
            console.log(
              `--------------------------------------------------------------`
            );
            if (this.state.selectedStopNo == ele.stopNo) {
              this.setState({ selectedIndex: index });
            }
            if (
              ele.currentLocation !== null &&
              this.state.selectedStopNo == ele.stopNo
            ) {
              console.log(`Selecting Location`);
              this.handleSelectCurrentLocation(ele.currentLocation, index);
            }
            if (index === this.state.StopData.length - 1) {
              let address =
                ele.shipperCard.name +
                "," +
                ele.shipperCard.address +
                "," +
                ele.shipperCard.city +
                "," +
                ele.shipperCard.zip;
              if (address === "undefined,undefined,undefined,undefined") {
                this.handleSourceSelect(
                  response.data.data.customerLoad
                    .loadPickupAndDeliveryDetails[0].address
                );
              } else {
                this.handleSourceSelect(address);
              }
            }
            if (this.state.selectedStopNo == ele.stopNo) {
              let address =
                ele.shipperCard.name +
                "," +
                ele.shipperCard.address +
                "," +
                ele.shipperCard.city +
                "," +
                ele.shipperCard.zip;
              if (address === "undefined,undefined,undefined,undefined") {
                this.handleDestSelect(
                  response.data.data.customerLoad
                    .loadPickupAndDeliveryDetails[1].address
                );
              } else {
                this.handleDestSelect(address);
              }
            }
          });
        }, 0);
      })
      .catch(function(error) {
        NotificationManager.error(
          "Something Went Wrong Server Is Not Responding ",
          "Error Message",
          3000
        );
        console.log(error);
      });

    if (this._isMounted) {
      if (arr.customerLoad.freightType !== "") {
        $("#" + arr.customerLoad.freightType).addClass("active");
      }
      //   $("#"+arr.customerLoad.temperatureType).addClass('active');
      $("#" + arr.customerLoad.cycle).addClass("active");
      $("#" + arr.customerLoad.driverRequirements).addClass("active");
      //   arr.customerLoad.freightType === 'reefer' ? ( document.getElementById('Shipping1').setAttribute('disabled',true)): {};

      //   arr.customerLoad.trailerType === "1"
      //     ? (document.getElementById("Shipping").className = "checked")
      //     : false;
      //   arr.customerLoad.trailerTypeOne === "1"
      //     ? (document.getElementById("Shipping1").className = "checked")
      //     : false;
      //   arr.customerLoad.trailerSize === "1"
      //     ? (document.getElementById("trailerType").className = "checked")
      //     : false;
      //   arr.customerLoad.trailerSizeOne === "1"
      //     ? (document.getElementById("trailerType1").className = "checked")
      //     : false;
      //   arr.customerLoad.driverRequirements === "1"
      //     ? (document.getElementById("driverRequirements").className = "checked")
      //     : false;
      //   arr.customerLoad.driverRequirementsOne === "1"
      //     ? (document.getElementById("driverRequirements1").className = "checked")
      //     : false;
    }

    // arr.carrier !== null && arr.carrier
    //   ? (options.push({
    //     value: arr.carrier.id,
    //     label:
    //       arr.carrier.carrierName +
    //       (arr.carrier.address
    //         ? (arr.carrier.address.address
    //           ? ", " + arr.carrier.address.address
    //           : "") +
    //         (arr.carrier.address.city
    //           ? ", " + arr.carrier.address.city
    //           : "") +
    //         (arr.carrier.address.state
    //           ? ", " + arr.carrier.address.state.state
    //           : "") +
    //         (arr.carrier.address.zip ? ", " + arr.carrier.address.zip : "")
    //         : ""),
    //   }),
    //     (carrierCard = {
    //       factoringCompany: arr.carrier.factoringCompany,
    //       address: arr.carrier.address ? arr.carrier.address.address : "",
    //       city: arr.carrier.address ? arr.carrier.address.city : "",
    //       state: arr.carrier.address ? arr.carrier.address.state.state : "",
    //     }))
    //   : "";

    // arr.customer !== null && arr.customer.address
    //   ? (customerOptionsData.push(arr.customer),
    //     (customerCard = {
    //       address: arr.customer.address.address,
    //       city: arr.customer.address.city,
    //       state: arr.customer.address.state.state,
    //     }),
    //     option1.push({
    //       value: arr.customer.id,
    //       label:
    //         arr.customer.customerName +
    //         (arr.customer.address.address
    //           ? ", " + arr.customer.address.address
    //           : "") +
    //         (arr.customer.address.city
    //           ? ", " + arr.customer.address.city
    //           : "") +
    //         (arr.customer.address.state
    //           ? ", " + arr.customer.address.state.state
    //           : "") +
    //         (arr.customer.address.zip ? ", " + arr.customer.address.zip : ""),
    //     }))
    //   : "";

    arr.customerLoad.loadPickupAndDeliveryDetails.forEach((shipper) => {
      shipper.ShipperOptions = [];
      shipper.shipperOptionsData = [];
      shipper.shipperCard = {};

      arr.shipper.forEach((element) => {
        if (element && shipper.shipperId === element.id) {
          var CompleteAddress = "";

          var address = element.address
            ? element.address.address
              ? element.address.address
              : ""
            : "";
          var city = element.address
            ? element.address.city
              ? element.address.city
              : ""
            : "";
          var state = element.address
            ? element.address.state
              ? element.address.state.state
              : ""
            : "";
          var zip = element.address
            ? element.address.zip
              ? element.address.zip
              : ""
            : "";
          var telephone = element.address
            ? element.address.telephone
              ? element.address.telephone
              : ""
            : "";
          var stateAbbreviation = element.address
            ? element.address.state
              ? element.address.state.stateAbbrivation
              : ""
            : "";

          CompleteAddress =
            (address ? ", " + address + ", " : address) +
            (city ? city + ", " : city) +
            (state ? state + ", " : state) +
            zip;

          shipper.shipperOptionsData.push(element);
          shipper.shipperCard = {
            address: address,
            city: city,
            state: state,
            telephone: telephone,
            name: element.name,
            stateAbbr: stateAbbreviation,
            zip: zip,
          };

          shipper.ShipperOptions = [
            { value: element.id, label: element.name + CompleteAddress },
          ];
        }
      });
    });

    arr.customerContacts
      ? arr.customerContacts.forEach((element) => {
          contactOptionsData.push(element);
          arr.customerLoad.customerContactId === element.id
            ? (contactCard = {
                name: element.name,
                email: element.email,
                telephone: element.telephone,
                fax: element.fax,
              })
            : "";
          var obj = { value: element.id, label: element.name };

          contacts.push(obj);
        })
      : {};

    var Documents = this.state.Documents;

    arr.documents.forEach((element) => {
      var filename = element.documentName;
      var extension = element.documentName.split(".").pop();

      if (filename.length > 15) {
        filename = filename.substring(0, 15) + "....";
      }

      Documents.forEach((docElement) => {
        if (docElement.documentNo === element.documentNo) {
          docElement["fileName"] = filename;
          docElement["s3FileKey"] = element.s3FileKey;
          docElement["documentId"] = element.id;
          docElement["fileExtension"] = extension;
        }
      });

      if (element.documentNo > 4) {
        var docType = element.documentType;
        var label =
          docType === "1"
            ? "Rate Sheet"
            : docType === "2"
            ? "BOL (Bill of Loading)"
            : docType === "3"
            ? "POD (Proof of Delivery)"
            : "Lumper Receipt";

        var obj = {
          label: label,
          docType: docType,
          documentNo: element.documentNo,
          fileName: filename,
          error: "",
          showIcons: true,
          file: "",
          s3FileKey: element.s3FileKey,
          documentId: element.id,
          invoiceError: "",
          fileExtension: extension,
        };
        Documents.push(obj);
      }
    });

    arr.invoiceSelectedDocuments
      ? Documents.forEach((element) => {
          arr.invoiceSelectedDocuments.forEach((elem) => {
            if (elem.loadDocumentId === element.documentId) {
              element.checked = true;
            }
          });
        })
      : [];

    var loadFormData = this.state.loadFormData;
    loadFormData = arr.customerLoad;
    loadFormData["rate"] = arr.customerLoad.rate
      ? arr.customerLoad.rate
      : "0.00";

    this.state.selectRateTypeValue = { label: loadFormData.rateType, value: 0 };
    this.state.salesPerson1 = {
      label: !loadFormData.salesDetails
        ? "Select..."
        : loadFormData.salesDetails,
      value: 0,
    };
    this.state.salesPerson2 = {
      label: !loadFormData.salesDetails1
        ? "Select..."
        : loadFormData.salesDetails1,
      value: 0,
    };

    if (this._isMounted) {
      await this.setState({
        // Documents,
        carrierdata: carrierCard,
        loadData: arr.carrier ? [arr.carrier] : [],
        CarrierOptions: options,
        CustomerOptions: option1,
        ContactOptions: contacts,
        // loadFormData: loadFormData,
        CustomerSelectedOption: arr.customerLoad.customerId,
        ContactSelectedOption: arr.customerLoad.customerContactId,
        CarrierSelectedOption: arr.customerLoad.carrierId,
        StopData: arr.customerLoad.loadPickupAndDeliveryDetails,
        freightType: arr.customerLoad.freightType,
        temperatureType: arr.customerLoad.temperatureType,
        cycle: arr.customerLoad.cycle,
        driverRequirements: arr.customerLoad.driverRequirements,
        customerOptionsData: customerOptionsData,
        customerCard: customerCard,
        contactOptionsData: contactOptionsData,
        contactCard: contactCard,

        totalAmount: arr.invoice
          ? arr.invoice.totalAmount
            ? currencyFormatter.format(arr.invoice.totalAmount, {
                code: "USD",
              })
            : "$0.00"
          : arr.customerLoad.rate
          ? currencyFormatter.format(arr.customerLoad.rate, { code: "USD" })
          : "$0.00",
        additionalCharges: arr.invoiceAdditionalCharges
          ? arr.invoiceAdditionalCharges
          : [],
        paymentType: arr.invoice ? arr.invoice.paymentType : "Quick Pay",
        invoiceId: arr.invoice ? arr.invoice.id : null,
        invoiceURL: arr.invoice ? arr.invoice.invoiceURL : null,
        invoiceS3FileKey: arr.invoice ? arr.invoice.s3FileKey : null,
        invoiceFileName: arr.invoice ? arr.invoice.fileName : "",
        quickPay: arr.invoice ? arr.invoice.quickPay : "",
        invoiceTo:
          arr.invoice && arr.invoice.invoiceTo
            ? arr.invoice.invoiceTo
            : "Customer",
        invoiceStatus:
          arr.invoice && arr.invoice.invoiceStatus
            ? arr.invoice.invoiceStatus
            : "Processed",
        commodity: arr.customerLoad.commodity,
        typeCommodity: arr.customerLoad.typeCommodity,
      });
    }

    //  this.loadcarrierinfo(arr.customerLoad.carrierId);
  };

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("beforeunload", this.onUnload);
    var config = {
      customerLoadId: this.state.loadid,
      token: this.state.token,
    };

    if (config.customerLoadId) {
      finishEditinfo(
        config,
        (res) => {
          console.log(res);
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  async handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    if (this._isMounted) {
      await this.setState({
        fields,
      });
    }
    this.validateForm();
  }
  addressCheckboxHandler = (e, index) => {
    const StopData = this.state.StopData;
    StopData[index].isAddressAvailable = e.target.checked ? "0" : "1";
    StopData[index].shipperId = StopData[index].shipperId === null ? "" : null;
    this.setState(
      {
        StopData,
      },
      () => console.log(this.state.StopData[index].shipperId)
    );
  };
  handleAddressChange = (address, index) => {
    if (this._isMounted) {
      this.handleSelect(address, index);
    }
  };

  handleSelect = (address, index) => {
    if (this._isMounted) {
      let StopData = this.state.StopData;
      StopData[index].address = address;
      this.setState({ StopData });
      geocodeByAddress(address)
        .then((results) => {
          getLatLng(results[0]);
        })
        .then((latLng) => this.setState({ addressLatLng: latLng }))
        .catch((error) => console.error("Error", error));
    }
  };
  handleCurrentLocationChange = (address, index) => {
    console.log(`Hanlde Current Location Change ${address}`);
    if (this._isMounted) {
      this.handleSelectCurrentLocation(address, index);
    }
  };

  handleSelectCurrentLocation = (address, index) => {
    if (this._isMounted) {
      this.setState({ currentLocation: address });

      geocodeByAddress(address)
        .then((results) => getLatLng(results[0]))
        .then((latLng) => {
          console.log(
            `SELCTING---------------------------Current Location LAT LNG ${latLng}`
          );
          this.setState({ currentLocationLatLng: latLng });
          this.doTimeZoneApiCall(latLng, this.setcurrentLocationClock);
        })
        .catch((err) => {
          console.log(err);
        })
        .catch((error) => console.error("Error", error));
    }
  };
  submituserRegistrationForm(e) {
    e.preventDefault();
    var send = false;

    if (
      this.validateAndSubmit() &&
      this.validateForm() &&
      this.validateDates()
    ) {
      console.log(this.validateDates(), "DATES STATUS");
      send = true;
    }
    if (send) {
      this.submit();
    }
  }
  //submitBillingRegistrationForm....
  submitBillingRegistrationForm(e) {
    e.preventDefault();
    var send = false;

    // if (this.validateBillingSubmit() && this.validateForm()) {
    if (this.validateForm()) {
      send = true;
    }
    if (send) {
      this.billingSubmitForm();
    }
  }
  //.............
  //.............
  //submitAccountingRegistrationForm....
  submitAccountingRegistrationForm(e) {
    e.preventDefault();
    var send = false;

    // if (this.validateBillingSubmit() && this.validateForm()) {
    if (this.validateForm()) {
      send = true;
    }
    if (send) {
      this.accountingSubmitForm();
    }
  }
  //.............

  validateDates() {
    let arr = [];
    for (let ind = 0; ind < this.state.StopData.length; ind++) {
      const el = this.state.StopData[ind];
      let from = new Date(el.scheduleFrom).getTime();
      let to = new Date(el.scheduleTo).getTime();
      let checkedIn = new Date(el.checkedInTime).getTime();
      let checkedOut = new Date(el.checkedOutTime).getTime();
      if (from > to) {
        NotificationManager.error(
          `"Schedule From" date cannot be greater than "Schedule To"`,
          `Stop ${ind + 1}`,
          4000
        );
        arr.push(false);
      } else if (checkedIn > checkedOut) {
        NotificationManager.error(
          `CheckedIn Time can not be greater than CheckedOut Time.`,
          `Stop ${ind + 1}`,
          4000
        );
        arr.push(false);
      } else {
        arr.push(true);
      }
    }
    let a = arr.find((el) => el == false);
    if (a == false) {
      return false;
    } else {
      return true;
    }
  }

  validateAndSubmit() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!this.state.CustomerSelectedOption) {
      formIsValid = false;
      errors["customer"] = "*Please Select Customer";
      $("[name='load']").focus();
    }
    // } else if (
    //   !this?.refs?.load?.value ||
    //   !this?.refs?.load?.value?.trim().length > 0
    // ) {
    //   formIsValid = false;
    //   errors["load"] = "*Please Enter Customers Load";
    //   errors["customer"] = "";
    //   $("[name='load']").focus();
    // }

    if (this._isMounted) {
      this.setState({
        errors: errors,
      });
    }
    return formIsValid;
  }

  //..........
  //EditBillingForm....
  EditAccountingForm() {
    var div = document.getElementById("editAccountingDiv");
    div.setAttribute("style", " cursor: not-allowed; pointer-events: none");

    var config = {
      customerLoadId: this.state.loadid,
      token: this.state.token,
    };

    getEditinfo(
      config,
      (res) => {
        console.log(res);
        if (res.data.status === 0) {
          if (this._isMounted) {
            this.setState({
              accountingInfoReadOnly: false,
              CheckEditInfo: config,
            });

            this.state.freightType === "reefer"
              ? this.refs.Shipping1.setAttribute("disabled", true)
              : {};
          }
        } else {
          div.removeAttribute("style");
          NotificationManager.warning(
            res.data.message,
            "Warning Message",
            3000
          );
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  //..........
  //validateBillingSubmit.....
  validateBillingSubmit() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!this.state.CustomerSelectedOption) {
      formIsValid = false;
      errors["customer"] = "*Please Select Customer";
      $("[name='load']").focus();
    } else if (
      !this.refs.load.value ||
      !this.refs.load.value.trim().length > 0
    ) {
      formIsValid = false;
      errors["load"] = "*Please Enter Customers Load";
      errors["customer"] = "";
      $("[name='load']").focus();
    }

    if (this._isMounted) {
      this.setState({
        errors: errors,
      });
    }
    return formIsValid;
  }
  //...............

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    // dispatchPercentage
    if (
      typeof fields["dispatchPercentage"] !== "undefined" &&
      fields["dispatchPercentage"]
    ) {
      if (
        !fields["dispatchPercentage"].match(
          /(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/
        )
      ) {
        formIsValid = false;
        errors["dispatchPercentage"] =
          "*Percentage Accept Numeric Value And Not Greater Than 100";
      }
    }

    // billingInvoice
    if (
      typeof fields["billingInvoice"] !== "undefined" &&
      fields["billingInvoice"]
    ) {
      if (!fields["billingInvoice"].match(/^[a-zA-Z0-9]{1,200}$/)) {
        formIsValid = false;
        errors["billingInvoice"] =
          "*Only Alpha-numerics Allowed (Max Range: 200)";
      }
    }

    if (typeof fields["load"] !== "undefined" && fields["load"]) {
      if (fields["load"].length > 200) {
        formIsValid = false;
        errors["load"] = "*Please Enter Only Valid Characters (Max Range: 200)";
      }
    }

    if (
      typeof fields["internalload"] !== "undefined" &&
      fields["internalload"]
    ) {
      if (fields["internalload"].length > 200) {
        formIsValid = false;
        errors["internalload"] =
          "*Please Enter Only Valid Characters (Max Range: 200)";
      }
    }

    if (typeof fields["rate"] !== "undefined" && fields["rate"]) {
      if (fields["rate"].length > 200) {
        formIsValid = false;
        errors["rate"] = "*Please Enter Only Valid Characters (Max Range: 200)";
      }
    }

    if (typeof fields["temperature"] !== "undefined" && fields["temperature"]) {
      if (fields["temperature"].length > 200) {
        formIsValid = false;
        errors["temperature"] =
          "*Please Enter Only Valid Characters (Max Range: 200)";
      }
    }

    if (this.state.errors.internalload) {
      formIsValid = false;
      errors["internalload"] = this.state.errors.internalload;
      this.refs.internalload.focus();
    }

    if (this.state.errors.internalloadlimit) {
      formIsValid = false;
      errors["internalloadlimit"] = this.state.errors.internalloadlimit;
      this.refs.internalload.focus();
    }

    if (this.state.errors.load) {
      formIsValid = false;
      errors["load"] = "*Record Already Exist With this Load No";
      this.refs.load.focus();
    }

    if (this._isMounted) {
      this.setState({
        errors: errors,
      });
    }
    return formIsValid;
  }

  EditForm = (name) => {
    var div = document.getElementById("EditDiv");
    div.setAttribute("style", " cursor: not-allowed; pointer-events: none");

    if (
      this.state.isCustomerDetailsEditable ||
      this.state.isLoadDetailsEditable ||
      this.state.isRateDetailsEditable ||
      this.state.isSalesDetailsEditable ||
      this.state.isFreightDetailsEditable ||
      this.state.isStopsDetailsEditable ||
      this.state.isCarrierDetailsEditable ||
      this.state.isRequirementsDetailsEditable
    ) {
      this.setState({
        [name]: true,
      });
      return false;
    }

    var config = {
      customerLoadId: this.state.loadid,
      token: this.state.token,
    };

    getEditinfo(
      config,
      (res) => {
        // console.log(res);
        if (res.data.status === 0) {
          if (this._isMounted) {
            this.setState({
              readonly: false,
              [name]: true,
              CheckEditInfo: config,
            });

            this.state.freightType === "reefer"
              ? this.refs.Shipping1.setAttribute("disabled", true)
              : {};
          }
        } else {
          div.removeAttribute("style");
          NotificationManager.warning(
            res.data.message,
            "Warning Message",
            3000
          );
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  //..............
  //accountingSubmitForm............
  accountingSubmitForm() {
    this.setState({ accountingInfoReadOnly: true });

    var dispatchType = this.state.dispatchType;
    var dispatchPercentage = this.state.dispatchPercentage;
    var dispatchFee = this.state.dispatchFee;

    var Load = {
      token: this.state.token,
      id: this.state.loadid,
      invoiceNo:
        this.state.fields["billingInvoice"] === ""
          ? null
          : this.state.fields["billingInvoice"],
      rate: this.state.billingRate,
      billingStatus: this.state.billingStatus,
      billingVerified: this.state.billingVerified,
      accountingVerified: this.state.accountingVerified,
      dispatchType: dispatchType,
      dispatchPercentage:
        dispatchPercentage === 0 || dispatchType === 1
          ? null
          : dispatchPercentage,
      dispatchFee: dispatchFee === 0 || dispatchType === 2 ? null : dispatchFee,
    };

    if (this.state.loadid) {
      axios
        .post(BaseURL + "customerLoad/updateBillingDetails", Load)
        .then((response) => {
          console.log(response.data);
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem("Token", "");
            this.props.history.push("/");
          } else if (response.data.error === "Load Already Exist") {
            this.setState({ accountingInfoReadOnly: false });
            NotificationManager.error(
              "Accounting Load Already Exist With This Number",
              "Error",
              3000
            );
          } else {
            this.setState({ accountingInfoHidden: false });
            NotificationManager.success(
              "Accounting Load Upated Successfully",
              "Edited",
              3000
            );
            //.....getBillingTab.............
            axios
              .post(BaseURL + "customerLoad/getBillingDetails", {
                id: this.state.loadid,
                token: this.state.token,
              })
              .then((res) => {
                console.log("BillingTabRes.....: ", res.data);
                // alert(res.data.dispatchType == 2 ? (res.data.dispatchPercentage * parseInt(res.data.rate) )/ 100  : null);
                this.setState({
                  dispatchPercentage: res.data.dispatchPercentage,
                  billingLoad: res.data.internalLoadNo,
                  billingCustomsLoad: res.data.customerLoadNo,
                  billingRate: res.data.rate,
                  billingAmount: res.data.billingAmount,
                  billingStatus: res.data.billingStatus,
                  billingInvoice: res.data.invoiceNo,
                  billingVerified: res.data.billingVerified,
                  accountingVerified: res.data.accountingVerified,
                  suggestedAmount:
                    res.data.dispatchType == 2
                      ? (res.data.dispatchPercentage *
                          parseInt(res.data.rate)) /
                        100
                      : null,
                });
              })
              .catch((err) => {
                console.log("AccountingTabError.....: ", err);
              });
            //..............
          }
        })
        .catch(function(error) {
          NotificationManager.error(
            "Something Went Wrong Server Is Not Responding ",
            "Error Message",
            3000
          );
          console.log(error);
        });
    }
  }
  //..............

  //EditBillingForm....
  EditBillingForm() {
    var div = document.getElementById("editBillingDiv");
    div.setAttribute("style", " cursor: not-allowed; pointer-events: none");

    var config = {
      customerLoadId: this.state.loadid,
      token: this.state.token,
    };

    getEditinfo(
      config,
      (res) => {
        console.log(res);
        if (res.data.status === 0) {
          if (this._isMounted) {
            this.setState({
              billingInfoReadOnly: false,
              CheckEditInfo: config,
            });

            this.state.freightType === "reefer"
              ? this.refs.Shipping1.setAttribute("disabled", true)
              : {};
          }
        } else {
          div.removeAttribute("style");
          NotificationManager.warning(
            res.data.message,
            "Warning Message",
            3000
          );
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  //..........

  async cancel() {
    if (window.confirm(" Are you sure you want to cancel ? "))
      var from = this.props.match.params.from
        ? "/" + this.props.match.params.from
        : "";
    var to = from || "/Pickup-Deleivery-Details";
    this.props.history.push(to);
  }

  submit() {
    this.setState({
      isLoadDetailsEditable: false,
      isCustomerDetailsEditable: false,
      isFreightDetailsEditable: false,
      isCarrierDetailsEditable: false,
      isRateDetailsEditable: false,
      isSalesDetailsEditable: false,
      isStopsDetailsEditable: false,
      isRequirementsDetailsEditable: false,
    });
    let r = JSON.stringify(this.state.StopData);
    console.log(`STOPDAAAAAAAAAAAAAAAAAAAAAAA`,this.state.selectedIndex,this.state.selectedStopNo)
    console.log(this.state.StopData)
    let pickuUPs = JSON.parse(r);
    pickuUPs.map((stop, ind) => {
      const stringfyPickups = JSON.stringify(stop.puNo);
      stop.puNo = stringfyPickups;
      return stop;
    });
    console.log(pickuUPs, "+++++++++++++++++++++");
    const loadPickupAndDeliveryDetails = pickuUPs;
    var Load = {
      loadPickupAndDeliveryDetails: loadPickupAndDeliveryDetails,
    };
    let selectedIndex = 0;
    Load.loadPickupAndDeliveryDetails.forEach((element, index) => {
      // if (this.state.selectedStopNo === element.stopNo) {
      //   selectedIndex = index;
      // }
      let getDateSF = new Date(
        moment(element.scheduleFrom)
          .utc()
          .toDate()
      );
      let setDateSF = getDateSF.setHours(getDateSF.getHours() - 7);
      element.scheduleFrom = setDateSF;

      let getDateSTo = new Date(
        moment(element.scheduleTo)
          .utc()
          .toDate()
      );
      let setDateSTo = getDateSTo.setHours(getDateSTo.getHours() - 7);
      element.scheduleTo = setDateSTo;
    });

    Load.loadPickupAndDeliveryDetails.forEach((element) => {
      element.scheduleFrom = new Date(element.scheduleFrom).toISOString();
      element.scheduleTo = new Date(element.scheduleTo).toISOString();
    });
    
    ;
    Load.loadPickupAndDeliveryDetail =
      Load.loadPickupAndDeliveryDetails[this.state.selectedIndex];

      if (this.state.etaChanged){
        console.log(`daskdaskdlkasdlkaslkdlksadlkaslkdsa`)
        Load.loadPickupAndDeliveryDetail['etaUpdatedAt']=new Date(this.state.etaChanged)

      }
    Load.id = Load.loadPickupAndDeliveryDetail.id;
    setTimeout(() => {
      this.state.StopData.forEach((ele, index) => {
        console.log(`=================================`, this.state.etaChanged);
        if (index === 0 && ele.currentLocation) {
          this.handleSelectCurrentLocation(ele.currentLocation);
        }
        if (index === 1) {
          let address =
            ele.shipperCard.name +
            "," +
            ele.shipperCard.address +
            "," +
            ele.shipperCard.city +
            "," +
            ele.shipperCard.zip;
          console.log("------------------", address);
          if (address === "undefined,undefined,undefined,undefined") {
            this.handleDestSelect(Load.loadPickupAndDeliveryDetails[1].address);
          } else {
            console.log("====================", address);
            this.handleDestSelect(address);
          }
        }
      });
    }, 0);
console.log(`Load------------`,Load)
    if (this.state.loadid) {
      axios
        .put(BaseURL + "loadPickupAndDelievery/", Load)
        .then((response) => {
          console.log(response.data);
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem("Token", "");
            this.props.history.push("/");
          } else if (response.data.error === "Load Already Exist") {
            this.setState({ readonly: false });
            NotificationManager.error(
              "Customer Load Already Exist With This Number",
              "Error",
              3000
            );
          } else {
            this.setState({ hidden: false });
            NotificationManager.success(
              "Load Pick-up and Delievery Details Updated Successfully",
              "Edited",
              3000
            );
          }
          this.FinishEditInfo();
        })
        .catch(function(error) {
          this.FinishEditInfo();

          NotificationManager.error(
            "Something Went Wrong Server Is Not Responding ",
            "Error Message",
            3000
          );
          console.log(error);
        });
    }
  }

  //billingSubmitForm............
  billingSubmitForm() {
    this.setState({ billingInfoReadOnly: true });

    var dispatchType = this.state.dispatchType;
    var dispatchPercentage = this.state.dispatchPercentage;
    var dispatchFee = this.state.dispatchFee;

    var Load = {
      token: this.state.token,
      id: this.state.loadid,
      invoiceNo:
        this.state.fields["billingInvoice"] === ""
          ? null
          : this.state.fields["billingInvoice"],
      rate: this.state.billingRate,
      billingStatus: this.state.billingStatus,
      billingVerified: this.state.billingVerified,
      dispatchType: dispatchType,
      dispatchPercentage:
        dispatchPercentage === 0 || dispatchType === 1
          ? null
          : dispatchPercentage,
      dispatchFee: dispatchFee === 0 || dispatchType === 2 ? null : dispatchFee,
      billingAmount: this.state.billingAmount,
    };

    if (this.state.loadid) {
      axios
        .post(BaseURL + "customerLoad/updateBillingDetails", Load)
        .then((response) => {
          console.log(response.data);
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem("Token", "");
            this.props.history.push("/");
          } else if (response.data.error === "Load Already Exist") {
            this.setState({ billingInfoReadOnly: false });
            NotificationManager.error(
              "Billing Load Already Exist With This Number",
              "Error",
              3000
            );
          } else {
            this.setState({ billingInfoHidden: false });
            NotificationManager.success(
              "Billing Load Upated Successfully",
              "Edited",
              3000
            );
            //.....getBillingTab.............
            axios
              .post(BaseURL + "customerLoad/getBillingDetails", {
                id: this.state.loadid,
                token: this.state.token,
              })
              .then((res) => {
                this.setState({
                  dispatchPercentage: res.data.dispatchPercentage,
                  billingLoad: res.data.internalLoadNo,
                  billingCustomsLoad: res.data.customerLoadNo,
                  billingRate: res.data.rate,
                  billingAmount: res.data.billingAmount,
                  billingStatus: res.data.billingStatus,
                  billingInvoice: res.data.invoiceNo,
                  billingVerified: res.data.billingVerified,
                  suggestedAmount:
                    res.data.dispatchType == 2
                      ? (res.data.dispatchPercentage *
                          parseInt(res.data.rate)) /
                        100
                      : null,
                });
                console.log(this.state);
              })
              .catch((err) => {
                console.log("BillingTabError.....: ", err);
              });
            //..............
          }
        })
        .catch(function(error) {
          NotificationManager.error(
            "Something Went Wrong Server Is Not Responding ",
            "Error Message",
            3000
          );
          console.log(error);
        });
    }
  }
  //..............

  async SaveContact(event) {
    event.preventDefault();

    if (this._isMounted) {
      this.setState({
        isLoading: true,
      });
    }

    $("#closePopUp").click();
    var arr;
    var data = false;
    var selectvalue = this.state.CustomerSelectedOption;

    if (selectvalue) {
      var CustomerContactUpdate = {
        token: this.state.token,
        customerId: selectvalue,
        name: this.refs.ContactName.value.trim(),
        title: this.refs.ContactTitle.value.trim(),
        email: this.refs.ContactEmail.value.trim(),
        telephone: this.refs.ContactTelephone.value.trim(),
        ext: this.refs.ContactExt.value.trim(),
        cellphone: this.refs.ContactPhone.value.trim(),
        fax: this.refs.ContactFax.value.trim(),
      };
      this.ClearContact();

      await axios
        .post(BaseURL + "customerContact/save", CustomerContactUpdate)
        .then((response) => {
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem("Token", "");
            this.props.history.push("/");
          }
          console.log(response.data);

          NotificationManager.success(
            "Customer Contact Infomation Saved",
            "Customer Info",
            3000
          );
        })
        .catch(function(error) {
          NotificationManager.error(
            "Something Went Wrong Server Is Not Responding ",
            "Error Message",
            3000
          );
          console.log(error);
        });

      this.handleChangeCustomer({ value: selectvalue });
      if (this._isMounted) {
        this.setState({
          whileSave: false,
          isLoading: false,
        });
      }
    }
  }

  ClearContact = async () => {
    this.refs.ContactName.value = "";
    this.refs.ContactTitle.value = "";
    this.refs.ContactEmail.value = "";
    this.refs.ContactTelephone.value = "";
    this.refs.ContactExt.value = "";
    this.refs.ContactPhone.value = "";
    this.refs.ContactFax.value = "";
  };

  handleChangeCarrier = (selectedOption) => {
    var event = selectedOption ? selectedOption.value : null;
    if (this._isMounted) {
      this.setState({ CarrierSelectedOption: event });
    }
    this.loadcarrierinfo(event);
  };

  loadcarrierinfo(e) {
    if (e) {
      var id = e;
      var data;

      this.state.loadData
        ? this.state.loadData.forEach((element) => {
            if (element.id === Number(id)) {
              data = {
                factoringCompany: element.factoringCompany,
                address: element.address.address,
                city: element.address.city,
                state: element.address.state.state,
              };
            }
          })
        : {};

      if (data && this._isMounted) {
        data.factoringCompany
          ? this.setState({ carrierdata: data, invoiceTo: "Factoring Company" })
          : this.setState({ carrierdata: data });
      }
    } else {
      if (this._isMounted) {
        this.setState({ carrierdata: "" });
      }
    }
  }

  handleChangeCustomer = async (selectedOption) => {
    var event = selectedOption ? selectedOption.value : null;

    if (event) {
      if (this._isMounted) {
        this.setState({ CustomerSelectedOption: event });
      }

      this.state.customerOptionsData.forEach((element) => {
        if (element.id === event) {
          this.setState({
            customerCard: {
              address: element.address.address,
              city: element.address.city,
              state: element.address.state.state,
            },
          });
        }
      });

      var arr = [],
        options = [];
      await axios
        .post(BaseURL + "customerContact/findByCustomerId", {
          token: this.state.token,
          customerId: event,
        })
        .then((response) => {
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem("Token", "");
            this.props.history.push("/");
          }
          arr = response.data.data;
        })
        .catch(function(error) {
          NotificationManager.error(
            "Something Went Wrong Server Is Not Responding ",
            "Error Message",
            3000
          );
          console.log(error);
        });

      arr.forEach((element) => {
        var obj = { value: element.id, label: element.name };

        options.push(obj);
      });

      if (this._isMounted) {
        this.setState({
          ContactOptions: options,
          contactOptionsData: arr,
          contactCard: {},
        });
      }
    } else {
      if (this._isMounted) {
        this.setState({
          CustomerSelectedOption: event,
          ContactOptions: [],
          ContactSelectedOption: event,
          customerCard: {},
          contactCard: {},
        });
      }
    }
  };

  handleChangeContact = (selectedOption) => {
    var event = selectedOption ? selectedOption.value : null;

    if (this._isMounted) {
      event === null
        ? this.setState({ ContactSelectedOption: event, contactCard: {} })
        : this.setState({ ContactSelectedOption: event });
    }

    this.state.contactOptionsData.forEach((element) => {
      if (element.id === event) {
        this.setState({
          contactCard: {
            name: element.name,
            email: element.email,
            telephone: element.telephone,
            fax: element.fax,
          },
        });
      }
    });
  };

  addStopChild = async (e) => {
    e.preventDefault();

    var StopData = await this.state.StopData;

    await StopData.push({
      ShipperOptions: [],
      shipperId: null,
      stopNo: StopData.length + 1,
      address: "",
      isAddressAvailable: "1",
      stopType: "pickup",
      scheduleFrom: moment().set({ hour: 0, minute: 0, second: 0 }),
      scheduleTo: moment().set({ hour: 0, minute: 0, second: 0 }),
      puNo: [""],
      notes: "",
      shipperOptionsData: [],
      shipperCard: {},
      checkedIn: "0",
      checkedOut: "0",
      checkedInT: 0,
      checkedOutT: 0,
      checkedInTime: moment().set({ hour: 0, minute: 0, second: 0 }),
      checkedOutTime: moment().set({ hour: 0, minute: 0, second: 0 }),
      dispatchCheckBox: false,
    });

    if (this._isMounted) {
      this.setState({
        StopData: StopData,
      });
    }
  };

  handleCheckBox = (e) => {
    var id = e.target.name;
    var className = document.getElementById(id).className;
    if (className) {
      this.refs[id].checked = false;
      document.getElementById(id).className = "";
    } else {
      this.refs[id].checked = true;
      document.getElementById(id).className = "checked";
    }
  };

  onFileChange(index, element) {
    var Documents = this.state.Documents;

    var file = element.file;

    function getFileExtension(filename) {
      return filename.split(".").pop();
    }

    Documents[index]["fileExtension"] = getFileExtension(file.name);

    var filename = { name: file.name };

    if (
      file.type !== "application/pdf" &&
      file.type !== "image/jpeg" &&
      file.type !== "image/png" &&
      file.type !== "image/jpg" &&
      file.type !== "image/gif" &&
      file.type !== "image/tif" &&
      getFileExtension(file.name) !== "docx" &&
      getFileExtension(file.name) !== "doc" &&
      getFileExtension(file.name) !== "odt" &&
      getFileExtension(file.name) !== "xls" &&
      getFileExtension(file.name) !== "xlsx" &&
      getFileExtension(file.name) !== "ppt" &&
      getFileExtension(file.name) !== "pptx"
    ) {
      Documents[index]["error"] =
        "Please Upload only PDF ,Images, Document files ";
      this.setState({ Documents });

      return false;
    } else if (file.size > 1000000) {
      Documents[index]["error"] =
        "Please Upload A File Less Than 1MB ( Max size for file: 1MB ) ";
      this.setState({ Documents });
      return false;
    } else {
      Documents[index]["error"] = "";
      this.setState({ Documents });
    }

    if (filename.name.length > 15) {
      filename = {
        name: filename.name.substring(0, 15) + "....",
      };
    }

    var doctype = element.docType;

    Documents[index]["showIcons"] = false;
    this.setState({
      Documents,
    });

    console.log("enter in upload file");
    let data = new FormData();
    data.append("file", file);
    data.append("fileName", file.name);
    data.append("loadId", this.state.loadid);
    data.append("type", file.type);
    data.append("documentType", doctype);
    data.append("documentNo", element.documentNo);

    axios
      .post(BaseURL + "loadDocuments/uploadFileToS3", data, {
        headers: {
          "Content-Type": "multipart/form-data",
          token: this.state.token,
        },
      })
      .then((response) => {
        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem("Token", "");
          this.props.history.push("/");
        }
        console.log(response.data);
        NotificationManager.success(
          "Document Uploaded Successfully",
          "Upload",
          3000
        );

        Documents[index]["showIcons"] = true;
        Documents[index]["fileName"] = filename.name;
        Documents[index]["s3FileKey"] = response.data.data.s3FileKey;
        Documents[index]["documentId"] = response.data.data.id;

        this.setState({
          Documents,
        });
      })
      .catch(function(error) {
        NotificationManager.error(
          "Something Went Wrong Server Is Not Responding ",
          "Error Message",
          3000
        );
        console.log(error);
      });
  }

  deleteFile(index, element) {
    var Documents = this.state.Documents;

    var doctype = element.docType;

    if (window.confirm("Are You Sure To Remove Docment ?")) {
      axios
        .post(BaseURL + "loadDocuments/deleteFile", {
          loadId: this.state.loadid,
          documentNo: element.documentNo,
          documentType: doctype,
          token: this.state.token,
        })
        .then((response) => {
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem("Token", "");
            this.props.history.push("/");
          }
          NotificationManager.success(
            "Document Deleted Successfully",
            "Delete",
            3000
          );
          Documents[index]["fileName"] = "";
          Documents[index]["s3FileKey"] = "";
          Documents[index]["checked"] = false;
          Documents[index]["documentId"] = "";
          this.setState({ Documents });
        })
        .catch(function(error) {
          NotificationManager.error(
            "Something Went Wrong Server Is Not Responding ",
            "Error Message",
            3000
          );
          console.log(error);
        });
    }
  }

  download = (index, element) => {
    console.log(index, element);

    var Documents = this.state.Documents;

    var documentNo = element.documentNo;

    Documents[index]["showIcons"] = false;
    this.setState({
      Documents,
    });

    console.log("enter in download file");

    axios
      .post(BaseURL + "loadDocuments/getDocuments", {
        loadId: this.state.loadid,
        token: this.state.token,
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem("Token", "");
          this.props.history.push("/");
        }

        response.data.documents.forEach((element) => {
          if (element.documentNo === documentNo) {
            console.log(element);
            window.open(element.documentUrl, "_blank");
          }
        });

        Documents[index]["showIcons"] = true;
        this.setState({
          Documents,
        });
      })
      .catch(function(error) {
        NotificationManager.error(
          "Something Went Wrong Server Is Not Responding ",
          "Error Message",
          3000
        );
        console.log(error);
      });
  };

  FinishEditInfo() {
    var config = this.state.CheckEditInfo;
    if (config.token) {
      this.setState({ readonly: true });

      finishEditinfo(
        config,
        (res) => {
          console.log(res);

          this.setState({
            CheckEditInfo: {},
            billingInfoReadOnly: true,
          });
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  addInvoiceCharges = (e) => {
    e.preventDefault();

    var {
      additionalCharges,
      totalAmount,
      invoicePopUpInputValues,
    } = this.state;

    var ChargeType = invoicePopUpInputValues.chargesType;
    var Amount = invoicePopUpInputValues.amount
      ? currencyFormatter.unformat(invoicePopUpInputValues.amount, {
          code: "USD",
        })
      : 0;

    totalAmount =
      currencyFormatter.unformat(totalAmount, { code: "USD" }) + Amount;

    // totalAmount = totalAmount.toFixed(2);
    totalAmount = currencyFormatter.format(totalAmount, { code: "USD" });

    var additionalCharges = [...additionalCharges];

    additionalCharges.push({
      chargesType: ChargeType,
      amount: invoicePopUpInputValues.amount || "$0.00",
    });

    console.log("add case", totalAmount, invoicePopUpInputValues.amount);

    this.setState({ additionalCharges, totalAmount });

    this.resetAdditionalChargesPopUp();
  };

  deleteInvoiceCharges = (index) => {
    var { additionalCharges, totalAmount } = this.state;
    var additionalCharges = [...additionalCharges];

    console.log("del case", totalAmount, additionalCharges[index]["amount"]);

    totalAmount =
      currencyFormatter.unformat(totalAmount, { code: "USD" }) -
      currencyFormatter.unformat(additionalCharges[index]["amount"], {
        code: "USD",
      });
    // console.log(totalAmount );

    // totalAmount = totalAmount.toFixed(2)
    totalAmount = currencyFormatter.format(totalAmount, { code: "USD" });

    additionalCharges.splice(index, 1);

    this.setState({ additionalCharges, totalAmount });
  };

  resetAdditionalChargesPopUp = () => {
    // this.refs.chargesType.value='Lumper';
    // this.refs.amount.value='' ;

    var invoicePopUpInputValues = {
      chargesType: "Lumper",
      amount: "",
    };
    this.setState({ invoicePopUpInputValues });
  };

  setInvoicePaymentType = (paymentType) => {
    paymentType === "Regular Pay"
      ? this.setState({ paymentType, quickPay: "" })
      : this.setState({ paymentType });
  };

  handleInvoicePopUpInputChange = (name, value) => {
    var invoicePopUpInputValues = this.state.invoicePopUpInputValues;

    invoicePopUpInputValues[name] = value;

    this.setState({ invoicePopUpInputValues });
  };

  submitInvoiceForm = () => {
    var invoiceError = false;

    this.state.Documents.forEach((element) => {
      if (element.invoiceError) {
        invoiceError = true;
      }
    });

    if (!invoiceError) {
      var customer = {},
        carrier = {},
        Docs = [],
        shipper = null;

      this.setState({ invoiceLoader: true });
      this.refs.ToploadStatus.focus();

      this.state.customerOptionsData.forEach((element) => {
        if (element.id === this.state.CustomerSelectedOption) {
          customer = element;
        }
      });

      this.state.loadData.forEach((element) => {
        if (element.id === this.state.CarrierSelectedOption) {
          carrier = element;
        }
      });

      this.state.Documents.forEach((element) => {
        if (element.checked) {
          Docs.push({
            s3FileKey: element.s3FileKey,
            documentId: element.documentId,
          });
        }
      });

      var stopData = JSON.parse(JSON.stringify(this.state.StopData));

      stopData.forEach((element) => {
        element.shipperOptionsData.forEach((elem) => {
          if (element.shipperId === elem.id) {
            shipper = elem;
            element.shipperInfo = shipper;
          }
        });
      });

      var invoice = {
        token: this.state.token,
        loadId: this.state.loadid,
        totalAmount: this.state.totalAmount,
        paymentType: this.state.paymentType,
        additionalCharges: this.state.additionalCharges,
        customer: customer,
        carrier: carrier,
        internalLoadNo: this.refs.internalload.value,
        customerLoadNo: this.refs.load.value,
        loadPickupAndDeliveryDetails: stopData,
        rate: currencyFormatter.format(this.state.loadFormData.rate, {
          code: "USD",
        }),
        invoiceDocuments: Docs,
        invoiceId: this.state.invoiceId,
        s3FileKey: this.state.invoiceS3FileKey,
        quickPay: this.state.quickPay,
        invoiceTo: this.state.invoiceTo,
        factoringCompany: this.state.carrierdata.factoringCompany
          ? this.state.carrierdata.factoringCompany
          : null,
        invoiceStatus: this.state.invoiceStatus,
      };

      axios
        .post(BaseURL + "invoice/save", invoice)
        .then((response) => {
          if (response.data.error === TokenErrorMsg) {
            localStorage.setItem("Token", "");
            this.props.history.push("/");
          }

          console.log(response);

          this.setState({
            invoiceFileName: response.data.invoice.fileName,
            invoiceS3FileKey: response.data.invoice.s3FileKey,
            invoiceURL: response.data.invoice.invoiceURL,
            invoiceId: response.data.invoice.id,
            invoiceLoader: false,
          });

          NotificationManager.success(
            "Invoice Created Successfully",
            "Created",
            3000
          );
        })
        .catch((error) => {
          this.setState({ invoiceLoader: false });
          NotificationManager.error(
            "Something Went Wrong Server Is Not Responding ",
            "Error Message",
            3000
          );
          console.log(error);
        });
    }
  };

  handleInvoiceDocsCheckBox = (index) => {
    var Documents = [...this.state.Documents];

    console.log(Documents[index]["fileExtension"]);

    if (Documents[index]["checked"]) {
      Documents[index]["invoiceError"] = "";
      Documents[index]["checked"] = false;
      this.setState({ Documents });
    } else {
      if (
        Documents[index]["fileExtension"] !== "pdf" &&
        Documents[index]["fileExtension"] !== "PDF"
      ) {
        Documents[index]["invoiceError"] = "Please Select PDF Document Only";
      }
      Documents[index]["checked"] = true;
      this.setState({ Documents });
    }
  };

  deleteInvoice = () => {
    if (!window.confirm("Are you sure you want to delete ?")) {
      return;
    }

    axios
      .post(BaseURL + "invoice/delete", {
        invoiceId: this.state.invoiceId,
        s3FileKey: this.state.invoiceS3FileKey,
        token: this.state.token,
      })
      .then((response) => {
        console.log(response.data);

        this.setState({
          invoiceId: null,
          invoiceS3FileKey: null,
          invoiceURL: null,
          invoiceFileName: "",
        });
        NotificationManager.success(
          "Invoice Deleted Successfully",
          "Delete",
          3000
        );

        if (response.data.error === TokenErrorMsg) {
          localStorage.setItem("Token", "");
          this.props.history.push("/");
        }
      })
      .catch(function(error) {
        NotificationManager.error(
          "Something Went Wrong Server Is Not Responding ",
          "Error Message",
          3000
        );
        console.log(error);
      });
  };

  inputQuickPay = (value) => {
    this.setState({ quickPay: value });
  };

  setInvoiceTo = (value) => {
    value === "Customer"
      ? this.setState({ invoiceTo: value })
      : this.setState({
          invoiceTo: value,
          quickPay: "",
          paymentType: "Quick Pay",
        });
  };

  handleDispatchStopsCheckBox = (index) => {
    var StopData = this.state.StopData;
    StopData[index]["dispatchCheckBox"] = StopData[index]["dispatchCheckBox"]
      ? false
      : true;
    this.setState(StopData);
  };

  resetDispatchStopsCheckBox = () => {
    var StopData = this.state.StopData;
    StopData.forEach((element) => {
      element.dispatchCheckBox = false;
    });
    this.setState({ StopData });
  };

  checkedDispatchStopsCheckBox = (ids) => {
    var idsArray = ids ? ids.split(",") : [];
    var StopData = this.state.StopData;
    StopData.forEach((element) => {
      idsArray.forEach((id) => {
        if (Number(id) === Number(element.stopNo)) {
          element.dispatchCheckBox = true;
        }
      });
    });

    this.setState({ StopData });
  };

  setInvoiceStatus = (value) => {
    this.setState({ invoiceStatus: value });
  };
  dispatchExistanceHandler = (response) => {
    let dispatchedStops = [];
    response.data.dispatchDetail.forEach((dispatch) => {
      let splitedStops = dispatch.shippers.split(",");
      if (splitedStops[0] !== "") {
        dispatchedStops = [...dispatchedStops, ...splitedStops];
      }
      this.setState({ dispatchedStops: dispatchedStops });
    });
  };
  verifyDispatch = (_stopNo) => {
    return this.state.dispatchedStops.length > 0 ? true : false;
  };
  onSortEnd = (oldIndex, type, stopNo) => {
    const doesDispatchExist = this.verifyDispatch();
    if (doesDispatchExist) {
      NotificationManager.warning(
        "You need to uncheck stops from dispatches before changing order",
        "Operation Not Allowed",
        5000
      );
      return false;
    }
    let newIndex = null;
    if (type === "up") newIndex = oldIndex - 1;
    else if (type === "down") newIndex = oldIndex + 1;
    this.shuffleElements(oldIndex, newIndex);
  };
  shuffleElements = (oldIndex, newIndex) => {
    if (newIndex === null) return false;
    let StopData = this.state.StopData;
    if (newIndex >= StopData.length) {
      let k = newIndex - StopData.length + 1;
      while (k--) {
        StopData.push(undefined);
      }
    }
    StopData.splice(newIndex, 0, StopData.splice(oldIndex, 1)[0]);
    StopData.forEach((obj, index) => (obj.stopNo = index + 1));
    this.setState({
      StopData,
    });
  };
   calculateTimeAndDay=(durationSeconds,durationText) =>{
    const durationMilliseconds = durationSeconds * 1000; // Convert seconds to milliseconds
    const oneWeekMilliseconds = 7 * 24 * 60 * 60 * 1000; // One week in milliseconds

    // Check if the duration is less than a week (168 hours)
    if (durationMilliseconds < oneWeekMilliseconds) {
        // Get the current date and time
        let currentDate = new Date();

        // Add the duration in milliseconds
        let newDate = new Date(currentDate.getTime() + durationMilliseconds);

        // Set the time to 9 PM
        // newDate.setHours(21, 0, 0, 0);

        // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
        let dayOfWeek = newDate.getDay();

        // Define an array of day names
        let daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

        // Get the day name from the array
        let dayName = daysOfWeek[dayOfWeek];

        // Format the time and day
        let formattedTime = newDate.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});

        return `${formattedTime} ${dayName}`;
    } else {

        return durationText;
    }
}

// Example: Calculate time and day with a duration of 604800 seconds (1 week)
// let result = calculateTimeAndDay(604800);
// console.log(result);

// // Example: Calculate time and day with a duration of 172800 seconds (2 days)
// result = calculateTimeAndDay(172800);
// console.log(result);

  render() {
    var wayPoints = this.state.wayPoints;

    const msgNone = {
      display: "none",
    };
    const msgShow = {
      display: "block",
    };

    const nonclick = {
      cursor: " notAllowed",
      pointerEvents: "none",
    };

    const {
      StopData,
      loadFormData,
      Documents,
      loadid,
      totalAmount,
      additionalCharges,
      invoicePopUpInputValues,
      paymentType,
      invoiceURL,
      invoiceLoader,
      invoiceFileName,
      quickPay,
      invoiceTo,
      role,
      subRole,
      showSelectedRowOnTabs,
      showSelectedRowOnTabsBoolean,
      showRowPickupDate,
      showRowDeliveryDate,
      showRowPickupCityState,
      showRowDeliveryCityState,
      selectedStopNo,
    } = this.state;
    //  console.log(new Date(StopData[1]["scheduleFrom"]).getTime())
    return (
      <div className="page-container-bg-solid page-header-fixed page-sidebar-closed-hide-logo page-md">
        <Header title="| Load" />
        <div className="page-container">
          <Sidebar editMode={!this.state.readonly} />
          <div className="page-content-wrapper">
            <div className="page-content">
              {/* <div className="page-head"></div> */}

              <div className="row">
                <div className="col-md-12">
                  <div
                    className="tabbable-line tabbable-reversed pt-4"
                    style={{
                      position: "fixed",
                      top: "4%",
                      background: "#E9ECF3",
                      zIndex: 1,
                      width: "100%",
                      padding: "px",
                    }}
                  ></div>
                  <div>
                    <div className="tab-content">
                      <div className="tab-pane active" id="tab_0">
                        <form
                          onSubmit={this.submituserRegistrationForm.bind(this)}
                          className="horizontal-form"
                          autoComplete="off"
                        >
                          <div className="bg-white border-orange yellow-gold">
                            <div className="portlet mb-3 yellow-gold box">
                              <div className="portlet-title" id="pickup">
                                <div className="caption">
                                  <i className="fa fa-cubes"></i>Pickup and
                                  Delivery Details:
                                </div>
                                <div
                                  className="actions"
                                  style={
                                    !this.state.isStopsDetailsEditable
                                      ? {}
                                      : nonclick
                                  }
                                  id="EditDiv"
                                >
                                  {role !== tenantUser ? (
                                    <div
                                      className="btn-group"
                                      style={
                                        this.state.hidden === false
                                          ? msgShow
                                          : msgNone
                                      }
                                    >
                                      <button
                                        type="button"
                                        className="btn sbold white dark"
                                        onClick={() =>
                                          this.EditForm(
                                            "isStopsDetailsEditable"
                                          )
                                        }
                                        hidden={this.state.hidden}
                                        disabled={this.state.hidden}
                                      >
                                        <i className="fa fa-pencil"></i> Edit
                                      </button>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>{" "}
                            <div className="portlet-body form">
                              <div className="max_width800">
                                <SortableContainer useDragHandle>
                                  {StopData.map((StopObj, index) => {
                                    if (selectedStopNo == StopObj.stopNo) {
                                      return (
                                        <>
                                          <div className="card">
                                            {/* <SortableItem
                                            key={`item-${StopObj.id}`}
                                            index={index}
                                            totalStops={StopData.length}
                                            props={StopObj}
                                            readonly={
                                              !this.state.isStopsDetailsEditable
                                            }
                                            value={`Stop ${StopObj.stopNo}`}
                                            stopNo={StopObj.stopNo}
                                            indexValue={index}
                                            stopDeleteHandler={(oldIndex) => {
                                              if (
                                                !window.confirm(
                                                  "Are You Sure You Want To Delete ? "
                                                )
                                              ) {
                                                return false;
                                              } else {
                                                var stop = StopData;
                                                stop.splice(index, 1);
                                                if (this._isMounted) {
                                                  this.setState({
                                                    StopData: stop,
                                                  });
                                                }
                                              }
                                            }}
                                            sortUpHandler={(oldIndex) =>
                                              this.onSortEnd(
                                                oldIndex,
                                                "up",
                                                StopObj.stopNo
                                              )
                                            }
                                            sortDownHandler={(oldIndex) =>
                                              this.onSortEnd(
                                                oldIndex,
                                                "down",
                                                StopObj.stopNo
                                              )
                                            }
                                            dispatchedStops={
                                              this.state.dispatchedStops
                                            }
                                            address={
                                              this.state.StopData[index]
                                                .shipperId !== null
                                                ? StopData[index][
                                                  "ShipperOptions"
                                                ][0] === undefined
                                                  ? ""
                                                  : StopData[index][
                                                    "ShipperOptions"
                                                  ][0].label
                                                : this.state.StopData[index]
                                                  .address
                                            }
                                          /> */}

                                            <div id={`item-${StopObj.stopNo}`}>
                                              <div className="form-group row">
                                                <div className="col-md-9">
                                                  <div className="form-group row">
                                                    <div className="col-md-4">
                                                      Stop {StopObj.stopNo}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="form-group row">
                                                <div className="col-md-12">
                                                  <div className="form-group row">
                                                    <div
                                                      className="col-md-3"
                                                      style={{
                                                        position: "static",
                                                      }}
                                                    >
                                                      <div className="d-flex">
                                                        <label>Verify</label>
                                                      </div>
                                                    </div>
                                                    <div className="col-md-9">
                                                      <div className="checker">
                                                        <span
                                                          className={
                                                            StopData[index][
                                                              "verified"
                                                            ] === null ||
                                                            StopData[index][
                                                              "verified"
                                                            ] === false
                                                              ? ""
                                                              : "checked"
                                                          }
                                                        >
                                                          <input
                                                            type="checkbox"
                                                            onClick={(e) => {
                                                              StopData[index][
                                                                "verified"
                                                              ] = !StopData[
                                                                index
                                                              ]["verified"];
                                                              this.setState({
                                                                verified: !this
                                                                  .state
                                                                  .verified,
                                                              });
                                                            }}
                                                            disabled={
                                                              !this.state
                                                                .isStopsDetailsEditable
                                                            }
                                                          />
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="form-group row">
                                                <label className="col-md-3 control-label">
                                                  {"Stop Type"}
                                                </label>
                                                <div className="col-md-3">
                                                  <label className="form-control">
                                                    {StopObj.stopType
                                                      .toLocaleUpperCase()
                                                      .toString()}
                                                  </label>
                                                </div>
                                              </div>

                                              <div className="form-group row" onClick={
                                                        this.state
                                                          .isStopsDetailsEditable
                                                          ? this.handleShow
                                                          : null
                                                      }>
                                                <div className="col-md-12 ml-2" style={{background:"rgb(23, 80, 102)",textAlign:'center'}}>
                                                  <label className="control-label" style={{color:"white",marginBottom:'0px',fontSize:'18px'}}>
                                                    {"ETA"}
                                                  </label>
                                                </div>
                                                <div className="col-md-12 mt-2">
                                                  <label className="col-md-3 control-label">
                                                    {
                                                      "Estimated Time"
                                                    }
                                                  </label>
                                                  <div className="col-md-8">
                                                    <input
                                                      className="form-control"
                                                    disabled
                                                    readOnly
                                                      type="text"
                                                      value={StopObj.eta
                                                        ? StopObj.currentLocation
                                                          ? StopObj.eta +
                                                            "/" +
                                                            StopObj.currentLocation
                                                          : StopObj.eta
                                                        : "HH:MM"}
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col-md-12 mt-2">
                                                  <label className="col-md-3 control-label">
                                                    {
                                                      "Estimated Last Updated Time "
                                                    }
                                                  </label>
                                                  <div className="col-md-8">
                                                    <input
                                                      className="form-control"
                                                      disabled
                                                      readOnly
                                                     type="text"
                                                      value={StopObj.etaUpdatedAt && !this.state.etaChanged 
                                                        ?Date(StopObj.etaUpdatedAt)
                                                        :this.state.etaChanged?new Date():  "MM/DD/YYYY HH:MM"}
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col-md-12 mt-1">
                                                  <label className="col-md-3 control-label">
                                                    From
                                                  </label>

                                                  <div className="col-md-9">
                                                    <label
                                                      className="form-control"
                                                      readOnly
                                                    >
                                                      {this.state.StopData[
                                                        index
                                                      ].currentLocation !== null
                                                        ?this.state.StopData[
                                                          index
                                                        ].currentLocation:""} {" "}
                                    
                                    </label>
                                                  </div>
                                                </div>
                                                <div className="col-md-12 mt-1">
                                                  <label className="col-md-3 control-label">
                                                    To
                                                  </label>
                                                  <div className="col-md-9">
                                                    <textarea
                                                      className="form-control"
                                                      style={{minHeight:"34px",resize:"none"}}
                                                      readOnly
                                                    value=
                                                      {this.state.StopData[
                                                        index
                                                      ].shipperId !== null
                                                        ? StopData[index][
                                                            "ShipperOptions"
                                                          ][0] === undefined
                                                          ? ""
                                                          : StopData[index][
                                                              "ShipperOptions"
                                                            ][0].label
                                                        : this.state.StopData[
                                                            index
                                                          ].address+" "}
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col-md-12 mt-1">
                                                  <label className="col-md-3">ETA Note</label>
                                                  <div className="col-md-9"><textarea className="form-control" value={this.state.StopData[index].etaNote 
                                                  || ''} readOnly style={{resize:"none"}}/></div>
                                                  
                                                  </div> 
                                                <div className="col-md-12 mt-1">
                                                  <label className="col-md-3">Miles Left</label>
                                                  <div className="col-md-3"><div className="form-control" readOnly>{this.state.milesLeft 
                                                  || 0}</div></div>
                                                  
                                                  </div> 
                                                  
                                                {/* <Modal
                                                  show={this.state.show}
                                                  onHide={this.handleClose}
                                                >
                                                  <Modal.Header closeButton>
                                                    <Modal.Title>
                                                      ETA
                                                    </Modal.Title>
                                                  </Modal.Header>
                                                  <Modal.Body>
                                                    Please Provide the Current
                                                    Location to calculate the
                                                    ETA
                                                  </Modal.Body>
                                                  <Modal.Body>
                                                    <>
                                                      <div className="form-group row">
                                                        <label className="col-md-3 control-label">
                                                          Current Location
                                                        </label>
                                                        <div
                                                          className="col-md-9"
                                                          key={"search"}
                                                        >
                                                          <PlacesAutocomplete
                                                            key={"places-index"}
                                                            searchOptions={
                                                              searchOptions
                                                            }
                                                            value={
                                                              this.state
                                                                .currentLocation
                                                                ? this.state
                                                                    .currentLocation
                                                                : ""
                                                            }
                                                            onChange={(
                                                              address
                                                            ) => {
                                                              console.log(
                                                                address
                                                              );
                                                              this.setState({
                                                                locationChanged: true,
                                                              });
                                                              this.handleCurrentLocationChange(
                                                                address,
                                                                index
                                                              );
                                                            }}
                                                            onSelect={(
                                                              address
                                                            ) =>
                                                              this.handleSelectCurrentLocation(
                                                                address,
                                                                index
                                                              )
                                                            }
                                                            onError={(e) =>
                                                              console.log(e)
                                                            }
                                                            clearItemsOnError={
                                                              true
                                                            }
                                                          >
                                                            {({
                                                              getInputProps,
                                                              suggestions,
                                                              getSuggestionItemProps,
                                                              loading,
                                                            }) => (
                                                              <div className="form-group">
                                                                <div className="">
                                                                  <input
                                                                    type="text"
                                                                    ref="address"
                                                                    {...getInputProps(
                                                                      {
                                                                        className:
                                                                          "location-search-input",
                                                                      }
                                                                    )}
                                                                    name="address"
                                                                    className="form-control"
                                                                    placeholder="City/State"
                                                                  />
                                                                  <div
                                                                    className="autocomplete-dropdown-container"
                                                                    style={{
                                                                      position:
                                                                        "absolute",
                                                                      zIndex: 9,
                                                                      background:
                                                                        "white",
                                                                      width:
                                                                        "92%",
                                                                    }}
                                                                  >
                                                                    {loading && (
                                                                      <div className="suggestion-item">
                                                                        Loading...
                                                                      </div>
                                                                    )}
                                                                    {suggestions.map(
                                                                      (
                                                                        suggestion
                                                                      ) => {
                                                                        const className = suggestion.active
                                                                          ? "suggestion-item--active"
                                                                          : "suggestion-item";
                                                                        return (
                                                                          <div
                                                                            key={JSON.stringify(
                                                                              suggestion
                                                                            )}
                                                                            {...getSuggestionItemProps(
                                                                              suggestion,
                                                                              {
                                                                                className,
                                                                              }
                                                                            )}
                                                                          >
                                                                            <span>
                                                                              {
                                                                                suggestion.description
                                                                              }
                                                                            </span>
                                                                          </div>
                                                                        );
                                                                      }
                                                                    )}
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            )}
                                                          </PlacesAutocomplete>
                                                        </div>
                                                      </div>
                                                    </>

                                                    <div className="form-body max_width800">
                                                      <div className="portlet-title">
                                                        <h2 className="h3">
                                                          {" "}
                                                          Miles Details:
                                                        </h2>
                                                        <div className="clearfix"></div>
                                                        <div className="form-group row">
                                                          <label className="col-md-3 control-label">
                                                            Miles Left:
                                                            <span className="font-red-thunderbird"></span>
                                                          </label>
                                                          <div className="col-md-9">
                                                            <input
                                                              type="text"
                                                              name="milesDetails"
                                                              className="form-control"
                                                              readOnly
                                                              placeholder="Miles/KM"
                                                              value={
                                                                this.state
                                                                  .milesLeft
                                                              }
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="form-group row">
                                                      <label className="col-md-3 control-label">
                                                        ETA:
                                                        <span className="font-red-thunderbird"></span>
                                                      </label>
                                                      <div className="col-md-9">
                                                        <input
                                                          type="text"
                                                          name="milesDetails"
                                                          className="form-control"
                                                          disabled={
                                                            this.state.readonly
                                                          }
                                                          placeholder="HH:MM"
                                                          value={StopObj.eta}
                                                          onChange={(e) => {
                                                            var stop = StopData;
                                                            stop[index]["eta"] =
                                                              e.target.value;
                                                            if (
                                                              this._isMounted
                                                            ) {
                                                              this.setState({
                                                                StopData: stop,
                                                                locationChanged: false,
                                                              });
                                                            }
                                                          }}
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="form-group row">
                                                      <label className="col-md-3 control-label">
                                                        Notes:
                                                      </label>
                                                      <div className="col-md-9">
                                                        <textarea
                                                          id="etaNotes"
                                                          style={{
                                                            resize: "none",
                                                          }}
                                                          className="form-control"
                                                          value={
                                                            StopData[index][
                                                              "etaNote"
                                                            ]
                                                          }
                                                          disabled={
                                                            this.state.readonly
                                                          }
                                                          onChange={(e) => {
                                                            var stop = StopData;
                                                            stop[index][
                                                              "etaNote"
                                                            ] = e.target.value;
                                                            if (
                                                              this._isMounted
                                                            ) {
                                                              this.setState({
                                                                StopData: stop,
                                                              });
                                                            }
                                                          }}
                                                        ></textarea>
                                                      </div>
                                                    </div>
                                                  </Modal.Body>
                                                  <Modal.Footer>
                                                    <Button
                                                      variant="secondary"
                                                      onClick={this.handleClose}
                                                    >
                                                      Close
                                                    </Button>
                                                    <Button
                                                      variant="primary"
                                                      onClick={() => {
                                                        this.confirmation(
                                                          index
                                                        );
                                                      }}
                                                    >
                                                      Confirm
                                                    </Button>
                                                  </Modal.Footer>
                                                </Modal> */}
                                              </div>
                                              <b>
                                              <hr style={{borderTop:'1px solid rgb(23, 80, 102)'}}/>
                                              </b>
                                              <div className="form-group row mt-2">
                                                <label className="col-md-3 control-label">
                                                  Schedule:
                                                </label>
                                                <div className="col-md-9">
                                                  <div className="form-group row">
                                                    <div
                                                      className="col-md-5"
                                                      style={{
                                                        position: "static",
                                                      }}
                                                    >
                                                      {/* 123123 */}
                                                      <DatePicker
                                                        disabled={
                                                          true
                                                        }
                                                        required
                                                        className="form-control"
                                                        placeholderText={
                                                          StopData[index][
                                                            "scheduleFTime"
                                                          ] == null &&
                                                          "MM/DD/YYYY HH:MM"
                                                        }
                                                        selected={
                                                          StopData[index][
                                                            "scheduleFTime"
                                                          ] !== null &&
                                                          new Date(
                                                            moment
                                                              .utc(
                                                                // (
                                                                StopData[index][
                                                                  "scheduleFrom"
                                                                ]
                                                              )
                                                              .format(
                                                                "MM/DD/YYYY HH:mm:ss"
                                                              )
                                                          )
                                                        }
                                                        showTimeSelect
                                                        showTimeInput
                                                        timeInputLabel="Input Time:"
                                                        timeFormat="HH:mm"
                                                        timeIntervals={30}
                                                        dateFormat="MM/dd/yyyy HH:mm"
                                                        timeCaption="Time"
                                                        onChange={(date) => {
                                                          if (date == null) {
                                                            StopData[index][
                                                              "scheduleFTime"
                                                            ] = null;
                                                            StopData[index][
                                                              "scheduleFrom"
                                                            ] = moment().set({
                                                              hour: 0,
                                                              minute: 0,
                                                              second: 0,
                                                            });
                                                            if (
                                                              this._isMounted
                                                            ) {
                                                              this.setState({
                                                                StopData: StopData,
                                                              });
                                                            }
                                                            return false;
                                                          }
                                                          let getUTC = getTimeZone();
                                                          let splitUTC = getUTC.split(
                                                            ":"
                                                          );
                                                          let getHourse =
                                                            splitUTC[0];
                                                          let getMints =
                                                            splitUTC[1];

                                                          if (
                                                            getHourse.includes(
                                                              "-"
                                                            )
                                                          ) {
                                                            let split = getHourse.split(
                                                              "-"
                                                            );
                                                            split = parseInt(
                                                              split[1]
                                                            );
                                                            let finalMints = parseInt(
                                                              getMints
                                                            );
                                                            let getFinalDate = new Date(
                                                              moment(date)
                                                                // .utc()
                                                                .toDate()
                                                            );
                                                            let setDateFinal = getFinalDate.setHours(
                                                              getFinalDate.getHours() -
                                                                split
                                                            );
                                                            setDateFinal =
                                                              setDateFinal -
                                                              finalMints *
                                                                60 *
                                                                1000;
                                                            date = setDateFinal;
                                                          } else {
                                                            let split = getHourse.split(
                                                              "+"
                                                            );
                                                            split = parseInt(
                                                              split[1]
                                                            );
                                                            let finalMints = parseInt(
                                                              getMints
                                                            );
                                                            let getFinalDate = new Date(
                                                              moment(date)
                                                                // .utc()
                                                                .toDate()
                                                            );
                                                            let setDateFinal = getFinalDate.setHours(
                                                              getFinalDate.getHours() +
                                                                split
                                                            );
                                                            setDateFinal =
                                                              setDateFinal -
                                                              finalMints *
                                                                60 *
                                                                1000;
                                                            date = setDateFinal;
                                                          }
                                                          // if (date > new Date(StopData[index]["scheduleTo"]).getTime()) {
                                                          //   NotificationManager.error(
                                                          //     "delivery date cannot be greater than pickup!",
                                                          //     "Error Message",
                                                          //     3000
                                                          //   );
                                                          // } else {
                                                          var stop = StopData;
                                                          stop[index][
                                                            "scheduleFrom"
                                                          ] = date;
                                                          StopData[index][
                                                            "scheduleFTime"
                                                          ] = date;
                                                          // console.log(stop[index]['scheduleFTime']);
                                                          if (this._isMounted) {
                                                            this.setState({
                                                              StopData: stop,
                                                            });
                                                          }
                                                          // }
                                                        }}
                                                      />
                                                    </div>
                                                    <div className="col-md-2 text-center">
                                                      TO
                                                    </div>
                                                    <div
                                                      className="col-md-5"
                                                      style={{
                                                        position: "static",
                                                      }}
                                                    >
                                                      <DatePicker
                                                        disabled={
                                                          true
                                                        }
                                                        required
                                                        className="form-control"
                                                        placeholderText={
                                                          StopData[index][
                                                            "scheduleTTime"
                                                          ] == null
                                                            ? "MM/DD/YYYY HH:MM"
                                                            : false
                                                        }
                                                        selected={
                                                          StopData[index][
                                                            "scheduleTTime"
                                                          ] !== null
                                                            ? new Date(
                                                                moment
                                                                  .utc(
                                                                    // (
                                                                    StopData[
                                                                      index
                                                                    ][
                                                                      "scheduleTo"
                                                                    ]
                                                                  )
                                                                  .format(
                                                                    "MM/DD/YYYY HH:mm:ss"
                                                                  )
                                                              )
                                                            : false
                                                        }
                                                        showTimeSelect
                                                        showTimeInput
                                                        timeInputLabel="Input Time:"
                                                        timeFormat="HH:mm"
                                                        timeIntervals={30}
                                                        dateFormat="MM/dd/yyyy HH:mm"
                                                        timeCaption="Time"
                                                        onChange={(date) => {
                                                          // console.log(date);
                                                          // console.log('current',date,'schedule From', new Date(StopData[index]['scheduleFrom']).getUTCHours());
                                                          if (date == null) {
                                                            StopData[index][
                                                              "scheduleTTime"
                                                            ] = null;
                                                            StopData[index][
                                                              "scheduleTo"
                                                            ] = moment().set({
                                                              hour: 0,
                                                              minute: 0,
                                                              second: 0,
                                                            });
                                                            if (
                                                              this._isMounted
                                                            ) {
                                                              this.setState({
                                                                StopData: StopData,
                                                              });
                                                            }
                                                            return false;
                                                          }
                                                          let getUTC = getTimeZone();

                                                          let splitUTC = getUTC.split(
                                                            ":"
                                                          );
                                                          let getHourse =
                                                            splitUTC[0];
                                                          let getMints =
                                                            splitUTC[1];

                                                          if (
                                                            getHourse.includes(
                                                              "-"
                                                            )
                                                          ) {
                                                            let split = getHourse.split(
                                                              "-"
                                                            );
                                                            split = parseInt(
                                                              split[1]
                                                            );
                                                            let finalMints = parseInt(
                                                              getMints
                                                            );
                                                            let getFinalDate = new Date(
                                                              moment(date)
                                                                // .utc()
                                                                .toDate()
                                                            );
                                                            let setDateFinal = getFinalDate.setHours(
                                                              getFinalDate.getHours() -
                                                                split
                                                            );
                                                            setDateFinal =
                                                              setDateFinal -
                                                              finalMints *
                                                                60 *
                                                                1000;
                                                            date = setDateFinal;
                                                          } else {
                                                            let split = getHourse.split(
                                                              "+"
                                                            );
                                                            split = parseInt(
                                                              split[1]
                                                            );
                                                            let finalMints = parseInt(
                                                              getMints
                                                            );
                                                            let getFinalDate = new Date(
                                                              moment(date)
                                                                // .utc()
                                                                .toDate()
                                                            );
                                                            let setDateFinal = getFinalDate.setHours(
                                                              getFinalDate.getHours() +
                                                                split
                                                            );
                                                            setDateFinal =
                                                              setDateFinal -
                                                              finalMints *
                                                                60 *
                                                                1000;
                                                            date = setDateFinal;
                                                          }

                                                          // if (date < new Date(StopData[index]["scheduleFrom"]).getTime()) {
                                                          //   let from = new Date(StopData[index]['scheduleFrom'])
                                                          //   if (current.getDay() + current.getMonth() + current.getFullYear()
                                                          //     == from.getDay() + from.getMonth() + from.getFullYear()) {
                                                          //     console.log('Dates are same...');
                                                          //     var stop = StopData;
                                                          //     stop[index][
                                                          //       "scheduleTo"
                                                          //     ] = date;
                                                          //     StopData[index]["scheduleTTime"] = date
                                                          //     if (this._isMounted) {
                                                          //       this.setState({
                                                          //         StopData: stop,
                                                          //       });
                                                          //     }
                                                          //   } else {
                                                          //     NotificationManager.error(
                                                          //       "delivery date cannot be greater than pickup!",
                                                          //       "Error Message",
                                                          //       3000
                                                          //     );
                                                          //   }
                                                          // } else {
                                                          var stop = StopData;
                                                          stop[index][
                                                            "scheduleTo"
                                                          ] = date;
                                                          StopData[index][
                                                            "scheduleTTime"
                                                          ] = date;
                                                          if (this._isMounted) {
                                                            this.setState({
                                                              StopData: stop,
                                                            });
                                                          }
                                                          // }
                                                        }}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              {/* )}  */}

                                              <div className="form-group row">
                                                <label className="col-md-3 control-label">
                                                  {StopData[index][
                                                    "stopType"
                                                  ] === "pickup"
                                                    ? "Driver"
                                                    : StopData[index][
                                                        "stopType"
                                                      ] === "delivery"
                                                    ? "Driver"
                                                    : "Transit"}
                                                  Status:
                                                </label>
                                                <div className="col-md-9">
                                                  <div
                                                    className="checkbox-list col-md-12"
                                                   
                                                  >
                                                    <label className="checkbox-inline">
                                                      Checked In &nbsp;
                                                    </label>
                                                    <div
                                                      style={{
                                                        position: "static",
                                                        display: "inline-block",
                                                        marginLeft: "63px",
                                                      }}
                                                    >
                                                      <DatePicker
                                                        disabled={
                                                          this.state.readonly
                                                        }
                                                        placeholderText={
                                                          StopData[index][
                                                            "driverCheckIn"
                                                          ] ||
                                                          "MM/DD/YYYY HH:MM"
                                                        }
                                                        className="form-control"
                                                        // selected={
                                                        //   StopData[index]['checkedInT'] !== 0 && new Date(
                                                        //     // moment.utc(
                                                        //     StopData[index][
                                                        //     "driverCheckIn"
                                                        //     ]
                                                        //     // ).format("MM/DD/YYYY HH:mm:ss")
                                                        //   )
                                                        // }
                                                        selected={
                                                          StopData[index][
                                                            "driverCheckIn"
                                                          ] &&
                                                          new Date(
                                                            moment
                                                              .utc(
                                                                StopData[index][
                                                                  "driverCheckIn"
                                                                ]
                                                              )
                                                              .format(
                                                                "MM/DD/YYYY HH:mm:ss"
                                                              )
                                                          )
                                                        }
                                                        showTimeSelect
                                                        showTimeInput
                                                        timeInputLabel="Input Time:"
                                                        timeFormat="HH:mm"
                                                        timeIntervals={30}
                                                        dateFormat={
                                                          "MM/dd/yyyy HH:mm"
                                                        }
                                                        // dateFormat={
                                                        //   StopData[index][
                                                        //     "checkedIn"
                                                        //   ] === "1"
                                                        //     ? "MM/dd/yyyy HH:mm"
                                                        //     : "HH:mm"
                                                        // }
                                                        timeCaption="Time"
                                                        onChange={(date) => {
                                                          var stop = StopData;
                                                          if (date == null) {
                                                            if (
                                                              this._isMounted
                                                            ) {
                                                              this.setState({
                                                                StopData: StopData,
                                                              });
                                                            }
                                                            return false;
                                                          }
                                                          console.log(
                                                            "---------------",
                                                            date
                                                          );
                                                          stop[index][
                                                            "driverCheckIn"
                                                          ] = new Date(date);
                                                          this.setState({
                                                            StopData: stop,
                                                          });
                                                          // if (this._isMounted) {
                                                          //   this.setState({
                                                          //     StopData: stop,
                                                          //   });
                                                          // }
                                                          // }
                                                        }}
                                                      />
                                                    </div>
                                                    <div className="form-group row mt-2">
                                                      <div className="col-md-3"><label  className=" control-label">CheckIn Note:</label></div>
                                                      <div class="col-md-9"><textarea id="notes" className="form-control" style={{"resize": "none"}}
                                                      value={
                                                      StopData[index][
                                                        "driverCheckInNote"
                                                      ]
                                                    }
                                                    disabled={
                                                      this.state.readonly
                                                    }
                                                    onChange={(e) => {
                                                      var stop = StopData;
                                                      stop[index][
                                                        "driverCheckInNote"
                                                      ] = e.target.value;
                                                      console.log(
                                                        `Stop---------------------`,
                                                        stop
                                                      );
                                                      if (this._isMounted) {
                                                        this.setState({
                                                          StopData: stop,
                                                        });
                                                      }
                                                    }}

                                                      
                                                      >**PRE COOL**dsadsaddsas</textarea></div>

                                                    </div>
                                                  </div>
                                                  <div
                                                    className="checkbox-list col-md-12"
                                                                                                     >
                                                    <label className="checkbox-inline">
                                                      Checked Out
                                                    </label>
                                                    {/* <div
                                                      style={{
                                                        position: "static",
                                                        display: "inline-block",
                                                        marginLeft: "60px",
                                                      }}
                                                    >
                                                      <DatePicker
                                                        disabled={
                                                          this.state.readonly
                                                        }
                                                        className="form-control"
                                                        placeholderText={
                                                          StopData[index][
                                                            "driverCheckOut"
                                                          ] == 0 &&
                                                          "MM/DD/YYYY HH:MM"
                                                        }
                                                        selected={
                                                          StopData[index][
                                                            "driverCheckOut"
                                                          ] !== 0 &&
                                                          new Date(
                                                            // moment.utc(
                                                            StopData[index][
                                                              "driverCheckOut"
                                                            ]
                                                            // ).format("MM/DD/YYYY HH:mm:ss")
                                                          )
                                                        }
                                                        showTimeSelect
                                                        showTimeInput
                                                        timeInputLabel="Input Time:"
                                                        timeFormat="HH:mm"
                                                        timeIntervals={30}
                                                        dateFormat={
                                                          StopData[index][
                                                            "driverCheckOut"
                                                          ] === "1"
                                                            ? "MM/dd/yyyy HH:mm"
                                                            : "HH:mm"
                                                        }
                                                        timeCaption="Time"
                                                        onChange={(date) => {
                                                          var stop = StopData;
                                                          if (date == null) {
                                                            stop[index][
                                                              "checkedOutT"
                                                            ] = 0;
                                                            stop[index][
                                                              "checkedOutTime"
                                                            ] = moment().set({
                                                              hour: 0,
                                                              minute: 0,
                                                              second: 0,
                                                            });
                                                            if (
                                                              this._isMounted
                                                            ) {
                                                              this.setState({
                                                                StopData: StopData,
                                                              });
                                                            }
                                                            return false;
                                                          }
                                                          // stop[index]['checkedOutT'] = date.getTime()
                                                          // if (date.getTime() < stop[index]['checkedInT'] && stop[index]['checkedInT'] !== 0) {
                                                          //   console.log('CheckedOut Time Cannnot be greater');
                                                          //   NotificationManager.error(
                                                          //     `CheckedOut Time can not be less than CheckedIn Time.`,
                                                          //     "Error Message",
                                                          //     3000
                                                          //   );
                                                          // } else {
                                                          stop[index][
                                                            "checkedOutTime"
                                                          ] = date;
                                                          stop[index][
                                                            "checkedOut"
                                                          ] = "1";
                                                          if (this._isMounted) {
                                                            this.setState({
                                                              StopData: stop,
                                                            });
                                                          }
                                                          // }
                                                        }}
                                                      />
                                                    </div> */}
                                                    <div
                                                      style={{
                                                        position: "static",
                                                        display: "inline-block",
                                                        marginLeft: "63px",
                                                      }}
                                                    >
                                                      <DatePicker
                                                        disabled={
                                                          this.state.readonly
                                                        }
                                                        placeholderText={
                                                          StopData[index][
                                                            "driverCheckOut"
                                                          ] ||
                                                          "MM/DD/YYYY HH:MM"
                                                        }
                                                        className="form-control"
                                                        // selected={
                                                        //   StopData[index]['checkedInT'] !== 0 && new Date(
                                                        //     // moment.utc(
                                                        //     StopData[index][
                                                        //     "driverCheckIn"
                                                        //     ]
                                                        //     // ).format("MM/DD/YYYY HH:mm:ss")
                                                        //   )
                                                        // }
                                                        selected={
                                                          StopData[index][
                                                            "driverCheckOut"
                                                          ] &&
                                                          new Date(
                                                            moment
                                                              .utc(
                                                                StopData[index][
                                                                  "driverCheckOut"
                                                                ]
                                                              )
                                                              .format(
                                                                "MM/DD/YYYY HH:mm:ss"
                                                              )
                                                          )
                                                        }
                                                        showTimeSelect
                                                        showTimeInput
                                                        timeInputLabel="Input Time:"
                                                        timeFormat="HH:mm"
                                                        timeIntervals={30}
                                                        dateFormat={
                                                          "MM/dd/yyyy HH:mm"
                                                        }
                                                        // dateFormat={
                                                        //   StopData[index][
                                                        //     "checkedIn"
                                                        //   ] === "1"
                                                        //     ? "MM/dd/yyyy HH:mm"
                                                        //     : "HH:mm"
                                                        // }
                                                        timeCaption="Time"
                                                        onChange={(date) => {
                                                          var stop = StopData;
                                                          if (date == null) {
                                                            if (
                                                              this._isMounted
                                                            ) {
                                                              this.setState({
                                                                StopData: StopData,
                                                              });
                                                            }
                                                            return false;
                                                          }
                                                          console.log(
                                                            "---------------",
                                                            date
                                                          );
                                                          stop[index][
                                                            "driverCheckOut"
                                                          ] = new Date(date);
                                                          this.setState({
                                                            StopData: stop,
                                                          });
                                                          // if (this._isMounted) {
                                                          //   this.setState({
                                                          //     StopData: stop,
                                                          //   });
                                                          // }
                                                          // }
                                                        }}
                                                      />
                                                    </div>
                                                    <div className="form-group row mt-2">
                                                      <div className="col-md-3"><label  className=" control-label">CheckOut Note:</label></div>
                                                      <div class="col-md-9"><textarea id="notes" className="form-control"  style={{"resize": "none"}}
                                                      
                                                      value={
                                                        StopData[index][
                                                          "driverCheckOutNote"
                                                        ]
                                                      }
                                                      disabled={
                                                        this.state.readonly
                                                      }
                                                      onChange={(e) => {
                                                        var stop = StopData;
                                                        stop[index][
                                                          "driverCheckOutNote"
                                                        ] = e.target.value;
                                                        console.log(
                                                          `Stop---------------------`,
                                                          stop
                                                        );
                                                        if (this._isMounted) {
                                                          this.setState({
                                                            StopData: stop,
                                                          });
                                                        }
                                                      }}
  
                                                      
                                                      
                                                      >**PRE COOL**dsadsaddsas</textarea></div>

                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="form-group row">
                                                <label className="col-md-3 control-label">
                                                  Notes:
                                                </label>
                                                <div className="col-md-9">
                                                  <textarea
                                                    id="notes"
                                                    style={{ resize: "none" }}
                                                    className="form-control"
                                                    value={
                                                      StopData[index]["notes"]
                                                    }
                                                    disabled={
                                                      this.state.readonly
                                                    }
                                                    onChange={(e) => {
                                                      var stop = StopData;
                                                      stop[index]["notes"] =
                                                        e.target.value;
                                                      if (this._isMounted) {
                                                        this.setState({
                                                          StopData: stop,
                                                        });
                                                      }
                                                    }}
                                                  ></textarea>
                                                </div>
                                              </div>
                                              <div className="form-group row">
                                                <label className="col-md-3 control-label">
                                                  Driver Notes:
                                                </label>
                                                <div className="col-md-9">
                                                  <textarea
                                                    id="driverNotes"
                                                    style={{ resize: "none" }}
                                                    className="form-control"
                                                    value={
                                                      StopData[index][
                                                        "driverNote"
                                                      ]
                                                    }
                                                    disabled={
                                                      this.state.readonly
                                                    }
                                                    onChange={(e) => {
                                                      var stop = StopData;
                                                      stop[index][
                                                        "driverNote"
                                                      ] = e.target.value;
                                                      console.log(
                                                        `Stop---------------------`,
                                                        stop
                                                      );
                                                      if (this._isMounted) {
                                                        this.setState({
                                                          StopData: stop,
                                                        });
                                                      }
                                                    }}
                                                  ></textarea>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <Modal
                                                  show={this.state.show}
                                                  onHide={this.handleClose}
                                                >
                                                  <Modal.Header closeButton>
                                                    <Modal.Title>
                                                      ETA
                                                    </Modal.Title>
                                                  </Modal.Header>
                                                  <Modal.Body>
                                                    Please Provide the Current
                                                    Location to calculate the
                                                    ETA
                                                  </Modal.Body>
                                                  <Modal.Body>
                                                    <>
                                                      <div className="form-group row">
                                                        <label className="col-md-3 control-label">
                                                          Current Location
                                                        </label>
                                                        <div
                                                          className="col-md-9"
                                                          key={"search"}
                                                        >
                                                          <PlacesAutocomplete
                                                            key={"places-index"}
                                                            searchOptions={
                                                              searchOptions
                                                            }
                                                            value={
                                                              this.state
                                                                .currentLocation
                                                                ? this.state
                                                                    .currentLocation
                                                                : ""
                                                            }
                                                            onChange={(
                                                              address
                                                            ) => {
                                                              console.log(
                                                                address
                                                              );
                                                              this.setState({
                                                                locationChanged: true,
                                                              });
                                                              this.handleCurrentLocationChange(
                                                                address,
                                                                index
                                                              );
                                                            }}
                                                            onSelect={(
                                                              address
                                                            ) =>
                                                              this.handleSelectCurrentLocation(
                                                                address,
                                                                index
                                                              )
                                                            }
                                                            onError={(e) =>
                                                              console.log(e)
                                                            }
                                                            clearItemsOnError={
                                                              true
                                                            }
                                                          >
                                                            {({
                                                              getInputProps,
                                                              suggestions,
                                                              getSuggestionItemProps,
                                                              loading,
                                                            }) => (
                                                              <div className="form-group">
                                                                <div className="">
                                                                  <input
                                                                    type="text"
                                                                    ref="address"
                                                                    {...getInputProps(
                                                                      {
                                                                        className:
                                                                          "location-search-input",
                                                                      }
                                                                    )}
                                                                    name="address"
                                                                    className="form-control"
                                                                    placeholder="City/State"
                                                                  />
                                                                  <div
                                                                    className="autocomplete-dropdown-container"
                                                                    style={{
                                                                      position:
                                                                        "absolute",
                                                                      zIndex: 9,
                                                                      background:
                                                                        "white",
                                                                      width:
                                                                        "92%",
                                                                    }}
                                                                  >
                                                                    {loading && (
                                                                      <div className="suggestion-item">
                                                                        Loading...
                                                                      </div>
                                                                    )}
                                                                    {suggestions.map(
                                                                      (
                                                                        suggestion
                                                                      ) => {
                                                                        const className = suggestion.active
                                                                          ? "suggestion-item--active"
                                                                          : "suggestion-item";
                                                                        return (
                                                                          <div
                                                                            key={JSON.stringify(
                                                                              suggestion
                                                                            )}
                                                                            {...getSuggestionItemProps(
                                                                              suggestion,
                                                                              {
                                                                                className,
                                                                              }
                                                                            )}
                                                                          >
                                                                            <span>
                                                                              {
                                                                                suggestion.description
                                                                              }
                                                                            </span>
                                                                          </div>
                                                                        );
                                                                      }
                                                                    )}
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            )}
                                                          </PlacesAutocomplete>
                                                        </div>
                                                      </div>
                                                    </>

                                                    <div className="form-body max_width800">
                                                      <div className="portlet-title">
                                                        <h2 className="h3">
                                                          {" "}
                                                          Miles Details:
                                                        </h2>
                                                        <div className="clearfix"></div>
                                                        <div className="form-group row">
                                                          <label className="col-md-3 control-label">
                                                            Miles Left:
                                                            <span className="font-red-thunderbird"></span>
                                                          </label>
                                                          <div className="col-md-9">
                                                            <input
                                                              type="number"
                                                              name="milesDetails"
                                                              className="form-control"
                                                              disabled={
                                                                this.state.readonly
                                                              }
                                                              onChange={(e) => {
                                                                var stop = StopData;
                                                                stop[index]["milesLeft"] =
                                                                  Number(e.target.value);
                                                                  // stop[index]["etaUpdatedAt"] =new Date().getUTCDate()
                                                                  e.target.value;
                                                                if (
                                                                  this._isMounted
                                                                ) {
                                                                  this.setState({
                                                                    StopData: stop,
                                                                    locationChanged: false,
                                                                    etaChanged:new Date(),
                                                                  });
                                                                }
                                                              }}
                                                              placeholder="Miles/KM"
                                                              value={
                                                                StopData[index]["milesLeft"]
                                                              }
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="form-group row">
                                                      <label className="col-md-3 control-label">
                                                        ETA:
                                                        <span className="font-red-thunderbird"></span>
                                                      </label>
                                                      <div className="col-md-9">
                                                        <input
                                                          type="text"
                                                          name="milesDetails"
                                                          className="form-control"
                                                          disabled={
                                                            this.state.readonly
                                                          }
                                                          placeholder="HH:MM"
                                                          value={StopObj.eta}
                                                          onChange={(e) => {
                                                            var stop = StopData;
                                                            stop[index]["eta"] =
                                                              e.target.value;
                                                              // stop[index]["etaUpdatedAt"] =new Date().getUTCDate()

                                                            if (
                                                              this._isMounted
                                                            ) {
                                                              this.setState({
                                                                StopData: stop,
                                                                locationChanged: false,
                                                                etaChanged:new Date(),
                                                              });
                                                            }
                                                          }}
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="form-group row">
                                                      <label className="col-md-3 control-label">
                                                        Notes:
                                                      </label>
                                                      <div className="col-md-9">
                                                        <textarea
                                                          id="etaNotes"
                                                          style={{
                                                            resize: "none",
                                                          }}
                                                          className="form-control"
                                                          value={
                                                            StopData[index][
                                                              "etaNote"
                                                            ]
                                                          }
                                                          disabled={
                                                            this.state.readonly
                                                          }
                                                          onChange={(e) => {
                                                            var stop = StopData;
                                                            stop[index][
                                                              "etaNote"
                                                            ] = e.target.value;
                                                            if (
                                                              this._isMounted
                                                            ) {
                                                              this.setState({
                                                                StopData: stop,
                                                              });
                                                            }
                                                          }}
                                                        ></textarea>
                                                      </div>
                                                    </div>
                                                  </Modal.Body>
                                                  <Modal.Footer>
                                                    <Button
                                                      variant="secondary"
                                                      onClick={this.handleClose}
                                                    >
                                                      Close
                                                    </Button>
                                                    <Button
                                                      variant="primary"
                                                      onClick={() => {
                                                        this.confirmation(
                                                          index
                                                        );
                                                      }}
                                                    >
                                                      Confirm
                                                    </Button>
                                                  </Modal.Footer>
                                                </Modal>
                                        </>
                                      );
                                    } else {
                                      return null;
                                    }
                                    
                                  })}
                                </SortableContainer>
                                <div className="clearfix"></div>
                                <br />
                                <div className="clearfix"></div>
                                <div className="text-right">
                                  <button
                                    onClick={this.cancel.bind(this)}
                                    type="button"
                                    className="btn default"
                                  >
                                    Cancel
                                  </button>
                                  &nbsp;
                                  {role !== tenantUser ? (
                                    <button
                                      disabled={
                                        !this.state.isStopsDetailsEditable
                                      }
                                      type="submit"
                                      className="btn green-meadow"
                                    >
                                      {this.state.whileSave === false ? (
                                        <i className="fa fa-save"></i>
                                      ) : (
                                        <i className="fa fa-circle-o-notch fa-spin"></i>
                                      )}
                                      Save
                                    </button>
                                  ) : null}
                                </div>
                                <br />
                              </div>
                            </div>
                          </div>
                        </form>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
          </div>

          <div
            className="modal fade"
            id="contact"
            data-backdrop="static"
            data-keyboard="false"
            tabIndex="-1"
            aria-hidden="true"
          >
            <div className="modal-dialog  modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    id="closePopUp"
                    data-dismiss="modal"
                    aria-hidden="true"
                    hidden={true}
                  ></button>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                    onClick={this.ClearContact}
                  ></button>
                  <h4 className="modal-title">Contact</h4>
                </div>
                <div className="modal-body">
                  <div className="portlet-body form">
                    <form
                      onSubmit={this.SaveContact.bind(this)}
                      className="horizontal-form"
                    >
                      <div className="form-body max_width800">
                        <br />

                        <div className="form-group row">
                          <label className="col-md-3 control-label">
                            Name
                            <span className="font-red-thunderbird">*</span>
                          </label>
                          <div className="col-md-9">
                            <input
                              type="text"
                              ref="ContactName"
                              pattern="[a-z A-Z0-9-'@!$#:;,.-_\x22 ]{1,200}"
                              title="Please Enter Contact Name (Max limit: 200)"
                              className="form-control"
                              placeholder=""
                              required
                            />
                          </div>
                        </div>

                        <div className="form-group row">
                          <label className="col-md-3 control-label">
                            Title
                          </label>
                          <div className="col-md-9">
                            <input
                              type="text"
                              ref="ContactTitle"
                              pattern="[a-z A-Z'(),-_.\x22 ]{1,200}"
                              title="Please Enter title (Max limit: 200)"
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>

                        <div className="form-group row">
                          <label className="col-md-3 control-label">
                            Email
                          </label>
                          <div className="col-md-9">
                            <input
                              type="email"
                              ref="ContactEmail"
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>

                        <div className="form-group row">
                          <label className="col-md-3 control-label">
                            Telephone
                          </label>
                          <div className="col-md-9 form-inline">
                            <input
                              type="text"
                              ref="ContactTelephone"
                              id="contactTelephone"
                              pattern="[a-z A-Z0-9- ]{12}"
                              title="Please Enter Full lenght Telephone (12 digits)"
                              onChange={this.AutoFormatContact}
                              className="form-control"
                              placeholder="xxx-xxx-xxxx"
                            />
                            <span className="help-inline"> EXT </span>
                            <input
                              type="text"
                              pattern="[0-9]{1,10}"
                              title="Please Enter extention (Max 10 Digits Only)"
                              ref="ContactExt"
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-md-3 control-label">
                            Cell Phone
                          </label>
                          <div className="col-md-9">
                            <input
                              type="text"
                              ref="ContactPhone"
                              id="contactPhone"
                              pattern="[a-z A-Z0-9- ]{12}"
                              title="Please Enter Full lenght Cellphone (12 digits)"
                              onChange={this.AutoFormatContact}
                              className="form-control"
                              placeholder="xxx-xxx-xxxx"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-md-3 control-label">Fax</label>
                          <div className="col-md-9">
                            <input
                              type="text"
                              ref="ContactFax"
                              id="contactFax"
                              pattern="[a-z A-Z0-9- ]{12}"
                              title="Please Enter Full lenght fax (12 digits)"
                              onChange={this.AutoFormatContact}
                              className="form-control"
                              placeholder="xxx-xxx-xxxx"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="clearfix"></div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn default"
                          onClick={this.ClearContact}
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                        &nbsp;
                        <button type="submit" className="btn green-meadow">
                          <i className="fa fa-check"></i> Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="customer_info"
            data-backdrop="static"
            data-keyboard="false"
            tabIndex="-1"
            aria-hidden="true"
          >
            <div className="modal-dialog  modal-lg">
              <Customer />
            </div>
          </div>
          <Map
            style={{ display: "block" }}
            key={"TotalMilesMap"}
            soureLatLng={this.state.soureLatLng}
            destinationLatLng={this.state.destinationLatLng}
            wayPoints={this.getFormattedWayPoints(wayPoints)}
            setTotalMiles={this.setTotalMiles}
            setDurationToReach={(duration) => {
              
              console.log(
                "Total Duration Left To Reach-------------",
                duration
              );
            }}
          />
          <Map
            style={{ display: "none" }}
            key={"currentLocationMap"}
            soureLatLng={this.state.currentLocationLatLng}
            destinationLatLng={this.state.destinationLatLng}
            wayPoints={this.getFormattedWayPoints(wayPoints)}
            setTotalMiles={this.setTotalMilesLeft}
            setDurationToReach={(duration) => {
              console.log(`Duration-----------------`,duration,this.state.locationChanged)
              if (this.state.locationChanged) {
                var stop = StopData;
                const currentTime = new Date();
                const etaTime = new Date(currentTime.getTime() + duration.value);
                if (this._isMounted) {
                  console.log(
                    `--------------------------------------ssss`,
                    this.state.selectedIndex
                  );
                  if (
                    this.state.selectedIndex != undefined ||
                    !stop[this.state.selectedIndex].eta
                  ) {
                    stop[this.state.selectedIndex].eta = "";

                    stop[this.state.selectedIndex]["eta"] = this.calculateTimeAndDay(duration.value,duration.text);
                   
                    stop[this.state.selectedIndex].etaDateTime =  etaTime;
                        // stop[this.state.selectedIndex]["etaUpdatedAt"] =new Date()


                    
                  
                  }else{
                    stop[this.state.selectedIndex]["eta"] = this.calculateTimeAndDay(duration.value,duration.text);
                    stop[this.state.selectedIndex].etaDateTime =  etaTime;
                        // stop[this.state.selectedIndex]["etaUpdatedAt"] =new Date()

                  }
                  this.setState({
                    StopData: stop,
                    etaChanged:new Date()
                  });
                }
              }
            }}
          />

          <div
            className="modal fade"
            id="shipper_info"
            data-backdrop="static"
            data-keyboard="false"
            tabIndex="-1"
            aria-hidden="true"
          >
            <div className="modal-dialog  modal-lg">
              <AddShipper />
            </div>
          </div>

          <div
            className="modal fade"
            id="carrier_info"
            data-backdrop="static"
            data-keyboard="false"
            tabIndex="-1"
            aria-hidden="true"
          >
            <div className="modal-dialog  modal-lg">
              <Addcarrier />
            </div>
          </div>
          <div
            className="modal fade"
            id="doc_pop"
            data-backdrop="static"
            data-keyboard="false"
            tabIndex="-1"
            aria-hidden="true"
          >
            <div className="modal-dialog  modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                    onClick={() => (this.refs.insertDocType.value = "1")}
                  ></button>
                  <h4 className="modal-title">Documents</h4>
                </div>
                <div className="modal-body">
                  <div className="form-group row">
                    <label className="col-md-3 control-label">
                      Documents Type:
                    </label>
                    <div className="col-md-8">
                      <select
                        className="form-control"
                        defaultValue="1"
                        ref="insertDocType"
                      >
                        <option value="2">BOL (Bill of Loading)</option>
                        <option value="1">Rate Sheet</option>
                        <option value="3">POD (Proof of Delivery)</option>
                        <option value="4">Lumper Receipt</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn default"
                    data-dismiss="modal"
                    onClick={() => (this.refs.insertDocType.value = "1")}
                  >
                    Cancel
                  </button>
                  &nbsp;
                  <button
                    type="submit"
                    className="btn green-meadow"
                    data-dismiss="modal"
                    onClick={(e) => {
                      e.preventDefault();
                      var docType = this.refs.insertDocType.value;
                      var label =
                        docType === "1"
                          ? "Rate Sheet"
                          : docType === "2"
                          ? "BOL (Bill of Loading)"
                          : docType === "3"
                          ? "POD (Proof of Delivery)"
                          : "Lumper Receipt";
                      var Documents = this.state.Documents;
                      var docNo = Documents.length + 1;
                      var obj = {
                        label: label,
                        docType: docType,
                        documentNo: docNo,
                        fileName: "",
                        error: "",
                        showIcons: true,
                        file: "",
                        s3FileKey: "",
                        checked: false,
                        documentId: "",
                        invoiceError: "",
                        fileExtension: "",
                      };
                      this.refs.insertDocType.value = "1";

                      Documents.push(obj);

                      this.setState({ Documents });
                    }}
                  >
                    <i className="fa fa-check"></i> Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    );
  }
}

export default withRouter(EditPickUpAndDelieveryDetails);
